import { RouterModule, Routes } from '@angular/router';

import { StartComponent } from './components/layout/start.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InconsistentLayoutComponent } from './components/layout/inconsistent-layout.component';

export const matchRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'unmatched',
                component: StartComponent
            },
            {
                path: 'inconsistent',
                component: InconsistentLayoutComponent
            }
        ]
    }
];

export const matchRouting = RouterModule.forChild(matchRoutes);
