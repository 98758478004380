import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiSettings, CompanyReportsSettings } from '../../../settings.class';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'con-press-release-filter',
  templateUrl: './press-release-filter.component.html',
  styleUrls: ['./press-release-filter.component.scss']
})
export class PressReleaseFilterComponent implements OnInit{

  // exchange related variables

  public exchanges: any[] = [];
  public presetExchanges: {[key: string]: any[]} = {};
  originalPresets: {[key: string]: any[]} = {};

  exchange_ids = '';
  @Output() filterUpdate = new EventEmitter<any>();

  initialized = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(ApiSettings.BASE_URL +'/company_report/get_exchange_presets/').pipe(take(1)).subscribe((exchangePresets: any) => {
      this.presetExchanges = JSON.parse(JSON.stringify(exchangePresets));
      this.originalPresets = exchangePresets;
      let milanExchange = {};
      for (let key in this.presetExchanges[environment.press_release_default_exchange]) {
        let value = this.presetExchanges[environment.press_release_default_exchange][key];
        if(value.id === environment.press_release_milan_exchange_id ) {
          milanExchange = this.presetExchanges[environment.press_release_default_exchange][key];
          this.addExchange(milanExchange)
        }
      }
      this.http.get(ApiSettings.BASE_URL +'/exchange/'+environment.press_release_euro_next_growth_milan_id).pipe(take(1)).subscribe((response: any) => {
        if(response) {
          const euroNextGrowthMilan = {
            id: response.id,
            name: response.name,
            mic: response.mic
          }
          this.addExchange(euroNextGrowthMilan);
          this.initialized = true;
          this.refresh();
        }
      }, (err) => {
          console.error("Api Error", err)
      })
    })
  }

  updateFilter() {
    const filterObject = {
      exchange_ids: this.exchange_ids
    }
    this.filterUpdate.emit(filterObject);
  }
  refresh(){
    if (this.exchanges.length) {
      this.exchange_ids = this.exchanges.map(e => e.id).join();
    } else {
      this.exchange_ids = '';
    }
    this.updateFilter();
  }
  addExchange(data: any) {
    // From group
    if (data.hasOwnProperty('value') && Array.isArray(data.value)) {
      const uniqueExchanges = [];

      if (this.addedToExchangeFilter(data)) {
        // toggle remove
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList !== -1) {
            this.exchanges.splice(givenExchangeInExistingList, 1);
            this.presetExchanges = this.originalPresets;
          }
        })
        if(this.initialized) {
          this.refresh();
        }
      } else {
        // toggle add
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList === -1) {
            uniqueExchanges.push(preset);
          }
        })
        if (uniqueExchanges.length > 0) {
          this.exchanges.push(...uniqueExchanges);
          if(this.initialized) {
            this.refresh();
          }
        }
      }
    } else {
      // From search box
      const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(data.id));
      if (givenExchangeInExistingList === -1) {
        this.exchanges.push(data);
        if(this.initialized) {
          this.refresh();
        }
      }
    }
  }
  addedToExchangeFilter(preset): boolean {
    if (preset.hasOwnProperty('value') && Array.isArray(preset.value)) {
      const mappedPresetIds = preset.value.map((item) => item.id);
      const mappedExchangesIds = this.exchanges.map((item) => item.id);
      return mappedPresetIds.every(v => mappedExchangesIds.includes(v));
    }
    return false;
  }
  removeExchange(exchange: any) {
    this.exchanges = this.exchanges.filter(e => Number(e.id) !== Number(exchange.id));
    this.refresh();
    this.presetExchanges = this.originalPresets;

  }
  removeAllExchanges() {
    this.exchanges = [];
    this.refresh();
    this.presetExchanges = this.originalPresets;
  }
}
