
import {tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { ProfileService } from '../services/profile.service';

import { Observable } from 'rxjs';

@Injectable()
export class SuperUserGuard implements CanActivate {

    constructor(private profile: ProfileService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.profile.isSuperUser().pipe(
                            tap(status => {
                                if (!status) {
                                    this.router.navigate(['']);
                                }
                            }));
    }
}
