import { Injectable } from '@angular/core';
import { DocProcessService } from 'app/doc-process/sub-modules/doc-process-common/services/doc-process.service';
import { HTTPMethod } from 'app/doc-process/sub-modules/doc-process-common/models/api';
import { from, of } from 'rxjs';
import { bufferCount, concatMap, delay, tap } from 'rxjs/operators';
import { Query } from 'app/doc-process/sub-modules/doc-process-common/services/methods.service';

@Injectable({
  providedIn: 'root'
})
export class DataRequestService {

  requestInstanceData(data: any[], query: Query) {
    const urlIds = data.map((v, i) => v.instance_id).join(',');
    this.docProcessService.getData({params: urlIds}, query, HTTPMethod.Get) //getInstanceData(urlIds)
  }
  requestInstanceDocuments(data: any[], query: Query) {
    from(data).pipe(
      bufferCount(10),
      concatMap(txn => of(txn).pipe(delay(100))),
      tap(h => this.docProcessService.getData({params: h.map((v, i) => v.doc_id).join(',')}, query, HTTPMethod.Get))
    ).subscribe();
  }


  constructor(private docProcessService: DocProcessService) { }
}
