<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <h3 class="mb-0">Institution Press Releases</h3>
      </div>
      <div class="d-flex justify-content-start sticky-header non-sticky py-2" *ngIf="!loading && institution">
        <h3 class="mb-0">
          <a routerLink="../../../entity/institution/{{institution?.id}}"><span class="badge badge-dark">{{institution.name}}</span></a>
          <span *ngIf="institution?.website"><a [href]=institution?.website class="link-fontsize" target="_blank">{{getStripedWebsiteString(institution?.website)}}</a></span>
        </h3>
        <div class="chip chip-inverse ml-auto">
          <con-avatar [userId]="institution.manager?.id" [size]="32"></con-avatar>
          {{institution.manager?.first_name}} {{institution.manager?.last_name}}
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf='institutionId'>
    <div class="col-6" *ngIf='institution'>
      <con-entity-form [entity]='institution' entityName='Institution' [canEdit]='false' [canDelete]='false' [canStore]='false'>
      </con-entity-form>
    </div>
    <div class="col-6">
      <con-press-release-category-editor (afterSave)="reloadListing($event)" [fromCompanyDetail]="true" [fixedValueInput]="{institution_id: institutionId, owner_id: institution?.id, owner_type: 'Institution' }"></con-press-release-category-editor>
    </div>
    <div class="col-12 mt-15">
      <div class="card">
        <con-entity-list [runUpdate]='changes$' entityName='PressRelease' originEntityName='Institution' [entityId]='institutionId' relation="PressReleases"></con-entity-list>
      </div>
    </div>
  </div>
</div>
