
<div class="card mb-2" *ngIf="context === 'ADD_REPORT_FILE'">
  <div class="card-header cursor-pointer" (click)="toggleExpansion()">
    <div class="d-flex justify-content-start">
      <con-icon-by-name iconName="file" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
      <span>New Company Report File</span>
      <div class="card-header-toggle ml-auto">
        <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
      </div>
    </div>
  </div>
  <div class="card-body" [hidden]="!expanded" >
    <div class="adjust-report-pb-10">
      <label class="switch">
        <input type="checkbox" [checked]="isMLDirectoryMode" (click)="toggleMode()">
        <span class="slider round"></span>
      </label>
      <span class="switch-text" >From ML Directory</span>
    </div>
    <div *ngIf="isMLDirectoryMode">
      <div *ngIf="selectedFileFromMLMode === null">
        Choose a company report before continuing:
        <con-entity-search-field  [entityName]="CompanyReport" (onSelect)="setCompanyReport($event)"></con-entity-search-field>
      </div>
      <div *ngIf="selectedFileFromMLMode">
        <div>
          <div>
            <div><strong>Selected Company Report : </strong> {{getFormattedCompanyReport() | titlecase}}</div>
            <div><a (click)="changeCompanyReport()" class="mat-primary cursor-pointer">Change Company Report</a></div>
          </div>
          <div class="adjust-report-mt-10">
            <button (click)="openMLDirectory()"  class="btn btn-primary">Browse ML Directory ... </button>
          </div>
        </div>
        <div *ngIf="mlData" class="adjust-report-mt-10">
          <con-list-radio-selection [data]="mlData" (selectedItem)="updateFileMlSelection($event)"></con-list-radio-selection>

        </div>
        <con-loader [showCircularLoder]="true" *ngIf="showLoader"></con-loader>
        <div *ngIf="selectedMLDirectoryFile && mlData === null && !showLoader">
          <con-entity-form-content [entityName]="CompanyReportFile"  [preFillValues]="prefillValues" [entity]="entity"  [canDelete]="false" [showWhenChanged]="false"  [reloadEditData]="_reload" [initialSaveEnabled]="true"  (afterSave)="redirectToEntity($event)"></con-entity-form-content>
        </div>
      </div>
    </div>
    <div *ngIf="!isMLDirectoryMode">
      <con-entity-form-content [entityName]="CompanyReportFile"  [canDelete]="false" (afterSave)="redirectToEntity($event)" ></con-entity-form-content>
    </div>
  </div>
</div>


<!-- EDIT MODE -->

<div class="card mb-2" *ngIf="context === 'EDIT_REPORT_FILE'">

  <div class="card-header cursor-pointer" (click)="toggleExpansion()">
    <div class="d-flex justify-content-start">
      <con-icon-by-name iconName="file" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
      <span>{{editModeTitle | titlecase}}</span>
      <div class="card-header-toggle ml-auto">
        <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
      </div>
    </div>
  </div>
  <div class="card-body" [hidden]="!expanded" >
    <div class="adjust-report-pb-10" *ngIf="!entitiesService.entityLockedByMl(companyReportFile) && !entitiesService.isEntityLocked(companyReportFile)">
      <label class="switch">
        <input type="checkbox" [checked]="isMLDirectoryMode" (click)="toggleMode()">
        <span class="slider round"></span>
      </label>
      <span class="switch-text" >From ML Directory</span>
    </div>
    <div *ngIf="isMLDirectoryMode">
      <div *ngIf="selectedFileFromMLMode === null">
        Choose a company report before continuing:
        <con-entity-search-field  [entityName]="CompanyReport" (onSelect)="setCompanyReport($event)"></con-entity-search-field>
      </div>
      <div *ngIf="selectedFileFromMLMode">
        <div>
          <button (click)="openMLDirectory()"  class="btn btn-primary">Browse ML Directory ... </button>
        </div>
        <div *ngIf="mlData" class="adjust-report-mt-10">
          <con-list-radio-selection [data]="mlData" (selectedItem)="updateFileMlSelection($event)"></con-list-radio-selection>
        </div>
        <con-loader [showCircularLoder]="true" *ngIf="showLoader"></con-loader>
        <div *ngIf="selectedMLDirectoryFile && mlData === null && !showLoader">
          <con-entity-form-content [entityName]="CompanyReportFile" [preFillValues]="prefillValues" [fixedValues]="fixedValues" [entity]="entity"  [canDelete]="false" [showWhenChanged]="true"  [reloadEditData]="_reload" [initialSaveEnabled]="true"  (afterSave)="redirectToEntity($event)"></con-entity-form-content>
        </div>
      </div>
    </div>
    <div *ngIf="!isMLDirectoryMode">
      <con-entity-form-content [entityName]="CompanyReportFile"   [canDelete]="false"  [fixedValues]="fixedValues" (afterSave)="redirectToEntity($event)" [entity]="companyReportFile" [fromDetailPage]="true"></con-entity-form-content>
    </div>
  </div>
</div>

<!-- REPORT DETAIL REPORT FILE -->


<div class="card mb-2" *ngIf="context === 'REPORT_DETAIL_REPORT_FILE'">
  <div class="card-header cursor-pointer" (click)="toggleExpansion()">
    <div class="d-flex justify-content-start">
      <con-icon-by-name iconName="file" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
      <span>New Company Report File</span>
      <div class="card-header-toggle ml-auto">
        <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
      </div>
    </div>
  </div>
  <div class="card-body" [hidden]="!expanded" >
    <div class="adjust-report-pb-10">
      <label class="switch">
        <input type="checkbox" [checked]="isMLDirectoryMode" (click)="toggleMode()">
        <span class="slider round"></span>
      </label>
      <span class="switch-text" >From ML Directory</span>
    </div>
    <div *ngIf="isMLDirectoryMode">
      <div *ngIf="selectedFileFromMLMode === null">
        Choose a company report before continuing:
        <con-entity-search-field  [entityName]="CompanyReport" (onSelect)="setCompanyReport($event)"></con-entity-search-field>
      </div>
      <div *ngIf="selectedFileFromMLMode">
        <div>
          <div>
            <button (click)="openMLDirectory()"  class="btn btn-primary">Browse ML Directory ... </button>
          </div>
        </div>
        <con-loader [showCircularLoder]="true" *ngIf="showLoader"></con-loader>
        <div *ngIf="mlData" class="adjust-report-mt-10">
          <con-list-radio-selection [data]="mlData" (selectedItem)="updateFileMlSelection($event)" (selectedUrl)="previewUrl($event)"></con-list-radio-selection>
          <con-ml-directory-preview [displayMLPreviewUrl]="previewMLReportUrl" (closePreviewFile)="setPreviewMLReportUrlEmpty($event)"> </con-ml-directory-preview>
        </div>
        <div *ngIf="selectedMLDirectoryFile && mlData === null && !showLoader">
          <con-entity-form-content [entityName]="CompanyReportFile"  [fixedValues]="fixedValues"  [emptyAfterSave]="true" [preFillValues]="prefillValues" [entity]="entity"  [canDelete]="false" [showWhenChanged]="false"  [reloadEditData]="_reload" [initialSaveEnabled]="true"  (afterSave)="redirectToEntity($event)"></con-entity-form-content>
        </div>
      </div>
    </div>
    <div *ngIf="!isMLDirectoryMode">
      <con-entity-form-content [entityName]="CompanyReportFile" [fixedValues]="fixedValues" [emptyAfterSave]="true"   [canDelete]="false" (afterSave)="redirectToEntity($event)" ></con-entity-form-content>
    </div>
  </div>
</div>

