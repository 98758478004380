import { Component, OnInit } from '@angular/core';
import { CompanyReportsSettings } from '../../settings.class';
import { Router } from '@angular/router';
import { ProfileService } from '../../auth/services/profile.service';
import { CompanyReportsService } from '../../company-reports/services/company-reports.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'con-press-release-start',
  templateUrl: './press-release-start.component.html',
  styleUrls: ['./press-release-start.component.scss']
})
export class PressReleaseStartComponent implements OnInit {
  public tierId: number = CompanyReportsSettings.TIER_ID;
  public recentCompanyList: any[];
  public recentInstitutionList: any[];
  public companyLsName = 'recentPressReleases';
  public institutionLsName = 'recentInstitutionPressReleases';
  public changes$: Subject<any> = new Subject<any>();
  public isInstitution: boolean = false;
  pressReleaseQueryParams: any;
  initialized = false;
  expanded = true;
  exchange_ids_val = {
    exchange_ids: ''
  }
  previousCompanyFilterValue: any;
  constructor(
    private router: Router,
    public profile: ProfileService,
    private reportService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.recentCompanyList = this.reportService.getRecent(this.companyLsName);
    this.recentInstitutionList = this.reportService.getRecent(this.institutionLsName);
    this.reportService.recentSearchUpdated.subscribe(data => {
      this.recentCompanyList = this.reportService.getRecent(this.companyLsName);
      this.recentInstitutionList = this.reportService.getRecent(this.institutionLsName);
    })
  }

  public navigateToCompany(company: any) {
    const recentData = {
      id: company.id,
      name: company.name
    };
    this.reportService.setRecent(this.companyLsName, recentData);
    this.router.navigate(['press_release', 'company', company.id]);

  }
  navigateToInstitution(institution: any) {
    const recentData = {
      id: institution.id,
      name: institution.name
    };
    this.reportService.setRecent(this.institutionLsName, recentData);
    this.router.navigate(['press_release', 'institution', institution.id]);
  }
  public removeFromList(company) {
    this.reportService.removeFromRecent(this.companyLsName, company, true)
  }
  public removeInstitutionFromList(institution) {
    this.reportService.removeFromRecent(this.institutionLsName, institution, true)
  }

  reloadListing(event: any) {
    this.changes$.next(event);
  }

  updatedFilter(filterObject: any) {
    this.pressReleaseQueryParams = filterObject;
    this.pressReleaseQueryParams.owner_type = 'Company';
    this.exchange_ids_val.exchange_ids = filterObject.exchange_ids;
    if(!this.initialized) {
      this.changes$.next(true);
      this.initialized = true;
    } else {
      setTimeout(()=> {
        this.changes$.next(true)
      }, 0)
    }
  }
  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  toggleInstitution(isInstitution: boolean) {
    this.isInstitution = isInstitution;
    if(this.isInstitution) {
      this.previousCompanyFilterValue = JSON.parse(JSON.stringify(this.pressReleaseQueryParams));
      this.pressReleaseQueryParams.exchange_ids = '';
      this.pressReleaseQueryParams.owner_type = 'Institution';
    } else {
      this.pressReleaseQueryParams = JSON.parse(JSON.stringify(this.previousCompanyFilterValue));
      this.exchange_ids_val.exchange_ids = this.previousCompanyFilterValue.exchange_ids;
    }
    setTimeout(()=> {
      this.changes$.next(true)
    }, 0);
  }

}
