import { Component, Input, OnChanges } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiSettings, ListsSettings } from '../../../settings.class';

@Component({
  selector: 'con-tier-missing-companies',
  templateUrl: './tier-missing-companies.component.html'
})

export class TierMissingCompaniesComponent implements OnChanges {
    @Input() tierId: number;
    public canView = false;
    public result: any;
    public loading = false;
    private companyName = '';

    constructor(private http: HttpClient,
                private profile: ProfileService) {}

    ngOnChanges() {
        if (this.profile.can(this.profile.getPermissionName('CompanyTier', 'show', 'Companies'))) {
            this.canView = true;
            this.loadPage(1);
        }
    }

    updateCompany(company: any) {
        const index = this.result.data.findIndex(c => c.id === company.id);
        if (index > -1) {
            this.result.data[index] = company;
        }
    }

    removeCompany(company: any) {
        const index = this.result.data.findIndex(c => c.id === company.id);
        if (index > -1) {
            this.result.data.splice(index, 1);
        }
    }

    searchChanged(name: string) {
        this.companyName = name;

        this.loadPage(this.result.current_page);
    }

    loadPage(pageNumber: number) {
        this.loading = true;

        const p = { page: <string><any>pageNumber };
        if (this.companyName !== '') {
            p['q:name'] = this.companyName;
        }
        const params = new HttpParams({ fromObject: p });
        this.http.get<any>(ApiSettings.BASE_URL + '/company_tier/' + this.tierId + '/' + ListsSettings.TIER_MISSING_COMPANIES_ENDPOINT,
                {
                    params
                }
            )
            .subscribe(
                res => {
                    this.loading = false;
                    this.result = res;
                },
                err => console.log(err)
            )
    }
}
