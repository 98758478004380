import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerChooserService } from 'app/doc-process/sub-modules/doc-process-common/services/server-chooser.service';
import {Environment} from '../../services/env-settings.service';

@Component({
  selector: 'con-server-chooser',
  templateUrl: './server-chooser.component.html',
  styleUrls: ['./server-chooser.component.scss']
})
export class ServerChooserComponent implements OnInit {

  index = 0

  get serverOptions(): Array<string> {
    return this.serverChooserService.getServerOptions()
  }

  constructor(
    private router: Router,
    public serverChooserService: ServerChooserService,
  ) { }

  setServer(serverString: string) {
    let server: Environment = serverString as Environment
    if (server) {
      this.router.navigate(['/doc_process/', server.toString().toLowerCase()])

      setTimeout(() => {
        this.serverChooserService.hasChangedServer.next(true)
      }, 0)
    }
  }


  ngOnInit() {
  }

}
