
import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            map((e: boolean) => {
                if (e) {
                    return true;
                }
                this.router.navigate(['login']);
                return false;
            }),
            catchError(() => {
                this.router.navigate(['login']);
                return observableOf(false);
            }));
    }
}
