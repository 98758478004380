import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EntitiesService } from '../../entities/services/entities.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'con-institution-tier-detail',
    templateUrl: './institution-tier-detail.component.html'
})

export class InstitutionTierDetailComponent implements OnInit {
    public institutionTier: any;
    public institutionTierId: number;
    public loading: boolean;

    public toggles: any = {};

    constructor(private entities: EntitiesService,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.route.params
              .pipe(switchMap((params: Params) => {
                  this.loading = true;
                  this.institutionTierId = params.id;
                  return this.entities.getEntityById('InstitutionTier', params.id);
              }))
              .subscribe(institutionTier => {
                  this.institutionTier = institutionTier;
                  this.loading = false;
              },
              err => {
                  if (err.status === 404) {
                      this.router.navigate(['tier', 'error'], { skipLocationChange: true });
                  }
                  this.loading = false;
              });
    }

    public toggleView(view: string) {
        this.toggles[view] = !this.toggles[view];
    }

    public ignoreInstitution(institution: any) {
        this.entities.addRelation(
            'InstitutionTier',
            this.institutionTierId,
            'ignoredInstitutions',
            [ institution.id ]
        )
        .subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    }
    public unignoreInstitution(institution: any) {
        this.entities.removeRelation(
            'InstitutionTier',
            this.institutionTierId,
            'ignoredInstitutions',
            [ institution.id ]
        )
        .subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    }
}
