<div class="content">
  <div class="container-fluid mb-3">
    <div class="row">
      <div class="col-12">
        <div>
          <button class='btn btn-primary float-right' (click)='createDeltaDelivery()'>Create delta delivery</button>
          <div class="delta-preloader">
            <con-loader *ngIf="loading" ></con-loader>
          </div>
        </div>
        <div class='mt-15'>
          <con-delta-deliveries-log></con-delta-deliveries-log>
        </div>
        <div class='mt-15'>
        <con-delta-deliveries-primary-missing></con-delta-deliveries-primary-missing>
        </div>
      </div>
    </div>
  </div>
</div>
