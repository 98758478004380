<div *ngIf="company?.missing_report_events.length || !isListing">
  <div class="d-flex justify-content-start sticky-header py-2">
    <a  target="_blank">
      <h3 class="mb-0">
        <span class="badge badge-dark">
          {{ company?.name }}
        </span>
        <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{reportService.getStripedWebsiteString(company?.website)}}</a></span>
      </h3>
    </a>
    <div class="ml-auto">
      <div class="chip chip-inverse chip-link"  (click)="addNewEvent()">
        <i class="fa fa-plus"></i>
        Add New Report Event
      </div>
    </div>
  </div>

  <!-- show add report event -->
  <div>
  <div class="card mb-2" *ngIf="company?.showAddEvent">
    <div class="card-header" (click)="toggleType(company)">
      <div class="d-flex justify-content-start align-items-center">
        <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
        Add New Report Event
        <div class="card-header-toggle-individual ml-auto">
          <con-icon-by-name iconName="angle-right" [mutations]="getMutations(company)"></con-icon-by-name>
          <con-icon-by-name ngbTooltip="Remove Window" iconName="remove" (click)="removeAddEvent(company)"></con-icon-by-name>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="company?.expanded">
      <con-entity-form-content
        [showEditButton]="false"
        [entityName]="entityName"
        [preFillValues]="{report_date: getCurrentDate(),report_type:'update'}"
        [canDelete]="false"
        [initialSaveEnabled] = "true"
        [fixedValues]="{ company_id: company.id, owner_type: ownerType}"
      >
      </con-entity-form-content>
    </div>
  </div>
</div>

  <!-- showing missing report events -->

  <div *ngIf="company?.missing_report_events.length">
    <div class="card mb-2" *ngFor="let t of company.missing_report_events; let i = index">
      <div class="card-header" (click)="toggleType(t)">
        <div class="d-flex justify-content-start align-items-center">
          <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
          {{ t.report_type | titlecase }}
          <div class="card-header-toggle-individual ml-auto">
            <con-icon-by-name iconName="angle-right" [mutations]="getMutations(t)"></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="t?.expanded">
          <con-entity-form-content
                                   [showEditButton]="false"
                                   [entityName]="entityName"
                                   [preFillValues]="{report_date: t.report_date}"
                                   [canDelete]="false"
                                   [initialSaveEnabled] = "true"
                                   [fixedValues]="{ company_id: t.company_id, owner_type: ownerType, report_type:t.report_type}"
                                   (afterSave)="removeEvent($event)"
                                   [showWhenChanged]="false"
                              >
          </con-entity-form-content>
      </div>
    </div>
  </div>
  <div>
    <con-no-data message="No report event missing" [count]="company?.missing_report_events.length"></con-no-data>
  </div>
  <hr>
</div>
