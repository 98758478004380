import { Injectable, Injector } from '@angular/core';
import { Instance } from '../../kpi-and-kiid/models/Typings';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Task } from '../models/Task';
import { FundamentalsApiService } from './fundamentals-api.service';
import { map } from 'rxjs/operators';
import { InstanceDocument } from '../../kpi-and-kiid/models/fields';
import { UpdateDocuments } from '../state-management/actions';

@Injectable({
  providedIn: null,
})
export class DocumentsLazyLoadingService {
  constructor(private injector: Injector, private apiService: FundamentalsApiService) {}

  private store = this.injector.get(Store);

  public loadInitialBatch(instances$: Observable<Instance[]>, taskInstances: Observable<Task>): Observable<{ [id: number]: InstanceDocument }> {
    const instancesToGet$ = instances$.pipe(
      map((instances: Instance[]) => {
        const batchSize = this.store.selectSnapshot(TaskDataEntryState.selectDocumentsBatchSize);
        return instances.slice(0, batchSize);
      })
    );
    return this.apiService.getInstanceDocumentsforInstances(instancesToGet$, taskInstances);
  }

  public loadNextBatch(id: number) {
    const batchSize = this.store.selectSnapshot(TaskDataEntryState.selectDocumentsBatchSize);
    const instances = this.store.selectSnapshot(TaskDataEntryState.selectAllInstances());
    const documents = this.store.selectSnapshot(TaskDataEntryState.selectAllDocuments());
    const task$ = this.store.select(TaskDataEntryState.selectTaskInstances);
    const documentsKeys = Object.keys(documents);
    const currentInstance = instances.find((instance) => instance.instance_id === id);
    const currentDocId = currentInstance.doc_id;
    if (instances.length > documentsKeys.length && !documentsKeys.includes(currentDocId.toString())) {
      const instancesForDocs = this.store.select(TaskDataEntryState.selectAllInstances()).pipe(
        map((instances) => {
          let instancesToGet = instances.filter((instance) => !documentsKeys.includes(instance.doc_id.toString()));
          if (instancesToGet.length > batchSize) {
            instancesToGet = instancesToGet.slice(0, batchSize);
          }
          if (!instancesToGet.includes(currentInstance)) {
            instancesToGet.splice(1, 1, currentInstance);
          }
          return instancesToGet;
        })
      );

      this.apiService.getInstanceDocumentsforInstances(instancesForDocs, task$).subscribe((documents) => {
        this.store.dispatch(new UpdateDocuments(documents));
      });
    }
  }
}
