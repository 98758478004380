import { Component, OnInit, ViewChild, Input, Output, AfterContentChecked, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilService } from '../../services/util.service';
import { Job, Symbol } from 'app/task-manager/typings/Typings';

@Component({
  selector: 'con-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() headSub: Subject<any>
  @Input() bodySub: Subject<any>
  @Input() tableHeadData: string[];
  @Input() tableBodyData: any[];
  @Input() withHeader: boolean;
  @Input() isEditable: boolean;
  @Input() dataTypes: any[];
  @Input() notEditableColumns: any[] = [];
  @Input() linkPath: string;
  @Input() clickableCells: number[] = [];
  @Input() cellsContainingUrl: number[] = [];
  @Input() canCancelData = false;
  @Input() specialConditionForCancellingData: string;
  @Input() amountOfElementsPerPage: number;
  @Input() isPaginated = false;
  @Input() hasSearch = false;
  @Input() editingObject: string;
  @Input() editDataWithoutInput = false;
  @Input() canDeleteData = false;
  @Input() selectColumn = false;
  @Input() wideCells = false;
  @Input() cellWidth: number = null;
  @Input() oldTableHeadData = [];
  @Input() oldTableBodyData = [];
  @Output() onChange = new EventEmitter();
  @Output() passedDataTypeCheck = new EventEmitter();
  @Output() deleteObject = new EventEmitter();
  @Output() editObject = new EventEmitter();
  @Output() selectedObject = new EventEmitter();


  public dataTypeCheckFailedRow = -1;
  public dataTypeCheckFailedColumn = -1;
  public bodyArr = [];
  public page = 1;
  private errMessage: any;
  private checkFailed: boolean;
  public hasData = false
  public columns: number[] = [];
  public amountOfPages: number;
  private paginationArr = []
  public searchTerm: string;
  private searchArr = [];
  public editingRow = 0;
  public editingColumn = 0;
  public differenceMessage: string = undefined;
  public moreThanTenPercentDiscrepancy = false;

  constructor(private utilService: UtilService) {
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.tableBodyData && this.tableBodyData.length > 0) {
      this.amountOfPages = this.tableBodyData.length - 1;
      this.mapBodyDataToHeadData();
      this.hasData = true;
      if (this.isPaginated) {
        if (this.page > 0) {
          this.paginate(this.page);
        }
      }
      if (this.hasSearch) {
        if (this.searchTerm) {
          this.search();
        }
      }
    }
  }

  ngAfterViewInit(): void {

    if (this.headSub) {
      this.headSub.subscribe(data => {
        this.tableHeadData = data;
      })
    }
    if (this.bodySub) {
      this.bodySub.subscribe(data => {
        this.tableBodyData = data;
        this.mapBodyDataToHeadData();
      })
    }
    if (this.tableBodyData) {
      this.mapBodyDataToHeadData();
    }
  }

  ngOnInit() {
  }
  mapBodyDataToHeadData() {
    if (this.canCancelData || this.canDeleteData) {

      switch (this.specialConditionForCancellingData) {
        case 'Running Mozenda Job': {
          this.tableBodyData.forEach((job: Job,  index: any) => {
            if (job.job_state === 'Manual' && job.m_job_id) {
              this.tableBodyData[index].icon = 'times';
            }
          })
        } break;
        case 'EditOnlyNuSymbols': {
          this.tableBodyData.forEach((symbol: Symbol, index: any) => {
            if (!symbol.tasks && symbol.symbol_id) {
              this.tableBodyData[index].icon = 'pencil';
            }
          });
        } break;
        default: {
          this.tableBodyData.forEach((data: any,  index: any) => {
            this.tableBodyData[index].icon = 'times';
          })
        }
      }
    }
    if (this.editDataWithoutInput) {
      this.tableBodyData.forEach((data: any,  index: any) => {
        this.tableBodyData[index].icon = 'pencil';
      });
    }
    if (this.withHeader) {
      this.bodyArr = [];
      this.tableBodyData.forEach((body, i) => {

        if (body) {
          const items = Object.keys(body).map(bodyKey => body[bodyKey]);
          if (this.isEditable) {
            items.forEach(item => {
            })
          }
          if (this.bodyArr.indexOf(items) < 0) {
            this.bodyArr.push(items);
          }
        }
      });
      this.searchArr = this.bodyArr;
      this.paginationArr = this.bodyArr;
      if (this.isPaginated) {
        this.bodyArr = this.paginate(1);
      }

    }
  }
  onChangeEvent(row: number, column: number, value: any) {
    this.tableBodyData[row][this.tableHeadData[column]] = value;
    const amountOfRows = this.tableBodyData.length;
    const amountOfColumns = this.dataTypes.length - 1;
    this.editingRow = row;
    this.editingColumn = column;
    for (let column = 2; column <= amountOfColumns; column++) {
      for (let row = 0; row < amountOfRows; row++) {

        const value = this.tableBodyData[row][this.tableHeadData[column]];
        this.checkTypes(column, row, value);
        if (this.checkFailed) {
          this.passedDataTypeCheck.emit(this.errMessage);
          this.dataTypeCheckFailedColumn = column;
          this.dataTypeCheckFailedRow = row;
          return;
        }
      }
    }
    if (this.oldTableBodyData) {
      const oldValue = this.oldTableBodyData[row][this.oldTableHeadData[column]];
      this.checkForDiscrepancy(column, row, value, oldValue)
    }
    if (!this.checkFailed) {
      this.passedDataTypeCheck.emit(true);
      this.dataTypeCheckFailedRow = -1;
      this.dataTypeCheckFailedColumn = -1;
      this.onChange.emit(this.tableBodyData);
    }

  }

  checkForDiscrepancy(column: number, row: number, value: any, oldValue: any) {
    this.errMessage = '';
    this.moreThanTenPercentDiscrepancy = false;
    this.differenceMessage = undefined;
    if (value) {
      const result =  parseFloat(Math.abs((Math.abs(oldValue) - Math.abs(value)) / (Math.abs(oldValue)) * 100).toFixed(2))
      if (result >= 10) {
        this.differenceMessage = `+ ${result}%`;
        this.moreThanTenPercentDiscrepancy = true;
      }
      if (result < 0) {
        const convRes = Math.abs(result);
        if (convRes >= 10) {
          this.differenceMessage = `${result}%`
          this.moreThanTenPercentDiscrepancy = true;
        }
      }
      if (result === 0 || value === '') {
        this.differenceMessage = undefined;
        this.moreThanTenPercentDiscrepancy = false;
      }
    } else {
      this.differenceMessage = undefined;
      this.moreThanTenPercentDiscrepancy = false;
    }
  }

  checkTypes(column: number, row: number, value: any) {
    this.errMessage = '';
    if (value) {
      this.dataTypeCheck(row, column, value);
    } else {
      if (!value) {
        this.tableBodyData[row][this.tableHeadData[column]] = null;
        this.checkFailed = undefined;
      }
    }
  }

  dataTypeCheck(row: number, column: number, value: any) {
    const dataType = this.dataTypes[column];
    switch (dataType.toLowerCase()) {
      case 'decimal': {
        this.checkFailed = !this.utilService.isDecimal(value);
        if (this.checkFailed) {
          this.errMessage =  'Not a decimal'
        }
      } break;
      case 'date': {
        this.checkFailed = !this.utilService.isValidDate(value);
        if (this.checkFailed) {
          this.errMessage =  'Not correct date format';
        }
      } break;
      case 'string': {
        this.checkFailed = this.utilService.isNumeric(value as string);
        if (this.checkFailed) {
          this.errMessage =  'Not a string';
        }
      }break;
    }
  }

  deleteData(row: any) {
    switch (this.editingObject) {
      case 'delivery': {
        const delivery = this.tableBodyData.find(data => {
          return data.delivery_id === row[1];
        });
        this.deleteObject.emit(delivery);
      } break;
      case 'component': {
        const indexComponent = this.tableBodyData.find(data => {
          return data.comp_id === row[0];
        });
        this.deleteObject.emit(indexComponent);
      }
    }
  }
  sendSelectedObject(row: any) {
    switch (this.editingObject) {
      case 'component': {
        const indexComponent = this.tableBodyData.find(data => {
          return data.feednu === row[0] && data.ticker === row[3];
        });
        this.selectedObject.emit(indexComponent);
      }
    }
  }

  editData(row: any) {
    const symbol = this.tableBodyData.find(data => {
      return data.symbol_id === row[0] || data.comp_id === row[0];
    })
    this.editObject.emit(symbol)
  }
  pageChange(page: any) {
    this.bodyArr = this.paginate(page)
  }
  paginate (page_number) {
    const arr = this.paginationArr;
    --page_number; // because pages logically start with 1, but technically with 0
    return arr.slice(page_number * this.amountOfElementsPerPage, (page_number + 1) * this.amountOfElementsPerPage);
  }
  search() {
    const searchArr = this.searchTable(this.searchTerm);
    this.amountOfPages = searchArr.length - 1;
    this.bodyArr = searchArr;
    this.paginationArr = this.bodyArr;
    this.page = 1;
    this.bodyArr = this.paginate(1);
  }
  searchTable(term: string) {
    const arr = this.searchArr;
    term = term.toLowerCase();
    return arr.filter(item => {
      return item.some(field => {
        if (field) {
          return field.toLowerCase().includes(term)
        }
      })
    }).map(item => {
      return item;
    })
  }
}
