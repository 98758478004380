import { Component, Input, OnChanges } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiSettings, ListsSettings } from '../../../settings.class';

@Component({
  selector: 'con-tier-missing-institutions',
  templateUrl: './tier-missing-institutions.component.html'
})

export class TierMissingInstitutionsComponent implements OnChanges {
    @Input() tierId: number;
    public canView = false;
    public result: any;
    public loading = false;
    private institutionName = '';

    constructor(private http: HttpClient,
                private profile: ProfileService) {}

    ngOnChanges() {
        if (this.profile.can(this.profile.getPermissionName('InstitutionTier', 'show', 'Institutions'))) {
            this.canView = true;
            this.loadPage(1);
        }
    }

    updateInstitution(institution: any) {
        const index = this.result.data.findIndex(c => c.id === institution.id);
        if (index > -1) {
            this.result.data[index] = institution;
        }
    }

    removeInstitution(institution: any) {
        const index = this.result.data.findIndex(c => c.id === institution.id);
        if (index > -1) {
            this.result.data.splice(index, 1);
        }
    }

    searchChanged(name: string) {
        this.institutionName = name;

        this.loadPage(this.result.current_page);
    }

    loadPage(pageNumber: number) {
        this.loading = true;

        const p = { page: <string><any>pageNumber };
        if (this.institutionName !== '') {
            p['q:name'] = this.institutionName;
        }
        const params = new HttpParams({ fromObject: p });
        this.http.get<any>(ApiSettings.BASE_URL +
                    '/institution_tier/' +
                    this.tierId + '/' +
                    ListsSettings.TIER_MISSING_INSTITUTIONS_ENDPOINT,
                {
                    params
                }
            )
            .subscribe(
                res => {
                    this.loading = false;
                    this.result = res;
                },
                err => console.log(err)
            )
    }
}
