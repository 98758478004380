<div class="sidebar">
    <nav class="nav flex-column">
        <a class="nav-link"
        [routerLink]="['unmatched']"
        routerLinkActive="active">
            Unmatched <span class="badge badge-pill badge-danger">{{ counts?.UNMATCHED }}</span>
        </a>
        <a class="nav-link"
        [routerLink]="['inconsistent']"
        routerLinkActive="active">
            Inconsistent <span class="badge badge-pill badge-danger">{{ counts?.INCONSISTENT }}</span>
        </a>
    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
