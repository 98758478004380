import {Component, Input} from "@angular/core";
import {CurrentFields} from "../../kpi-and-kiid/models/fields";

@Component({
  selector: 'abstract-field-status',
  template: ''
})
export abstract class AbstractFieldMetaDependencies {
  @Input() instanceData;
  @Input() currentFields: CurrentFields[]
  @Input() fieldName: string
}
