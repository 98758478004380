import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/auth/services/profile.service';
import { CalendarService } from 'app/calendar/calendar.service';
import { CalendarSettings } from 'app/settings.class';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-earnings',
  templateUrl: './earnings-layout.component.html'
})
export class EarningsLayoutComponent implements OnInit, OnDestroy {

  private componentDestroyed$: Subject<any> = new Subject<void>();

  public companyTierId: number = CalendarSettings.TIER_ID;
  public companyDetails: any;

  constructor(
    public profileService: ProfileService,
    private calendarService: CalendarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.setChoosenCompany();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public chooseCompany(company: any) {
    this.calendarService.setEarningSelectedCompany(company);
    this.router.navigate(['/calendar/earnings/company', company.id]);
  }

  private setChoosenCompany(): void {
    this.calendarService.getEarningSelectedCompany.pipe(takeUntil(this.componentDestroyed$)).subscribe((company: any) => {
      this.companyDetails = company;
    })
  }

  public clearChoosenCompany(): void {
    this.router.navigate(['/calendar/earnings']);
    this.calendarService.setEarningSelectedCompany(null);
  }
}
