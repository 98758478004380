
import {switchMap, startWith} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { CountService } from '../services/count.service';

import { MatchSettings } from '../../settings.class';

import { HttpClient } from '@angular/common/http';

import { Observable ,  Subject } from 'rxjs';



@Component({
    selector: 'con-unmatched',
    templateUrl: './unmatched.component.html',
    styles: [`.highlight-preset { background-color: #009688; color: white} .adjust-margin { margin-bottom: 4px; }`]
})

export class UnmatchedComponent implements OnInit {
    private page$ = new Subject<number>();
    public currentPage = 1;
    public data: any;
    public exchanges: any[] = [];
    public noDataMessage = 'No data to display.';
    public loading: boolean;
    public presetExchanges: {[key: number]: string} = {}
    constructor(private http: HttpClient, private count: CountService) {}

    ngOnInit() {
        this.page$.pipe(
            startWith(this.currentPage),
            switchMap(page => {
                this.loading = true;
                return this.getUnmatched(page)
            }))
            .subscribe((res: any)  => {
                    const _data = [];
                    for (const [key, value] of (<any>Object).entries(res.data)) {
                      if (key === 'presetExchanges') {
                        // store preset exchange
                        this.presetExchanges = res.data.presetExchanges;
                        // remove preset exchange key
                        delete res.data.presetExchanges;
                      } else {
                        _data.push(value);
                      }
                    }
                    res.data = _data;
                    this.data  = res;
                    this.loading = false;
                },
                err => {
                    this.loading = false;
                }
            );
    }

    addExchange(data: any) {
        // From group
        if (data.hasOwnProperty('value') && Array.isArray(data.value)) {
          const uniqueExchanges = [];

          if (this.addedToExchangeFilter(data)) {
            // toggle remove
            (data.value as Array<any>).forEach((preset) => {
              const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
              if (givenExchangeInExistingList !== -1) {
                this.exchanges.splice(givenExchangeInExistingList, 1);
              }
            })
            this.refresh();
          } else {
            // toggle add
            (data.value as Array<any>).forEach((preset) => {
              const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
              if (givenExchangeInExistingList === -1) {
                uniqueExchanges.push(preset);
              }
            })
            if (uniqueExchanges.length > 0) {
              this.exchanges.push(...uniqueExchanges);
              this.refresh();
            }
          }
        } else {
          // From search box
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(data.id));
          if (givenExchangeInExistingList === -1) {
            this.exchanges.push(data);
            this.refresh();
          }
        }
    }

    removeExchange(exchange: any) {
        this.exchanges = this.exchanges.filter(e => Number(e.id) !== Number(exchange.id));
        this.refresh();
    }

    getUnmatched(page: Number) {
        const params = {
            page: String(page)
        };

        if (this.exchanges.length) {
            params['exchange_ids'] = this.exchanges.map(e => e.id).join();
        }
        return this.http.get(MatchSettings.BASE_URL + '/' + MatchSettings.UNMATCHED_ENDPOINT, {
            params
        });
    }

    afterMatched(match: any) {
        this.data.data = this.data.data.filter(m => m.isin !== match.isin);
        this.count.decrementCount('UNMATCHED');
        if (!this.data.data.length) {
            this.refresh();
        }
    }

    changePage(page: number) {
        this.page$.next(page);
    }

    refresh() {
      this.currentPage = 1;
      this.page$.next(1);
    }

  addedToExchangeFilter(preset): boolean {
    if (preset.hasOwnProperty('value') && Array.isArray(preset.value)) {
      const mappedPresetIds = preset.value.map((item) => item.id);
      const mappedExchangesIds = this.exchanges.map((item) => item.id);
      return mappedPresetIds.every(v => mappedExchangesIds.includes(v));
    }
    return false;
  }

  removeAllExchanges() {
    this.exchanges = [];
    this.refresh();
  }
}
