export class HslColor {
  constructor(
    public hue: number,
    public saturation: number,
    public lightness: number
  ) { }

  public toString(): string {
    return "hsl("+this.hue+","+this.saturation+"%,"+this.lightness+"%)"
  }
}
