import { Component, Input, Output, EventEmitter, OnChanges, OnInit, OnDestroy } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'con-create-task',
    templateUrl: './create-task.component.html'
})

export class CreateTaskComponent implements OnChanges, OnInit, OnDestroy {
    @Input() description: string;
    @Input() title: string;
    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();

    private userSubscription: ISubscription;

    private user: any;
    public fixedValues: any = {};
    public hasUser: boolean;

    constructor(public profileService: ProfileService) {}

    ngOnChanges() {
        this.fixedValues = {};
        if (this.user) {
            this.fixedValues['owner_id'] = this.user.id;
        }
        if (this.description) {
            this.fixedValues['description'] = this.description;
        }
        if (this.title) {
            this.fixedValues['title'] = this.title;
        }
    }

    ngOnInit() {
        this.userSubscription = this.profileService
                                    .getUser()
                                    .subscribe(user => {
                                        if (user == null) {
                                            this.hasUser = false;
                                        } else {
                                            this.user = user;
                                            this.hasUser = true;
                                            this.ngOnChanges();
                                        }
                                    });
    }

    isLoading() {
        return this.profileService.isLoading();
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }
}
