import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileService } from 'app/auth/services/profile.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-missing-primary-editor',
  templateUrl: './missing-primary-editor.component.html'
})
export class MissingPrimaryEditorComponent implements OnChanges, OnDestroy {

  @Input() company: any;
  @Input() showEditor = true;
  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
  is_processing: boolean;
  taskSubscription: any;

  public managerName = 'Manager not assigned';

  constructor(public profile: ProfileService,
    public service: EntitiesService,
    public toastr: ToastrService) { }

  ngOnChanges() {
    if (this.company && this.company.manager && this.company.manager.first_name) {
      this.managerName = this.company.manager.first_name + ' ' + this.company.manager.last_name;
    }
    const missingInstruments = [{
      "id": 1112213,
      "ticker": "TSO",
      "name": "TESORO RES FPO [TSO]",
      "isin": "AU0000077208",
      "currency": {
        "id": 8,
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$",
        "revised_at": "2021-05-03 01:00:02",
        "created_by": 0,
        "updated_by": 0,
        "deleted_by": null,
        "created_at": "2018-07-05T14:07:22+00:00",
        "updated_at": "2021-05-03T01:00:03+00:00",
        "deleted_at": null
      },
      "exchange": {
        "id": 51,
        "mic": "XASX",
        "name": "ASX - ALL MARKETS",
        "country_id": 13,
        "operating_exchange_id": 29,
        "revised_at": "2021-10-06 12:09:02",
        "created_by": 0,
        "updated_by": 0,
        "deleted_by": null,
        "created_at": "2018-07-05T14:06:52+00:00",
        "updated_at": "2021-10-06T12:09:05+00:00",
        "deleted_at": null
      }
    },
      {
        "id": 1112213,
        "ticker": "TSO",
        "name": "TESORO RES FPO [TSO]",
        "isin": "AU0000077208",
        "currency": {
          "id": 8,
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$",
          "revised_at": "2021-05-03 01:00:02",
          "created_by": 0,
          "updated_by": 0,
          "deleted_by": null,
          "created_at": "2018-07-05T14:07:22+00:00",
          "updated_at": "2021-05-03T01:00:03+00:00",
          "deleted_at": null
        },
        "exchange": {
          "id": 51,
          "mic": "XASX",
          "name": "ASX - ALL MARKETS",
          "country_id": 13,
          "operating_exchange_id": 29,
          "revised_at": "2021-10-06 12:09:02",
          "created_by": 0,
          "updated_by": 0,
          "deleted_by": null,
          "created_at": "2018-07-05T14:06:52+00:00",
          "updated_at": "2021-10-06T12:09:05+00:00",
          "deleted_at": null
        }
      }
    ];
    this.company.missing_instruments = missingInstruments;
  }

  saveEntity(payload: any, entityName: string) {
    this.is_processing = true;
    this.taskSubscription = this.service.saveEntity(entityName, payload).subscribe(data => {
      if (data.done || data.is_primary ) {
        this.toastr.success('Primary instrument updated successfully!', 'Primary Instrument');
        this.afterSave.emit(this.company);
      } else {
        this.toastr.error('Sorry, some error occurred!', 'Primary Instrument');
      }
      this.is_processing = false;
    }, err => {
      this.is_processing = false;
      this.toastr.error('Sorry, some error occurred!', 'Primary Instrument');
    });
  }

  ngOnDestroy() {
    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }
  }

  getActions() {
    return [ { label: 'Make Primary', icon: 'plus-circle', buttonClass: 'success', name: 'makePrimary' } ]
  }

  updateMissingPrimaryInstrument(data: any) {
    const payload = {
      id: data.id,
      is_primary: true
    };
    this.saveEntity(payload, 'Instrument');
  }
}
