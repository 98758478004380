import { Component, OnChanges, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'con-entity-outdated-modal',
    templateUrl: './entity-outdated-modal.component.html'
})

export class EntityOutdatedModalComponent implements OnChanges {
    @Input() object1: any;
    @Input() object2: any;

    public user: any;
    public loading = false;

    constructor(private users: UsersService,
                public activeModal: NgbActiveModal) {}

    ngOnChanges() {
        this.loading = true;
        this.users.getUserById(this.object1.updated_by)
                    .subscribe(user => {
                        this.user = user;
                        this.loading = false;
                    });
    }

    setObjects(object1: any, object2: any) {
        this.object1 = object1;
        this.object2 = object2;
        this.ngOnChanges();
    }
}
