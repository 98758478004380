import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PressReleaseComponent } from './layout/press-release.component';
import { PressReleaseStartComponent } from './layout/press-release-start.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EntitiesModule } from '../entities/entities.module';
import { UniversalDataEntryModule } from '../doc-process/sub-modules/universal-data-entry/universal-data-entry.module';
import { PressReleaseFilterComponent } from './component/press-release-filter/press-release-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyPressReleaseComponent } from './component/company-press-release/company-press-release.component';
import { InstitutionPressReleaseComponent } from './component/institution-press-release/institution-press-release.component';
import { PressReleaseCategoryEditorComponent } from './component/press-release-category-editor/press-release-category-editor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    PressReleaseComponent,
    PressReleaseStartComponent,
    PressReleaseFilterComponent,
    CompanyPressReleaseComponent,
    InstitutionPressReleaseComponent,
    CompanyPressReleaseComponent,
    PressReleaseCategoryEditorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    EntitiesModule,
    FormsModule,
    ReactiveFormsModule,
    UniversalDataEntryModule,
    NgbModule
  ]
})
export class PressReleaseModule { }
