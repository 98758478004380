import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProfileService } from '../auth/services/profile.service';

import { ApiSettings } from './../settings.class';

import { HttpClient } from '@angular/common/http';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'con-powers',
    templateUrl: './powers.component.html'
})

export class PowersComponent implements OnInit, OnDestroy {
    public loaders: any = {};
    public powers: any = ApiSettings.POWERS;
    public messages: any = [];

    private user: any;

    private userSubscription: ISubscription;

    constructor(private authHttp: HttpClient,
                private profile: ProfileService) {}

    ngOnInit(): void {
        this.userSubscription = this.profile
                                    .getUser()
                                    .subscribe(user => this.user = user);
    }

    usePower(power: any): void {
        this.loaders[power.name] = true;
        let request;
        if (power.method === 'GET') {
            request = this.authHttp.get(ApiSettings.BASE_URL + '/' + power.endpoint);
        } else if (power.method === 'POST') {
            request = this.authHttp.post(ApiSettings.BASE_URL + '/' + power.endpoint, {});
        }
        request.subscribe(res => {
            this.loaders[power.name] = false;
            this.messages.push(res);
        }, err => {
            this.messages.push(err);
            this.loaders[power.name] = false;
        });
    }

    getHorizonUrl(): string {
        return ApiSettings.HORIZON_URL + '?code=' + this.user.horizon_code;
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }
}
