<div class="card mb-2">
    <div class="card-header cursor-pointer" (click)="refresh()" ngbTooltip="Refresh List">
        <div class="d-flex justify-content-start">
            <con-icon-by-name iconName="tasks" [mutations]="['fw']" class="mr-1"></con-icon-by-name> {{ getHeaderText() }} ({{ tasks?.total }})
            <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="refresh" [mutations]="['fw', 'lg']"></con-icon-by-name>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-8">
                <ng-container *ngIf="!modes_loading">
                    <h6 class="mb-0">{{ getModeTitle() }}</h6>
                    <span class="badge badge-pill timeline-pill" *ngFor="let m of modes" (click)="changeMode(m)" [ngClass]="[ m.value === mode ? 'badge-primary' : 'badge-dark' ]">
                        {{ m.label }}
                    </span>
                </ng-container>
                <con-loader *ngIf="modes_loading"></con-loader>
            </div>
            <div class="col-4 text-right">
                <ng-container *ngIf="!modes_loading">
                    <h6 class="mb-0">Status</h6>
                    <span class="badge badge-pill timeline-pill" *ngFor="let m of statusModes" (click)="changeStatusMode(m)" [ngClass]="[ m.value === statusMode ? 'badge-primary' : 'badge-dark' ]">
                        {{ m.label }}
                    </span>
                </ng-container>
                <con-loader *ngIf="modes_loading"></con-loader>
            </div>
        </div>
        <div class="row mt-10">
          <div class="col-12">
            <ng-container *ngIf="!modes_loading">
              <h6 class="mb-0">Tasks</h6>
              <span class="badge badge-pill timeline-pill" (click)="toggleDuplicateShareholderMode()" [ngClass]="[ duplicateShareholderMode ? 'badge-primary' : 'badge-dark' ]">
                        Shareholder duplicates
               </span>
            </ng-container>
          </div>
        </div>
    </div>



    <div class="card-body">
        <ul [ngClass]="{'timeline':tasks?.data?.length>0}">
            <con-loader *ngIf="isLoading()"></con-loader>
            <ng-container *ngIf="!isLoading()">
                <li *ngFor="let task of tasks?.data">
                    <div class="timeline-circ timeline-circ-linked" ><con-icon-by-name iconName="plug"></con-icon-by-name></div>
                    <div class="timeline-entry">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <strong>
                                            <span [class]="(task.deadline | amParse:'') < now ? 'text-danger' : 'text-muted'">
                                                <con-icon-by-name iconName="clock-o"></con-icon-by-name> {{ task.deadline | amCalendar }}
                                                <con-icon-by-name iconName="tag" class="ml-1"></con-icon-by-name> {{ getAssignedToName(task) }}
                                                <con-icon-by-name iconName="user" class="ml-1" *ngIf="task.owner"></con-icon-by-name> {{ getOwnerName(task)}}
                                            </span>
                                            <br>
                                            <a *ngIf="taskLinking.getLink(task).length !== 0" [routerLink]="taskLinking.getLink(task)[0]" [queryParams]="taskLinking.getLink(task)[1]" >{{ task.title }}</a>
                                            <span *ngIf="taskLinking.getLink(task).length === 0 ">{{ task.title }}</span>
                                        </strong><br>
                                            <em>{{ task.description }}</em>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="pull-left">
                                            <button class="btn btn-sm btn-link text-primary" *ngIf="profile.can(profile.getPermissionName('Task', 'show', 'comments'))" (click)="toggleComments(task)">
                                                <con-icon-by-name iconName="comment"></con-icon-by-name><strong>Comments ({{ task.comments_count }})</strong>
                                            </button>
                                        </div>
                                        <div class="pull-right">
                                            <button class="btn btn-sm btn-link text-success" (click)="markAsDone(task)" *ngIf="!task.done && profile.can(profile.getPermissionName('Task', 'update'))">
                                                <con-loader *ngIf="loaders['done-' + task.id]"></con-loader><con-icon-by-name iconName="check" [mutations]="['fw']" *ngIf="!loaders['done-' + task.id]"></con-icon-by-name><strong *ngIf="!loaders['done-' + task.id]">Mark as done</strong>
                                            </button>
                                            <button class="btn btn-sm btn-link text-danger" (click)="markAsUndone(task)" *ngIf="task.done && profile.can(profile.getPermissionName('Task', 'update'))">
                                                <con-loader *ngIf="loaders['done-' + task.id]"></con-loader><con-icon-by-name iconName="minus" [mutations]="['fw']" *ngIf="!loaders['done-' + task.id]"></con-icon-by-name><strong *ngIf="!loaders['done-' + task.id]">Mark as undone</strong>
                                            </button>
                                            <button class="btn btn-sm btn-link text-info" *ngIf="userOwnsTask(task) && profile.can(profile.getPermissionName('Task', 'update'))" (click)="toggleDeadline(task)">
                                                <con-icon-by-name iconName="clock-o" [mutations]="['fw']"></con-icon-by-name><strong>Change deadline</strong>
                                            </button>
                                            <button class="btn btn-sm btn-link text-warning" *ngIf="profile.can(profile.getPermissionName('Task', 'update'))" (click)="toggleAssignedTo(task)">
                                                <con-icon-by-name iconName="tag" [mutations]="['fw']"></con-icon-by-name><strong>Reassign</strong>
                                            </button>
                                            <button class="btn btn-sm btn-link text-danger" *ngIf="userOwnsTask(task) && profile.can(profile.getPermissionName('Task', 'delete'))" (click)="deleteTask(task)">
                                                <con-loader *ngIf="loaders['delete-' + task.id]"></con-loader><con-icon-by-name iconName="trash" [mutations]="['fw']" *ngIf="!loaders['delete-' + task.id]"></con-icon-by-name><strong *ngIf="!loaders['delete-' + task.id]">Delete</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="commentsIsExpanded(task)">
                                    <div class="col-12">
                                        <div class="timeline-comments">
                                            <con-conversation
                                                [subjectId]="task.id"
                                                subjectType="Task"
                                                (afterAdd)="incrementComments(task)"
                                                (afterDelete)="decrementComments(task)">
                                            </con-conversation>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="deadlineExpanded(task)">
                                    <div class="col-12">
                                        <con-entity-form-content
                                            entityName="Task"
                                            [entity]="task"
                                            [only]="['deadline']"
                                            [canDelete]="false"
                                            [showWhenChanged]="false"
                                            (afterSave)="updateTaskInList($event)"
                                            toastMessage = "Deadline changed successfully!"
                                            ></con-entity-form-content>
                                    </div>
                                </div>
                                <div class="row" *ngIf="assignedToIsExpanded(task)">
                                    <div class="col-12">
                                        <con-entity-form-content
                                            entityName="Task"
                                            [entity]="task"
                                            [only]="['assignedTo']"
                                            [canDelete]="false"
                                            [showWhenChanged]="false"
                                            (afterSave)="afterReassign($event)"
                                            toastMessage = "Assigned successfully!"
                                            ></con-entity-form-content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
        <con-no-data *ngIf="!isLoading()" [count]="tasks?.total" [message]="noDataMessage"></con-no-data>
        <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!tasks?.total}">
            <ngb-pagination [collectionSize]="tasks?.total"
                            [(page)]="page"
                            (pageChange)="changePage($event)"
                            [pageSize]="tasks?.per_page"
                            maxSize="10"
                            [rotate]="true"></ngb-pagination>
        </div>
    </div>
</div>
