<div class="container-fluid" *ngIf="profile.can(profile.getPermissionName('CompanyReport','store'))">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="paperclip"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Report</h3>
      </div>
    </div>
  </div>
  <div>
    <con-missing-company-reports></con-missing-company-reports>
  </div>
</div>
