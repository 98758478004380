import { Component, OnInit } from '@angular/core';
import {DataEntryComponent} from '../../../kpi-and-kiid/components/data-entry/data-entry.component';

@Component({
  selector: 'con-wide-textbox-data-entry',
  template: `
    <textarea
      *ngIf="instanceData?.[fieldName]"
      [(ngModel)]="instanceData[fieldName].value"
      type="text"
      class="form-control smaller-text-form w-100"
      placeholder="Objective"
      aria-label="Objective"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      rows="15"
      (paste)="removeMultipleSpacesAndNewLine($event, instanceData?.[fieldName])"
      (ngModelChange)="instanceData[fieldName].altered = true;instanceData[fieldName].confirmed = 'Altered'"
    ></textarea>
  `,
})
export class WideTextboxDataEntryComponent extends DataEntryComponent implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  removeMultipleSpacesAndNewLine(clipboardEvent: ClipboardEvent, valueToReplace: any) {
    setTimeout(() => {
      const filteredText = (clipboardEvent.target as HTMLTextAreaElement).value.split(/\s{2,}(\r\n|\n|\r)/).join('').replace(/\s+/g, ' ')
      valueToReplace.value = filteredText
    }, 0)
  }
}
