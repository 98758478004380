import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { ApiSettings, ShareholderSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'con-shareholders-missing',
    templateUrl: './shareholders-missing.component.html'
})

export class ShareholdersMissingComponent implements OnInit, OnDestroy {
    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    private reloadSubscription = new Subscription;
    private missingSubscription = new Subscription;

    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService) {}

    ngOnInit() {
        this.loadPage(1);
        this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
            if (type === 'Missing') {
                this.loadPage(1);
            }
        });
    }

    ngOnDestroy() {
        this.reloadSubscription.unsubscribe();
        this.missingSubscription.unsubscribe();
    }

    loadPage(pageNumber: number) {
        this.countService.getCounts(true);
        this.loading = true;

        this.missingSubscription = this.http.get<any>(ShareholderSettings.BASE_URL + '/' + ShareholderSettings.MISSING_ENDPOINT,
                {
                    params: {
                        page: String(pageNumber)
                    }
                })
                 .subscribe(res => {
                     this.countService.setCount('missing', res.total);
                     this.formatMissingData(res);
                     this.loading = false;
                 }, err => {
                     this.loading = false;
                     if (err.isPermissionError()) {
                         this.router.navigate(['shareholders', 'auth'], { skipLocationChange: true });
                     }
                 });
    }
    formatMissingData(res: any) {
        res.data.forEach(company => {
            company.missing = true;
            if (company.shareholders.length && company.shareholders[0]) {
                company.shareholders[0].missing = true;
                if (company.shareholders[0].source_type === 'CompanyReport'
                    && company.shareholders[0].source && company.shareholders[0].source.company) {
                    company.shareholders[0].source.company.name =
                        company.shareholders[0].source.company.name + ' - ' +
                        moment(company.shareholders[0].source.report_date). format('yyyy-MM-DD');
                }
            } else {
                company.shareholders = [{
                    owners: [],
                    missing: true,
                    source: {
                        id: company.id,
                        name: company.name
                    },
                    source_type: 'Company',
                    source_id: company.id
                }]
            }
        });
        this.missing = res;
    }

    removeFromList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.ngOnInit();
        }
    }

    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
    }
}
