import { Injectable } from '@angular/core';

import { CalendarEventSettings, DEFAULT_DATE_FORMAT } from '../../settings.class';

import * as moment from 'moment-timezone';
import { ProfileService } from 'app/auth/services/profile.service';
import { take } from 'rxjs/operators';

@Injectable()
export class DateConverterService {
    
    private currentTimezone = CalendarEventSettings.REPORT_DATES_DEFAULT_TIMEZONE;

    constructor( private profileService: ProfileService) {
        this.initializeTimezone();
    }

    private initializeTimezone() {
        this.profileService.getUserTimezone().pipe(take(1)).subscribe( timezone => {
            this.currentTimezone = timezone;
        });
    }

    public fromEntityString(date: string, timeZone?) {
        if (timeZone) {
            return moment.utc(date)
                     .tz(timeZone);
        }
            return moment.utc(date)
                    .tz(this.currentTimezone);
    }
    public toEntityString(date: any) {
        return date.utc()
                   .format('yyyy-MM-DDTHH:mm:ssZ');
    }
}
