import { Component, OnInit } from '@angular/core';
import { ProfileService } from './auth/services/profile.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'con-inside',
    templateUrl: './inside.component.html'
})

export class InsideComponent implements OnInit {
    public isInitialized = false;
    constructor(private profileService: ProfileService) {}

    ngOnInit() {
        this.profileService.whenReady().pipe(take(1)).subscribe(ready => {
            this.isInitialized = ready;
        });
    }
}
