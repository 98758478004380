import { Component, Input, OnChanges } from '@angular/core';

const ERROR_TEMPLATES = {
    required: 'This field is required',
    email: 'Must be a valid email',
    max: 'Value must be less than or equal to {{max}}',
    min: 'Value must be greater than or equal to {{min}}',
    default: 'Something is wrong with this value'
}
const DYNAMIC_ERROR_KEYS = ['max', 'min'];

@Component({
    selector: 'con-form-errors',
    templateUrl: './form-errors.component.html'
})

export class FormErrorsComponent implements OnChanges {
    @Input() control: any;
    @Input() extra: any;

    public controlsInternal: any;
    ngOnChanges() {
        if (this.isArray(this.control)) {
            this.controlsInternal = this.control;
        } else {
            this.controlsInternal = [ this.control ];
        }
    }
    isArray(arr: any) {
        return Object.prototype.toString.call(arr) === '[object Array]';
    }
    getErrorText(errorName: string, control: any) {
        if (DYNAMIC_ERROR_KEYS.indexOf(errorName) > -1) {
            return this.control.errors[errorName][errorName] 
                ? ERROR_TEMPLATES[errorName].replace(`{{${errorName}}}`, this.control.errors[errorName][errorName]) 
                : ERROR_TEMPLATES['default'];
        }
        else {
            return ERROR_TEMPLATES[errorName] ? ERROR_TEMPLATES[errorName] : ERROR_TEMPLATES['default']
        }
    }
}
