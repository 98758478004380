<div class="modal-header">
    <h5 class="modal-title" ngbAutofocus>Review Translated Descriptions</h5>
    <div>
        <button  class="btn btn-primary" [disabled]="!targetLanguages.length || loading" (click)="saveAll()">Save
            All</button>
        <button class="btn btn-secondary ml-1" (click)="close()" >Cancel</button>
    </div>
</div>
<div class="modal-body" *ngIf="descriptions.length">
    <div class="card mb-2" *ngIf="sourceDescription.description">
        <div class="card-header">
            <con-icon-by-name iconName="info" [mutations]="['fw']"></con-icon-by-name>{{ sourceDescription.language?.name }} description
        </div>
        <div class="card-body">
            <textarea class="form-control" type="text" disabled placeholder="Description"
            [(ngModel)]="sourceDescription.description"></textarea>
        </div>
    </div>
    <div class="card mb-2" *ngFor="let entity of descriptions; let index = index ">
        <div class="card-header">
            <con-icon-by-name iconName="info" [mutations]="['fw']"></con-icon-by-name>{{ entity.langauge_name }} description
        </div>
        <div class="card-body">
            <textarea class="form-control" type="text" placeholder="Description"
            [(ngModel)]="entity.description"></textarea>
            <button type="button" [disabled]="!entity.description?.length || loading" class="btn btn-success mt-2"
                (click)="saveOne(entity,index)">Save</button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <con-loader *ngIf="loading"></con-loader>
    <button class="btn btn-primary" [disabled]="!targetLanguages.length || loading" (click)="saveAll()">Save
        All</button>
    <button class="btn btn-secondary" (click)="close()">Cancel</button>
</div>