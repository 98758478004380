import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EntitiesService, EntityDescription } from '../services/entities.service';
import { ProfileService } from '../../auth/services/profile.service';
import { prettyCase } from 'app/shared/utilities.classes';
import { Observable } from 'rxjs';
@Component({
    selector: 'con-entities-layout',
    templateUrl: './entities-layout.component.html'
})

export class EntitiesLayoutComponent implements OnInit {
    @ViewChild('searchBox') searchElement: ElementRef;

    public entityDescriptions: EntityDescription[];
    public search = false;
    public searchInput: string;
    public isLoading() {
        return this.service.isLoading() || this.profileService.isLoading();
    }

    constructor(private service: EntitiesService,
        public profileService: ProfileService
    ) {}

    ngOnInit() {
        this.service.getAllEntityDescriptions()
                        .subscribe(entityDescriptions => {
                            this.entityDescriptions = entityDescriptions
                        } );
    }
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.searchEntity(false, null);
    };
    searchEntity(value: boolean, event?) {
        if (event) {
            event.stopPropagation();
        }
        this.search = value;
        if (!this.search) {
            this.searchInput = '';
        }
        setTimeout(() => {
            if (value) {
                this.searchElement.nativeElement.focus();
            }
          }, 0);
    }

    getEntities () {
        if (!this.entityDescriptions) {
            return [];
        } else if (this.entityDescriptions && !this.searchInput) {
            return this.entityDescriptions;
        } else {
            return this.entityDescriptions.filter(entity =>
                prettyCase(entity.name).toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
        }
    }
}
