import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import { ApiSettings, CalendarEventSettings, CalendarSettings } from 'app/settings.class';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'con-earnings-events',
  templateUrl: './earnings-events.component.html'
})
export class EarningsEventsComponent implements OnInit, OnDestroy {

  private componentDestroyed$: Subject<any> = new Subject<void>();
  public refreshTable$: Subject<any> = new Subject<void>();

  public isFetching = false;
  public companyDetails: any;
  public earningsTypeId: number;
  public reportDatesPageError: string;
  public tableFilter: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entityService: EntitiesService,
    private toastrService: ToastrService,
    private companyService: CompanyReportsService,
    private calendarService: CalendarService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      this.fetchInitialDetails(params['id']);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.calendarService.setEarningSelectedCompany(null);
  }

  private fetchInitialDetails(companyId: number): void {
    this.isFetching = true;
    const company$ = this.getCompanyDetails(companyId);
    const earningsTypeId$ = this.getEarningsTypeId();
    combineLatest([company$, earningsTypeId$])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([company, earningsTypeId]) => {
        this.companyDetails = company;
        this.earningsTypeId = earningsTypeId;
        this.tableFilter = this.calendarService.getReportDatesTableFilter(this.earningsTypeId, companyId);
        this.isFetching = false;
      },
        (error) => {
          this.isFetching = false;
          const errorMessages = error?.status === ApiSettings.RESPONSE_CODES.NOT_FOUND 
            ? 'Selected company not found. Please choose another company' 
            : ApiSettings.INTERNAL_SERVER_ERROR;
          this.toastrService.error(errorMessages);
          this.closeCompanyView();
        }
      );
  }

  private getCompanyDetails(companyId: number): Observable<any> {
    return this.calendarService.getEarningSelectedCompany.pipe(
      take(1),
      switchMap((company) => {
        if (company) {
          return of(company);
        } else {
          return this.entityService.getEntityById('Company', companyId).pipe(
            tap((fetchedCompany: any) => {
              const isInCalendarTier = fetchedCompany?.company_tiers.some((tier: any) => tier.id === CalendarSettings.TIER_ID);
              if (!isInCalendarTier) {
                this.toastrService.error('The selected company is not in calendar tier');
                this.closeCompanyView();
              }
              this.calendarService.setEarningSelectedCompany(fetchedCompany);
            })
          );
        }
      })
    );
  }

  private getEarningsTypeId(): Observable<number | null> {
    return this.calendarService.getEarningsTypeId.pipe(
      take(1),
      switchMap((earningsTypeId) => {
        if (earningsTypeId) {
          return of(earningsTypeId);
        } else {
          return this.companyService.getCalendarEventTypes().pipe(
            map((calendarEventTypes: any) => {
              const calendarEventType = calendarEventTypes.data.find((item) => item.name === CalendarEventSettings.REPORT_DATES_CALENDAR_EVENT_TYPE);
              if (calendarEventType) {
                this.calendarService.setEarningsTypeId(calendarEventType.id);
                return calendarEventType.id;
              } else {
                this.reportDatesPageError = 'Earnings event type not found';
                return null;
              }
            })
          );
        }
      })
    );
  }


  public updateListing() {
    this.refreshTable$.next(true);
  }

  private closeCompanyView(): void {
    this.router.navigate(['/calendar/earnings']);
  }
}
