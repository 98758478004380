<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <h3 class="mb-0">Company Press Releases</h3>
      </div>
      <div class="d-flex justify-content-start sticky-header non-sticky py-2" *ngIf="!loading && company">
        <h3 class="mb-0">
          <a routerLink="../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company.name}}</span></a>
          <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{getStripedWebsiteString(company?.website)}}</a></span>
        </h3>
        <div class="chip chip-inverse ml-auto">
          <con-avatar [userId]="company.manager?.id" [size]="32"></con-avatar>
          {{company.manager?.first_name}} {{company.manager?.last_name}}
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf='companyId'>
    <div class="col-6" *ngIf='company'>
        <con-entity-form [entity]='company' entityName='Company' [canEdit]='false' [canDelete]='false' [canStore]='false'>
        </con-entity-form>
    </div>
    <div class="col-6">
      <con-press-release-category-editor (afterSave)="reloadListing($event)" [fromCompanyDetail]="true" [fixedValueInput]="{company_id: companyId, owner_id: companyId, owner_type: 'Company'}"></con-press-release-category-editor>
    </div>
    <div class="col-12 mt-15">
      <div class="card">
         <con-entity-list [runUpdate]='changes$' entityName='PressRelease' originEntityName='Company' [entityId]='companyId' relation="PressReleases"></con-entity-list>
      </div>
    </div>
  </div>
</div>
