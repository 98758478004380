import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({name: 'stringify'})

export class StringifyPipe implements PipeTransform {

    transform(value: any) {
        if (value === 0 || value)  {
            return value.toString();
        }
        return '';
    }
}
