
<div class="p-2">
<!--  Language-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'Language'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="LANGUAGES"
      [fieldName]="'Language'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

<!--  PublicationDate-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'PublicationDate'"
  >
    <con-date-picker-component
      [dateObject]="instanceData.PublicationDate"
      (onValidation)="dataFieldValidityService.allowInstanceNavigation.next($event)"
    ></con-date-picker-component>
  </con-instance-data-field-wrapper>

<!--  SRRI-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'SRRI'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="srriArray"
      [fieldName]="'SRRI'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

<!--  Objective-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'Objective'"
  >
    <con-wide-textbox-data-entry
      [instanceData]="instanceData"
      [fieldName]="'Objective'"
      class="d-block w-100"
    ></con-wide-textbox-data-entry>
  </con-instance-data-field-wrapper>

<!--  InvestorTargetGroup-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'InvestorTargetGroup'"
  >
    <con-wide-textbox-data-entry
      [instanceData]="instanceData"
      [fieldName]="'InvestorTargetGroup'"
      class="d-block w-100"
    ></con-wide-textbox-data-entry>
  </con-instance-data-field-wrapper>

<!--  RecHoldingPeriod-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'RecHoldingPeriod'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="RecHoldingPeriod"
      [fieldName]="'RecHoldingPeriod'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

<!--  ShareClasses-->
  <con-share-classes
    [instanceData]="instanceData"
    [fieldName]="'ShareClasses'"
    [currentFields]="currentFields"
  ></con-share-classes>

</div>
