import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';

import { HttpClient } from '@angular/common/http';

import { cloneDeep } from 'lodash';
import * as moment from 'moment';

const MISSING_MODE = 'missing';
const OUTDATED_MODE = 'outdated';
const REVIEW_MODE = 'review'

@Component({
    selector: 'con-shareholders-editor',
    templateUrl: './shareholders-editor.component.html'
})

export class ShareholdersEditorComponent implements OnInit {
    @Input() company: any;
    @Input() mode: string = MISSING_MODE;
    @Input() fromSearch = false;

    @Output() afterFixed: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    public loading: boolean;
    public languages: any;
    public positions: any;
    public showOutdated: boolean;
    public showMissing: boolean;
    public outdatedCompany: any;

    constructor(private http: HttpClient,
                public profile: ProfileService) {}

    ngOnInit() {
        this.formatOwnerValues();
        if (this.company.outdated) {
            this.outdatedCompany = cloneDeep(this.company);
            this.formatRevisionData();
        }
    }
    formatRevisionData() {
            this.outdatedCompany.shareholders.splice(1);
            delete this.outdatedCompany.shareholders[0].id;
            this.outdatedCompany.shareholders[0].owners.forEach(owner => {
                delete owner.id;
            });
    }
    formatOwnerValues() {
        if (this.company.shareholders
            && this.company.shareholders.length) {
                this.company.shareholders.forEach(share => {
                    share.owners.forEach(owner => {
                        owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
                        owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
                    });
                });
            }
    }
    formatOutdatedOwnerValues(){
        if (this.outdatedCompany.shareholders
            && this.outdatedCompany.shareholders.length) {
                this.outdatedCompany.shareholders.forEach(share => {
                    share.owners.forEach(owner => {
                        owner.capital = (owner.capital !== null && owner.capital !== '') ? (owner.capital * 100).toFixed(2) : null;
                        owner.votes = (owner.votes !== null && owner.votes !== '') ? (owner.votes * 100).toFixed(2) : null;
                    });
                });
            }
    }
    isMissingMode() {
        return this.mode === MISSING_MODE;
    }
    isOutdatedMode() {
        return this.mode === OUTDATED_MODE;
    }
    isReviewMode() {
        return this.mode === REVIEW_MODE;
    }

    showShareholderForm(index: number) {
        if (this.isMissingMode()) {
            return (!this.company.shareholders
                        || !this.company.shareholders.length
                        || !this.company.shareholders[index].owners
                        || !this.company.shareholders[index].owners.length );
        }
        return this.company.shareholders
            && this.company.shareholders.length
            && this.company.shareholders[index].owners
            && this.company.shareholders[index].owners.length ;
    }

    shareholderUpdated(shareholder: any, index: number) {
        shareholder.hide = false;
        if (shareholder.source_type === 'CompanyReport') {
            shareholder.source.company = {
                id: this.company.id,
                name: this.company.name + ' - ' + moment(shareholder.source.report_date).format('yyyy-MM-DD')
            }
        }
        if (this.isMissingMode()) {
            this.company.shareholders[index] = shareholder;
            this.company.shareholders[index].missing = true;
            this.formatOwnerValues();
        }
        if (this.isOutdatedMode()) {
            if(shareholder.prefill_owners && shareholder.prefill_owners.length>0 && ( !shareholder.owners || shareholder.owners.length==0 )) {
                shareholder.owners=shareholder.prefill_owners;
              }
            this.company.shareholders[index].outdated = true;
            this.outdatedCompany.shareholders[index] = shareholder;
            this.formatOutdatedOwnerValues();
        }
    }
    ownerUpdated(owner: any, index: number) {
        this.company.shareholders[index].owners = owner;
        this.afterUpdate.emit(this.company);
    }
    public toggleType(event?) {
        event.hide = ! event.hide;
    }
    getMutations(event) {
        return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
    }
    hasValidShareholder() {
        let hasShareholder = false;
        if (this.company.shareholders && this.company.shareholders.length) {
            this.company.shareholders.forEach(shareholder => {
                if (shareholder.id) {
                    hasShareholder = true;
                }
            });
            return hasShareholder;
        } else {
            return false;
        }
    }
}
