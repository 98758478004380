import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiSettings, TranslationSettings } from "app/settings.class";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "con-list-in-modal",
  templateUrl: "./list-in-modal.component.html",
})
export class ListInModalComponent implements OnInit {
  public language = [];
  public description: any = {};
  listForm: UntypedFormGroup;
  loading: boolean = false;
  existingDescriptions: any[] = [];
  selectGivenLanguage = false;
  selectedLanguage = null;
  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.listForm = new UntypedFormGroup({});
        if (!this.selectedLanguage) {
            this.language.forEach((lang) => {
                lang.isSelected = true;
                this.listForm.addControl(lang.iso639_1, new UntypedFormControl(lang.isSelected));
              });
          } else {
            this.language.forEach((lang) => {
                lang.isSelected = (lang.id === this.selectedLanguage.id);
                this.listForm.addControl(lang.iso639_1, new UntypedFormControl(lang.isSelected));
              });
        }

    this.http
      .get(
        `${ApiSettings.BASE_URL}/${TranslationSettings.COMPANY_ENDPOINT}/${this.description.company_id}/descriptions?per_page=1000  `
      )
      .subscribe((descriptions: any) => {
        this.existingDescriptions = descriptions.data
      }),
      (err) => {
        console.log(err);
      }
  }

  canDisableYesButton() {
    return !this.getSelectedLanguages().length || this.loading;
  }

  getTranslation() {
    const selectedLanguages = this.getSelectedLanguages();
    this.loading = true;
    const postData = {
      target_languages: selectedLanguages.join(),
      description: this.description.description,
      id: this.description.id,
    };
    this.http
      .post(
        `${ApiSettings.BASE_URL}/${TranslationSettings.TRANSLATION_ENDPOINT}`,
        postData
      )
      .subscribe(
        (result) => {
          this.activeModal.close(result);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error(
            "Sorry, some error occurred",
            "Company Description"
          );
        }
      );
  }

  getSelectedLanguages() {
    const result = [];
    this.language.forEach((lang) => {
      if (this.listForm.value[lang.iso639_1]) {
        result.push(lang.iso639_1);
      }
    });

    return result;
  }

  isDescriptionExists(iso639_1: string) {
    return !!this.getDescriptionByCode(iso639_1)
  }

  getDescriptionByCode(iso639_1: string) {
    const description = this.existingDescriptions.find((desc => desc.language.iso639_1 === iso639_1))
    if (description) {
      return description.description
    } else {
      null
    }
  }
}
