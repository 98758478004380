import { Component, Input, OnChanges } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';

import { EntitiesService } from '../../../entities/services/entities.service';
import { ApiSettings, ListsSettings } from '../../../settings.class';

@Component({
  selector: 'con-tier-companies',
  templateUrl: './tier-companies.component.html'
})

export class TierCompaniesComponent implements OnChanges {
    @Input() tierId: number;
    public canView = false;
    public result: any;
    private companyName: string;
    public loading = false;

    constructor(private entities: EntitiesService,
                private profile: ProfileService) {}

    ngOnChanges() {
        if (this.profile.can(this.profile.getPermissionName('CompanyTier', 'show', 'Companies'))) {
            this.canView = true;
            this.loadPage(1);
        }
    }

    updateCompany(company: any) {
        const index = this.result.data.findIndex(c => c.id === company.id);
        if (index > -1) {
            this.result.data[index] = company;
        }
    }

    removeCompany(company: any) {
        const index = this.result.data.findIndex(c => c.id === company.id);
        if (index > -1) {
            this.result.data.splice(index, 1);
        }
    }

    searchChanged(name: string) {
        this.companyName = name;

        this.loadPage(this.result.current_page);
    }

    loadPage(pageNumber: number) {
        this.loading = true;
        this.entities.searchRelation(
                'CompanyTier',
                this.tierId,
                'companies',
                {
                    name: this.companyName
                }
            )
            .subscribe(
                res => {
                    this.loading = false;
                    this.result = res;
                },
                err => console.log(err)
            )
    }
}
