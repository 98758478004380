import { RouterModule, Routes } from '@angular/router';

import { DashboardLayoutComponent } from './layout/dashboard-layout.component';
import { DashboardComponent } from './layout/dashboard.component';
import { DashboardCalendarComponent } from './layout/dashboard-calendar.component';

export const dashboardRoutes: Routes = [
    {
        path: '',
        component: DashboardLayoutComponent,
        canActivate: [],
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'calendar',
                component: DashboardCalendarComponent
            }
        ]
    }
];

export const dashboardRouting = RouterModule.forChild(dashboardRoutes);
