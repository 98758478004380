import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'con-entity-confirm-modal',
    templateUrl: './entity-confirm-modal.component.html'
})

export class EntityConfirmModalComponent {
    @Input() modalData;

    constructor(public activeModal: NgbActiveModal) {}

}
