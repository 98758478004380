import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CompanyReportsSettings} from '../../../settings.class';
import * as moment from 'moment';

@Component({
  selector: 'con-missing-report-events',
  templateUrl: './missing-report-events.component.html',
  styleUrls: ['./missing-report-events.component.scss']
})
export class MissingReportEventsComponent implements OnInit {
  companyId: number;
  companyReportEvent: any;
  loading = true;
  showErrorMessage = false;
  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.route.paramMap.subscribe( paramMap => {
      this.companyId = Number(paramMap.get('id'));
      this.loadData();

    })
  }
  loadData() {
    this.http.get( CompanyReportsSettings.MISSING_EVENTS_ENDPOINT + '/' + this.companyId + '/').subscribe((result) => {
      if(result){
        this.companyReportEvent = this.mapEventResponseData(result);
      }
      this.showErrorMessage = false;
      this.loading = false;
    },(error) => {
      this.loading = false;
      this.showErrorMessage = true;
      console.error(error);
    });
  }
  ngOnInit(): void {
  }

  mapEventResponseData(company) {
    company.id = company.company.id;
    company.name = company.company.name;
    //  add update event flag
    company.showAddEvent = false;
    company.expanded = true;
    // check annual key in missing event types
    if((company.missing_event_types as Array<any>).includes('annual')) {
      const annualMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'annual');
      if(annualMissingReportEvent) {
        const previousAnnualDate = annualMissingReportEvent.report_date;
        const prefilledAnnualData = {
          company_id : company.id,
          report_date: this.getAddedAnnualDate(previousAnnualDate),
          report_type: 'annual'
        }
        const previousAnnualDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'annual');
        company.missing_report_events.splice(previousAnnualDataIndex,1);
        company.missing_report_events.push(prefilledAnnualData);
      } else {
        const prefilledAnnualData = {
          company_id : company.id,
          report_date: this.getCurrentDate(),
          report_type: 'annual'
        }
        company.missing_report_events.push(prefilledAnnualData)
      }
    }
    // check interim key in missing event types
    if((company.missing_event_types as Array<any>).includes('interim')) {
      const interimMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'interim');
      if(interimMissingReportEvent) {
        const previousInterimDate = interimMissingReportEvent.report_date;
        const prefilledInterimData = {
          company_id : company.id,
          report_date: this.getAddedInterimDate(previousInterimDate),
          report_type: 'interim'
        }
        const previousInterimDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'interim');
        company.missing_report_events.splice(previousInterimDataIndex,1);
        company.missing_report_events.push(prefilledInterimData);
      } else {
        const prefilledInterimData = {
          company_id : company.id,
          report_date: this.getCurrentDate(),
          report_type: 'interim'
        }
        company.missing_report_events.push(prefilledInterimData)
      }
    }
    // set expanded flag
    company.missing_report_events.forEach((event) => {
      event.expanded = true;
    });
    return company;
  }


  getCurrentDate(): string {
    return moment().format('yyyy-MM-DD').toString();
  }
  getAddedAnnualDate(date: string): string {
    return moment(date).add(1,'year').format('yyyy-MM-DD').toString();
  }
  getAddedInterimDate(date: string): string {
    return moment(date).add(3,'months').format('yyyy-MM-DD').toString();
  }


  updateCompanyMissingData(companyId: number) {
    this.companyId = companyId;
    this.loadData();
  }
}
