import { Injectable } from '@angular/core';
import { InstanceDefinition, NewsAnnotation, NewsAnnotationField, NewsLabelingAnnotation } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { InstanceData, InstanceDataNews, InstanceDataUnioned } from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { DataExtractorService } from './data-extractor.service';
import { NewsInstancesService } from '../../doc-process-common/services/news-instances.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedAnnotationService {

  handleSelectedAnnotation(newAnnot: NewsAnnotation, index: number, InstanceDocumentAnnotation:NewsLabelingAnnotation, instanceDataNews: InstanceDataNews) {
    const field: NewsAnnotationField = {
      field: this.newsInstanceService.selectedTaxonomyDataField.toString(),
      context: newAnnot.fields[0].context
    }
    InstanceDocumentAnnotation.annotations[index].fields.push(field)

    const fieldFromAnnotations = InstanceDocumentAnnotation.annotations[index].fields.find(field => field.field === this.newsInstanceService.selectedTaxonomyDataField)
    const currentPeriodTaxonomyValue = instanceDataNews.taxonomy_data[fieldFromAnnotations.field].values.find(value => value.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod)
    if(currentPeriodTaxonomyValue) {
      currentPeriodTaxonomyValue.value = InstanceDocumentAnnotation.annotations[index].spans[0].text
    }
  }


  constructor(private newsInstanceService: NewsInstancesService, private dataExtractorService: DataExtractorService) { }
}
