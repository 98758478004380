
<div class="sidebar">
    <!--<con-loader *ngIf="!taskManagerDescriptions"></con-loader>-->
    <nav class="nav flex-column">
        <ng-container *ngFor="let taskDescription of taskManagerDescriptions">
            <a class="nav-link"
                [routerLink]="['/task_manager', taskDescription.taskName | snakeCase]"
                routerLinkActive="active"
                placement="right"
                [ngbTooltip]="taskDescription.taskName | prettyCase">
                <con-icon-by-name iconName="{{taskDescription.taskIcon}}"></con-icon-by-name>
            </a>
        </ng-container>
    </nav>
</div>
<con-loader-task-manager [isLoading]="isLoading()"></con-loader-task-manager>
<div class="content"  *ngIf="!isLoading()">
    <div *ngIf="profile.canOr([profile.getPermissionName('task_manager', 'show'), profile.getPermissionName('task_manager', 'update')]); else unauthorized" class="container-fluid mb-3">
        <div  class="page-title mb-2 pb-3 pt-3 d-flex justify-content-between">
            <h3 class="mb-0">{{headerName}}</h3>
            <div class="form-inline">
                <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Select server</span></label>
                <select class="custom-select" type="text" [(ngModel)]="selectedValue" (change)="setServer($event.target.value)">
                    <option *ngFor="let option of serverPool" [value]="option.value">{{option.value}}</option>
                </select>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
    <ng-template #unauthorized>
        <div class="container-fluid mb-3">
            <div class="jumbotron">
                <h1>Oops!</h1>
                <p class="lead">You are not authorized to see this! <con-icon-by-name iconName="frown-o"></con-icon-by-name></p>
            </div>
        </div>
    </ng-template>
</div>
