import {Component, Input, OnInit} from '@angular/core';
import {DocProcessTaxonomyFieldComponent} from './doc-process-taxonomy-field.component';

@Component({
  selector: 'con-fundamentals-sanity-delta',
  template: `
    <ng-container>
      <div class="text-right text-monospace font-italic {{isDeltaSane() ? 'text-muted' : 'text-danger'}} {{ !!moduleData?.level ? 'font-weight-bold' : 'font-weight-normal' }}"
           [ngbPopover]="popContent"
           container="body"
           [openDelay]="300"
           [closeDelay]="500"
           triggers="mouseenter:mouseleave">
        Δ:{{(getAbs())?.toFixed(2)}}</div>
    </ng-container>

    <ng-template #popContent><div [innerHTML]="popoverText"></div></ng-template>

  `,
})
export class FundamentalsSanityDeltaComponent implements OnInit {
  @Input() data: any;
  @Input() moduleData: any;
  get popoverText() {
    return `The delta value shows the difference between the sum of a module's annotations and the sum of its children modules.<br>
           Annotated value is: ${this.data.annotationsSum}.<br>
           Children total is: ${this.data.childrenConsolidation}.<br>
           Level value is: ${this.data.levelSum}.`
  }

  constructor() { }

  get Math() {return Math}
  get valuesForField() {
    let numbersToCompare: Array<number> = []

    if (!isNaN(this.data.childrenConsolidation))
      numbersToCompare.push(this.data.childrenConsolidation === 0 ? null : this.data.childrenConsolidation)

    if (!isNaN(this.data.annotationsSum))
      numbersToCompare.push(this.data.annotationsSum)

    if (!isNaN(this.data.levelSum))
      numbersToCompare.push(this.data.levelSum  === 0 ? null : this.data.levelSum)

    return numbersToCompare
  }

  ngOnInit(): void {
  }

  public getAbs(): number {
    let valuesForField = this.valuesForField.filter(val => !!val)
    return Math.min( ...valuesForField ) - Math.max( ...valuesForField )
  }

  public isDeltaSane(): boolean {
    let valuesForField = this.valuesForField
    if (DocProcessTaxonomyFieldComponent.isDifferenceBiggerThan10Percent(Math.min( ...valuesForField ), Math.max( ...valuesForField )))
      return false
    return true
  }
}
