import { ElementRef, Injectable } from '@angular/core';
import { InstanceDefinition, NewsAnnotation } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {
  InstanceDataNews,
  InstanceDataNewsTaxonomyData,
  InstanceDataUnioned,
  NewsTaxonomyDataFieldColors,
  TaxonomyContextPeriod,
  TaxonomyDataValue,
  TaxonomyFieldTypes,
  TaxonomyQuantity,
  TaxonomySign,
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataExtractorService } from '../../kpi-and-kiid/services/data-extractor.service';
import Mark from 'mark.js';
import { DocProcessHandlerUtilsService } from './utils.service';
import TextHighlighter from 'texthighlighter';
import { UtilService } from 'app/doc-process/sub-modules/doc-process-common/services/util.service';
import RangeOffsetModel from '../models/range-offset.model';
import {PersistentRange} from '../models/persistent-range';
import {DocProcessService} from './doc-process.service';

interface TaxonomyDataFieldValues extends Array<TaxonomyFieldTypes> {}

@Injectable({
  providedIn: 'root',
})
export class NewsInstancesService { // TODO this is a KPI&KIID specific service. refactor and move it under KPI&KIID module.
  fieldValueIndexes: { [field in TaxonomyFieldTypes]: number } = {} as any;
  private readonly _selectedTaxonomyDataContextPeriod = new BehaviorSubject<TaxonomyContextPeriod | string>(this.docProcessService.newsLabelingDefaultPeriod.getValue());
  private readonly _selectedTaxonomyQuantity = new BehaviorSubject<TaxonomyQuantity>(TaxonomyQuantity.Percent);
  private readonly _selectedTaxonomySign = new BehaviorSubject<TaxonomySign>(TaxonomySign.Plus);
  private readonly _instanceId = new BehaviorSubject<number>(null);

  private readonly _selectedNewsAnnotation = new BehaviorSubject<NewsAnnotation>(null);
  public selectedTaxonomyValue: TaxonomyDataValue = null;
  public selectedTaxonomy: TaxonomyDataValue = null;
  private readonly _selectedTaxonomyDataField = new BehaviorSubject<TaxonomyFieldTypes | string>(TaxonomyFieldTypes[TaxonomyFieldTypes.orders]);

  readonly selectedTaxonomyDataContextPeriod$ = this._selectedTaxonomyDataContextPeriod.asObservable();
  readonly selectedNewsAnnotation$ = this._selectedNewsAnnotation.asObservable();
  readonly selectedTaxonomyQuantity$ = this._selectedTaxonomyQuantity.asObservable();
  readonly selectedTaxonomySign$ = this._selectedTaxonomySign.asObservable();
  readonly selectedTaxonomyDataField$ = this._selectedTaxonomyDataField.asObservable();

  textHighlighter: typeof TextHighlighter = null;

  public newsFieldsValues: [
    {
      field: string;
      values: [
        {
          value: string;
          quantity: string;
          annot_id: number;
        }
      ];
    }
  ];

  _deletedAnnotation = new BehaviorSubject<boolean>(false);

  readonly deletedAnnotation$ = this._deletedAnnotation.asObservable();

  public instanceDataNews: InstanceDataNews = null;

  private highlightedText = '';

  public sub$ = new BehaviorSubject(undefined);
  public selectedAnnotationText = '';

  previousTextAnnotations = [];

  constructor(
    private dataExtractorService: DataExtractorService,
    private documentUtilsService: DocProcessHandlerUtilsService,
    private utilsService: UtilService,
    private docProcessService: DocProcessService,
  ) {
    this.subscribeToSelectedAnnotation();
  }

  get instanceId(): number {
    return this._instanceId.getValue();
  }

  set instanceId(val: number) {
    this._instanceId.next(val);
  }

  setInstanceId(instanceId: number) {
    this.instanceId = instanceId;
  }

  get selectedTaxonomyQuantity(): TaxonomyQuantity {
    return this._selectedTaxonomyQuantity.getValue();
  }

  set selectedTaxonomyQuantity(val: TaxonomyQuantity) {
    this._selectedTaxonomyQuantity.next(val);
  }

  setSelectedTaxonomyQuantity(selectedTaxonomyQuantity: TaxonomyQuantity) {
    this.selectedTaxonomyQuantity = selectedTaxonomyQuantity;
  }

  get selectedTaxonomySign(): TaxonomySign {
    return this._selectedTaxonomySign.getValue();
  }

  set selectedTaxonomySign(val: TaxonomySign) {
    this._selectedTaxonomySign.next(val);
  }

  setSelectedTaxonomySign(selectedTaxonomySign: TaxonomySign) {
    this.selectedTaxonomySign = selectedTaxonomySign;
  }

  get selectedNewsAnnotation(): NewsAnnotation {
    return this._selectedNewsAnnotation.getValue();
  }

  set selectedNewsAnnotation(val: NewsAnnotation) {
    this._selectedNewsAnnotation.next(val);
  }

  setSelectedNewsAnnotation(selectedNewsAnnotation: NewsAnnotation) {
    this.selectedNewsAnnotation = selectedNewsAnnotation;
  }

  get selectedTaxonomyDataField(): TaxonomyFieldTypes | string {
    return this._selectedTaxonomyDataField.getValue();
  }

  set selectedTaxonomyDataField(val: TaxonomyFieldTypes | string) {
    this._selectedTaxonomyDataField.next(val);
  }

  setSelectedNewsInstanceField(selectedTaxonomyDataField: TaxonomyFieldTypes | string) {
    this.selectedTaxonomyDataField = selectedTaxonomyDataField;
  }

  get selectedTaxonomyDataContextPeriod(): TaxonomyContextPeriod | string {
    return this._selectedTaxonomyDataContextPeriod.getValue();
  }

  set selectedTaxonomyDataContextPeriod(val: TaxonomyContextPeriod | string) {
    this._selectedTaxonomyDataContextPeriod.next(val);
  }

  setTaxonomyDataContextPeriod(selectedTaxonomyDataContextPeriod: TaxonomyContextPeriod | string) {
    this.selectedTaxonomyDataContextPeriod = selectedTaxonomyDataContextPeriod;
  }

  public updateNewsFieldsValues = (newsInstanceData: InstanceDataUnioned) => {
    const arr = this.utilsService
      .ToArray(TaxonomyFieldTypes)
      .map((field) => {
        if (newsInstanceData.taxonomy_data[field]) {
          const arr = newsInstanceData.taxonomy_data[field].values
            .map((value) => {
              if (value.period === this.selectedTaxonomyDataContextPeriod) {
                return { field: field, values: value.values };
              }
            })
            .filter((val) => val && val.values && val.values.length);
          return arr;
        }
      })
      .filter((e) => e);

    //@ts-ignore
    this.newsFieldsValues = this.utilsService.flatMap(arr, (e) => e);
  };

  onTextHighlight(element, range, htmlViewer: ElementRef, instanceDefinitions: Array<InstanceDefinition>, instanceId: number, rg?: Range, instanceData?: InstanceDataUnioned) {
    const instanceDefinition = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    const { InstanceDataNews, InstanceDocumentAnnotation } = instanceDefinition;

    if (!NewsInstancesService.hasTextContent(rg) || !InstanceDataNews?.taxonomy_data) {
      return;
    }

    const highlightXpath: string = NewsInstancesService.stripLastHtmlNode(
      DocProcessHandlerUtilsService.getXpath(rg.startContainer)
    );
    const highlightXpathFromDocRoot: string = NewsInstancesService.getXpathRelativeToDocumentRoot(highlightXpath);
    const rangeOffsetFromParentElement: RangeOffsetModel = PersistentRange.fromRange(rg).rangeOffset

    const createdAnnotation: NewsAnnotation = this.createNewsAnnotation(InstanceDataNews.taxonomy_data, highlightXpathFromDocRoot, rangeOffsetFromParentElement, rg.toString());
    InstanceDocumentAnnotation.annotations.push(createdAnnotation);
    if (NewsInstancesService.Todo_RenameMeIfYouKnowWhyIDoWhatIDo(highlightXpathFromDocRoot)) {
      return;
    }
    this.handleNewsInstanceAnnotations(true, htmlViewer, instanceDefinitions, instanceId, instanceDefinition.InstanceDataNews.taxonomy_data, InstanceDataNews as any, rg.toString(), true);
    return InstanceDataNews;
  }

  private static Todo_RenameMeIfYouKnowWhyIDoWhatIDo(newsAnnotationOffsetXpath: string) {
    return newsAnnotationOffsetXpath.split('/').findIndex((str) => str.replace(/[\[\]']+/g, '').includes('MARK')) > -1;
  }

  private static getRangeText(rg: Range) {
    return rg.toString().trim();
  }

  private static hasTextContent(rg: Range) {
    return !!NewsInstancesService.getRangeText(rg).length;
  }

  public static getXpathRelativeToDocumentRoot(xpathFromHighlightedElement: string): string {
    return '/html/body/' + (xpathFromHighlightedElement.split('section')[1].split('/').slice(1,).join('/'));
  }

  public static stripLastHtmlNode(xpath: string): string {
    return xpath.split('/').slice(0, -1).join('/');
  }

  handleNewsInstanceAnnotations(
    newInstance = false, // TODO remove this parameter.
    htmlViewer: ElementRef<HTMLDivElement>,
    instanceDefinitions: Array<InstanceDefinition>,
    instanceId: number,
    taxonomyData?: any,
    instanceData?: InstanceDataUnioned,
    annotationTextValue?: string,
    isNewAnnotation?: boolean,
    origInstanceData?: InstanceDataUnioned
  ) {
    if (!htmlViewer) {
      return;
    }

    const {
      InstanceDocumentAnnotation: { annotations },
    } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);

    htmlViewer.nativeElement.innerHTML = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId).InstanceDocument.documentString as string;
    htmlViewer.nativeElement.addEventListener('click', (ev) => {
      if (ev.target['nodeName'] !== 'MARK') {
        this.setSelectedNewsAnnotation(null);
      }
    });

    const parentXpath = DocProcessHandlerUtilsService.getXpath(htmlViewer.nativeElement);

    this.previousTextAnnotations.forEach((annot) => annot && annot.unmark());
    this.previousTextAnnotations = [];

    const didAnnotation$ = new BehaviorSubject(null);
    const redrawAnnotations$ = new BehaviorSubject(null);
    redrawAnnotations$.subscribe((redraw) => {
      if (redraw) {
        this.handleNewsInstanceAnnotations(newInstance, htmlViewer, instanceDefinitions, instanceId, taxonomyData, instanceData, annotationTextValue, isNewAnnotation);
      }
    });
    didAnnotation$.subscribe((data) => {
      if (data) {
        const [didAnnotation, annot_id, isNewAnnotation, annotTextValue, annotFields, annot, node] = data;
        if (didAnnotation) {
          this.updateNewsTaxonomy(taxonomyData, isNewAnnotation ? annotationTextValue : annotTextValue, instanceData, annot_id, isNewAnnotation, annotFields, annot, node, origInstanceData);
        }
      }
    });
    let isOnce = true;
    annotations.forEach((annot, annotIndex) => {
      if (annot) {
        if (!annot.annot_id) {
          annot.annot_id = Math.floor(Math.random() * 1000000 + 1);
        }
        let elementFromXpath = DocProcessHandlerUtilsService.getElementByXpath(annot.spans[0].xref, parentXpath);
        if (annot && annot.fields && annot.fields.findIndex((field) => field.context.period === this.selectedTaxonomyDataContextPeriod) > -1) {
          const textAnnotation = new Mark(elementFromXpath);
          const sub$ = this.sub$;
          const hexToRgbA = this.utilsService.hexToRgbA;
          const options = {
            element: 'mark',
            className: 'highlight',
            each: function (node: HTMLElement, range) {
              node.removeEventListener('click', () => {});
              const textValue = node.innerHTML;
              const annotationText = annot.spans[0].text;
              const index = annotations.findIndex((annot1) => JSON.stringify(annot1) === JSON.stringify(annot));
              node.addEventListener('click', (ev: MouseEvent) => {
                if (index > -1) {
                  sub$.next([annotations[index], node, textValue, instanceId, false]);
                }
              });
              if (annot) {
                if (annot.fields) {
                  node.setAttribute('data-before', annot.fields.map((field) => field.field).join('\n'));
                }
              }

              node.setAttribute('data-highlighted', 'true');
              node.setAttribute('data-index', annotIndex.toString());
              node.setAttribute('data-annotation', JSON.stringify(annot));
              node.setAttribute('data-xref', annot.spans[0].xref);
              if (!isNewAnnotation) {
                didAnnotation$.next([true, annot.annot_id, false, annot.spans[0].text, annot.fields, annot, node]);
              }
              if (isNewAnnotation && isOnce && validateHighlightIsSingleLine(annotations[index], node) && annotIndex === annotations.length - 1) {
                didAnnotation$.next([true, annot.annot_id, isNewAnnotation, annotationText, annot.fields, annot, node]);
                isOnce = false;
              }
              node.style.backgroundColor = annot.fields ? hexToRgbA(NewsTaxonomyDataFieldColors[annot.fields[annot.fields.length - 1].field]) : 'lightgray';
            },
            noMatch: (_) => {
              const annotationIndex = annotations.indexOf(annot);
              if (annotationIndex >= -1) {
                delete annotations[annotationIndex];
                annotations.filter((annot) => annot);
              }
            },
            done: (_) => {
              if (annotIndex === annotations.length - 1) {
                const highlights = this.textHighlighter.getHighlights();
                const annotationsToDelete = highlights
                  .map((high, index) => {
                    const highlight: HTMLElement = high;
                    try {
                      const annotationFromHighlight = JSON.parse(highlight.getAttribute('data-annotation'));
                      if (annotationFromHighlight.spans[0].text && high.innerText && annotationFromHighlight.spans[0].text.replace(/\s/g, '') !== high.innerText.replace(/\s/g, '')) {
                        highlight.style.backgroundColor = 'red';
                        return annotationFromHighlight;
                      }
                    } catch (error) {
                      console.warn(error);
                    }
                  })
                  .filter((e) => e);
                if (annotationsToDelete.length) {
                  annotationsToDelete.forEach((annotation) => {
                    const index = annotations.findIndex((annot) => JSON.stringify(annot) === JSON.stringify(annotation));
                    if (index > -1) {
                      delete annotations[index];
                      redrawAnnotations$.next(true);
                    }
                  });
                }
                Object.keys(TaxonomyFieldTypes).forEach((field) => {
                  if (instanceData.taxonomy_data[TaxonomyFieldTypes[field]]) {
                    const value = instanceData.taxonomy_data[TaxonomyFieldTypes[field]].values.find((val) => val.period === this.selectedTaxonomyDataContextPeriod);
                    const annotations =
                      instanceDefinitions.find((def) => def.InstanceId === instanceData.instance_id) &&
                      instanceDefinitions.find((def) => def.InstanceId === instanceData.instance_id).InstanceDocumentAnnotation;
                    if (annotations) {
                      value.values = value.values
                        .map((val, index) => {
                          const annotationIndex = annotations.annotations.findIndex((annot) => annot && annot.annot_id === val.annot_id);
                          if (annotationIndex > -1) {
                            return val;
                          }
                        })
                        .filter((e) => e);
                    }
                  }
                });
              }
            },
          };
          textAnnotation.markRanges(
            [
              {
                start: annot.spans[0].offsets[0],
                length: annot.spans[0].offsets[1] - annot.spans[0].offsets[0],
              },
            ],
            options
          );
          this.previousTextAnnotations.push(textAnnotation);
        }
      }
    });
    return instanceData;

    function validateHighlightIsSingleLine(annotation: NewsAnnotation, node: HTMLElement): boolean {
      const val_one = node.innerText.trim();
      const val_two = annotation.spans[0].text.trim();
      return val_one === val_two;
    }
  }

  private createNewsAnnotation(taxonomy_data: InstanceDataNewsTaxonomyData, newsAnnotationOffsetXpath: string, range, value): NewsAnnotation {
    const taxonomyDataValue = taxonomy_data[this.selectedTaxonomyDataField].values.find((value) => value.period === this.selectedTaxonomyDataContextPeriod);
    return {
      conf: 1,
      annot_id: Math.floor(Math.random() * 1000000 + 1),
      spans: [
        {
          xref: newsAnnotationOffsetXpath.toLowerCase(),
          offsets: [range.start, range.end],
          text: value,
        },
      ],
      fields: [
        {
          context: {
            period: taxonomyDataValue && taxonomyDataValue.period,
            quantity: taxonomyDataValue && taxonomyDataValue.quantity,
            sign: (taxonomyDataValue && taxonomyDataValue.sign) ?? TaxonomySign.Plus,
          },
          field: this.selectedTaxonomyDataField as string,
        },
      ],
    };
  }

  public selectedAnnotationSub = new Subscription();

  private updateNewsTaxonomy(
    taxonomy_data: InstanceDataNewsTaxonomyData,
    textValue: string,
    instanceDataNews: InstanceDataUnioned,
    annot_id: number,
    isNewAnnotation: boolean,
    annotFields?: any[],
    annot?: NewsAnnotation,
    elm?: HTMLElement,
    origInstanceData?: InstanceDataUnioned
  ) {
    if (annotFields && !isNewAnnotation) {
      annotFields.forEach((field) => {
        const taxonomyValueToChange = taxonomy_data[field.field].values.find((value) => value.period === this.selectedTaxonomyDataContextPeriod);
        if (taxonomyValueToChange) {
          taxonomyValueToChange.value = textValue;
          const value = {
            value: textValue,
            quantity: taxonomyValueToChange.quantity,
            annot_id: annot_id,
          } as any;
          const valueIndexToModify = taxonomyValueToChange.values.findIndex((val) => val.annot_id === annot_id);
          if (valueIndexToModify < 0) {
            if (taxonomyValueToChange.values.find((val) => !val.annot_id)) {
              taxonomyValueToChange.values.splice(
                taxonomyValueToChange.values.findIndex((val) => !val.hasOwnProperty('instance_id')),
                1
              );
            }
            taxonomyValueToChange.values.push(value);
            this.fieldValueIndexes[TaxonomyFieldTypes[field]] = taxonomyValueToChange.values.length - 1;
            taxonomyValueToChange.altered = true;
            taxonomyValueToChange.confirmed = 'Altered';
            taxonomyValueToChange.annot_id = annot_id;
          } else if (!taxonomyValueToChange.values[valueIndexToModify].annot_id) {
            taxonomyValueToChange.values[valueIndexToModify].annot_id = annot_id;
          }
          if (origInstanceData) {
            origInstanceData.taxonomy_data[field.field].values = taxonomy_data[field.field].values;
          }
        }
      });
    } else {
      const taxonomyValueToChange = taxonomy_data[this.selectedTaxonomyDataField].values.find((value) => value.period === this.selectedTaxonomyDataContextPeriod);

      if (taxonomyValueToChange) {
        taxonomyValueToChange.value = textValue;
        const value = {
          value: textValue,
          quantity: taxonomyValueToChange.quantity,
          annot_id: annot_id,
        } as any;
        const valueIndexToModify = taxonomyValueToChange.values.findIndex((val) => val.annot_id === annot_id);
        if (valueIndexToModify < 0) {
          taxonomyValueToChange.values.push(value);
          this.fieldValueIndexes[TaxonomyFieldTypes[this.selectedTaxonomyDataField]] = taxonomyValueToChange.values.length - 1;
          taxonomyValueToChange.altered = true;
          taxonomyValueToChange.confirmed = 'Altered';
          taxonomyValueToChange.annot_id = value.annot_id;
        } else if (!taxonomyValueToChange.values[valueIndexToModify].annot_id) {
          taxonomyValueToChange.values[valueIndexToModify].annot_id = value.annot_id;
        }
        if (origInstanceData) {
          origInstanceData.taxonomy_data[this.selectedTaxonomyDataField].values = taxonomy_data[this.selectedTaxonomyDataField].values;
        }
      }
    }

    this.updateNewsFieldsValues(instanceDataNews as InstanceDataUnioned);
  }

  reset() {
    this.setSelectedNewsAnnotation(null);
    this.setSelectedTaxonomyQuantity(TaxonomyQuantity.Percent);
    this.setTaxonomyDataContextPeriod(this.docProcessService.newsLabelingDefaultPeriod.getValue());
  }

  private subscribeToSelectedAnnotation() {
    this.selectedAnnotationSub = this.sub$.subscribe((data) => {
      if (data) {
        const [annot, element, textValue, instanceId, dbClick] = data;
        this.setSelectedNewsAnnotation(annot);
        const selectedTaxonomy = this.instanceDataNews.taxonomy_data[this.selectedTaxonomyDataField].values.find((value) => value.period === this.selectedTaxonomyDataContextPeriod);
        if (selectedTaxonomy && this.instanceDataNews.instance_id === instanceId && textValue) {
          this.selectedTaxonomy = selectedTaxonomy;
          const taxonomyToEdit = selectedTaxonomy.values.find((tax) => tax.annot_id === annot.annot_id);
          if (!taxonomyToEdit) {
            const value = {
              value: textValue,
              quantity: selectedTaxonomy.quantity,
              annot_id: annot.annot_id,
            } as any;
            const valueIndexToModify = selectedTaxonomy.values.findIndex((val) => val.annot_id !== annot.annot_id);
            if (valueIndexToModify < 0) {
              selectedTaxonomy.values.push(value);
              this.fieldValueIndexes[TaxonomyFieldTypes[this.selectedTaxonomyDataField]] = selectedTaxonomy.values.length - 1;
              selectedTaxonomy.altered = true;
              selectedTaxonomy.confirmed = 'Altered';
              selectedTaxonomy.annot_id = value.annot_id;
            } else if (!selectedTaxonomy.values[valueIndexToModify].annot_id) {
              selectedTaxonomy.values[valueIndexToModify].annot_id = value.annot_id;
            }
            this.selectedAnnotationText = textValue;
          }
        }
        const elm = element as HTMLElement;
        const elements = [].slice.call(document.getElementsByClassName('highlight'));
        elements.forEach((elm: HTMLElement) => elm.classList.remove('outline'));
        elm.classList.add('outline');
      }
    });
  }

}
