<div class="sidebar entity-scroll" [ngClass]="{'expand-sidebar':search}">
    <con-loader *ngIf="!entityDescriptions"></con-loader>
    <div *ngIf="entityDescriptions" class="input-group input-group-search" [ngClass]="{'active':search}">
        <div class="input-group-prepend" (click)="search ? searchEntity(false, $event) : searchEntity(true)" [ngbTooltip]="search ? 'Close Search' : 'Search Entities'" placement="right" container="body">
          <span class="input-group-text"><con-icon-by-name iconName="search"></con-icon-by-name></span>
        </div>
        <input *ngIf="search" type="text" class="form-control" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" #searchBox>
    </div>
    

    <nav class="nav entity-list">
        <ng-container *ngFor="let entityDescription of getEntities()">
            <a (click)="searchEntity(false)" 
                class="nav-link search-entity" 
                *ngIf="profileService.canOr([profileService.getPermissionName(entityDescription.name, 'index'),
                profileService.getPermissionName(entityDescription.name, 'store')])"
                [routerLink]="['/entity', entityDescription.name | snakeCase]" routerLinkActive="active" placement="right"
                [ngbTooltip]="entityDescription.name | prettyCase">
                <con-icon-by-name [iconName]="entityDescription.getIcon()"></con-icon-by-name>
                <span *ngIf="search">{{entityDescription.name | prettyCase}}</span>
            </a>
        </ng-container>
    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
