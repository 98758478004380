import {Component, OnInit} from '@angular/core';
import {AbstractInstanceDataFieldMeta} from "../abstract-instance-data-field-meta.component";

@Component({
  selector: 'con-instance-data-field-wrapper',
  template: `
    <div class="form-inline mb-2 mt-0 w-100 p-1" [ngStyle]="{'background-color': getFieldBackgroundColor() }">
      <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass()" [iconName]="getLowMlConfOrConfrimedFieldIconName()"></con-icon-by-name>
      <label class="mb-1 mr-2 d-none d-sm-block"><span class="badge badge-light">{{fieldName}}</span></label>
      <div class="w-100">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`.checked{
    color: green;
    margin-right: 4px;
  }
  .warning {
    color: orange;
    margin-right: 4px;
  }
  `]
})
export class InstanceDataFieldWrapperComponent extends AbstractInstanceDataFieldMeta implements OnInit {

  constructor() { super() }

  ngOnInit(): void {
  }

}
