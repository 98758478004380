import { Pipe, PipeTransform } from '@angular/core';
import { ProfileService } from 'app/auth/services/profile.service';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'convertToTimezone'
})
export class ConvertToTimezonePipe implements PipeTransform {

  private userTimeZone: string;
  
  constructor(private profileService: ProfileService) {
    this.profileService.getUserTimezone().pipe(take(1)).subscribe((timezone) => {
      this.userTimeZone = timezone || moment.tz.guess();
    })
  }

  transform(utcValue: string|null, format: string|null = 'yyyy-MM-DD HH:mm:ss', timezone: string = this.userTimeZone): string {
    if (!utcValue) {
      return '';
    }
    const date = moment.utc(utcValue);
    return date.tz(timezone).format(format);
  }

}
