
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EntitiesService } from '../../../entities/services/entities.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'con-company',
    templateUrl: './company.component.html'
})

export class CompanyComponent implements OnInit  {
    private loading: boolean;
    public company: any;
    public refreshExternalData: Subject<any> = new Subject<any>()

    constructor(private service: EntitiesService,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.route.params.pipe(
                  switchMap((params: Params) => {
                      this.loading = true;

                      return this.service.getEntityById('Company', params.id);
                  }))
                  .subscribe(company => {
                      this.company = company;
                      this.loading = false;
                  },
                  err => {
                      if (err.status === 404) {
                          this.router.navigate(['static', 'error'], { skipLocationChange: true });
                      }
                      this.loading = false;
                  });
    }

    public isLoading() {
        return this.loading;
    }

    public refreshData() {
        this.refreshExternalData.next();
    }
}
