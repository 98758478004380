import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';

import { StartComponent } from './components/layout/start.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InconsistentLayoutComponent } from './components/layout/inconsistent-layout.component'

import { UnmatchedComponent } from './components/unmatched.component';
import { MatcherComponent } from './components/matcher.component';
import { InconsistentComponent } from './components/inconsistent.component';
import { InconsistentResolverComponent } from './components/inconsistent-resolver.component';

import { CountService } from './services/count.service';

import { EntitiesModule } from '../entities/entities.module';
import { AuthModule } from '../auth/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        EntitiesModule,
        AuthModule,
        RouterModule,
        NgbModule
    ],

    providers: [
        CountService
    ],

    declarations: [
        StartComponent,
        LayoutComponent,
        InconsistentLayoutComponent,
        UnmatchedComponent,
        MatcherComponent,
        InconsistentComponent,
        InconsistentResolverComponent
    ],

    exports: []
})

export class MatchModule {}
