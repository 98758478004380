import { Component, Input } from '@angular/core';

@Component({
  selector: 'con-loader',
  templateUrl: './loader.component.html'
})

export class LoaderComponent {
  @Input() showCircularLoder = true;
  @Input() isSmallLoader = false;
}
