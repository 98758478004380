import { RouterModule, Routes } from '@angular/router';

import { StartComponent } from './components/layout/start.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CompanyComponent } from './components/layout/company.component';
import { InstitutionComponent } from './components/layout/institution.component';
import { CalendarOverviewComponent } from './components/layout/calendar-overview.component';
import { DirektSyncStatusComponent } from './components/direkt-sync-status.component';
import { EarningsLayoutComponent } from './components/layout/earnings-layout.component';
import { EarningsEventsComponent } from './components/earnings-events.component';

export const calendarRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: StartComponent,
                children: [
                    {
                        path: '',
                        component: CalendarOverviewComponent
                    },
                    {
                        path: 'direkt_sync_status',
                        component: DirektSyncStatusComponent
                    },
                    {
                        path: 'earnings',
                        component: EarningsLayoutComponent,
                        children: [
                            { path: 'company/:id', component: EarningsEventsComponent },
                        ]
                    },
                ]
            },
            {
                path: 'company/:id',
                component: CompanyComponent
            },
            {
                path: 'institution/:id',
                component: InstitutionComponent
            }
        ]
    }
];

export const calendarRouting = RouterModule.forChild(calendarRoutes);
