<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Details</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <con-loader *ngIf="loading"></con-loader>
        <div class="col-6 mb-2" *ngIf="!loading">
            <con-pretty-entity-shower
                [entity]="companyTier"
                entityName="CompanyTier"
                ></con-pretty-entity-shower>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('universes')">
                    <div class="d-flex justify-content-start">
                        Universes
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['universes'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['universes']">
                    <con-entity-list
                        entityName="CompanyUniverse"
                        originEntityName="CompanyTier"
                        relation="companyUniverses"
                        [entityId]="companyTierId"
                    ></con-entity-list>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('companies')">
                    <div class="d-flex justify-content-start">
                        Companies
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['companies'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['companies']">
                    <con-tier-companies [tierId]="companyTierId"></con-tier-companies>            
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('ignored_companies')">
                    <div class="d-flex justify-content-start">
                        Ignored Companies
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['ignored_companies'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['ignored_companies']">
                    <con-tier-ignored-companies [tierId]="companyTierId"></con-tier-ignored-companies>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('missing')">
                    <div class="d-flex justify-content-start">
                        Missing Companies
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['missing'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['missing']">
                    <con-tier-missing-companies [tierId]="companyTierId"></con-tier-missing-companies>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('additional')">
                    <div class="d-flex justify-content-start">
                        Additional Companies
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['additional'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['additional']">
                    <con-tier-additional-companies [tierId]="companyTierId"></con-tier-additional-companies>
                </div>
            </div>
        </div>
    </div>
</div>
