import { Component } from '@angular/core';

@Component({
    selector: 'con-lists',
    templateUrl: './lists.component.html'
})

export class ListsComponent {

}
