import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'con-coverage-list',
  templateUrl: './coverage-list.component.html',
  styleUrls: ['./coverage-list.component.scss']
})
export class CoverageListComponent implements OnInit {
  public reloadSubject = new BehaviorSubject(false);
  constructor() { }

  ngOnInit(): void {
  }
  updateListing(data) {
    this.reloadSubject.next(true);
  }

}
