<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
          <div class="page-title pb-3 pt-3 fixed-height-container">
            <div class="missing-title-positioning"><h3 class="mb-0">Missing</h3></div>
            <div class="missing-select-positioning" *ngIf="canShowMissingFilterDropdown()">
              <select [formControl]="missingFilter" (ngModelChange)="onUpdateMissingFilter()" class="form-control custom-select">
                <option value="all">Default</option>
                <option value="">Including Ignored</option>
                <option value="ignored">Only Ignored</option>
              </select>
            </div>
            <con-static-tab (onTabChanged)="onTabSelected($event)"></con-static-tab>
          </div>
        </div>
      </div>
    <div class="mt-15">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-body">
              <div>
                <h5 class="card-title">Filter Missing</h5>
              </div>
              <con-icon-by-name iconName="angle-right" (click)="filterExpanded = !filterExpanded" [mutations]="filterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" class="filter-toggle"></con-icon-by-name>
              <div [ngClass]="filterExpanded ? 'd-block' : 'd-none'">
                <con-filter-static (filterUpdate)="updateFilterConfiguration($event)"></con-filter-static>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <ng-container *ngIf="!loading">
                <con-static-editor
                    *ngFor="let comp of missing?.data"
                    [company]="comp"
                    [tabName]="tabName"
                    [sectors]="sectors"
                    (afterFixed)="whenFixed($event)"
                    (afterUpdate)="updateInList($event)">
                </con-static-editor>
                <con-no-data [count]="missing?.total" [message]="noDataMessage"></con-no-data>
            </ng-container>
            <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!missing?.total}">
                <ngb-pagination [collectionSize]="missing?.total"
                                [page]="missing?.current_page"
                                (pageChange)="loadPage($event)"
                                [pageSize]="missing?.per_page"
                                maxSize="10"
                                [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>
