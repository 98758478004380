<!-- Missing -->
<div *ngIf="isMissingMode() && company.missing">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3><span class="badge badge-dark">{{ company.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="company.manager?.id" [size]="32"></con-avatar>
            {{ company.manager?.first_name }} {{ company.manager?.last_name }}
        </div>
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <div *ngIf="!loading">
        <div *ngFor="let shareholder of company.shareholders; let index = index">
            <div>
                <div class="card mb-2" *ngIf="shareholder.missing">
                    <div class="card-header" (click)="toggleType(shareholder)">
                        <div class="d-flex justify-content-start align-items-center">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Shareholder
                            <div class="card-header-toggle-individual ml-auto">
                                {{shareholder.shareholder_date}}
                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(shareholder)"></con-icon-by-name> 
                            </div>
                        </div>
                    </div>
                    <div class="p-4 col-6" *ngIf="!shareholder.hide">
                        <con-entity-form-content 
                            [fromShareholder]="true"
                            [parentCompany]="{id: company.id, name: company.name}"
                            [entity]="shareholder" 
                            entityName="Shareholder"
                            [preFillValues]="{shareholder_date: shareholder.shareholder_date, source_id: shareholder.source_id, source_type: shareholder.source_type}"
                            [showWhenChanged]="false" 
                            [canDelete]="false"
                            (afterSave)="shareholderUpdated($event, index)"
                            toastMessage="Shareholder added successfully!">
                        </con-entity-form-content>
                    </div>

                    <div class="card-body mb-2" *ngIf="shareholder.id && shareholder.missing && !shareholder.hide">
                        <div class="card-header">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Owners
                        </div>
                        <div class="card-body">
                            <con-entity-form-content-table 
                                [entity]="company" 
                                entityName="Owner"
                                [only]="['name', 'shares', 'capital', 'votes']"
                                [fixedValues]="{ shareholder_id: shareholder.id }" 
                                [showWhenChanged]="false"
                                [canDelete]="true" 
                                [canEdit]="true" 
                                (afterSave)="ownerUpdated($event, index)"
                                [entityList]="shareholder.owners" 
                                toastMessage="Owner added successfully!">
                            </con-entity-form-content-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div>
<div class="row" *ngIf="fromSearch && isMissingMode() && !company.missing">
    <div class="col-6">
        <p class="lead">No missing data!</p>
    </div>
</div>
<!-- Outdated -->
<div *ngIf="isOutdatedMode() && outdatedCompany">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3><span class="badge badge-dark">{{ outdatedCompany.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="outdatedCompany.manager?.id" [size]="32"></con-avatar>
            {{ outdatedCompany.manager?.first_name }} {{ outdatedCompany.manager?.last_name }}
        </div>
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <div *ngIf="!loading">
        <div *ngFor="let shareholder of outdatedCompany.shareholders; let index = index">
            <div>
                <div class="card mb-2">
                    <div class="card-header" (click)="toggleType(shareholder)">
                        <div class="d-flex justify-content-start align-items-center">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Shareholder
                            <div class="card-header-toggle-individual ml-auto">
                                {{shareholder.shareholder_date}}
                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(shareholder)"></con-icon-by-name> 
                            </div>
                        </div>
                    </div>
                    <div class="p-4 col-6" *ngIf="!shareholder.hide">
                        <con-entity-form-content 
                            [fromShareholder]="true"
                            [parentCompany]="{id: outdatedCompany.id, name: outdatedCompany.name}"
                            [entity]="shareholder" 
                            entityName="Shareholder"
                            [preFillValues]="{shareholder_date: shareholder.shareholder_date, source_id: shareholder.source_id, source_type: shareholder.source_type}"
                            [showWhenChanged]="false" 
                            [canDelete]="false"
                            (afterSave)="shareholderUpdated($event, index)"
                            toastMessage="Shareholder added successfully!">
                        </con-entity-form-content>
                    </div>
                    <div class="card-body mb-2" *ngIf="shareholder && shareholder.id && !shareholder.hide">
                        <div class="card-header">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Owners
                        </div>
                        <div class="card-body">
                            <con-entity-form-content-table 
                                [entity]="outdatedCompany" 
                                entityName="Owner"
                                [only]="['name', 'shares', 'capital', 'votes']"
                                [fixedValues]="{ shareholder_id: shareholder.id }" 
                                [showWhenChanged]="false"
                                [canDelete]="true" [canEdit]="true" 
                                (afterSave)="ownerUpdated($event, index)"
                                [entityList]="shareholder.owners" 
                                toastMessage="Owner added successfully!">
                            </con-entity-form-content-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div>
<div class="row" *ngIf="fromSearch && isOutdatedMode() && !company.outdated">
    <div class="col-6">
        <p class="lead">No outdated data!</p>
    </div>
</div>
<!-- Review -->
<div *ngIf="isReviewMode()">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3><span class="badge badge-dark">{{ company.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="company.manager.id" [size]="32"></con-avatar>
            {{ company.manager?.first_name }} {{ company.manager?.last_name }}
        </div>
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <div *ngIf="!loading">
        <div *ngFor="let shareholder of company.shareholders; let index = index">
            <div *ngIf="shareholder.id">
                <div class="card mb-2">
                    <div class="card-header" (click)="toggleType(shareholder)">
                        <div class="d-flex justify-content-start align-items-center">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Shareholder
                            <div class="card-header-toggle-individual ml-auto">
                                {{shareholder.shareholder_date}}
                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(shareholder)"></con-icon-by-name> 
                            </div>
                        </div>
                    </div>
                    <div class="p-4 col-6" *ngIf="!shareholder.hide">
                        <con-entity-form-content 
                            [fromShareholder]="true"
                            [parentCompany]="{id: company.id, name: company.name}"
                            [entity]="shareholder" 
                            entityName="Shareholder"
                            [showWhenChanged]="false" 
                            [canDelete]="false"
                            [newTab]="true" 
                            (afterSave)="shareholderUpdated($event, index)"
                            toastMessage="Shareholder updated successfully!">
                        </con-entity-form-content>
                    </div>
                    <div class="card-body mb-2" *ngIf="!shareholder.hide">
                        <div class="card-header">
                            <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Owners
                        </div>
                        <div class="card-body">
                            <con-entity-form-content-table [entity]="company" entityName="Owner"
                                [only]="['name', 'shares', 'capital', 'votes']"
                                [fixedValues]="{ shareholder_id: shareholder.id }" 
                                [showWhenChanged]="false"
                                [canDelete]="true" 
                                [canEdit]="true" 
                                (afterSave)="ownerUpdated($event, index)"
                                [entityList]="shareholder.owners" 
                                toastMessage="Owner added successfully!">
                            </con-entity-form-content-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div>
<div class="row" *ngIf="fromSearch && isReviewMode() && !hasValidShareholder()">
    <div class="col-6">
        <p class="lead">No data!</p>
    </div>
</div>