import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { StaticSettings, MissingCompanyEventsSettings } from '../../settings.class';

import { Observable, ReplaySubject ,  Subject } from 'rxjs';
import * as moment from 'moment';
import { DateConverterService } from 'app/shared/services/date-converter.service';

@Injectable()
export class CountService {
    private countsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
    private countRequest: Observable<any>;
    public reloadSubject = new Subject();
    public loadingCompleted = false;
    private loading = false;
    private counts: any;
    private fullMissingCompanyCount: number

    constructor(
        private http: HttpClient,
        private dateConverterService: DateConverterService
    ) {
        this.getCounts().subscribe(counts => {
            this.counts = counts;
        });
    }

    public decrementCount(type: string) {
        this.counts[type]--;
        if(type === 'companies')
            this.fullMissingCompanyCount--;
        this.countsSubject.next(this.counts);
    }

    public setCount(type: string, count: number) {
        this.loadingCompleted = true;
        if (this.counts && this.counts[type] !== count) {
            this.counts[type] = count;
            this.countsSubject.next(this.counts);
        }
    }

    getCounts(refresh: boolean = false): Observable<any> {
        const params = new HttpParams()
            .set('from_date',this.dateConverterService.toEntityString(MissingCompanyEventsSettings.MISSING_EVENTS_DEFAULT_FROM_DATE))
            .set('to_date', this.dateConverterService.toEntityString(MissingCompanyEventsSettings.MISSING_EVENTS_DEFAULT_TO_DATE));
        if (!this.countRequest || refresh) {
            this.loading = true;
            this.countRequest = this.http.get(MissingCompanyEventsSettings.BASE_URL + '/' + MissingCompanyEventsSettings.COUNTS_ENDPOINT, { params: params });

            this.countRequest.subscribe(res => {
                                            this.countsSubject.next(res);
                                            this.loading = false;
                                            this.fullMissingCompanyCount = res.companies;
                                        },
                                        err => {
                                            this.countsSubject.error(err);
                                            this.loading = false;
                                        })
        }
        return this.countsSubject.asObservable();
    }

    getMissingEventsCount(refresh: boolean = false): Observable<any> {
        if (!this.countRequest || refresh) {
            this.loading = true;
            this.countRequest = this.http.get(StaticSettings.BASE_URL + '/' + StaticSettings.COUNTS_ENDPOINT);

            this.countRequest.subscribe(res => {
                                            this.countsSubject.next(res);
                                            this.loading = false;
                                        },
                                        err => {
                                            this.countsSubject.error(err);
                                            this.loading = false;
                                        })
        }
        return this.countsSubject.asObservable();
    }

    isLoading() {
        return this.loading;
    }

    resetToDefaultCounts() {
        this.counts['companies'] = this.fullMissingCompanyCount;
        this.countsSubject.next(this.counts);
    }


}
