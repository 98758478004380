<div class="sidebar">
    <con-loader *ngIf="loading"></con-loader>
    <nav class="nav flex-column" *ngIf="!loading">
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['deliveries']" routerLinkActive="active" (click)="selectedType('Deliveries')">
                Deliveries
                <span class="badge badge-pill badge-danger">{{ counts['deliveries'] }}</span>
            </a>
        </div>
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['production']" routerLinkActive="active"
            (click)="selectedType('Production')">
                Production
                <span class="badge badge-pill badge-danger">{{ counts['production'] }}</span>
            </a>
        </div>
    </nav>
</div>
<div class="content no-scroll">
    <router-outlet></router-outlet>
</div>
