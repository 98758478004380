import {Component, HostListener, Input, OnChanges, OnInit} from '@angular/core';
import {InstanceDataTopicClassification, TopicClassificationField} from '../models/Typings';
import {DocProcessLegacyCommonsService} from '../services/doc-process-legacy-commons.service';
import {UtilService} from '../../doc-process-common/services/util.service';

@Component({
  selector: 'con-topic-classification-taxonomy',
  template: `
    <ng-container
      *ngFor="let field of instanceData.taxonomy_data.classes; let fieldIndex = index;"
    >
      <ng-container *ngIf="writeAccess || field.value === true">
        <con-icon-by-name [iconName]="'long-arrow-right'" [hidden]="fieldIndex !== highlightedFieldIndex"
                          class="float-left" style="color: black;"></con-icon-by-name>
        <div
          [ngClass]="{'d-inline-flex': !writeAccess}"
          class="{{writeAccess ? getTaxonomyFieldLeftPaddingCssClass(field) : ''}}"
          class="border-bottom"
        >
          <div class="container-fluid p-0 pl-3 m-0" [ngClass]="{'d-inline-flex': !writeAccess}">
            <button type="button"
                    [ngClass]="{'btn-block': writeAccess}"
                    [class]="getButtonClass(field)"
                    class=' text-start btn py-0 pl-0'
                    [disabled]="(!isSelectable(field)) && field.value === false"
                    style="{{(getDepthOfClass(field) >= 2) ? 'font-weight:400;' : 'font-weight:600;'}}"
                    (click)="writeAccess ? onTaxonomyFieldClick(fieldIndex) : stopPropagation($event)">
              <span class="pull-left" style="text-align: left">
                {{field?.title}}
              </span>
              <con-icon-by-name [iconName]="'check-square'" [hidden]="field?.value === false || !writeAccess"
                                class="float-right"
                                style="color: white;"></con-icon-by-name>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class TopicClassificationTaxonomyComponent implements OnInit, OnChanges {

  @Input() instanceData: InstanceDataTopicClassification;
  @Input() highlightedFieldIndex: number;
  @Input() writeAccess: boolean;

  get highlightedField(): TopicClassificationField {
    return this.getHighlightedField();
  }

  stopPropagation($event){
    $event.stopPropagation()
  }

  constructor(
    private utilService: UtilService,
  ) {
  }

  ngOnInit(): void {
  }

  public ngOnChanges() {
    this.highlightedFieldIndex = this.instanceData.taxonomy_data.classes.length;
    this.focusFirstAvailableField()
  }

  public getTaxonomyFieldLeftPaddingCssClass(field: TopicClassificationField): string {
    let cssClass: string;

    const levelInClassTree: number = this.getDepthOfClass(field);
    const leftPadding = (levelInClassTree === 0) ? 0 : levelInClassTree + 3;
    cssClass = `pl-${leftPadding}`;

    return cssClass;
  }

  private getHighlightedField(): TopicClassificationField {
    return this.getField(this.highlightedFieldIndex);
  }

  private getField(fieldIndex: number): TopicClassificationField {
    return this.instanceData.taxonomy_data.classes[fieldIndex];
  }

  public onTaxonomyFieldClick(fieldIndex: number): void {
    this.toggleMarkForField(fieldIndex);
    this.updateHighlightedFieldIndex(fieldIndex);
  }

  // warning: recursive
  public getDepthOfClass(field: TopicClassificationField): number {
    if (field.parent_field === null) {
      return 0;
    }

    const parentField = this.getParentField(field)
    return 1 + this.getDepthOfClass(parentField)
  }

  public getButtonClass(field: TopicClassificationField): string {
    let buttonColorClass: string;
    if (field.value === true || ((!this.isSelectable(field)) && !this.isMultiClass())) {
      buttonColorClass = 'btn-info';
    }
    else if (field.suggestion === true) {
      buttonColorClass = 'btn-warning';
    }
    else {
      buttonColorClass = 'btn-default';
    }
    return buttonColorClass;
  }

  public isSelectable(field: TopicClassificationField): boolean {
    if (this.hasMarkedParent(field) || this.hasMarkedChild(field))
      return false;
    if (this.isMultiClass() && this.markedFieldCount() >= 1)
      return false
    else
      return true;
  }

  public hasMarkedChild(field: TopicClassificationField): boolean {
    let isAChildSelected = false
    this.getChildrenOfField(field).forEach(subField => {
      isAChildSelected = isAChildSelected || subField?.value || this.hasMarkedChild(subField)
    })
    return isAChildSelected;
  }

  public hasMarkedParent(field: TopicClassificationField) {
    if (!field?.parent_field) return false;
    let parentField = this.getParentField(field);
    let isAParentSelected = parentField?.value || this.hasMarkedParent(parentField);
    return isAParentSelected;
  }

  private getChildrenOfField(field: TopicClassificationField): Array<TopicClassificationField> {
    return this.instanceData.taxonomy_data.classes.filter(eachField => eachField.parent_field === field.field);
  }

  private getParentField(field: TopicClassificationField): TopicClassificationField {
    if (!field || !(field?.parent_field))
      return null;
    return this.instanceData.taxonomy_data.classes.find(eachField => eachField?.field === field.parent_field);
  }

  private toggleMarkForField(fieldIndex: number): void {
    const field = this.getField(fieldIndex)
    field.value = !field.value;
  }

  private updateHighlightedFieldIndex(fieldIndex: number): void {
    this.highlightedFieldIndex = fieldIndex;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    if (ev.key === "Enter") {
      ev.preventDefault()
      ev.stopPropagation()
      this.highlightedField.value = !this.highlightedField.value;
    }
    else if (ev.shiftKey && ev.key === "Tab") {
      ev.preventDefault()
      ev.stopPropagation()
      this.highlightNextField( {reverse: true} );
    }
    else if (ev.key === "Tab") {
      ev.preventDefault()
      ev.stopPropagation()
      this.highlightNextField();
    }
    else if (ev.key === "Alt") {
      ev.preventDefault()
      ev.stopPropagation()
      do {
        this.highlightNextField();
      } while (this.getDepthOfClass(this.highlightedField) != 1);
    }
  }

  private highlightNextField(order ?: {reverse: boolean}): void {
    do {
      this.highlightedFieldIndex = this.getNextFieldIndex(order);
    } while ((this.isSelectable(this.getHighlightedField()) === false) && this.highlightedField.value === false);
  }

  private getNextFieldIndex(order ?: {reverse: boolean}): number {
    return this.utilService.modulo(this.highlightedFieldIndex + (!!order?.reverse ? -1 : 1), this.instanceData.taxonomy_data.classes.length);
  }

  public focusFirstAvailableField(): void {
    this.highlightNextField( {reverse: true} )
    this.highlightNextField()
  }

  private markedFieldCount(): number {
    return this.instanceData.taxonomy_data.classes.filter((field: TopicClassificationField) => field.value === true).length
  }

  private isMultiClass(): boolean {
    return this.instanceData.taxonomy_data?.classification_type === "multiclass"
  }
}
