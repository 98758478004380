<div class="nav-link d-flex align-items-center" *ngIf="loading">
    <con-loader *ngIf="loading"></con-loader>
</div>
<div class="nav-link d-flex align-items-center px-1" *ngIf="!loading && hasUser">
    <div class="chip">
        <con-avatar [userId]="user?.id" [size]="32"></con-avatar>
        {{ user?.first_name }} {{ user?.last_name }}
    </div>
</div>
<a class="nav-link d-flex align-items-center settings" *ngIf="!loading && hasUser" [routerLink]="['/settings']" routerLinkActive="active" placement="bottom" ngbTooltip="Settings">
    <con-icon-by-name iconName="cog" [mutations]="['lg','fw']"></con-icon-by-name>
</a>
<a class="nav-link d-flex align-items-center logout" *ngIf="!loading && hasUser" (click)="logout()">
    <con-icon-by-name iconName="power-off" [mutations]="['lg','fw']"></con-icon-by-name>
</a>
