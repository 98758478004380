import { Component } from '@angular/core';

@Component({
    selector: 'con-not-allowed-alert',
    templateUrl: './not-allowed-alert.component.html'
})

export class NotAllowedAlertComponent {

}
