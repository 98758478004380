<div class="form-inline">
    <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Select server</span></label>
    <select class="custom-select" type="text" (change)="setServer($event.target.value)">
        <option
          *ngFor="let opt of serverOptions;"
          [ngValue]="opt"
          [selected]="opt.toLowerCase() === serverChooserService.getBackendEnvName()"
        >
          {{opt}}
        </option>
      <option disabled hidden [defaultSelected]="true"></option>
    </select>
</div>
