import { Injectable } from '@angular/core';

import { ReplaySubject ,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { MatchSettings } from '../../settings.class';

@Injectable()
export class CountService {
    private counts: any = {
        UNMATCHED: 0
    }
    private loading = false;
    private counts$: ReplaySubject<any> = new ReplaySubject<any>(1);
    private countsRequest$: Observable<any>;
    private hasRequested = false;

    constructor(private http: HttpClient) {}

    public incrementCount(type: string, number: number = 1) {
        if (this.counts[type]) {
            this.counts[type] += number;
            this.counts$.next(this.counts);
        }
    }

    public decrementCount(type: string, number: number = 1) {
        return this.incrementCount(type, -1 * number);
    }

    public getCounts(refresh: boolean = true) {
        if (!this.countsRequest$ || refresh) {
            this.loading = true;
            this.countsRequest$ = this.http.get(MatchSettings.BASE_URL + '/' + MatchSettings.COUNTS_ENDPOINT);

            this.countsRequest$.subscribe(
                res => {
                    this.counts = res;
                    this.counts$.next(res);
                    this.loading = false;
                },
                err => {
                    this.counts$.error(err);
                    this.loading = false;
                }
            );
        }

        return this.counts$.asObservable();
    }

    public getUnmatchedCount(refresh: boolean = false) {
        return this.getCounts(refresh)
                    .pipe(map(counts => counts['UNMATCHED']));
    }
}
