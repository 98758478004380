import { Component, Input } from '@angular/core';

@Component({
    selector: 'con-json-difference',
    templateUrl: './json-difference.component.html'
})

export class JsonDifferenceComponent {
    @Input() object1: any;
    @Input() object2: any;

    private diff = '';
}
