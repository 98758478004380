<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
          <div class="page-title pb-3 pt-3">
            <h3 class="mb-0">Revisions</h3>
            <con-static-tab (onTabChanged)="onTabSelected($event)"></con-static-tab>
          </div>
        </div>
      </div>
    <div class="mt-15">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3">
            <div class="card-body">
              <div>
                <h5 class="card-title">Filter Revision</h5>
              </div>
              <con-icon-by-name iconName="angle-right" (click)="filterExpanded = !filterExpanded" [mutations]="filterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" class="filter-toggle"></con-icon-by-name>
              <div [ngClass]="filterExpanded ? 'd-block' : 'd-none'">
                <con-filter-static (filterUpdate)="updateFilterConfiguration($event)"></con-filter-static>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <ng-container *ngIf="!loading">
                <con-static-editor
                    *ngFor="let comp of revisions?.data"
                    mode="revision"
                    [tabName]="tabName"
                    [company]="comp"
                    [sectors]='sectors'
                    (afterFixed)="whenFixed($event)"
                    (afterUpdate)="updateInList($event)">
                </con-static-editor>
                <con-no-data [count]="revisions?.total" [message]="noDataMessage"></con-no-data>
            </ng-container>
            <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!revisions?.total}">
                <ngb-pagination [collectionSize]="revisions?.total"
                                [page]="revisions?.current_page"
                                (pageChange)="loadPage($event)"
                                [pageSize]="revisions?.per_page"
                                maxSize="10"
                                [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>
