import { Instance } from '../../kpi-and-kiid/models/Typings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Task {
  // TODO rename me to DataEntryTask or something like that
  project_id: number;
  project_name: string;
  task_id: number;
  user_assigned: string;
  current_fields: string[];
  instances: Instance[];
  show_page?: number;
  document_batch_size: number;
  instance_data_component_width: number;
}

export function taskInstancesToDocIds(taskInstances: Task): string {
  const serialized: string = taskInstances?.instances.reduce((a: string, b: Instance) => a + b.doc_id + ',', '');
  return serialized.substring(0, serialized.length - 1); // it removes comma at the end
}

export function taskInstancesToInstanceIds(taskInstances: Task): string {
  const serialized: string = taskInstances?.instances.reduce((a: string, b: Instance) => a + b.instance_id + ',', '');
  return serialized.substring(0, serialized.length - 1); // it removes comma at the end
}

export function taskInstancesToInstanceIds$(taskInstances$: Observable<Task>): Observable<string> {
  return taskInstances$.pipe(
    map((taskInstances: Task) => {
      const taskInstancesString: string = taskInstancesToInstanceIds(taskInstances);
      return taskInstancesString;
    })
  );
}
