import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DocProcessHandlerUtilsService { // TODO merge into util service?

    public static getXpath(node): string {
        let comp = null
        const comps = [];
        let parent = null;
        let xpath = '';
        const getPos = (node) => {
            let position = 1
            let curNode = null;
            if (node.nodeType == Node.ATTRIBUTE_NODE) {
                return null;
            }
            for (curNode = node.previousSibling; curNode; curNode = curNode.previousSibling) {
                if (curNode.nodeName == node.nodeName) {
                    ++position;
                }
            }
            return position;
         }

        if (node instanceof Document) {
            return '/';
        }
        for (; node && !(node instanceof Document); node = node.nodeType == Node.ATTRIBUTE_NODE ? node.ownerElement : node.parentNode) {
            comp = comps[comps.length] = {};
            switch (node.nodeType) {
              case Node.ELEMENT_NODE:
                comp.name = node.localName;
                break;
            case Node.ATTRIBUTE_NODE:
                comp.name = '@' + node.nodeName;
                break;
            case Node.TEXT_NODE:
            case Node.CDATA_SECTION_NODE:
                comp.name = 'text()';
                break;
            case Node.PROCESSING_INSTRUCTION_NODE:
                comp.name = 'processing-instruction()';
                break;
            case Node.COMMENT_NODE:
                comp.name = 'comment()';
                break;
            case Node.DOCUMENT_NODE:
                comp.name = '';
                break;
            default:
                comp.name = '';
                break;
            }
            comp.position = getPos(node);
        }
        for (let i = comps.length - 1; i >= 0; i--) {
            comp = comps[i];
            comp.name === "mark" && (comp.name = 'text()')
            xpath += '/' + comp.name;
            if (comp.position != null) {
                xpath += '[' + comp.position + ']';
            }
        }

        return xpath;
      }
      public static getElementByXpath(path: string, parentXPath?: string) {
        if(!parentXPath) {
          try {
            const elm = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);

            return elm.singleNodeValue
          } catch (error) {
            console.warn(error)
          }
        }
        const xpath = `${parentXPath}/${path.split('/').splice(3, path.split('/').length -1).join('/')}`
        try {
          const elm = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
          return elm.singleNodeValue
        } catch (error) {
          console.warn(error)
        }
      }
}
