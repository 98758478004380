import { Component, Input, EventEmitter, Output, OnChanges, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';

import { EntitiesService, EntityDescription } from '../../services/entities.service';

import { snakeCase } from 'change-case';

@Component({
    selector: 'con-pretty-entity-shower',
    templateUrl: './pretty-entity-shower.component.html'
})

export class PrettyEntityShowerComponent implements OnChanges {
    @Input() entity: any;
    @Input() entityName: string;
    @Input() actions: any;
    @Input() newTab = false;
    @Input() fromDetailPage: boolean;
    @Input() redirectButton = {
      show: false,
      label: '',
      link: ''
    };
    @Output() onAction: EventEmitter<any> = new EventEmitter<any>();

    private snakeCase = snakeCase;
    public loading: boolean;
    public entityDescription: EntityDescription;

    public constructor(private router: Router,
                       private entitiesService: EntitiesService
                       ) {}

    ngOnChanges() {
        this.loading = true;
        this.entitiesService
            .getEntityDescriptionByEntityName(this.entityName)
            .subscribe(entityDescription => {
                this.entityDescription = entityDescription;
                if (this.entityName === 'Shareholder') {
                    this.entityDescription.data.fields.forEach(field => {
                        if (field.key === 'source_type') {
                            field.show_in_table = true;
                        }
                    });
                }
                this.loading = false;
            });
    }

    public doAction(action) {
        this.onAction.emit({ action, entity: this.entity });
    }
    public redirectTo(name, id) {
        if (this.newTab) {
            const url = '/entity/' + snakeCase(name) + '/' + id;
            window.open(url, '_blank');
        } else {
            this.router.navigate(['entity', snakeCase(name), id])
        }
    }
    public showGotoButton() {
        return !this.fromDetailPage
    }
}
