export enum MLSendStatus {
      CREATED = 'CREATED',
      IN_PROGRESS ='IN_PROGRESS',
      SUBMITTED = 'SUBMITTED',
      CLOSED = 'CLOSED',
      FAILED = 'FAILED',
      CREATED_MESSAGE = 'Already sent to ML',
      IN_PROGRESS_MESSAGE = 'Under ML review',
      SUBMITTED_MESSAGE = 'ML processed data received',
      CLOSED_MESSAGE = 'ML processing completed',
      FAILED_MESSAGE = 'ML processing failed'
}
