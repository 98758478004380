import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'con-confirmation-popup',
  templateUrl: './confirmation-popup.component.html'
})
export class ConfirmationPopupComponent {
  @Input() modalData: { title: string, text: string, subText?: string};
  @Input() buttonLabels: { confirm: string, cancel: string };
  constructor(public activeModal: NgbActiveModal) { }
}
