import {RouterModule, Routes} from '@angular/router';
import {CompanyReportsComponent} from './layout/company-reports/company-reports.component';
import {NewReportComponent} from './component/new-report/new-report.component';
import {NewReportFileRelationComponent} from './component/new-report-file-relation/new-report-file-relation.component';
import {PageNotFoundComponent} from 'app/page-not-found.component';
import {StartComponent} from './layout/start/start.component';
import {CompanyCheckComponent} from './component/company-check/company-check.component';
import {CompanyReportEventsComponent} from './component/company-report-events/company-report-events.component';
import {CompanyStatusComponent} from './component/company-status/company-status.component';
import {CompanyReportComponent} from './component/company-report/company-report.component';
import {MissingReportEventsComponent} from './component/missing-report-events/missing-report-events.component';
import {ReportEventsStartComponent} from './component/report-events-start/report-events-start.component';
import {AddNewCompanyReportComponent} from './component/add-new-company-report/add-new-company-report.component';
export const companyReportsRoutes: Routes = [
  {
    path: '',
    component: CompanyReportsComponent,
    data: {menu: 'MainSideMenu'},
    children: [
      {
        path: '',
        component: StartComponent
      },
      {
        path: 'report',
        component: CompanyReportComponent
      },
      {
        path: 'check',
        component: CompanyCheckComponent
      },
      {
        path: 'report-events',
        component: ReportEventsStartComponent,
        children: [
          {
            path: '',
            component: CompanyReportEventsComponent,
          },
          {
            path: 'company/:id',
            component: MissingReportEventsComponent
          },
        ]
      },
      {
        path: 'status',
        component: CompanyStatusComponent
      },
      {
        path: 'company/:id',
        component: NewReportComponent,
        data: {menu: 'CompanyReportSideMenu'}
      },
      {
        path: ':companyId/report/:id',
        component: NewReportFileRelationComponent,
        data: {menu: 'CompanyReportFilesSideMenu'},
        canActivate: [],
        children: [
          {
            path: ':relation',
            component: NewReportFileRelationComponent
          }
        ]
      },
    ],
    canActivate: []
  },
  {
    path: 'error',
    component: PageNotFoundComponent
  }
];
export const companyReportsRouting = RouterModule.forChild(companyReportsRoutes);
