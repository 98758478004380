<div *ngIf="company">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar *ngIf="company.manager?.id" [userId]="company?.manager?.id" [size]="32"></con-avatar>
            {{managerName}}
        </div>
    </div>

    <div class="card mb-2" *ngIf="showEditor">
        <div class="card-header">
            <con-icon-by-name iconName="hashtag" [mutations]="['fw']"></con-icon-by-name> {{header}}
        </div>
        <div class="card-body">
            <con-entity-form-content entityName="ShareCount" [entity]="company" [preFillValues]="preFillValues" [only]="['valid_from','share_count']" [fixedValues]="fixedValues" [showWhenChanged]="false" [canDelete]="false" (afterSave)="shareCountUpdated($event)"
                toastMessage="Share count updated successfully!">

            </con-entity-form-content>
            <br>
            <div class="card mb-2" *ngIf="fixedValues && displayEntityList">

                <div class="card-header cursor-pointer" role="tab" (click)="toggleShareCountHistory= !toggleShareCountHistory">
                    <div class="d-flex justify-content-start">
                        <con-icon-by-name iconName="hashtag" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        Share Counts
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggleShareCountHistory ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="toggleShareCountHistory">
                    <con-entity-list entityName="ShareCount" originEntityName="company" relation="shareCounts" [entityId]="fixedValues.company_id">

                    </con-entity-list>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!showEditor">
        <div class="col-12">
            <div class="alert no-data">Nothing to display!</div>
        </div>

    </div>
</div>