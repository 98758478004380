<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="paperclip"></con-icon-by-name>
        </div>

        <h3 class="mb-0">Company Reports</h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">Go to company</div>

        <div class="card-body">
          <con-entity-search-in-relation
            parentEntityName="CompanyTier"
            [parentId]="tierId"
            relationName="companies"
            [disabled]="
              !profile.can(
                profile.getPermissionName('CompanyTier', 'show', 'companies')
              )
            "
            (onSelect)="navigateToCompany($event)"
          ></con-entity-search-in-relation>
        </div>

        
        <div class="recent-search" *ngIf="recentList.length>0">
            <h4>Recent Searches:</h4>
            <div>
                <div class="chip" *ngFor="let company of recentList" (click)="navigateToCompany(company)">
                {{ company.name }}
            <i class="fa fa-close" (click)="removeFromList(company)"></i>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
