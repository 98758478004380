import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'con-ml-directory-preview',
  templateUrl: './ml-directory-preview.component.html',
  styleUrls: ['./ml-directory-preview.component.scss']
})
export class MlDirectoryPreviewComponent implements OnInit, OnChanges {
  @Input() displayMLPreviewUrl;
  @Output() closePreviewFile = new EventEmitter<boolean>();
  loading = false;
  constructor() { }

  ngOnInit(): void {
  }

  contentLoaded() {
    this.loading = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const currentDisplayUrl = changes.displayMLPreviewUrl;
    if(!currentDisplayUrl.firstChange && (currentDisplayUrl.previousValue !== currentDisplayUrl.currentValue)) {
      this.loading = true;
    }
  }

  close() {
    this.closePreviewFile.emit(true);
  }
}
