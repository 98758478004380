<ng-container [ngSwitch]="selectedProjectService.project">
  <doc-process-annotation-view-component *ngSwitchCase="DocProcessProjectName.PrNewsLabeling"></doc-process-annotation-view-component>
  <doc-process-annotation-view-component *ngSwitchCase="DocProcessProjectName.Kiid"></doc-process-annotation-view-component>
  <doc-process-annotation-view-component *ngSwitchCase="DocProcessProjectName.KiidPriip"></doc-process-annotation-view-component>
  <doc-process-annotation-view-component *ngSwitchCase="DocProcessProjectName.Factsheet"></doc-process-annotation-view-component>
  <doc-process-annotation-view-component *ngSwitchCase="DocProcessProjectName.KiidMetadata"></doc-process-annotation-view-component>
  <dp-data-entry-page *ngSwitchCase="DocProcessProjectName.TopicClassification"></dp-data-entry-page>
  <dp-data-entry-page *ngSwitchCase="DocProcessProjectName.Calendar"></dp-data-entry-page>
  <dp-data-entry-page *ngSwitchCase="DocProcessProjectName.IncomingReports"></dp-data-entry-page>
  <dp-data-entry-page *ngSwitchCase="DocProcessProjectName.FundamentalsPageLabeling"></dp-data-entry-page>
  <dp-data-entry-page *ngSwitchCase="DocProcessProjectName.PrNewsLabelingV2"></dp-data-entry-page>
</ng-container>
