<div *ngIf="!instanceDefinitions" class="d-flex justify-content-start align-content-center align-items-center">
  No matching data!
</div>

<div *ngIf="instanceDefinitions" style="overflow-x: hidden;" #root (keyup)="handlePageMoving($event);">
  <div (keyup)="handlePageMoving($event);">
    <div>
      <!--HEADER-->
      <div class="p-0 my-2 col-12 d-flex justify-content-between align-items-center align-content-center">
        <div class="d-flex">

          <!-- go back button -->
          <button (click)="onGoBackButtonClick()"
                  class="d-flex justify-content-start align-content-center align-items-center btn btn-primary">
            <con-icon-by-name class="mr-3" iconName="long-arrow-left"></con-icon-by-name>
            Go back
          </button>

          <!-- confirm button -->
          <button (click)="onConfirmButtonClick()"
                  class="d-flex ml-2 justify-content-start align-content-center align-items-center btn btn-success">
            Save Selected
            <con-icon-by-name class="ml-3" iconName="check"></con-icon-by-name>
          </button>

          <!-- zoom slider -->
          <div class="zoom-container">
            <con-icon-by-name class="mr-2 d-flex align-items-center" (click)="lowerZoom()"
                              iconName="search-minus"></con-icon-by-name>
            <input id="test" min="1" max="20" [value]='this.utilService.zoomScale' step="1"
                   (change)="zoomChange($event)" type="range"/>
            <con-icon-by-name class="ml-2 d-flex align-items-center" (click)="increaseZoom()"
                              iconName="search-plus"></con-icon-by-name>
          </div>

          <con-loader-small *ngIf="updatingInstanceData"></con-loader-small>
        </div>

        <div style="color: black;" class="counter d-flex pr-2">
          <div class="counter-input">
            Page {{pageIndex + 1}}
          </div>
          <div class="counter-length">
            of {{instanceDefinitions.length}}
          </div>
        </div>
      </div>
      <!-- END Header -->

      <!-- left sidebar -->
      <div class="row">
        <div class="col-2 pr-0">
          <!-- left sidebar -->
          <div class="card" id="instanceCard" #instancesContainer style="height: 90vh; overflow-y: auto;">
            <div class="card-header">Instances</div>
            <div class="card-body p-0">
              <con-material-table
                #instanceNavigationTable
                [isClickable]="true"
                [isSelectable]="true"
                [clickableColumns]="[0, 1]"
                [selectableColumns]="[0]"
                [data]="instanceTableInfo"
                [fieldClasses]="fieldClasses"
                [columnsToHide]="['']"
                (onClick)="onInstanceSelect($event);"
                (onSelectedAll)="onSelectAllInstances($event)"
                (onSelected)="onSwitchInstance($event)">
              ></con-material-table>
            </div>
          </div>
        </div>
        <!-- doc contents -->
        <div class="col-5">
          <div class="card" id="viewer" #pdfViewer style="min-height: 90vh; max-height: 90vh; overflow-y: scroll;">

            <!-- fetch failed alert -->
            <ngb-alert
              type="danger"
              [dismissible]="false"
              *ngIf="failedToFetchDocumentFeedback"
            >
              {{failedToFetchDocumentFeedback}}
            </ngb-alert>

            <!-- loading info -->
            <div #animContainer *ngIf="fetchingData || (!documentString && !documentSafeResourceUrl)"
                 class="animation-container d-flex justify-content-center align-content-center align-items-center">
              Fetching data...
              <con-loader></con-loader>
            </div>

            <!-- document contents -->
            <ng-container *ngIf="selectedProjectService.project" [ngSwitch]="true">
              <!-- PrNewsLabeling -->
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.PrNewsLabeling">
                <ng-container *ngTemplateOutlet="htmlDocumentPdfViewerWrapper"></ng-container>
              </ng-container>
              <!-- Kiid -->
              <ng-container *ngSwitchCase="selectedProjectService.isProjectTypeKiidLike()">
                <ng-container *ngTemplateOutlet="kiidDocumentPdfViewerWrapper"></ng-container>
              </ng-container>
              <!-- Topic Classification -->
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.TopicClassification">
                <ng-container *ngIf="documentSafeResourceUrl$">
                  <ng-container *ngTemplateOutlet="kiidDocumentPdfViewerWrapper"></ng-container>
                </ng-container>
                <ng-container *ngTemplateOutlet="htmlDocumentPdfViewerWrapper"></ng-container>
              </ng-container>
              <!-- Calendar -->
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.Calendar">
                <ng-container *ngIf="documentSafeResourceUrl$">
                  <ng-container *ngTemplateOutlet="kiidDocumentPdfViewerWrapper"></ng-container>
                </ng-container>
                <ng-container *ngTemplateOutlet="htmlDocumentPdfViewerWrapper"></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <ng-template #kiidDocumentPdfViewerWrapper>
          <con-pdf-viewer-wrapper
            [documentSafeResourceUrl$]="documentSafeResourceUrl$"
            #kiidDocumentPdfViewer
            [id]="'kiidDocumentPdfViewer'"
            *ngIf="!fetchingData"
          ></con-pdf-viewer-wrapper>
        </ng-template>

        <ng-template #htmlDocumentPdfViewerWrapper>
          <section
            #htmlViewer
            *ngIf="documentString"
            id="sandbox"
            class="content"
            style="padding: 12px"
          ></section>
        </ng-template>

        <!-- right sidebar -->
        <div class="col-5">
          <div class="card" style="height: 90vh; overflow-y: auto;">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div class="mr-3">
                  Instance data
                </div>
              </div>
            </div>

            <!-- right taxonomy view -->
            <div *ngIf="selectedProjectService.project && !!instanceData" [ngSwitch]="true">
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.PrNewsLabeling">
                <con-news-instances-handler #newsInstancesHandlerReference [instanceDataAnnotations]="annotations"
                                            [instanceDataNews]="instanceData"
                                            (onClearAllAnnotations)="onClearAllAnnotationsClick()"></con-news-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.Kiid">
                <con-kiid-instances-handler [instanceData]="instanceData"
                                            [currentFields]="currentFields"></con-kiid-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.KiidPriip">
                <con-kiid-priip-instances-handler [instanceData]="instanceData"
                                            [currentFields]="currentFields"></con-kiid-priip-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.Factsheet">
                <con-kiid-factsheet-instances-handler [instanceData]="instanceData"
                                            [currentFields]="currentFields"></con-kiid-factsheet-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.KiidMetadata">
                <con-kiid-metadata-instances-handler [instanceData]="instanceData"
                                            [currentFields]="currentFields"></con-kiid-metadata-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.TopicClassification">
                <con-topic-classification-instances-handler [instanceData]="instanceData">
                </con-topic-classification-instances-handler>
              </ng-container>
              <ng-container *ngSwitchCase="selectedProjectService.project === currentProject.Calendar">
                <con-topic-classification-instances-handler [instanceData]="instanceData">
                </con-topic-classification-instances-handler>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- saveConfirmationModal -->
<ng-template #saveConfirmationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Sanity validation <b>failed!</b></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="mb-0">
      The sanity validation detected at least one possible inconsistency in tagged values, quantities and signs.
      <br><br>
      <b>Are you sure you checked annotations marked with a warning sign and want to submit?</b>
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="modal.close('Save click')">Save</button>
    <button class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
  </div>
</ng-template>

<con-clear-data-modal #clearDataConfirmationModal (approve)="clearAllAnnotations()"></con-clear-data-modal>
