import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'con-not-authorized-modal',
    templateUrl: './not-authorized-modal.component.html'
})

export class NotAuthorizedModalComponent {
    constructor(public activeModal: NgbActiveModal) {}
}
