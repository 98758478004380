import { RouterModule, Routes } from '@angular/router';

import { StaticComponent } from './layout/static.component';
import { StaticRevisionComponent } from './layout/static-revision.component';
import { StaticMissingComponent } from './layout/static-missing.component';
import { StaticStartComponent } from './layout/static-start.component';
import { StaticCompanyComponent } from './layout/static-company.component';

import { PageNotFoundComponent } from '../page-not-found.component';
import { NotAuthorizedComponent } from '../not-authorized.component';

export const staticRoutes: Routes = [
    {
        path: '',
        component: StaticComponent,
        canActivate: [],
        children: [
            {
                path: '',
                component: StaticStartComponent
            }, {
                path: 'error',
                component: PageNotFoundComponent
            }, {
                path: 'auth',
                component: NotAuthorizedComponent
            }, {
                path: 'missing',
                component: StaticMissingComponent
            }, {
                path: 'revisions',
                component: StaticRevisionComponent
            }, {
                path: ':id',
                component: StaticCompanyComponent
            }
        ]
    }
];

export const staticRouting = RouterModule.forChild(staticRoutes);
