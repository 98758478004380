import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { Task, EmailMapping, TaskDetail } from 'app/task-manager/typings/Typings';

@Component({
  selector: 'con-email-mapping-view',
  templateUrl: './email-mapping-view.component.html',
})
export class EmailMappingViewComponent implements OnInit, OnDestroy {

    public task: Task;
    public step = 0;
    public steps: number[] = [];
    public loading = true;
    public confCopy: any;
    public errorMessage: string;
    public emailMapping: EmailMapping;
    public actionSucceeded = false;
    public emailMappingCopy: any;
    public feedbackFromApiResponse: string;
    public showFeedbackFromApiAlert = false;

    @Input() taskDetail: TaskDetail;
    @Input() checkPassed: boolean;
    @Input() checkLoading: boolean;

    @Output() actionHandler = new EventEmitter();
    @Output() loadingAnimationHandler = new EventEmitter();

    @ViewChild('responseElm') responseElm: ElementRef;

    constructor( public taskManagerService: TaskManagerService, private subscriptionService: SubscriptionService, private utilService: UtilService) {}

    ngOnInit(): void {
        this.taskManagerService.getEmailMapping(this.taskDetail.task_id)
        this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: any) => {
            switch (response.type) {
                case 'getEmailMappingPlaceholder': {
                    this.setEmailMapping(response.data);
                    this.stopAnimation('approved');
                } break;
                case 'getEmailMapping': {
                    this.setEmailMapping(response.data);
                    this.stopAnimation('approved');
                } break;
                case 'testEmailMapping': {
                    if (this.utilService.isJsonString(response.data)) {
                        this.handleResponseStateMessage(response.data);
                    } else {
                        this.showHTMLResponseFromAPI(response.data);
                        this.stopAnimation('approved');
                    }
                } break;
                case 'setEmailMapping': {
                    this.handleResponseStateMessage(response.data);
                } break;
                case 'removeEmailMapping': {
                    this.handleResponseStateMessage(response.data)
                }
            }
        });
        this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: any) => {
            this.actionHandler.emit('failed');
            this.errorMessage = err.data;
            this.feedbackFromApiResponse = err.data.Traceback
        })
    }

    ngOnDestroy(): void {
        this.subscriptionService.apiErrorSub.unsubscribe();
        this.subscriptionService.apiResponseSub.unsubscribe();
    }

    requestAction(action: string) {
        this.responseElm.nativeElement.innerHTML = '';
        this.startAnimation();
        this.actionSucceeded = false;
        this.feedbackFromApiResponse = '';
        this.showFeedbackFromApiAlert = false;
        switch (action) {
            case 'getEmailMappingPlaceholder': return this.taskManagerService.getEmailMappingPlaceholder(this.taskDetail.task_id);
            case 'getEmailMapping': return this.taskManagerService.getEmailMapping(this.taskDetail.task_id);
            case 'testEmailMapping': return this.taskManagerService.testEmailMapping(this.taskDetail.task_id, this.emailMappingCopy);
            case 'setEmailMapping': return this.taskManagerService.setEmailMapping(this.taskDetail.task_id, this.emailMappingCopy);
            case 'removeEmailMapping': return this.taskManagerService.removeEmailMapping(this.taskDetail.task_id);
        }
    }

    onConfigChange(config) {
        this.emailMappingCopy = config
    }

    private handleResponseStateMessage(response: any) {
        this.showFeedbackFromApiAlert = true;
        const STATE = this.utilService.isJsonString(response) ? JSON.parse(response).State : response.State
        switch (STATE) {
            case 'Error': {
                this.stopAnimation('failed');
                const EXCEPTION_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Exception : response.Exception;
                this.actionSucceeded = false;
                this.feedbackFromApiResponse = EXCEPTION_MESSAGE;
            }; break;
            case 'Success': {
                this.stopAnimation('approved');
                const RESULT_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Result : response.Result;
                this.actionSucceeded = true;
                this.feedbackFromApiResponse = RESULT_MESSAGE;
            } break;
            default: {
                this.showHTMLResponseFromAPI(response);
            }
        }
    }

    private startAnimation() {
        this.loadingAnimationHandler.emit('start');
    }

    private stopAnimation(state: string) {
        this.actionHandler.emit(state);
        this.loadingAnimationHandler.emit('stop');
    }

    private setEmailMapping(emailMapping: EmailMapping) {
        this.emailMapping = emailMapping;
        this.emailMappingCopy = emailMapping;
    }

    private showHTMLResponseFromAPI(responseContainer: string) {
        this.responseElm.nativeElement.innerHTML = responseContainer;
    }

}
