import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityConfirmModalComponent } from '../entities/components/views/entity-confirm-modal.component';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApiSettings } from '../settings.class';

@Component({
  selector: 'con-delta-deliveries',
  templateUrl: './delta-deliveries.component.html'
})
export class DeltaDeliveriesComponent {
  public loading = false;
  constructor(private modalService: NgbModal, private http: HttpClient, private toaster: ToastrService,) {
  }

  createDeltaDelivery() {
    const modalRef = this.modalService.open(EntityConfirmModalComponent,
      {
        size: 'md'
      });

    modalRef.componentInstance.modalData = {
      title: "Confirm delta delivery creation",
      message: 'Are you sure you want to create delta delivery manually?',
      alert: false
    };
    modalRef.result.then((result) => {
      this.loading = true;
      this.http.post(ApiSettings.BASE_URL + '/delta_deliveries/create', {}).subscribe((response) => {
        this.loading = false;
        this.toaster.success('Delta delivery created successfully!');
      }, (error) => {
        this.loading = false;
        this.toaster.error('Sorry, some error occurred!');
        console.error(error);
      })
    });
  }
}
