import { Component, Input, OnInit } from '@angular/core';
import { FundamentalsAnnotation, InstanceDocument } from '../../kpi-and-kiid/models/fields';
import { DataEntryTaxonomyDetails } from '../models/data-entry-taxonomy-details';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { ComponentOptionsService } from '../services/component-options.service';

@Component({
  selector: 'dp-dynamic-data-entry-component-wrapper',
  providers: [ComponentOptionsService],
  template: `
    <!--component list-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.TabComponents || component.component_type === DynamicDataEntryComponentTypeEnum.ListOfComponents">
      <con-dynamic-data-entry-component-container [component]="component"></con-dynamic-data-entry-component-container>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.DateSelectorComponent">
      <con-dynamic-data-entry-date-input-component [component]="component"></con-dynamic-data-entry-date-input-component>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.IntegerSelectorComponent">
      <con-dynamic-data-entry-integer-input [component]="component"></con-dynamic-data-entry-integer-input>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.ConnectorCurrencySelectorComponent">
      <con-dynamic-data-entry-currency-input [component]="component"></con-dynamic-data-entry-currency-input>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.CheckboxComponent">
      <con-dynamic-data-entry-checkbox-input-component [component]="component"></con-dynamic-data-entry-checkbox-input-component>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.LabelingComponent">
      <con-dynamic-data-entry-labeling-input [component]="component" [isComponentTabActive]="isComponentTabActive"></con-dynamic-data-entry-labeling-input>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.LanguageSelectorComponent">
      <con-dynamic-data-entry-language-selector-component [component]="component"></con-dynamic-data-entry-language-selector-component>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.SingleHorizontalSelectorComponent">
      <con-dynamic-single-horizontal-selector [component]="component"></con-dynamic-single-horizontal-selector>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.MultiHorizontalSelectorComponent">
      <con-dynamic-multiple-horizontal-selector [component]="component"></con-dynamic-multiple-horizontal-selector>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.MulticlassTaxonomySelector">
      <con-dynamic-data-entry-multiclass-taxonomy-selector [component]="component"></con-dynamic-data-entry-multiclass-taxonomy-selector>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.CompanyCalendarWidget">
      <con-calendar-events-widget [component]="component"></con-calendar-events-widget>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.ConnectorRedirectButton">
      <con-connector-redirect-button [component]="component"></con-connector-redirect-button>
    </ng-container>

    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.ConnectorIframe">
        <con-company-iframe></con-company-iframe>
    </ng-container>

    <!--if horizontal group (like buttons)-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.RowOfComponents">        
        <div class="d-flex flex-row">
            <div *ngFor="let eachComponent of component.components">
                <label *ngIf="component.options?.display_title">
                    <small>{{ eachComponent.title }}</small>
                </label>
                <dp-dynamic-data-entry-component-wrapper class="flex-column" [component]="eachComponent"></dp-dynamic-data-entry-component-wrapper>
            </div>
        </div>
    </ng-container>
  `,
})
export class DynamicDataEntryComponentWrapper implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  @Input() activeMenuTabId: string;

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum;
  }

  get isComponentTabActive() {
    return this.component.title === this.activeMenuTabId;
  }

  constructor(private componentOptionsService: ComponentOptionsService) {}

  ngOnInit(): void {
    this.componentOptionsService.componentOptions.next(this.component.options);
    this.componentOptionsService.setComponent(this.component);
  }
}
