import { RouterModule, Routes } from '@angular/router';
import { EstimatesStartComponent } from './layout/estimates-start.component';
import { EstimatesLayoutComponent } from './layout/estimates-layout.component';
import { NewSurveyComponent } from './component/new-survey/new-survey.component';
import { SurveyDetailMainComponent } from './component/survey-detail-main/survey-detail-main.component';
import { PageNotFoundComponent } from '../page-not-found.component';
import { CoverageListComponent } from './component/coverage-list/coverage-list.component';

export const estimatesRoutes: Routes = [
  {
    path: '',
    component: EstimatesLayoutComponent,
    data: {menu: 'MainSideMenu'},
    children: [
      {
        path: '',
        component: EstimatesStartComponent
      },
      {
        path: 'coverage-list',
        component: CoverageListComponent
      },
      {
        path: 'company/:id',
        component: NewSurveyComponent,
        data: {menu: 'SurveySideMenu'}
      },
      {
        path: ':companyId/estimate/:id',
        component: SurveyDetailMainComponent,
        data: {menu: 'SurveyDetailsSideMenu'},
        canActivate: [],
        children: [
          {
            path: ':relation',
            component: SurveyDetailMainComponent
          }
        ]
      },
    ],
    canActivate: []
  },
  {
    path: 'error',
    component: PageNotFoundComponent
  }
];
export const estimateRouting = RouterModule.forChild(estimatesRoutes);
