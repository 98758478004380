import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'con-company-reports',
  templateUrl: './company-reports.component.html'
})
export class CompanyReportsComponent {

  showMainSideMenu = false;
  showCompanyReportSideMenu = false;
  showCompanyReportFilesSideMenu = false;

  constructor() {

  }

  onActivate($event: any) {
    if ($event.route && $event.route.hasOwnProperty('data')) {
      $event.route.data.subscribe((routeData: any) => {
        if (routeData.hasOwnProperty('menu')) {

          this.resetMenuViewDisplay();
          if (routeData.menu === 'MainSideMenu') {
            this.showMainSideMenu = true;
          }
          if (routeData.menu === 'CompanyReportSideMenu') {
            this.showCompanyReportSideMenu = true;
          }
          if (routeData.menu === 'CompanyReportFilesSideMenu') {
            this.showCompanyReportFilesSideMenu = true;
          }
        }
      })
    } else {
      this.resetMenuViewDisplay();
      this.showMainSideMenu = true;
    }

  }

  resetMenuViewDisplay(): void {
    this.showMainSideMenu = false;
    this.showCompanyReportSideMenu = false;
    this.showCompanyReportFilesSideMenu = false;
  }
}
