<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="isLoading()"></con-loader>
            <div class="page-title mb-2 pb-3 pt-3" *ngIf="!isLoading() && company && company.id">
                <h3 class="mb-0">{{ company.name }}</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="!isLoading() && company && company.id">
            <con-missing-events [company]="company" [ownerType]="'Company'" (refreshList)="refreshData()"></con-missing-events>
            <div class="mt-2">
                <con-confirm-events [company]="company" [ownerType]="'Company'" (refreshList)="refreshData()"></con-confirm-events>
            </div>
            <div class="mt-2">
                <con-unconfirmed-historical-events [company]="company" [ownerType]="'Company'" (refreshList)="refreshData()"></con-unconfirmed-historical-events>
            </div>
            <div class="card mt-2">
                <div class="card-header">
                    All events
                </div>
                <con-entity-list originEntityName="Company" relation="events" entityName="CalendarEvent" [refreshExternalData]="refreshExternalData" [entityId]="company.id"></con-entity-list>
            </div>
        </div>
    </div>
</div>