<div class="card mb-2" *ngIf="showForm()">
  <div class="card-header">
    <con-icon-by-name iconName="institution" [mutations]="['fw']"></con-icon-by-name>Sector
  </div>
  <div class="card-body">
    <form [formGroup]="form">
      <div class="row form-group w-500">
        <div class="col">
          <label><small>Infront L1 Sector</small></label>
          <select formControlName="infront_sector_l1" class='form-control custom-select'>
            <option [ngValue]=null>Select</option>
            <option *ngFor="let sector of sectors" [value]="sector.id">{{ sector.name }}</option>
          </select>
        </div>
        <div class="col">
          <label><small>Infront L2 Sector</small></label>
          <select formControlName="infront_sector_l2" class='form-control custom-select'>
            <option [ngValue]=null>Select</option>
            <option *ngFor="let sector of subsectors" [value]="sector.id">{{ sector.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <button (click)='saveSectors()' class='btn btn-success' [disabled]="!form.get('infront_sector_l1').value">Save</button>
          <button class="btn btn-secondary" (click)="toggleForm()" *ngIf="this.company.infront_sector_l1_id !== null">
            <con-icon-by-name iconName="remove"></con-icon-by-name> Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- edit mode -->

<div class="card mb-2" *ngIf="!showForm()">
  <div class="card-header">
    <con-icon-by-name iconName="institution"></con-icon-by-name>Sector
  </div>
  <div class="card-body">
    <div class="alert alert-info">
      <strong>Infront L1 Sector : {{getCompanySector()?.l1_name}}</strong> <br/>
      <strong>Infront L2 Sector : {{getCompanySector()?.l2_name}}</strong>
    </div>
    <button class="btn btn-info" (click)="toggleForm()" *ngIf="profile.can(profile.getPermissionName('Company', 'update'))">
      <con-icon-by-name iconName="pencil"></con-icon-by-name> Edit
    </button>
  </div>
</div>
