import { Component, OnInit } from '@angular/core';
import { CountService } from '../services/count.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'con-missing-events',
    templateUrl: './missing-events.component.html'
})

export class MissingEventsComponent implements OnInit {
    public loading = true;
    public counts: any = {};
    public selected: string;
    constructor(private countService: CountService,
                private router: Router,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.loading = true;
        this.countService.getCounts().subscribe(counts => {
            this.counts = counts;
            this.loading = false;
        });
    }
    public loadPage(type: string, event: any) {
        event.stopPropagation();
        this.countService.reloadSubject.next(type);
    }
    public selectedType (type: string) {
        this.selected = type;
    }
    public showRefreshButton(type: string) {
        const keyword = type.toLocaleLowerCase();
        if (this.countService.loadingCompleted && this.router.url.indexOf(keyword) > -1) {
            return true;
        } else {
            return false;
        }
    }
}
