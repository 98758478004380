<div class="modal-header">
    <h5 class="modal-title">Do you want to proceed?</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('abort')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0">
        This object has been updated by <con-avatar [userId]="object1.updated_by" [size]="32"></con-avatar> {{ user?.first_name }} {{ user?.last_name }} since you loaded it.<br><em>Review the changes below and decide if you want to proceed or abort.</em>
    </p>
    <con-loader *ngIf="loading"></con-loader>
    <small><con-avatar [userId]="object1.updated_by" [size]="32"></con-avatar> {{ user?.first_name }} {{ user?.last_name }}'s version:</small>
    <con-json-difference [object1]="object1" [object2]="object2"></con-json-difference>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="activeModal.close('proceed')">Proceed</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('abort')">Abort</button>
</div>
