import {Component, Input, OnInit} from '@angular/core';
import {DataEntryComponent} from '../data-entry/data-entry.component';

@Component({
  selector: 'con-multiple-choice-data-entry',
  template: `
    <div class="d-flex px-1 w-100 align-items-center justify-content-start flex-wrap">
      <button
        *ngFor="let choice of multipleChoiceOptionsList; let i = index"
        style="border:none;"
        [class]="(instanceData[fieldName].value === choice ? 'btn-primary' : 'btn-disabled') + ' my-1 mr-1 px-1 py-1 cursor-pointer col'"
        (click)="onOptionClick($event.target, choice)"
        [value]="choice"
      >
        {{ multipleChoiceDisplayValuesList?.[i] ?? choice }}
      </button>
    </div>
  `,
})
export class MultipleChoiceDataEntryComponent extends DataEntryComponent implements OnInit  {
  @Input() multipleChoiceOptionsList: Array<string | number> = []
  @Input() multipleChoiceDisplayValuesList: any[] = []

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

  onOptionClick(target: EventTarget, choice: string | number) {
    if (this.instanceData[this.fieldName].value === choice)
      this.onValueChange(target, null)
    else
      this.onValueChange(target, choice)
  }
}
