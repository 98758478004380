import { RouterModule, Routes } from '@angular/router';
import { TaskManagerLayoutComponent } from './layout/task-manager-layout.component';
import { TasksViewComponent } from './views/tasks-view/tasks-view.component';
import { CreateTaskViewComponent } from './views/create-task-view/create-task-view.component';
import { TaskDetailViewComponent } from './views/task-detail-view/task-detail-view.component';
import { DeliveriesViewComponent } from './views/deliveries-view/deliveries-view.component';
import { DeliveryDetailViewComponent } from './views/delivery-detail-view/delivery-detail-view.component';
import { SymbolsViewComponent } from './views/symbols-view/symbols-view.component';

export const taskManagerRoutes: Routes = [
    {
        path: '',
        component: TaskManagerLayoutComponent,
        children: [
            {
                path: 'tasks',
                component: TasksViewComponent,

            },
            {
                path: 'tasks/:id',
                component: TaskDetailViewComponent
            },
            {
                path: 'create',
                component: CreateTaskViewComponent
            },
            {
                path: 'symbols',
                component: SymbolsViewComponent
            },
            {
                path: 'deliveries',
                component: DeliveriesViewComponent
            },
            {
                path: 'deliveries/:id',
                component: DeliveryDetailViewComponent
            },
        ]
    }
];

export const taskManagerRouting = RouterModule.forChild(taskManagerRoutes);
