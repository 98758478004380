import { Injectable } from '@angular/core';
import { ApiSettings, StaticSettings } from 'app/settings.class';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StaticService {
  private missingFilter: string;
  constructor(private http: HttpClient) {
    this.missingFilter = 'all';
  }

  getMissingFilter(): string {
    return this.missingFilter;
  }

  setMissingFilter(filterStatus: string) {
    this.missingFilter = filterStatus;
  }

  getEndpoint(tabName: any, page: string) {
    let baseUrl = ''
    if (page === 'static') {
        baseUrl = StaticSettings.BASE_URL + '/' + StaticSettings.MISSING_ENDPOINT ;
    } else {
        baseUrl = StaticSettings.BASE_URL + '/' + StaticSettings.REVISIONS_ENDPOINT ;
    }
    switch (tabName) {
        case 'positions':
            return baseUrl + '/' + StaticSettings.POSITIONS_ENDPOINT
        case 'advisors':
            return baseUrl + '/' + StaticSettings.ADVISORS_ENDPOINT
        case 'company data':
            return baseUrl + '/' + StaticSettings.COMPANY_DATA_ENDPOINT
        case 'description':
            return baseUrl + '/' + StaticSettings.DESCRIPTIONS_ENDPOINT
        case 'all':
        default:
            return baseUrl
    }
  }
  getSectorEndpoint() {
    return this.http.get<any[]>(ApiSettings.BASE_URL + '/' + StaticSettings.SECTOR_ENDPOINT);
  }
  updateSectorsOfCompany(patchCompanyData: any) {
    return this.http.patch(ApiSettings.BASE_URL + '/' + StaticSettings.COMPANY_ENDPOINT + '/' + patchCompanyData.companyId, {
      id: patchCompanyData.companyId,
      infront_sector_l1_id: patchCompanyData.infront_sector_l1_id,
      infront_sector_l2_id: patchCompanyData.infront_sector_l2_id,
      updated_at: patchCompanyData.updated_at,
      revised_at: patchCompanyData.revised_at
    });
  }
}
