import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ListRadioSelectionComponent} from '../../../shared/components/list-radio-selection.component';
import {HttpClient} from '@angular/common/http';
import {CompanyReportsService} from '../../../company-reports/services/company-reports.service';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ApiSettings, CompanyReportsSettings} from '../../../settings.class';
import {EntitiesService} from '../../services/entities.service';

@Component({
  selector: 'con-report-file-editor',
  templateUrl: './report-file-editor.component.html',
  styleUrls: ['./report-file-editor.component.scss']
})
export class ReportFileEditorComponent implements OnInit {
  @Input() context: string = 'ADD_REPORT_FILE';
  @Input() companyReportFile: any = null;
  @Output() afterSave: any = new EventEmitter<any>();
  isMLDirectoryMode = false;
  prefillValues = null;
  fixedValues = null;
  reportFileFormObject = null;
  entity: any = {}
  expanded = true;
  selectedFileFromMLMode = null;
  selectedMLDirectoryFile = null;
  _reload = false;
  editModeTitle = '';
  showLoader = false;
  CompanyReportFile = 'CompanyReportFile';
  CompanyReport = 'CompanyReport';
  mlData = null;
  previewMLReportUrl = '';
  constructor(private modalService: NgbModal, private http: HttpClient,
              private toast: ToastrService,
              public entitiesService: EntitiesService,
              public companyReportService: CompanyReportsService) {
  }

  ngOnInit(): void {
    if(this.context === 'REPORT_DETAIL_REPORT_FILE') {
      this.fixedValues = {
        company_report_id: this.companyReportFile.id
      }
      this.setCompanyReport(this.companyReportFile);
    }
    if(this.context === 'EDIT_REPORT_FILE'){
      this.fixedValues = {
        company_report_id: this.companyReportFile.company_report_id
      }
      this.http.get(ApiSettings.BASE_URL + '/company/'+ this.companyReportFile.company_report.company_id).subscribe((response: any)=>{
        this.editModeTitle = (response.name as string)  + ', ' + this.companyReportFile.company_report.report_type + ', ' + this.companyReportFile.company_report.report_date;
      })
    }
   }

  redirectToEntity(savedData: any) {
    const afterSaveObject = {
      data: savedData,
      context: this.context
    }
    this.afterSave.emit(afterSaveObject);
    if(this.context === 'REPORT_DETAIL_REPORT_FILE' && this.isMLDirectoryMode) {
      this.selectedMLDirectoryFile = null;
    }
  }

  setCompanyReport(companyReport: any) {
    this.selectedFileFromMLMode = companyReport;
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

  toggleMode() {
    this.isMLDirectoryMode = !this.isMLDirectoryMode;
    this.previewMLReportUrl = '';
    if(this.context === 'EDIT_REPORT_FILE' && this.isMLDirectoryMode){
      this.selectedFileFromMLMode = this.companyReportFile.company_report;
      this.selectedMLDirectoryFile = this.companyReportFile;
      this.entity = this.companyReportFile;
      this.prefillValues = {
        label: this.entity.label,
        type: this.entity.type,
        language_id: this.entity.language_id,
        company_report_id: this.entity.company_report_id
      }

    }
  }

  openMLDirectory() {
    this.mlData = null;
    this.previewMLReportUrl = '';
    if(this.selectedFileFromMLMode) {

      const mlDirectoryUrlParams = {
        company_id :this.selectedFileFromMLMode.company_id,
        params: {
          is_annual: this.selectedFileFromMLMode.report_type === 'annual',
          report_date:this.selectedFileFromMLMode.report_date
        }
      }
      this.showLoader = true;
      this.http.get(CompanyReportsSettings.BASE_URL + `/ml_directory_files/${mlDirectoryUrlParams.company_id}`, {params: mlDirectoryUrlParams.params}).subscribe((data) => {
        this.showLoader = false;
        if(!(data as Array<any>).length){
          this.toast.error('No Data Available');
          return;
        }
        const mapData: any = {
          data: []
        };
        mapData.data = data;
        mapData.keys = ['doc_format','doc_id','doc_title','doc_type'];
        this.mlData = mapData;
      }, (error)=> {
        this.showLoader = false;
        this.toast.error('Could not load ML Directory.');
      })

    }
  }
  updateFileMlSelection(docFromMLFileSelection) {

    this.showLoader = true;
    this.mlData = null;

    let entity: any = {};

    if(docFromMLFileSelection?.doc_language){
      this.http.get(ApiSettings.BASE_URL + `/language?q:iso639_1=${docFromMLFileSelection.doc_language}&page=1&order_by=updated_at`).pipe(take(1)).subscribe((response: any) => {
        const docLanguage = (response.data as Array<any>)[0];
        entity.label = docFromMLFileSelection?.doc_title;
        entity.type = (docFromMLFileSelection?.doc_type as string)?.toLocaleLowerCase().replace('_' , ' ');
        if(!!entity.label === false) {
          entity.label = '';
        }
        entity.language_id = docLanguage.id;
        entity.language =  docLanguage;
        entity.company_report_id = this.selectedFileFromMLMode.id;
        entity.company_report = this.selectedFileFromMLMode;
        if(this.context === 'ADD_REPORT_FILE'){
          entity.id = -1;
        }
        if(this.context === 'EDIT_REPORT_FILE'){
          entity.id = this.companyReportFile.id;
        }
        this.http.get(CompanyReportsSettings.BASE_URL + `/ml_directory_file/${docFromMLFileSelection.doc_id}?doc_format=${docFromMLFileSelection.doc_format}`).subscribe((responseData: any) => {
          entity.source = responseData.file_name;
          this.entity = entity;
          this.prefillValues = {
            label: entity.label,
            source: entity.source,
            type: entity.type,
            language_id: entity.language_id,
            company_report_id: entity.company_report_id
          }
          this._reload = true;
          this.showLoader = false;
          setTimeout(()=> {
            this._reload = false;
          }, 1)
        }, (error) => {
          this.showLoader = false;
          this.toast.error('Could not fetch ML data')
        })
      },(error) => {
        this.showLoader = false;
        this.toast.error('Error getting language data')
      })
    } else {
      this.showLoader = false;
      this.toast.error('Error processing doc language')
    }
    this.selectedMLDirectoryFile = {};

  }

  getFormattedCompanyReport(): string {
    return this.selectedFileFromMLMode?.company?.name  + ', ' + this.selectedFileFromMLMode?.report_type + ', ' + this.selectedFileFromMLMode?.report_date;
  }

  changeCompanyReport() {
    this.selectedFileFromMLMode = null;
    this.mlData = null;
    this.selectedMLDirectoryFile = null;
  }

  previewUrl(data: any) {
    this.previewMLReportUrl = data + '#toolbar=0&navpanes=0&scrollbar=0';
  }
  setPreviewMLReportUrlEmpty(closeEvent: boolean) {
    if(closeEvent) {
      this.previewMLReportUrl = null;
    }
  }
}
