<div class="content settings-page">
    <div class="container-fluid mb-3">
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <div class="page-title-icon">
                        <con-icon-by-name iconName="cog"></con-icon-by-name>
                    </div>
                    <h3 class="mb-0">Settings</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card mb-2">
                    <div class="card-header">
                        Toolbar
                    </div>
                    <div class="card-body" dragula="DRAGULA_VIEWS" id="toolbar" [(dragulaModel)]="toolbarViews">
                        <ng-container *ngFor="let view of toolbarViews">
                            <div class="chip chip-link"
                                 *ngIf="profile.can('view-' + view.name.toLowerCase())">
                                <con-icon-by-name [iconName]="view.icon"></con-icon-by-name> {{ view.name }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        Available views
                    </div>
                    <div class="card-body" dragula="DRAGULA_VIEWS" id="views" [(dragulaModel)]="views">
                        <ng-container *ngFor="let view of views">
                            <div class="chip chip-link"
                                 *ngIf="profile.can('view-' + view.name.toLowerCase()) && !layout.viewIsInToolbar(view.name)">
                                <con-icon-by-name [iconName]="view.icon"></con-icon-by-name> {{ view.name }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
