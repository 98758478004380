import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { StaticSettings, ApiSettings } from '../settings.class';
import { CountService } from '../static/services/count.service';

@Component ({
    selector: 'con-customers-webhook',
    templateUrl: './customer.component.html'
})
export class CustomerComponent implements OnInit {
    public loading = true;
    public customer: any;

    constructor(private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private countService: CountService) {}

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        this.loadPage(1, id);

    }

    loadPage(pageNumber: number, id: number) {
        let url = ApiSettings.BASE_URL + '/' + ApiSettings.WEBHOOK_CUSTOMERS_ENDPOINT;
        if (id) {
            url += '/' + id;
        }
        this.http.get<any>(url,
                {
                    params: {
                        page: String(pageNumber)
                    }
                })
                 .subscribe(res => {
                     this.countService.setCount('customers', res.total);
                     this.customer = res;
                     this.loading = false;
                 }, err => {
                     if (err.status === ApiSettings.RESPONSE_CODES.PERMISSION_ERROR) {
                        this.router.navigate(['powers', 'auth'], { skipLocationChange: true });
                     } else {
                        this.router.navigate(['powers', 'error'], { skipLocationChange: true });
                     }
                 });
    }

    removeFromList(customer: any) {
        if (!customer.webhook_active) {
            return;
        }
        this.router.navigate(['powers/customers']);
    }

    whenFixed(customer: any) {
        this.removeFromList(customer);
        this.countService.decrementCount('customers');
    }

}
