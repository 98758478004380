import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, switchMap } from 'rxjs/operators';
import { ApiSettings } from '../settings.class';
import { Router } from '@angular/router';

@Component({
  selector: 'con-delta-deliveries-primary-missing',
  templateUrl: './delta-deliveries-primary-missing.component.html'
})
export class DeltaDeliveriesPrimaryMissingComponent implements OnInit {


  public loading: boolean;
  private page$ = new Subject<number>();
  public currentPage = 1;
  public data: any;
  public noDataMessage = 'No missing primaries to display.';
  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.page$.pipe(
      startWith(this.currentPage),
      switchMap(page => {
        this.loading = true;
        return this.http.get<any>(ApiSettings.BASE_URL + '/delta_deliveries/list_task', {
          params: {
            page: String(page)
          }
        })
      }))
      .subscribe(res => {
        this.data = res;
        this.loading = false;
      });
  }


  changePage(page: number) {
    this.page$.next(page);
  }

  refresh() {
    this.page$.next(1);
  }
}
