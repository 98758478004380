<con-loader *ngIf="loading"></con-loader>
<ng-container *ngIf="!loading">
    <form *ngIf="profile.can(profile.getPermissionName('Comment', 'store'))" [formGroup]="messageForm" (ngSubmit)="addComment()" novalidate>
        <div class="form-group" [ngClass]="{ 'has-danger': (!messageForm.get('message').untouched && messageForm.get('message').status === 'INVALID') }">
            <label>Comment</label>
            <con-loader *ngIf="saving"></con-loader>
            <textarea class="form-control" rows="2" formControlName="message" *ngIf="!saving"></textarea>
            <con-form-errors [control]="messageForm.get('message')"
                         [extra]="formErrors"
                         *ngIf="!saving"></con-form-errors>
        </div>
        <div class="alert alert-danger" *ngFor="let message of generalErrors">
            {{ message }}
        </div>
        <div class="text-right">
            <button type="submit" class="btn btn-primary" [disabled]="messageForm.pristine">Submit</button>
        </div>
    </form>
    <con-icon-by-name (click)="refresh()" iconName="refresh"></con-icon-by-name>
    <div class="timeline-comment" *ngFor="let comment of comments?.data">
        <div class="media">
            <con-avatar class="mr-3" [userId]="comment.user_id" [size]="32"></con-avatar>
            <div class="media-body">
                <div><strong>{{ comment.user.first_name }} {{ comment.user.last_name }}</strong> {{ comment.created_at | amCalendar }}</div>
                {{ comment.message }}
            </div>
        </div>
    </div>
</ng-container>
