import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CompanyReportsSettings} from '../../../settings.class';
import {PaginatedDeliveries} from '../../models/PaginatedDeliveries';
import {MLSendStatus} from '../../models/MLSendStatus';
import {take} from 'rxjs/operators';
import {CompanyReportsService} from '../../services/company-reports.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'con-company-status',
  templateUrl: './company-status.component.html',
  styleUrls: ['./company-status.component.scss']
})
export class CompanyStatusComponent implements OnInit, OnDestroy {
  public noDataMessage = 'No data to display.';
  public loading = true;
  public mlReportStatuses: any;
  public showCircularLoader = true;
  MLSendStatus = MLSendStatus;
  public filterModels = {
    selectedTypeFilter: '',
    selectedSort: 'created_at',
  }
  public filterTypes: any[];
  public selectedTypeFilter = {label: 'All', value: ''};
  private mlReportSubscription = new Subscription;

  constructor(private http: HttpClient,
              private router: Router,
              private toaster: ToastrService,
              public reportService: CompanyReportsService
  ) {
  }

  ngOnInit() {
    this.filterTypes = [
      {
        label: 'All',
        value: ''
      },
      {
        label: 'CREATED',
        value: 'CREATED'
      },
      {
        label: 'IN PROGRESS',
        value: 'IN_PROGRESS'
      },
      {
        label: 'SUBMITTED',
        value: 'SUBMITTED'
      },
      {
        label: 'CLOSED',
        value: 'CLOSED'
      },
      {
        label: 'FAILED',
        value: 'FAILED'
      },
    ];
    this.loadPage(1);
  }

  ngOnDestroy() {
    this.mlReportSubscription.unsubscribe();
  }

  processParams(pageNumber: number) {
    const params: any = {};
    params.page = String(pageNumber);
    params.order_by = 'created_at';
    params.desc = true;
    if (this.selectedTypeFilter.value) {
      params.request_status = this.selectedTypeFilter.value;
    }
    return params;
  }

  loadPage(pageNumber: number) {
    this.loading = true;
    this.showCircularLoader = true;
    this.mlReportSubscription = this.http.get<any>(CompanyReportsSettings.BASE_URL + '/ml_process_status/',
      {
        params: this.processParams(pageNumber)
      })
      .subscribe(res => {

        this.formatDeliveriesData(res);
        this.loading = false;
        this.showCircularLoader = false;
      }, err => {
        this.loading = false;
        this.showCircularLoader = false;
        if (err.isPermissionError()) {
          this.router.navigate(['shareholders', 'auth'], {skipLocationChange: true});
        }
      });
  }

  formatDeliveriesData(response: any) {
    response.data.forEach((mlStatus, index) => {
      mlStatus.hide = true;
      mlStatus.loading = false;
    });
    this.mlReportStatuses = response;
  }

  public toggleType(mlStatus) {
    mlStatus.hide = !mlStatus.hide;
    if (!mlStatus.hide && !!mlStatus.data === false) {
      mlStatus.loading = true;
      this.http.get<any>(CompanyReportsSettings.BASE_URL + '/ml_instance_data/' + mlStatus.id + '/').pipe(take(1)).subscribe((response) => {
        mlStatus.data = JSON.parse(response.instance_data);
        mlStatus.loading = false;
      }, (error) => {
        console.error(error);
        mlStatus.loading = false;
      });
    }
  }

  getMutations(event) {
    return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }

  selectTypeFilter(filter: any) {
    if (filter.value === this.selectedTypeFilter.value) {
      return;
    }
    this.selectedTypeFilter = filter;
    this.loadPage(1);
  }

  goToReport(event) {
    event.stopPropagation();
  }

  getReportTitle(mlStatus: any) {
    let name = '';
    let reportType = '';
    let reportDate = '';
    let reportTitle = '';
    if (mlStatus.hasOwnProperty('company_report')) {
      if (mlStatus.company_report.hasOwnProperty('company')) {
        name = mlStatus.company_report.company.name;
      }
      if (mlStatus.company_report.hasOwnProperty('report_type')) {
        reportType = mlStatus.company_report.report_type;
      }
      if (mlStatus.company_report.hasOwnProperty('report_date')) {
        reportDate = mlStatus.company_report.report_date;
      }
    }
    if (name) {
      reportTitle += name;
    }
    if (name && reportType) {
      reportTitle += ' / ';
    }
    if (reportType) {
      reportTitle += reportType;
    }
    if ((reportType && reportDate) || (name && reportDate)) {
      reportTitle += ', ';
    }
    if (reportDate) {
      reportTitle += reportDate;
    }
    return reportTitle;
  }

  copyJSON(event, data) {
    navigator.clipboard.writeText(JSON.stringify(data));
    const successMsg = ' JSON successfully copied to clipboard.'
    this.toaster.success(successMsg);
  }
}
