import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyReportsService} from '../../services/company-reports.service';
import {take} from 'rxjs/operators';
import {ApiSettings} from '../../../settings.class';
import {MLSendStatus} from '../../models/MLSendStatus';

@Component({
  selector: 'con-send-to-ml-dialog',
  templateUrl: './send-to-ml-dialog.component.html',
  styleUrls: ['./send-to-ml-dialog.component.scss']
})
export class SendToMlDialogComponent implements OnInit {

  @Input() modalData;
  public currentPage = 1;
  public response: any;
  public loading = false;
  public noDataMessage = 'No Data Found';
  initialized = false;
  showTable = false;
  ApiSettings = ApiSettings;
  sendToMlMessage = '';
  mode = MLSendStatus;
  constructor(public activeModal: NgbActiveModal, public companyService: CompanyReportsService) {
  }

  ngOnInit(): void {

    if(this.modalData.hasOwnProperty('mlInfo') &&
      this.modalData.hasOwnProperty('reportId') &&
      (typeof this.modalData.reportId === 'number') &&
      this.modalData.mlInfo.ml_request_status === null
    ){
      this.loadData(true);
    }

    if(this.modalData.hasOwnProperty('mlInfo') &&
      this.modalData.hasOwnProperty('reportId') &&
      (typeof this.modalData.reportId === 'number') &&
      this.changeEnabledStatuses()
    ){
      this.updateCurrentReportFileSelection(this.modalData.mlInfo.company_report_file)
    }
  }

  changeEnabledStatuses(): boolean {
    return this.modalData.mlInfo.ml_request_status === MLSendStatus.FAILED;
  }

  loadData(initialLoad: boolean) {
    if(initialLoad) {
     this.parseReportFilesResponseData(this.modalData.reportFilesData);
     return;
    }
    this.loading = true;
    this.companyService.getCompanyReportFilesData(this.modalData.reportId, this.currentPage).pipe(take(1)).subscribe((response) => {
      this.parseReportFilesResponseData(response);
      this.loading = false;
    }, (error) => {
      console.error(error);
      this.loading = false;
    });
  }
  parseReportFilesResponseData(reportFileResponse) {
    if (!this.initialized) {
      if (reportFileResponse.total === 1) {
        if(this.modalData.mlInfo.ml_request_status === null){
          this.companyService.setSelectedReportFile(reportFileResponse.data[0]);
          this.modalData.message = 'Attached report file is <a href="/entity/company_report_file/' +reportFileResponse.data[0].id +'" target="_blank">' + reportFileResponse.data[0].label + '</a>. ' + this.modalData.message;
        }
        if(this.changeEnabledStatuses()){
          this.updateCurrentReportFileSelection(reportFileResponse.data[0]);
        }
      }
      if (reportFileResponse.total > 1) {
        this.modalData.message = 'Please select one report file to continue';
        this.showTable = true;
        if(this.changeEnabledStatuses()){
          this.companyService.setSelectedReportFile(null);
          this.sendToMlMessage = '';
        }
      }
      this.initialized = true;
    }
    this.response = reportFileResponse;
  }
  changePage($event: number) {
    this.currentPage = $event;
    this.companyService.setSelectedReportFile(null);
    this.sendToMlMessage = '';
    this.modalData.message = 'Please select one report file to continue.';
    this.loadData(false);
  }

  updateCurrentReportFileSelection(reportFile: any) {
    this.companyService.setSelectedReportFile(reportFile);
    this.sendToMlMessage = (this.changeEnabledStatuses() && (this.modalData.mlInfo.company_report_file.id === reportFile.id)) ? 'Send to machine learning team with <a href="/entity/company_report_file/' + reportFile.id +'" target="_blank">' +reportFile.label+ '</a> report file again?' : 'Send to machine learning team with <a href="/entity/company_report_file/' +reportFile.id +'" target="_blank">'+reportFile.label+'</a> report file?';
  }
  canShowFooterActions() : boolean{
    return !(this.showTable && this.companyService.getSelectedReportFile() === null);
  }
}
