<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="isLoading()"></con-loader>
            <div class="page-title mb-2 pb-3 pt-3" *ngIf="show()">
                <h3 class="mb-0">{{ institution.name }}</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="show()">
            <con-missing-events [company]="institution" [ownerType]="'Institution'" (refreshList)="refreshData()"></con-missing-events>
            <div class="mt-2">
                <con-confirm-events [company]="institution" [ownerType]="'Institution'" (refreshList)="refreshData()"></con-confirm-events>
            </div>
            <div class="mt-2">
                <con-unconfirmed-historical-events [company]="institution" [ownerType]="'Institution'" (refreshList)="refreshData()"></con-unconfirmed-historical-events>
            </div>
            <div class="card mt-2">
                <div class="card-header">
                    All events
                </div>
                <con-entity-list originEntityName="Institution" relation="events" entityName="CalendarEvent" [entityId]="institution.id" [refreshExternalData]="refreshExternalData"></con-entity-list>
            </div>
        </div>
    </div>
</div>