import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { CountService } from '../services/count.service';

import { MatchSettings } from '../../settings.class';

@Component({
    selector: 'con-inconsistent',
    templateUrl: './inconsistent.component.html'
})

export class InconsistentComponent implements OnInit {
    public loading: boolean;
    public inconsistent: any;

    constructor(private http: HttpClient, private count: CountService) {}

    ngOnInit() {
        this.loading = true;
        this.getInconsistent().subscribe(
            res => {
                this.inconsistent = res;
                this.loading = false;
            },
            err => {
                console.log(err);
                this.loading = false;
            }
        )
    }

    getInconsistent() {
        return this.http.get(MatchSettings.BASE_URL + '/' + MatchSettings.INCONSISTENT_ENDPOINT);
    }

    removeFromList(inconsistent: any) {
        this.inconsistent = this.inconsistent.filter(i => i.isin !== inconsistent.isin);
        this.count.decrementCount('INCONSISTENT');
        if (!this.inconsistent.length) {
            this.ngOnInit();
        }
    }
}
