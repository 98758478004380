
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable ,  ReplaySubject } from 'rxjs';

import { ApiSettings } from '../../settings.class';

@Injectable()
export class UsersService {
    private usersRequest: Observable<any>;
    private usersSubject: ReplaySubject<any> = new ReplaySubject(1);
    private teamsSubject: ReplaySubject<any> = new ReplaySubject(1);

    private loading: boolean;
    constructor(private http: HttpClient) {}

    public getUserById(id: number, refresh: boolean = false) {
        return this.getUsers(refresh).pipe(
            map(users => users[id]));
    }
    public getTeams(refresh: boolean = false) {
        this.refresh(refresh);

        return this.teamsSubject.asObservable();
    }
    public getUsers(refresh: boolean = false) {

        this.refresh(refresh);

        return this.usersSubject.asObservable();
    }
    public refresh(fresh: boolean = false) {
        if (fresh || !this.usersRequest) {
            this.loading = true;
            this.usersRequest = this.http.get(ApiSettings.BASE_URL + '/' + ApiSettings.USERS_ENDPOINT);

            this.usersRequest.subscribe(
                result => {
                    const users = {};
                    result.users.forEach(user => {
                        users[user.id] = user;
                    })
                    this.usersSubject.next(users);

                    const teams = {};
                    result.teams.forEach(team => {
                        teams[team.id] = team;
                    });
                    this.teamsSubject.next(teams);

                    this.loading = false;
                },
                err => {
                    this.usersSubject.error(err);
                    this.teamsSubject.error(err);
                    this.loading = false;
                });
        }
    }

    public isLoading() {
        return this.loading;
    }
}
