import { Injectable } from '@angular/core';

import { switchMap, tap } from 'rxjs/operators';

import { EntitiesService } from '../services/entities.service';

export class Relation {
    private inverseRelations: any  = [];
    private relatedEntityDescriptions: any = {};

    public static getEntityNameFromRelation(relation: any) {
        return relation.owner;
    }
    public static isMorphRelationType(relationType: string) {
        return ['morphOne', 'morphMany', 'morphTo'].indexOf(relationType) > -1;
    }
    public static isHasOneRelationType(relationType: string) {
        return ['morphOne', 'morphTo', 'hasOne'].indexOf(relationType) > -1;
    }
    public static isHasManyRelationType(relationType: string) {
        return ['morphMany', 'hasMany'].indexOf(relationType) > -1;
    }
    public static isHasOneOrManyRelationType(relationType: string) {
        return Relation.isHasOneRelationType(relationType) || Relation.isHasManyRelationType(relationType);
    }
    public static isHasManyToManyRelationType(relationType: string) {
        return relationType === 'belongsToMany';
    }

    public constructor(private relation: any,
                       private service: EntitiesService) {
    }

    public getUniqueNameForRelation(relation) {
        const modelsString = (relation.model ? [ relation.model ] : relation.models).join('-');
        return [relation.name, relation.model, relation.type, relation.owner].join('-');
    }

    public isMorphRelation() {
        return Relation.isMorphRelationType(this.relation.type);
    }

    public isHasOneRelation() {
        return Relation.isHasOneRelationType(this.relation.type);
    }

    public isHasManyToManyRelation() {
        return Relation.isHasManyToManyRelationType(this.relation.type);
    }

    public getEntityName() {
        return Relation.getEntityNameFromRelation(this.relation);
    }

    public init() {
        return this.service.getInverseRelations(this.relation)
            .pipe(tap(relations => {
                this.inverseRelations = relations;
            }))
            .pipe(switchMap(relations => {
                const validNames = {};
                validNames[this.relation.owner] = true;
                relations.forEach(r => validNames[r.owner] = true);
                return this.service.getEntityDescriptionsByEntityNames(Object.keys(validNames));
            }))
            .pipe(tap(entityDescriptions => {
                entityDescriptions.forEach(entityDescription => this.relatedEntityDescriptions[entityDescription.name] = entityDescription);
            }));
    }

    private generateFixedSearchParams(parent: any, relation: any, entity: any = null) {
        const obj = {};

        // case: hasMany, hasOne in that case we know that inverse is belongsTo
        // Then we want free objects only
        if (parent.type === 'hasMany' || parent.type === 'hasOne') {
            obj[relation.name + ':'] = 'f:null';

        // case: belongsToMany, in that case we know that inverse is belongsToMany
        // Then we want objects which hasnt been assigned to us yet
        } else if (parent.type === 'belongsToMany') {
            if (entity.id) {
                obj[relation.name + ':id'] = 'f:neq:' + entity.id;
            }


        // case: morphMany/morphOne, in that case we know that inverse is morphTo
        // Then we want free objects only
        } else if (parent.type === 'morphMany' || parent.type === 'morphOne') {
            obj[relation.foreign_key] = 'f:null';
            obj[relation.foreign_type] = 'f:null';

        // case: morphTo, in that case it could be morphOne or morphMany
        } else if (parent.type === 'morphTo') {
            // case: morphOne
            // We want only objects which does not have a relation yet
            if (relation.type === 'morphOne') {
                obj[relation.name + ':'] = 'f:null';
            }
        // case: belongsTo, in that case it could be hasOne or hasMany
        } else if (parent.type === 'belongsTo') {
            // case: hasOne
            // We want only objects which does not have a relation yet
            if (relation.type === 'hasOne') {
                obj[relation.name + ':'] = 'f:null';
            }
        }

        return obj;
    }
    public getAllFixedChildValues(entity: any = null) {
        const fixedChildValues = {};
        this.inverseRelations.forEach(r => {
            fixedChildValues[this.getUniqueNameForRelation(r)] = this.generateFixedChildValues(this.relation, r, entity);
        });
        return fixedChildValues;

    }
    public generateFixedChildValues(parent: any, relation: any, entity: any = null) {
        const obj = {};
        if (Relation.isHasOneOrManyRelationType(parent.type)) {
            if (Relation.isMorphRelationType(parent.type)) {
                obj[relation.foreign_key] = entity.id;
                obj[relation.foreign_type] = parent.owner;
            } else {
                obj[relation.foreign_key] = entity.id;
            }
        }
        return obj;
    }
    public getInverseRelations() {
        return this.inverseRelations;
    }

    public getModelNameFromRelation(relation: any) {
        return relation.owner;
    }

    public hasPivotFields() {
        return Relation.isHasManyToManyRelationType(this.relation.type) && this.relation.pivot_fields.length > 0;
    }

    public getAllRelatedEntityDescriptions() {
        return this.relatedEntityDescriptions;
    }

    public getAllInverseRelations() {
        return this.inverseRelations;
    }

    public getAllFixedSearchParams(entity: any = null) {
        const fixedSearchParams = {};
        this.inverseRelations.forEach(r => {
            fixedSearchParams[this.getUniqueNameForRelation(r)] = this.generateFixedSearchParams(this.relation, r, entity);
        });
        return fixedSearchParams;
    }

    public getRelatedEntityNames(relation: any) {
        return relation.model ? [relation.model] : relation.models;
    }
}

@Injectable()
export class RelationService {
    constructor(public service: EntitiesService) {}

    getRelation(relation: any): Relation {
        return new Relation(relation, this.service);
    }
}
