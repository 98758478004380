import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'con-share-count-editor',
  templateUrl: './share-count-editor.component.html'
})
export class ShareCountEditorComponent implements OnInit, OnChanges {


  @Input() header: string;
  @Input() company: any;
  @Input() preFillValues: any;
  @Input() showEditor = true;
  @Input() fixedValues: any;
  @Input() displayEntityList = false;
  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();

  public showShareCountList = false;
  public toggleShareCountHistory = false;
  public managerName = 'Manager not assigned';

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
    if (this.company && this.company.manager && this.company.manager.first_name) {
      this.managerName = this.company.manager.first_name + ' ' + this.company.manager.last_name;
    }
  }
  shareCountUpdated(company: any) {
    this.afterSave.emit(company);
  }
}
