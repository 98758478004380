<ng-template #rt let-r="result" let-t="term">
  {{ relatedEntityDescription.getPrimaryString(r) }}
</ng-template>
<con-loader *ngIf="loading"></con-loader>
<div class="form-group" [class.has-danger]="searchFailed" *ngIf="!loading">
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text"><con-icon-by-name [iconName]="relatedEntityDescription.getIcon()" [mutations]="['fw']"></con-icon-by-name></span>
    </div>
    <input [disabled]="disabled" type="search" class="form-control" [(ngModel)]="model" (ngModelChange)="entitySelected($event)" [ngbTypeahead]="search" [resultTemplate]="rt" placeholder="Search" />
    <div class="input-group-append" *ngIf="searching">
      <span class="input-group-text"><con-loader></con-loader></span>
    </div>
  </div>
  <div class="form-control-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
</div>
