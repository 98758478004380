
import {switchMap} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { EntitiesService } from '../../entities/services/entities.service';
import { ProfileService } from '../../auth/services/profile.service';

import { ApiSettings, ListsSettings } from '../../settings.class';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'con-universe-detail',
    templateUrl: './universe-detail.component.html'
})

export class UniverseDetailComponent implements OnInit, OnDestroy {
    public companyUniverse: any;
    public companyUniverseId: number;
    public loading: boolean;

    public toggles: any = {};

    public assignSuccessful = false;
    public isSuperUser: boolean;
    private superUserSubscription: ISubscription;
    constructor(private entities: EntitiesService,
                private route: ActivatedRoute,
                private router: Router,
                private http: HttpClient,
                private profileService: ProfileService) {}

    syncCompanies(entity: any) {
        this.http.patch<any>(ApiSettings.BASE_URL + '/company_universe/' + this.companyUniverseId + '/' +
        ListsSettings.UNIVERSE_SYNC_COMPANIES_ENDPOINT, {})
                .subscribe(
                    res => console.log(res),
                    err => console.log(err)
                );
    }

    handleAction(action: any) {
        if (action.name === 'sync') {
            this.syncCompanies(action.entity);
        }
    }

    ngOnInit() {
        this.route.params.pipe(
              switchMap((params: Params) => {
                  this.loading = true;
                  this.companyUniverseId = params.id;
                  return this.entities.getEntityById('CompanyUniverse', params.id);
              }))
              .subscribe(companyUniverse => {
                  this.companyUniverse = companyUniverse;
                  this.loading = false;
              },
              err => {
                  if (err.status === 404) {
                      this.router.navigate(['universe', 'error'], { skipLocationChange: true });
                  }
                  this.loading = false;
              });
        this.superUserSubscription = this.profileService.isSuperUser()
                                                        .subscribe(res => {
                                                            this.isSuperUser = res;
                                                        });
    }

    ngOnDestroy() {
        this.superUserSubscription.unsubscribe();
    }

    assignManagerToCompaniesWithoutManager(event: any) {
        if (event.id) {
            this.http.put(
                ApiSettings.BASE_URL
                + '/company_universe'
                + '/' + this.companyUniverseId
                + '/' + ListsSettings.ASSIGN_MANAGER_TO_MISSING_COMPANIES_ENDPOINT,
                { manager_id: event.id }
            )
            .subscribe(res => {
                this.assignSuccessful = true;
            }, err => {
                console.log(err);
            });
        }
    }

    public toggleView(view: string) {
        this.toggles[view] = !this.toggles[view];
    }
}
