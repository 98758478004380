import { RouterModule, Routes } from '@angular/router';

import { ListsComponent } from './layout/lists.component';
import { TierStartComponent } from './layout/tier-start.component';
import { TierDetailComponent } from './layout/tier-detail.component';
import { InstitutionTierDetailComponent } from './layout/institution-tier-detail.component';
import { UniverseStartComponent } from './layout/universe-start.component';
import { UniverseDetailComponent } from './layout/universe-detail.component';
import { InstitutionUniverseDetailComponent } from './layout/institution-universe-detail.component';
import { PageNotFoundComponent } from '../page-not-found.component';
import { NotAuthorizedComponent } from '../not-authorized.component';

export const listsRoutes: Routes = [
    {
        path: '',
        component: ListsComponent,
        canActivate: [],
        children: [
            {
                path: 'company_tier',
                children: [
                    {
                        path: '',
                        component: TierStartComponent
                    }, {
                        path: ':id',
                        component: TierDetailComponent
                    }, {
                        path: 'error',
                        component: PageNotFoundComponent
                    }, {
                        path: 'auth',
                        component: NotAuthorizedComponent
                    }
                ]
            },
            {
                path: 'company_universe',
                children: [
                    {
                        path: '',
                        component: UniverseStartComponent
                    }, {
                        path: ':id',
                        component: UniverseDetailComponent
                    }, {
                        path: 'error',
                        component: PageNotFoundComponent
                    }, {
                        path: 'auth',
                        component: NotAuthorizedComponent
                    }
                ]
            },
            {
                path: 'institution_tier',
                children: [
                    {
                        path: '',
                        component: TierStartComponent
                    }, {
                        path: ':id',
                        component: InstitutionTierDetailComponent
                    }, {
                        path: 'error',
                        component: PageNotFoundComponent
                    }, {
                        path: 'auth',
                        component: NotAuthorizedComponent
                    }
                ]
            },
            {
                path: 'institution_universe',
                children: [
                    {
                        path: '',
                        component: UniverseStartComponent
                    }, {
                        path: ':id',
                        component: InstitutionUniverseDetailComponent
                    }, {
                        path: 'error',
                        component: PageNotFoundComponent
                    }, {
                        path: 'auth',
                        component: NotAuthorizedComponent
                    }
                ]
            }
        ]
    }
];

export const listsRouting = RouterModule.forChild(listsRoutes);
