import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EnvSettingsService } from './env-settings.service';
import { Method, DeliveryState } from './methods.service'
import { UtilService } from './util.service';
import { EmailMapping } from '../typings/Typings';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public jsonPlaceholder = new Subject();
  public responseFromCreateTask = new Subject();
  public headerNameSub = new Subject<string>();
  constructor(private http: HttpClient, private utilService: UtilService) {}

  getPlaceholderJSONTaskData() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetCreateTaskPlaceholder).toPromise();
  }
  getPlaceholderJSONSymbolsData() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetCreateSymbolsPlaceholder).toPromise();
  }
  getPlaceholderJSONIndexData() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetCreateIndexPlaceholder).toPromise();
  }
  getPlaceholderJSONIhsData() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetCreateIhsPlaceholder).toPromise();
  }


  /*Task manager*/
  getDepStatus() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetDepStatusAll).toPromise();
  }
  getTask(id: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetTaskDetail + id).toPromise();
  }
  getDepStatusRunningDeliveries() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetDepStatusRunning).toPromise();
  }
  deleteTask(task_id: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.DeleteTask + task_id).toPromise();
  }
  activateTask(task_id: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.ActivateTask + task_id).toPromise();
  }
  deactivateTask(task_id: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.DeactivateTask + task_id).toPromise();
  }
  createManualDelivery(taskID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.CreateManualDelivery + taskID).toPromise();
  }
  createMozendaDelivery(taskID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.CreateMozendaDelivery + taskID).toPromise();
  }
  getTaskOverviewWithIndicies() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetTaskOverviewWithIndicies).toPromise();
  }

  /*Agent edit*/
  getConfig(taskId: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAgent + taskId).toPromise();
  }
  createTask(jsonBody: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.CreateTask, jsonBody).toPromise();
  }
  createIndex(jsonBody: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.CreateIndex, jsonBody).toPromise();
  }
  createSymbol(jsonBody: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.CreateVWDSymbol, jsonBody).toPromise();
  }
  createIHSSymbol(jsonBody: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.CreateIHSSymbol, jsonBody).toPromise();
  }
  getPlaceholderConfig(task_id: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAgentConfigPlaceholder + task_id).toPromise();
  }
  dimCheck(config: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.DimCheck, config, {responseType: 'json'}).toPromise();
  }
  getMapColumns(config: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.MapColumns, config, {responseType: 'text'}).toPromise();
  }
  getMozendaData(config: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.ViewMozendaData , config, {responseType: 'text'}).toPromise();
  }
  getStep(config: string, step: number) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.Transform + step, config, {responseType: 'text'}).toPromise();
  }
  addAgent(agentId: number, config: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.SetAgent + agentId, config, {responseType: 'text'}).toPromise();
  }
  testConfig(config: string, task_id: number) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.TestConfig + task_id, config, {responseType: 'text'}).toPromise();
  }
  deleteAgent(agentId: number, config: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.RemoveAgent + agentId, config, {responseType: 'text'}).toPromise();
  }
  updateTaskSpec(taskID: number, taskSpec: any) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.UpdateTaskSpec + taskID, taskSpec, {responseType: 'json'}).toPromise();
  }
  getTaskSpec(taskID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetTaskSpec + taskID).toPromise();
  }

  /*Email mapping*/
  getEmailMapping(taskId: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetEmailMapping + taskId).toPromise();
  }
  removeEmailMapping(taskId: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.RemoveEmailMapping + taskId).toPromise();
  }
  getEmailMappingPlaceholder(taskId: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetEmailMappingPlaceholder + taskId).toPromise();
  }
  setEmailMapping(taskId: number, emailMapping: EmailMapping) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.SetEmailMapping + taskId, emailMapping, {responseType: 'text'}).toPromise();
  }
  testEmailMapping(taskId: number, emailMapping: EmailMapping) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.TestEmailMapping + taskId, emailMapping, {responseType: 'text'}).toPromise();
  }


  /*Indicies*/
  getIndiciesTaskDetails(taskId: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL()  + Method.GetIndicesTaskDetails + taskId).toPromise();
  }
  symbolSearch(searchBody: any) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.SymbolSearch, searchBody,  {responseType: 'json'}).toPromise();
  }
  updateIndexComponent(taskId: number, newIndexComponent: any) {
    return this.http.post(EnvSettingsService.MOZENDA_URL()  + Method.UpdateIndexComponent + taskId, newIndexComponent, {responseType: 'json'}).toPromise();
  }
  deleteIndexComponent(taskId: number, newIndexComponent: any) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.DeleteIndexComponent + taskId, newIndexComponent, {responseType: 'json'}).toPromise();
  }


  /*Symbols*/
  getAllSymbols() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllSymbols).toPromise();
  }
  getRendintenSymbols() {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetRedndintenSymbols).toPromise();
  }
  getSymbolSpec(symbolID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetSymbolSpec + symbolID).toPromise();
  }
  updateSymbolSpec(symbolID: number, symbolSpec: any) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.UpdateSymbolSpec + symbolID, symbolSpec, {responseType: 'json'}).toPromise();
  }

  /*Deliveries*/
  getDelivery(deliveryID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetDeliveryDetails + deliveryID).toPromise();
  }
  getIndicesMozendaData(deliveryID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetIndicesMozendaData + deliveryID, {responseType: 'text'}).toPromise();
  }
  getAllRunningDelieveries(limit?: number, offset?: number) {
    if (limit !== undefined && offset !== undefined) {
      return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Running + `&offset=${offset}` + `&limit=${limit}`).toPromise();
    }
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Running).toPromise();
  }
  getAllPendingDelieveries(limit?: number, offset?: number) {
    if (limit !== undefined && offset !== undefined) {
      return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Pending + `&offset=${offset}` + `&limit=${limit}`).toPromise();
    }
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Pending).toPromise();
  }
  getAllCompletedDelieveries(limit?: number, offset?: number) {
    if (limit !== undefined && offset !== undefined) {
      return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Completed + `&offset=${offset}` + `&limit=${limit}`).toPromise();
    }
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetAllDeliveries + DeliveryState.Completed).toPromise();
  }
  cancelDelivery(deliveryID: number, reason: string) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.cancelDelivery + deliveryID + '&reason=' + reason).toPromise();
  }
  getScrapedMozendaHTML(deliveryID: number) {
    return this.http.get(EnvSettingsService.MOZENDA_URL() + Method.GetScrapedHtml + deliveryID, {responseType: 'text'}).toPromise();
  }
  updateDataDelivery(deliveryID: number, data: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.UpdateDeliveryData + deliveryID, data,
      {responseType: 'text'}).toPromise();
  }
  submitDataDelivery(deliveryID: number, data: string) {
    return this.http.post(EnvSettingsService.MOZENDA_URL() + Method.SubmitDeliveryData + deliveryID, data,
      {responseType: 'text'}).toPromise();
  }
  getDeliveriesFromGivenTimeRange(deliveryState: string, fromDate: string, toDate: string, limit?: number, offset?: number) {
    let url: string = EnvSettingsService.MOZENDA_URL() +  Method.GetAllDeliveries;
    if (deliveryState) {
      url += `${deliveryState}`
    } else {
      url += `${DeliveryState.Running}`
    }
    if (fromDate) {
      url += `&from_date=${fromDate}`
    }
    if (toDate) {
      url += `&to_date=${toDate}`
    }
    if (limit !== undefined) {
      url += `&limit=${limit}`
    }
    if (offset !== undefined) {
      url += `&offset=${offset}`
    }
    return this.http.get(url, {responseType: 'json'}).toPromise();
  }
}
