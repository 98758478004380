<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <div class="row">
                        <div class="col-6">
                            <div class="page-title-icon">
                                <con-icon-by-name iconName="tachometer"></con-icon-by-name>
                            </div>
                            <h3 class="mb-0">Dashboard</h3>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link"
                                    [routerLink]="['/dashboard']"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Overview</a>
                                </li>
                                <li class="nav-item">
                                        <a class="nav-link"
                                        [routerLink]="['calendar']"
                                        routerLinkActive="active">Calendar</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>