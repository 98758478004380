import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {CompanyReportsService} from '../../services/company-reports.service';

@Component({
  selector: 'con-report-events-editor',
  templateUrl: './report-events-editor.component.html',
  styleUrls: ['./report-events-editor.component.scss']
})
export class ReportEventsEditorComponent implements OnInit {
  @Input() company: any;
  @Input() ownerType: string;
  @Input() isListing: boolean = false;
  @Output() savedCompany = new EventEmitter<number>();
  entityName = 'CompanyReportEvent';
  constructor(public reportService: CompanyReportsService) { }

  ngOnInit(): void {
  }

  getMutations(event) {
    const mutation = event.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
    return mutation;
  }

  toggleType(event) {
    event.expanded = ! event.expanded;
  }

  addNewEvent() {
    this.company.showAddEvent = true;
  }

  removeAddEvent(company: any) {
    company.showAddEvent = false;
  }
  getCurrentDate(): string {
    return moment().format('yyyy-MM-DD').toString();
  }

  removeEvent(event: any) {
    this.savedCompany.emit(this.company.id);
  }
}
