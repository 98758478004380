import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DynamicDataEntryComponentInterface} from "../models/dynamic-data-entry-component-interface";
import {Observable} from "rxjs";
import {TaskDataEntryState} from "../state-management/states";
import {map} from "rxjs/operators";
import {InstanceService} from "../services/instance.service";
import {Store} from "@ngxs/store";
import {SetComponentData} from "../state-management/actions";

@Component({
  selector: 'con-dynamic-multiple-horizontal-selector',
  template: `
      <div class="d-flex w-100 justify-content-start flex-wrap mb-2">
          <button
                  *ngFor="let choice of component.enums; let i = index"
                  [class]="((value$ | async).includes(choice) ? 'btn-primary' : 'btn-disabled') + ' btn-sm mr-1 mb-1 px-2 py-1 cursor-pointer'"
                  (click)="onOptionClick($event.target, choice)"
                  [value]="choice">
              {{  choice }}
          </button>
      </div>  `,
  styles: ['button { border: none; } .btn-disabled { background: white }']
})
export class DynamicMultipleHorizontalSelectorComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<any>;
  public value: String | Number;

  constructor(
    public instanceService: InstanceService,
    private store: Store,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
      .pipe(
        map((value: any) => {
          return value
        }))
  }

  onOptionClick(target: any, choice: string | number) {
    let currentSelectedArray: [string | number] = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component));
    let selectedArray = [];
    let index = currentSelectedArray.indexOf(choice);
    if (index > -1) {
      selectedArray = currentSelectedArray.filter(item => item !== choice)
    } else {
      selectedArray = [choice, ...currentSelectedArray];
    }
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, selectedArray));
    this.ref.markForCheck();
  }
}
