import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'con-clear-data-modal',
  template: `<ng-template #clearDataModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Clear instance annotations</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="mb-0">
        <span>Your data will be deleted and irrecoverably lost.</span>
        <br><br>
        <span><b>Are you sure?</b></span>
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="modal.close('Clear All click')">Clear All</button>
      <button class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
    </div>
  </ng-template>`
})
export class ClearDataModalComponent implements OnInit {
  @ViewChild('clearDataModal') clearDataModal;
  @Output() approve: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
  }

  public show(): void {
    this.modalService.open(this.clearDataModal, {}).result.then(() => {
      this.approve.emit();
    }, () => {});
  }
}
