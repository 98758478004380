import {IdentifierModel} from './identifier-model';
import {TaxonomyModule} from './taxonomy-module';
import {MarkModel} from './mark-model';
import {DecimalSeparator} from './decimal-separator';
import {FundamentalsStatementService} from '../../universal-data-entry/services/fundamentals-statement.service';

export class AnnotationModel {
  public id: IdentifierModel;
  public quantity: any = undefined;
  public passedSanityCheck? : boolean;

  constructor(
    public module: TaxonomyModule,
    private fundamentalsStatementService: FundamentalsStatementService, // TODO this should not be required to instantiate a modal
    public subtract: boolean = false,
    quantity: any = null,
    id: number = null,
  ) {
    if (!!id)
      this.id = new IdentifierModel(id.toString())
    else
      this.id = new IdentifierModel()

    if (!!quantity)
      this.quantity = quantity
    else
      this.quantity = module.quantity
  }

  public static compare(a: AnnotationModel, b: AnnotationModel): number {
    return IdentifierModel.compare(a.id, b.id)
  }

  toConsolidationFactor(decimalSeparator: DecimalSeparator): number {
    let mark = this.getMark()
    return (mark.getScalarValue(decimalSeparator) ?? 0) * (this.subtract ? -1 : 1)
  }

  public getMark(): MarkModel { // TODO remove service call in a model. Bad idea
    return this.fundamentalsStatementService.getMarkOfAnnotationSequential(this.id)
  }
}
