import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MozendaDeliveriesSettings } from '../../settings.class';
import { MozendaService } from '../services/mozenda.service';
import { Subscription, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'con-mozenda-deliveries',
    templateUrl: './mozenda-deliveries.component.html'
})

export class MozendaDeliveriesComponent implements OnInit, OnDestroy {
    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    public deliveries: any;
    public selectedTypeFilter = {label: 'All', value: ''};
    public selectedSort = {label: 'Date', value: 'created_at'};
    public filterTypes: any[];
    public sortTypes: any[];
    public sortOrder = 'desc';
    public selectedItems = [];
    public showCircularLoader = true;
    public searchText = '';
    public filterModels =  {
        selectedTypeFilter: '',
        selectedSort: 'created_at',
    }

    private searchSubject = new Subject<string>();
    private reloadSubscription = new Subscription;
    private deliveriesSubscription = new Subscription;

    constructor(private http: HttpClient,
                private router: Router,
                private mozendaService: MozendaService,
                private toastr: ToastrService) {}

    ngOnInit() {
        this.filterTypes = [
            {
                label: 'All',
                value: ''
            },
            {
                label: 'Shareholders',
                value: 'ShareHolders'
            },
            {
                label: 'Company reports',
                value: 'CompanyReports'
            }
        ];
        this.sortTypes = [
            {
                label: 'Date',
                value: 'created_at'
            },
            {
                label: 'Project',
                value: 'project'
            },
            {
                label: 'Agent',
                value: 'agent_id'
            }
        ]
        this.loadPage(1);
        this.reloadSubscription = this.mozendaService.reloadSubject.subscribe(type => {
            if (type === 'Missing') {
                this.loadPage(1);
            }
        });
        this.searchSubject
            .pipe(debounceTime(1000))
            .pipe(distinctUntilChanged())
            .subscribe(model => {
              this.loadPage(1);
        });
    }

    ngOnDestroy() {
        this.reloadSubscription.unsubscribe();
        this.deliveriesSubscription.unsubscribe();
    }

    processParams(pageNumber: number) {
        const params: any = {};
        params.page = String(pageNumber);
        if (this.selectedTypeFilter.value) {
            params.project = this.selectedTypeFilter.value;
        }
        if (this.selectedSort.value) {
            params.order_by = this.selectedSort.value;
        }
        if (this.sortOrder === 'asc') {
            params.asc = true;
        }
        if (this.searchText) {
            params.agent_id = this.searchText;
        }
        return params;
    }

    loadPage(pageNumber: number) {
        this.selectedItems = [];
        this.mozendaService.getCounts(true);
        this.loading = true;
        this.showCircularLoader = true;
        this.deliveriesSubscription = this.http.get<any>(MozendaDeliveriesSettings.BASE_URL + '/' + MozendaDeliveriesSettings.DELIVERIES_ENDPOINT,
                {
                    params: this.processParams(pageNumber)
                })
                 .subscribe(res => {
                     this.mozendaService.setCount('missing', res.total);
                     this.formatDeliveriesData(res);
                     this.loading = false;
                     this.showCircularLoader = false;
                 }, err => {
                     this.loading = false;
                     this.showCircularLoader = false;
                     if (err.isPermissionError()) {
                         this.router.navigate(['shareholders', 'auth'], { skipLocationChange: true });
                     }
                 });
    }
    formatDeliveriesData(res: any) {
        res.data.forEach((del, index) => {
            if (index !== 0) {
               del.hide =  true;
            }
            del.data = JSON.parse(del.data);
        });
        this.deliveries = res;
    }

    removeFromList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.ngOnInit();
        }
    }
    public toggleType(event?) {
        event.hide = ! event.hide;
    }
    getMutations(event) {
        return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
    }
    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
    }

    selectTypeFilter(filter) {
        if (filter.value === this.selectedTypeFilter.value) {
            return;
        }
        this.selectedTypeFilter = filter;
        this.loadPage(1);
    }
    selectSort(sort) {
        if (sort.value === this.selectedSort.value) {
            return;
        }
        this.selectedSort = sort;
        this.loadPage(1);
    }
    setSortOrder(order: string) {
     this.sortOrder = order;
     this.loadPage(1);
    }
    onSubmit(data, index) {
        const confirmation  = confirm ('Are you sure you want to confirm and remove this entry?');
        if (confirmation) {
            this.loading = true;
            this.showCircularLoader = false;
            this.mozendaService.deleteMozendaData(data.id).subscribe( response => {
                    this.deliveries.data.splice(index, 1);
                    this.mozendaService.decrementCount('deliveries');
                    this.toastr.success('Success!', 'Mozenda');
                    this.loading = false;
                    if (!this.deliveries.data.length) {
                        this.loadPage(1);
                    }
                }, error => {
                    this.loading = false;
                }
            )
        }
    }

    selectAll() {
        if (!this.deliveries || !this.deliveries.data) {
            return;
        }
        this.deliveries.data.forEach(del => {
            del.selected = true;
            const index = this.selectedItems.indexOf(del.id);
            if (index < 0) {
                this.selectedItems.push(del.id);
            }
        });
    }

    allSelected() {
        if (this.deliveries && this.deliveries.data) {
            let allSelected = true;
        this.deliveries.data.forEach(del => {
            if (!del.selected) {
                allSelected = false;
            }
        });
        return allSelected;
        } else {
            return false;
        }
    }

    deselectAll() {
        if (!this.deliveries || !this.deliveries.data) {
            return;
        }
        this.deliveries.data.forEach(del => {
            del.selected = false;
            const index = this.selectedItems.indexOf(del.id);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            }
        });
    }

    isSelected (id: number) {
        if (this.selectedItems.indexOf (id) > -1) {
            return true;
        }
        return false;
    }

    selectItem (id: number, event) {
        event.stopPropagation();
        const index = this.selectedItems.indexOf(id);
        if ( index > -1) {
            this.selectedItems.splice(index, 1);
            this.deliveries.data.forEach(del => {
                if (del.id === id) {
                    del.selected = false;
                }
            });
        } else {
            this.selectedItems.push(id);
            this.deliveries.data.forEach(del => {
                if (del.id === id) {
                    del.selected = true;
                }
            });
        }
    }

    confirmSelected () {
        const count = this.selectedItems.length;
        const countTxt = count > 1 ? 'items' : 'item';
        const confirmFlag = confirm('You have selected ' + count + ' ' + countTxt + ' ! Are you sure you want to proceed ?');
        if (confirmFlag) {
            this.loading = true;
            this.showCircularLoader = false;
            this.mozendaService.deleteSelectedMozendaData(this.selectedItems).subscribe(response => {
                this.loadPage(1);
                this.toastr.success('Success!', 'Mozenda');
            }, error => {
                this.loading = false;
            });
        }
    }

    search() {
        this.searchSubject.next(this.searchText);
    }

    resetFilters() {
        this.searchText = '';
        this.selectedTypeFilter = {label: 'All', value: ''};
        this.selectedSort = {label: 'Date', value: 'created_at'};
        this.sortOrder = 'desc';
        this.filterModels =  {
            selectedTypeFilter: '',
            selectedSort: 'created_at',
        }
        this.loadPage(1);
    }
}
