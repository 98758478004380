import {Directive, HostListener} from '@angular/core';
import {CanDeactivateComponent} from '../interfaces/can-deactivate-component';
import {RouterOutlet} from '@angular/router';
import {CanDeactivateNotification} from '../services/can-deactivate-notification.service';

@Directive({ selector: 'router-outlet' })
export class RouterOutletCanDeactivatePluginDirective {

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate($event: any) {
    for (const component of this.components) {
      if(typeof component.canDeactivate === 'function' && !component.canDeactivate()){
        if (!this.canDeactivateService.canDeactivateUserConfirmation()) {
          $event.returnValue = true;
          return;
        }
      }
    }
  }

  private components: CanDeactivateComponent[] = [];

  constructor(routerOutlet: RouterOutlet, private canDeactivateService: CanDeactivateNotification) {
    routerOutlet.activateEvents.subscribe( c => {
      this.components.push(c)
    } );
    routerOutlet.deactivateEvents.subscribe( c => {
      const idx = this.components.indexOf(c);
      if (idx > -1) {
        this.components.splice(idx, 1);
      }
    });
  }
}
