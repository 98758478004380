<div class="content">
    <div class="container-fluid mb-3">
        <div class="row">
            <div class="col-12">
                <ng-container *ngFor="let power of powers">
                    <button class="btn btn-sm btn-primary mb-1" (click)="usePower(power)">{{ power.name }}</button>
                    <con-loader *ngIf="loaders[power.name]"></con-loader>
                </ng-container>
            </div>
        </div>
        <div class="row" *ngIf="messages.length">
            <div class="col-12">
                <p *ngFor="let message of messages" class="mb-1"><kbd>{{ message | json }}</kbd></p>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12">
                <h4>Logs</h4>
                <con-logs-table></con-logs-table>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a href="{{ getHorizonUrl() }}">Go to Horizon</a>
            </div>
        </div>
    </div>
</div>
