<con-loader *ngIf="isLoading() || loading"></con-loader>
<div *ngIf="!loading && !hidden">
    <span *ngIf="!_canShow && !_canEdit && entityAlreadyExists()">
        You are not allowed to see/edit this entity
    </span>
  <span *ngIf="!_canStore && !entityAlreadyExists()">
        You are not allowed to create new entities of this type
    </span>
  <con-pretty-entity-shower
    [fromDetailPage]="fromDetailPage"
    *ngIf="!_canEdit && _canShow && entityAlreadyExists()"
    [entity]="obj"
    [entityName]="entityDescription.name"
    [newTab]="newTab"
    [redirectButton]="redirectButton"
  >
  </con-pretty-entity-shower>
  <form *ngIf="(
            (_canEdit && entityAlreadyExists())
            || (_canStore && !entityAlreadyExists())
        ) && (
            _canShow
            || !entityAlreadyExists()
        )" [formGroup]="entityForm" (ngSubmit)="onSubmit()"  novalidate>
    <ng-container *ngFor="let field of fields">
      <div class="form-group"
           *ngIf="!fixedValues[field.key] && entityForm.get(field.key)"
           [ngClass]="{ 'has-danger': (fieldErrors[field.key] && fieldErrors[field.key].length)
                                || (!entityForm.get(field.key).untouched && entityForm.get(field.key).status === 'INVALID') }">
        <label><small>{{ field.label }}</small></label>
        <div>
          <con-flexible-input [entityForm]="entityForm" [control]="entityForm.get(field.key)" [field]="field" [resetKey]="resetKey" [reverseDates]="combinedStatements"></con-flexible-input>
          <con-form-errors [control]="entityForm.get(field.key)" [extra]="fieldErrors[field.key]"></con-form-errors>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <div class='mt-10' *ngIf='incomeStatements.length'>
        <ng-container>
          <div class='form-group'>
            <label><small>Income Statements</small></label>
            <button class='btn btn-link pull-right no-focusborder clear-btn-style' (click)='clearSelection($event,"income_statement_selection")'>Clear</button></div>
          <div *ngFor='let is of incomeStatements' class="form-group">
            <label >
              <input type="radio" [value]="is.value" formControlName="income_statement_selection">
              <span>{{is.name}}</span>
            </label>
          </div>
        </ng-container>
      </div>
      <div *ngIf='balanceSheets.length'>
        <ng-container>
          <div class='form-group'>
            <label> <small>Balance Sheets </small> </label><button class='btn btn-link pull-right no-focusborder clear-btn-style' (click)='clearSelection($event,"balance_sheet_selection")'>Clear</button></div>
          <div  *ngFor='let bs of balanceSheets' class='form-group'>
            <label>
              <input type="radio" [value]="bs.value" formControlName="balance_sheet_selection">
              <span>{{bs.name}}</span>
            </label>
          </div>
        </ng-container>
      </div>
      <div *ngIf='cashFlows.length'>
        <div class='form-group'>
          <label> <small>Cashflows </small></label><button class='btn btn-link pull-right no-focusborder clear-btn-style' (click)='clearSelection($event,"cashflow_selection")'>Clear</button></div>
        <ng-container>
          <div  *ngFor='let cf of cashFlows' class='form-group'>
            <label>
              <input type="radio" [value]="cf.value" formControlName="cashflow_selection">
              <span>{{cf.name}}</span>
            </label>
          </div>
        </ng-container>
      </div>

    </ng-container>
    <ng-container *ngFor="let relation of getBelongsToRelations()">
      <div class="form-group"
           *ngIf="!fixedValues[relation.foreign_key]"
           [ngClass]="{ 'has-danger': (fieldErrors[relation.foreign_key] && fieldErrors[relation.foreign_key].length)
                                            || (!entityForm.get(relation.foreign_key)?.untouched && entityForm.get(relation.foreign_key)?.status === 'INVALID') }">
        <label><small>{{ relation.label }}</small></label>
        <div>
          <con-belongs-to-form
            [parentCompany]="parentCompany"
            [ownerType]="ownerType"
            [entity]="obj"
            [form]="entityForm"
            [relation]="relation"
            [fromShareholder]="fromShareholder"
            (onSelect)="resetErrorsForRelation(relation, $event)">
          </con-belongs-to-form>
          <con-form-errors [control]="entityForm.get(relation.key)"
                           [extra]="fieldErrors[relation.foreign_key]">
          </con-form-errors>
        </div>
      </div>
    </ng-container>
    <ul class="list-group list-group-flush mb-2" *ngIf="entityAlreadyExists() && showWhenChanged">
      <li class="list-group-item">
        <strong>Updated at: </strong><con-date-shower [date]="obj['updated_at']"></con-date-shower> <con-avatar [userId]="obj['updated_by']" [size]="32"></con-avatar>
      </li>
      <li class="list-group-item">
        <strong>Created at: </strong><con-date-shower [date]="obj['created_at']"></con-date-shower> <con-avatar [userId]="obj['created_by']" [size]="32"></con-avatar>
      </li>
    </ul>
    <div class="alert alert-danger" *ngFor="let message of generalErrors">
      {{ message }}
    </div>
    <con-loader *ngIf="isSaving"></con-loader>
    <button *ngIf="showEditButton" type="button"
            [disabled]="entityForm.pristine" class="btn btn-success" (click)="onSubmit(false, true)">Save & Comment</button>
    <button type="submit"
            [disabled]="entityForm.pristine  && !initialSaveEnabled" class="btn btn-success">Save</button>
    <button type="reset" (click)="revert()"
            [disabled]="entityForm.pristine" class="btn btn-warning">Revert</button>
    <button type="button" *ngIf="_canDelete && entityAlreadyExists()" (click)="delete()"
            class="btn btn-danger">Delete</button>
    <a *ngIf="redirectButton.show" [routerLink]="redirectButton.link" class="btn btn-dark" target="_blank">{{redirectButton.label | titlecase}}</a>
  </form>
</div>
