import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { MissingCompanyEventsSettings, MissingInstitutionEventsSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { EntitiesService } from '../../entities/services/entities.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'con-static-company',
    templateUrl: './missing-events-search.component.html'
})

export class MissingEventsSearchComponent implements OnInit {
    public company: any;
    private loading: boolean;
    public view: string;
    public ownerType: string;

    constructor(private service: EntitiesService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private countService: CountService) {}

    public isLoading() {
        return this.loading;
    }

    ngOnInit() {
        this.countService.reloadSubject.subscribe(type => {
            if (type === this.ownerType) {
                this.loadEvents();
            }
        });
        this.loadEvents();
    }
    loadEvents () {
        this.route.params
                  .pipe(switchMap((params: Params) => {
                      this.loading = true;
                      let url: string;
                      if (this.router.url.indexOf('company') > -1 ) {
                          this.ownerType = 'Company';
                          url = MissingCompanyEventsSettings.BASE_URL + '/' +
                          MissingCompanyEventsSettings.MISSING_ENDPOINT + '/' + params.id ;
                      } else {
                        this.ownerType = 'Institution';
                        url = MissingInstitutionEventsSettings.BASE_URL + '/' +
                        MissingInstitutionEventsSettings.MISSING_ENDPOINT + '/' + params.id ;
                      }
                      return this.http.get(url) ;
                  }))
                  .subscribe(company => {
                      this.company = company;
                      this.loading = false;
                      this.countService.loadingCompleted = true;
                  },
                  err => {
                      if (err.status === 404) {
                          this.router.navigate(['static', 'error'], { skipLocationChange: true });
                      }
                      this.loading = false;
                  });
    }
    updateCompany(company: any) {
        this.company = company;
    }
}
