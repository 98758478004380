<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <con-icon-by-name iconName="line-chart" [mutations]="['fw']"></con-icon-by-name> {{ inconsistent.isin }}
            </div>
            <div class="card-body">
                <div class="card" *ngFor="let group of inconsistent.groups">
                    <div class="card-header">
                        {{ group.company.name }}
                    </div>
                    <div class="card-body">
                        <h3 class="card-title">Instruments</h3>
                        <div class="chip chip-link mr-1 mt-1" *ngFor="let i of group.instruments" [routerLink]="['/', 'entity', 'instrument', i.id]" ngbTooltip="Go to Instrument">
                            <con-icon-by-name iconName="line-chart" [mutations]="['lg']"></con-icon-by-name> {{ i.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                Mark all as:
                <con-loader *ngIf="loading"></con-loader>
                <ng-container *ngIf="!loading">
                    <div class="chip chip-link mr-1 mt-1" *ngFor="let c of companies" (click)="matchWith(c)" ngbTooltip="Choose Company">
                        <con-icon-by-name iconName="building" [mutations]="['lg']"></con-icon-by-name> {{ c.name }} <con-icon-by-name iconName="check" class="text-success"></con-icon-by-name>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>