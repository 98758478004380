<con-direkt-sync-events-filter (filterEvents)="filterEvents($event)" [isLoadingData]="isLoading"></con-direkt-sync-events-filter>

<ng-container *ngIf="isLoading; else dataList">
    <con-loader *ngIf="isLoading"></con-loader>
</ng-container>
<ng-template #dataList>
    <div class="card mb-2" *ngFor="let event of syncEventList?.data; let index = index">
        <div class="card-header cursor-pointer" (click)="toggleAccordion(index, event)">
            <div class="d-flex justify-content-start align-items-center">
                <ng-container *ngIf="!event?.calendar_event?.deleted_by" >
                    <con-icon-by-name iconName="external-link" class="mr-2" (click)="openCalendarEvent(event?.calendar_event?.id, $event)"></con-icon-by-name>
                </ng-container>
                <ng-container *ngIf="event?.calendar_event">
                    {{ event?.calendar_event?.owner?.name }}, {{ event?.calendar_event?.calendar_event_type?.name }} 
                    ( <con-icon-by-name iconName="clock-o" class="mx-1" placement="bottom" [ngbTooltip]="currentTimezone"> </con-icon-by-name> {{ event?.calendar_event?.from_date | convertToTimezone:'yyyy-MM-DD HH:mm:ss':currentTimezone }} )
                </ng-container>
                <span class="ml-2 badge" [ngClass]="{'ml-status-in-progress': event.event === 'updated', 'ml-status-closed': event.event === 'created', 'ml-status-failed': event.event === 'deleted'}">
                    {{ event.event | uppercase }}
                </span>
                <span *ngIf="event?.direkt_response_code" class="ml-2 badge" [ngClass]="{'badge-success': event.direkt_response_code < 400, 'badge-danger': event.direkt_response_code >= 400}">
                    {{ event?.direkt_response_code }}
                </span>
                <div class="card-header-toggle-individual ml-auto" *ngIf="event?.calendar_event || event?.request_body || event?.direkt_response_body">
                    <con-icon-by-name iconName="angle-right" [mutations]="eventExpandList.includes(index) ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                </div>
            </div>
        </div>
        <div class="p-4 col-12" *ngIf="eventExpandList.includes(index)">
            <div class="row">
                <div class="col-md-6" *ngIf="event?.request_body">
                    <div class="card-header mt-2">
                        <div class="d-flex justify-content-start align-items-center">
                            Direkt: Request
                            <div class="card-header-toggle-individual ml-auto">
                                <con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyToClipboard(event, 'request_body')"></con-icon-by-name>
                            </div>
                        </div>
                    </div>
                    <con-object-inspector [object]="event?.request_body" [isReadOnly]="true" [inputDataType]="'xml'"></con-object-inspector>
                </div>
                <div class="col-md-6" *ngIf="event?.direkt_response_body">
                    <div class="card-header mt-2">
                        <div class="d-flex justify-content-start align-items-center">
                            Direkt: Response
                            <div class="card-header-toggle-individual ml-auto">
                                <con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyToClipboard(event, 'direkt_response_body')"></con-icon-by-name>
                            </div>
                        </div>
                    </div>
                    <con-object-inspector [object]="event?.direkt_response_body || null" [isReadOnly]="true" [inputDataType]="'xml'"></con-object-inspector>
                </div>
            </div>
            <ng-container *ngIf="event?.calendar_event">
                <div class="card-header cursor-pointer mt-2" (click)="toggleSnapshot(index)">
                    <div class="d-flex justify-content-start align-items-center">
                        Snapshot
                        <div class="card-header-toggle-individual ml-auto">
                            <con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyToClipboard(event, 'snapshot', $event)"></con-icon-by-name>
                        </div>
                        <div class="card-header-toggle-individual ml-2">
                            <con-icon-by-name iconName="angle-right" [mutations]="snapshotExpandList.includes(index) ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="snapshotExpandList.includes(index)">
                    <con-object-inspector [object]="event?.calendar_event" [isReadOnly]="true" [inputDataType]="'json'"></con-object-inspector>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <con-no-data [count]="syncEventList?.total || 0" [message]="'No data to display.'"></con-no-data>
    <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!syncEventList?.total}">
        <ngb-pagination [collectionSize]="syncEventList?.total" [(page)]="currentPage" (pageChange)="pageChanged($event)" [pageSize]="syncEventList?.per_page" maxSize="10" [rotate]="true"></ngb-pagination>
    </div>
    <div class="d-flex justify-content-center" *ngIf="syncEventList?.total">
       <small>Showing {{syncEventList?.from}} to {{syncEventList?.to}} of {{syncEventList?.total}} entries</small>
    </div>
</ng-template>