import {AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChildren} from '@angular/core';
import {TaxonomyModule} from '../../doc-process-common/models/taxonomy-module';
import {TaxonomyFieldListService} from '../services/taxonomy-field-list.service';
import {filter, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {HighlightEvent} from '../../doc-process-common/models/highlight-event';
import {HighlightEventType} from '../../doc-process-common/models/highlight-event-type';
import {AnnotationModel} from '../../doc-process-common/models/annotation-model';
import {FundamentalsStatementService} from '../services/fundamentals-statement.service';
import {UtilService} from '../../doc-process-common/services/util.service';
import {PersistentRange} from '../../doc-process-common/models/persistent-range';
import {IdentifierModel} from '../../doc-process-common/models/identifier-model';
import {InstanceService} from '../services/instance.service';
import {DecimalSeparator} from '../../doc-process-common/models/decimal-separator';
import {ChangeDetectorRef } from '@angular/core';
import {ComponentOptionsService} from '../services/component-options.service';
import {LabelingHelperService} from "../services/labeling-helper.service";

@Component({
  selector: 'con-doc-process-taxonomy-field',
  template: `
    <div *ngIf="aboveChildren | async">
      <con-doc-process-taxonomy-field #childModule *ngFor="let module of aboveChildren | async" [moduleData]="module"
                                      [treeLevel]="treeLevel + 1"></con-doc-process-taxonomy-field>
    </div>

    <div class="border-top {{ !!moduleData?.level ? ' border-dark' : '' }}">
      <div class="row mx-0">
        <div class="col-color" [ngStyle]="{ backgroundColor: fieldColor }"></div>
        <div class="col">
          <ng-container *ngIf="{isFocused$: isFocused$ | async} as data">
          <div class="row"
               style="cursor: pointer"
               [style.padding-left]="treeLevel / 2 + 'rem'"
               [ngClass]="{'focused' : isFocused$ | async, 'background-module': !moduleData.is_highlighted }"
          >
            <div
              class="col-6 pr-1 pl-1"
              [ngClass]="{'font-weight-lighter': !moduleData.is_highlighted,
              'font-weight-bold': this.moduleData.level}"
              ngbPopover="For this instance, the system does not require you to annotate non-KPI fields."
              container="body"
              [openDelay]="500"
              [closeDelay]="0"
              triggers="{{!moduleData.is_highlighted ? 'mouseenter:mouseleave' : ''}}"

            >{{ moduleData.name }}</div>
              <ng-container *ngIf="{
               annotationsSum: annotationsSum$ | async,
               displayDeltaSanity: displayDeltaSanity$ | async,
               childrenConsolidation: childrenConsolidation$ | async,
               levelSum: levelSum$ | async,
               lastSelected$: lastSelected$,
               hasSanityError: hasSanityError
                }
                as data">

              <div class="col-2 px-1">
                <con-fundamentals-sanity-delta
                  *ngIf="data.displayDeltaSanity === true && labelingHelperService.isDeltaDisplayed$ | async"
                  [data]="data"
                  [moduleData]="moduleData"
                ></con-fundamentals-sanity-delta>
              </div>

              <con-fundamentals-field-primary-value
                [moduleData]="moduleData"
                [data]="data"
                class="col-3 px-1"
              >

              </con-fundamentals-field-primary-value>
            </ng-container>

            <!--number of annotations-->
            <div class="col-1 px-1 text-right">
              <span *ngIf="(nOfAnnotations | async) >= 1; else zeroAnnotations"
                    class="badge badge-info">{{ nOfAnnotations | async }}</span>
            </div>
          </div>
          </ng-container>
          <con-field-annotations-list *ngIf="isFocused$ | async"
                                      [annotationList]="annotationList"
          ></con-field-annotations-list>
        </div>
      </div>
      <!--      <div class="col-3" style="background-color: beige">{{ moduleData.value_rep }}</div>-->
      <con-icon-by-name [iconName]="'long-arrow-right'" [hidden]="true" class="float-left"
                        style="color: black;"></con-icon-by-name>
    </div>

    <div class="mr-0 ml-0" style="display: grid" *ngIf="belowChildren | async">
      <con-doc-process-taxonomy-field #childModule *ngFor="let module of belowChildren | async" [moduleData]="module"
                                      [treeLevel]="treeLevel + 1"></con-doc-process-taxonomy-field>
    </div>

    <ng-template #zeroAnnotations></ng-template>
  `,
  styles: [
    `
      :host.disabled {
          opacity: 0.5;
          pointer-events: none;
      }
      .col-color {
        flex: 0 0 10px;
        max-width: 10px;
      }
      .background-module {
        color:#a9a9a9 !important;
          background-color: #f6f6f6;
      }
      .focused {
          background-color: #d1ecf1;
      }
    `,
  ],
})
export class DocProcessTaxonomyFieldComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() moduleData: TaxonomyModule;
  @Input() treeLevel: number;
  @Input() isUnavailable;
  @ViewChildren('childModule') childrenModules: Array<DocProcessTaxonomyFieldComponent>;
  public isFocused$: Observable<boolean>;
  private subscribeUntil: Subject<boolean> = new Subject();
  public fieldColor: string;
  public annotationList$: Observable<Array<AnnotationModel>>;
  public annotationList: Array<AnnotationModel>;
  public aboveChildren: BehaviorSubject<Array<TaxonomyModule>> = new BehaviorSubject<Array<TaxonomyModule>>([]);
  public belowChildren: BehaviorSubject<Array<TaxonomyModule>> = new BehaviorSubject<Array<TaxonomyModule>>([]);
  private componentInstanceLoadedAnnotations: boolean = false;
  public annotationsSum$: Observable<number | null>;
  public childrenConsolidation$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public moduleValue$: Observable<number | null>;
  public displayDeltaSanity$: Observable<boolean | null>;
  public hasSanityError: boolean = false;
  public levelSum$: Observable<number | null> = of(null);
  public lastSelected$ = new BehaviorSubject<number | null>(null);

  get nOfAnnotations(): Observable<number> { // TODO rename to nOfAnnotations$
    return this.annotationList$.pipe(
      map((annotations: AnnotationModel[]) => {
        return annotations.length;
      })
    );
  }

  constructor(
    public taxonomyFieldListService: TaxonomyFieldListService,
    public labelingHelperService: LabelingHelperService,
    public fundamentalsStatementService: FundamentalsStatementService,
    private instanceService: InstanceService,
    private cdref: ChangeDetectorRef,
    public componentOptionsService: ComponentOptionsService,
  ) {
    this.isFocused$ = this.taxonomyFieldListService.focusedTaxonomyModulePosition.pipe(
      map( (focusedModulePosition: number) => {
        return focusedModulePosition === this.moduleData?.position
      })
    );

    combineLatest([fundamentalsStatementService.markEventStream, this.isFocused$])
      .pipe(takeUntil(this.subscribeUntil))
      .subscribe((markEventAndIsFocusedCombined: [HighlightEvent, boolean]) => {
        let highlightEvent: HighlightEvent = markEventAndIsFocusedCombined[0];
        let isFocused$: boolean = markEventAndIsFocusedCombined[1];

        if (!isFocused$) return;

        if (highlightEvent.eventType === HighlightEventType.CreateHighlight) {
          if (
            FundamentalsStatementService.isMultipleNode(highlightEvent) ||
            FundamentalsStatementService.isMarkOverlapping(highlightEvent) ||
            FundamentalsStatementService.isMarkEmpty(highlightEvent)
          ) {
            return;
          }

          const parentElement = highlightEvent.range.startContainer.parentElement;
          let annotationPersistentRange = PersistentRange.fromRange(highlightEvent.range);
          this.fundamentalsStatementService.createAnnotation(annotationPersistentRange, this.moduleData);
        }
      });

    fundamentalsStatementService.onMarkClick
      .pipe(
        takeUntil(this.subscribeUntil),
        withLatestFrom(this.isFocused$),
        filter(([markGuid, isFocused$]: [IdentifierModel, boolean]) => !!isFocused$)
      )
      .subscribe(([markGuid, isFocused$]: [IdentifierModel, boolean]) => {
        const clickedMark = this.fundamentalsStatementService.documentMarks.getValue().find((mark) => mark.id.toString() === markGuid.toString());
        const duplicateAnnotation = clickedMark.annotationBindings.find((annotation) => annotation.module.module_id === this.moduleData.module_id);
        if (!!duplicateAnnotation) return;

        const newAnnotation = new AnnotationModel(this.moduleData, this.fundamentalsStatementService);
        this.fundamentalsStatementService.addAnnotationToMark(markGuid, newAnnotation)
        fundamentalsStatementService.addAnnotation(newAnnotation);

      })
  }

  ngOnInit(): void {
    this.fieldColor = UtilService.generateRandomLightColor(this.moduleData.unique_id);

    this.annotationList$ = this.fundamentalsStatementService.getAnnotationListByModuleId(this.moduleData.module_id);
    this.annotationList$.pipe(takeUntil(this.subscribeUntil)).subscribe(list => {
      this.annotationList = list
      if (this.labelingHelperService.hasSanityCheck()) {
        this.hasSanityError = !this.fundamentalsStatementService.sanityValidation(this.annotationList);
      }
    });
    this.isFocused$.pipe(takeUntil(this.subscribeUntil),
      withLatestFrom(this.annotationList$))
      .subscribe( ([isFocused$, annotationList$]: [boolean, Array<AnnotationModel>]) => {
        if (isFocused$) {
          this.fundamentalsStatementService.focusedMarkId.next(annotationList$?.[0]?.getMark()?.id)
          this.fundamentalsStatementService.scrollToMark.next(annotationList$?.[0]?.getMark()?.id)
        }
      });

   this.annotationsSum$ = combineLatest([
      this.fundamentalsStatementService.filterAnnotationsByModuleId(this.fundamentalsStatementService.getAnnotationListAfterMarkSubmitted(), this.moduleData.module_id),
      this.fundamentalsStatementService.decimalSelector
    ])
    .pipe(
      map( ([annotationList$, decimalSelector]: [AnnotationModel[], DecimalSeparator]) => {
        return annotationList$.map( (annotation: AnnotationModel) => {
          let result = annotation.toConsolidationFactor(decimalSelector);

          if (this.labelingHelperService.hasIndividualQuantifiers()) {
            result = result * annotation.quantity.multiplier
          }
          return result
        })
        .reduce((accumulator, currentValue) => {
          this.lastSelected$.next(currentValue); //todo: find better place to do this
          return accumulator + currentValue}, null)
      })
      )

    if (this.moduleData.level)
      this.levelSum$ = this.getLevelSumOfField()

    this.moduleValue$ = combineLatest([this.childrenConsolidation$, this.annotationsSum$, this.levelSum$])
    .pipe(
      map(([childrenConsolidation, annotationsSum, levelSum] : [number | null, number | null, number | null]) => {
        if (annotationsSum !== null && annotationsSum !== undefined && annotationsSum !== 0)
          return annotationsSum
        else if(childrenConsolidation)
          return childrenConsolidation
        else
          return levelSum
      })
    )

    if (this.treeLevel === 0){
      this.submitModuleValueToService()
    }
  }

  ngOnChanges(): void {
    this.aboveChildren.next(this.moduleData?.children?.filter((childModule: TaxonomyModule) => childModule.position < this.moduleData.position).sort(TaxonomyModule.compare));
    this.belowChildren.next(this.moduleData?.children?.filter((childModule: TaxonomyModule) => childModule.position > this.moduleData.position).sort(TaxonomyModule.compare));
  }

  ngAfterViewInit() {
    let childrenModulesValueArray = this.childrenModules.map((childModule: DocProcessTaxonomyFieldComponent) => {
      return childModule.moduleValue$
    })

    combineLatest(childrenModulesValueArray).pipe(
      map((val: Array<number>) => {
        let sumOfChildrenModules = val.reduce((a, b) => a + b, 0)
        return sumOfChildrenModules
      })
    ).subscribe((sumOfChildrenModules) => {
      this.childrenConsolidation$.next(sumOfChildrenModules)
    })

    this.displayDeltaSanity$ = combineLatest([this.childrenConsolidation$, this.annotationsSum$, this.levelSum$])
    .pipe(
      map(([childrenConsolidation, annotationsSum, levelSum] : [number | null, number | null, number | null]) => {
        let numbersToCompare: Array<number> = []

        if (!!childrenConsolidation) {
          numbersToCompare.push(childrenConsolidation)
        }
        if (!!annotationsSum) {
          numbersToCompare.push(annotationsSum)
        }
        if (!!levelSum) {
          numbersToCompare.push(levelSum)
        }

        if (numbersToCompare.length <= 1) { // nothing to compare
          return false
        }

        return true
      })
    )

    this.cdref.detectChanges();
  }

  public static isDifferenceBiggerThan10Percent(a: number, b: number) {
    if ((a/b > 1.1 || a/b < 0.9 || b/a > 1.1 || b/a < 0.9) && !!a && !!b)
      return true
    else
      return false
  }

  ngOnDestroy(): void {
    this.subscribeUntil.next(true);
  }

  @HostListener('click', ['$event']) onClick($event) {
    $event.stopPropagation();
    if (this.moduleData.is_highlighted) {
      this.taxonomyFieldListService.setOnModuleClick(this.moduleData);
    }
  }

  private static elementIsInsideATable(element: HTMLElement) {
    if (element.tagName.toLowerCase() === 'td') return true;
    else if (element.tagName.toLowerCase() === 'body') return false;
    else return DocProcessTaxonomyFieldComponent.elementIsInsideATable(element.parentElement);
  }

  private submitModuleValueToService() {
    this.moduleValue$.pipe(takeUntil(this.subscribeUntil)).subscribe((moduleValue: number) => {
      let topLevelValues = this.taxonomyFieldListService.topLevelValues.getValue()
      if (topLevelValues[this.moduleData?.position]?.[1] === moduleValue && moduleValue !== null && moduleValue !== undefined )
        return
      topLevelValues[this.moduleData?.position] = [this.moduleData.level, moduleValue]
      this.taxonomyFieldListService.topLevelValues.next(topLevelValues)
    })
  }

  private getLevelSumOfField(): Observable<number> {
    return this.taxonomyFieldListService.topLevelValues.pipe(
      map( (topLevelValues: { [i: number]: [boolean, number]; }) => {
        let levelSum = 0
        for (const [key, value] of Object.entries(topLevelValues)){
          if (key === this.moduleData.position.toString())
            return levelSum

          if (value[0] === true)
            levelSum = value[1]
          else
            levelSum += value[1]
        }
      })
    )
  }

}
