import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';

import { HttpClient } from '@angular/common/http';

import { StaticSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EntityConfirmModalComponent} from '../../entities/components/views/entity-confirm-modal.component';
import {ToastrService} from 'ngx-toastr';
import {StaticService} from '../services/static.service';
import {take} from 'rxjs/operators';

import {Router} from '@angular/router';

const MISSING_MODE = 'missing';
const REVISION_MODE = 'revision';

@Component({
    selector: 'con-static-editor',
    templateUrl: './static-editor.component.html',
    styles: [`.small-size { font-size: 12px; }`]
})

export class StaticEditorComponent implements OnChanges, OnInit {
    @Input() company: any;
    @Input() mode: string = MISSING_MODE;
    @Input() tabName: string = 'all';
    @Input() sectors: any = [];

    @Output() afterFixed: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    public loading: boolean;
    public showPositionEditor = false;
    private missingLanguages: any;
    private missingPositions: any;
    private missingAdvisors: any;

    public languages: any = [];
    public positions: any = [];
    public advisors: any = [];

    public noDataMessage = 'No data to display.';

  constructor(private http: HttpClient,
      public profile: ProfileService,
      private modalService: NgbModal,
      private toaster: ToastrService,
      public staticService: StaticService,
      private router: Router,
      private countService: CountService) { }

  ngOnInit() {
    if (this.company && this.company.positions && !this.company.isRoleFormated) {
      this.company.isRoleFormated = true
    }
    // checking missing
    if (this.isMissingMode() && this.missingPositions.length && !this.company.isMissingPositionFormated) {
      this.company.isMissingPositionFormated = true
      this.missingPositions.forEach(pos => {
        if (pos.type_name) {
          pos.name = `${pos.name}, ${pos.type_name}`
        }
      });
    }
    this.showPositionEditor = true;

    }

    ngOnChanges() {
        this.viewChanged(false);
    }

    onViewDidChanged() {
        this.viewChanged(false)
    }

    viewChanged(afterFormUpdate: boolean = false) {

    if (afterFormUpdate) {
      // get count from api response
      this.updateMissingCount();
    }

        this.missingLanguages = [];
        if(this.company.required_languages) {
            this.company.required_languages.forEach(language => {
                if (this.company.descriptions.findIndex(d => d.language_id === language.id) === -1) {
                    this.missingLanguages.push(language);
                }
            });
        }
        this.missingPositions = [];
        if(this.company.required_roles) {
            this.company.required_roles.forEach(roles => {
                if (this.company.positions.findIndex(d => d.company_role_id === roles.id) === -1) {
                    this.missingPositions.push(roles);
                }
            });
        }

        this.missingAdvisors = [];
        if(this.company.required_advisor_types) {
            this.company.required_advisor_types.forEach(type => {
                if (this.company.company_advisors.findIndex(d => d.company_advisor_type_id === type.id) === -1) {
                    this.missingAdvisors.push(type);
                }
            });
        }

        if (this.isMissingMode()) {
            this.languages = this.missingLanguages;
        } else {
            if(this.company.descriptions) {
                this.languages = this.company.descriptions.map(d => d.language);
            }
        }

        if (this.isMissingMode()) {
            this.positions = [];
            this.advisors = [];
            this.missingPositions.forEach(pos => {
                this.positions.push({
                    company_id: this.company.id,
                    company_role_id: pos.id,
                    company_role: pos,
                    person_name: '',
                    revised_at: null
                })
            });

      this.missingAdvisors.forEach(pos => {
        this.advisors.push({
          company_id: this.company.id,
          company_advisor_type_id: pos.id,
          company_advisor_type: pos,
          name: '',
          revised_at: null
        })
      });
    } else {
      if(this.company.positions) {
        this.company.positions.forEach((pos) => {
          pos.company_role.user_id = pos.id;
          /**
           * name key in company role is used as a combination of name key ex. "CA" and  type_name ex. Management.
           * Since we dont have a concatenated key from backend and name is used as input in the form we need to setup
           * a temporary key called _name which used to hold the original value.
           * If _name is available, it is used to replace the name key
           * to avoid repeated concatenation.
           */
          pos.company_role.hasOwnProperty('_name') ? pos.company_role.name = '' : pos.company_role._name =  pos.company_role.name;
          pos.company_role.name = `${pos.company_role._name}, ${pos.company_role.type_name}`;
        });
        this.positions = this.company.positions.map(p => p.company_role);
      }
      this.advisors = this.company.company_advisors;
    }

        if (this.isMissingMode()) {
            this.checkFixed();
        }
    }

    isMissingMode() {
        return this.mode === MISSING_MODE;
    }

    getDescriptionByLanguage(languageId: any) {
        return this.company.descriptions.find(d => d.language_id === languageId);
    }

    getRequiredPositions() {
        return StaticSettings.REQUIRED_COMPANY_POSITIONS;
    }

    showWebsiteForm() {
        if (this.isMissingMode()) {
            return this.company.website === null;
        }
        return this.company.website != null;
    }

    showEmailForm() {
        if (this.isMissingMode()) {
            return this.company.email === null;
        }
        return this.company.email != null;
    }

    showPhoneForm() {
        if (this.isMissingMode()) {
            return this.company.phone === null;
        }
        return this.company.phone != null;
    }

    showAddressForm() {
        if (this.isMissingMode()) {
            return this.company.address === null;
        }
        return this.company.address != null;
    }

    showFoundedForm() {
        if (this.isMissingMode()) {
            return this.company.founded_at === null;
        }
        return this.company.founded_at != null;
    }

    showCountryForm() {
        if (this.isMissingMode()) {
            return this.company.country_id === null;
        }
        return this.company.country_id != null;
    }

    showSectorForm() {
      if (this.isMissingMode()) {
        return this.company.infront_sector_l1_id === null;
      }
      return this.company.infront_sector_l1_id != null;
    }

    showApproveButton() {
        const hasPositions = this.positions && this.positions.length > 0;
        const hasLanguages = this.languages && this.languages.length > 0;
        const hasWebsite = this.company.website !== null;
        const hasEmail = this.company.email !== null;
        const hasPhone = this.company.phone !== null;
        const hasAddress = this.company.address !== null;
        const hasFounded = this.company.founded_at !== null;
        const hasCountry = this.company.country_id !== null;
        const hasSectors = this.company.infront_sector_l1_id !== null;
        return !this.isMissingMode() && this.canShowEditorComponent() && ( hasLanguages || hasPositions || hasWebsite
            || hasEmail || hasPhone || hasAddress || hasFounded || hasCountry || hasSectors);
    }

    approve() {
        this.loading = true;
        const formatedTabName = this.tabName.replace(' ', '_')
        this.http.get(`${StaticSettings.BASE_URL}/${StaticSettings.APPROVE_ENDPOINT}/${this.company.id}/${formatedTabName}`)
            .subscribe(company => {
                this.loading = false;
                this.afterFixed.emit(this.company);
            }, err => {
                this.loading = false;
                console.log(err);
            });
    }

    hasPositionData(position: string) {
        return this.company.positions.filter(p => p.position === position).length > 0;
    }

  getPositionData(position: any) {
    if (!this.isMissingMode()) {
      return this.company.positions.find(p => p.id === position.user_id) || null;
    }
  }
  checkFixed() {
    if(!this.canShowEditorComponent() && this.isMissingMode()) {
      const formatedTabName = this.tabName.replace(' ', '_')
      this.http.get(StaticSettings.BASE_URL + '/' + StaticSettings.MISSING_DONE_ENDPOINT + '/' + this.company.id + '/'+ formatedTabName)
        .subscribe();
      this.afterFixed.emit(this.company);
    }
  }

    websiteUpdated(company: any) {
        this.company.website = company.website;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    emailUpdated(company: any) {
        this.company.email = company.email;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    phoneUpdated(company: any) {
        this.company.phone = company.phone;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }
    sectorUpdated(company: any) {
      this.company.infront_sector_l1_id = company.infront_sector_l1_id;
      this.company.infront_sector_l2_id = company.infront_sector_l2_id;
      this.company.updated_at = company.updated_at;
      this.company.revised_at = company.revised_at;
      this.afterUpdate.emit(this.company);
      this.viewChanged(true);
    }
    addressUpdated(company: any) {
        this.company.address = company.address;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    foundedUpdated(company: any) {
        this.company.founded_at = company.founded_at;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    countryUpdated(company: any) {
        this.company.country_id = company.country_id;
        this.company.updated_at = company.updated_at;
        this.company.revised_at = company.revised_at;
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    updateDescription(description: any) {
        const indx = this.company.descriptions.findIndex(d => d.id === description.id);
        if (indx > -1) {
            this.company.descriptions[indx] = description;
        } else {
            this.company.descriptions.push(description);
        }
        this.afterUpdate.emit(this.company);
        this.viewChanged(true);
    }

    updatePosition(position: any) {
        const indx = this.company.positions.findIndex(p => p.id === position.id);
        if (indx > -1) {
            this.company.positions[indx] = position;
        } else {
            this.company.positions.push(position);
        }
        this.viewChanged(true);
    }

    // merge updatePosition and updateAdvisor methods later
    updateAdvisor(advisor: any) {
        const indx = this.company.company_advisors.findIndex(p => p.id === advisor.id);
        if (indx > -1) {
            this.company.company_advisors[indx] = advisor;
        } else {
            this.company.company_advisors.push(advisor);
        }
        this.viewChanged(true);
    }

    removePosition(position: any) {
        // This method is also used for company advisors change
        this.viewChanged(true);
    }

    removeDescription(description: any) {
        const indx = this.company.descriptions.findIndex(d => d.id === description.id);
        this.company.descriptions.splice(indx, 1);
        this.viewChanged(true);
    }

    showMissingMessage(): boolean {
        return (
          this.isMissingMode() &&
          !this.languages.length &&
          !this.positions.length &&
          !this.advisors.length  &&
          !this.showWebsiteForm() &&
          !this.showEmailForm() &&
          !this.showPhoneForm() &&
          !this.showAddressForm() &&
          !this.showFoundedForm() &&
          !this.showCountryForm() &&
          !this.showSectorForm()
        );
      }

    canShowComponent(groupName: string){
        if(this.tabName === 'all' || this.tabName === groupName)
        {
            return true; // show all component if all tab is selected
        } else {
            return false
        }
    }

    canShowEditorComponent() {
        // If no data to show under selected tab, then no need to show this page
        switch (this.tabName) {
          case "all":
              return(
                this.languages.length ||
                this.positions.length ||
                this.advisors.length ||
                this.showWebsiteForm() ||
                this.showEmailForm() ||
                this.showPhoneForm() ||
                this.showAddressForm() ||
                this.showFoundedForm() ||
                this.showCountryForm() ||
                this.showSectorForm()
              )
            break;
          case "company data":
              return (
                this.showWebsiteForm() ||
                this.showEmailForm() ||
                this.showPhoneForm() ||
                this.showAddressForm() ||
                this.showFoundedForm() ||
                this.showCountryForm() ||
                this.showSectorForm()
              )
          case "positions":
              return this.positions.length;
          case "advisors":
            return this.advisors.length
          case "description":
            return this.languages.length
        }
    }
  // check the mode ie missing or revision and redirect the company page with the specific mode
  redirectToCompanyPage(id: number, isMissingMode: boolean): void {
    // check if the current page is already in company missing or revision view, if true we send navigation to entity page
    if (this.router.url.includes('view=missing') || this.router.url.includes('view=revision')) {
      this.router.navigateByUrl('entity/company/' + id)
      return;
    }
    // check if mode is missing mode
    if (isMissingMode) {
      this.router.navigateByUrl('static/' + id + '?view=missing');
      return;
    } else {
      // here mode is revision
      this.router.navigateByUrl('static/' + id + '?view=revision')
      return;
    }
  }

  getCompanyISINs(company: any): string {
    if (company.hasOwnProperty('instruments') && company.instruments) {
      return (company.instruments as Array<any>).map((isinData) => isinData.isin).join(', ');
    }
    return '';
  }
  updateIgnoreMissingField(company: any, ref: HTMLInputElement) {
    const currentCompanyIgnoredStatus = company.ignore_missing;
    const modalRef = this.modalService.open(EntityConfirmModalComponent,
      {
        size: 'md'
      });
    const data = {
      title: currentCompanyIgnoredStatus ? 'Remove from ignored list' : 'Add to ignored list',
      message: currentCompanyIgnoredStatus ? 'Are you sure you want to remove from ignored list?': 'Are you sure you want to add to ignored list?',
      alert: false
    }
    modalRef.componentInstance.modalData = data;
    modalRef.result.then(() => {
        const ignoreMissingPatchValue = !currentCompanyIgnoredStatus ? '1' : '0';
        this.http.put(`${StaticSettings.BASE_URL}/${StaticSettings.MISSING_ENDPOINT}/update_missing/${this.company.id}/?ignore_missing=${ignoreMissingPatchValue}`, null)
          .subscribe((response: any) => {
            if (response.hasOwnProperty('status')) {
              if (response.status === 'SUCCESS') {
                this.company.ignore_missing = !currentCompanyIgnoredStatus;
                if (this.staticService.getMissingFilter() === 'ignored') {
                  this.afterFixed.emit(this.company);
                }
                this.updateMissingCount();
              }
            }
            this.toaster.success(!currentCompanyIgnoredStatus ? 'Successfully added to ignored list' :  'Successfully removed from ignored list' );
          }, err => {
            console.log(err);
            ref.checked = currentCompanyIgnoredStatus;
            this.toaster.error('Sorry, some error occurred', !currentCompanyIgnoredStatus ? 'Add to ignore list' : 'Remove from ignore list');
          });
      },
      (reason) => {
        ref.checked = currentCompanyIgnoredStatus;
      })
  }
  showCompanyDetailForIgnoredMissing(): boolean {
    if (this.isMissingMode() && this.staticService.getMissingFilter() === 'all' && this.company.ignore_missing) {
      if ( this.showWebsiteForm() ||
        this.showEmailForm() ||
        this.showPhoneForm() ||
        this.showAddressForm() ||
        this.showFoundedForm() ||
        this.showSectorForm() ||
        this.showCountryForm()) {
        return false;
      }
    }
    return true;
  }
  hideCompanyMissingForOnlyIgnored(): boolean {
    if (!this.company.ignore_missing && this.staticService.getMissingFilter() === 'ignored'
      && this.isMissingMode()
      && (this.tabName === 'all' || this.tabName === 'company data')) {
      return false;
    }

    // if non company data is filled & in all filter ignored missing is true
    if (this.company.ignore_missing && this.isMissingMode()
      && !this.languages.length && !this.positions.length
      && !this.advisors.length  && this.staticService.getMissingFilter() === 'all'
      && (this.tabName === 'all' || this.tabName === 'company data')) {
      return false;
    }
    return true;
  }
  updateMissingCount() {
    const url = this.staticService.getEndpoint(this.tabName, 'static')
    const params = {
      list_filter: this.staticService.getMissingFilter()
    }
    // check
    if (this.tabName === 'all' || this.tabName === 'company data') {
      this.http.get<any>(url, { params }).pipe(take(1)).subscribe(res => {
        this.countService.setCount('missing', res.total);
      })
    } else {
      this.http.get<any>(url).pipe(take(1)).subscribe(res => {
        this.countService.setCount('missing', res.total);
      })
    }
  }

  canShowIgnoreCheckbox() {
    if ((this.tabName === 'all' || this.tabName === 'company data') && (
        !this.showWebsiteForm() &&
        !this.showEmailForm() &&
        !this.showPhoneForm() &&
        !this.showAddressForm() &&
        !this.showFoundedForm() &&
        !this.showCountryForm()) &&
        !this.showSectorForm() &&
      this.isMissingMode()
    ) {
      return false;
    }
    if (this.tabName !== 'all' && this.tabName !== 'company data' && this.isMissingMode()) {
      return false;
    }
    if (!this.isMissingMode()) {
      return false;
    }
    return true;
  }

  copyISIN(event, companyISINs: string) {
    navigator.clipboard.writeText(companyISINs);
    const successMsg = companyISINs + ' ISIN successfully copied to clipboard.'
    this.toaster.success(successMsg);
  }
}
