<div class="row">
    <div class="col-12">
        <div class="card mb-2">
            <div class="card-header">    
                <con-icon-by-name iconName="line-chart" [mutations]="['fw']"></con-icon-by-name> {{ match.isin }}
            </div>
            <con-loader *ngIf="loading"></con-loader>
            <div class="card-body" *ngIf="!loading && isExpanded()">
                <h5 class="card-title">Instrument names</h5>
                <div class="chip chip-link mr-1 mt-1" *ngFor="let instrument of match.instruments" [routerLink]="['/', 'entity', 'instrument', instrument.id]" ngbTooltip="Go to Instrument">
                    <con-icon-by-name iconName="line-chart" [mutations]="['lg']"></con-icon-by-name> {{ instrument.name }}
                </div>
                <hr>
                <ng-container *ngIf="match.candidates.similar_names.length">
                    <h5 class="card-title">Companies with similar names</h5>
                    <div class="chip chip-link mr-1 mt-1" *ngFor="let company of match.candidates.similar_names" (click)="matchWith(company)" ngbTooltip="Match with Company">
                        <con-icon-by-name iconName="building" [mutations]="['lg']"></con-icon-by-name> {{ company.name }} ({{ company.id }}) <con-icon-by-name class="text-success" iconName="check" [mutations]="['lg']"></con-icon-by-name>
                    </div>
                    <hr>
                </ng-container>
                <ng-container *ngIf="match.candidates.same_isin.length">
                    <h5 class="card-title">Companies with same ISIN</h5>
                    <div class="chip chip-link mr-1 mt-1" *ngFor="let company of match.candidates.same_isin" (click)="matchWith(company)"  ngbTooltip="Match with Company">
                        <con-icon-by-name iconName="building" [mutations]="['lg']"></con-icon-by-name> {{ company.name }} ({{ company.id }}) <con-icon-by-name class="text-success" iconName="check" [mutations]="['lg']"></con-icon-by-name>
                    </div>
                    <hr>
                </ng-container>
                <h5 class="card-title">Other company</h5>
                <con-entity-search-field
                    entityName="Company"
                    (onSelect)="matchWith($event)"
                    >
                </con-entity-search-field>
                <hr>
                <h5 class="card-title">Mark as inactive</h5>
                <div class="form-group">
                    <con-flexible-input
                        [field]="inactiveField"
                        [control]="inactiveFormControl">
                    </con-flexible-input>
                </div>
                <button type="button"
                        [disabled]="inactiveFormControl?.pristine"
                        (click)="markAsInactive()"
                        class="btn btn-success">
                    Save
                </button>
                <hr>
                <h5 class="card-title mb-0">New company</h5>
                <con-entity-form-content
                    entityName="Company"
                    [only]="['name']"
                    [emptyAfterSave]="true"
                    (afterSave)="matchWith($event)"
                    ></con-entity-form-content>
            </div>
        </div>
    </div>
</div>
