<con-loader *ngIf="loading"></con-loader>
<con-pretty-entity-shower
    *ngIf="!loading && existing"
    [entity]="relatedObject"
    [entityName]="relatedType"
    [actions]="actions"
    (onAction)="handleAction($event)">
</con-pretty-entity-shower>
<div *ngIf="!loading && !existing" class="card-body">
    <h5><con-icon-by-name iconName="plug"></con-icon-by-name> Attach</h5>
    <ng-container *ngFor="let inverseRelation of inverseRelations">
        <con-entity-search-field
            [parentEntity]="entity"
            (onSelect)="addRelation(inverseRelation, $event)"
            [entityName]="inverseRelation.owner"
            [fixedSearchParams]="fixedSearchParams[relations.getUniqueNameForRelation(inverseRelation)]"
            [disabled]="!canEdit || relation.disabled">
        </con-entity-search-field>
      <small *ngIf='attachErrorText' class='mat-error'>{{attachErrorText}}</small>
    </ng-container>
</div>
