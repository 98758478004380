import {SignEnum} from './sign-enum';
import {IdentifierModel} from './identifier-model';
import {PersistentRange} from './persistent-range';
import {AnnotationModel} from './annotation-model';
import NewsSanityCheckService from '../services/news-sanity-check.service';
import {UtilService} from '../services/util.service';
import {DecimalSeparator} from './decimal-separator';

export class MarkModel {
  public readonly id: IdentifierModel
  public innerText: string
  public sign: SignEnum;
  public annotationBindings: Array<AnnotationModel>

  constructor(
    public range: PersistentRange,
    baseAnnotation: AnnotationModel,
    sign: SignEnum
  ) {
    this.id = new IdentifierModel()
    this.innerText = range.innerString // TODO can we implement a getter for innerText instead of keeping it as a property? range is already a property, which contains the same value.
    this.sign = sign
    this.annotationBindings = new Array<AnnotationModel>(baseAnnotation)
  }

  public getFieldNames(): string {
    return this.annotationBindings.map((annotation: AnnotationModel) => annotation.module.unique_id).join('\n')
  }

  public getLastAnnotation(): AnnotationModel {
    return this.annotationBindings[this.annotationBindings.length - 1]
  }

  public getColor(): string {
    return UtilService.generateRandomLightColor(this.getLastAnnotation().module.unique_id)
  }

  public alterSign(): void {
    if (this.sign === SignEnum.Negative) this.sign = SignEnum.Positive;
    else this.sign = SignEnum.Negative;
  }

  public getScalarValue(decimalSeparator: DecimalSeparator): number { // TODO remove service access
    let isDecimalOverwrite: boolean = null;

    if (decimalSeparator === DecimalSeparator.Dot) {
      if (this.innerText.indexOf('.') > -1)
        isDecimalOverwrite = true
      else
        isDecimalOverwrite = false
    }

    else if (decimalSeparator === DecimalSeparator.Comma) {
      if (this.innerText.indexOf(',') > -1)
        isDecimalOverwrite = true
      else
        isDecimalOverwrite = false
    }

    else
      isDecimalOverwrite = null

    // TODO instead of instantiating service here, can we make it a static method?
    return new NewsSanityCheckService(new UtilService()).parseAmountFromStr(this.innerText, isDecimalOverwrite) * this.sign
  }
}
