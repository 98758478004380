import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { prettyCase } from 'app/shared/utilities.classes';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from './api.service';
import { UtilService } from './util.service';
import { Task, EmailMapping, Delivery, DeliveryDetail } from '../typings/Typings';

@Injectable({
  providedIn: 'root'
})
export class TaskManagerService {
  public headerNameSub = new Subject<string>();
  public errorMessage: string;
  public apiResponse = new Subject();
  public apiError = new Subject();

  constructor(private utilService: UtilService, private router: Router, private apiService: ApiService) {
    this.setHeaderName();
  }

  getPlaceholderJSONTaskData() {
    this.apiService.getPlaceholderJSONTaskData().then(data => {
      this.apiResponse.next({data: data, type: 'getPlaceholderJSONTaskData'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getPlaceholderJSONSymbolsData() {
    this.apiService.getPlaceholderJSONSymbolsData().then(data => {
      this.apiResponse.next({data: data, type: 'getPlaceholderJSONSymbolsData'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getPlaceholderJSONIndexData() {
    this.apiService.getPlaceholderJSONIndexData().then(data => {
      this.apiResponse.next({data: data, type: 'getPlaceholderJSONIndexData'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getPlaceholderJsonIhsData() {
    this.apiService.getPlaceholderJSONIhsData().then(data => {
      this.apiResponse.next({data: data, type: 'getPlaceholderJSONIhsData'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  setHeaderName() {
    this.router.events.subscribe(ev => {
      let headerName: string;
      if (ev instanceof NavigationEnd) {
        headerName = prettyCase(ev.url.split('/')[ev.url.split('/').length - 1]);
        this.headerNameSub.next(headerName);
      }
    });
  }

  /*Task Manager*/
  createTask(jsonBody: string) {
    this.apiService.createTask(jsonBody).then(reply => {
      this.apiResponse.next({data: reply, type: 'createTask'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  createIndex(jsonBody: string) {
    this.apiService.createIndex(jsonBody).then(reply => {
      this.apiResponse.next({data: reply, type: 'createIndex'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  createSymbol(jsonBody: string) {
    this.apiService.createSymbol(jsonBody).then(reply => {
      this.apiResponse.next({data: reply, type: 'createSymbol'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  createIHSSymbol(jsonBody: string) {
    this.apiService.createIHSSymbol(jsonBody).then(reply => {
      this.apiResponse.next({data: reply, type: 'createIhsSymbol'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getDepStatus() {
    this.apiService.getDepStatus().then(response => {
      this.apiResponse.next({data: response, type: 'getDepStatus'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getDepStatusRunning() {
    this.apiService.getDepStatusRunningDeliveries().then(response => {
      this.apiResponse.next({data: response, type: 'getDepStatusRunning'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }

  getTaskOverviewWithIndicies() {
    this.apiService.getTaskOverviewWithIndicies().then(response => {
      this.apiResponse.next({data: response, type: 'getTaskOverviewWithIndicies'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }


  getTask(id: number) {
    this.apiService.getTask(id).then((task: Task) => {
      this.apiResponse.next({data: task, type: 'getTask'});
    }).catch(err => {
      this.apiError.next(err);
      }
    );
  }

  activateTask(taskID: number) {
    this.apiService.activateTask(taskID).then(response => {
      this.getTask(taskID);
    }).catch(err => {
      this.apiError.next(err);
    });
  }

  deactivateTask(taskID: number) {
    this.apiService.deactivateTask(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'deactivateTask'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  deleteTask(taskID: number) {
    this.apiService.deleteTask(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'deleteTask'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  createManualDelivery(taskID: number) {
    this.apiService.createManualDelivery(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'createManualDelivery'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }
  createMozendaDelivery(taskID: number) {
    this.apiService.createMozendaDelivery(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'createMozendaDelivery'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  /*Agent editor*/
  getMozendaData(config: string) {
    this.apiService.getMozendaData(config).then(response => {
      this.apiResponse.next({data: response, type: 'getMozendaData'});
    }).catch(err => {
      this.apiError.next(err.data);
    });
  }

  getStepData(config: string, step: number) {
    this.apiService.getStep(config, step).then(response => {
      this.apiResponse.next({data: response, type: 'getStepData'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  getMapColumns(config: string) {
    this.apiService.getMapColumns(config).then(response => {
      this.apiResponse.next({data: response, type: 'getMapColumns'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  deleteAgent(taskID: number, config: string) {
    this.apiService.deleteAgent(taskID, config).then(response => {
      this.apiResponse.next({data: response, type: 'deleteAgent'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  addAgent(taskID: number, config: string) {
    this.apiService.addAgent(taskID, config).then(response => {
      this.apiResponse.next({data: response, type: 'addAgent'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  dimCheck(config: string) {
    this.apiService.dimCheck(config).then((response: any) => {
      this.apiResponse.next({data: JSON.stringify(response), type: 'dimCheck'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  testConfig(config: string, task_id: number) {
    this.apiService.testConfig(config, task_id).then(response => {
      this.apiResponse.next({data: response, type: 'testConfig'});
    }).catch(err => {
      this.apiError.next(err);
      this.errorMessage = err;
    });
  }

  getConfig(taskID: number) {
    this.apiService.getConfig(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'getConfig'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }

  getPlaceholderConfig(taskID: number) {
    this.apiService.getPlaceholderConfig(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'jsonPlaceholder'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  updateTaskSpec(taskID: number, taskSpec: any) {
    this.apiService.updateTaskSpec(taskID, taskSpec).then(response => {
      this.apiResponse.next({data: response, type: 'updateTaskSpec'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  getTaskSpec(taskID: number) {
    this.apiService.getTaskSpec(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'getTaskSpec'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  /*Email mapping*/
  getEmailMappingPlaceholder(taskID: number) {
    this.apiService.getEmailMappingPlaceholder(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'getEmailMappingPlaceholder'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  getEmailMapping(taskID: number) {
    this.apiService.getEmailMapping(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'getEmailMapping'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  testEmailMapping(taskID: number, emailMapping: EmailMapping) {
    this.apiService.testEmailMapping(taskID, emailMapping).then(response => {
      this.apiResponse.next({data: response, type: 'testEmailMapping'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  setEmailMapping(taskID: number, emailMapping: EmailMapping) {
    this.apiService.setEmailMapping(taskID, emailMapping).then(response => {
      this.apiResponse.next({data: response, type: 'setEmailMapping'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  removeEmailMapping(taskID: number) {
    this.apiService.removeEmailMapping(taskID).then(response => {
      this.apiResponse.next({data: response, type: 'removeEmailMapping'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  /*Indicies*/
  symbolSearch(searchBody: any) {
    this.apiService.symbolSearch(searchBody).then(response => {
      this.apiResponse.next({data: response, type: 'symbolSearch'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  getIndiciesTaskDetails(taskId: number) {
    this.apiService.getIndiciesTaskDetails(taskId).then(response => {
      this.apiResponse.next({data: response, type: 'getIndiciesTaskDetails'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  updateIndexComponent(taskId: number, newIndexComponent) {
    this.apiService.updateIndexComponent(taskId, newIndexComponent).then(response => {
      this.apiResponse.next({data: response, type: 'updateIndexComponent'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  deleteIndexComponent(taskId: number, newIndexComponent: any) {
    this.apiService.deleteIndexComponent(taskId, newIndexComponent).then(response => {
      this.apiResponse.next({data: response, type: 'deleteIndexComponent'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  /*Symbols*/
  getAllSymbols() {
    this.apiService.getAllSymbols().then(response => {
      this.apiResponse.next({data: response, type: 'getAllSymbols'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }
  getRendintenSymbols() {
    this.apiService.getRendintenSymbols().then(response => {
      this.apiResponse.next({data: response, type: 'getAllSymbols'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  getSymbolSpec(symbolID: number) {
    this.apiService.getSymbolSpec(symbolID).then(response => {
      this.apiResponse.next({data: response, type: 'getSymbolSpec'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  updateSymbolSpec(symbolID: number, symbolSpec: any) {
    this.apiService.updateSymbolSpec(symbolID, symbolSpec).then(response => {
      this.apiResponse.next({data: response, type: 'updateSymbolSpec'});
    }).catch(err => {
      this.errorMessage = err;
      this.apiError.next(err);
    });
  }

  /*Delivery*/
  getPendingDeliveries(limit?: number, offset?: number) {
    this.apiService.getAllPendingDelieveries(limit, offset).then((deliveries: Delivery[]) => {
      this.apiResponse.next(deliveries);
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getIndicesMozendaData(deliveryID: number) {
    this.apiService.getIndicesMozendaData(deliveryID).then((response) => {
      this.apiResponse.next(({data: response, type: 'getIndicesMozendaData'}));
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getRunningDeliveries(limit?: number, offset?: number) {
    this.apiService.getAllRunningDelieveries(limit, offset).then((deliveries: Delivery[]) => {
      this.apiResponse.next(deliveries);
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getCompletedDelieveries(limit?: number, offset?: number) {
    this.apiService.getAllCompletedDelieveries(limit, offset).then((deliveries: Delivery[]) => {
      this.apiResponse.next(deliveries);
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getDelivery(deliveryID: number) {
    this.apiService.getDelivery(deliveryID).then((delivery: DeliveryDetail) => {
      this.apiResponse.next({data: delivery, type: 'DeliveryDetail'});
    }).catch(err => {
      this.apiError.next(err);
    })
  }
  getScrapedMozendaHTML(deliveryID: number) {
    this.apiService.getScrapedMozendaHTML(deliveryID).then((data: any) => {
      this.apiResponse.next({data: data, type: 'html'});
    }).catch(err => {
      this.apiError.next(err);
    })
  }
  updateDataDelivery(deliveryID: number, data: string) {
    this.apiService.updateDataDelivery(deliveryID, data).then(response => {
      this.apiResponse.next({data: response, type: 'updateDataDelivery'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  submitDataDelivery(deliveryID: number, data: string) {
    this.apiService.submitDataDelivery(deliveryID, data).then(response => {
      this.apiResponse.next({data: response, type: 'submitDataDelivery'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  cancelDelivery(deliveryID: number, reason: string) {
    this.apiService.cancelDelivery(deliveryID, reason).then(response => {
      this.apiResponse.next({data: response, type: 'cancelDelivery'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }
  getDeliveriesFromGivenTimeRange(deliveryState: string, fromDate: string, toDate: string, limit?: number, offset?: number) {
    this.apiService.getDeliveriesFromGivenTimeRange(deliveryState, fromDate, toDate, limit, offset).then(response => {
      this.apiResponse.next({data: response, type: 'getDeliveriesFromGivenTimeRange'});
    }).catch(err => {
      this.apiError.next(err);
    });
  }

}

