import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { snakeCase } from 'change-case';

@Component({
    templateUrl: './entity-has-dependencies-modal.component.html',
    selector: 'con-entity-has-dependencies-modal'
})

export class EntityHasDependenciesModalComponent implements OnInit {
    @Input() entities: any;
    @Input() isBulk: boolean = false;
    @Input() entityName?: string;
    public enitityURLKey: string;

    constructor(public activeModal: NgbActiveModal) {}

    setEntities(entities: any) {
        this.entities = entities;
    }

    ngOnInit(): void {
        this.enitityURLKey =  this.entityName ? snakeCase(this.entityName) : null;
    }
}
