import { RouterModule, Routes } from '@angular/router';

import { PowersComponent } from './powers.component';
import { SuperUserGuard } from './../auth/guards/super-user-guard.service';
import { PowersLayoutComponent } from './powers-layout.component';
import { CustomersWebhookComponent } from './customers-webhook.component';
import { CustomerComponent } from './customer.component';
import { DeltaDeliveriesComponent } from './delta-deliveries.component';

export const powersRoutes: Routes = [
    {
        path: '',
        component: PowersLayoutComponent,
        canActivate: [SuperUserGuard],
        children: [
            {
                path: '',
                component: PowersComponent
            },
            {
                path: 'customers',
                component: CustomersWebhookComponent
            },
            {
                path: 'customers/:id',
                component: CustomerComponent
            },
            {
              path: 'delta-deliveries',
              component: DeltaDeliveriesComponent
            }
        ]
    }
];

export const powersRouting = RouterModule.forChild(powersRoutes);
