import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'app/auth/services/profile.service';
import { EntitiesService, EntityDescription } from 'app/entities/services/entities.service';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';

@Component({
  selector: 'con-report-employee',
  templateUrl: './employee.component.html'
})
export class EmployeeComponent implements OnInit, OnDestroy {
  reportsSubscription: any;
  loading: boolean;
  reportEntity: any;
  relation: any;
  entityDescription: EntityDescription;
  public MLSendStatus = MLSendStatus;
  @Input() entity: any;
  companySubscription: any;

  constructor(
    private toaster: ToastrService,
    public profile: ProfileService,
    public entityService: EntitiesService,
    public companyService: CompanyReportsService
  ) { }

  ngOnInit() {
      this.reportEntity = this.entity;
      this.loading = true;

      this.companySubscription = this.entityService.getEntityDescriptionByEntityName('CompanyReport').subscribe(entityDescription => {
        this.entityDescription = entityDescription;
        const relation = entityDescription.getAllRelations();
        const employeeRelation = relation.filter(element => {
          return (element.name === 'companyEmployees');
        });
        this.relation = employeeRelation[0];
        this.loading = false;
      }, error => {
        this.toaster.error('Sorry, some error occurred!');
        this.loading = false;
      });
  }
  getDisplayTitle() {
    let displayName = this.entity.company.name;
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  ngOnDestroy() {
    if (this.companySubscription) {
      this.companySubscription.unsubscribe();
    }
  }
  get settingLock () {
    return this.companyService.locking;
  }
}
