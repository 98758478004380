<div class="row">

    <div class="col-12">
        <h2>{{'Create ' + createMode}}</h2>
        <select class="custom-select col-2" type="text" ng-model="dropdownOptions" (change)="changeMode($event.target.value)">
            <option *ngFor="let option of dropdownOptions" value={{option}}>
                {{option}}
            </option>
        </select>
        <con-loader-task-manager [isLoading]="isLoading"></con-loader-task-manager>
        <con-editor (onDataSaved)="create($event); open(content)" [showSaveButton]="true" [createdMessage]="createDataResponse.Result" [dataSub]="jsonDataSub"></con-editor>
        <div *ngIf="errMessage">{{errMessage}}</div>
    </div>
</div>
<ng-template #content let-modal>
    <con-loader-task-manager *ngIf="modalContentLoading" [isLoading]="modalContentLoading"></con-loader-task-manager>
    <div *ngIf="!modalContentLoading">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Created task</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" *ngIf="!createFailed">
            {{createMode === 'task' ? 
                'Task submitted successfully with id' + taskID : 
                createMode === 'symbol' ? 
                'Symbol successfully created' : 
                (createMode === 'IHS' || createMode === 'IHS symbol') ? 
                'Ihs (index) symbol successfully created' : 
                'Index successfully created'
            }}
        </div>
        <div class="modal-body" *ngIf="createFailed">
            Error: "Exception: {{errMessage}}"
        </div>
        <div class="modal-footer d-flex justify-content-between" *ngIf="!createFailed">
            <button 
                type="button" class="btn btn-primary" 
                (click)="createMode === 'task' ? 
                createAnotherTask() : 
                createMode === 'symbol' ? 
                createAnotherSymbol(): 
                (createMode === 'IHS' || createMode === 'IHS symbol') ? 
                createAnotherIHSSymbol() :
                createAnotherIndex(); 
                modal.close('New Task')"
            >
                {{'Create new ' + createMode}}
            </button>
            <button type="button" class="btn btn-primary" (click)="proceedToTask(); modal.close('View Task')" *ngIf="createMode === 'task'">View task {{taskID}}</button>
        </div>
    </div>
</ng-template>