import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketcapCountService } from 'app/market-cap/service/marketcap-count.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'con-marketcap',
  templateUrl: './marketcap.component.html'
})
export class MarketcapComponent implements OnInit, OnDestroy {
  countSubscription: any;

  public counts: { missing: number, outdated: number, duplicate_primaries: number, missing_primaries: number };
  public loading = true;

  constructor(
    private countService: MarketcapCountService,
    private toastr: ToastrService,
    private router: Router
  ) { };


  ngOnInit() {
    this.countSubscription = this.countService.getCounts().subscribe(count => {
      this.counts = count;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.toastr.error('Sorry, some error occurred!');
    });

  }
  public showRefreshButton(type: string) {
    const keyword = type.toLocaleLowerCase();
    if (this.countService.loadingCompleted && this.router.url.indexOf(keyword) > -1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.countSubscription.unsubscribe();
  }

  public loadPage(type: string, event: any) {
    event.stopPropagation();
    this.countService.reloadSubject.next(type);
  }

}
