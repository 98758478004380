
<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="calendar"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Report Events</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <con-loader *ngIf="loading"></con-loader>
      <con-no-data message="Error fetching data" count="0" *ngIf="showErrorMessage"></con-no-data>
      <con-report-events-editor *ngIf="!showErrorMessage && !loading"
        ownerType="Company"
        [company]="companyReportEvent"
        (savedCompany)="updateCompanyMissingData($event)"
        [isListing]="false"
      ></con-report-events-editor>
    </div>
  </div>
</div>
