import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../../auth/services/profile.service';
import { CalendarSettings } from '../../../settings.class';

import { DateConverterService } from '../../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component({
    selector: 'con-start',
    templateUrl: './start.component.html'
})

export class StartComponent {
}
