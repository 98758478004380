<h4>Primary Missing Tasks</h4>
<div class='card'>
  <div class='table-responsive'>

    <table class='table'>
      <thead class='thead-light'>
      <tr>
        <th>Id</th>
        <th>Title</th>
        <th>Created At</th>
        <th class="text-right">
          <con-icon-by-name (click)="refresh()" iconName="refresh"></con-icon-by-name>
        </th>
      </tr>
      </thead>
      <con-loader *ngIf='loading'></con-loader>
      <tbody *ngIf='!loading'>
      <ng-container *ngFor='let mp of data?.data'>
        <tr>
          <td><a href="entity/task/{{mp.id}}" target='_blank'>{{mp.id}}</a></td>
          <td><a href="entity/company/{{mp.entity_id}}" target='_blank'>{{ mp.title }}</a></td>
          <td colspan="2">{{ mp.created_at }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <con-no-data *ngIf='!loading' [count]='data?.total' [message]='noDataMessage'></con-no-data>
  <div class='card-body d-flex justify-content-center' [ngClass]="{'hide-pagination':!data?.total}">
    <ngb-pagination [collectionSize]='data?.total'
                    [(page)]='currentPage'
                    (pageChange)='changePage($event)'
                    [pageSize]='data?.per_page'
                    maxSize='10'
                    [rotate]='true'></ngb-pagination>
  </div>
</div>
