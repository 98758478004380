import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiSettings, TranslationSettings } from "app/settings.class";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "con-translated-descriptions",
  templateUrl: "./translated-descriptions.component.html",
})
export class TranslatedDescriptionsComponent implements OnInit {
  translations: any;
  companyId: string;
  targetLanguages: any[];
  translatedTexts: any[];
  loading: boolean;
  descriptions: any[] = [];
  translationSaved = false; // This field is used to identify is there any language's description is saved
  sourceDescription:any = {}

  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  saveDescription(descriptions, removeFromList = false, index = 0) {
    this.loading = true;
    const postData = [];
    descriptions.forEach((lang) => {
      postData.push({
        language_id: lang.language_id,
        description: lang.description,
      });
    });
    this.http
      .post(
        ApiSettings.BASE_URL +
        "/" +
        TranslationSettings.COMPANY_ENDPOINT +
        "/" +
        this.companyId +
        "/" +
        TranslationSettings.SAVE_ALL,
        { descriptions: postData }
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.translationSaved = true;
          if (removeFromList) {
            this.removeFromList(index);
          } else {
            this.close();
          }
          this.showSuccessMsg();
        },
        (err) => {
          console.log(err);
          this.showErrorMsg();
          this.loading = false;
        }
      );
  }

  close() {
    this.activeModal.close(this.translationSaved);
  }

  removeFromList(index) {
    this.descriptions.splice(index, 1);
  }

  saveOne(entity, index) {
    this.saveDescription([entity], true, index);
  }

  saveAll() {
    this.saveDescription(this.descriptions);
  }

  showSuccessMsg() {
    this.toastr.success(
      "Descriptions updated successfully!",
      "Company Description"
    );
  }

  showErrorMsg() {
    this.toastr.error("Sorry, some error occurred", "Company Description");
  }
}
