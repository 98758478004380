import {Component, Input, OnInit} from '@angular/core';
import {DynamicDataEntryComponentInterface} from '../models/dynamic-data-entry-component-interface';
import {Observable} from 'rxjs';
import {TaskDataEntryState} from '../state-management/states';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {InstanceService} from '../services/instance.service';
import {SetComponentData} from '../state-management/actions';

@Component({
  selector: 'con-dynamic-data-entry-currency-input',
  template: `
    <ng-container *ngIf="{ currency: value$ | async } as data">
      <div class="alert alert-info alert-dismissible">
        <button type="button" aria-label="Close" class="close" (click)="isCurrencySelectorHidden = !isCurrencySelectorHidden" [hidden]="data.currency === null">
                <span aria-hidden="true">
                  <con-icon-by-name iconName="edit"></con-icon-by-name>
                </span>
        </button>
        <con-icon-by-name iconName="money" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
        <strong>{{ data.currency?.name }}, {{ data.currency?.symbol }}</strong>
      </div>
      <con-entity-search-field [hidden]="isCurrencySelectorHidden && data.currency !== null" [entityName]="'Currency'" [showDismiss]="false" (onSelect)="updateValue($event)">
      </con-entity-search-field>
    </ng-container>
  `,
})
export class DynamicDataEntryCurrencyInputComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<any>;
  public isCurrencySelectorHidden: boolean = true;

  constructor(
    private store: Store,
    public instanceService: InstanceService,
  ) { }

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
      .pipe(
        map((value: any) => {
            return value
          }
        )
      )
  }

  updateValue($event: any) {
    this.isCurrencySelectorHidden = true;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, $event))
  }
}
