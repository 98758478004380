import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthModule } from "app/auth/auth.module";
import { EntitiesModule } from "app/entities/entities.module";
import { SharedModule } from "app/shared/shared.module";
import { ListInModalComponent } from "./components/list-in-modal.component";
import { TranslatedDescriptionsComponent } from "./components/translated-descriptions/translated-descriptions.component";
import { TranslationService } from "./services/translation.service";

@NgModule({
    declarations: [ListInModalComponent, TranslatedDescriptionsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        NgbModule,
        AuthModule,
        EntitiesModule,
        FormsModule
    ],
    providers: [TranslationService]
})
export class UtilityModule {}
