import {Component, HostListener, Input, OnChanges, OnInit} from '@angular/core';
import {TaxonomyFieldListService} from '../services/taxonomy-field-list.service';
import {ReportStatement} from '../models/report-statement';
import { UtilService } from '../../doc-process-common/services/util.service';
import { TaxonomyModule } from '../../doc-process-common/models/taxonomy-module';
import {InstanceService} from '../services/instance.service';

@Component({
  selector: 'con-taxonomy-fields-list',
  providers: [TaxonomyFieldListService],
  template: `
    <div *ngFor="let module of statementModules" style="font-size: 80%">
      <con-doc-process-taxonomy-field [moduleData]="module" [treeLevel]="0" [ngClass]="{'disabled': reportStatement.is_unavailable}"></con-doc-process-taxonomy-field>
    </div>
  `,
})
export class TaxonomyFieldsListComponent implements OnInit {
  @Input() reportStatement: ReportStatement;
  private nOfModules: number = 0;
  public statementModules: Array<TaxonomyModule>;

  constructor(
      private taxonomyFieldListService: TaxonomyFieldListService,
      private instanceService: InstanceService,
    ) { }

  ngOnInit(): void {
    function findMaxModulePosition(module: TaxonomyModule): number {
      let maxPositionIndex: number = module?.position ?? 0

      for (let childModule of module?.children ?? []) {
        maxPositionIndex = Math.max(maxPositionIndex, findMaxModulePosition(childModule))
      }

      return maxPositionIndex
    }

    this.nOfModules = findMaxModulePosition({children: this.reportStatement.modules} as TaxonomyModule)

    this.statementModules = ReportStatement.sortedModules(this.reportStatement)
  }



  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    // noinspection RedundantConditionalExpressionJS
    let focusedModulePosition = this.taxonomyFieldListService.focusedTaxonomyModulePosition.getValue()
    let currModule : TaxonomyModule = this.findModuleByPosition(focusedModulePosition);
    if (currModule) {
      let keepItLoopin: boolean = currModule.is_highlighted ? true : false;
      let newModulePosition: number;
      do {

        if (ev.shiftKey && ev.key === 'Tab') {
          ev.preventDefault()
          ev.stopPropagation()


          newModulePosition = UtilService.modulo(focusedModulePosition - 2, this.nOfModules) + 1
          this.taxonomyFieldListService.focusedTaxonomyModulePosition.next(newModulePosition)
        } else if (ev.key === 'Tab') {
          ev.preventDefault()
          ev.stopPropagation()

          let focusedModulePosition = this.taxonomyFieldListService.focusedTaxonomyModulePosition.getValue()
          newModulePosition = UtilService.modulo(focusedModulePosition, this.nOfModules) + 1
          this.taxonomyFieldListService.focusedTaxonomyModulePosition.next(newModulePosition)
        }
        else {
          return
        }

        let newModule = this.findModuleByPosition(newModulePosition)
        if (newModule.is_highlighted) {
          keepItLoopin = false
        }
      }
      while (keepItLoopin)
    }

  }

  private findModuleByPosition(position: number): TaxonomyModule {
    for (let childModule of this.statementModules) {
      let moduleObj = (childModule as TaxonomyModule)

      let targetModule = TaxonomyModule.findModuleByPosition(moduleObj, position)
      if (targetModule)
        return targetModule
    }
    return null
  }
}
