import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'con-survey-report',
  templateUrl: './survey-report.component.html',
  styleUrls: ['./survey-report.component.scss']
})
export class SurveyReportComponent implements OnInit {

  company: any;

  constructor( private route: ActivatedRoute, private companyReportService: CompanyReportsService) { }

  ngOnInit(): void {

    this.route.params.pipe(
      tap((params: Params) => {
        this.companyReportService.getCompanyData(params.companyId).subscribe((companyDetails) => {
          this.company = companyDetails;
        }) }))
      .subscribe((companySurveys: any) => {
          // current not using gui, placed for later implementation
        },
        err => {
         console.error(err);
        });
  }

}
