import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {switchMap, catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';

import {
    HttpInterceptor,
    HttpHandler,
    HttpEvent,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpClient
} from '@angular/common/http';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { NotAuthorizedModalComponent } from '../components/not-authorized-modal.component';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthSettings, ApiSettings } from '../../settings.class';

import { AuthService } from './auth.service';

import { Router } from '@angular/router';

class ResponseError {
    private type: string;
    private data: any;
    private status: number;

    constructor(response: HttpErrorResponse) {
        this.data = response.error;
        this.status = response.status;
        if (this.data && this.data.type) {
            this.type = this.data.type;
        } else {
            this.type = 'Unknown';
        }
    }
    isNotFoundError(): boolean {
        return this.status === 404;
    }
    isValueError(): boolean {
        return this.type === ApiSettings.RESPONSES.VALUE_ERROR;
    }
    isEntityOutdatedError(): boolean {
        return this.type === ApiSettings.RESPONSES.ENTITY_OUTDATED;
    }
    isHasDependentEntitiesError(): boolean {
        return this.type === ApiSettings.RESPONSES.ENTITY_HAS_DEPENDENT_ENTITIES;
    }
    isPermissionError(): boolean {
        return this.type === ApiSettings.RESPONSES.PERMISSION_ERROR;
    }
    isTokenExpiredError(): boolean {
        return this.data && this.data.error === ApiSettings.RESPONSES.TOKEN_EXPIRED;
    }
    isTokenExpiredMessage(): boolean {
        return this.data && this.data.message === ApiSettings.RESPONSES.TOKEN_EXPIRED_MESSAGE;
    }
    isUnauthorizedError(): boolean {
        return this.isTokenExpiredMessage() || this.isTokenNotProvidedMessage() ||
                this.isTokenInvalidMessage() || this.isTokenExpiredError() ||
                this.isTokenNotProvidedError() || this.isTokenInvalidError();
    }
    isTokenNotProvidedError(): boolean {
        return this.data && this.data.error === ApiSettings.RESPONSES.TOKEN_NOT_PROVIDED;
    }
    isTokenNotProvidedMessage(): boolean {
        return this.data && this.data.error === ApiSettings.RESPONSES.TOKEN_NOT_PROVIDED_MESSAGE;
    }
    isTokenInvalidError(): boolean {
        return this.data && this.data.error === ApiSettings.RESPONSES.TOKEN_INVALID;
    }
    isTokenInvalidMessage(): boolean {
        return this.data && this.data.error === ApiSettings.RESPONSES.TOKEN_INVALID_MESSAGE;
    }
    isUnknownError(): boolean {
        return this.type === 'Unknown';
    }
    isLockedError(): boolean {
        return this.type === 'LOCKED_ERROR';
    }
    getData() {
        return this.data;
    }
}

@Injectable()
export class RefreshJwtInterceptor implements HttpInterceptor {
    private refreshTokenRequest: Observable<any>;
    private refreshTokenSubject: Subject<string>;

    constructor(private jwtHelper: JwtHelperService,
                private http: HttpClient,
                private auth: AuthService,
                private modalService: NgbModal,
                private router?: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
                    catchError(err => {
                        const responseError = new ResponseError(err);
                        if (responseError.isTokenExpiredError()) {
                            return this.refreshToken().pipe(
                                        switchMap(token => {
                                            const headers = request.headers.set(AuthSettings.HEADER_NAME,
                                                AuthSettings.HEADER_PREFIX + this.jwtHelper.tokenGetter());
                                            return next.handle(request.clone({ headers }));
                                        }),
                                        catchError(error => {
                                            return observableThrowError(new ResponseError(error));
                                        }));
                        }
                        return observableThrowError(responseError);
                    }),
                    catchError((responseError: ResponseError) => {
                        if (responseError.isUnauthorizedError()) {
                            this.setPreviousUrl();
                            this.auth.logout();
                        }
                        if (responseError.isPermissionError()) {
                            this.openPermissionErrorModal();
                        }
                        return observableThrowError(responseError);
                    })
                );
    }

    private openPermissionErrorModal() {
        this.modalService.open(NotAuthorizedModalComponent);
    }

    private refreshToken(token: string = this.jwtHelper.tokenGetter()): Observable<string> {
        if (!this.refreshTokenRequest) {
            this.refreshTokenSubject = new Subject<string>();
            this.refreshTokenRequest = this.http.get<any>(ApiSettings.BASE_URL + '/' + AuthSettings.REFRESH_TOKEN_ENDPOINT);
            this.refreshTokenRequest.subscribe((data: any) => {
                                        AuthSettings.setToken(data.token);
                                        this.refreshTokenSubject.next(data.token);
                                        this.refreshTokenSubject = null;
                                        this.refreshTokenRequest = null;
                                    }, err => {
                                        AuthSettings.emptyToken();
                                        this.refreshTokenSubject.error(err);
                                        this.refreshTokenRequest = null;
                                        this.refreshTokenSubject = null;
                                    });
        }
        return this.refreshTokenSubject.asObservable();
    }

    private setPreviousUrl() {
        const url = this.router.url;
        if (url !== '/login') {
            sessionStorage.setItem(AuthSettings.REDIRECT_KEY, JSON.stringify(this.router.url));
        } else {

        }
    }
}
