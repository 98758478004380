import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketcapSettings } from 'app/settings.class';
import { HttpClient } from '@angular/common/http';
import { MarketcapCountService } from 'app/market-cap/service/marketcap-count.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'con-update-share-count',
  templateUrl: './update-share-count.component.html'
})
export class UpdateShareCountComponent implements OnInit, OnDestroy {

  public loading = true;
  public updates: any;
  private updateSubscription = new Subscription;
  private reloadSubscription = new Subscription;
  public noDataMessage = 'No data to display.';

  constructor(
    private http: HttpClient,
    private countService: MarketcapCountService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadPage(1);
    this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
      if (type === 'outdated') {
        this.loadPage(1);
      }
    });
  }
  loadPage(pageNumber: number) {
    this.loading = true;
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    this.updateSubscription = this.http.get<any>(MarketcapSettings.BASE_URL + '/' + MarketcapSettings.OUTDATED_ENDPOINT,
      {
        params: {
          page: String(pageNumber)
        }
      })
      .subscribe(res => {
        this.countService.setCount('outdated', res.total);
        this.updates = res;
        this.loading = false;
      }, error => {
        this.loading = false;
        this.countService.setCount('outdated', 0);
        this.updates = {total: 0};
        this.toastr.error('Sorry, some error occurred!');
      });
  }

  updateInList(company: any) {
    const index = this.updates.data.findIndex(c => c.id === company.company_id);
    this.updates.data.splice(index, 1);
    this.countService.decrementCount('outdated');
  }

  ngOnDestroy() {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }
}
