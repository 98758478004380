import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ZoomService} from '../../../doc-process-common/services/zoom.service';
import {DocumentService} from '../../../doc-process-common/services/document.service';
import {Observable, Subscription} from 'rxjs';
import {HotkeyHandlerService} from '../../services/hotkey-handler.service';
// import {PdfViewerComponent} from "ng2-pdf-viewer";
// import {SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'con-pdf-viewer-wrapper',
  template: `
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>
          <small>Inline</small>
        </a>
        <ng-template ngbNavContent>
          <div
          >
            <pdf-viewer
            [src]="(documentSafeResourceUrl)?.changingThisBreaksApplicationSecurity"
            [render-text]="true"
            [render-text-mode]="2"
            [page]="pdfPage$ | async"
            [show-all]="true"
            [original-size]="false"
            [autoresize]="false"
            [fit-to-page]="false"
            [zoom]="(zoomService.zoomMagnification | async) / 100"
            (click)="onClickEvent($event)"
            style="min-height: 85vh;"
          ></pdf-viewer>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>
          <small>Embedded</small>
        </a>
        <ng-template ngbNavContent>
          <div
            *ngIf="displayDocument"
          >
          <object
            style="width:100%; min-height: 85vh;"
            [data]="documentSafeResourceUrl$ | async"
            type="application/pdf"
          >
            <embed
              #pdfElement
              name='plugin'
              [src]="documentSafeResourceUrl$ | async"
              type="application/pdf"/>
          </object>
        </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  `,
})
export class PdfViewerWrapperComponent implements OnInit, OnChanges {
  @Input() documentSafeResourceUrl$: any;
  displayDocument = true;
  public pdfPage$: Observable<number>;
  public documentSafeResourceUrl: any = false;
  public documentSafeResourceUrlSubscription: Subscription;

  constructor(
    public zoomService: ZoomService,
    public hotkeyHandlerService: HotkeyHandlerService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges) {
    this.documentSafeResourceUrlSubscription?.unsubscribe()
    this.documentSafeResourceUrlSubscription = this.documentSafeResourceUrl$?.pipe()?.subscribe(val => {
      this.documentSafeResourceUrl = val
      // console.log("documentSafeResourceUrl$ changed:")
      // console.log(val)

      if (!!val) {
        this.pdfPage$ = DocumentService.parsePageNumberFromUrl(this.documentSafeResourceUrl$)
      }

      setTimeout(() => {
        this.displayDocument = false
        setTimeout(() => {
          this.displayDocument = true
        },100)
      },100)
    })
  }

  // @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
  // scrollToPage(page: number) {
  //   this.pdfComponent.pdfViewer.scrollPageIntoView({
  //     pageNumber: page
  //   });
  // }
  onClickEvent($event: MouseEvent) {
    this.hotkeyHandlerService.clickEvent$.next($event)
  }
}
