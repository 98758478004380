<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active' : selectedTab == 'all'}" (click)="onTabSelected('all')" data-toggle="tab" role="tab">All</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active' : selectedTab == 'company data'}" (click)="onTabSelected('company data')" data-toggle="tab" role="tab">Company Data</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active' : selectedTab == 'description'}" (click)="onTabSelected('description')" data-toggle="tab" role="tab">Description</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active' : selectedTab == 'positions'}" (click)="onTabSelected('positions')" data-toggle="tab" role="tab">Positions</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active' : selectedTab == 'advisors'}" (click)="onTabSelected('advisors')" data-toggle="tab" role="tab">Advisors</a>
  </li>
</ul>
