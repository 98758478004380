import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {InstanceDataValue} from '../../kpi-and-kiid/models/Typings';
import {IdentifierModel} from '../models/identifier-model';

class DateModal { // TODO move to another file in the models folder
  year: number;
  month: number;
  day: number;

  constructor() { }

  fromString(source: string): this {
    try {
      this.year = parseInt(source.split('-')[0])
      this.month = parseInt(source.split('-')[1])
      this.day = parseInt(source.split('-')[2])
      return this
    }
    catch (e) {
      return null
    }
  }

  fromDateObject(dateObject : {year,month,day }): this {
    this.year = dateObject?.year
    this.month = dateObject?.month
    this.day = dateObject?.day
    return this
  }

  toString(): string {
    let finalDateString: string;
    try {
      const year = this.year.toString()
      const month = this.month.toString().padStart(2, '0')
      const day = this.day.toString().padStart(2, '0')

      if (year === "NaN")
        {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error()
        }

      finalDateString = year + '-' +  month + '-' +  day
    }
    catch (e) {
      finalDateString = ""
    }
    return finalDateString
  }
}

@Component({
  selector: 'con-date-picker-component',
  template: `
      <div *ngIf="!!dateObject" class="input-group">
        <input
          class="form-control"
          placeholder="yyyy-mm-dd"
          name="dp"
          ngbDatepicker
          #d="ngbDatepicker"
          [ngModel]="rawDate"
          (ngModelChange)="rawDate = $event"
          (input)="onTextChange($event.target)"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><con-icon-by-name [iconName]="'calendar'"></con-icon-by-name></button>
        </div>
        <button *ngIf="!isDateValid"
                class="btn p-0 quantity-button d-flex align-items-top"
                type="button"
                ngbPopover="Date is not valid!"
                container="body"
                [openDelay]="300"
                [closeDelay]="500"
                triggers="mouseenter:mouseleave">
          <con-icon-by-name class="align-items-center"
                            [iconName]="'exclamation'"
                            style="color: red;"></con-icon-by-name>
        </button>
      </div>
  `,
})
export class DatePickerComponentComponent implements OnInit, OnChanges {
  @Input() dateObject: InstanceDataValue;
  @Output() onValidation: EventEmitter<[boolean, IdentifierModel]> = new EventEmitter<[boolean, IdentifierModel]>();
  @Output() onValidationV2: EventEmitter<[boolean, IdentifierModel, InstanceDataValue]> = new EventEmitter<[boolean, IdentifierModel, InstanceDataValue]>();

  private _rawDate: {year,month,day} | string;
  private _isDateValid: boolean = false;
  private id = new IdentifierModel();

  get isDateValid(): boolean {
    return this._isDateValid
  }
  set isDateValid(isValid) {
    this.onValidation.emit([isValid, this.id])
    this._isDateValid = isValid
  }

  get rawDate(): {year,month,day } | string {return this._rawDate}
  set rawDate(val: {year,month,day } | string) {
    this._rawDate = val
    this.assignNewValueToObject()
    this.onRawDateManipulation()
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    try {
      this._rawDate = new DateModal().fromString(this.dateObject.value)
      this.assignNewValueToObject()
    }
    catch (e) {
      this._rawDate = new DateModal()
      this.assignNewValueToObject()
    }
  }

  private assignNewValueToObject() {
    let newDateString = this.rawDate;

    if (this.isString(newDateString)) {
      this.isDateValid = false;
      this.dateObject.value = "";
      return
    }
    else {
      newDateString = new DateModal().fromDateObject(newDateString as {year,month,day}).toString()
      this.isDateValid = DatePickerComponentComponent.checkDateValidity(newDateString)
      this.updateDateObject(newDateString, this.isDateValid)
    }
  }

  private onRawDateManipulation() {
    this.dateObject.altered = true;
    this.dateObject.confirmed = 'Altered';
  }

  private onRawDateChange() {
  }

  public isString(inputString): boolean {
    return typeof inputString === 'string' || inputString instanceof String
  }

  public static checkDateValidity(value: string): boolean {
    const regex = /^((\d{4})-((0?[1-9])|1[0-2])-((0?[1-9])|[1-2]\d|3[0-1]))?$/g
    const passedCheck = regex.test(value);
    return passedCheck
  }

  onTextChange(target: any) {
    let value: string = target?.value
    this.isDateValid = DatePickerComponentComponent.checkDateValidity(value)
  }

  private updateDateObject(newDateString: string, isDateValid: boolean) {
    this.dateObject.value = isDateValid ? newDateString : ""
    this.onValidationV2.emit([isDateValid, this.id, this.dateObject])

    // console.log("modified dataobject value:")
    // console.log(this.dateObject.value)
  }
}
