
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GuestGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) {}

    canActivate() {
        return this.auth.isLoggedIn().pipe(map(status => {
            if (status) {
                this.router.navigate(['']);
                return false;
            }
            return true;
        }),
        catchError(() => {
            return observableOf(true);
        }), );
    }
}
