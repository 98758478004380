<div class="d-flex justify-content-start">
    <h3><span class="badge badge-dark">{{ customer.name }}</span></h3>
</div>
<con-loader *ngIf="loading"></con-loader>
<div *ngIf="!loading">
    <con-webhook-editor
        [customer]="customer"
        (afterUpdate)="webhookUpdated($event)"
    >
    </con-webhook-editor>
</div>
<hr>
