
<div class="row">
    <div class="ml-3">
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Deliveries</span></label>
            <select class="custom-select" type="text" ng-model="dropdownOptions" (change)="requestAction($event.target.value)">
                <option *ngFor="let option of dropdownOptions" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div> 
    </div>
    <div class="ml-3">
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Search (YYYY-MM-DD)</span></label>
            <div class="input-group">
                <input 
                    [ngStyle]="{'border-color': fromDateValid ? '': 'red'}" 
                    [(ngModel)]="fromDate" 
                    (ngModelChange)="checkIfFromDateIsValid();" 
                    type="text" class="form-control" 
                    placeholder="From date." 
                    aria-label="From date."
                    (keyup)="search($event)"
                >
            </div>  
            <div class="input-group ml-3">
                <input 
                    [ngStyle]="{'border-color': toDateValid ? '': 'red'}" 
                    [(ngModel)]="toDate" 
                    (ngModelChange)="checkIfToDateIsValid()" 
                    type="text" 
                    class="form-control" 
                    placeholder="To date" 
                    aria-label="To date"
                    (keyup)="search($event)"
                >
            </div> 
            <div class="input-group ml-3">
                <button (click)= "getDeliveriesFromGivenTimeRange(); hasDateSet = true" [disabled]="!toDateValid || !fromDateValid" class="btn btn-primary">Search</button>
            </div>
            <div class="input-group ml-3">
                <button (click)= "getDeliveriesWithoutTimeRange(); hasDateSet = false" [disabled]="!hasDateSet" class="btn btn-danger">Remove date</button>
            </div>
        </div>
    </div>
    <div class="ml-3">
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Amount of deliveries</span></label>
            <select class="custom-select" type="text" ng-model="deliveryLimits" (change)="requestAction($event.target.value)">
                <option *ngFor="let option of deliveryLimits" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div> 
    </div>
    <div class="ml-3">
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Filter</span></label>
            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                <button class="btn dropdown-button" id="dropdownBasic1" ngbDropdownToggle>Categories</button>
                <div class="p-2" ngbDropdownMenu aria-labelledby="dropdownManual">
                    <label *ngFor="let category of categories" class="container" ngbButtonLabel>
                        <button [class]="selectedCategories.indexOf(category) >= 0 ? 'btn btn-primary select-button' : 'btn select-button' " (click)="selected(category)" ngbButton>{{category}}</button>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                Delieveries
            </div>
            <div class="card-body">
                <ngb-alert type="danger" *ngIf="errMessage" [dismissible]="true" (close)="errMessage = undefined">{{errMessage}}</ngb-alert>
                <con-loader-task-manager [isLoading]="isLoading"></con-loader-task-manager>
                <div (click)="redirectToTask(delivery.value.delivery_id)" class="card col-12" *ngFor="let delivery of deliveries | keyvalue:sortingOrder">
                    <div class="card-header row p-2">
                        <div class="col-1">
                            <con-icon-by-name iconName="truck" class="mr-2"></con-icon-by-name>
                            <a class="text-white" [routerLink]="['/task_manager', 'deliveries', delivery.value.delivery_id]">{{delivery.value.delivery_id}}</a>
                        </div>
                        <div class="col-1">{{delivery.value.id}}</div>
                        <div class="col-2">{{delivery.value.name}}</div>
                        <div class="col-2">{{delivery.value.category}}</div>
                        <div
                            [class]=
                                "delivery.value.agent_status === 'Operational' ? 
                                'col-2 text-success' : delivery.value.agent_status === 'FailedProcessing' ? 
                                'col-2 text-danger' : delivery.value.agent_status === 'FailedScrape' ?
                                'col-2 text-warning' : 'col-2 text-white'"
                        >{{delivery.value.agent_status}}</div>
                        <div
                            [class]="delivery.value.delivery_state === 'Running' ? 
                                delivery.value.delivery_status === 'Running: ManualEntry' ? 
                                'text-warning col-2' : 'text-success col-2' : 
                                delivery.value.delivery_state === 'Pending' ? 
                                'text-white col-2' : delivery.value.delivery_state === 'Canceled' ? 
                                'text-secondary col-2' : 'text-warning col-2'
                            ">{{delivery.value.delivery_status}}</div>
                        <div class="col-2">{{delivery.value.scheduled_time}}</div>
                    </div>
                </div>
                <div *ngIf="!errMessage" class="col-12 my-3 d-flex justify-content-center">
                    <button (click)="loadMoreDeliveris()" class="btn btn-primary">Load more deliveries</button>
                </div>
            </div> 
        </div>
    </div>
</div>
    
    