
import {startWith} from 'rxjs/operators';
import { Directive, Input, ElementRef, OnChanges } from '@angular/core';

import { FileTokenService } from '../../auth/services/file-token.service';

import { Subject } from 'rxjs';


import { ApiSettings } from '../../settings.class';

@Directive({
    selector: '[conResolveLinkToFile]'
})
export class ResolveLinkToFileDirective implements OnChanges {
    @Input() conResolveLinkToFile: string;
    @Input() fileType: string;

    private conResolveLinkToFile$: Subject<string> = new Subject<string>();
    private loading = true;
    private token: any;
    constructor(private tokenService: FileTokenService, private element: ElementRef) {
        this.tokenService.getToken()
                        .subscribe(token => {
                            this.token = token;
                            this.conResolveLinkToFile$.pipe(
                                startWith(this.conResolveLinkToFile))
                                .subscribe(file => {
                                    this.applyLink();
                                    this.loading = false;
                                })
                        });
    }
    public ngOnChanges(event) {
        this.conResolveLinkToFile$.next(this.fileType);
    }
    private applyLink() {
        let attribute = 'href';
        switch (this.fileType) {
            case 'image':
                attribute = 'src';
                break;
            default:
                break;
        }
        this.element.nativeElement.setAttribute(attribute, this.getSignedPath())
    }

    private getSignedPath() {
        return ApiSettings.BASE_URL + '/' + ApiSettings.FILE_ENDPOINT + '/' + this.conResolveLinkToFile + '?token=' + this.token.token;
    }
}
