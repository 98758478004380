import { RouterModule, Routes } from '@angular/router';
import { DocProcessLayoutComponent } from './views/components/doc-process-layout/doc-process-layout.component';
import { DocProcessModuleHomeView } from './views/doc-process-module-home-view.component';
import { DocProcessTaskView } from './sub-modules/doc-process-common/pages/doc-process-task/doc-process-task-view.component';
import { DocProcessProjectView } from './sub-modules/doc-process-common/pages/doc-process-project/doc-process-project-view.component';
import { CanDeactivateGuard } from './sub-modules/universal-data-entry/services/can-deactivate-guard.service';
import { DataEntryViewRouterComponent } from './views/components/data-entry-view-router/data-entry-view-router.component';
import { HelpPageComponent } from './views/components/help-page/help-page.component';

export const docProcessRoutes: Routes = [
  {
    path: '',
    component: DocProcessLayoutComponent,
    children: [
      {
        path: '',
        component: DocProcessModuleHomeView,
      },
      {
        path: ':dp-backend-env-name',
        component: DocProcessModuleHomeView,
      },
      {
        path: ':dp-backend-env-name/project/:project_id',
        component: DocProcessProjectView,
      },
      {
        path: ':dp-backend-env-name/project/:project_id/task/:task_id',
        component: DocProcessTaskView,
      },
      {
        path: ':dp-backend-env-name/project/:project_id/task/:task_id/annotate',
        component: DataEntryViewRouterComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ':dp-backend-env-name/help',
        component: HelpPageComponent,
      },
    ],
  },
];

export const docProcessRouting = RouterModule.forChild(docProcessRoutes);
