<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <h3 class="mb-0">Report Dates</h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <con-loader></con-loader>
  </ng-container>
  <ng-container *ngIf="!isLoading && !reportDatesPageError">
    <div class="d-flex justify-content-start align-items-center sticky-header non-sticky py-2">
      <h3 class="m-0"><span class="badge badge-dark">
        {{getDisplayTitle()}}
      </span></h3>
      <span *ngIf="entity?.company?.website" class="website-link-positioning">
        <a [href]="entity?.company?.website" class="link-fontsize" target="_blank">{{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
      </span>
      <div class="chip chip-inverse ml-auto">
        <con-avatar [userId]="entity.company.manager?.id" [size]="32"></con-avatar>
        {{ entity.company.manager?.first_name }} {{ entity.company.manager?.last_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <con-report-dates-form [companyDetails]="entity?.company" [eventTypeId]="earningsTypeId" (eventCreated)="updateListing()"></con-report-dates-form>
      </div>
    </div>
    <div class="row white-bg mb-2">
      <div class="col-12">
        <div class="card">
          <con-entity-list 
            [columnsToDisplay]="['from_date', 'calendar_event_type.name', 'updated_at']" 
            [customColumnNames]="{ from_date: 'Date' }"
            [runUpdate]="refreshTable$" entityName="CalendarEvent" [showSearchFields]="false" 
            [fixedSearchParams]="tableFilter"
            [sortFields]="{field: 'from_date', asc: false}">
          </con-entity-list>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading && reportDatesPageError">
    <div class="alert alert-danger">
      {{ reportDatesPageError }}
  </div>
  </ng-container>
</div>