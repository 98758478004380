<div class="content">
    <div class="container-fluid mb-3">
      <div class="row">
        <div class="col-12">
          <con-loader *ngIf="loading"></con-loader>
          <ng-container *ngIf="!loading">
              <con-customers-editor
                          mode="revision"
                          [customer]="customer"
                          (afterFixed)="whenFixed($event)"
                          (afterUpdate)="removeFromList($event)">
                      </con-customers-editor>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  