<con-entity-form
    *ngIf="hasUser"
    entityName="Task"
    emptyAfterSave="true"
    [except]="['internal_name', 'done']"
    [fixedValues]="fixedValues"
    [canEdit]="profileService.can(profileService.getPermissionName('Task', 'store'))"
    [canShow]="profileService.can(profileService.getPermissionName('Task', 'show'))"
    [canDelete]="profileService.can(profileService.getPermissionName('Task', 'delete'))"
>
</con-entity-form>
