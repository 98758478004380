<div class="modal-header">
    <h5 class="modal-title">{{modalData.title}}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">
            <con-icon-by-name iconName="close"></con-icon-by-name>
        </span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0">
        {{modalData.text}}
    </p>
    <p class="mt-2 mb-0">
        {{modalData?.subText}}
    </p>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="activeModal.dismiss('abort')">{{buttonLabels?.cancel || 'Cancel'}}</button>
    <button class="btn btn-primary" (click)="activeModal.close()">{{buttonLabels?.confirm || 'Confirm'}}</button>
</div>