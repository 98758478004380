import { Component } from '@angular/core';
import { DocProcessProjectName, SelectedProjectService } from '../../../sub-modules/kpi-and-kiid/services/selected-project.service';
import { CanDeactivateComponent } from '../../../sub-modules/universal-data-entry/interfaces/can-deactivate-component';
import { DocProcessService } from '../../../sub-modules/doc-process-common/services/doc-process.service';

@Component({
  selector: 'con-data-entry-view-router',
  templateUrl: 'data-entry-view-router.component.html',
})
export class DataEntryViewRouterComponent implements CanDeactivateComponent {
  constructor(public selectedProjectService: SelectedProjectService, private docProcessService: DocProcessService) {}

  get DocProcessProjectName(): typeof DocProcessProjectName {
    return DocProcessProjectName;
  }

  canDeactivate(): boolean {
    return !this.docProcessService.unsavedData.getValue();
  }
}
