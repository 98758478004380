import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'con-institution-press-release',
  templateUrl: './institution-press-release.component.html',
  styleUrls: ['./institution-press-release.component.scss']
})
export class InstitutionPressReleaseComponent implements OnInit {
  institutionId = null;
  institution = null;
  public changes$: Subject<any> = new Subject<any>();
  loading = true;
  constructor(private router: Router, private reportService: CompanyReportsService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.params.pipe(  tap((params: Params) => {
      this.reportService.getInstitutionData(params.id).subscribe((institutionDetails) => {
        this.institution = institutionDetails;
      })
    })).subscribe((data) => {
      if(data.id) {
        this.institutionId = data.id;
      }
      this.loading = false;
    })
  }

  reloadListing(event: any) {
    this.changes$.next(event);
  }
  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      const websiteStringStripRegex = /(^\w+:|^)\/\//;
      return companyWebsite.replace(websiteStringStripRegex, '');
    }
    return '';
  }
}
