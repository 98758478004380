<form [formGroup]="group" (ngSubmit)="returnData()" novalidate>
    <con-entity-search-field
        *ngIf="!entity"
        [parentEntity]="parentEntity"
        [entityName]="relation.owner"
        [fixedSearchParams]="fixedSearchParams"
        [disabled]="disabled"
        (onSelect)="objectSelected($event)">
    </con-entity-search-field>
    <div class="alert alert-info alert-dismissible mb-0" *ngIf="entity">
        <button type="button" class="close" aria-label="Close" (click)="empty()" *ngIf="!alreadyCreated()">
            <span aria-hidden="true">&times;</span>
        </button>
        <con-icon-by-name
              [iconName]="entityDescriptions[relation.owner].getIcon()"
              class="mr-1">
        </con-icon-by-name>
        <strong>{{ entityDescriptions[relation.owner].getPrimaryString(entity) }}</strong>
    </div>
    <ng-container *ngFor="let field of relation.pivot_fields">
        <div class="form-group"
                *ngIf="group && group.get(field.key)">
            <label><small>{{ field.label }}</small></label>
            <div>
                <con-flexible-input [control]="group.get(field.key)" [field]="field"></con-flexible-input>
                <con-form-errors *ngIf="errors && errors.fields" [control]="group?.get(field.key)" [extra]="errors?.fields[field.key]"></con-form-errors>
            </div>
        </div>
    </ng-container>
    <button type="submit" class="btn btn-success" *ngIf="hasPivotFields()">Save</button>
    <button class="btn btn-warning" *ngIf="alreadyCreated()" (click)="abort()">Abort</button>

</form>
