import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedInstancesService {
   // - Nobody outside the Store should have access to the BehaviorSubject
  //   because it has the write rights
  // - Writing to state should be handled by specialized Store methods (ex: addTodo, removeInstanceMetadata, etc)
  // - Create one BehaviorSubject per store entity, for example if you have TodoGroups
  //   create a new BehaviorSubject for it, as well as the observable$, and getters/setters
  private readonly _instanceMetadata = new BehaviorSubject<InstanceMetadata>(null);
  private readonly _selectedInstanceIds = new BehaviorSubject<{projectInstanceTable: number[]}>(null);

  // Expose the observable$ part of the _todos subject (read only stream)
  readonly instancesMetadata$ = this._instanceMetadata.asObservable();
  readonly selectedInstanceIds$ = this._instanceMetadata.asObservable();


  // the getter will return the last value emitted in _todos subject
  get instancesMetadata(): InstanceMetadata {
    return this._instanceMetadata.getValue();
  }


  // assigning a value to this.todos will push it onto the observable
  // and down to all of its subsribers (ex: this.todos = [])
  private set instanceMetadata(val: InstanceMetadata) {
    this._instanceMetadata.next(val);
  }

  addInstanceMetadata(metadata: InstanceMetadata) {
    // we assaign a new copy of todos by adding a new to do to it
    // with automatically assigned ID ( don't do this at home, use uuid() )
    this.instanceMetadata = metadata
  }

    // the getter will return the last value emitted in _todos subject
  get selectedInstanceIds(): {projectInstanceTable: number[]} {
    return this._selectedInstanceIds.getValue();
  }


  // assigning a value to this.todos will push it onto the observable
  // and down to all of its subsribers (ex: this.todos = [])
  set selectedInstanceIds(data: {projectInstanceTable: number[]}) {
    this._selectedInstanceIds.next(data);
  }

  addselectedInstanceIds(data: {projectInstanceTable: number[]}) {
    // we assaign a new copy of todos by adding a new to do to it
    // with automatically assigned ID ( don't do this at home, use uuid() )
    this.selectedInstanceIds = data
  }

  constructor() { }
}


export interface InstanceMetadata {
  projectInstanceTable: Array<InstanceMetadataData>
}
export interface InstanceMetadataData {
  created: string
  doc_lang: string
  id: number
  instance_status: string
  issuer_name: string
  selected: boolean
  task_id: number
  taxonomy_title: string
}
