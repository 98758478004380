<ng-container *ngIf="isLoading; else customFormSection">
    <con-loader></con-loader>
</ng-container>
<ng-template #customFormSection>
    <div class="card mb-2">
        <div class="card-header cursor-pointer" (click)="expandCreateForm()">
            <div class="d-flex justify-content-start align-items-center">
                <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                Create new calendar event
                <div class="card-header-toggle ml-auto">
                    <con-icon-by-name iconName="angle-right" [mutations]="getMutations(!showCreateForm)"></con-icon-by-name>
                </div>
            </div>
        </div>
        <div *ngIf="!hasPermission && showCreateForm" class="alert alert-danger m-3">
            {{permissionErrorMessage}}
        </div>
        <div class="card-body" *ngIf="showCreateForm && hasPermission">
            <form [formGroup]="calendarEventForm" (ngSubmit)="submitCalendarEvent()">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label><small>Date</small></label>
                            <con-day-input [control]="calendarEventForm.controls['from_date']" [field]="{key: 'from_date'}"
                                class="mt-2"></con-day-input>
                            <div class="mt-1">
                                <con-form-errors [control]="calendarEventForm.controls['from_date']"></con-form-errors>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <ng-container *ngFor="let error of formErrors">
                            <div class="alert alert-danger">
                                {{ error }}
                            </div>
                        </ng-container>
                        <con-loader *ngIf="isSaving"></con-loader>
                        <button type="submit" class="btn btn-success" [disabled]="isSaving">Save</button>
                        <button type="button" class="btn btn-warning" (click)="initForm()"
                            [disabled]="!calendarEventForm.dirty">Revert</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>