import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MarketcapCountService } from 'app/market-cap/service/marketcap-count.service';
import { ToastrService } from 'ngx-toastr';
import { MarketcapSettings} from 'app/settings.class';

@Component({
  selector: 'con-marketcap-company',
  templateUrl: './marketcap-company.component.html'
})
export class MarketcapCompanyComponent implements OnInit {

  public PRIMARY_INSTRUMENT = 'duplicate_primary';
  public MISSING_PRIMARY_INSTRUMENT = 'missing_primary';
  public SHARE_COUNT = 'share_count';
  public SHARE_COUNT_MISSING = 'missing';
  public SHARE_COUNT_UPDATE = 'outdated';
  public REVIEW = 'review';


  public company: any;
  private loading: boolean;
  public activeTab: string = this.SHARE_COUNT;
  public editorHeader: string;
  public showForm = true;
  public view: string;
  public noDataMessage = 'No data to display.';
  public preFillValues: any;
  public displayEntityList = false;
  public managerName = 'Manager not assigned';
  public isShareCountListRequested = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private countService: MarketcapCountService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.setActiveTab(params.view);
      });
    this.route.params.pipe(
      switchMap((params: Params) => {
        this.loading = true;

        return this.http.get(MarketcapSettings.BASE_URL + '/' + MarketcapSettings.COMPANY_ENDPOINT + '/' + params.id);
      }))
      .subscribe((company: any) => {
        this.view = this.setActiveTab(company.status);
        this.company = company;

          if (this.company && this.company.manager && this.company.manager.first_name) {
            this.managerName = this.company.manager.first_name + ' ' + this.company.manager.last_name;
          }

        this.setEditorTitle(company.status);
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.toastr.error('Sorry, some error occurred!');
      });
  }
  setEditorTitle(status: string) {
    switch (status) {
      case this.SHARE_COUNT_MISSING:
        this.editorHeader = 'New share count';
        this.displayEntityList = false;
        break;
      case this.SHARE_COUNT_UPDATE:
        this.editorHeader = 'Update share count';
        this.displayEntityList = true;
        this.preFillValues = {valid_from: this.company.valid_from};
        break;

    }
  }
  setActiveTab(view: string): string {

    switch (view) {
      case this.SHARE_COUNT_MISSING:
      case this.SHARE_COUNT_UPDATE:
      case this.SHARE_COUNT:
        this.activeTab = this.SHARE_COUNT;
        break;
      case this.PRIMARY_INSTRUMENT:
        this.activeTab = this.PRIMARY_INSTRUMENT;
        break;
      case this.MISSING_PRIMARY_INSTRUMENT:
        this.activeTab = this.MISSING_PRIMARY_INSTRUMENT;
        break;
      case this.REVIEW:
        this.activeTab = this.REVIEW;
        this.getShareCountsForReview();
        break;

    }
    return this.activeTab;
  }
  getShareCountsForReview() {
    this.isShareCountListRequested = true;
  }
  isLoading() {
    return this.loading;
  }
  updateInList(company: any) {
    this.showForm = false;
    this.countService.getCounts();
  }

  showNoDataMessage(): boolean {
    if (this.company) {
      if (this.activeTab === this.REVIEW && this.company.share_counts && this.company.share_counts.length > 0) {
        return false;
      } else if (this.view !== this.activeTab) {
        return true;
      }
    }

    return false;
  }
}
