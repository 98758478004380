import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CompanyReportsService } from '../../services/company-reports.service';
import * as moment from 'moment';
import { StatementType } from '../../models/StatementType';
import { Router } from '@angular/router';
import { EntitiesService } from '../../../entities/services/entities.service';
;

@Component({
  selector: 'con-statements-listing',
  templateUrl: './statements-listing.component.html',
  styleUrls: ['./statements-listing.component.scss']
})
export class StatementsListingComponent implements OnInit {
  @Input() entity: any;
  @Output() updatedFigures = new EventEmitter<boolean>();
  public allStatementsData: any;
  public loading = true;
  public expandIncomeStatements = true;
  public expandBalanceSheets = true;
  public expandCashFlows = true;
  public expandPeriodKpis = true;
  public expandSnapshotKpis = true;
  public statementType = StatementType;

  constructor(private reportsService: CompanyReportsService, public entityService: EntitiesService, private router: Router) {
  }

  ngOnInit(): void {
    this.entityService.combinedStatementListing.subscribe(entity => {
      this.loading = true;
      this.getInitialData();
    });
    this.getInitialData();
  }
  getInitialData() {
    this.reportsService.getAllStatement(this.entity.id).subscribe((data: any) => {
      data.income_statements.map(item => {
        item.hide = true;
      })
      data.balance_sheets.map(item => {
        item.hide = true;
      })
      data.cash_flows.map(item => {
        item.hide = true;
      })
      data.period_kpis.map(item => {
        item.hide = true;
      })
      data.snapshot_kpis.map(item => {
        item.hide = true;
      })
      this.allStatementsData = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    })
  }
  getMutations(event) {
    return (!event ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }

  expandIncomeStatementListing() {
    this.expandIncomeStatements = !this.expandIncomeStatements;
  }

  expandBalanceSheetListing() {
    this.expandBalanceSheets = !this.expandBalanceSheets;
  }

  expandCashFlowListing() {
    this.expandCashFlows = !this.expandCashFlows;
  }
  expandPeriodKpiListing() {
    this.expandPeriodKpis = !this.expandPeriodKpis;
  }
  expandSnapshotKpiListing() {
    this.expandSnapshotKpis = !this.expandSnapshotKpis;
  }

  incomeStatementUpdated(updatedData: any, index: number) {
    this.allStatementsData.income_statements[index] = updatedData;
    this.updatedFigures.emit(true);
  }
  balanceSheetUpdated(updatedData: any, index: number) {
    this.allStatementsData.balance_sheets[index] = updatedData;
    this.updatedFigures.emit(true);
  }
  cashFlowUpdated(updatedData: any, index: number) {
    this.allStatementsData.cash_flows[index] = updatedData;
    this.updatedFigures.emit(true);
  }
  periodKpiUpdated(updatedData: any, index: number) {
    this.allStatementsData.period_kpis[index] = updatedData;
    this.updatedFigures.emit(true);
  }

  snapshotKpiUpdated(updatedData: any, index: number) {
    this.allStatementsData.snapshot_kpis[index] = updatedData;
    this.updatedFigures.emit(true);
  }
  getDisplayNameISCS(income: any) {
    let displayName = '';
    if (income) {
      displayName +=
        moment(income.from_date).format('yyyy-MM-DD') +
        ', ' +
        moment(income.to_date).format('yyyy-MM-DD') +
        ', ' + income.quantity.name +
        ', ' + income.unit.name

    }
    return displayName;
  }

  getDisplayNameBS(bs: any) {
    let displayName = '';
    if (bs) {
      displayName +=
        moment(bs.balance_sheet_date).format('yyyy-MM-DD') +
        ', ' + bs.quantity.name +
        ', ' + bs.unit.name
    }
    return displayName;
  }

  toggleType(event: any) {
    event.hide = !event.hide;
  }

  getDisplayNamePeriodKpi(pk: any) {
    let displayName = '';
    if (pk) {
      displayName +=
        moment(pk.from_date).format('yyyy-MM-DD') +
        ', ' +
        moment(pk.to_date).format('yyyy-MM-DD')
    }
    return displayName;
  }

  getDisplayNameSnapshotKpi(sk: any) {
    let displayName = '';
    if (sk) {
      displayName +=
        moment(sk.snapshot_date).format('yyyy-MM-DD')
    }
    return displayName;
  }

}
