<div class="card mb-2" *ngIf="showForm()">
    <div class="card-header">
        <con-icon-by-name iconName="external-link" [mutations]="['fw']"></con-icon-by-name>Webhook Url
    </div>
    <div class="card-body">
        <con-entity-form-content
            entityName="Customer"
            [entity]="customer"
            [only]="['webhook_url', 'revised_at', 'webhook_active']"
            [fixedValues]="fixedValues"
            [showWhenChanged]="false"
            [canDelete]="false"
            (afterSave)="customerUpdated($event)"
            toastMessage="Updated successfully!"
        >
        </con-entity-form-content>
    </div>
    <div class="card-body pt-0" *ngIf="customer.webhook_url !== null">
        <button class="btn btn-secondary" (click)="toggleForm()">
            <con-icon-by-name iconName="remove"></con-icon-by-name> Cancel
        </button>
    </div>
</div>
<div class="card mb-2" *ngIf="!showForm()">
    <div class="card-header">
        <con-icon-by-name iconName="external-link" [mutations]="['fw']"></con-icon-by-name>Webhook Url
    </div>
    <div class="card-body">
        <div class="alert alert-info">
            <con-icon-by-name iconName="external-link" class="mr-1"></con-icon-by-name><strong>{{ customer.webhook_url }}</strong>
        </div>
        <button class="btn btn-info" (click)="toggleForm()" *ngIf="profile.can(profile.getPermissionName('Customer', 'update'))">
            <con-icon-by-name iconName="pencil"></con-icon-by-name> Edit
        </button>
    </div>
</div>