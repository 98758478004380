<div class="card mb-2" *ngIf="showForm()">
    <div class="card-header">
      <con-icon-by-name iconName="info" [mutations]="['fw']"></con-icon-by-name>{{ language.name }} description
      <button class="btn btn-primary position-btn"(click)="openTranslationWithGivenMissingDescription(englishDescription, language)" *ngIf="showTranslateButton()">
        <con-icon-by-name iconName="globe"></con-icon-by-name> Translate
      </button>
    </div>
    <div class="card-body">
        <con-entity-form-content
            entityName="Description"
            [entity]="description"
            [only]="['description', 'revised_at']"
            [fixedValues]="fixedValues"
            [showWhenChanged]="false"
            [canDelete]="!required"
            (afterSave)="descriptionUpdated($event)"
            (afterDelete)="descriptionDeleted($event)"
            toastMessage="Description updated successfully!"
        >
        </con-entity-form-content>
    </div>
    <div class="card-body pt-0" *ngIf="description">
        <button class="btn btn-secondary" (click)="toggleForm()">
            <con-icon-by-name iconName="remove"></con-icon-by-name> Cancel
        </button>
    </div>
</div>
<div class="card mb-2" *ngIf="!showForm()">
    <div class="card-header">
        <con-icon-by-name iconName="info" [mutations]="['fw']"></con-icon-by-name>{{ language.name }} description
    </div>
    <div class="card-body">
        <div class="alert alert-info">
            <strong><p>{{ description.description }}</p></strong>
        </div>
        <button class="btn btn-info" (click)="toggleForm()" *ngIf="profile.can(profile.getPermissionName('Description', 'update'))">
            <con-icon-by-name iconName="pencil"></con-icon-by-name> Edit
        </button>
        <button class="btn btn-primary" (click)="openTranslation(description)" *ngIf="canTranslate()">
            <con-icon-by-name iconName="globe"></con-icon-by-name> Translate
        </button>
    </div>
</div>
