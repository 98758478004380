import { Component, Input } from '@angular/core';

@Component({
    selector: 'con-icon-by-name',
    templateUrl: './icon-by-name.component.html'
})

export class IconByNameComponent {
    @Input() iconName: string;
    @Input() mutations: any = [];

    getClasses() {
        let str = 'fa-' + this.iconName;
        this.mutations.forEach(mutation => {
            str += ' fa-' + mutation;
        });
        return str;
    }
}
