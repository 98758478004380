<con-loader *ngIf="loading"></con-loader>
<div *ngIf="hasTask" class= "row">
    <div class="col-6">
        <div class="card mb-3">
            <div class="card-header">{{task.task_type !== 'INDICES' ? 'Symbols' : 'Index details'}}</div>
            <div class="card-body p-2 symbols">
                <con-table 
                    [isEditable]="false"
                    [withHeader]="true"
                    [tableHeadData]="symbolsHeader" 
                    [wideCells]="true"
                    [tableBodyData]="symbolsBody">
                </con-table>
            </div>
        </div>
        <div *ngIf="task.task_type !== 'INDICES'" class="card">
            <div class="card-header">Attributes</div>
            <div class="card-body symbol-attributes p-2">
                <con-table 
                    [isEditable]="false"
                    [withHeader]="true"
                    [tableHeadData]="symbolAttributesHeaderNames" 
                    [tableBodyData]="task.symbol_attributes">
                </con-table>
            </div>
        </div>
    </div>
    <div class="col-6 m-0">
        <div class="col-12 mb-3">
            <div class="card">
                <div class="card-header">Task</div>
                <div class="card-body">
                    <button (click)="requestAction('activateTask')" [class]="!taskIsActive ? 'btn btn-success mr-1' :'btn btn-success disabled mr-1'" [disabled]="taskIsActive">Activate task</button>
                    <button (click)="requestAction('deactivateTask')" [class]="taskIsActive ? 'btn btn-warning mr-1' :'btn btn-warning disabled mr-1'" [disabled]="!taskIsActive">Deactivate task</button>
                    <button (click)="requestAction('deleteTask')" class="btn btn-danger mr-1">Delete task</button>
                    <button (click)="resetEditTask();open(content)" class="btn btn-primary mr-1 float-right" [disabled]="taskIsActive">Edit task</button>
                    <ngb-alert *ngIf="!taskIsActive" [dismissible]="false" class="mt-3">
                        <strong>Warning!</strong> Task is deactivated
                    </ngb-alert>
                </div>
                <div class="card-body p-2">
                    <con-table 
                        [isEditable]="false"
                        [withHeader]="true"
                        [tableHeadData]="['Name', 'Schedule', 'Mask', 'Active', 'ID' ]" 
                        [tableBodyData]="[taskDetail]"
                    ></con-table>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-header">Deliveries</div>
                <div class="card-body p-2">
                    <con-table 
                        [linkPath] = "'deliveries'"
                        [clickableCells] = "[0]"
                        [isEditable]="false"
                        [withHeader]="true"
                        [tableHeadData]="['Delivery ID', 'Scheduled time', 'Delivery state' ]" 
                        [tableBodyData]="deliveries"
                    ></con-table>
                </div>
            </div>
        </div>
        <div class="col-12">
            <button class="btn btn-primary mt-2 mb-3 mr-1" (click)="requestAction('createMozendaDelivery')">Create Mozenda delivery</button>
            <button class="btn btn-primary mt-2 mb-3 mr-1" (click)="requestAction('createManualDelivery')">Create Manual delivery</button>
            <ngb-alert *ngIf="createdDelivery" [type]="succesfullyCreatedDelivery ? 'success' : 'danger'" [dismissible]="true" (close)="createdDelivery = !createdDelivery">
                {{createdDeliveryFeedback}}
            </ngb-alert>
        </div>
    </div>
</div>
<div *ngIf="hasTask" class="row mt-3">
    <div class="col-12">
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Edit Mode</span></label>
            <select class="custom-select" type="text" ng-model="dropdownOptions" (change)="toggleEditMode($event.target.value)">
                <option *ngFor="let option of dropdownOptions" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>     
        <div class="card">
            <div class="card-header">Edit</div>
            <div class="card-body">
                <con-agent-edit-view 
                    *ngIf="this.editMode === 'agent-view'"
                    (loadingAnimationHandler)="animationHandler($event)" 
                    (actionHandler)="actionStateHandler($event)" 
                    [taskDetail]="taskDetail"
                    [checkPassed]="checkPassed"
                    [checkLoading]="checkLoading"
                >
                </con-agent-edit-view>
                <con-email-mapping-view
                    *ngIf="this.editMode === 'email-mapping'"
                    (loadingAnimationHandler)="animationHandler($event)" 
                    (actionHandler)="actionStateHandler($event)" 
                    [taskDetail]="taskDetail"
                    [checkPassed]="checkPassed"
                    [checkLoading]="checkLoading"
                >
                </con-email-mapping-view>
                <indicies-view
                    *ngIf="this.editMode === 'indicies-view'"
                    (loadingAnimationHandler)="animationHandler($event)" 
                    (actionHandler)="actionStateHandler($event)" 
                    [taskDetail]="taskDetail"
                    [checkPassed]="checkPassed"
                    [checkLoading]="checkLoading">
                </indicies-view>
                
            </div>
        </div>
    </div>
</div>
<div *ngIf="!hasTask && !loading">
    Task does not exist
</div>

<ng-template #content let-modal>
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit task</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <con-editor *ngIf="!updateTaskSpecLoading && !updateTaskSpecResponseMessage" (onChange)="onConfigChange($event)" [data]="taskSpec"></con-editor>
            <div *ngIf="!updateTaskSpecLoading && updateTaskSpecResponseMessage">{{updateTaskSpecResponseMessage}}</div>
            <con-loader-task-manager *ngIf="updateTaskSpecLoading && !updateTaskSpecResponseMessage"></con-loader-task-manager>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button type="button" [disabled]="updateTaskSpecLoading || updateTaskSpecResponseMessage" class="btn btn-primary" (click)="requestAction('updateTaskSpec');">Save</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Cancel')" >{{updateTaskSpecResponseMessage ? 'Go back' : 'Cancel'}}</button>
        </div>
    </div>
</ng-template>