import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';

import { EntityDescription, EntitiesService } from '../../services/entities.service';
import { RelationService, Relation } from '../../services/relation.service';

@Component({
    selector: 'con-has-one-editor',
    templateUrl: './has-one-editor.component.html'
})

export class HasOneEditorComponent implements OnChanges {
    @Input() relation: any;
    @Input() entity: any;
    @Input() canShow = true;
    @Input() canEdit = true;
    @Input() canEditRequired = true;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();


    public loading: boolean;

    public relatedEntityDescriptions: any;
    public inverseRelations: any;
    public fixedValuesForChilds: any;
    public fixedSearchParams: any;

    public relatedObject: any;
    public relatedType: any;

    public existing: boolean;
    public relations: Relation;
    constructor(private service: EntitiesService,
                private relationService: RelationService) {}

    ngOnChanges() {
        this.loading = true;
        this.reset();
        this.relations = this.relationService.getRelation(this.relation);
        this.relations.init()
                      .subscribe(() => {
                          this.relatedEntityDescriptions = this.relations.getAllRelatedEntityDescriptions();
                          this.inverseRelations = this.relations.getAllInverseRelations();
                          this.fixedValuesForChilds = this.relations.getAllFixedChildValues(this.entity);
                          this.fixedSearchParams = this.relations.getAllFixedSearchParams(this.entity);
                      })

        this.service.getRelation(this.relation.owner, this.entity.id, this.relation.name)
                    .subscribe(res => {
                        this.relatedObject = res;
                        if (res.id) {
                            this.existing = true;
                        }
                        this.loading = false;
                    },
                    err => {
                        this.existing = false;
                        this.loading = false;
                    });
    }
    reset() {
        this.relatedEntityDescriptions = {};
        this.fixedValuesForChilds = {};
        this.inverseRelations = [];
        this.relatedType = this.relation.model;
    }

    handleAction($event) {
        if ($event.action === 'detach') {
            this.loading = true;
            this.service.removeRelation(this.relation.owner, this.entity.id, this.relation.name, [$event.entity.id])
                        .subscribe(res => {
                            this.relatedObject = {};
                            this.loading = false;
                            this.existing = false;
                            this.onChange.emit({ action: 'removed', objects: [res] });
                        },
                        err => {
                            this.loading = false;
                            this.existing = true;
                        });
        }
    }
    addRelation(relation, $event) {
        let ownerId, attachId, result;
        if (relation === this.relation) {
            ownerId = this.entity.id;
            attachId = $event.id;
        } else {
            ownerId = $event.id;
            attachId = this.entity.id;
            result = $event;
        }

        this.loading = true;
        this.service.addRelation(relation.owner, ownerId, relation.name, [ attachId ])
                    .subscribe(res => {
                        if (result === undefined) {
                            result = res;
                        }
                        this.relatedObject = result;
                        this.loading = false;
                        this.existing = true;
                        this.onChange.emit({ action: 'added', objects: [ result ] });
                    });
    }

    updateRelationFromForm($event) {
        this.relatedObject = $event;
        if ($event === null) {
            this.ngOnChanges();
        }
    }

    getActions() {
        return (this.canEdit && !this.relation.disabled) ? [{
            name: 'detach',
            label: 'Detach',
            icon: 'eject'
        }] : [];
    }
}
