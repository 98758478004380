import { Injectable } from '@angular/core';
import { AutomatedContentSettings } from 'app/settings.class';
import {ServerChooserService} from './server-chooser.service';
import {Md5} from 'ts-md5/dist/md5';

export interface AuthorizationConnectionString {
  uri: string,
  code: string,
  userToken: string,
}

@Injectable({
  providedIn: 'root'
})
export class EnvSettingsService {

  public ML_BACKEND_URI() {
    const server: Environment = this.serverChooserService.getBackendEnvName()

    if (!server)
      return

    const envNameAndUriMapping = {
      'local': AutomatedContentSettings.MOZENDA_URL_LOCAL,
      'dev': AutomatedContentSettings.DOC_PROCESSING_URL_DEV,
      'test': AutomatedContentSettings.DOC_PROCESSING_URL_TEST,
      'preview': AutomatedContentSettings.DOC_PROCESSING_URL_PREVIEW,
      'labeling': AutomatedContentSettings.DOC_PROCESSING_URL_LABELING,
      'production': AutomatedContentSettings.DOC_PROCESSING_URL_PRODUCTION,
    }

    let envUri = envNameAndUriMapping[server.toString().toLowerCase()] ?? envNameAndUriMapping['production']

    return envUri + 'userToken=' + EnvSettingsService.generateUserToken() + "&";
  }

  constructor(
    private serverChooserService: ServerChooserService,
  ) {
  }

  public static getEnvironmentColor(env: Environment): string {
    const envHumanFriendlyNameMapping = { }
    envHumanFriendlyNameMapping[Environment.Local] = '#198754'
    envHumanFriendlyNameMapping[Environment.Dev] = '#198754'
    envHumanFriendlyNameMapping[Environment.Test] = '#0d6efd'
    envHumanFriendlyNameMapping[Environment.Preview] = '#d5c800'
    envHumanFriendlyNameMapping[Environment.Labeling] = '#fd7e14'
    envHumanFriendlyNameMapping[Environment.Production] = '#dc3545'

    return envHumanFriendlyNameMapping[env] ?? "#dc3545"
  }

  public static getEnvironmentHumanFriendlyName(env: Environment): string {
    const envHumanFriendlyNameMapping = { }
    envHumanFriendlyNameMapping[Environment.Local] = 'Local'
    envHumanFriendlyNameMapping[Environment.Dev] = 'Development'
    envHumanFriendlyNameMapping[Environment.Test] = 'Test'
    envHumanFriendlyNameMapping[Environment.Preview] = 'Preview'
    envHumanFriendlyNameMapping[Environment.Labeling] = 'Labeling'
    envHumanFriendlyNameMapping[Environment.Production] = 'Production'

    return envHumanFriendlyNameMapping[env] ?? "Unknown"
  }

  private static generateUserToken(): string {
    let userEmail = JSON.parse(localStorage.getItem('USER-DETAILS')).email;
    let created_at = JSON.parse(localStorage.getItem('USER-DETAILS')).created_at;

    const md5 = new Md5();
    let md5HashString = md5.appendStr(userEmail+created_at).end().toString();

    return md5HashString;
  }
}

export enum Environment {
  Local='local',
  Dev='dev',
  Test='test',
  Preview='preview',
  Labeling='labeling',
  Production='production',
}
