import {cloneDeep} from 'lodash';
import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbCarouselConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  DocProcessProjectName,
  SelectedProjectService
} from 'app/doc-process/sub-modules/kpi-and-kiid/services/selected-project.service';
import {DocProcessService} from 'app/doc-process/sub-modules/doc-process-common/services/doc-process.service';
import {Query} from 'app/doc-process/sub-modules/doc-process-common/services/methods.service';
import {HTTPMethod, HTTPResponse} from 'app/doc-process/sub-modules/doc-process-common/models/api';
import {FieldClass} from 'app/doc-process/sub-modules/kpi-and-kiid/models/field-color';
import {
  CurrentFields,
  InstanceDefinition,
  InstanceDocumentAnnotations,
  NewsAnnotation
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {
  Instance,
  InstanceData,
  InstanceDataKiid,
  InstanceDataNews,
  InstanceDataTopicClassification,
  InstanceDataUnioned
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import {Observable, Subscription} from 'rxjs';
import {DataExtractorService} from '../../services/data-extractor.service';
import {DataRequestService} from '../../services/data-request.service';
import {DocProcessLegacyCommonsService} from '../../services/doc-process-legacy-commons.service';
import {DocumentService} from '../../../doc-process-common/services/document.service';
import {HotkeyHandlerService} from '../../services/hotkey-handler.service';
import {NewsInstancesService} from '../../../doc-process-common/services/news-instances.service';
import {UtilService} from '../../../doc-process-common/services/util.service';
import {DeleteAnnotationService} from '../../services/delete-annotation.service';
import {SelectedAnnotationService} from '../../services/selected-annotation.service';
import {SelectedQuantityService} from '../../services/selected-quantity.service';
import {SelectedFieldService} from '../../services/selected-field.service';
import {SelectedSignService} from '../../services/selected-sign.service';
import TextHighlighter from 'texthighlighter';
import {NewsInstancesHandlerComponent} from '../../components/news-instances-handler/news-instances-handler.component';
import NewsSanityCheckService from '../../../doc-process-common/services/news-sanity-check.service';
import {
  ClearDataModalComponent
} from '../../../doc-process-common/components/clear-data-modal/clear-data-modal.component';
import {FactsheetService} from '../../services/factsheet.service';
import {PdfViewerComponent} from 'ng2-pdf-viewer';
import {ZoomService} from '../../../doc-process-common/services/zoom.service';
import {DataFieldValidityService} from '../../../doc-process-common/services/data-field-validity.service';
import {Task} from '../../../universal-data-entry/models/Task';

@Component({
  selector: 'doc-process-annotation-view-component',
  templateUrl: './doc-process-annotation-view.component.html',
  styleUrls: ['./doc-process-annotation-view.component.scss'],
  providers: [DataFieldValidityService],
})
export class DocProcessAnnotationView implements OnInit, OnDestroy {
  public pageIndex = 0;
  public fetchingData = false;
  public updatingInstanceData = false;
  public instanceDefinitions: Array<InstanceDefinition> = [];
  public fieldClasses: Array<FieldClass> = [];
  public instanceTableInfo: [Pick<Instance, 'instance_id' | 'issuer_name'>] = null;
  public currentFields: CurrentFields[] = [];
  public failedToFetchDocumentFeedback: string = null;
  public documentSafeResourceUrl: any;
  public documentSafeResourceUrl$: Observable<any>;
  public documentString: string;
  public annotations: InstanceDocumentAnnotations = null;
  public instanceDataItems: InstanceDataKiid[];
  public pdfViewerHeight: number = 0;
  public scrollTop = 0;
  public projectId = 0;
  public instanceId = 0;
  public instanceData: any = null;
  public compl = [];
  private dontRenderUi: boolean = false;

  private _instanceSubmissionCheckboxStatus = {}
  get instanceSubmissionCheckboxStatus() {
    return this._instanceSubmissionCheckboxStatus
  }
  set instanceSubmissionCheckboxStatus(val) {
    this._instanceSubmissionCheckboxStatus = val
  }

  private taskInstances: Instance[] = [];

  public taskInstance: Task;

  @ViewChild('pdfViewer') pdfViewer: ElementRef<HTMLDivElement>;
  @ViewChild('kiidDocumentPdfViewer') kiidDocumentPdfViewer: PdfViewerComponent;
  @ViewChild('instancesContainer') instancesContainer: ElementRef<HTMLDivElement>;
  @ViewChild('htmlViewer') htmlViewer: ElementRef<HTMLDivElement>;
  @ViewChild('newsInstancesHandlerReference') newsInstancesHandlerReference: NewsInstancesHandlerComponent;
  @ViewChild('saveConfirmationModal') saveConfirmationModal: ElementRef;
  @ViewChild('clearDataConfirmationModal') clearDataConfirmationModal: ClearDataModalComponent;
  @ViewChild('instanceNavigationTable') instanceNavigationTable: any;
  private taskId: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private deleteAnnotationService: DeleteAnnotationService,
    private selectedAnnotationService: SelectedAnnotationService,
    private selectedQuantityService: SelectedQuantityService,
    private selectedFieldService: SelectedFieldService,
    private selectedSignService: SelectedSignService,
    private docProcessService: DocProcessService,
    private config: NgbCarouselConfig,
    private router: Router,
    private route: ActivatedRoute,
    private dataExtractorService: DataExtractorService,
    private pdfService: DocumentService,
    private dataRequestService: DataRequestService,
    private newsInstanceService: NewsInstancesService,
    private docProccessCommonsService: DocProcessLegacyCommonsService,
    private hotkeyHandlerService: HotkeyHandlerService,
    public utilService: UtilService,
    public selectedProjectService: SelectedProjectService,
    private newsSanityCheckService: NewsSanityCheckService,
    private modalService: NgbModal,
    public zoomService: ZoomService,
    private dataFieldValidityService: DataFieldValidityService,
  ) {
    this.config.interval = 0;
    this.config.showNavigationIndicators = false;
    this.config.keyboard = true;
  }

  async ngOnInit() {
    let routeParamsSub = this.route.params.subscribe((params: any) => {
      this.projectId = parseInt(params.project_id);
      this.taskId = JSON.parse(params.task_id);
      this.docProcessService.taskId.next(this.taskId)

      this.taskInstance = this.docProcessService.getTaskInstances(this.taskId);
      if (!this.taskInstance) {
        this.router.navigateByUrl(this.getTaskValidationUrl(), {});
        this.dontRenderUi = true;
        return
      }

      this.createTaskInstances(this.taskInstance);
    });
    this.subscriptions.push(routeParamsSub)

    let restoreDataSub = this.docProccessCommonsService.restoreData.subscribe((restore) => {
      if (restore) {
        if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
          this.newsInstanceService.textHighlighter.destroy();
          this.newsInstanceService.textHighlighter = null;
          this.newsInstanceService.previousTextAnnotations = [];
        }
        this.instanceDefinitions = null;
        this.createTaskInstances(this.taskInstance);
      }
    });
    this.subscriptions.push(restoreDataSub)

    let apiResponseSub = this.docProcessService.apiResponse.subscribe(async (response: HTTPResponse) => {
      this.failedToFetchDocumentFeedback = null;
      const { data, type } = response;
      switch (type) {
        case Query.GetInstanceData:
          {
            this.instanceData = data.instances[this.instanceId];
            for (const instanceDefinition of this.instanceDefinitions) {
              const { InstanceId } = instanceDefinition;
              switch (this.selectedProjectService.project) {
                case DocProcessProjectName.PrNewsLabeling: {
                  instanceDefinition.InstanceDataNews = data.instances[InstanceId];
                  this.newsInstanceService.instanceDataNews = this.instanceData as InstanceDataUnioned;
                  this.newsInstanceService.updateNewsFieldsValues(this.instanceData as InstanceDataUnioned);
                  this.hotkeyHandlerService.createHotkeyBindings(this.instanceData as InstanceDataUnioned, this.newsInstanceService.selectedTaxonomyDataField.toString());
                  break;
                }
                case DocProcessProjectName.Kiid:
                case DocProcessProjectName.KiidMetadata:
                case DocProcessProjectName.Factsheet:
                case DocProcessProjectName.KiidPriip: {
                  instanceDefinition.InstanceDataKiid = data.instances[InstanceId];
                  break;
                }
                case DocProcessProjectName.TopicClassification: {
                  instanceDefinition.InstanceDataTopicClassification = data.instances[InstanceId];
                  break;
                }
                case DocProcessProjectName.Calendar: {
                  instanceDefinition.InstanceDataTopicClassification = data.instances[InstanceId];
                  break;
                }
              }
            }
          }
          break;
        case Query.GetDocument:
          {
            if (data) {
              try {
                this.failedToFetchDocumentFeedback = null;
                if (this.selectedProjectService.isProjectTypeAnnotation())
                  this.dataRequestService.requestInstanceData(this.taskInstances, Query.GetJsonAnnotations);
                for (let instanceDefinition of this.instanceDefinitions) {
                  const instanceDocument = data.find((documentData) => documentData.doc_id === instanceDefinition.InstanceDocument.doc_id);
                  if (instanceDocument) {
                    instanceDefinition.InstanceDocument.doc_content = instanceDocument.doc_content;
                    instanceDefinition.InstanceDocument.doc_id = instanceDocument.doc_id;
                    instanceDefinition.InstanceDocument.doc_type = instanceDocument.doc_type;
                    if (this.selectedProjectService.isProjectTypeKiidLike()) {
                      instanceDefinition.InstanceDocument.documentSafeResourceUrl = this.pdfService.getDocument(instanceDefinition);
                      instanceDefinition.InstanceDocument.documentSafeResourceUrl$ = this.pdfService.getDocumentObservable(instanceDefinition);
                    } else {
                      const sanitize = ! this.zoomService.stylingEnabled.getValue()
                      if (instanceDefinition.InstanceDocument.doc_type === 'pdf') {
                        instanceDefinition.InstanceDocument.documentSafeResourceUrl = this.pdfService.getDocument(instanceDefinition);
                        instanceDefinition.InstanceDocument.documentSafeResourceUrl$ = this.pdfService.getDocumentObservable(instanceDefinition);
                      }
                      else {
                        instanceDefinition.InstanceDocument.documentString = this.pdfService.getDocumentString(instanceDefinition, sanitize);
                      }
                    }
                  }
                }
                this.documentSafeResourceUrl = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentSafeResourceUrl;
                this.documentSafeResourceUrl$ = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentSafeResourceUrl$;
                this.documentString = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentString;
                this.loadHtmlDocumentContents(this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument)
                this.fetchingData = false;
              } catch (error) {
                console.warn('error', error);
              }
            }
          }
          break;
        case Query.GetJsonAnnotations:
          {
            const jsonAnnotations = response.data;
            this.onGetJsonAnnotations(jsonAnnotations);
          }
          break;
        case Query.UpdateInstanceData:
          {
            this.updatingInstanceData = false;
            this.docProcessService.displaySaveCompletedToastr(data)
          }
          break;
      }
    });
    this.subscriptions.push(apiResponseSub)

    this.subscriptions.push(this.docProcessService.apiError.subscribe((err: any) => {
      this.fetchingData = false;
      if (err.type === 'getDocument') {
        this.failedToFetchDocumentFeedback = 'No documents found...';
      }
    }));
  }

  private getTaskValidationUrl(): string {
    return UtilService.getUpperRouteUrl(window.location.pathname);
  }

  ngAfterViewInit() {
    if (this.dontRenderUi)
      return;

    if (this.pdfViewer) {
      setTimeout(() => {
        this.pdfViewerHeight = this.pdfViewer.nativeElement.clientHeight;
      }, 0);
    }
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.handleBindings();
    }
    setTimeout(() => {
      this.instanceSubmissionCheckboxStatus[this.instanceId] = true
      this.instanceNavigationTable.selection.select(this.instanceId)
    }, 10)
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach( sub => {
        try {
          sub.unsubscribe();
        }
        catch (e) {
          console.warn("nothing to unsubscribe:")
          console.warn(e)
        }
      })

    this.instanceDefinitions = null;
  }

  createTaskInstances(taskInstance: Task) {
    if (taskInstance || taskInstance.instances) { // TODO Best of Machine Learning Content - Greatest hits Vol. 1
      const { instances } = taskInstance;
      this.taskInstances = instances;
      this.initializeInstanceSubmissinCheckboxStatus(instances)
      if (instances.length > 0) {
        const [currentFields, instanceDefinitions] = this.docProccessCommonsService.createInstanceDefinitions(taskInstance, instances);
        this.currentFields = currentFields as unknown as CurrentFields[];
        this.instanceDefinitions = instanceDefinitions as unknown as InstanceDefinition[];
        this.dataRequestService.requestInstanceData(instances, Query.GetInstanceData);
        this.dataRequestService.requestInstanceDocuments(instances, Query.GetDocument);
        this.fetchingData = true;
        this.instanceId = this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex);
        this.newsInstanceService.setInstanceId(this.instanceId);
        this.instanceTableInfo = this.instanceDefinitions.map((isdf) => isdf.InstanceInfo) as any;
        this.fieldClasses[0] = FieldClass.Current;
      }
    }
  }

  get currentProject(): typeof DocProcessProjectName {
    return DocProcessProjectName;
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(ev: KeyboardEvent) {
    if (ev.shiftKey && !this.fetchingData) {
      UtilService.logIfAdmin(ev.key);
      if (ev.key === '+' || ev.key === '?') {
        ev.preventDefault();
        this.increaseZoom();
      }
      if (ev.key === '-' || ev.key === '_') {
        ev.preventDefault();
        this.lowerZoom();
      }
      if (ev.key === 'Insert' || ev.key === '=') {
        ev.preventDefault();
        this.resetZoom();
      }
      this.handlePageMoving(ev.key);
    }
  }
  @HostListener('window:keydown', ['$event'])
  onKeyboardEvent(ev: KeyboardEvent) {
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      if ((ev.ctrlKey || ev.metaKey || ev.altKey) && ev.key === ' ') {
        ev.preventDefault();
        this.onNewsLabelingCtrlSpaceHotkey();
        this.instanceNavigationTable.selection.select(this.instanceId)
        this.instanceSubmissionCheckboxStatus[this.instanceId] = true
      }
      if (ev.ctrlKey || ev.metaKey || ev.altKey)
        return;
      this.hotkeyHandlerService.handleNewsLabelingHotkeyBindings(
        ev,
        this.instanceData,
        this.newsInstanceService.selectedTaxonomyDataField.toString(),
        this.newsInstanceService.selectedTaxonomyDataContextPeriod
      );
      if (ev.key === 'Delete' || ev.key === 'Backspace') {
        this.onDeleteHotkey();
      }
    }  else if (this.selectedProjectService.isProjectTypeKiidLike()) {
      if ((ev.ctrlKey || ev.metaKey || ev.altKey) && ev.code === 'Space') {
        if (this.dataFieldValidityService.allowInstanceNavigation.getValue() === false) {
          this.dataFieldValidityService.displayInvalidReportDateError()
          return
        }
        this.onKiidCtrlSpaceHotkey();
        this.instanceNavigationTable.selection.select(this.instanceId)
        this.instanceSubmissionCheckboxStatus[this.instanceId] = true
        ev.preventDefault();
      }
    } else if (this.selectedProjectService.project === DocProcessProjectName.TopicClassification || this.selectedProjectService.project === DocProcessProjectName.Calendar) {
      if ((ev.ctrlKey || ev.metaKey || ev.altKey) && ev.key === ' ') {
        ev.preventDefault();
        this.onCtrlSpaceHotkeyForTopicClassification();
        this.instanceNavigationTable.selection.select(this.instanceId)
        this.instanceSubmissionCheckboxStatus[this.instanceId] = true
      }
    }
    if (this.selectedProjectService.project === DocProcessProjectName.Factsheet) {
      if (ev.key !== 'c' || ev.ctrlKey || ev.metaKey || ev.altKey)
        return;

      let selectedRange = window.getSelection()
      let isSelectionWithinDocument = selectedRange.focusNode.parentElement.closest("#kiidDocumentPdfViewer")?.id === "kiidDocumentPdfViewer"
      if (!isSelectionWithinDocument)
        return;

      let selectedText = selectedRange.toString()
      this.hotkeyHandlerService.ctrlCevent$.next(selectedText)
    }
  }

  onGoBackButtonClick() {
    const wantsToExit = confirm('Your unsaved progress will be discarded. Do you want to continue?');
    if (wantsToExit) {
      this.router.navigateByUrl(this.getTaskValidationUrl(), /* Removed unsupported properties by Angular migration: relativeTo. */ {});
    }
  }

  saveDataToBackend() {
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      const data = Object.assign(
        {},
        ...this.instanceDefinitions.map((instanceDefition) => {
          return { [`${instanceDefition.InstanceId}`]: instanceDefition.InstanceDataNews };
        })
      );
      const payload = { instances: data };
      const annotations = Object.assign(
        {},
        ...this.instanceDefinitions.map((instanceDefition) => {
          return {
            [`${instanceDefition.InstanceId}`]: instanceDefition.InstanceDocumentAnnotation,
          };
        })
      );

      const isValid = Object.keys(payload.instances)
        .map(instanceKey => payload.instances[instanceKey]?.taxonomy_data)
        .map(taxonomyDataObject => Object.keys(taxonomyDataObject  ?? {}))
        .every(instanceFields => instanceFields?.length ?? 0 > 0)

      if (!isValid) {
        console.warn("invalid taxonomy, can't save.")
        console.warn("payload")
        console.warn(payload)
        console.warn("annotations")
        console.warn(annotations)
        return
      }

      DocProcessService.filterInstancesBySubmissionCheckbox(annotations, this.instanceSubmissionCheckboxStatus)
      DocProcessService.filterInstancesBySubmissionCheckbox(payload.instances, this.instanceSubmissionCheckboxStatus)

      this.docProcessService.getData({ body: annotations, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateJsonAnnotations, HTTPMethod.Post);
      this.docProcessService.getData({ body: payload, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateInstanceData, HTTPMethod.Post);
      this.updatingInstanceData = true;
    }
    else if (this.selectedProjectService.isProjectTypeKiidLike()) {
      const data = Object.assign(
        {},
        ...cloneDeep(this.instanceDefinitions).map((instanceDefition) => {
          if (this.selectedProjectService.project === DocProcessProjectName.Factsheet) {
            console.log(instanceDefition)
            for (let field of Object.entries(instanceDefition.InstanceDataKiid)) {
              let rowArray = instanceDefition.InstanceDataKiid?.[field[0]]?.value
              if (rowArray.constructor === Array) {
                instanceDefition.InstanceDataKiid[field[0]].value = rowArray.filter( (row) => {
                  if (FactsheetService.isRowEmpty(row))
                    return false
                  return true
                })
              }
            }
          }
          return { [`${instanceDefition.InstanceId}`]: instanceDefition.InstanceDataKiid };
        })
      );

      const payload = { instances: data };
      DocProcessService.filterInstancesBySubmissionCheckbox(payload.instances, this.instanceSubmissionCheckboxStatus)
      this.docProcessService.getData({ body: payload, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateInstanceData, HTTPMethod.Post);
      this.updatingInstanceData = true;
    }
    else if (this.selectedProjectService.project === DocProcessProjectName.TopicClassification || this.selectedProjectService.project === DocProcessProjectName.Calendar) {
      const data = Object.assign(
        {},
        ...this.instanceDefinitions.map((instanceDefition) => {
          return { [`${instanceDefition.InstanceId}`]: instanceDefition.InstanceDataTopicClassification };
        })
      );

      const payload = { instances: data };
      DocProcessService.filterInstancesBySubmissionCheckbox(payload.instances, this.instanceSubmissionCheckboxStatus)
      this.docProcessService.getData({ body: payload, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateInstanceData, HTTPMethod.Post);
      this.updatingInstanceData = true;
    }
  }

  // Called only after view init.
  // Handles highlight and annotation bindings for news labeling project.
  private handleBindings() {
    let selectedTaxonomyDataContextPeriodSub = this.newsInstanceService.selectedTaxonomyDataContextPeriod$.subscribe((period) => {
      if (period) {
        const { InstanceDataNews } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
        this.refreshDocumentAnnotations(InstanceDataNews);
      }
    });
    this.subscriptions.push(selectedTaxonomyDataContextPeriodSub)

    let selectedNewsAnnotationSub = this.newsInstanceService.selectedNewsAnnotation$.subscribe((annot: NewsAnnotation) => {
      if (annot) {
        const { InstanceDocumentAnnotation, InstanceDataNews } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
        const fieldIndex = annot.fields.findIndex((field) => field.field === this.newsInstanceService.selectedTaxonomyDataField);
        if (fieldIndex < 0) {
          const newAnnot = annot;
          const index = InstanceDocumentAnnotation.annotations.findIndex((annot) => JSON.stringify(annot) === JSON.stringify(newAnnot));
          if (index > -1) {
            this.selectedAnnotationService.handleSelectedAnnotation(newAnnot, index, InstanceDocumentAnnotation, InstanceDataNews);
            this.newsInstanceService.handleNewsInstanceAnnotations(
              false,
              this.htmlViewer,
              this.instanceDefinitions,
              this.instanceId,
              InstanceDataNews.taxonomy_data,
              this.instanceData as InstanceDataUnioned
            );
            this.instanceData = InstanceDataNews;
          }
        }
      }
    });
    this.subscriptions.push(selectedNewsAnnotationSub)

    let deletedAnnotationSub = this.newsInstanceService.deletedAnnotation$.subscribe((deleted) => {
      if (deleted) {
        const { InstanceDataNews } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
        this.refreshDocumentAnnotations(InstanceDataNews);
        this.newsInstanceService.instanceDataNews = this.instanceData as InstanceDataUnioned;
      }
    });
    this.subscriptions.push(deletedAnnotationSub)

    let selectedTaxonomyQuantitySub = this.newsInstanceService.selectedTaxonomyQuantity$.subscribe((selectedQuantity) => {
      if (selectedQuantity) {
        const { InstanceDataNews, InstanceDocumentAnnotation } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
        this.selectedQuantityService.handleSelectedQuantity(InstanceDocumentAnnotation);
        this.refreshDocumentAnnotations(InstanceDataNews);
      }
    });
    this.subscriptions.push(selectedTaxonomyQuantitySub)

    let selectedTaxonomyFieldSub = this.newsInstanceService.selectedTaxonomyDataField$.subscribe((selectedField) => {
      if (selectedField) {
        this.selectedFieldService.handleSelectedField(selectedField, this.instanceDefinitions, this.instanceId, this.instanceData);
      }
    });
    this.subscriptions.push(selectedTaxonomyFieldSub)

    let selectedTaxonomySignSub = this.newsInstanceService.selectedTaxonomySign$.subscribe((selectedSign) => {
      if (selectedSign) {
        this.selectedSignService.handleSelectedSign(selectedSign, this.instanceDefinitions, this.instanceId, this.instanceData);
      }
    });
    this.subscriptions.push(selectedTaxonomySignSub)

    let doHighlightSub = this.docProccessCommonsService.doHighlight.subscribe((range) => {
      if (range) {
        this.newsInstanceService.onTextHighlight(
          range.startContainer,
          [range.startOffset, range.endOffset],
          this.htmlViewer,
          this.instanceDefinitions,
          this.instanceId,
          range,
          this.instanceData as InstanceDataUnioned
        );
      }
    })
    this.subscriptions.push(doHighlightSub)
  }

  private refreshDocumentAnnotations(instanceDataNews: InstanceDataNews) {
    if (instanceDataNews) {
      this.newsInstanceService.setSelectedNewsAnnotation(null);
      this.newsInstanceService.handleNewsInstanceAnnotations(
        false,
        this.htmlViewer,
        this.instanceDefinitions,
        this.instanceId,
        instanceDataNews.taxonomy_data,
        this.instanceData as InstanceDataUnioned
      );
      this.newsInstanceService.updateNewsFieldsValues(this.instanceData as InstanceDataUnioned);
    }
  }

  public handlePageMoving(ev: any) {
    if (!(ev === 'ArrowRight' || ev === 'ArrowLeft')) {
      return;
    }

    let arrowPressedFun;
    if (ev === 'ArrowRight') {
      arrowPressedFun = this.docProccessCommonsService.handleRightArrowPressed;
    } else if (ev === 'ArrowLeft') {
      arrowPressedFun = this.docProccessCommonsService.handleLeftArrowPressed;
    }

    const { pageIndex, coloredClasses } = arrowPressedFun(this.pageIndex, this.instanceDefinitions, this.fieldClasses);
    this.pageIndex = pageIndex;
    this.fieldClasses = coloredClasses;
    this.handleInstanceChange();
  }

  private getInstanceDataOfProjectType(instanceDataKiid: InstanceDataKiid, instanceDataNews: InstanceDataNews, instanceDataTopicClassification: InstanceDataTopicClassification): InstanceData {
    switch (this.selectedProjectService.project) {
      case DocProcessProjectName.Kiid:
      case DocProcessProjectName.KiidPriip:
      case DocProcessProjectName.Factsheet:
      case DocProcessProjectName.KiidMetadata:
        return instanceDataKiid;
      case DocProcessProjectName.PrNewsLabeling:
        return instanceDataNews;
      case DocProcessProjectName.Calendar:
      case DocProcessProjectName.TopicClassification:
        return instanceDataTopicClassification;
    }
  }

  onInstanceSelect(selectedInstance: Instance) {
    if (this.dataFieldValidityService.allowInstanceNavigation.getValue() === false) {
      this.dataFieldValidityService.displayInvalidReportDateError()
      return
    }
    console.log("onInstanceSelect called")
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.newsInstanceService.reset();
      this.newsInstanceService.setInstanceId(selectedInstance.instance_id);
    }
    this.scrollTop = this.instancesContainer.nativeElement.scrollTop;

    if (this.scrollTop > 0) {
      localStorage.setItem('scroll', this.scrollTop.toString());
    }

    const { Instance } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex));
    this.fieldClasses[this.pageIndex] = Instance.validated ? FieldClass.Validated : FieldClass.Previous;
    this.instanceId = selectedInstance.instance_id;
    this.pageIndex = Object.keys(this.taskInstance.instances).findIndex((instanceDataId) => selectedInstance.instance_id === parseInt(instanceDataId, 10));
    if (this.pageIndex < 0) {
      this.pageIndex = this.instanceDefinitions.findIndex((instance) => instance.InstanceId === selectedInstance.instance_id);
      const { Instance } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex));
      this.fieldClasses[this.pageIndex] = Instance && Instance.validated ? FieldClass.ValidatedPrevious : FieldClass.Current;
      this.handleInstanceChange();
    }
    this.instanceSubmissionCheckboxStatus[selectedInstance.instance_id] = true
    this.instanceNavigationTable.selection.select(selectedInstance.instance_id)
  }

  private handleInstanceChange() {
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.resetSelectedPeriod()
    }
    this.instanceId = this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex);
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.newsInstanceService.setInstanceId(this.instanceId);
    }
    const { InstanceDocument, InstanceDataKiid, InstanceDataNews, InstanceDocumentAnnotation, InstanceDataTopicClassification } = this.dataExtractorService.getInstanceDefinition(
      this.instanceDefinitions,
      this.instanceId
    );
    this.instanceData = this.getInstanceDataOfProjectType(InstanceDataKiid, InstanceDataNews, InstanceDataTopicClassification);
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.newsInstanceService.updateNewsFieldsValues(this.instanceData as InstanceDataUnioned);
      this.newsInstanceService.instanceDataNews = this.instanceData as InstanceDataUnioned;
    }
    this.documentString = InstanceDocument.documentString;
    this.documentSafeResourceUrl = InstanceDocument.documentSafeResourceUrl;
    this.documentSafeResourceUrl$ = InstanceDocument.documentSafeResourceUrl$;
    this.loadHtmlDocumentContents(InstanceDocument)
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.newsInstanceService.selectedNewsAnnotation = null;
    }
    if (InstanceDocumentAnnotation && InstanceDocumentAnnotation.annotations) {
      setTimeout(() => {
        if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
          this.newsInstanceService.handleNewsInstanceAnnotations(
            true,
            this.htmlViewer,
            this.instanceDefinitions,
            this.instanceId,
            InstanceDataNews.taxonomy_data,
            this.instanceData as InstanceDataUnioned
          );
        }
      }, 0);
    }
    this.annotations = InstanceDocumentAnnotation;
  }

  lowerZoom() {
    this.utilService.showVal(this.utilService.zoomScale - 1);
    this.zoomService.zoomMagnification.next((this.utilService.zoomScale-1) * 10)
  }

  increaseZoom() {
    this.utilService.showVal(this.utilService.zoomScale + 1);
    this.zoomService.zoomMagnification.next((this.utilService.zoomScale+1) * 10)
  }

  resetZoom() {
    this.utilService.showVal(10);
  }

  zoomChange($event) {
    const valueFrom1to20 = $event.target?.value
    this.utilService.showVal(valueFrom1to20)
    this.zoomService.zoomMagnification.next(valueFrom1to20 * 10)
  }

  onConfirmButtonClick(): void {
    if (this.dataFieldValidityService.allowInstanceNavigation.getValue() === false) {
      this.dataFieldValidityService.displayInvalidReportDateError()
      return
    }
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      const isTaxonomySanityCheckValid = this.taxonomySanityCheck();
      if (!isTaxonomySanityCheckValid) {
        this.openSaveConfirmationModal();
      } else {
        this.saveDataToBackend();
      }
    } else {
      this.saveDataToBackend();
    }
  }

  private taxonomySanityCheck(): boolean {
    let isSane = true;
    Object.keys(this.instanceData.taxonomy_data)
      .map((key) => this.instanceData.taxonomy_data[key])
      .forEach((field) => {
        field.values.forEach((period) => {
          if (!this.newsSanityCheckService.checkSanity(period.values)) {
            isSane = false;
            return isSane;
          }
        });
      });
    return isSane;
  }

  private openSaveConfirmationModal() {
    this.modalService.open(this.saveConfirmationModal, {}).result.then(
      (result) => {
        this.saveDataToBackend();
      },
      (reason) => {
        return;
      }
    );
  }

  private onKiidCtrlSpaceHotkey() {
    const { Instance, InstanceDataKiid } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
    Instance.validated = !Instance.validated;
    if (InstanceDataKiid) {
      Object.keys(InstanceDataKiid).forEach((key) => {
        const currentField = this.currentFields.find((field) => field === CurrentFields[key]);
        if (currentField > -1 && !Array.isArray(InstanceDataKiid[key])) {
          InstanceDataKiid[key].confirmed = Instance.validated ? 'Checked' : false;
        }
      });
      InstanceDataKiid.ShareClasses?.map((shareClass) => {
        Object.keys(shareClass).forEach((key) => {
          const currentField = this.currentFields.find((field) => field === CurrentFields[key]);
          if (currentField > -1) {
            shareClass[key].confirmed = Instance.validated ? 'Checked' : false;
          }
        });
      });
    }

    this.fieldClasses[this.pageIndex] = !Instance.validated ? FieldClass.Previous : FieldClass.Validated;
    if (this.pageIndex < this.instanceDefinitions.length - 1) {
      this.pageIndex++;
    }
    const instanceId = this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex);
    const { Instance: NewInstance } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, instanceId);
    this.fieldClasses[this.pageIndex] = NewInstance && NewInstance.validated ? FieldClass.ValidatedPrevious : FieldClass.Current;
    this.handleInstanceChange();
  }

  private onDeleteHotkey() {
    if (this.newsInstanceService.selectedNewsAnnotation) {
      this.deleteAnnotationService.handleDeletedAnnotation(this.instanceDefinitions, this.instanceId, this.instanceData);
    }
  }

  onClearAllAnnotationsClick() {
    this.clearDataConfirmationModal.show();
  }

  public clearAllAnnotations(): void {
    let instanceDefinition = this.instanceDefinitions.find((instanceDefition) => this.instanceId === instanceDefition.InstanceId);

    if (!instanceDefinition)
      return;

    this.utilService.ToStringArray(instanceDefinition.InstanceDataNews.taxonomy_data).forEach((field) => {
      field.values.forEach((period) => {
        period.values = [];
      });
    });

    instanceDefinition.InstanceDocumentAnnotation.annotations = [];

    this.saveDataToBackend();
    setTimeout(() => {
      this.docProccessCommonsService.restoreData.next(true);
    }, 1000);
  }

  private onCtrlSpaceHotkeyForTopicClassification() {
    this.fieldClasses[this.pageIndex] = FieldClass.Previous;

    const newInstanceOrderIndex = this.utilService.modulo(this.pageIndex + 1, this.instanceDefinitions.length);
    this.pageIndex = newInstanceOrderIndex;
    this.handleInstanceChangeForTopicClassification();
  }

  private handleInstanceChangeForTopicClassification() {
    this.instanceId = this.dataExtractorService.getInstanceId(this.instanceDefinitions, this.pageIndex);
    this.fieldClasses[this.pageIndex] = FieldClass.Current;
    this.instanceData = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDataTopicClassification;
    this.documentString = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentString;
    this.documentSafeResourceUrl = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentSafeResourceUrl;
    this.documentSafeResourceUrl$ = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument.documentSafeResourceUrl$;
    this.loadHtmlDocumentContents(this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId).InstanceDocument)
  }

  private onGetJsonAnnotations(jsonAnnotations: any) {
    for (let instance of this.instanceDefinitions) {
      const annotations = jsonAnnotations.find((annotation) => annotation.instance_id === instance.InstanceDocument.instance_id);
      if (annotations) {
        instance.InstanceDocumentAnnotation = annotations;
      }
    }
    if (this.selectedProjectService.project === DocProcessProjectName.PrNewsLabeling) {
      this.attachTextHighligherHook();
      const { InstanceDocumentAnnotation, InstanceDataNews } = this.dataExtractorService.getInstanceDefinition(this.instanceDefinitions, this.instanceId);
      if (InstanceDocumentAnnotation?.annotations) {
        // TODO understand and refactor
        this.newsInstanceService.handleNewsInstanceAnnotations(
          false,
          this.htmlViewer,
          this.instanceDefinitions,
          this.instanceId,
          InstanceDataNews.taxonomy_data,
          this.instanceData as InstanceDataUnioned
        );
      }
      this.annotations = InstanceDocumentAnnotation;
    }
  }

  private attachTextHighligherHook() {
    this.newsInstanceService.textHighlighter = new TextHighlighter(document.getElementById('sandbox'), {
      onBeforeHighlight: (range: Range) => {
        this.docProccessCommonsService.doHighlight.next(range);
      },
      //onRemoveHighlight: (annot: any) => {
      // console.log(annot)
      // TODO use this callback instead of current heavy delete logic
      //}
    });
  }

  private resetSelectedPeriod() {
    this.newsInstancesHandlerReference && this.newsInstancesHandlerReference.resetSelectedPeriodToDefault();
  }

  private onNewsLabelingCtrlSpaceHotkey() {
    this.fieldClasses[this.pageIndex] = FieldClass.Previous
    if (this.pageIndex < this.instanceDefinitions.length - 1) {
      this.pageIndex++;
    }
    this.fieldClasses[this.pageIndex] = FieldClass.Current

    this.handleInstanceChange();
  }

  onSelectAllInstances($event: any) {
    if ($event.length >= 1){
      $event.forEach( instanceId => {
        this.instanceSubmissionCheckboxStatus[instanceId] = true
      })
    }
    else {
      for (let checkboxStatus of Object.keys(this.instanceSubmissionCheckboxStatus)) {
        this.instanceSubmissionCheckboxStatus[checkboxStatus] = false
      }
    }
  }

  onSwitchInstance($event: any) {
    this.instanceSubmissionCheckboxStatus[$event.instance_id] = !this.instanceSubmissionCheckboxStatus[$event.instance_id]
  }

  private initializeInstanceSubmissinCheckboxStatus(instances: Instance[]) {
    for (let instance of instances) {
      this.instanceSubmissionCheckboxStatus[instance.instance_id] = false
    }
  }

  loadHtmlDocumentContents(InstanceDocument) {

    setTimeout(() => {
      if(this.htmlViewer) {
      this.htmlViewer.nativeElement.innerHTML = InstanceDocument.documentString as string;
      }
    }, 0)
  }
}
