<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-md-12 col-lg-6">
            <con-assignment-list type="user"></con-assignment-list>
        </div>
        <div class="col-md-12 col-lg-6">
            <con-assignment-list type="team"></con-assignment-list>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-6">
            <con-create-task></con-create-task>
        </div>
        <div class="col-md-12 col-lg-6">
            <con-assignment-list type="user_owns"></con-assignment-list>
        </div>
    </div>
</div>
