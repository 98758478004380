import { Injectable } from '@angular/core';
import { snakeCase } from 'change-case';
const TASK_NAME_TO_LINK: any = [
    {
        prefix: 'unmatched_instruments',
        generate: (internal_name: string) => {
            return ['/match/unmatched', {}];
        }
    },
    {
        prefix: 'inconsistent_instruments',
        generate: (internal_name: string) => {
            return ['/match/inconsistent', {}];
        }
    },
    {
        prefix: 'static_data_revision',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/static/' + id[1],  { view: 'revision' }];
        }
    },
    {
        prefix: 'static_data_missing',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/static/' + id[1],  { view: 'missing' }];
        }
    },
    {
        prefix: 'company_calendar_event_missing',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/company/' + id[1]];
        }
    },
    {
        prefix: 'company_calendar_event_confirm',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/company/' + id[1]];
        }
    },
    {
        prefix: 'company_calendar_event_historical_confirm',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/company/' + id[1]];
        }
    },
    {
        prefix: 'institution_calendar_event_missing',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/institution/' + id[1]];
        }
    },
    {
        prefix: 'institution_calendar_event_confirm',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/institution/' + id[1]];
        }
    },
    {
        prefix: 'institution_calendar_event_historical_confirm',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/calendar/institution/' + id[1]];
        }
    },
    {
        prefix: 'missing_companies_in_company_tier',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/company_lists/tier/' + id[1]];
        }
    },
    {
        prefix: 'additional_companies_in_company_tier',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/company_lists/tier/' + id[1]];
        }
    },
    {
        prefix: 'split_shares',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/entity/company/' + id[1]];
        }
    },
    {
        prefix: 'primary_missing',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/entity/company/' + id[1]];
        }
    },
    {
        prefix: 'multiple_codes_matched_to_company',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/entity/company/' + id[1]];
        }
    },
    {
        prefix: 'duplicate_primaries',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/entity/company/' + id[1]];
        }
    },
    {
        prefix: 'disabled_customer_webhook',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/powers/customers/' + id[1]];
        }
    },
    {
        prefix: 'customer_webhook_failed',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/powers/customers/' + id[1]];
        }
    },
    {
        prefix: 'shareholder_data_outdated',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/shareholders/' + id[1],  { view: 'outdated' }];
        }
    },
    {
        prefix: 'shareholder_data_missing',
        generate: (internal_name: string) => {
            const id = internal_name.split('-');
            return ['/shareholders/' + id[1],  { view: 'missing' }];
        }
    },
    {
      prefix: 'multiple_issuer_id_for_lei_of_company',
      generate: (internal_name: string) => {
        const id = internal_name.split('-');
        return ['/entity/company/' + id[1]];
      }
    },
    {
      prefix: 'duplicate_shareholders_in_company',
      generate: (internal_name: string) => {
        const id = internal_name.split('-');
        return ['/entity/company/' + id[1]];
      }
    },
    {
      prefix: 'duplicate_shareholder_owner',
      generate: (internal_name: string) => {
        const id = internal_name.split('-');
        return ['/entity/owner/' + id[1]];
      }
    }
]

@Injectable()
export class TaskLinkingService {

    public getLink(task: any) {
        let link = [];
        if(task.entity_type &&  task.entity_id) {
          // queryParams type check need link variable to be initialized as null
          link = [`/entity/${snakeCase(task.entity_type)}/` + task.entity_id]
        }
        TASK_NAME_TO_LINK.forEach(autoLink => {
            if (task.internal_name && task.internal_name.indexOf(autoLink.prefix) === 0) {
                link = autoLink.generate(task.internal_name);
            }
        });

        return link;
    }
}
