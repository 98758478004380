<div class="card">
  <div class="table-responsive">
  <table class="table">
    <thead class="thead-light">
        <th>Select</th>
        <ng-container *ngFor="let key of data?.keys">
          <th *ngIf="key !== 'doc_id'">
            {{getFormattedKeys(key) | titlecase}}
          </th>
        </ng-container>
    </thead>
    <tbody>
      <tr *ngFor="let object of data?.data">
        <td>
          <input type="radio" name="radioOption" (click)="setSelectedReportFile(object)">
        </td>
        <ng-container  *ngFor="let item of object | keyvalue">
          <td *ngIf="data?.keys.includes(item.key) && item.key !== 'doc_id'" >
            <a  *ngIf="item.key === 'doc_title'" [href]="getFileUrl(object)" target="_blank">{{item.value}} </a>
            <span *ngIf="item.key !== 'doc_title'">{{item.value}}</span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>

<div class="custom-modal-footer">
  <div class="custom-modal-footer-right">
    <button class="btn btn-primary m-10" (click)="emitSelection()" [disabled]="selectedReportFile === null">Select</button>
  </div>
</div>
</div>
</div>
