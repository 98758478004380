import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, EventType } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'app/auth/services/profile.service';
import { EntityConfirmModalComponent } from 'app/entities/components/views/entity-confirm-modal.component';
import { EntityName } from 'app/entities/models/EntityName';
import { EntityDescription, EntitiesService } from 'app/entities/services/entities.service';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { ApiSettings, CalendarEventSettings } from 'app/settings.class';
import { EntityAttachmentsService } from 'app/shared/services/entity-attachments.service';
import { prettyCase } from 'app/shared/utilities.classes';
import { TranslationService } from 'app/utility/services/translation.service';
import { snakeCase } from 'change-case';
import { ToastrService } from 'ngx-toastr';
import { pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { SubscriptionLike as ISubscription, Subject } from 'rxjs';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import { RecurringEventsModalComponent } from 'app/calendar/components/recurring-events-modal.component';
import { ConfirmationPopupService } from 'app/shared/services/confirmation-popup.service';
import { environment } from 'environments/environment';
import { CalendarService } from 'app/calendar/calendar.service';

@Component({
  selector: 'con-custom-entity-form-content',
  templateUrl: './custom-entity-form-content.component.html',
  styleUrls: ['./custom-entity-form-content.component.scss']
})
export class CustomEntityFormContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() initialSaveEnabled = false;
  @Input() entityName: string;
  @Input() entity: any;
  @Input() fixedValues: any = {};
  @Input() preFillValues: any = {}
  @Input() removeRelations = false;
  @Input() canStore: boolean;
  @Input() canEdit: boolean;
  @Input() canShow: boolean;
  @Input() canDelete: boolean;
  @Input() emptyAfterSave = false;
  @Input() except: any = [];
  @Input() only: any = [];
  @Input() hidden = false;
  @Input() showWhenChanged = true;
  @Input() toastMessage = '';
  @Input() fromCalendar = false;
  @Input() showEditButton = false;
  @Input() ownerType: string;
  @Input() setEntityFormContent: boolean = true;
  @Input() rows = [{
    id: null,
    showEdit: false
  }];
  @Input() newTab = false;
  @Input() parentCompany: any;
  @Input() reloadEditData: boolean;
  @Input() resetKey: string;
  @Input() fromDetailPage = false;
  @Input() fromReports = false;
  @Input() redirectButton = {
    show: false,
    label: '',
    link: ''
  };
  @Input() hasFixedCalendarValues: boolean = false
  @Input() combinedStatements = false;
  @Input() fromPressRelease = false;
  @Input() addCommentSection = false;
  @Input() handleFullDayEvent: boolean = false;
  @Input() direktProfileLanguage: any;
  @Input() enableRecurringEvent: boolean = false;
  @Input() relationValues: any = {};

  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() showComments: EventEmitter<any> = new EventEmitter<any>();
  @Output() addNewRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterOwnershipChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterDirectProfileChange: EventEmitter<any> = new EventEmitter<any>();

  public isSaving: boolean;
  private hasErrors: boolean;
  public fieldErrors: any = {};
  public generalErrors: any = [];
  public entityDescription: EntityDescription;
  public entityForm: UntypedFormGroup;
  public entityDescriptions: any = {};
  private oppositeRelations: any = {};
  public fields: any = [];
  public obj: any;
  public loaders: any = {};
  public loading: boolean;
  public resetForm = true;
  public _canStore: boolean;
  public _canEdit: boolean;
  public _canShow: boolean;
  public _canDelete: boolean;
  private profileReadySubscription: ISubscription;
  public forceDelSubscription: ISubscription;
  public forceDeleteSubscription: ISubscription;
  public EntityName = EntityName;
  public commentFormFields: any
  public commentEntity: any
  public autoTranslateMessage = CalendarEventSettings.CALENDAR_EVENT_AUTO_TRANSLATE_MESSAGE
  public occurrenceFromControl: FormControl | null = null;
  private currentEntityValues: any = null;
  public languageName: string
  public checkingPermission: boolean = false;

  private componentDestroyed$: Subject<any> = new Subject();

  constructor(private service: EntitiesService,
    private formBuilder: UntypedFormBuilder,
    private validatorConverter: ValidatorConversionService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private translationService: TranslationService,
    private attachmentService: EntityAttachmentsService,
    private dateConverterService: DateConverterService,
    private confirmationPopupService: ConfirmationPopupService,
    private calendarService: CalendarService
  ) { }

  isLoading() {
    return this.service.isLoading();
  }

  generatePermissions() {
    this._canShow = (this.canShow === undefined) ? this.profileService.can(this.profileService.getPermissionName(this.entityName, 'show')) : this.canShow;
    this._canStore = (this.canStore === undefined) ? this.profileService.can(this.profileService.getPermissionName(this.entityName, 'store')) : this.canStore;
    this._canEdit = (this.canEdit === undefined) ? this.profileService.can(this.profileService.getPermissionName(this.entityName, 'update')) : this.canEdit;
    this._canDelete = (this.canDelete === undefined) ? this.profileService.can(this.profileService.getPermissionName(this.entityName, 'delete')) : this.canDelete;
    if(this._canEdit === true && this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.entityAlreadyExists() && this.entity.owner_type === 'Company'){
      this.checkingPermission = true;
      this.calendarService.hasPermission('edit', this.entity?.owner?.company_tiers).pipe(take(1)).subscribe(hasPermission => {
        this._canEdit = hasPermission;
        this.checkingPermission = false;
      })
    }
  }

  ngOnInit() {
    this.profileReadySubscription = this.profileService.whenReady().subscribe(res => {
      if (res) {
        this.generatePermissions();
      }
    });
    this.router.events.subscribe(route => {
      this.setFormResetFlag(true);
    });

    this.forceDelSubscription = this.service.forceDelSub.subscribe(force => {
      const entity = this.setEntityFormContent ? this.service.getEntityForEntityFormContent() : { ...this.entity }
      if (
        (force?.entity?.company?.id && entity.id && force?.entity?.company?.id === entity.id && force.name === this.entityName) ||
        (force?.entity?.source?.id && entity.id && force?.entity?.source?.id === entity.id && force.name === this.entityName) ||
        (force?.entity?.id && entity.id && force?.entity?.id === entity.id && force.name === this.entityName)
      ) {
        this.isSaving = true;
        this.forceDeleteSubscription = this.service.forceDelete(force.name, force.entity.id).subscribe(res => {
          this.obj = null;
          this.hasErrors = false;
          this.isSaving = false
          this.fieldErrors = {};
          this.afterDelete.emit(res);
          this.toastr.success('Deleted successfully!', 'Force delete');
          if (this.emptyAfterSave) {
            this.empty();
          }
          if (this.fromDetailPage) {
            this.redirectToListing();
          }
        }, (error) => {
          this.isSaving = false;
          this.toaster.error('Something went wrong. Please contact the administrator.', 'Force delete');
        });
      }
    }, err => {
      if (err.hasOwnProperty('type')) {
        if (err.type === "LOCKED_ERROR" && !this.entity.locked) {
          this.toaster.warning('ML data is being processed', 'ML Error');
        }
      }
      this.isSaving = false;
    });
    this.service.companyReportFileEditingSubject.subscribe(entity => {
      this.reloadEditData = true;
    });
    this.attachmentService.reset();
  }

  ngOnDestroy() {
    this.profileReadySubscription.unsubscribe();
    this.forceDelSubscription.unsubscribe();
    if (this.forceDeleteSubscription) {
      this.forceDeleteSubscription.unsubscribe();
    }
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.modalService.dismissAll();
  }

  setFormResetFlag(value: boolean) {
    this.resetForm = value;
  }

  ngOnChanges() {
    this.loading = true;
    if (this.entity && (this.service.isEntityLocked(this.entity, this.entityName) || this.service.entityLockedByMl(this.entity))) {
      this.canEdit = false;
      this._canShow = true;
    }
    if (this.entity && this.service.isEntityCalculated(this.entity, this.entityName)) {
      this.canEdit = false;
      this._canShow = true;
    }
    if (this.entity && !(Object.keys(this.entity).length === 0 && this.entity.constructor === Object)) {
      this.service.setEntityForEntityFormContent(this.entity);
    }
    if (!this.entity) {
      this.obj = {};
    } else {
      this.obj = this.entity;
    }
    this.prepareForm()
    if(this.entityAlreadyExists()){
      this.currentEntityValues = JSON.parse(JSON.stringify(this.entity));
    }
  }

  prepareForm() {
    this.service.getEntityDescriptionByEntityName(this.entityName).pipe(take(1)).subscribe((entityDescription: EntityDescription) => {
      this.entityDescription = entityDescription;
      if (this.addCommentSection && this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY) {
        this.injectCommentFields()
      }
      if (this.handleFullDayEvent && this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY) {
        this.injectFullDayEventField()
      }
      if(this.enableRecurringEvent && this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && !this.entityAlreadyExists()){
        this.injectRecurringEventField()
      }
      if (this.resetForm || this.reloadEditData) {
        this.reloadEditData = false;
        this.createForm();
      }
      this.prepareRelations();
      this.loading = false;
      this.setFormResetFlag(false);
      if (this.hasFixedCalendarValues) {
        this.setCalendarEventFixedValues()
      }
    });
  }

  prepareRelations() {
    this.oppositeRelations = {};
    this.entityDescription.getBelongsToRelationsForForm().forEach(relation => {
      this.loaders[relation.name] = {};
      this.oppositeRelations[relation.name] = { required: false };
      this.service.getInverseRelations(relation)
        .subscribe(relations => {
          this.oppositeRelations[relation.name] = relation
        });
      if (relation.model && !this.entityDescriptions[relation.model]) {
        this.service.getEntityDescriptionByEntityName(relation.model)
          .subscribe(entityDescription => {
            this.entityDescriptions[relation.model] = entityDescription;
          });
      }
    });
  }

  objectHasKeys(obj: any) {
    return obj && Object.keys(obj).length > 0;
  }

  createForm(): void {
    const formStructure: any = {};
    this.hasErrors = false;
    this.fieldErrors = {};
    this.generalErrors = [];
    this.fields = [];
    this.getFields().forEach(field => {
      this.fields.push(field);
      if (this.fixedValues && !this.fixedValues[field.key]) {
        const structure = [];
        // Default value
        if (this.entityAlreadyExists() && (this.entity[field.key] || this.entity[field.key] === 0)) {
          structure.push({ value: this.entity[field.key], disabled: field.disabled });
        } else if (this.preFillValues && this.preFillValues[field.key]) {
          structure.push({ value: this.preFillValues[field.key], disabled: this.entityAlreadyExists() ? field.disabled : false });
        } else if (field.prefill && !this.entityAlreadyExists()) {
          structure.push({ value: this.service.getFieldDefaultValue(field), disabled: this.entityAlreadyExists() ? field.disabled : false });
        } else {
          structure.push({ value: '', disabled: this.entityAlreadyExists() ? field.disabled : false });
        }
        const validators = [];
        field.rules.forEach(rule => {
          const validator = this.validatorConverter.getValidatorByString(rule);
          if (validator) {
            validators.push(validator);
          }
        })
        structure.push(validators);
        formStructure[field.key] = structure;
      }
    });
    this.entityForm = this.formBuilder.group(formStructure);
    Object.keys(this.entityForm.controls).forEach(control => {
      this.entityForm.controls[control].valueChanges
        .pipe(startWith(this.entityForm.controls[control].value), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if ( this.handleFullDayEvent && (control === 'full_day_event' || (control === 'from_date' || control === 'to_date'))){
            this.handleFullDayEventChange(control, prev, next)
          }
          if (this.enableRecurringEvent && control === 'recurring_event') {
            this.handleRecurringEventChange(next);
          }
          if (this.fieldErrors[control] && this.fieldErrors[control].length) {
            this.fieldErrors[control] = [];
          }
        });
    });
  }

  revert() {
    this.attachmentService.reset();
    this.setFormResetFlag(true);
    this.ngOnChanges();
    if(this.enableRecurringEvent && this.entityName == CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY){
      this.handleRecurringEventChange(null);
    }
  }

  entityAlreadyExists() {
    return this.obj && this.obj.id !== undefined;
  }

  getBelongsToRelations() {
    return this.entityDescription.getBelongsToRelationsForForm()
      .filter(r => this.shouldShowRelation(r));
  }

  shouldShowRelation(relation: any) {
    return (this.only.length ? this.only.indexOf(relation.name) > -1 : true)
      && this.except.indexOf(relation.name) === -1;
  }

  getFields() {
    let fields;
    if (this.entityAlreadyExists()) {
      fields = this.entityDescription.getFieldsForUpdateForm();
    } else {
      fields = this.entityDescription.getFieldsForStoreForm();
    }
    return fields.filter(field => {
      return this.only.length ? this.only.indexOf(field.key) > -1 : true
    })
      .filter(field => {
        return this.except.indexOf(field.key) === -1;
      });
  }

  delete() {
    if (this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.enableRecurringEvent && this.entityAlreadyExists() && this.entity.hasOwnProperty('recurrence_batch') && this.entity.recurrence_batch) {
      const modalData = {
        texts: {
          title: 'Delete recurring event',
          text: 'This event is part of a recurring event series. Do you want to delete other events as well?'
        },
        buttonLabels: {confirm: 'Select events to delete', cancel: 'Delete only this event'}
      }
      const modalRef = this.confirmationPopupService.showConfirmModal(modalData);
      modalRef.result.then((result) => {
        this.openRecurringEventModal(this.entity, false, 'delete');
      }, (reason) => {
        if(reason && reason === 'abort'){
          this.deleteConfirmed();
        }
      });
    } else {
      const modalRef = this.modalService.open(EntityConfirmModalComponent, { size: 'md' });
      const data = {
        title: prettyCase(this.entityName),
        message: 'Are you sure you want to delete ?',
        alert: false
      }
      modalRef.componentInstance.modalData = data;
      modalRef.result.then((result) => {
        this.deleteConfirmed();
      }, (reason) => {
      });
    }
  }

  deleteConfirmed() {
    this.isSaving = true;
    this.service.deleteEntity(this.entityDescription.name, this.obj)
      .subscribe(
        res => {
          this.obj = null;
          this.isSaving = false;
          this.hasErrors = false;
          this.fieldErrors = {};
          this.afterDelete.emit(res);
          this.toastr.success('Deleted successfully!', prettyCase(this.entityName));
          if (this.emptyAfterSave) {
            this.empty();
          }
          if (this.fromDetailPage) {
            this.redirectToListing();
          }
        },
        error => {
          if (error.hasOwnProperty('type')) {
            if (error.type === "LOCKED_ERROR" && !this.entity.locked) {
              this.toaster.warning('ML data is being processed', 'ML Error');
              this.hasErrors = true;
              this.isSaving = false;
              return;
            }
          }
          if (error.data && error.data.message) {
            this.toastr.warning(error.data.message, 'Delete');
          } else if (!error.hasOwnProperty('type')) {
            this.toaster.error('Something went wrong. Please contact the administrator.', `Delete ${prettyCase(this.entityName)}`);
          }
          this.hasErrors = true;
          this.isSaving = false;
        });
  }

  fieldIsRequired(key) {
    let ret = false;
    this.getFields().forEach(f => {
      if (f.key === key) {
        ret = f.rules.indexOf('required') > -1;
      }
    });
    return ret;
  }

  onSubmit(force: boolean = false, edit: boolean = false) {
    this.generalErrors = [];
    this.isSaving = true;
    const entity: any = {};
    if (this.entityAlreadyExists()) {
      entity['id'] = this.obj.id;
      if (!force) {
        entity['updated_at'] = this.obj.updated_at;
      }
    }
    for (const key in this.entityForm.controls) {
      if (this.entityForm.controls.hasOwnProperty(key)) {
        const control = this.entityForm.controls[key];
        if (control.dirty || key === 'owner_type') {
          if (this.entity) {
            // Check if value is dirty
            if (control.value !== this.entity[key]) {
              entity[key] = control.value;

              // Check for a new empty value
            } else if (control.value === '' && this.entity[key] !== '') {
              entity[key] = control.value;
            }
          } else {
            if (control.value !== '') {
              entity[key] = control.value;
            }
          }
        }
        // Field is required, include it if we do not exists
        if (!this.entityAlreadyExists()
          && this.fieldIsRequired(key)) {
          entity[key] = control.value;
        } else if (this.preFillValues?.hasOwnProperty(key)) {
          entity[key] = control.value;
        } else {
          const fieldDescription = this.fields.find(field => field.key === key);
          if(fieldDescription && !this.entityAlreadyExists() && fieldDescription.hasOwnProperty('prefill') && fieldDescription.prefill !== null && !entity[key]){
            entity[key] = control.value;
          }
        }
      }
    }
    Object.keys(this.fixedValues).forEach(field => {
      entity[field] = this.fixedValues[field];
    });
    if (this.entityDescription.name === 'Owner') {
      if (entity.capital !== null && entity.capital !== undefined && entity.capital !== '') {
        if (entity.capital.toString().length) {
          entity.capital = (entity.capital / 100).toFixed(4);
        }
      }
      if (entity.votes !== null && entity.votes !== undefined && entity.votes !== '') {
        if (entity.votes.toString().length) {
          entity.votes = (entity.votes / 100).toFixed(4);
        }
      }
    }
    if (this.entity
      && this.entity.id
      && this.entityForm.controls.hasOwnProperty('locked')
      && this.entityForm.controls.locked.value !== this.entity.locked) {
      const modalRef = this.modalService.open(EntityConfirmModalComponent,
        {
          size: 'md'
        });
      const lock = entity.locked ? 'lock' : 'unlock';
      const data = {
        title: 'Lock',
        message: 'Are you sure you want to ' + lock + ' this report ?',
        alert: false
      }
      modalRef.componentInstance.modalData = data;
      modalRef.result.then((result) => {
        this.saveEntity(entity, edit);
      }, (reason) => {
        this.entityForm.controls['locked'].setValue(!entity.locked);
        this.isSaving = false;
        this.entityForm.controls.locked.markAsPristine();
      });
    } else if (this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && !this.entityAlreadyExists() && entity.owner_type === 'Company' && this.relationValues.owner) {
      this.checkEventWritePermission(entity, edit);
    } else if(this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.enableRecurringEvent && entity.hasOwnProperty('recurring_event') && entity.recurring_event !== ''){
      this.validateEntity(entity, edit, 'create');
    } else if(this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.enableRecurringEvent && this.entityAlreadyExists() && this.entity.hasOwnProperty('recurrence_batch') && this.entity.recurrence_batch){
      this.validateEntity(entity, edit, 'update');
    }else {
      this.saveEntity(entity, edit)
    }
  }

  private checkEventWritePermission(entity: any, edit: any) {
    this.calendarService.hasPermission('write', this.relationValues?.owner?.company_tiers).pipe(take(1)).subscribe(hasPermission => {
      if(hasPermission){
        if(this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY && this.enableRecurringEvent && entity.hasOwnProperty('recurring_event') && entity.recurring_event !== ''){
          this.validateEntity(entity, edit, 'create');
        }else {
          this.saveEntity(entity, edit)
        }
      } else {
        this.toaster.error(CalendarEventSettings.CALENDAR_EVENT_CREATE_PERMISSION_ERROR, 'Create calendar event');
        this.isSaving = false;
      }
    })
  }

  saveEntity(entity, edit) {
    if (entity.id === -1) {
      entity.id = undefined;
    }

    this.service.saveEntity(this.entityDescription.name, entity)
      .subscribe(
        res => {
          const promise = Promise.resolve(res);

          if (this.removeRelations) {
            this.entityDescription.getAllRelations().forEach(relation => {
              if (res[relation.name]) { delete res[relation.name]; }
            });
          }
          promise.then(() => {
            if (this.entityAlreadyExists()) {
              this.toastr.success(
                this.toastMessage ? this.toastMessage : 'Updated successfully!', prettyCase(this.entityName)
              );
            } else {
              if (this.fromCalendar) {
                this.service.calendarEditingSubject.next(this.entityName);
              }
              this.toastr.success(
                this.toastMessage ? this.toastMessage : 'Saved successfully!', prettyCase(this.combinedStatements ? 'Statements' : this.entityName)
              );
            }
            this.obj = res;
            this.isSaving = false;
            this.hasErrors = false;
            this.isDescriptionEntity(this.entityName, this.obj);
            this.afterSave.emit(this.obj);
            this.showComments.emit(edit);
            if (this.emptyAfterSave) {
              this.empty();
            }
          });
          this.entityForm.markAsPristine();
        },
        err => {
          if (err.isValueError()) {
            this.handleError(err);
          } else if (err.isLockedError()) {
            this.toastr.warning(err.getData().message, prettyCase(this.entityName));
          } else {
            this.toaster.error('Something went wrong. Please contact the administrator.', `Save ${prettyCase(this.entityName)}`);
            this.isSaving = false;
          }
        }
      )
  }

  handleError(err: any) {
    this.isSaving = false;
    this.fieldErrors = err.getData().fields;
    this.generalErrors = err.getData().general;
    this.hasErrors = true;
  }

  isDescriptionEntity(entityName: string, obj: any) {
    if (entityName === 'Description') {
      this.translationService.onDescriptionSaved(obj)
    }
  }

  pushToRelation(relation: any, obj: any) {
    if (!this.entity[snakeCase(relation.name)]) {
      this.entity[snakeCase(relation.name)] = [];
    }
    this.entity[snakeCase(relation.name)].push(obj);
  }

  spliceFromRelation(relation: any, obj: any) {
    const indx = this.entity[snakeCase(relation.name)].findIndex(o => o.id === obj.id);
    this.entity[snakeCase(relation.name)].splice(indx, 1);
  }

  resetErrors(key: string, value: string) {
    if (value) { this.fieldErrors[key] = []; }
  }

  resetErrorsForRelation(relation: any, value: any) {
    this.resetErrors(relation.foreign_key, value.model);
    if (value.type) {
      this.resetErrors(relation.foreign_type, value.type);
    }
    this.entityForm.get(relation.foreign_key).markAsDirty();
    if(this.addCommentSection && value?.type == 'DirektProfile'){
      this.setLangBasedOnProfile(value.model);
    } 
    if(this.hasFixedCalendarValues && value?.type == 'DirektProfile'){
      this.afterDirectProfileChange.emit(value.model);
    }
    if(this.enableRecurringEvent && this.entityName == CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY){
      this.relationValues[relation.name] = value.model;
    }
  }

  empty() {
    this.obj = {};
    this.revert();
  }

  addRow() {
    this.rows.push({
      id: null,
      showEdit: false
    })
  }

  removeRow(index: number) {
    this.rows.splice(index, 1);
  }

  getFixedSearchParams(relation: any) {
    const obj = {};
    const oppositeRelation = this.getOppositeRelation(relation);
    if (relation.type === 'hasOne') {
      obj[relation.foreign_key] = 'f:null';
    }
    if (oppositeRelation) {
      if (relation.type === 'hasMany') {
        obj[oppositeRelation.name + ':'] = 'f:null'
      }
    }
    return obj;
  }

  getOppositeRelation(relation) {
    return this.oppositeRelations[relation.name];
  }

  redirectToListing() {
    const urlChunks = this.router.url.split('/');
    urlChunks.splice(urlChunks.length - 1, 1);
    const url = urlChunks.splice(1, urlChunks.length);
    this.router.navigate(url);
  }

  setCalendarEventFixedValues() {
    if (this.entity) {
      this.fixedValues = {
        date_confirmed: this.entity.hasOwnProperty('date_confirmed') ? this.entity.date_confirmed : this.entityDescription.getPrefillValueByFieldKey('date_confirmed') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_confirmed,
        date_approximation_id: this.entity.date_approximation_id || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_approximation_id,
        to_date: this.entity.to_date || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.to_date,
        source_type: this.entity.source_type || this.entityDescription.getPrefillValueByFieldKey('source_type') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.source_type,
        ...this.fixedValues
      }
    } else {
      this.fixedValues = {
        date_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_approximation_id,
        to_date: null,
        date_confirmed: this.entityDescription.getPrefillValueByFieldKey('date_confirmed') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.date_confirmed,
        source_type: this.entityDescription.getPrefillValueByFieldKey('source_type') || CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.source_type,
        ...this.fixedValues
      }
      this.preFillValues = {
        ...this.preFillValues,
        time_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.time_approximation_id,
      }
      this.obj = {
        ...this.obj,
        time_approximation_id: CalendarEventSettings.CALENDAR_EVENT_PREFILL_DATA.time_approximation_id,
        time_approximation: environment.time_approx_object,
      }
    }
    if(this.handleFullDayEvent)
      delete this.fixedValues.to_date
  }

  onEmptyRelation(relation: any) {
   if(this.addCommentSection && relation?.model == 'DirektProfile'){
     this.setLangBasedOnProfile();
   }
   if(this.hasFixedCalendarValues && relation?.model == 'DirektProfile'){
     this.afterDirectProfileChange.emit();
   }
  }

  injectCommentFields() {
    this.service.getEntityDescriptionByEntityName('CalendarEventComment').pipe(take(1)).subscribe(
      (commentEntityDescription: EntityDescription) => {
        const commentFields = commentEntityDescription.data.fields.filter(field => field.key === 'comment');
        commentFields[0].rules = commentFields[0].rules.filter(rule => rule !== 'required');
        const relations = commentEntityDescription.data.relations.filter(relation => ['language'].includes(relation.name));
        this.commentFormFields = {
          comment: commentFields[0],
          language: relations[0]
        }
        this.entityDescription = new EntityDescription({
          fields: this.entityDescription.data.fields.concat(commentFields),
          relations: relations.concat(this.entityDescription.data.relations),
          name: this.entityDescription.data.name,
          timestamps: this.entityDescription.data.timestamps,
        });
        this.commentEntity = {
          ...this.obj,
          language: CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG,
          language_id: CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG.id
        }
        this.preFillValues = {
          ...this.preFillValues,
          language_id: CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG.id
        }
      }
    );
  }

  injectFullDayEventField() {
    const toDateIndex = this.entityDescription.data.fields.findIndex(field => field.key === 'to_date');
    const fields = [...this.entityDescription.data.fields];
    fields.splice(toDateIndex, 0, CalendarEventSettings.CALENDAR_FULL_DAY_EVENT_FIELD);
    this.entityDescription = new EntityDescription({
      ...this.entityDescription.data,
      fields,
    });
  }

  handleFullDayEventChange(control: any, prev: any, next: any) {
    const isFullDayEventChecked = this.entityForm.controls['full_day_event'].value
    const fromDate = this.entityForm.controls['from_date'].value;
    const toDate = this.dateConverterService.fromEntityString(fromDate).endOf('day')
    if(control === 'full_day_event' && prev !== next && isFullDayEventChecked && fromDate){
      this.resetToDateValue(toDate)
    } else if(control === 'from_date' && prev?.substring(0, 10) !== next?.substring(0, 10) && next && isFullDayEventChecked){
      this.resetToDateValue(toDate)
    } else if(control === 'to_date' && next && this.dateConverterService.toEntityString(toDate) !== next  && isFullDayEventChecked){
      this.entityForm.controls['full_day_event'].setValue(false)
    }
  }

  resetToDateValue(toDate: any) {
    this.entityForm.controls['to_date'].setValue(this.dateConverterService.toEntityString(toDate), { emitEvent: false })
    this.entityForm.controls['to_date'].markAsDirty()
    this.entityForm.controls['to_date'].markAsTouched()
    this.service.resetDateInput.next('to_date')
  }

  setLangBasedOnProfile(profile?: any): void {
    this.fieldErrors['language_id'] = [];
    const hasComment = this.entityForm.get('comment').value
    if (profile && profile.language_id && profile.language) {
      this.languageName = profile?.language?.name || null
      if (hasComment) return;
      this.commentEntity = {
        ...this.obj,
        language: profile.language,
        language_id: profile.language_id
      }
      this.entityForm.get('language_id').setValue(profile.language_id);
    } else {
      if(profile)
        this.languageName = CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG.name || null
      else
        this.languageName = null
      if (hasComment) return;
      this.commentEntity = {
        ...this.obj,
        language: CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG,
        language_id: CalendarEventSettings.CALENDAR_EVENT_COMMENT_DEFAULT_LANG.id
      }
    }
  }

  openRecurringEventModal(entity: any, edit: boolean, mode: 'update' | 'create' | 'delete') {
    if (this.occurrenceFromControl && this.occurrenceFromControl.invalid)
      return;
    const modalRef = this.modalService.open(RecurringEventsModalComponent, { size: 'xl', centered: true, scrollable: true, backdrop: 'static', keyboard: false });
    const entityDetails = { ...entity };
    if(mode === 'create'){
      entityDetails.occurrences = this.occurrenceFromControl ? this.occurrenceFromControl.value : 1;
    }
    modalRef.componentInstance.entityDetails = entityDetails;
    modalRef.componentInstance.relationValues = this.relationValues;
    modalRef.componentInstance.entityDescription = this.entityDescription;
    modalRef.componentInstance.currentEntityValues = this.currentEntityValues;
    modalRef.componentInstance.mode = mode;
    modalRef.result.then((res) => { this.postRecurringEventSave(res, edit, mode) }, () => { });
  }

  postRecurringEventSave(entity: any, isEditing: boolean, mode: 'update' | 'create' | 'delete') {
    this.isSaving = false;
    this.hasErrors = false;
    this.showComments.emit(isEditing);
    if(mode === 'create' || mode === 'update'){
      this.obj = entity;
      this.afterSave.emit(this.obj);
    } else if (mode === 'delete') {
      this.afterDelete.emit(entity);
      if (this.fromDetailPage) {
        this.redirectToListing();
      }
    }
    if (this.emptyAfterSave) {
      this.empty();
    }
    this.entityForm.markAsPristine();
  }

  injectRecurringEventField() {
    const toDateIndex = this.entityDescription.data.fields.findIndex(field => field.key === 'to_date');
    const fields = [...this.entityDescription.data.fields];
    fields.splice(toDateIndex + 1, 0, CalendarEventSettings.CALENDAR_RECURRING_EVENT_FIELD);
    this.entityDescription = new EntityDescription({
      ...this.entityDescription.data,
      fields,
    });
  }

  handleRecurringEventChange(value: any) {
    this.occurrenceFromControl = value ? new FormControl(1, [
      Validators.required, 
      Validators.min(1), 
      Validators.max(CalendarEventSettings.CALENDAR_EVENT_MAX_RECURRING_COUNT[value])
    ]) : null;
  }

  validateEntity(entity: any, isEditing: boolean, mode: 'update' | 'create') {
    this.fieldErrors['language_id'] = [];
    this.service.validateEntity(this.entityDescription.name, entity, this.entityAlreadyExists ? this.currentEntityValues?.id : undefined).subscribe(
      () => {
        this.isSaving = false;
        if(this.isLanguageInvalid()){
          this.fieldErrors['language_id'] = [CalendarEventSettings.LANGUAGE_ID_REQUIRED_ERROR_MESSAGE]
          this.hasErrors = true;
          return
        }
        this.hasErrors = false;
        if (mode === 'create') {
          this.openRecurringEventModal(entity, isEditing, mode);
        } else {
          const modalData = {
            texts: {
              title: 'Edit recurring event',
              text: 'This event is part of a recurring event series. Do you want to edit the entire series?'
            },
            buttonLabels: {confirm: 'Edit all events', cancel: 'Edit only this event'}
          }
          const modalRef = this.confirmationPopupService.showConfirmModal(modalData);
          modalRef.result.then((result) => {
            this.openRecurringEventModal(entity, isEditing, mode);
          }, (reason) => {
            if (reason && reason === 'abort') {
              this.isSaving = true;
              this.saveEntity(entity, isEditing);
            }
          });
        }
      },
      (error: any) => {
        if (error.isValueError()) {
          if(this.isLanguageInvalid()){
            error.data.fields['language_id'] = error.data.fields['language_id'] && error.data.fields['language_id'].length 
              ? [...error.data.fields['language_id'], CalendarEventSettings.LANGUAGE_ID_REQUIRED_ERROR_MESSAGE] 
              : [CalendarEventSettings.LANGUAGE_ID_REQUIRED_ERROR_MESSAGE]
          }
          this.handleError(error);
        } else if (error.isLockedError()) {
          this.toastr.warning(error.getData().message, prettyCase(this.entityName));
        } else {
          this.toaster.error(ApiSettings.INTERNAL_SERVER_ERROR, `Validate ${prettyCase(this.entityName)}`);
          this.isSaving = false;
        }
      }
    )
  }

  private isLanguageInvalid(): boolean {
    return this.addCommentSection 
      && this.entityName === CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY 
      && this.entityForm.controls['comment'].value 
      && !this.entityForm.controls['language_id'].value
  }
}