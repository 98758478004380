import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { StaticSettings, ApiSettings } from '../settings.class';
import { CountService } from '../static/services/count.service';
import { ToastrService } from 'ngx-toastr';

@Component ({
    selector: 'con-customers-webhook',
    templateUrl: './customers-webhook.component.html'
})
export class CustomersWebhookComponent implements OnInit {
    public loading = true;
    public customers: any;
    public noDataMessage = 'No customers to display.';
    constructor(private http: HttpClient,
        private router: Router,
        private countService: CountService,
        private toastr: ToastrService) {}

    ngOnInit() {
        this.loadPage(1);
    }
    loadPage(pageNumber: number) {
        this.loading = true;
        this.http.get<any>(ApiSettings.BASE_URL + '/' + ApiSettings.WEBHOOK_CUSTOMERS_ENDPOINT,
                {
                    params: {
                        page: String(pageNumber)
                    }
                })
                 .subscribe(res => {
                     this.countService.setCount('customers', res.total);
                     this.customers = res;
                     this.loading = false;
                 }, err => {
                    if (err.status === ApiSettings.RESPONSE_CODES.PERMISSION_ERROR) {
                        this.router.navigate(['powers', 'auth'], { skipLocationChange: true });
                     }
                 });
    }

    removeFromList(customer: any) {
        if (!customer.webhook_active) {
            return;
        }
        const indx = this.customers.data.findIndex(c => c.id === customer.id);
        this.customers.data.splice(indx, 1);
        if (!this.customers.data.length) {
            this.ngOnInit();
        }
    }

    whenFixed(customer: any) {
        this.removeFromList(customer);
        this.countService.decrementCount('customers');
    }

    updateInList(customer: any) {
        const indx = this.customers.data.findIndex(c => c.id === customer.id);
        customer.positions = this.customers.data[indx].positions;
        customer.required_languages = this.customers.data[indx].required_languages;
        customer.descriptions = this.customers.data[indx].descriptions;
        this.customers.data[indx] = customer;
    }

}
