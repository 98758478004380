import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';

@Component({
    selector: 'con-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent {
    public email: string;
    public password: string;
    public hasErrors = false;
    public loading = false;

    constructor(private service: AuthService,
                private router: Router) {}

    onChange() {
        this.hasErrors = false;
    }

    login() {
        this.loading = true;
        this.hasErrors = false;
        this.service.login(this.email, this.password)
                    .subscribe(res => {
                        this.loading = false;
                    },
                    err => {
                        this.hasErrors = true;
                        this.loading = false;
                    });
    }
}
