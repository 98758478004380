import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiSettings, CalendarSettings } from '../../settings.class';

import { EntitiesService } from '../../entities/services/entities.service';

import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component({
  selector: 'con-unconfirmed-historical-events',
  templateUrl: './unconfirmed-historical-events.component.html'
})
export class UnconfirmedHistoricalEventsComponent implements OnInit {
  @Input()
  company: any;
  @Input() ownerType: string;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();

  public events: any;
  public noDataMessage = 'No events to display.';
  public page = 1;
  private page$: BehaviorSubject<number> = new BehaviorSubject<number>(
    this.page
  );
  public expansionDict: any;
  public loading: boolean;
  public confirming: any = {};
  public now: string;

  constructor(
    private service: EntitiesService,
    public profile: ProfileService,
    private http: HttpClient,
    private dateConverter: DateConverterService
  ) {}

  toggleEvent(eventId: number) {
    if (!(eventId in this.expansionDict)) {
      this.expansionDict[eventId] = false;
    }
    this.expansionDict[eventId] = !this.expansionDict[eventId];
  }

  getOwnerTypeUri(): string {
    const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
    return url;
  }

  confirmEvent(event: any) {
    this.confirming[event.id] = true;
    this.service
      .saveEntity('CalendarEvent', {
        id: event.id,
        date_confirmed: true,
        revised_at: this.now
      })
      .subscribe(
        ev => {
          this.removeFromList(ev.id);
          this.confirming[ev.id] = false;
        },
        err => {
          console.log(err);
          this.confirming[event.id] = false;
        }
      );
  }

  removeFromList(eventId: number) {
    const indx = this.events.data.findIndex(e => e.id === eventId);
    if (indx > -1) {
      this.events.data.splice(indx, 1);
      if (this.events.total === 1) {
        this.http
          .put(
            ApiSettings.BASE_URL +
              this.getOwnerTypeUri() +
              this.company.id +
              '/' +
              CalendarSettings.HISTORICAL_CONFIRM_FIXED_ENDPOINT,
            {}
          )
          .subscribe();
      } else {
        if (!this.events.data.length) {
          this.page$.next(1);
        }
      }
    }
    this.refreshList.emit();
  }
  refresh() {
    this.changePage(1);
  }

  ngOnInit() {
    this.now = this.dateConverter.toEntityString(moment());
    const relation = this.ownerType === 'Institution' ? 'Institution' : ' Company';

    this.page$
      .pipe(tap(page => {
        this.expansionDict = {};
        this.loading = true;
      }))
      .pipe(switchMap(page => {
        return this.service.searchRelation(
          relation,
          this.company.id,
          'events',
          {
            date_confirmed: 'f:false',
            date: 'f:leq:' + moment().format('yyyy-MM-DD')
          },
          page,
          'from_date',
          true,
          1
        );
      }))
      .subscribe(
        res => {
          this.events = res;
          this.loading = false;
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
  }
  public changePage(page: number) {
    this.page = page;
    this.page$.next(this.page);
  }
}
