import { Component, OnInit } from '@angular/core';
import { switchMap, tap, pluck } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { ProfileService } from '../../../auth/services/profile.service';

@Component({
    selector: 'con-new-report-file-relation',
    templateUrl: './new-report-file-relation.component.html'
})
export class NewReportFileRelationComponent implements OnInit {

    public entity: any;
    public entityDescription: EntityDescription;
    public entityName: string;
    public loading: boolean;
    private id: number;
    public relation: string;

    constructor(private service: EntitiesService,
        public profile: ProfileService,
        private route: ActivatedRoute,
        private router: Router,
        private companyService: CompanyReportsService) {

      this.route.firstChild.params.subscribe(
        (params: any) => {
          this.relation = params.relation;
        });
    }

    ngOnInit() {
        this.companyService.selectedReport.subscribe(entity => {
            this.entity.locked = entity.locked;
            this.companyService.setMLCurrentStatus(entity.ml_info);
          });
        this.route.params.pipe(
            tap((params: Params) => {
                this.loading = true;
                this.id = params.id;
            }),
            switchMap((params: Params) => this.service.getEntityById('company_report', params.id)))
            .subscribe(
                entity => {
                    this.entity = entity;
                    this.loading = false;
                    this.companyService.selectedReport.next(entity);
                },
                error => {
                    this.loading = false;
                    if (error.isPermissionError()) {
                        this.entity = {
                            id: this.id
                        };
                        this.loading = false;
                    } else if (error.isNotFoundError()) {
                        this.router.navigate(['entity', 'error'], { skipLocationChange: true });
                    }
                });
    }
}
