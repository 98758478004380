<div class="row">    
    <div class="col-lg-6 col-sm-12 p-0 text-center">
        <div class="col-lg-12 col-sm-12 d-flex">
            <button (click)="requestAction('getMozendaData')" class="btn btn-primary mr-2 my-3">Vew Mozenda data</button>     
            <select ng-model="steps" (change)="setTransformationStep($event.target.value)" class="mr-2 my-3">
                <option *ngFor="let step of steps" value={{step}}>{{step}}</option>
            </select>
            <button (click)="requestAction('getStepData')" class="btn btn-primary mr-2 my-3">Transformation</button>
            <button (click)="requestAction('dimCheck')" class="btn btn-primary mr-2 my-3">Dim check</button>
            <button (click)="requestAction('getMapColumns')" class="btn btn-primary mr-2 my-3">Test column mappings</button>
            <button (click)="requestAction('testConfig')" class="btn btn-primary mr-2 my-3">Test config full</button>
            <div class="check-container d-flex align-items-center mr-2 my-3 ml-auto">
                <con-loader *ngIf="checkLoading" class="loader"></con-loader>
                <con-icon-by-name class="loader" *ngIf="checkPassed !== undefined" [iconName]="checkPassed ? 'check' : 'times'"></con-icon-by-name>
            </div>
        </div>
        <button (click)="requestAction('getPlaceholderConfig')" class="btn btn-lg btn-block btn-success" *ngIf="!configPlaceholder">Get config <con-icon-by-name iconName="cloud-download"></con-icon-by-name></button>
        <con-editor *ngIf="configPlaceholder" (onChange)="onConfigChange($event)" [data]="configPlaceholder"></con-editor>
    </div>


    <div class="col-lg-6 col-md-12">
        <div class="col-lg-12 error-message">
            <button (click)="requestAction('addAgent')" class="btn btn-primary mr-2 my-3">Submit agent</button>
            <button (click)="requestAction('deleteAgent')" class="btn btn-primary mr-2 my-3">Remove agent</button>
        </div>
        <div class="card">
            <div class="card-header">Response</div>
            <div id="responseElm" #responseElm class="card-body px-0 py-0">
            </div>
        </div>
        <ngb-alert [type]="actionSucceeded ? 'success' : 'danger'" *ngIf="showFeedbackFromApiAlert" [dismissible]="false">
            {{feedbackFromApiResponse}}
        </ngb-alert>
        <div>{{errorMessage}}</div>
    </div>
</div>