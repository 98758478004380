import { Component } from '@angular/core';

@Component ({
    selector: 'con-powers-layout',
    templateUrl: './powers-layout.component.html'
})

export class PowersLayoutComponent {

}
