import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'app/auth/services/profile.service';
import { Router } from '@angular/router';
import { MarketcapSettings } from 'app/settings.class';

@Component({
  selector: 'con-marketcap-start',
  templateUrl: './marketcap-start.component.html'
})
export class MarketcapStartComponent implements OnInit {

  public tierId: number = MarketcapSettings.TIER_ID;

  constructor(
    private router: Router,
    public profile: ProfileService
    ) {}

  ngOnInit() {
  }
  public navigateToCompany(company: any) {
    this.router.navigate(['marketcap', company.id]);
}

}
