import {Component, Input, OnInit} from '@angular/core';
import {DynamicDataEntryComponentInterface} from "../models/dynamic-data-entry-component-interface";
import {Observable} from "rxjs";
import {TaskDataEntryState} from "../state-management/states";
import {map, take, tap} from "rxjs/operators";
import {InstanceService} from "../services/instance.service";
import {Store} from "@ngxs/store";
import {SetComponentData} from "../state-management/actions";

@Component({
  selector: 'con-dynamic-single-horizontal-selector',
  template: `
      <div class="d-flex w-100 justify-content-start flex-wrap mb-2">
          <button
                  *ngFor="let choice of component.enums; let i = index"
                  [class]="((value$ | async) === choice ? 'btn-primary' : 'btn-disabled') + ' btn-sm mr-1 mb-1 px-2 py-2 cursor-pointer'"
                  (click)="onOptionClick($event.target, choice)"
                  [value]="choice">
              {{  choice }}
          </button>
      </div>  `,
  styles: ['button { border: none; } .btn-disabled { background: white }']
})
export class DynamicSingleHorizontalSelectorComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<any>;

  constructor(
    public instanceService: InstanceService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
  }

  onOptionClick(target: any, choice: string | number) {
    let currentValue = this.store.selectSnapshot(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
    if (choice !== currentValue) {

      this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, choice))
    } else {
      this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, null))
    }
  }
}
