import { FetchTaskDataEntryState, FetchTaskInstances, SaveTask, SetComponentData, SetStatementAnnotations, SetTaskId } from './../actions';
import { TaskDataEntryState } from './../states';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Instance } from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { FundamentalsAnnotation, InstanceDocument } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { DataEntryTaxonomyDetails } from '../../models/data-entry-taxonomy-details';
import { DynamicDataEntryComponentInterface } from '../../models/dynamic-data-entry-component-interface';
import { Task } from '../../models/Task';

@Injectable() //Todo: remove? it all be done by selectors
export class TaskDataEntryService {

  constructor(private store: Store) { }

  // getTaskId$(): Observable<number> {
  //   return this.store.select(TaskDataEntryState.selectTaskId)
  // }
  //
  // getTaskId(): number {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectTaskId)
  // }
  //
  // getTaskInstanceByIndex$(instanceIndex: number): Observable<Instance> {
  //   return this.store.select(TaskDataEntryState.selectTaskInstanceByIndex(instanceIndex))
  // }
  //
  // getTaskInstanceByIndex(instanceIndex: number): Instance {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectTaskInstanceByIndex(instanceIndex))
  // }
  //
  // getTaskInstanceById$(instanceId: number): Observable<Instance> {
  //   return this.store.select(TaskDataEntryState.selectTaskInstanceById(instanceId))
  // }
  //
  // getTaskInstanceById(instanceId: number): Instance {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectTaskInstanceById(instanceId))
  // }
  //
  // getInstanceDocuments$(instanceId: number): Observable<InstanceDocument> {
  //   return this.store.select(TaskDataEntryState.selectInstanceDocuments(instanceId))
  // }
  //
  // getInstanceDocuments(instanceId: number): InstanceDocument {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectInstanceDocuments(instanceId))
  // }
  //
  // getInstanceDetails$(instanceId: number): Observable<DataEntryTaxonomyDetails> {
  //   return this.store.select(TaskDataEntryState.selectInstanceDetails(instanceId))
  // }
  //
  // getInstanceDetails(instanceId: number): DataEntryTaxonomyDetails {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectInstanceDetails(instanceId))
  // }
  //
  // getInstanceComponents$(instanceId: number): Observable<DynamicDataEntryComponentInterface> {
  //   return this.store.select(TaskDataEntryState.selectInstanceComponents(instanceId))
  // }
  //
  // getInstanceComponents(instanceId: number): DynamicDataEntryComponentInterface {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectInstanceComponents(instanceId))
  // }
  //
  // getInstanceJsonAnnotations$(instanceId: number, component: DynamicDataEntryComponentInterface): Observable<Array<FundamentalsAnnotation>> {
  //   return this.store.select(TaskDataEntryState.selectInstanceJsonAnnotations(instanceId, component))
  // }
  //
  // getInstanceJsonAnnotations(instanceId: number, component: DynamicDataEntryComponentInterface): Array<FundamentalsAnnotation> {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectInstanceJsonAnnotations(instanceId, component))
  // }
  //
  // getComponentData$<T>(instanceId: number, component: DynamicDataEntryComponentInterface): Observable<T> {
  //   return this.store.select(TaskDataEntryState.selectComponentData<T>(instanceId, component))
  // }
  //
  // getComponentData<T>(instanceId: number, component: DynamicDataEntryComponentInterface): T {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectComponentData<T>(instanceId, component))
  // }
  //
  // getTaskInstances$(): Observable<Task> {
  //   return this.store.select(TaskDataEntryState.selectTaskInstances)
  // }
  //
  // getTaskInstances(): Task {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectTaskInstances)
  // }
  //
  // isTaskDataLoaded$(): Observable<boolean> {
  //   return this.store.select(TaskDataEntryState.selectIsTaskDataLoaded)
  // }
  //
  // isTaskDataLoaded(): boolean {
  //   return this.store.selectSnapshot(TaskDataEntryState.selectIsTaskDataLoaded)
  // }
  //
  // setTaskId(taskId: number): void {
  //   const action = new SetTaskId(taskId)
  //
  //   this.store.dispatch(action)
  // }
  //
  // setStatementAnnotations(instanceId: number, component: DynamicDataEntryComponentInterface, statementAnnotations: FundamentalsAnnotation[]): void {
  //   const action = new SetStatementAnnotations(instanceId, component, statementAnnotations)
  //
  //   this.store.dispatch(action)
  // }
  //
  // setComponentData(instanceId: number, component: DynamicDataEntryComponentInterface, value: any): void {
  //   const action = new SetComponentData(instanceId, component, value)
  //
  //   this.store.dispatch(action)
  // }
  //
  // fetchTaskInstances(): void {
  //   const action = new FetchTaskInstances()
  //
  //   this.store.dispatch(action)
  // }
  //
  // fetchTaskDataEntryState(): void {
  //   const action = new FetchTaskDataEntryState()
  //
  //   this.store.dispatch(action)
  // }
  //
  // saveTask(instanceIdsToSubmit: Array<any>): void {
  //   const action = new SaveTask(instanceIdsToSubmit)
  //
  //   this.store.dispatch(action)
  // }



}
