export class IdentifierModel {
  public id: string;

  constructor(guid ?: string){
    if (!guid)
      this.id = this.getRandomId().toString() // UtilService.generateGuid();
    else
      this.id = guid;
  }

  public toString(): string {
    return this.id;
  }

  public static compare(a: IdentifierModel, b: IdentifierModel): number{
    if (a.id === b.id)
      return 0;
    if ( a.id < b.id )
      return -1;
    else
      return 1
  }

  public toInt(): number {
    return parseInt(this.id)
  }

  private getRandomId(): number {
    var maxInt = 2147483647;
    const hashOfGuid = Math.random()
    return Math.floor( hashOfGuid * maxInt);
  }
}
