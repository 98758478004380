
<div class="p-2">
  <!--  Language-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'Language'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="LANGUAGES"
      [fieldName]="'Language'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

  <!--  PublicationDate-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'PublicationDate'"
  >
    <con-date-picker-component
      [dateObject]="instanceData.PublicationDate"
      (onValidation)="dataFieldValidityService.allowInstanceNavigation.next($event)"
    ></con-date-picker-component>
  </con-instance-data-field-wrapper>

  <!--  DocumentType-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'DocumentType'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="DocumentTypes"
      [multipleChoiceDisplayValuesList]="documentTypesDisplayValuesArray"
      [fieldName]="'DocumentType'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

  <!--  ShareClasses-->
  <con-share-classes
    [instanceData]="instanceData"
    [fieldName]="'ShareClasses'"
    [currentFields]="currentFields"
  ></con-share-classes>
</div>
