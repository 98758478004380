import { Injectable } from '@angular/core';
import { InstanceDefinition, NewsLabelingAnnotation } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { DataExtractorService } from './data-extractor.service';
import { NewsInstancesService } from '../../doc-process-common/services/news-instances.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedQuantityService {

  handleSelectedQuantity(instanceDocumentAnnotation: NewsLabelingAnnotation) {
    if (instanceDocumentAnnotation) {
      const { annotations } = instanceDocumentAnnotation;
      const selectedAnnotationIndex = annotations.findIndex(annotation => JSON.stringify(annotation && annotation.spans) === JSON.stringify(this.newsInstanceService.selectedNewsAnnotation && this.newsInstanceService.selectedNewsAnnotation.spans));
      if (selectedAnnotationIndex > -1) {
        annotations[selectedAnnotationIndex] && annotations[selectedAnnotationIndex].fields.forEach(field => {
          if (field.field === this.newsInstanceService.selectedTaxonomyDataField.toString()) {
            field.context.quantity = this.newsInstanceService.selectedTaxonomyQuantity;
          }
        });
      }
    }
  }

  constructor(private newsInstanceService: NewsInstancesService, private dataExtractorService: DataExtractorService) { }
}
