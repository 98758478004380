import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { CompanyReportsService } from '../../../company-reports/services/company-reports.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'con-pre-consensus-snapshot',
  templateUrl: './pre-consensus-snapshot.component.html',
  styleUrls: ['./pre-consensus-snapshot.component.scss']
})
export class PreConsensusSnapshotComponent implements OnInit {
  company: any;
  showData = false;
  inputData =  {
    "target_price": {
      "id": 1,
      "consensus_id": 1,
      "mean": 1,
      "median": 1,
      "high": 1,
      "low": 1,
      "amount": 1,
      "created_at": "2023-10-25T18:24:29+00:00",
      "updated_at": "2023-10-25T18:24:29+00:00",
      "deleted_at": null,
      "created_by": 2,
      "updated_by": 2,
      "deleted_by": null,
      "consensus": {
        "id": 1,
        "survey_id": 1,
        "active": true,
        "consensus_type": "PRE",
        "quantity_id": null,
        "unit_id": null,
        "created_at": "2023-10-25T18:19:55+00:00",
        "updated_at": "2023-10-25T18:19:55+00:00",
        "deleted_at": null,
        "created_by": 2,
        "updated_by": 2,
        "deleted_by": null
      }
    },
    "outlook": {
      "id": 1,
      "consensus_id": 1,
      "buy": 1,
      "overweight": 1,
      "hold": 1,
      "underweight": 1,
      "sell": 1,
      "created_at": "2023-10-25T18:24:53+00:00",
      "updated_at": "2023-10-25T18:24:53+00:00",
      "deleted_at": null,
      "created_by": 2,
      "updated_by": 2,
      "deleted_by": null,
      "consensus": {
        "id": 1,
        "survey_id": 1,
        "active": true,
        "consensus_type": "PRE",
        "quantity_id": null,
        "unit_id": null,
        "created_at": "2023-10-25T18:19:55+00:00",
        "updated_at": "2023-10-25T18:19:55+00:00",
        "deleted_at": null,
        "created_by": 2,
        "updated_by": 2,
        "deleted_by": null
      }
    }
  }
  recommendationsForm: FormGroup;
  targetPriceForm: FormGroup;
  outlookForm: FormGroup;
  constructor(private http: HttpClient, private route: ActivatedRoute, private companyReportService: CompanyReportsService, private fb: FormBuilder) { }

  ngOnInit(): void {


    this.route.params.pipe(
      tap((params: Params) => {
        this.companyReportService.getCompanyData(params.companyId).subscribe((companyDetails) => {
          this.company = companyDetails;
        }) }))
      .subscribe((companySurveys: any) => {
          this.buildForm();
        },
        err => {
            console.error(err);
        });
  }

  buildForm() {
    // Create the two new form groups
    this.targetPriceForm = this.createTargetPriceForm(this.inputData.target_price);
    this.outlookForm = this.createOutlookForm(this.inputData.outlook);

    // Combine all forms into one parent form
    this.recommendationsForm = this.fb.group({
      targetPrice: this.targetPriceForm,
      outlook: this.outlookForm
    });
  }
  createTargetPriceForm(targetPriceData) {
    return this.fb.group({
      id:new FormControl(targetPriceData.id),
      mean: new FormControl(targetPriceData.mean, [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]),
      median: new FormControl(targetPriceData.median, [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]),
      high: new FormControl(targetPriceData.high, [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]),
      low: new FormControl(targetPriceData.low, [Validators.required, Validators.pattern(/^\d*\.?\d+$/)]),
      amount: new FormControl(targetPriceData.amount, [Validators.required, Validators.pattern(/^\d+$/)]),
    });
  }

  createOutlookForm(outlookData) {
    return this.fb.group({
      id:new FormControl(outlookData.id),
      buy: new FormControl(outlookData.buy, [Validators.required, Validators.pattern(/^\d+$/)]),
      overweight: new FormControl(outlookData.overweight, [Validators.required, Validators.pattern(/^\d+$/)]),
      hold: new FormControl(outlookData.hold, [Validators.required, Validators.pattern(/^\d+$/)]),
      underweight: new FormControl(outlookData.underweight, [Validators.required, Validators.pattern(/^\d+$/)]),
      sell: new FormControl(outlookData.sell, [Validators.required, Validators.pattern(/^\d+$/)]),
    });
  }
  onSubmit() {
  }
}

