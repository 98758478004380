import {
  Component,
} from '@angular/core';

@Component({
  template: `
    <div class="col-12">
      <h1 class="mt-3">Please choose a project</h1>
      <con-server-chooser></con-server-chooser>
    </div>`
})
export class DocProcessModuleHomeView {

}
