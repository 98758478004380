import { Injectable } from '@angular/core';
import {InstanceDocument} from '../../kpi-and-kiid/models/fields';
import {Observable} from 'rxjs';
import {map, mergeAll, shareReplay, take} from 'rxjs/operators';
import {Query} from '../../doc-process-common/services/methods.service';
import {ApiService} from '../../doc-process-common/services/api.service';
import {Task, taskInstancesToInstanceIds} from '../models/Task';
import {Instance} from '../../kpi-and-kiid/models/Typings';

export abstract class AbstractApiService {

  constructor(
    protected apiService: ApiService
  ) { }


  getInstanceDocumentsforInstances<T extends InstanceDocument>(instances$: Observable<Instance[]>, taskInstances: Observable<Task>): Observable<{[id: number]: T}> {
    let queryResponse: Observable<Array<T>> = instances$.pipe(
      map((instances: Instance[]) => {
        const res = this.apiService.httpGet<Array<T>>({params: this.instancesToDocIds(instances)}, Query.GetDocument)
        return res
      }),
      mergeAll(),
      shareReplay(),
    )

    return queryResponse.pipe(
      map( (instanceDocuments: Array<T>) => {

        // convert array to an object where id of each array element is a key to that element.
        let newRes = instanceDocuments.reduce((a: {[id: number]: T}, b: T) => {
          a[b.doc_id] = b
          return a
        }, {} as {[id: number]: T})

        return newRes
      })
    )
  }



  private instancesToDocIds(instances: Instance[]): string {
    let serialized: string = instances.reduce((a: string, b: Instance) => a + b.doc_id + ",", "")
    return serialized.substring(0, serialized.length - 1) // it removes comma at the end
  }




  public getInstanceDetailsBulk<T>(taskInstances: Observable<Task>): Observable<{[id: number]: T}> {
    const instanceDataResponse = taskInstances.pipe(
      map((taskInstances) => {
        const res = this.apiService.httpGet({params: taskInstancesToInstanceIds(taskInstances)}, Query.GetInstanceData)
        return res
      }),
      mergeAll(),
      shareReplay(),
    )

    const instanceDetails: Observable<{[id: number]: T}> = instanceDataResponse.pipe(
      map( (res: any) => {
        return res.instances
      })
    )

    return instanceDetails
  }
}
