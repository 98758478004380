import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import 'codemirror/mode/xml/xml';

@Component({
    selector: 'con-object-inspector',
    templateUrl: './object-inspector.component.html'
})

export class ObjectInspectorComponent implements OnInit, AfterViewInit {
  @Input() object: any;
  @Input() isReadOnly: boolean = false;
  @Input() inputDataType: 'json' | 'xml' | 'other' = 'json';
  @ViewChild(CodemirrorComponent) codemirrorComponent: CodemirrorComponent;
  codeMirrorOptions: any = {
    theme: 'blackboard',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers',
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };
  displayData = {}

  ngOnInit(): void {
    this.codeMirrorOptions.readOnly = this.isReadOnly;
    if(this.inputDataType === 'json' && this.object) {
      this.codeMirrorOptions.mode = { name: "javascript", json: true };
      this.displayData = this.getString()
    } else if(this.inputDataType === 'xml' && this.object) {
      this.codeMirrorOptions.mode = 'xml';
      this.displayData = this.formatXMLCode(this.object);
    } else {
      this.displayData = this.object;
    }
  }

  getKeys() {
    return this.object ? Object.keys(this.object) : [];
  }

  isObject(obj: any) {
    return obj !== null && typeof obj === 'object';
  }

  isNull(value) {
    return value === null;
  }

  getString() {
    return JSON.stringify(this.object, null, 4);
  }

  ngAfterViewInit(): void {
    if (this.inputDataType === 'json') {
      setTimeout(() => { this.foldKeysWithChildren(JSON.parse(this.codemirrorComponent.codeMirror.getValue()), this.codemirrorComponent.codeMirror, 1); }, 0);
    } else if (this.inputDataType === 'xml'){
      setTimeout(() => { this.indentXMLCode(); }, 0)
    }
  }

  foldKeysWithChildren(obj: any, cm: any, level: number) {
    if(!obj) {
      return;
    }
    const keys = Object.keys(obj);
    const nestedValues = [];
    keys.forEach((key, index) => {
      nestedValues.push(key);
    });
    for(let i = 0; i < nestedValues.length; i++) {
      const fold = this.findFirstLevelFoldsBraces(nestedValues[i], this.codemirrorComponent.codeMirror.getValue());
      cm.foldCode({ line: fold.startPos, ch: 0 }, null, 'fold');
    }
  }
  findFirstLevelFoldsBraces(tokenToCheck: string, jsonString: string) {
    let closeBraceLineNumber = 0;
    let startBraceLineNumber = 0;
    const tokens = jsonString.split('\n');
    let type = '';
    startBraceLineNumber = tokens.findIndex((item) => item.startsWith(`    "${tokenToCheck}"`));
    if(startBraceLineNumber !== -1) {
      if(tokens[startBraceLineNumber].endsWith('{')) {
        type = 'object';
      } else if(tokens[startBraceLineNumber + 1].endsWith('[')) {
        type = 'array';
      }
    }
    for(let i = startBraceLineNumber; i < tokens.length; i++) {
      if(tokens[i].startsWith('    }') && type === 'object') {
        closeBraceLineNumber = i+1;
        break;
      }
      if(tokens[i].startsWith('    ]') && type === 'array') {
        closeBraceLineNumber = i+1;
        break;
      }
    }
    return {startPos: startBraceLineNumber, endPos:closeBraceLineNumber}
  }

  indentXMLCode() {
    const cmInstance = this.codemirrorComponent.codeMirror;
    cmInstance.operation(() => {
      for (let i = 0; i < cmInstance.lineCount(); i++) {
        cmInstance.indentLine(i, 'smart');
      }
    });
  }

  formatXMLCode(xml: string): string {
    const formatRegex = /(>)(<)(\/*)/g;
    xml = xml.replace(formatRegex, '$1\r\n$2$3');
    return xml.split('\r\n').join('\r\n');
  }
}

