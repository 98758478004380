<con-entity-form *ngIf="!showEdit && fromCompanyDetail" [fixedValues]="fixedValueInput" [fromPressRelease]="true"  entityName="PressRelease" [emptyAfterSave]="true" (afterSave)="showEditForm($event)" ></con-entity-form>
<con-entity-form *ngIf="!showEdit && !fromCompanyDetail"  [fromPressRelease]="true"  entityName="PressRelease" [emptyAfterSave]="true" (afterSave)="showEditForm($event)" ></con-entity-form>

<div class="card mb-15" *ngIf="showEdit">
  <div class="card-header">
    <div class="d-flex justify-content-start align-items-center">
      <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
      Press Release
      <div class="card-header-toggle-individual ml-auto">
        <div class="chip chip-link mr-15">
          <i class="fa fa-pencil fa-fw"></i>
          Edit
        </div>
        <con-icon-by-name iconName="angle-right" class="mr-15" (click)='editFormExpanded = !editFormExpanded' [mutations]="getMutations()"></con-icon-by-name>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="editFormExpanded">
    <div>
      <con-entity-form-content  [entityName]="entityName" [canEdit]="true" [canDelete]="true" [entity]="addedPressRelease" [fromPressRelease]="true" (afterDelete)="resetView($event)"  (afterSave)="showEditForm($event)"></con-entity-form-content>
    </div>
    <div class="mt-15">
      <con-to-many-editor
        [canShow]="profile.can(profile.getPermissionName(entityName, 'show', pressReleaseCategoryRelation.name))"
        [canEdit]="profile.canAnd([profile.getPermissionName(entityName, 'update', pressReleaseCategoryRelation.name),
                                                                 profile.getPermissionName(pressReleaseCategoryRelation.model, 'index')])"
        [relation]="pressReleaseCategoryRelation"
        [entity]="addedPressRelease"
      >
      </con-to-many-editor>
      <button class="btn btn-success" (click)="closeEditView()">Close and Create New</button>
    </div>
  </div>
</div>


