import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvSettingsService } from './env-settings.service';
import { Query, getSubQuery } from './methods.service'
import { HTTPMethod, Headers, ResponseType } from '../models/api';
import {Observable} from 'rxjs';
import {UtilService} from './util.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private connectionString: string;

  constructor(
    private httpClient: HttpClient,
    private envSettingsService: EnvSettingsService
  ) {}

  sendHttpRequest(headers: Headers, method: Query, httpMethod: HTTPMethod, responseType: ResponseType = 'json'): Promise<any> {
    this.updateCompiledConnectionString()

    let requestUri = this.connectionString + 'method=' + method;

    let subQuery = getSubQuery(method)
    if (subQuery && headers.params) {
      requestUri += subQuery + headers.params
    }

    switch (httpMethod) {
      case HTTPMethod.Get : {
        return this.httpClient.get( requestUri, {params: headers.options, responseType: (responseType as any)}).toPromise()
      }
      case HTTPMethod.Post : {
        return this.httpClient.post(requestUri, headers.body, {responseType: responseType as any}).toPromise()
      }
    }
  }

  httpGet<T>(headers: Headers, method: Query, responseType: ResponseType = 'json'): Observable<T>{
    this.updateCompiledConnectionString()

    const res = this.httpClient.get<T>(
      this.connectionString + 'method=' + method + getSubQuery(method) + headers.params,
      {params: headers.options, responseType: (responseType as any)}
    )
    UtilService.logIfAdmin("httpGet res:")
    UtilService.logIfAdmin(res)
    return res
  }

  httpPost<T>(headers: Headers, method: Query, responseType: ResponseType = 'json'): Observable<T>{
    this.updateCompiledConnectionString()

    const res = this.httpClient.post<T>(
      this.connectionString + 'method=' + method + getSubQuery(method) + headers.params, headers.body,
      {params: headers.options, responseType: (responseType as any)}
    )
    return res
  }

  private updateCompiledConnectionString(): void {
    this.connectionString = this.envSettingsService.ML_BACKEND_URI()
  }
}
