import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component ({
    selector: 'con-customers-editor',
    templateUrl: './customers-editor.component.html'
})
export class CustomersEditorComponent implements OnChanges {
    loading = false;

    @Input() customer: any ;
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    ngOnChanges () {

    }

    showWebhookForm() {
        return this.customer.webhook_url != null;
    }

    webhookUpdated(customer: any) {
        this.customer.webhook_url = customer.webhook_url;
        this.customer.updated_at = customer.updated_at;
        this.customer.revised_at = customer.revised_at;
        this.afterUpdate.emit(customer);
        this.ngOnChanges();
    }
}
