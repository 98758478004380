import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { AuthModule } from './../auth/auth.module';
import { EntitiesModule } from './../entities/entities.module';

import { StaticComponent } from './layout/static.component';
import { StaticRevisionComponent } from './layout/static-revision.component';
import { StaticMissingComponent } from './layout/static-missing.component';
import { StaticStartComponent } from './layout/static-start.component';
import { StaticCompanyComponent } from './layout/static-company.component';

import { StaticEditorComponent } from './components/static-editor.component';
import { PositionEditorComponent } from './components/position-editor.component';
import { WebsiteEditorComponent } from './components/website-editor.component';
import { EmailEditorComponent } from './components/email-editor.component';
import { PhoneEditorComponent } from './components/phone-editor.component';
import { AddressEditorComponent } from './components/address-editor.component';
import { FoundedEditorComponent } from './components/founded-editor.component';
import { CountryEditorComponent } from './components/country-editor.component';
import { DescriptionEditorComponent } from './components/description-editor.component';

import { CountService } from './services/count.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StaticTabComponent } from './static-tab/static-tab.component';
import { UtilityModule } from 'app/utility/utility.module';
import { FilterStaticComponent } from './components/filter-static/filter-static.component';
import { SectorEditorComponent } from './components/sector-editor.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        NgbModule,
        AuthModule,
        EntitiesModule,
        UtilityModule
    ],
    providers: [
        CountService
    ],
    declarations: [
        StaticComponent,
        StaticRevisionComponent,
        StaticMissingComponent,
        StaticStartComponent,
        StaticCompanyComponent,
        StaticEditorComponent,
        PositionEditorComponent,
        WebsiteEditorComponent,
        EmailEditorComponent,
        PhoneEditorComponent,
        AddressEditorComponent,
        FoundedEditorComponent,
        CountryEditorComponent,
        DescriptionEditorComponent,
        StaticTabComponent,
        FilterStaticComponent,
        SectorEditorComponent
    ],
    exports: [ StaticCompanyComponent ]
})
export class StaticModule {}
