<!-- template -->
<div class="form-group">
  <label>
    <small>Comment</small>
  </label>
  <textarea [formControl]="commentControl" class="form-control" placeholder="Comment"></textarea>
</div>

<button type="button" class="btn btn-success" (click)="saveComment()" [disabled]="!saveEnabled">
  Save Comment
</button>
<button type="button" class="btn btn-warning" (click)="revertComment()" [disabled]="!saveEnabled">
  Revert
</button>
