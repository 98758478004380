import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from '../components/confirmation-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPopupService {

  constructor(private modalService: NgbModal) { }

  showConfirmModal(inputData: any) {
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { size: 'md' });
    modalRef.componentInstance.modalData = inputData?.texts;
    modalRef.componentInstance.buttonLabels = inputData?.buttonLabels;
    return modalRef
  }
}
