<div class="input-group" *ngIf="field.is_filterable">
    <div class="input-group-prepend">
        <span class="input-group-text">
            <con-icon-by-name iconName="search" [mutations]="['fw']"></con-icon-by-name>
        </span>
    </div>
    <input *ngIf="nothingSpecial()" type="search" class="form-control" [formControl]="control" placeholder="Search by {{ field.label }}">
    <select *ngIf="isBoolean()" class="form-control custom-select" [formControl]="control">
        <option *ngFor="let value of boolValues" [ngValue]="value.value">{{value.label}}</option>
    </select>
    <select *ngIf="isEnum()" class="form-control custom-select" [formControl]="control">
        <option [ngValue]="''">Any</option>
        <option *ngFor="let value of field.values" [ngValue]="value.value">{{ value.label }}</option>
    </select>
</div>
