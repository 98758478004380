export class TaxonomyModule {

  constructor(
    public children: Array<TaxonomyModule>,
    public figure_id: any,
    public level: boolean,
    public is_key_financial: boolean,
    public is_highlighted: boolean,
    public line_id: number,
    public module_id: number,
    public name: string,
    public parent_id: any,
    public position: number,
    public tree_name: string,
    public unique_id: string,
    public value_rep: number,
    public quantity?: any,
  ) {

  }

  public static compare(a, b): number{
    if (a.position === b.position)
      return 0;
    if ( a.position < b.position )
      return -1;
    else
      return 1
  }

  public static findModuleById(moduleTree: TaxonomyModule, uniqueId: string): TaxonomyModule | null {
    if (moduleTree.unique_id === uniqueId) {
      return moduleTree
    }
    else if (moduleTree?.children?.length >= 1) {
      for (let module of moduleTree?.children) {
        const moduleSearchResult = TaxonomyModule.findModuleById(module, uniqueId)
        if (moduleSearchResult) {
          return moduleSearchResult
        }
      }
    }
    return null
  }

  public static findModuleByPosition(moduleTree: TaxonomyModule, position: number): TaxonomyModule | null {
    if (moduleTree.position === position) {
      return moduleTree
    }
    else if (moduleTree?.children?.length >= 1) {
      for (let module of moduleTree?.children) {
        const moduleSearchResult = TaxonomyModule.findModuleByPosition(module, position)
        if (moduleSearchResult) {
          return moduleSearchResult
        }
      }
    }
    return null
  }
}
