import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EntitiesService } from 'app/entities/services/entities.service';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { MissingCompanyEventsSettings } from 'app/settings.class';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-filter-missing-company-events',
  templateUrl: './filter-missing-company-events.component.html',
  styleUrls: ['./filter-missing-company-events.component.scss']
})
export class FilterMissingCompanyEventsComponent implements OnInit, OnDestroy {

  @Output() filterEvents = new EventEmitter<any>();

  private componentDestroyed$ = new Subject();

  public isFilterExpanded = true;
  public dropdownFieldValues: any = {
    universe: null,
    tier: null
  }
  private defaultFromDate = MissingCompanyEventsSettings.MISSING_EVENTS_DEFAULT_FROM_DATE
  private defaultToDate = MissingCompanyEventsSettings.MISSING_EVENTS_DEFAULT_TO_DATE
  public eventTypes: any[] = [];
  public isLoading = false;

  public filterForm: FormGroup = new FormGroup({
    from_date: new FormControl(this.dateConverterService.toEntityString(this.defaultFromDate) , []),
    to_date: new FormControl(this.dateConverterService.toEntityString(this.defaultToDate), [])
  }, { validators: this.validatorConversionService.dateRangeValidator(true) });

  constructor(
    private validatorConversionService: ValidatorConversionService,
    private entitiesService: EntitiesService,
    private dateConverterService: DateConverterService
  ) { }

  ngOnInit(): void {
    this.getEventTypes();
    this.filter();
  }
  
  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getEventTypes(): void {
    this.isLoading = true;
    const pageSize = 100;
    const eventTypeEntityName = 'calendar_event_type';
    const earningsEventType = MissingCompanyEventsSettings.EARNINGS_EVENT_TYPE;

    const fetchEventTypes = (page: number): void => {
      this.entitiesService.getEntities(eventTypeEntityName, page, pageSize).pipe(takeUntil(this.componentDestroyed$)).subscribe(
        (response) => {
          const earningsIndex = response.data.findIndex((eventType) => eventType.name === earningsEventType);
          const earnings = response.data.splice(earningsIndex, 1);
          response.data.unshift(earnings[0]);
          this.eventTypes.push(...response.data);
          if (response.last_page > page) {
            fetchEventTypes(page + 1);
          } else {
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    };

    fetchEventTypes(1);
  }

  toggleExpansion(): void {
    this.isFilterExpanded = !this.isFilterExpanded;
  }

  toggleEventTypeFilter(eventType: any): void {
    eventType.isSelected = !eventType.isSelected;
    this.filterForm.markAsDirty();
  }

  onDropdownValueSelected(data: any, field: string): void {
    this.dropdownFieldValues[field] = data;
    this.filterForm.markAsDirty();
  }

  clearSelectedDropdownValues(field: string): void {
    this.dropdownFieldValues[field] = null;
  }

  filter(): void {
    if(this.filterForm.invalid) {
      return;
    }
    const payload = {
      from_date: this.filterForm.get('from_date').value,
      to_date: this.filterForm.get('to_date').value,
      universe: this.dropdownFieldValues.universe,
      tier: this.dropdownFieldValues.tier,
      event_types: this.eventTypes.filter((eventType) => eventType.isSelected).map((eventType) => eventType.id)
    };
    this.filterEvents.emit(payload);
  }

  reset(): void {
    this.filterForm.reset()
    this.filterForm.markAsDirty();
    this.dropdownFieldValues = { universe: null, tier: null }
    this.eventTypes = this.eventTypes.map((eventType) => { 
      eventType.isSelected = false;
      return eventType;
    });
  }

}
