import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EntitiesService } from 'app/entities/services/entities.service';


@Component({
  selector: 'con-primary-instrument-editor',
  templateUrl: './primary-instrument-editor.component.html'
})
export class PrimaryInstrumentEditorComponent implements OnInit, OnDestroy, OnChanges {

  @Input() company: any;
  @Input() showEditor = true;
  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();

  public currentInstrument: any;
  public newInstrument: any;
  public taskSubscription: any;
  public is_processing = false;
  public managerName = 'Manager not assigned';
  public hasNoPrimaries = false;

  constructor(
    private toastr: ToastrService,
    private service: EntitiesService,
  ) { }

  ngOnInit() {
    // if instruments count is 2, and both are not primary
    if (this.company.instruments.length === 2 && !this.company.instruments[0].is_primary && !this.company.instruments[1].is_primary) {
      this.currentInstrument = this.company.instruments[0];
      this.newInstrument = this.company.instruments[1];
      // set hasNoPrimaries to true
      this.hasNoPrimaries = true;
    } else {
      this.company.instruments.forEach(element => {
        if (element.is_primary) {
          this.currentInstrument = element;
        } else {
          this.newInstrument = element;
        }
      });
    }


  }
  ngOnChanges() {

    if (this.company && this.company.manager && this.company.manager.first_name) {
      this.managerName = this.company.manager.first_name + ' ' + this.company.manager.last_name;
    }
  }
  markAsDone() {

    const task = this.company.tasks[0];
    const payload = {
      id: task.id,
      done: true
    };
    this.saveEntity(payload, 'Task');
  }
  saveEntity(payload: any, entityName: string) {
    this.is_processing = true;
    this.taskSubscription = this.service.saveEntity(entityName, payload).subscribe(data => {
      if (data.done || data.is_primary) {
        this.toastr.success('Primary instrument updated successfully!', 'Primary Instrument');
        this.afterSave.emit(this.company);
      } else {
        this.toastr.error('Sorry, some error occurred!', 'Primary Instrument');
      }
      this.is_processing = false;
    }, err => {
      this.is_processing = false;
      this.toastr.error('Sorry, some error occurred!', 'Primary Instrument');
    });
  }
  switchPrimary() {

    const instrument = this.newInstrument;
    this.newInstrument = this.currentInstrument;
    this.currentInstrument = instrument;
    const payload = {
      id: this.currentInstrument.id,
      is_primary: true
    };
    this.saveEntity(payload, 'Instrument');
  }
  ngOnDestroy() {
    if (this.taskSubscription) {
      this.taskSubscription.unsubscribe();
    }
  }

}
