<div class="alert alert-info alert-dismissible mb-0" *ngIf="entityHasRelation()">
  <button type="button" class="close" aria-label="Close" (click)="empty()" *ngIf="!relation.disabled">
    <span aria-hidden="true">&times;</span>
  </button>
  <a class="alert-link-icon mr-1" [routerLink]="['/entity/', getRelatedObjectType() | snakeCase, relationObject.id]" placement="top" container="body" [ngbTooltip]="'Go to ' + (relation.name | prettyCase)">
    <con-icon-by-name [iconName]="getIconForRelation()" [mutations]="['fw']"></con-icon-by-name>
  </a>
  <strong>{{ getPrimaryStringForRelation() }}</strong>
</div>
<ng-container *ngIf="!entityHasRelation() && (!relation.disabled || !entityAlreadyExists())">
    <ng-container *ngFor="let r of inverseRelations">
        <con-entity-search-field
            [parentCompany] ="parentCompany"
            [fromShareholder]="fromShareholder"
            [ownerType]="ownerType"
            [fixedSearchParams]="fixedSearchParams[relations.getUniqueNameForRelation(r)]"
            [entityName]="r.owner"
            [relation]="r"
            [fromEntityForm]="fromEntityForm"
            (onSelect)="changeRelation(r, $event)">
        </con-entity-search-field>
    </ng-container>
</ng-container>
<ng-container *ngIf="!entityHasRelation() && relation.disabled && entityAlreadyExists()">
    <div class="alert alert-info">
        None
    </div>
</ng-container>
