import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketcapSettings } from 'app/settings.class';
import { HttpClient } from '@angular/common/http';
import { MarketcapCountService } from 'app/market-cap/service/marketcap-count.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'con-missing-share-count',
  templateUrl: './missing-share-count.component.html'
})
export class MissingShareCountComponent implements OnInit, OnDestroy {

  public loading = true;
  public missing: any;
  private missingSubscription = new Subscription;
  private reloadSubscription = new Subscription;
  public noDataMessage = 'No data to display.';

  constructor(
    private http: HttpClient,
    private countService: MarketcapCountService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadPage(1);
    this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
      if (type === 'missing') {
        this.loadPage(1);
      }
    });
  }
  loadPage(pageNumber: number) {

    this.loading = true;
    if (this.missingSubscription) {
      this.missingSubscription.unsubscribe();
    }
    this.missingSubscription = this.http.get<any>(MarketcapSettings.BASE_URL + '/' + MarketcapSettings.MISSING_ENDPOINT,
      {
        params: {
          page: String(pageNumber)
        }
      })
      .subscribe(res => {
        this.countService.setCount('missing', res.total);
        this.missing = res;
        this.loading = false;
      }, error => {
        this.loading = false;
        this.countService.setCount('missing', 0);
        this.missing = { total: 0, data: [] };
        this.toastr.error('Sorry, some error occurred!');
      });
  }

  updateInList(company: any) {
    const index = this.missing.data.findIndex(c => c.id === company.company_id);
    this.missing.data.splice(index, 1);
    this.countService.decrementCount('missing');
  }

  ngOnDestroy() {
    if (this.missingSubscription) {
      this.missingSubscription.unsubscribe();
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }
}
