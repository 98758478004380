import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'con-share-count-list',
  templateUrl: './share-count-list.component.html'
})
export class ShareCountListComponent implements OnInit, OnChanges {

  @Input() company: any;
  public managerName = 'Manager not assigned';
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {

    if (this.company && this.company.manager && this.company.manager.first_name) {
      this.managerName = this.company.manager.first_name + ' ' + this.company.manager.last_name;
    }
  }
}
