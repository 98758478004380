<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title pb-3 pt-3">

                <button class="btn" [ngClass]="{'btn-primary' : activeTab===SHARE_COUNT ? true : false}" (click)="setActiveTab(SHARE_COUNT)">Share Count</button>
                <button class="btn" [ngClass]="{'btn-primary' : activeTab===PRIMARY_INSTRUMENT? true : false}" (click)="setActiveTab(PRIMARY_INSTRUMENT)">Duplicate Primaries</button>
                <button class="btn" [ngClass]="{'btn-primary' : activeTab===MISSING_PRIMARY_INSTRUMENT? true : false}" (click)="setActiveTab(MISSING_PRIMARY_INSTRUMENT)">Missing Primaries</button>
                <button class="btn" [ngClass]="{'btn-primary' : activeTab===REVIEW? true : false}" (click)="setActiveTab(REVIEW)">Review</button>
            </div>
            <con-loader *ngIf="isLoading()"></con-loader>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="!isLoading() && company">
            <con-share-count-editor *ngIf="activeTab===SHARE_COUNT && view===SHARE_COUNT" [company]="{name : company.name, manager : company.manager}" [showEditor]="showForm" [fixedValues]="{company_id : company.id}" [header]='editorHeader' [preFillValues]="preFillValues"
                [displayEntityList]="displayEntityList" (afterSave)="updateInList($event)">
            </con-share-count-editor>

            <con-primary-instrument-editor *ngIf="activeTab===PRIMARY_INSTRUMENT && view===PRIMARY_INSTRUMENT" [company]="company" [showEditor]="showForm" (afterSave)="updateInList($event)">
            </con-primary-instrument-editor>
            <con-missing-primary-editor *ngIf="activeTab===MISSING_PRIMARY_INSTRUMENT && view===MISSING_PRIMARY_INSTRUMENT" [company]="company" [showEditor]="showForm" (afterSave)="updateInList($event)">
            </con-missing-primary-editor>
            <con-share-count-list *ngIf="isShareCountListRequested && company?.share_counts?.length > 0" [hidden]="!(activeTab===REVIEW &&  company?.share_counts?.length>0)" [company]="company">
            </con-share-count-list>

            <div *ngIf="showNoDataMessage()">
                <div class="d-flex justify-content-start sticky-header py-2">
                    <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3>
                    <div class="chip chip-inverse ml-auto">
                        <con-avatar *ngIf="company.manager?.id" [userId]="company.manager?.id" [size]="32"></con-avatar>
                        {{managerName}}
                    </div>
                </div>

            </div>
            <con-no-data count="0" *ngIf="showNoDataMessage()" [message]="noDataMessage"></con-no-data>
        </div>
        <div class="col-12" *ngIf="!isLoading() && !company">
            <con-no-data count="0" [message]="noDataMessage"></con-no-data>
        </div>

    </div>
</div>