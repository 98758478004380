import {Component, OnInit} from '@angular/core';
import {InstanceDataKiid, InstanceDataValue, ShareClass} from '../../../kpi-and-kiid/models/Typings';
import {FieldColor} from '../../../kpi-and-kiid/models/field-color';
import {CurrentFields} from '../../../kpi-and-kiid/models/fields';
import {AbstractFieldMetaDependencies} from "../../models/FieldStatusInterface";

@Component({
  selector: 'con-share-classes',
  template: `
    <div class="container-fluid mt-2 share-class-area">
      <div class="form">
        <h4 class="my-1 mr-2 d-none d-sm-block row"><span class="badge badge-light">Share classes</span></h4>
      </div>
      <div class="row">
        <div class="col-12 share-class-container">
          <div  [ngClass]="i === 0 ?'share-class-field col-7 p-2 text-center':' share-class-field col-3 p-2 text-center'" *ngFor="let shareClass of instanceData.ShareClasses; let i=index">
            <button (click)="removeShareClass(i)" class="btn btn-danger my-1 smaller-button" [disabled]="i === 0">
              <con-icon-by-name iconName="minus"></con-icon-by-name>
            </button>
            <ng-container *ngIf="instanceData.ShareClasses.length">
              <div
                [ngStyle]="{'background-color' : getFieldBackgroundColor(item.value)}"
                *ngFor="let item of getShareClassProperties(shareClass) | keyvalue; let g=index"
                class="form-inline "
              >
                <div class="p-1 d-flex full-width justify-content-between col-12 p-0" *ngIf="getCurrentFields[item.value]">
                  <label *ngIf="i === 0" class="my-1 d-none d-sm-block"><span class="badge badge-light">{{item.value}}</span></label>
                  <div class="d-flex align-items-center justify-content-end">
                    <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass(item.value, i)" [iconName]="getLowMlConfOrConfrimedFieldIconName(item.value, i)"></con-icon-by-name>
                    <input
                      *ngIf="shareClass[item.value]"
                      [(ngModel)]="shareClass[item.value].value"
                      type="text"
                      class="form-control smaller-input share-class-input"
                      [placeholder]="item.value"
                      (ngModelChange)="shareClass[item.value].altered = true; shareClass[item.value].confirmed = 'Altered'"
                      (paste)="removeLettersFromNumbers($event, shareClass[item.value], item.value)"
                    >
                  </div>

                </div>

              </div>
            </ng-container>
          </div>
          <div class="col-2  d-flex align-items-center">
            <button (click)="addNewShareClass(instanceData)" class="btn btn-primary">
              <con-icon-by-name iconName="plus"></con-icon-by-name>
            </button>
          </div>
        </div>
      </div>

    </div>
  `,
  styles: [`
    .share-class-area{
      overflow-y: scroll;
    }
    .share-class-container {
      display: inline-flex;
      min-height: 170px;
    }
    .share-class-field {
      min-width: 140px;
    }
    .smaller-input{
      padding: 0 10px;
      height: 30px;
    }
    .smaller-button {
      padding: 0.1rem 0.4rem;
    }
    .share-class-input {
      max-width: 120px;
    }
    .full-width{
      width: 100%;
    }
    .checked{
      color: green;
      margin-right: 4px;
    }
    .warning {
      color: orange;
      margin-right: 4px;
    }
  `]
})
export class ShareClassesComponent extends AbstractFieldMetaDependencies implements OnInit {
  private fieldsForShareClasses: string[];

  constructor() { super() }

  ngOnInit(): void {
    this.saveShareClasses(this.instanceData);
  }

  public addNewShareClass(instanceData) {
    let newShareClass = {}

    for (const field of this.fieldsForShareClasses) {
      newShareClass[field] = getNewInstanceDataValue()
    }

    delete newShareClass?.['ShareClass']
    delete newShareClass?.['ShareCurrency']

    instanceData.ShareClasses.push(newShareClass as ShareClass)

    function getNewInstanceDataValue(): InstanceDataValue {
      return {
        value: null,
        ml_conf: null,
        altered: null,
        confirmed: null
      } as InstanceDataValue
    }
  }
  saveShareClasses(instanceData: InstanceDataKiid) {
    const newInstanceData = JSON.parse(JSON.stringify(instanceData))
    this.fieldsForShareClasses = Object.keys(newInstanceData.ShareClasses[0]);
  }
  getShareClassProperties(shareClass: ShareClass) {
    return Object.keys(shareClass)
  }
  get getCurrentFields(): typeof CurrentFields {
    return CurrentFields
  }
  removeShareClass(index: number) {
    this.instanceData.ShareClasses.splice(index, 1)
  }
  getLowMlConfOrConfrimedFieldClass(field: string, shareClassIndex: number) {
    if (this.isFieldConfirmed(field, shareClassIndex)) { return 'checked' }
    if (this.isMlConfigLow(field, shareClassIndex)) { return 'warning' }
    return ''
  }
  isMlConfigLow(field: string, shareClassIndex: number) {
    return this.instanceData.ShareClasses[shareClassIndex][field]['ml_conf'] === 'Low'
  }
  isFieldConfirmed(field: string, shareClassIndex: number) {
    return this.instanceData.ShareClasses[shareClassIndex][field]['confirmed']
  }
  getFieldBackgroundColor(field: string) {
    if (this.isCurrentClass(field)) { return FieldColor.Current }
    return FieldColor.Previous
  }
  isCurrentClass(currentClass: string) {
    return this.currentFields.find(field => CurrentFields[currentClass] === field) > -1
  }
  removeLettersFromNumbers(clipboardEvent: ClipboardEvent, valueToReplace: any, type: string) {
    if (type === 'ISIN') { return valueToReplace.value }
    setTimeout(() => {
      const parseToInt = parseFloat((clipboardEvent.target as HTMLInputElement).value.replace(/,/g, '.').replace(/[^0-9.]/g, ''))
      if (isNaN(parseToInt)) { valueToReplace.value = '0' } else {
        valueToReplace.value = parseToInt.toFixed(2)
      }
    }, 0)
  }
  getLowMlConfOrConfrimedFieldIconName(field: string, shareClassIndex?: number) {
    if (this.isFieldConfirmed(field, shareClassIndex)) { return 'check-circle-o' }
    if (this.isMlConfigLow(field, shareClassIndex)) { return 'exclamation-triangle' }
    return ''
  }
}
