
import {combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { LayoutService } from './layout.service';
import { ProfileService } from './auth/services/profile.service';

import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';



@Component({
    selector: 'con-settings',
    templateUrl: './settings.component.html'
})

export class SettingsComponent implements OnInit, OnDestroy {
    public views: any;
    public toolbarViews: any[] = [];
    private BAG = 'DRAGULA_VIEWS';
    private dragSubscriptions: Subscription = new Subscription();
    private view: string;

    constructor(public layout: LayoutService,
                public profile: ProfileService,
                private dragulaService: DragulaService) {
                    this.dragSubscriptions.add(
                        this.dragulaService
                            .drop(this.BAG)
                            .subscribe(view => {
                                this.view = (view.el as HTMLElement).innerText;
                                switch (view.target.id) {
                                    case 'toolbar': {
                                        this.layout.getViews().subscribe(views => {
                                            const newViews = (view.target as HTMLElement).innerText.split(' ')
                                                .filter(str => str)
                                                .map(data => {
                                                    return views.filter(newView => newView.name.includes(data))[0]
                                                });
                                            this.toolbarViews =  newViews.filter((v, i) =>  newViews.indexOf(v) === i)
                                        })
                                    } break;
                                }
                                this.updateOrder();
                            }
                        )
                    );
                }

    ngOnInit() {
        this.layout.getViewsInToolbar().pipe(
                    combineLatest(this.layout.getViews()))
                    .subscribe(views => {
                        this.toolbarViews = views[0];
                        this.views = views[1].filter(v1 => {
                            return this.toolbarViews.filter(v2 => v2.name === v1.name).length === 0;
                        });
                    });
    }

    updateOrder() {
        this.layout.syncOrder(this.toolbarViews);
    }

    ngOnDestroy() {
        this.dragSubscriptions.unsubscribe();
    }
}
