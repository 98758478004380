<div class="container-fluid mb-3">
    <div>
        <div class="row">
            <div class="col-12">
                <div class="page-title mb-2 pb-3 pt-3">
                    <h3 class="mb-0">Key Financials</h3>
                </div>
            </div>
        </div>
    </div>
  <div class="d-flex justify-content-start sticky-header non-sticky py-2">
    <h3><span class="badge badge-dark">{{getDisplayTitle()}}</span></h3>
    <span *ngIf="entity?.company?.website" class="website-link-positioning">
              <a [href]=entity?.company?.website class="link-fontsize" target="_blank">
                {{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
            </span>
    <div class="ml-auto">
      <a [href]='entity?.task_backlink' target='_blank' *ngIf='entity?.task_backlink'>
        <div class="chip chip-inverse">
          <con-icon-by-name iconName="external-link"></con-icon-by-name>
          Task
        </div>
      </a>
      <div class="chip chip-inverse">
        <con-avatar [userId]="entity?.company?.manager?.id" [size]="32"></con-avatar>
        {{ entity?.company?.manager?.first_name }} {{ entity?.company?.manager?.last_name }}
      </div>
    </div>
  </div>
    <div class="d-flex justify-content-start sticky-header non-sticky py-2">
        <form>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="key_fin_check" *ngIf="(entity?.locked && !settingLock)"
                    [formControl]="reportCtrls.controls['checkbox']"
                    disabled="true"/>
                <input type="checkbox" class="custom-control-input" id="key_fin_check" *ngIf="!(entity?.locked && !settingLock)"
                    [formControl]="reportCtrls.controls['checkbox']" (change)="approveKeyFinancials()" />
                <label class="custom-control-label" for="key_fin_check">Approve Key Financials</label>
            </div>
        </form>
    </div>
    <div class="alert ml-status-created" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CREATED">
      <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
      <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
    </div>
    <div class="alert ml-status-in-progress" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.IN_PROGRESS">
      <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
      <button class='btn btn-primary pull-right cancel-btn-pos' (click)='companyService.cancelRequest(this.entity.id)'>Cancel</button>
    </div>
    <div class="alert ml-status-submitted" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.SUBMITTED">
      <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
    </div>
    <div class="alert ml-status-closed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.CLOSED">
      <con-icon-by-name iconName="check"></con-icon-by-name> {{MLSendStatus.CLOSED_MESSAGE}}
    </div>
    <div class="alert ml-status-failed" *ngIf="companyService.mlCurrentStatus() === MLSendStatus.FAILED">
      <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.FAILED_MESSAGE}}
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <con-no-data *ngIf="entity?.locked && !settingLock" count="0" class="locked-report"></con-no-data>
    <div class="card mb-2" *ngIf="!loading">
      <con-no-data *ngIf="!tableFormatData.tableHeadData.length" [count]="tableFormatData.tableHeadData.length" message="No key financials data available."></con-no-data>
      <table class="key-financial-table" *ngIf="tableFormatData.tableHeadData.length">
        <tr>
          <th class="header-spacing">&nbsp;</th>
          <th *ngFor="let header of tableFormatData.tableHeadData" class="text-center">{{header}}</th>
        </tr>

        <tr *ngFor="let incomeStatementModule of tableFormatData.incomeStatements[0]?.modules; let i = index;" class="tree-node">
          <td>{{ incomeStatementModule?.tree_name ? incomeStatementModule?.tree_name : incomeStatementModule?.name}}</td>

          <td *ngFor="let incomeStatement of tableFormatData.incomeStatements;" class="text-center">
              {{getDisplayValue('income_statement', incomeStatement?.modules[i], incomeStatement?.quantity) | stringify | imask: mask}}
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr *ngFor="let balanceSheetModule of tableFormatData.balanceSheets[0]?.modules; let i = index;" class="tree-node">
          <td>{{ balanceSheetModule?.tree_name ? balanceSheetModule?.tree_name : balanceSheetModule?.name}}</td>
          <td *ngFor="let balanceSheet of tableFormatData.balanceSheets"  class="text-center">
            {{getDisplayValue('balance_sheet', balanceSheet?.modules[i], balanceSheet?.quantity) | stringify | imask: mask}}
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr *ngFor="let cashFlowModule of tableFormatData.cashFlows[0]?.modules; let i = index;" class="tree-node">
            <td>{{ cashFlowModule?.tree_name ? cashFlowModule?.tree_name : cashFlowModule?.name}}</td>
            <td  *ngFor="let cashFlow of tableFormatData.cashFlows;"  class="text-center">
              {{getDisplayValue('cash_flow', cashFlow?.modules[i], cashFlow?.quantity) | stringify | imask: mask}}
            </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
          <tr *ngFor="let periodKpiModule of tableFormatData.periodKpis[0]?.modules; let i = index;" class="tree-node">
            <td>{{ periodKpiModule?.tree_name ? periodKpiModule?.tree_name : periodKpiModule?.name}}</td>
            <td *ngFor="let periodKpi of tableFormatData.periodKpis"  class="text-center">
              {{getDisplayValue('period_kpi', periodKpi?.modules[i], periodKpi?.quantity) | stringify | imask: mask}}
            </td>
          </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
          <tr *ngFor="let snapshotKpiModule of tableFormatData.snapshotKpis[0]?.modules; let i = index;" class="tree-node">
            <td>{{ snapshotKpiModule?.tree_name ? snapshotKpiModule?.tree_name : snapshotKpiModule?.name}}</td>
            <td *ngFor="let snapshotKpi of tableFormatData.snapshotKpis"  class="text-center">
              {{getDisplayValue('snapshot_kpi', snapshotKpi?.modules[i], snapshotKpi?.quantity) | stringify | imask: mask}}
            </td>
          </tr>

      </table>
    </div>
</div>
