import { Component, Input, Output, OnInit, OnChanges, OnDestroy, EventEmitter } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { EntitiesService } from '../../../entities/services/entities.service';

@Component({
    selector: 'con-tier-institution-editor',
    templateUrl: './tier-institution-editor.component.html'
})

export class TierInstitutionEditorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() result: any;
    @Input() tierId: number;

    @Input() canRemoveManger = true;

    @Input() fetching = false;
    @Input() showIgnore = false;
    @Input() showUnignore = false;
    @Input() showInclude = false;
    @Input() showExclude = false;

    @Output() institutionUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() institutionIgnored: EventEmitter<any> = new EventEmitter<any>();
    @Output() institutionUnignored: EventEmitter<any> = new EventEmitter<any>();
    @Output() institutionIncluded: EventEmitter<any> = new EventEmitter<any>();
    @Output() institutionExcluded: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() termsChanged: EventEmitter<string> = new EventEmitter<string>();

    private editing: any = {};
    private loading: any = {};
    private errors: any = {};
    public noDataMessage = 'No data to display.';
    public institutionName: UntypedFormControl = new UntypedFormControl();
    public institutionNameSubscription: Subscription;

    constructor(private entitiesService: EntitiesService) {}

    refresh() {
        this.pageChanged.emit(this.result.current_page);
    }

    ngOnInit() {
        this.institutionNameSubscription = this.institutionName.valueChanges
                                                       .pipe(debounceTime(300))
                                                       .subscribe(
                                                           institutionName => {
                                                               this.termsChanged.emit(institutionName);
                                                           }
                                                       );
    }

    ngOnDestroy() {
        this.institutionNameSubscription.unsubscribe();
    }

    ngOnChanges() {
        this.editing = {};
        this.loading = {};
        this.errors = {};
    }

    changePage(pageNumber: number) {
        this.pageChanged.emit(pageNumber);
    }

    doAction(institutionId: Number) {
        this.loading[institutionId + '-action'] = true;
    }

    actionFinished(institutionId: Number) {
        this.loading[institutionId + '-action'] = false;
    }

    isDoingAction(institutionId: Number) {
        return this.loading[institutionId + '-action'];
    }

    managerHasErrors(institutionId: Number) {
        return this.errors[institutionId + '-manager'];
    }

    emptyManagerErrors(institutionId: Number) {
        delete this.errors[institutionId + '-manager'];
    }

    getManagerErrors(institutionId: Number) {
        return this.errors[institutionId + '-manager'];
    }

    institutionHasManager(institution: any) {
        return institution.manager_id !== null;
    }

    isEditingManager(institutionId: Number) {
        return this.editing[institutionId + '-manager'];
    }

    isLoadingManger(institutionId: Number) {
        return this.loading[institutionId + '-manager'];
    }

    toggleEditingManager(institutionId: Number) {
        if (!(institutionId + '-manager' in this.editing)) {
            this.editing[institutionId + '-manager'] = false;
        }
        this.emptyManagerErrors(institutionId);
        this.editing[institutionId + '-manager'] = !this.editing[institutionId + '-manager'];
    }

    hideEditingManager(institutionId: Number) {
        this.editing[institutionId + '-manager'] = false;
    }

    includeInstitution(institution: any) {
        this.doAction(institution.id);

        this.entitiesService.addRelation(
            'InstitutionTier',
            this.tierId,
            'institutions',
            [ institution.id ]
        )
        .subscribe(res => {
            this.institutionIncluded.emit(institution);
            this.actionFinished(institution.id);
        }, err => {
            console.log(err);
            this.actionFinished(institution.id);
        });
    }

    excludeInstitution(institution: any) {
        this.doAction(institution.id);

        this.entitiesService.removeRelation(
            'InstitutionTier',
            this.tierId,
            'institutions',
            [ institution.id ]
        )
        .subscribe(res => {
            this.institutionExcluded.emit(institution);
            this.actionFinished(institution.id);
        }, err => {
            console.log(err);
            this.actionFinished(institution.id);
        });
    }

    ignoreInstitution(institution: any) {
        this.doAction(institution.id);

        this.entitiesService.addRelation(
            'CInstitutionTier',
            this.tierId,
            'ignoredInstitutions',
            [ institution.id ]
        )
        .subscribe(res => {
            this.institutionIgnored.emit(institution);
            this.actionFinished(institution.id);
        }, err => {
            console.log(err);
            this.actionFinished(institution.id);
        });
    }

    unignoreInstitution(institution: any) {
        this.doAction(institution.id);

        this.entitiesService.removeRelation(
            'InstitutionTier',
            this.tierId,
            'ignoredInstitutions',
            [ institution.id ]
        )
        .subscribe(res => {
            this.institutionUnignored.emit(institution);
            this.actionFinished(institution.id);
        }, err => {
            this.actionFinished(institution.id);
        });
    }

    removeManger(institution: any) {
        this.loading[institution.id + '-manager'] = true;

        this.entitiesService.saveEntity(
            'Institution',
            {
                id: institution.id,
                updated_at: institution.updated_at,
                manager_id: null
            }
        )
        .subscribe(res => {
            this.loading[institution.id + '-manager'] = false;
            this.institutionUpdated.emit(res);
        }, err => {
            if (err.isValueError()) {
                this.errors[institution.id + '-manager'] = err.getData().general;
            }
            this.loading[institution.id + '-manager'] = false;
        });
    }

    updateManager(institution: any, manager: any) {
        this.loading[institution.id + '-manager'] = true;
        this.entitiesService.saveEntity(
            'Institution',
            {
                id: institution.id,
                updated_at: institution.updated_at,
                manager_id: manager.id
            }
        )
        .subscribe(res => {
            this.loading[institution.id + '-manager'] = false;
            this.hideEditingManager(institution.id);
            this.institutionUpdated.emit(res);
        }, err => {
            this.loading[institution.id + '-manager'] = false;
        });
    }
}
