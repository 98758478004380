import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../auth/services/profile.service';
import { ShareholderSettings } from '../../settings.class';

@Component({
    selector: 'con-shareholders-start',
    templateUrl: './shareholders-start.component.html'
})

export class ShareholdersStartComponent {
    public tierId: number = ShareholderSettings.TIER_ID;

    constructor(private router: Router,
                public profile: ProfileService) {}

    public navigateToCompany(company: any) {
        this.router.navigate(['shareholders', company.id]);
    }
}
