import { Component } from '@angular/core';

@Component({
    selector: 'con-dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent {

}
