import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';

@Component({
  selector: 'con-connector-redirect-button',

  template: `
    <button (click)="openUrl()" type="button" class="btn btn-primary btn-sm d-flex align-items-center flex-nowrap ml-0 mr-2 my-2">
      {{ component.title }}
      <con-icon-by-name class="ml-2" [iconName]="iconName"></con-icon-by-name>
    </button>
  `,
})
export class ConnectorRedirectButtonComponent {
  @Input() component: DynamicDataEntryComponentInterface;
  private companyId: number;
  public iconName: string;

  constructor(private store: Store, private instanceService: InstanceService) {}

  ngOnInit() {
    this.companyId = this.store.selectSnapshot(TaskDataEntryState.selectCompanyId(this.instanceService.getInstanceId()));
    this.iconName = this.component.options.icon;
  }

  openUrl() {
    const url = this.component.url.replace('{mapping}', this.companyId.toString());
    window.open(url, '_blank');
  }
}
