<div *ngIf="hideCompanyMissingForOnlyIgnored()">
  <div class="d-flex justify-content-start sticky-header py-2">
    <h3 class="mb-0">
      <a (click)="redirectToCompanyPage(company.id, isMissingMode())" class="cursor-pointer">
        <span class="badge badge-dark">{{ company.name }}</span>
      </a>
    </h3>
    <div class="ml-2 adjust-mt-5">
      <span class="badge badge-secondary" *ngIf="getCompanyISINs(company)">{{getCompanyISINs(company)}}<con-icon-by-name iconName="copy" class="ml-15 cursor-pointer" (click)="copyISIN($event, getCompanyISINs(company))"></con-icon-by-name></span>
      <span *ngIf=canShowIgnoreCheckbox() >
        <input class="ml-2" type="checkbox" id="scales" [value]="company.ignore_missing" [checked]="company.ignore_missing" #ref (click)="updateIgnoreMissingField(company,ref)">
        <label for="scales" *ngIf=canShowIgnoreCheckbox()><small class="small-size">Ignore Missing</small></label>
      </span>
    </div>
    <div class="chip chip-inverse ml-auto">
      <con-avatar [userId]="company.manager_id" [size]="32"></con-avatar>
      {{ company.manager?.first_name }} {{ company.manager?.last_name }}
    </div>
  </div>
<con-loader *ngIf="loading"></con-loader>
<div *ngIf="!loading">
  <con-sector-editor *ngIf="showSectorForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
   [company]="company" (afterUpdate)="sectorUpdated($event)" [sectors] =sectors
  >
  </con-sector-editor>
    <con-website-editor
        *ngIf="showWebsiteForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="websiteUpdated($event)"
    ></con-website-editor>
    <con-email-editor
        *ngIf="showEmailForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="emailUpdated($event)"
    ></con-email-editor>
    <con-phone-editor
        *ngIf="showPhoneForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="phoneUpdated($event)"
    ></con-phone-editor>
    <con-address-editor
        *ngIf="showAddressForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="addressUpdated($event)"
    ></con-address-editor>
    <con-country-editor
        *ngIf="showCountryForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="countryUpdated($event)"
    ></con-country-editor>
    <con-founded-editor
        *ngIf="showFoundedForm() && canShowComponent('company data') && showCompanyDetailForIgnoredMissing()"
        [company]="company"
        (afterUpdate)="foundedUpdated($event)"
    ></con-founded-editor>
    <div class="card mb-2" *ngIf="!isMissingMode() && canShowComponent('positions') && showPositionEditor && company.positions?.length > 0">
        <div class="card-header">
            <con-icon-by-name iconName="user" [mutations]="['fw']"></con-icon-by-name>Company positions
        </div>
        <div class="card-body">
            <con-multi-position-editor
                [entityList]="company.positions"
                entityName="CompanyPosition"
                [showWhenChanged]="false"
                [only]="['person_name', 'companyRole']"
                [canDelete]="true"
                [canEdit]="true"
                (afterSave)="updatePosition($event)"
                (afterDelete)="removePosition($event)"
                [fixedValues]="{company_id: company.id}"
                toastMessage="Position updated successfully!">
            </con-multi-position-editor>
        </div>
    </div>
    <div class="card mb-2" *ngIf="isMissingMode() && canShowComponent('positions') && showPositionEditor && positions.length > 0">
        <div class="card-header">
            <con-icon-by-name iconName="user" [mutations]="['fw']"></con-icon-by-name>Company positions
        </div>
        <div class="card-body">
            <con-multi-position-editor
                [entityList]="positions"
                entityName="CompanyPosition"
                [showWhenChanged]="false"
                [only]="['person_name', 'companyRole']"
                [canDelete]="true"
                [canEdit]="true"
                (afterSave)="updatePosition($event)"
                (afterDelete)="removePosition($event)"
                [fixedValues]="{company_id: company.id}"
                [isMissing]="isMissingMode()"
                toastMessage="Position updated successfully!">
            </con-multi-position-editor>
        </div>
    </div>

    <div class="card mb-2" *ngIf="!isMissingMode() && canShowComponent('advisors') && company.company_advisors.length > 0">
        <div class="card-header">
            <con-icon-by-name iconName="user" [mutations]="['fw']"></con-icon-by-name>Company Advisors
        </div>
        <div class="card-body">
            <con-multi-position-editor
                [entityList]="company.company_advisors"
                entityName="CompanyAdvisor"
                [showWhenChanged]="false"
                [only]="['name', 'companyAdvisorType']"
                [canDelete]="true"
                [canEdit]="true"
                (afterSave)="updateAdvisor($event)"
                (afterDelete)="removePosition($event)"
                [fixedValues]="{company_id: company.id}"
                [isMissing]="isMissingMode()"
                toastMessage="Advisor updated successfully!">
            </con-multi-position-editor>
        </div>
    </div>

    <div class="card mb-2" *ngIf="isMissingMode() && canShowComponent('advisors') && advisors.length > 0">
        <div class="card-header">
            <con-icon-by-name iconName="user" [mutations]="['fw']"></con-icon-by-name>Company Advisors
        </div>
        <div class="card-body">
            <con-multi-position-editor
                [entityList]="advisors"
                entityName="CompanyAdvisor"
                [showWhenChanged]="false"
                [only]="['name', 'companyAdvisorType']"
                [canDelete]="true"
                [canEdit]="true"
                (afterSave)="updateAdvisor($event)"
                (afterDelete)="removePosition($event)"
                [fixedValues]="{company_id: company.id}"
                [isMissing]="isMissingMode()"
                toastMessage="Advisor updated successfully!">
            </con-multi-position-editor>
        </div>
    </div>
    <div *ngIf="canShowComponent('description') ">
        <con-description-editor
        *ngFor="let lang of languages"
        [companyDescriptions]="company.descriptions"
        [description]="getDescriptionByLanguage(lang.id)"
        [language]="lang"
        [requiredLanguages]="company.required_languages"
        [companyId]="company.id"
        (afterUpdate)="updateDescription($event)"
        (afterDelete)="removeDescription($event)"
    >
    </con-description-editor>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="showMissingMessage()">
            <div class="alert no-data">Nothing missing!</div>
        </div>
        <div class="col-12" *ngIf="showApproveButton() && profile.canAnd([profile.getPermissionName('Company', 'update'), profile.getPermissionName('Company', 'update', 'positions')])">
            <button class="btn btn-lg btn-primary" (click)="approve()"><con-icon-by-name iconName="check"></con-icon-by-name> Approve data</button>
        </div>
    </div>
</div>
<div *ngIf="!showMissingMessage() && !canShowEditorComponent()">
    <con-no-data [count]="0" [message]="noDataMessage"></con-no-data>
</div>
<hr>
</div>
