import { Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import {ProfileService} from '../../../../auth/services/profile.service';
import {DateConverterService} from '../../../../shared/services/date-converter.service';
import {ApiSettings, CalendarSettings} from '../../../../settings.class';

@Component({
  selector: 'con-dp-new-event-form',
  template: `
    <div class="row">
      <div class="col-12">
        <div class="card mb-2">
          <div class="card-header">
            <div class="float-left text-left">
              {{company.currentEditingEvent ? 'Edit Event' : 'Create Event'}}
            </div>
            <div class="float-right cursor-pointer" *ngIf="company.currentEditingEvent" >
              <div class="chip chip-link add-comment" *ngIf="company.currentEditingEvent" (click)="toggleEdit()">
                <i class="fa fa-pencil fa-fw"></i>
                Edit
              </div>
              <con-icon-by-name iconName="remove" (click)="clearEditor()"></con-icon-by-name>
            </div>
          </div>
          <div class="card-body" *ngIf="showEvent()">
            <con-entity-form-content *ngIf="!company.currentEditingEvent"
                                     [ownerType]="ownerType"
                                     showEditButton=true
                                     entityName="CalendarEvent"
                                     (showComments)="setCommentFlag($event)"
                                     [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                     [emptyAfterSave]="true"
                                     (afterSave)="newEventChanged($event)"
                                     toastMessage="Added successfully!"></con-entity-form-content>
            <con-entity-form-content *ngIf="company.currentEditingEvent"
                                     [ownerType]="ownerType"
                                     showEditButton=true
                                     entityName="CalendarEvent"
                                     (showComments)="setCommentFlag($event)"
                                     [entity]="company.currentEditingEvent"
                                     [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                     [emptyAfterSave]="true"
                                     (afterSave)="newEventChanged($event)"
                                     toastMessage="Updated successfully!"></con-entity-form-content>
            <div *ngIf="company.currentEditingEvent">
              <div class="alert alert-info clearfix" *ngFor="let comment of company.currentEditingEvent.comments">
                <div class="float-left text-left" *ngIf="!isEditingComment(comment,company.currentEditingEvent)">
                  {{ comment.comment }} ({{ comment.language.iso639_2 }})
                </div>
                <con-entity-form-content
                  *ngIf="isEditingComment(comment, company.currentEditingEvent)"
                  entityName="CalendarEventComment"
                  [entity]="comment"
                  [fixedValues]="{ calendar_event_id: company.currentEditingEvent.id, language_id: comment.language_id }"
                  (afterSave)="commentUpdated($event, company.currentEditingEvent)"
                  (afterDelete)="commentDeleted($event, company.currentEditingEvent)"
                  toastMessage="Updated successfully!"
                ></con-entity-form-content>
                <div class="float-right">
                  <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, company.currentEditingEvent)"></con-icon-by-name>
                </div>
              </div>
              <div class="alert alert-info" *ngIf="company.currentEditingEvent.id">
                <con-entity-form-content
                  entityName="CalendarEventComment"
                  [fixedValues]="{ calendar_event_id: company.currentEditingEvent.id }"
                  [emptyAfterSave]="true"
                  (afterSave)="commentAdded($event, company.currentEditingEvent)"
                  toastMessage="Added successfully!"
                >
                </con-entity-form-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class NewEventFormComponent implements OnChanges {
  @Input() company: any; // query the backend, get company object, give it to this component via a wrapper.
  @Input() ownerType: string; // '/company/'

  public loading: boolean;
  public error: boolean;
  public missingTypes: any;
  public now: string = this.dateConverter.toEntityString(moment());
  public showNewEventFlag = false;
  public expanded = true;
  constructor(private http: HttpClient,
              public profile: ProfileService,
              private dateConverter: DateConverterService) {}

  showAll() {
    this.missingTypes.forEach(event => {
      event.expanded = true;
    });
    this.company.expanded = true;
  }
  public toggleType(event?) {
    if (event) {
      event.expanded = !event.expanded;
    } else {
      this.expanded = ! this.expanded;
    }
  }
  public toggleEdit(event?) {
    if (!event) {
      this.company.expanded = ! this.company.expanded;
    } else {
      event.expanded = ! event.expanded;
    }
  }
  showEvent(event?) {
    if (!event && (this.company.expanded)) {
      return true;
    } else if (event && event.expanded) {
      return true;
    } else {
      return false;
    }
  }
  clearEditor() {
    if (this.company.currentEditingEvent) {
      this.company.currentEditingEvent = null;
    } else {
      this.showNewEventFlag = false;
    }
  }
  setCommentFlag(flag, event?) {
    if (!event) {
      this.company.expanded = flag;
    } else {
      event.expanded = flag;
    }
  }
  getOwnerTypeUri(): string {
    const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
    return url;
  }

  getMutations(event?) {
    if (event) {
      const mutation = event.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
      return mutation;
    } else {
      const mutation = this.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
      return mutation;
    }
  }

  show() {
    if (!this.loading && !this.error && this.expanded) {
      return true;
    } else {
      return false;
    }
  }
  newEventChanged(event: any) {
    this.company.currentEditingEvent = event;
    this.company.currentEditingEvent.editingCommentsDict = {};
  }
  toggleEditOfComment(comment, currentEditingEvent) {
    if (!(comment.id in currentEditingEvent.editingCommentsDict)) {
      currentEditingEvent.editingCommentsDict[comment.id] = false;
    }
    currentEditingEvent.editingCommentsDict[comment.id] = !currentEditingEvent.editingCommentsDict[comment.id];
  }
  isEditingComment(comment, currentEditingEvent?) {
    if (currentEditingEvent) {
      return currentEditingEvent.editingCommentsDict[comment.id];
    } else {
      return false;
    }
  }
  commentAdded(comment, currentEditingEvent) {
    currentEditingEvent.comments.push(comment);
  }
  commentUpdated(comment, currentEditingEvent) {
    const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
    if (index > -1) {
      currentEditingEvent.comments[index] = comment;
    }
    this.toggleEditOfComment(comment, currentEditingEvent);
  }
  commentDeleted(comment, currentEditingEvent) {
    const index = currentEditingEvent.comments.findIndex(c => c.id === comment.id);
    if (index > -1) {
      currentEditingEvent.comments.splice(1, index);
    }
    this.toggleEditOfComment(comment, currentEditingEvent);
  }
  ngOnChanges() {
    if (this.company && this.company.id) {
      this.loading = true;
      this.http.get(ApiSettings.BASE_URL +
        this.getOwnerTypeUri() +
        this.company.id + '/' +
        CalendarSettings.MISSING_ENDPOINT)
        .subscribe(res => {
          this.missingTypes = res;
          this.showAll();
          this.loading = false;
        }, err => {
          console.log(err);
          this.error = true;
          this.loading = false;
        });
    }
  }

}
