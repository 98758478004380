<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title pb-3 pt-3">
                <h3 class="mb-0">Missing Company Events</h3>
            </div>
        </div>
    </div>
    <con-filter-missing-company-events (filterEvents)="filterEvents($event)"></con-filter-missing-company-events>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <ng-container *ngIf="!loading">
                <con-missing-events-editor
                    *ngFor="let comp of missing?.data"
                    ownerType="Company"
                    [company]="comp"
                    (afterFixed)="whenFixed($event)"
                    (afterUpdate)="updateInList($event)">
                </con-missing-events-editor>
                <con-no-data [count]="missing?.total" [message]="noDataMessage"></con-no-data>
            </ng-container>
            <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!missing?.total}">
                <ngb-pagination [collectionSize]="missing?.total"
                                [page]="missing?.current_page"
                                (pageChange)="loadPage($event)"
                                [pageSize]="missing?.per_page"
                                maxSize="10"
                                [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>