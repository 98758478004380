<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="paperclip"></con-icon-by-name>
        </div>

        <h3 class="mb-0">Press Release</h3>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="mt-15">
        <con-press-release-category-editor  (afterSave)="reloadListing($event)"></con-press-release-category-editor>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-header cursor-pointer" (click)="toggleExpansion()">
          <div class="d-flex justify-content-start">
            <con-icon-by-name iconName="building" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
            <span>Press Release Exchange Filter</span>
            <div class="card-header-toggle ml-auto">
              <con-icon-by-name iconName="angle-right" [mutations]="expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
            </div>
          </div>
        </div>

        <div class="card-body" [hidden]="!expanded">
          <div>
            <button [ngClass]="!isInstitution ? 'btn btn-primary':'btn btn-grey'" (click)="toggleInstitution(false)">Company Filter</button>
            <button [ngClass]="isInstitution ? 'btn btn-primary':'btn btn-grey'" (click)="toggleInstitution(true)">Institution Filter</button>
          </div>
            <div class="border-outline" [ngClass]="!isInstitution ? 'd-block':'d-none'">
                <con-press-release-filter (filterUpdate)="updatedFilter($event)"></con-press-release-filter>
                <div class="mt-10">
                  <small>Company Search:</small>
                  <con-entity-search-field entityName="Company"  [fixedSearchParams]=exchange_ids_val (onSelect)="navigateToCompany($event)"></con-entity-search-field>
                </div>

                <div class="recent-search p-0" *ngIf="recentCompanyList.length>0 && !isInstitution && expanded">
                  <h4>Recent Company Searches:</h4>
                  <div>
                    <div class="chip" *ngFor="let company of recentCompanyList" (click)="navigateToCompany(company)">
                      {{ company.name }}
                      <i class="fa fa-close" (click)="removeFromList(company)"></i>
                    </div>
                  </div>
                </div>
            </div>
            <div class="border-outline" *ngIf="isInstitution">
              <div class="mt-10">
                <small>Institution Search:</small>
                <con-entity-search-field entityName="Institution"   (onSelect)="navigateToInstitution($event)"></con-entity-search-field>
              </div>
              <div class="recent-search p-0" *ngIf="recentInstitutionList.length>0 && isInstitution && expanded">
                <h4>Recent Institution Searches:</h4>
                <div>
                  <div class="chip" *ngFor="let institution of recentInstitutionList" (click)="navigateToInstitution(institution)">
                    {{ institution.name }}
                    <i class="fa fa-close" (click)="removeInstitutionFromList(institution)"></i>
                  </div>
                </div>
              </div>
            </div>
         </div>


      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mt-15" *ngIf="pressReleaseQueryParams">
         <con-entity-list entityName="PressRelease" [runUpdate]="changes$"  [fixedSearchParams]=pressReleaseQueryParams  ></con-entity-list>
      </div>
    </div>
  </div>


</div>


