import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

import * as moment from 'moment-timezone';

@Component({
  selector: 'con-month-explorer',
  templateUrl: './month-explorer.component.html'
})
export class MonthExplorerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() canIndex: boolean;

  private offset = 0;
  public startDay: any;
  public _canIndex: boolean;

  private readySubscription: ISubscription;

  constructor(private profileService: ProfileService) {}

  ngOnInit() {
    this.startDay = moment().add(this.offset, 'months');

    this.readySubscription = this.profileService.whenReady().subscribe(res => {
      if (res) {
        this.generatePermissions();
      }
    });
  }

  private generatePermissions() {
    this._canIndex =
      this.canIndex === undefined
        ? this.profileService.can(
            this.profileService.getPermissionName('Task', 'index')
          )
        : this.canIndex;
  }

  ngOnChanges() {
    this.generatePermissions();
  }

  incrementOffset() {
    this.offset++;

    this.ngOnInit();
  }

  decrementOffset() {
    this.offset--;

    this.ngOnInit();
  }

  ngOnDestroy() {
    this.readySubscription.unsubscribe();
  }
}
