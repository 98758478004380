import { Component, OnInit, ViewChild, Input, Output, OnChanges } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'con-loader-task-manager',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
    @Input() isLoading = true;
    ngOnInit(): void {
    }

}
