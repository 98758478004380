import { RouterModule, Routes } from '@angular/router';

import { GuestGuard } from './guards/guest-guard.service';

import { LoginComponent } from './components/login.component';

export const authRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuestGuard]
    }
];

export const authRouting = RouterModule.forRoot(authRoutes, { relativeLinkResolution: 'legacy' });
