import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { EntitiesService } from '../../entities/services/entities.service';
import { ApiSettings, CalendarSettings } from '../../settings.class';

import { ProfileService } from '../../auth/services/profile.service';

import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component({
    selector: 'con-confirm-events',
    templateUrl: './confirm-events.component.html'
})

export class ConfirmEventsComponent implements OnChanges {
    @Input() company: any;
    @Input() ownerType: string;
    @Output() refreshList: EventEmitter<any> = new EventEmitter();

    public error: boolean;
    public loading: boolean;
    public confirmEvents: any;
    public now: string;
    public editing: any = {};
    public loaders: any = {};

    constructor(private http: HttpClient,
                public profile: ProfileService,
                private entities: EntitiesService,
                private dateConverter: DateConverterService) {}

    toggleEdit(eventId: number) {
        if (!(eventId in this.editing)) {
            this.editing[eventId] = false;
        }
        this.editing[eventId] = !this.editing[eventId];

    }

    getOwnerTypeUri(): string {
        const url = this.ownerType === 'Institution' ? '/institution/' : '/company/';
        return url;
    }
    removeFromList(eventId: number) {
        const indx = this.confirmEvents.findIndex(e => e.id === eventId);
        this.confirmEvents.splice(indx, 1);
        if (!this.confirmEvents.length) {
            this.http.put(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.CONFIRM_FIXED_ENDPOINT, {})
                     .subscribe();
        }
        this.refreshList.emit();
    }

    confirmEvent(eventId: number) {
        this.loaders[eventId] = true;

        this.entities.saveEntity('CalendarEvent', {
            id: eventId,
            revised_at: this.now
        })
        .subscribe(entity => {
            this.removeFromList(eventId);
            this.loaders[eventId] = false;
        }, error => {
            console.log(error);
            this.loaders[eventId] = false;
        });
    }
    refresh() {
        this.ngOnChanges();
    }
    ngOnChanges() {
        this.now = this.dateConverter.toEntityString(moment());
        if (this.company && this.company.id) {
            this.loading = true;
            this.http.get(ApiSettings.BASE_URL +
                            this.getOwnerTypeUri() +
                            this.company.id + '/' +
                            CalendarSettings.CONFIRM_ENDPOINT)
                    .subscribe(res => {
                         this.confirmEvents = res;
                         this.loading = false;
                     }, err => {
                         console.log(err);
                         this.error = true;
                         this.loading = false;
                     });
        }
    }
}
