import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'con-press-release-category-editor',
  templateUrl: './press-release-category-editor.component.html',
  styleUrls: ['./press-release-category-editor.component.scss']
})
export class PressReleaseCategoryEditorComponent implements OnInit, OnDestroy{
  @Input() fixedValueInput: any = null;
  @Input() fromCompanyDetail: boolean = false;
  @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
  addedPressRelease = null;
  showEdit = false;
  pressReleaseCategoryRelation = null;
  entityName = "PressRelease";
  relationName = "PressReleaseCategory";
  sub: Subscription;
  editFormExpanded = true;
  constructor(public profile: ProfileService, private service: EntitiesService) { }

  ngOnInit(): void {
  this.sub =  this.service.getEntityDescriptionByEntityName(this.entityName).subscribe((data: EntityDescription) => {
      this.pressReleaseCategoryRelation = data.getAllRelations().find((item) => item.model === this.relationName)
    })
  }

  showEditForm(addedPressRelease: any) {
    this.addedPressRelease = addedPressRelease;
    this.showEdit = true;
    this.afterSave.emit(this.addedPressRelease);

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeEditView() {
    this.showEdit = false;
  }

  getMutations() {
    const mutation = this.editFormExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
    return mutation;
  }

  resetView(data: any) {
    this.showEdit = false;
    this.afterSave.emit(true);
  }
}
