import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component({
    selector: 'con-email-editor',
    templateUrl: './email-editor.component.html'
})

export class EmailEditorComponent implements OnChanges {
    @Input() company: any;


    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    private showsForm = false;
    public fixedValues: any;

    constructor(public profile: ProfileService,
                private dateConverter: DateConverterService) {}

    ngOnChanges() {
        this.fixedValues = {
            revised_at: this.dateConverter.toEntityString(moment())
        }
    }

    companyUpdated(company: any) {
        this.showsForm = false;
        this.afterUpdate.emit(company);
    }

    toggleForm() {
        this.showsForm = !this.showsForm;
    }

    hasNoEmail() {
        return this.company.email === null;
    }

    showForm() {
        return this.company.email === null || this.showsForm;
    }
}
