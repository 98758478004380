<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
          <div class="page-title pb-3 pt-3">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link"
                    [routerLink]="[]"
                    [queryParams]="{ view: 'missing' }"
                    routerLinkActive="active">Missing</a>
                </li>
                <li class="nav-item">
                        <a class="nav-link"
                        [routerLink]="[]"
                        [queryParams]="{ view: 'revision' }"
                        routerLinkActive="active">Revision</a>
                </li>
            </ul>
            <con-static-tab (onTabChanged)="onTabSelected($event)"></con-static-tab>
          </div>
        </div>
      </div>

    <con-loader *ngIf="isLoading()"></con-loader>
    <div class="row">
        <div class="col-12" *ngIf="!isLoading()">
            <con-static-editor
            [company]="company"
            [mode]="view"
            [tabName]="tabName"
            [sectors]='sectors'
            (afterFixed)="updateCompany($event)"
            (afterUpdate)="updateCompany($event)"></con-static-editor>
        </div>
    </div>
</div>
