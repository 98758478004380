import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilService } from 'app/doc-process/sub-modules/doc-process-common/services/util.service';
import { ValidationTaskDetailTableData } from 'app/doc-process/sub-modules/doc-process-common/models/table-data';
import { FieldClass, FieldColor } from 'app/doc-process/sub-modules/kpi-and-kiid/models/field-color';
import { Subscription } from 'rxjs';
import { TableService } from 'app/doc-process/sub-modules/doc-process-common/services/table.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'con-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MaterialTableComponent<T, D extends T & ValidationTaskDetailTableData> implements OnInit {

  @Input() data: D[] = null
  @Input() columnsToHide = []
  @Input() pageSizeOptions = [15, 30, 50, 100]
  @Input() isPaginated = false
  @Input() isSortable = false
  @Input() isSelectable = false
  @Input() isClickable = false
  @Input() makeLinksPrimaryColor = false
  @Input() isEditable = false
  @Input() isExpandable = false
  @Input() hideTableHeader = false
  @Input() metaLink = false
  @Input() selectableColumns: number[] = [0]
  @Input() clickableColumns: number[] = [0]
  @Input() fieldClasses: FieldClass[] = []
  @Input() initiallySelected: Array<any>;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() onClick: EventEmitter<D> = new EventEmitter()
  @Output() onDelete: EventEmitter<D> = new EventEmitter()
  @Output() onSelected: EventEmitter<D> = new EventEmitter()
  @Output() onSelectedAll: EventEmitter<Array<D>> = new EventEmitter()
  @Output() onSelectionChange: EventEmitter<Array<T>> = new EventEmitter()

  dataSource: MatTableDataSource<D> = null

  public selection: SelectionModel<T>
  public columnsToDisplay = []
  public dataChangedSubscription: Subscription
  public expandedElement: any | null;

  get window() {
    return window
  }

  get columnsToDisplayWithExpand() {
    return [...this.columnsToDisplay, 'expand']
  }

  get fieldClass(): typeof FieldClass {
    return FieldClass
  }

  constructor(public utilService: UtilService, public dialog: MatDialog, private tableService: TableService ) {
    this.dataChangedSubscription = this.tableService.changedTableData.subscribe(dataChanged => {
      if (dataChanged) {
        this.initTable()
      }
    })
  }

  ngOnInit() {
    this.initTable()
    this.initiallySelected?.forEach(id => {
      this.toggleSelection(id)
    })
  }
  ngAfterViewInit() {
    this.initTableSortableAndPagination()
  }
  initTableSortableAndPagination() {
    if (this.isPaginated) this.dataSource.paginator = this.paginator;
    if (this.isSortable) this.dataSource.sort = this.sort;
  }
  initTable() {
    if (this.data && this.data.length) {
      this.dataSource = new MatTableDataSource<D>(this.data)
      // @ts-ignore
      this.columnsToDisplay = [...new Set(Object.keys(this.data[0]).flat())]
      this.columnsToDisplay.filter(column => column !== "expand")

      if (this.columnsToHide.length) {
        this.columnsToDisplay = this.columnsToDisplay.filter(
          column => [...this.columnsToHide, 'expandData'].indexOf(column) < 0
        )
      }
    }
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<T>(allowMultiSelect, initialSelection);
    this.initTableSortableAndPagination()
  }
  selectedOne(row) {
    this.onSelected.emit(row)
    this.emitSelectionChange()
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected == numRows;
  }
  masterToggle(index: number) {  // TODO Best of Machine Learning Content - Greatest hits Vol. 2
    this.isAllSelected() ?
        (this.selection.clear() ) :
        this.data.forEach((row: D) => {
          this.selection.select(row[Object.keys(row)[index]])
        });
    if (this.isAllSelected()) {
      this.onSelectedAll.emit(this.data.map(row => row[Object.keys(row)[index]]))
    } else {
      this.onSelectedAll.emit([])
    }
    this.emitSelectionChange()
  }
  isSelectableColumn(i) {
    return this.selectableColumns.indexOf(i) > -1
  }
  isClickableColumn(i) {
    return this.clickableColumns.indexOf(i) > -1
  }
  onRowClick(rowData: D) {
    this.onClick.emit(rowData)
  }
  onClearInput(rowData: D) {
    this.onDelete.emit(rowData)
  }

  private emitSelectionChange() {
    this.onSelectionChange.emit(this.selection.selected)
  }

  public toggleSelection(value) {
    this.selection.toggle(value)
  }
}
