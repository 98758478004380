import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, UntypedFormControl, UntypedFormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntitiesService } from 'app/entities/services/entities.service';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { ApiSettings, CalendarEventSettings } from 'app/settings.class';
import { ConfirmationPopupComponent } from 'app/shared/components/confirmation-popup.component';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityHasDependenciesModalComponent } from 'app/entities/components/views/entity-has-dependencies-modal.component';
import { sentenceCase } from 'change-case';
import { ConfirmationPopupService } from 'app/shared/services/confirmation-popup.service';

@Component({
  selector: 'con-recurring-events-modal',
  templateUrl: './recurring-events-modal.component.html',
  styleUrls: ['./recurring-events-modal.component.scss']
})
export class RecurringEventsModalComponent implements OnInit, OnDestroy {
  public readonly modalModes = {
    EDIT: 'update',
    CREATE: 'create',
    DELETE: 'delete'
  };

  @Input() public entityDetails: any;
  @Input() public entityDescription: any;
  @Input() public currentEntityValues: any;
  @Input() public mode: string;
  @Input() public relationValues: any;

  private componentDestroyed$ = new Subject<void>();
  public recurringEvents: Array<any> = [];
  public occurrenceFormControl: FormControl | null = null;
  public isAllEventsSelected = false;
  public currentEditingEvents: { [key: number]: FormGroup } = {};
  public isSaving = false;
  public isLoading = false;
  public hasCommentField = false;
  public hasAtleastOneEventSelected = true;
  public formErrors: any = { fields: { recurrences: {} }, general: [] };
  public duplicateEvents: Array<boolean> = [];
  private variableKeys = ['from_date', 'to_date', 'date_confirmed'];
  private generalKeysToDelete = CalendarEventSettings.KEYS_TO_DELETE_FROM_PAYLOAD
  private editSaveTriggered = false;
  private selectedIndexes: Array<number> = [];
  private parentDateValidationMessage = 'The from date must be greater than the from date of first event';

  constructor(
    private dateConverter: DateConverterService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private validatorService: ValidatorConversionService,
    private entityService: EntitiesService,
    private toastService: ToastrService,
    private confirmationPopupService: ConfirmationPopupService
  ) { }

  ngOnInit(): void {
    this.hasCommentField = this.entityDescription.data.fields.some((field: any) => field.key === 'comment');
    if (this.mode !== this.modalModes.CREATE) {
      this.getRecurrenceBatch();
    } else {
      this.generateRecurringEvents();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private generateRecurringEvents(startIndex: number = 0): void {
    const recurringInterval = this.getMomentAddIntervalKey(this.entityDetails.recurring_event);
    const totalOccurrences = this.entityDetails.occurrences || 1;
    const fromDate = moment(this.entityDetails.from_date);
    const toDate = this.entityDetails.to_date ? moment(this.entityDetails.to_date) : null;
    const diffBetweenDates = toDate ? toDate.diff(fromDate, 'seconds', true) : null;
    const commonData = { ...this.entityDetails };
    this.generalKeysToDelete.forEach((key) => delete commonData[key]);

    for (let i = startIndex; i < totalOccurrences; i++) {
      const newFromDate = fromDate.clone().add(i, recurringInterval);
      const newToDate = diffBetweenDates ? this.dateConverter.toEntityString(newFromDate.clone().add(diffBetweenDates, 'seconds')) : null;
      const isDateAdjusted = recurringInterval !== 'week' && fromDate.date() !== newFromDate.date();
      const recurringEvent: any = {
        ...commonData,
        from_date: this.dateConverter.toEntityString(newFromDate),
        to_date: newToDate,
        date_confirmed: this.entityDetails.date_confirmed,
        is_selected: true,
        is_edited: false,
        is_child_edited: false,
        comment: this.hasCommentField ? this.entityDetails.comment || '' : undefined,
        adjusted: isDateAdjusted
      };
      this.recurringEvents.push(recurringEvent);
    }
    this.checkForDuplicateEvents();
    this.isAllEventsSelected = this.recurringEvents.every((event) => event.is_selected);
    this.setSelectedIndexes();
  }

  private getMomentAddIntervalKey(recurringInterval: string): moment.unitOfTime.DurationConstructor {
    const intervals: { [key: string]: moment.unitOfTime.DurationConstructor } = {
      weekly: 'week',
      monthly: 'month',
      quarterly: 'quarter',
      yearly: 'year'
    };
    return intervals[recurringInterval] || 'day';
  }

  public exitModal(isConfirmed: boolean, data?: any): void {
    if (isConfirmed) {
      this.activeModal.close(data);
      return;
    }

    const hasEditedEvents = this.recurringEvents.some((event) => event.is_edited);
    if (hasEditedEvents && this.editSaveTriggered) {
      const modalData = {
        texts:  {
          title: 'Please confirm',
          text: 'If you close this modal, any edits made to the recurring events will be lost.',
          subText: 'Are you sure you want to proceed?'
        }
      }
      const modalRef = this.confirmationPopupService.showConfirmModal(modalData);
      modalRef.result.then(() => this.activeModal.dismiss(), () => { });
    } else {
      this.activeModal.dismiss();
    }
  }

  public toggleOccurrenceEdit(enableEdit: boolean): void {
    this.occurrenceFormControl = enableEdit 
      ? new FormControl(this.entityDetails.occurrences || 1, [Validators.required, Validators.min(1), Validators.max(CalendarEventSettings.CALENDAR_EVENT_MAX_RECURRING_COUNT[this.entityDetails.recurring_event])]) 
      : null;
  }

  public saveOccurrence(): void {
    if (!this.occurrenceFormControl?.valid) return;
    const newOccurrences = this.occurrenceFormControl.value;

    if (newOccurrences < this.entityDetails.occurrences) {
      this.confirmOccurrenceReduction(newOccurrences);
    } else if (newOccurrences > this.entityDetails.occurrences) {
      this.extendOccurrences(newOccurrences);
    } else {
      this.toggleOccurrenceEdit(false);
    }
  }

  private confirmOccurrenceReduction(newOccurrences: number): void {
    const largestEditedEventIndex = this.getLargestEditedEventIndex();
    if (largestEditedEventIndex !== null && largestEditedEventIndex >= newOccurrences) {
      const modalData = {
        texts: {
          title: 'Please confirm',
          text: 'Changing the number of occurrences will remove any edited events beyond the new occurrence count.',
          subText: 'Are you sure you want to proceed?'
        }
      }
      const modalRef = this.confirmationPopupService.showConfirmModal(modalData);
      modalRef.result.then(() => this.reduceOccurrences(newOccurrences), () => { });
    } else {
      this.reduceOccurrences(newOccurrences);
    }
  }

  private reduceOccurrences(newOccurrences: number): void {
    this.recurringEvents.splice(newOccurrences);
    this.entityDetails.occurrences = newOccurrences;
    this.postOccurrenceEditActions();
  }

  private extendOccurrences(newOccurrences: number): void {
    const startIndexOfNewEvents = this.recurringEvents.length;
    this.entityDetails.occurrences = newOccurrences;
    this.generateRecurringEvents(startIndexOfNewEvents);
    this.postOccurrenceEditActions();
  }

  private postOccurrenceEditActions(): void {
    this.isAllEventsSelected = this.recurringEvents.every((event) => event.is_selected);
    Object.keys(this.currentEditingEvents).forEach((key) => {
      const eventIndex = parseInt(key, 10);
      if (eventIndex >= this.entityDetails.occurrences) {
        delete this.currentEditingEvents[eventIndex];
        delete this.formErrors.fields.recurrences[eventIndex];
      }
    });
    Object.keys(this.formErrors.fields.recurrences).forEach((key) => {
      const eventIndex = parseInt(key, 10);
      if (eventIndex >= this.entityDetails.occurrences) {
        delete this.formErrors.fields.recurrences[eventIndex];
      }
    });
    this.toggleOccurrenceEdit(false);
  }

  public selectEvent(eventIndex?: number): void {
    if (eventIndex !== undefined) {
      this.toggleSingleEventSelection(eventIndex);
    } else {
      this.toggleAllEventsSelection();
    }
    this.hasAtleastOneEventSelected = this.recurringEvents.some((event) => event.is_selected);
  }

  private toggleSingleEventSelection(eventIndex: number): void {
    if([this.modalModes.EDIT, this.modalModes.DELETE].includes(this.mode) && this.recurringEvents[eventIndex]?.id == this.currentEntityValues?.id) return;
    this.recurringEvents[eventIndex].is_selected = !this.recurringEvents[eventIndex].is_selected;
    this.isAllEventsSelected = this.recurringEvents.every((event) => event.is_selected);
    this.setSelectedIndexes();
  }

  private toggleAllEventsSelection(): void {
    this.recurringEvents.forEach((event) => {
      if(this.mode === this.modalModes.CREATE || (this.mode !== this.modalModes.CREATE && event?.id !== this.currentEntityValues?.id)) {
        event.is_selected = !this.isAllEventsSelected;
      }
    });
    this.isAllEventsSelected = this.recurringEvents.every((event) => event.is_selected);
  }

  public toggleEventEdit(eventIndex: number): void {
    if (this.mode === this.modalModes.CREATE && eventIndex === 0 || this.mode === this.modalModes.EDIT && this.recurringEvents[eventIndex]?.id === this.currentEntityValues?.id) return;
    if (this.currentEditingEvents[eventIndex]) {
      delete this.currentEditingEvents[eventIndex];
    } else {
      this.currentEditingEvents[eventIndex] = this.getEventEditFormGroup(eventIndex);
    }
  }

  private getEventEditFormGroup(eventIndex: number): FormGroup {
    const formControls = {
      from_date: new UntypedFormControl(this.recurringEvents[eventIndex].from_date || '', this.findValidationRules('from_date')),
      to_date: new UntypedFormControl(this.recurringEvents[eventIndex].to_date || '', this.findValidationRules('to_date')),
      date_confirmed: new UntypedFormControl(this.recurringEvents[eventIndex].date_confirmed, this.findValidationRules('date_confirmed'))
    };

    if (this.hasCommentField) {
      formControls['comment'] = new UntypedFormControl(this.recurringEvents[eventIndex].comment || '', this.findValidationRules('comment'));
    }

    return new UntypedFormGroup(formControls, { validators: [this.validatorService.dateRangeValidator(false), this.parentDateValidator(eventIndex)] });
  }

  private findValidationRules(controlName: string): any[] {
    const field = this.entityDescription.data.fields.find((field: any) => field.key === controlName);
    return field ? field.rules.map((rule: string) => this.validatorService.getValidatorByString(rule)).filter(Boolean) : [];
  }

  public saveRecurringEvent(eventIndex: number): void {
    const formGroup = this.currentEditingEvents[eventIndex];
    formGroup.updateValueAndValidity();
    if (formGroup.invalid) return;

    const updatedEvent = {
      ...this.recurringEvents[eventIndex],
      from_date: formGroup.value.from_date,
      to_date: formGroup.value.to_date,
      date_confirmed: formGroup.value.date_confirmed,
      is_edited: true,
      is_child_edited: true,
      comment: this.hasCommentField ? formGroup.value.comment : undefined
    };

    this.recurringEvents[eventIndex] = updatedEvent;
    this.toggleEventEdit(eventIndex);
    this.checkForDuplicateEvents();
    this.editSaveTriggered = true;
    if (!this.recurringEvents[eventIndex].is_selected) {
      this.selectEvent(eventIndex);
    }
    if (this.formErrors?.fields?.recurrences[eventIndex]) {
      this.formErrors.fields.recurrences[eventIndex] = [];
    }
    if(eventIndex === 0) {
      this.initialParentDateValidator();
    }
  }

  private parentDateValidator(eventIndex: number): any {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromDate = moment(control.get('from_date')?.value);
      const parentFromDate = this.mode === this.modalModes.CREATE 
        ? moment(this.entityDetails.from_date) 
        : this.mode === this.modalModes.EDIT && eventIndex === 0 ? null : moment(this.recurringEvents[0].from_date);
      return parentFromDate && fromDate.isSameOrBefore(parentFromDate) ? { parent_date_error: true } : null;
    };
  }

  private initialParentDateValidator(): any {
    const parentFromDate = moment(this.recurringEvents[0].from_date);
    this.recurringEvents.forEach((event, index) => {
      const fromDate = moment(event.from_date);
      this.formErrors.fields.recurrences[index] = this.formErrors?.fields?.recurrences[index]?.filter((error: string) => error !== this.parentDateValidationMessage);
      if (fromDate.isSameOrBefore(parentFromDate) && index !== 0) {
        if(this.formErrors.fields.recurrences[index]) {
          this.formErrors.fields.recurrences[index].push(this.parentDateValidationMessage);
        } else {
          this.formErrors.fields.recurrences[index] = [this.parentDateValidationMessage];
        }
      }
    });
  }

  private getLargestEditedEventIndex(): number | null {
    return this.recurringEvents.reduceRight((acc, item, index) => acc === null && item.is_edited ? index : acc, null) as number | null;
  }

  private getPayloadForApi(mode: 'create' | 'update' | 'validate' | 'delete'): any {
    const selectedEvents = this.recurringEvents
      .filter(event => ( mode !== 'validate' ? event.is_selected : true ))
      .map(event => this.prepareEventPayload(event));
    const filteredEvents = selectedEvents.filter(event => event !== null);
    return mode === this.modalModes.DELETE 
      ? filteredEvents.join(',') 
      : { recurrences: selectedEvents };
  }

  private prepareEventPayload(event: any): any {
    if(this.mode === this.modalModes.DELETE) {
      return event?.id !== this.currentEntityValues?.id ? event.id : null;
    };
    const fullPayload = { ...this.entityDetails, ...event };
    if (this.mode === this.modalModes.EDIT && !fullPayload.is_child_edited) {
      this.variableKeys.forEach(prop => delete fullPayload[prop]);
    }
    if (this.hasCommentField && fullPayload.comment == '') {
      delete fullPayload.comment
      delete fullPayload.language_id
    }
    this.generalKeysToDelete.forEach(prop => delete fullPayload[prop]);
    return fullPayload;
  }

  public onSubmitRecurringEvents(forced: boolean = false): void {
    if(this.hasAnyFormErrors()) return;
    this.formErrors = null;
    this.isSaving = true;
    let apiCall;
    if (this.mode === this.modalModes.EDIT) {
      apiCall = this.entityService.editRecurringEvents(this.getPayloadForApi('update'), this.currentEntityValues?.id);
    } else if (this.mode === this.modalModes.DELETE) {
      const payload = this.getPayloadForApi('delete');
      if(!payload) {
        this.eventIndividualDelete();
        return
      } else {
        apiCall = this.entityService.deleteRecurringEvents(this.currentEntityValues?.id, this.getPayloadForApi('delete'), forced);
      }
    } else {
      apiCall = this.entityService.saveRecurringEvents(this.getPayloadForApi('create'));
    }
    if(apiCall) {
      apiCall.pipe(takeUntil(this.componentDestroyed$)).subscribe((response) => {
        this.isSaving = false;
        this.toastService.success(`Recurring events ${this.mode}d successfully`);
        this.exitModal(true, response);
      }, (error) => {
        if (error.isValueError()) {
          this.handleErrorResponse(error.data)
        } else if(error.isHasDependentEntitiesError()) {
          const modalRef = this.modalService.open(EntityHasDependenciesModalComponent, { centered: false, scrollable: true, size: 'lg' });
          modalRef.componentInstance.entities = error.getData().entities;
          modalRef.componentInstance.isBulk = true;
          modalRef.componentInstance.entityName = sentenceCase(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY);
          modalRef.result.then(() => {
            this.onSubmitRecurringEvents(true);
          }, () => { });
  
        } else {
          this.toastService.error(ApiSettings.INTERNAL_SERVER_ERROR, `Recurring events`);
        }
        this.isSaving = false;
      });
    }
  }

  private handleErrorResponse(error: any): void {
    this.formErrors = { fields: { recurrences: {} }, general: [] };
    const recurrencesGeneralRegex = /^recurrences\.(\d+)(\..*)?$/;
    const recurrencesReplaceRegex = /recurrences\.(\d+)(\..*?)?/g;

    Object.keys(error.fields).forEach(key => {
      const match = key.match(recurrencesGeneralRegex);
      if (match) {
        const eventIndex = this.selectedIndexes[parseInt(match[1], 10)];
        const field = match[2]?.substring(1);
        this.formErrors.fields.recurrences[eventIndex] = this.formErrors.fields.recurrences[eventIndex] || [];
        const errors = error.fields[key].map((error: string) => error.replace(recurrencesReplaceRegex, field ? '' : 'event'));
        this.formErrors.fields.recurrences[eventIndex].push(...errors);
      } else {
        this.formErrors.general.push(...error.fields[key]);
      }
    });
    this.formErrors.general.push(...error.general);
  }

  private checkForDuplicateEvents(): void {
    if (this.mode !== 'create') return;
    this.entityService.checkDuplicateRecurringEvents(this.getPayloadForApi('validate')).pipe(takeUntil(this.componentDestroyed$)).subscribe((response) => {
      this.duplicateEvents = response.message as Array<boolean>;
    });
  }

  private getRecurrenceBatch(): void {
    this.isLoading = true;
    this.entityService.searchEntities(
      CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY,
      { recurrence_batch: this.currentEntityValues.recurrence_batch },
      { page: 1, order_by: 'from_date', order_asc: true, per_page: CalendarEventSettings.CALENDAR_EVENT_MAX_RECURRING_COUNT['weekly'] }
    ).pipe(takeUntil(this.componentDestroyed$)).subscribe((response) => {
      this.generateExistingEventBatch(response.data);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }

  private generateExistingEventBatch(recurrenceBatch: Array<any>): void {
    const lowerFromDate = this.entityDetails.from_date && (moment(this.entityDetails.from_date).isBefore(moment(this.currentEntityValues.from_date)))
      ? moment(this.entityDetails.from_date)
      : moment(this.currentEntityValues.from_date);

    this.recurringEvents = recurrenceBatch.map((event: any) => ({
      from_date: this.getUpdatedValue(event, 'from_date'),
      to_date: this.getUpdatedValue(event, 'to_date'),
      date_confirmed: this.getUpdatedValue(event, 'date_confirmed'),
      is_edited: this.needToMarkForSubmit(event, lowerFromDate),
      is_selected: this.needToMarkForSubmit(event, lowerFromDate),
      is_child_edited: this.isChildEdited(event),
      id: event.id
    }));

    this.isAllEventsSelected = this.recurringEvents.every((event) => event.is_selected);
    this.setSelectedIndexes();
    this.initialParentDateValidator();
  }

  private getUpdatedValue(event: any, key: string): any {
    return event.id === this.currentEntityValues?.id ? this.entityDetails[key] !== undefined ? this.entityDetails[key] : event[key] : event[key];
  }

  private needToMarkForSubmit(event: any, lowerFromDate: moment.Moment): boolean {
    return (moment(event.from_date).isSameOrAfter(lowerFromDate) && event.id !== this.currentEntityValues?.id) || event.id === this.currentEntityValues?.id || this.mode === this.modalModes.DELETE;
  }

  private isChildEdited(event: any): boolean {
    return event.id === this.currentEntityValues?.id && this.variableKeys.some(key => this.entityDetails[key] !== undefined);
  }

  private setSelectedIndexes(): void {
    this.selectedIndexes = this.recurringEvents.reduce((acc, item, index) => {
      if (item.is_selected) {
        acc.push(index);
      }
      return acc;
    }, []);
  }

  private eventIndividualDelete(): void {
    this.entityService.deleteEntity(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY, this.currentEntityValues).pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.toastService.success(`Recurring event deleted successfully`);
      this.exitModal(true, { id: this.currentEntityValues.id });
      this.isSaving = false;
    }, (error) => {
      if (error.data && error.data.message) {
        this.toastService.warning(error.data.message, 'Delete');
      } else if (!error.hasOwnProperty('type')) {
        this.toastService.error(ApiSettings.INTERNAL_SERVER_ERROR, `Recurring events`);
      }
      this.isSaving = false;
    });
  }

  private hasAnyFormErrors(): boolean {
    let hasErrors = false;
    if(this.formErrors) {
      hasErrors = Object.keys(this.formErrors?.fields?.recurrences).some((key) => this.formErrors?.fields?.recurrences[key]?.length > 0);
    }
    return hasErrors;
  }
}
