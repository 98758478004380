import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyReportsService} from '../../company-reports/services/company-reports.service';
import {HttpClient} from '@angular/common/http';
import {FileTokenService} from '../../auth/services/file-token.service';
import {take} from 'rxjs/operators';
import {ApiSettings} from '../../settings.class';

@Component({
  selector: 'con-list-radio-selection',
  templateUrl: './list-radio-selection.component.html',
  styleUrls: ['./list-radio-selection.component.scss']
})
export class ListRadioSelectionComponent implements OnInit {
  @Input() data = null;
  @Output() selectedItem = new EventEmitter<any>();
  @Output() selectedUrl = new EventEmitter<any>();
  selectedReportFile = null;
  public fileToken = null;
  constructor(private http: HttpClient, private fileTokenService: FileTokenService) { }

  ngOnInit(): void {
    this.fileTokenService.getToken().pipe(take(1)).subscribe((tokenObject: any) => {
      this.fileToken = tokenObject.token;
    })
  }

  setSelectedReportFile(object: any) {
    this.selectedReportFile = object;
    this.selectedUrl.emit(this.getFileUrl(this.selectedReportFile));
  }

  emitSelection(){
    this.selectedItem.emit(this.selectedReportFile);
  }

  getFormattedKeys(key: string) {
    return (key as string).replace('_', ' ');
  }

  getFileUrl(object: any) : string{
    if(object) {
      return ApiSettings.BASE_URL + `/ml_directory_file_preview/${object?.doc_id}?token=${this.fileToken}&doc_format=${object?.doc_format}`;
    }
    return '';
  }
}
