import { Injectable, OnDestroy } from '@angular/core';

import { ReplaySubject ,  Observable ,  SubscriptionLike as ISubscription, of } from 'rxjs';

import { FrontendSettings } from './settings.class';

const LAYOUT_STORE_KEY = 'CONNECTOR_LAYOUT';

@Injectable()
export class LayoutService implements OnDestroy {
    private viewsInToolbar$: ReplaySubject<any> = new ReplaySubject<any>(1);
    private viewsInToolbar: any;
    private viewsSubscription: ISubscription;

    constructor() {
        const current = this.readCurrentViews();

        this.pushNewViews(current);

        this.viewsSubscription = this.viewsInToolbar$
                                      .subscribe(this.storeCurrentViews);
    }

    private storeCurrentViews(views: any) {
        const res = [];
        views.forEach(v => res.push(v.name));
        localStorage.setItem(LAYOUT_STORE_KEY, JSON.stringify(res))
    }

    private readCurrentViews() {
        let current;
        try {
            current = localStorage.getItem(LAYOUT_STORE_KEY);
            current = JSON.parse(current);
            if (current.constructor !== Array) {
                current = [];
            }
        } catch (e) {
            current = [];
        }

        return current;
    }

    private pushNewViews(current) {
        const res = [];
        current.forEach(view => {
            const v = this.getViewByName(view);
            if (v) {
                res.push(v);
            }
        });
        this.viewsInToolbar = res;
        this.viewsInToolbar$.next(this.viewsInToolbar);
    }

    public addViewToToolbar(view: any): void {
        const v = this.getViewByName(view.name);
        if (v) {
            this.viewsInToolbar.push(v);
            this.viewsInToolbar$.next(this.viewsInToolbar);
        }

    }

    public removeViewFromToolbar(view: any): void {
        const v = this.getViewByName(view.name);
        if (v) {
            this.viewsInToolbar = this.viewsInToolbar.filter(v2 => v2.name !== v.name);
            this.viewsInToolbar$.next(this.viewsInToolbar);
        }
    }

    public syncOrder(views: any) {
        this.viewsInToolbar = [];
        views.forEach(view => {
            const v = this.getViewByName(view.name);
            if (v) {
                this.viewsInToolbar.push(v);
            }
        });
        this.viewsInToolbar$.next(this.viewsInToolbar);
    }

    public viewIsInToolbar(name: string) {
        return this.viewsInToolbar.findIndex(view => view.name === name) > -1;
    }

    public getViewByName(name: string) {
        return FrontendSettings.VIEWS.find(view => view.name === name);
    }

    public getViewsInToolbar() {
        return this.viewsInToolbar$.asObservable();
    }

    public getViews() {
        return of(FrontendSettings.VIEWS);
    }

    ngOnDestroy() {
        this.viewsSubscription.unsubscribe();
    }
}
