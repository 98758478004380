<div class="sidebar">
    <nav class="nav flex-column">
        <a class="nav-link"
        [routerLink]="['company_tier']"
        routerLinkActive="active">
            Company Tiers
        </a>
        <a class="nav-link"
        [routerLink]="['company_universe']"
        routerLinkActive="active">
            Company Universes
        </a>
        <a class="nav-link"
        [routerLink]="['institution_tier']"
        routerLinkActive="active">
            Institution Tiers
        </a>
        <a class="nav-link"
        [routerLink]="['institution_universe']"
        routerLinkActive="active">
            Institution Universes
        </a>
    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
