<div>
    <con-not-allowed-alert *ngIf="!canView"></con-not-allowed-alert>
    <div *ngIf="canView">
        <con-tier-institution-editor
            [result]="result"
            [tierId]="tierId"
            [canRemoveManger]="false"
            [showExclude]="true"
            [showIgnore]="true"
            [fetching]="loading"
            (institutionUpdated)="updateInstitution($event)"
            (institutionIgnored)="removeInstitution($event)"
            (institutionExcluded)="removeInstitution($event)"
            (pageChanged)="loadPage($event)"
            (termsChanged)="searchChanged($event)"
        ></con-tier-institution-editor>
    </div>
</div>
