<div>
  <div>
    <small>Filter by exchange</small>
    <con-entity-search-field
      entityName="Exchange"
      (onSelect)="addExchange($event)"
    ></con-entity-search-field>
    <div>
      <button type="button" class="btn btn-sm btn-outline-info mr-1 adjust-margin" *ngFor="let exchange of exchanges" (click)="removeExchange(exchange)" placement="top" ngbTooltip="Remove from Filter">
        {{ exchange.name }}
      </button>
      <div *ngIf="exchanges.length"><a  class="btn-link cursor-pointer" (click)="removeAllExchanges()"><small>Clear All Exchanges</small></a></div>
    </div>
    <div>
      <small>Exchange Presets</small>
    </div>
    <button type="button" class="btn btn-sm btn-outline-info mr-1"  placement="top" ngbTooltip="Toggle Filter"
            *ngFor="let preset of presetExchanges | keyvalue" (click)="addExchange(preset)" [ngClass]="{'highlight-preset': addedToExchangeFilter(preset)}">
      {{preset.key}}
    </button>
  </div>

</div>
