
<con-loader *ngIf="loading"></con-loader>
<div *ngIf="reportButtons" class="input-group input-group-search" [ngClass]="{'active':search}">
  <div class="input-group-prepend" (click)="focusInput();" [ngbTooltip]="search ? 'Close Search' : 'Search'" placement="right" container="body">
    <span class="input-group-text"><con-icon-by-name iconName="search"></con-icon-by-name></span>
  </div>
  <input *ngIf="search" #search autofocus type="text" class="form-control" placeholder="Search" aria-label="Search" [(ngModel)]="searchInput" (ngModelChange)="searchReport($event)">
</div>
<nav class="nav entity-list">
  <ng-container *ngFor="let report of reportButtons">
      <a class="nav-link side-menu-link report-side-menu-item">
        <div (click)="goToReport(report)" class="pull-left" [ngClass]="report?.report_type === 'interim' ? 'interim-shift': ''" >{{report.report_type}}, {{report.report_date | date:'yyyy-MM-dd'}}</div>
        <div *ngIf="report && report.id" class="pull-right">
          <con-icon-by-name [ngClass]="report?.locked ? 'lock-set': ''" [iconName]="report?.locked ? 'lock': 'unlock'" ></con-icon-by-name>
        </div>
      </a>
  </ng-container>
</nav>
