import {Component} from '@angular/core';
import {ProfileService} from '../../../auth/services/profile.service';

@Component({
  selector: 'con-add-new-company-report',
  templateUrl: './add-new-company-report.component.html',
  styleUrls: ['./add-new-company-report.component.scss']
})
export class AddNewCompanyReportComponent {
  entityName = 'CompanyReport';
  constructor() {
  }
}
