
<div class="row">
    <div class="col-12">
        <select class="custom-select col-2 mb-3" type="text" ng-model="dropdownOptions" (change)="requestAction($event.target.value)">
            <option *ngFor="let option of dropdownOptions" value={{option}}>
                {{option}}
            </option>
        </select>
        <div class="card">
            <con-loader-task-manager *ngIf="isLoading"></con-loader-task-manager>
            <div *ngIf="!isLoading" class="card-header d-flex justify-content-start">
                <div class="mr-1">
                    <con-icon-by-name iconName="tasks"></con-icon-by-name>
                </div>
                Symbols
            </div>
            <div *ngIf="!isLoading" class="card-body">
                <con-table 
                    [withHeader]="true"
                    [canCancelData]="true"
                    amountOfElementsPerPage=100
                    [isPaginated]="true"
                    [hasSearch]="true"
                    specialConditionForCancellingData="EditOnlyNuSymbols"
                    [tableHeadData]="symbolsTableHeaderData" 
                    [tableBodyData]="symbolsTableBodyData"
                    (editObject)="setSymbol($event); requestAction('getSymbolSpec'); open(content)"
                >
            </con-table>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit task</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <con-editor *ngIf="!updateSymbolSpecLoading && !updateSymbolSpecResponseMessage" (onChange)="onConfigChange($event)" [data]="symbolSpec"></con-editor>
            <div *ngIf="!updateSymbolSpecLoading && updateSymbolSpecResponseMessage">{{updateSymbolSpecResponseMessage}}</div>
            <con-loader-task-manager *ngIf="updateSymbolSpecLoading && !updateSymbolSpecResponseMessage"></con-loader-task-manager>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button type="button" [disabled]="updateSymbolSpecLoading || updateSymbolSpecResponseMessage" class="btn btn-primary" (click)="requestAction('updateSymbolSpec');">Save</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Cancel')" >{{updateSymbolSpecResponseMessage ? 'Go back' : 'Cancel'}}</button>
        </div>
    </div>
</ng-template>