<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Production</h3>
            </div>
        </div>
    </div>
    <div class="row mb-3 sticky-header mozenda-menu">
        <div class="col-12">
            <div class="row">
                <div class="col-2 filter-options">
                    <mat-form-field >
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="filterModels.selectedTypeFilter">
                            <mat-option *ngFor="let type of filterTypes" 
                                    [value]="type.value" 
                                    (click)="selectTypeFilter(type)">
                                        {{type.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-2 filter-options">
                    <mat-form-field >
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="filterModels.selectedStatusFilter">
                            <mat-option *ngFor="let status of filterStatuses" 
                                    [value]="status.value" 
                                    (click)="selectStatusFilter(status)">
                                        {{status.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-3 filter-options pull-left">
                    <mat-form-field class="sort">
                    <mat-label>Sort by</mat-label>
                    <mat-select [(ngModel)]="filterModels.selectedSort">
                        <mat-option *ngFor="let sort of sortTypes" 
                            [value]="sort.value" 
                            (click)="selectSort(sort)">
                                {{sort.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="filter-icon pull-right">
                    <span  class="mozenda-sort">
                        <i class="fa fa-sort-asc fa-lg" 
                            [ngClass]="{'active': sortOrder === 'asc'}"
                            (click)="setSortOrder('asc')"></i>
                        <i class="fa fa-sort-desc fa-lg" 
                            [ngClass]="{'active': sortOrder === 'desc'}"
                            (click)="setSortOrder('desc')"></i>
                    </span>
                </div>
                </div>
                <div class="mozenda-search">
                    <mat-form-field class="example-form-field">
                    <mat-label>Search by agent</mat-label>
                    <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="search()">
                    <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''; search()">
                      <mat-icon>close</mat-icon>
                    </button>
                    </mat-form-field>
                    <div class="filter-icon pull-right" ngbTooltip="Reset filters">
                        <span (click)="resetFilters()">
                            <i class="fa fa-undo reset"></i>
                        </span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <div class="inner-scroll" *ngIf="!loading">
                <div >
                    <con-loader *ngIf="loading"></con-loader>
                    <div *ngIf="!loading">
                        <div *ngFor="let delivery of production.data; let index = index">
                            <div>
                                <div class="card mb-2">
                                    <div class="card-header" (click)="toggleType(delivery)"> 
                                        <div class="d-flex justify-content-start align-items-center">
                                            <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name> 
                                            {{ delivery.project}} - Agent Id # {{delivery.agent_id}} 
                                            <div class="card-header-toggle-individual ml-auto">
                                                {{delivery.created_at}}
                                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(delivery)"></con-icon-by-name> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-4 col-6" *ngIf="!delivery.hide">
                                        <div class="card-header"> 
                                            <div class="d-flex justify-content-start align-items-center">
                                                <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name>Inspect
                                                <div class="card-header-toggle-individual ml-auto">
                                                </div>
                                            </div>
                                        </div>
                                        <con-object-inspector [object]="delivery.data">
                                            
                                        </con-object-inspector>                
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                
                <con-no-data [count]="production?.total" [message]="noDataMessage"></con-no-data>
            </div>
            <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!production?.total}">
                <ngb-pagination [collectionSize]="production?.total"
                                [page]="production?.current_page"
                                (pageChange)="loadPage($event)"
                                [pageSize]="production?.per_page"
                                maxSize="10"
                                [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>