import { Injectable } from '@angular/core';
import {
  ShareClass,
  InstanceDataValue,
  InstanceDataKiid,
} from '../models/Typings';

@Injectable({
  providedIn: 'root'
})
export class KiidService {
  private fieldsForShareClasses: string[];

  constructor() { }

  public addNewShareClass(instanceData) {
    function getNewInstanceDataValue(): InstanceDataValue {
      const InstanceDataValue: InstanceDataValue = {
        value: null,
        ml_conf: null,
        altered: null,
        confirmed: null
      };

      return InstanceDataValue
    }

    let newShareClass = {}

    for (const field of this.fieldsForShareClasses) {
      newShareClass[field] = getNewInstanceDataValue()
    }

    delete newShareClass?.['ShareClass']
    delete newShareClass?.['ShareCurrency']

    instanceData.ShareClasses.push(newShareClass as ShareClass)
  }

  saveShareClasses(instanceData: InstanceDataKiid) {
    const newInstanceData = JSON.parse(JSON.stringify(instanceData))
    this.fieldsForShareClasses = Object.keys(newInstanceData.ShareClasses[0]);
  }
}
