<div *ngIf="company">
    <div class="d-flex justify-content-start sticky-header py-2">
        <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3>
        <div class="chip chip-inverse ml-auto">
            <con-avatar *ngIf="company.manager?.id" [userId]="company.manager?.id" [size]="32"></con-avatar>
            {{managerName}}
        </div>
    </div>
    <div class="card mb-2">
        <div class="card-header">
            <con-icon-by-name iconName="hashtag" [mutations]="['fw']"></con-icon-by-name> Share Counts
        </div>
        <div class="card-body">
            <con-entity-list entityName="ShareCount" originEntityName="company" relation="shareCounts" [entityId]="company.id">

            </con-entity-list>
        </div>
    </div>
</div>