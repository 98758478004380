import { RouterModule, Routes } from '@angular/router';

import { ShareholdersComponent } from './layout/shareholders.component';
import { ShareholdersOutdatedComponent } from './layout/shareholders-outdated.component';
import { ShareholdersMissingComponent } from './layout/shareholders-missing.component';
import { ShareholdersStartComponent } from './layout/shareholders-start.component';
import { ShareholdersCompanyComponent } from './layout/shareholders-company.component';

import { PageNotFoundComponent } from '../page-not-found.component';
import { NotAuthorizedComponent } from '../not-authorized.component';

export const shareholdersRoutes: Routes = [
    {
        path: '',
        component: ShareholdersComponent,
        canActivate: [],
        children: [
            {
                path: '',
                component: ShareholdersStartComponent
            }, {
                path: 'error',
                component: PageNotFoundComponent
            }, {
                path: 'auth',
                component: NotAuthorizedComponent
            }, {
                path: 'missing',
                component: ShareholdersMissingComponent
            }, {
                path: 'outdated',
                component: ShareholdersOutdatedComponent
            }, {
                path: ':id',
                component: ShareholdersCompanyComponent
            }
        ]
    }
];

export const shareholdersRouting = RouterModule.forChild(shareholdersRoutes);
