<div *ngIf="dataReady">
<div *ngIf="relation.name === 'companyReportFiles'">
  <con-ml-directory-preview [displayMLPreviewUrl]="previewMLReportUrl"  (closePreviewFile)="setPreviewMLReportUrlEmpty($event)"> </con-ml-directory-preview>
</div>

<con-entity-list *ngIf="canShow && !isEmployeeFigures" [entityName]="relation.model" [entityId]="entity.id" [relation]="relation.name"
    [originEntityName]="relation.owner" [actions]="getActions()" [runUpdate]="changes$" [loaders]="loaders" [customCompanyReportFileView]="customCompanyReportFileView"
    (onAction)="changeInList($event)" (onResponse)="setResponseListData($event)" (selectedUrl)="previewUrl($event)">
</con-entity-list>

<div class="alert alert-danger" *ngFor="let error of errors?.general">
    {{ error }}
</div>
<div [ngClass]="isEmployeeFigures ? '' : 'card-body'">
    <div #ReportEdit>
        <div *ngIf="editEntity" class="card">
            <div class="card-header">
                <div class="d-flex justify-content-start align-items-center">
                    <con-icon-by-name iconName="group" [mutations]="['fw']"></con-icon-by-name>Edit
                    <div class="card-header-toggle-individual ml-auto">
                        <con-icon-by-name ngbTooltip="Remove Window" iconName="remove" (click)="closeEdit()">
                        </con-icon-by-name>
                        <!-- <con-icon-by-name iconName="angle-right" [mutations]="getMutations(shareholder)"></con-icon-by-name>  -->
                    </div>
                </div>
            </div>
            <div class="p-4">
                <con-entity-form [entity]="editEntity"
                    [reloadEditData]="reloadEditData"
                    removeRelations="true"
                    emptyAfterSave="true"
                    [fromReports]="fromReports"
                    [entityName]="inverseRelation.owner"
                    [parentCompany]="getCompany()"
                    [fixedValues]="fixedChildValues[relations.getUniqueNameForRelation(inverseRelation)]"
                    [canEdit]="canEdit && !relation.disabled" (afterSave)="appendToRelation(inverseRelation, $event)">
                </con-entity-form>
            </div>
        </div>
    </div>
    <div *ngIf="!isLocked  && !isCalculated">
        <h5 *ngIf="!isEmployeeFigures">Include:</h5>
        <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let worker of workers">
                <con-icon-by-name [iconName]="relatedEntityDescriptions[worker.type].getIcon()"></con-icon-by-name>
                {{ relatedEntityDescriptions[worker.type].getPrimaryString(worker.entity) }}
                <con-loader *ngIf="worker.loading"></con-loader>
            </li>
        </ul>
        <con-pivot-form *ngIf="!inverseRelation.required"
            [relation]="inverseRelation"
            [object]="pivotFormObject"
            (afterSave)="addToRelation(inverseRelation, $event)"
            (afterUpdate)="updatePivotData(inverseRelation, $event)"
            (onAbort)="resetPivotForm()"
            [disabled]="!canEdit || relation.disabled"
            [fixedSearchParams]="fixedSearchParams[relations.getUniqueNameForRelation(inverseRelation)]"
            [errors]="errors"
            [parentEntity]="entity">
        </con-pivot-form>
        <con-report-file-editor *ngIf="inverseRelation.required && inverseRelation.owner === 'CompanyReportFile'" context="REPORT_DETAIL_REPORT_FILE"
                                [companyReportFile]="entity"
                                (afterSave)="appendToRelation(null, $event)"
        ></con-report-file-editor>
        <con-entity-form *ngIf="inverseRelation.required && inverseRelation.owner !== 'CompanyReportFile'"
            removeRelations="true"
            emptyAfterSave="true"
            [entityName]="inverseRelation.owner"
            [fromReports]="fromReports"
            [parentCompany]="getCompany()"
            [fixedValues]="fixedChildValues[relations.getUniqueNameForRelation(inverseRelation)]"
            [canEdit]="canEdit && !relation.disabled"
            (afterSave)="appendToRelation(inverseRelation, $event)">
        </con-entity-form>
    </div>

</div>


<div class="bg-white" *ngIf="canShow && isEmployeeFigures">
  <con-entity-list entityName="CompanyEmployee" [entityId]=parentCompany relation="companyEmployees"
                   originEntityName="CompanyEmployees" [runUpdate]="changes$" [loaders]="loaders"
                   [isEmployeeFigures]="true"
                   (onAction)="changeInList($event)" (onResponse)="setResponseListData($event)">
  </con-entity-list>
</div>

</div>
