<div class="row">
    <div class="col-12">
        <div class="card mb-2">
            <div class="card-header">
                Owner
            </div>
            <con-loader *ngIf="loadingUsers"></con-loader>
            <div class="card-body" *ngIf="!loadingUsers">
                <div class="chip chip-link" [ngClass]="{ 'chip-primary': getOwner() === 'any' }" (click)="setOwner('any')">
                    <con-icon-by-name iconName="user"></con-icon-by-name> Any
                </div>
                <div class="chip chip-link mr-1" *ngFor="let userId of users | keys" [ngClass]="{ 'chip-primary': getOwner() === userId }" (click)="setOwner(userId)">
                    <con-avatar [userId]="userId" size="32" [showTooltip]="false"></con-avatar> {{ users[userId].first_name }} {{ users[userId].last_name }}
                </div>
            </div>
        </div>
        <div class="card mb-2">
                <div class="card-header">
                    Assigned to
                </div>
                <div class="card-body" *ngIf="!loadingUsers">
                    <div class="chip chip-link" [ngClass]="{ 'chip-primary': getAssignedToType() == 'any' && getAssignedToId() == 'any' }" (click)="setAssignedTo('any', 'any')">
                        Any
                    </div><br>
                    <strong>Users:</strong>
                    <div class="chip chip-link" [ngClass]="{ 'chip-primary': getAssignedToType() == 'User' && getAssignedToId() == 'any' }" (click)="setAssignedTo('any', 'User')">
                        <con-icon-by-name iconName="user"></con-icon-by-name> Any
                    </div>
                    <div class="chip chip-link  mr-1" *ngFor="let userId of users | keys" [ngClass]="{ 'chip-primary': getAssignedToType() == 'User' && getAssignedToId() == userId }" (click)="setAssignedTo(userId, 'User')">
                        <con-avatar [userId]="userId" size="32" [showTooltip]="false"></con-avatar> {{ users[userId].first_name }} {{ users[userId].last_name }}
                    </div>
                    <br>
                    <strong>Teams:</strong>
                    <div class="chip chip-link" [ngClass]="{ 'chip-primary': getAssignedToType() == 'Team' && getAssignedToId() == 'any' }" (click)="setAssignedTo('any', 'Team')">
                        <con-icon-by-name iconName="users"></con-icon-by-name> Any
                    </div>
                    <div class="chip chip-link" *ngFor="let teamId of teams | keys"  [ngClass]="{ 'chip-primary': getAssignedToType() == 'Team' && getAssignedToId() == teamId }" (click)="setAssignedTo(teamId, 'Team')">
                        <con-icon-by-name iconName="users"></con-icon-by-name> {{ teams[teamId].name }}
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="row">
    <div class="col-2">
        <button class="btn btn-sm btn-outline-info" (click)="refresh()"><con-icon-by-name iconName="refresh"></con-icon-by-name></button>
    </div>
</div>
<div class="calendar">
    <div class="days-of-week">
        <span class="week-block"></span>
        <span class="day-block" *ngFor="let day of ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']">{{ day }}</span>
    </div>
    <div class="row" *ngIf="loading">
        <div class="col-12">
            <con-loader></con-loader>
        </div>
    </div>
    <ng-container *ngIf="!loading">
        <div class="days-of-month" *ngFor="let week of weeks">
            <span class="week-block">
                <span class="week-number">
                    <span class="week-text">Week </span>{{ week[0].date.isoWeek() }}
                </span>
            </span>
            <span class="day-block" *ngFor="let date of week" [ngClass]="{ 'today': isToday(date.date) }">
                <div class="event-block"
                    dragula="TASKS"
                    [(dragulaModel)]="date.tasks"
                    [attr.date]="date.date.toISOString()"
                    >
                    <con-task-alert *ngFor="let task of date.tasks"
                                [task]="task"
                                [ngClass]="{ 'no-drag': task.owner_id !== currentUserId }">
                    </con-task-alert>
                </div>
                <span class="number" [ngClass]="{'number-muted': !isHighlightedMonth(date.date) }">{{ date.date | amDateFormat:'DD' }}
                </span>
            </span>
        </div>
    </ng-container>
</div>