<span *ngIf="nothingSpecial() && !shouldBeMasked()">
    {{ entityDescription.getAttributeByString(entity, field.key) | field:field }}
</span>
<span *ngIf="nothingSpecial() && shouldBeMasked()">
    {{ (entityDescription.getAttributeByString(entity, field.key) | stringify) | field:field | imask: mask}}
</span>
<span *ngIf="isDate() && !isSystemTimestamp() && entityDescription.getAttributeByString(entity, field.key) != null">
    <con-date-shower [date]="entityDescription.getAttributeByString(entity, field.key) | field:field"></con-date-shower>
</span>
<pre *ngIf="isText()">
    {{ entityDescription.getAttributeByString(entity, field.key) | field:field }}
</pre>
<span *ngIf="isSystemTimestamp()">
    <con-date-shower [date]="entityDescription.getAttributeByString(entity, field.key) | field:field"></con-date-shower>
    <con-avatar [userId]="entity[replaceAtToBy(field.key)]" [size]="17"></con-avatar>
</span>
