import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../../settings.class';

@Injectable({
  providedIn: 'root'
})
export class EntityAttachmentsService {

  attachmentIdsForDeletion = [];
  constructor(private http: HttpClient) { }
  reset(){
    this.attachmentIdsForDeletion = [];
  }

  markForDeletion(value: any) {
    this.attachmentIdsForDeletion.push(value.id);
  }

  deleteMarkedAttachments() {
    this.http.post(`${ApiSettings.BASE_URL}/press_release_attachment/bulk_delete/`, {
      ids: this.attachmentIdsForDeletion
    }).subscribe((res) => {

    }, (err) => {
      console.error((err))
    })

  }
}
