<div class="modal-header">
    <h4 class="modal-title">Confirm recurring events</h4>
    <button type="button" class="btn btn-sm" (click)="exitModal(false)">
        <con-icon-by-name iconName="times"></con-icon-by-name>
    </button>
</div>
<ng-container *ngIf="isLoading; else modalBody">
    <con-loader></con-loader>
</ng-container>
<ng-template #modalBody>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="common-data mb-2">
                    <div class="list-row">
                        <div class="list-cell">Owner</div>
                        <div class="list-cell">{{ relationValues?.owner?.name || currentEntityValues?.owner?.name || '-'}}</div>
                    </div>
                    <div class="list-row" *ngIf="mode === modalModes.CREATE">
                        <div class="list-cell">Recurring interval</div>
                        <div class="list-cell">{{ entityDetails?.recurring_event | capitalize}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="common-data mb-2">
                    <div class="list-row">
                        <div class="list-cell">Event type</div>
                        <div class="list-cell">{{relationValues?.calendarEventType?.name || currentEntityValues?.calendar_event_type?.name || '-'}}</div>
                    </div>
                    <div class="list-row" *ngIf="mode === modalModes.CREATE">
                        <div class="list-cell">Occurrences</div>
                        <div class="list-cell">
                            <ng-container *ngIf="!occurrenceFormControl; else occurrenceEditForm">
                                <span>{{entityDetails?.occurrences || '1'}}</span>
                                <button class="btn btn-sm ml-1 px-0 no-shadow" [ngbTooltip]="'Edit occurrences'" [placement]="'right'" (click)="toggleOccurrenceEdit(true)">
                                    <con-icon-by-name iconName="pencil"></con-icon-by-name>
                                </button>
                            </ng-container>
                            <ng-template #occurrenceEditForm>
                                <div class="form-group mb-0 d-flex">
                                    <input type="number" class="form-control form-control-sm" placeholder="Occurrences" [formControl]="occurrenceFormControl">
                                    <button class="ml-1 btn btn-sm btn-success" (click)="saveOccurrence()"> <con-icon-by-name iconName="check"></con-icon-by-name></button>
                                    <button class="ml-1 btn btn-sm btn-secondary"(click)="toggleOccurrenceEdit(false)"> <con-icon-by-name iconName="times"></con-icon-by-name></button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="list-row">
                        <div class="list-cell"></div>
                        <div class="list-cell no-colon pt-0"><con-form-errors [control]="occurrenceFormControl"></con-form-errors></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="events-table">
            <div class="table-row table-head">
                <div class="table-cell w-50px justify-content-center">
                    <div class="custom-control custom-checkbox" >
                        <input type="checkbox" class="custom-control-input" [id]="'check-all'" [checked]="isAllEventsSelected" (click)="selectEvent()">
                        <label class="custom-control-label" [for]="'check-all'"></label>
                    </div>
                </div>
                <div class="table-cell w-60px justify-content-between" *ngIf="mode === modalModes.CREATE">Sl. No.</div>
                <div class="table-cell w-90px" *ngIf="mode !== 'create'">Id</div>
                <div class="table-cell" [ngClass]="{ 'flex-1': !hasCommentField, 'w-220px': hasCommentField }">From date</div>
                <div class="table-cell" [ngClass]="{ 'flex-1': !hasCommentField, 'w-220px': hasCommentField }">To date</div>
                <div class="table-cell w-115px justify-content-center">Date confirmed</div>
                <div class="table-cell flex-1" *ngIf="hasCommentField">Comment</div>
                <div class="table-cell w-120px justify-content-center" *ngIf="mode !== modalModes.DELETE">Edit</div>
            </div>
            <ng-container *ngFor="let event of recurringEvents; let first = first; let index = index;">
                <div class="table-row">
                    <div class="table-cell w-50px justify-content-center">
                        <div class="custom-control custom-checkbox" >
                            <input type="checkbox" class="custom-control-input" [id]="'check-' + index" [checked]="event.is_selected" (click)="selectEvent(index)" [disabled]="[modalModes.EDIT, modalModes.DELETE].includes(mode) && event?.id == currentEntityValues?.id">
                            <label class="custom-control-label" [for]="'check-' + index"></label>
                        </div>
                    </div>
                    <div class="table-cell w-60px justify-content-between" *ngIf="mode === modalModes.CREATE">
                        <span>{{ index + 1 }}</span>
                        <ng-container *ngIf="duplicateEvents[index] || event?.adjusted">
                            <con-icon-by-name 
                                iconName="exclamation-triangle" 
                                [ngbPopover]="warningPopover" 
                                [container]="'body'" 
                                [popoverClass]="'custom-popover'" 
                                [placement]="'right'"
                                [triggers]="'mouseenter:mouseleave'">
                            </con-icon-by-name>
                        </ng-container>
                        <ng-template #warningPopover>
                            <ul class="mb-0 text-white" [ngClass]="!(duplicateEvents[index] && event?.adjusted) ? 'list-style-none pl-1': 'pl-3'">
                                <li *ngIf="duplicateEvents[index]">Duplicate event found</li>
                                <li *ngIf="event?.adjusted">The event date has been adjusted to the last available day of the month</li>
                            </ul>
                        </ng-template>
                    </div>
                    <div class="table-cell w-90px" *ngIf="mode !== 'create'">
                        <a [href]="'/entity/calendar_event/'+ event.id" target="_blank"><span>{{ event?.id }}</span></a>
                    </div>
                    <div class="table-cell" [ngClass]="{ 'flex-1': !hasCommentField, 'w-220px': hasCommentField }">
                        <ng-container *ngIf="event.from_date; else nullValueHiphen">
                            <con-date-shower [date]="event.from_date" [showWeekDay]="entityDetails?.recurring_event === 'weekly'"></con-date-shower>
                        </ng-container>
                    </div>
                    <div class="table-cell" [ngClass]="{ 'flex-1': !hasCommentField, 'w-220px': hasCommentField }">
                        <ng-container *ngIf="event.to_date; else nullValueHiphen">
                            <con-date-shower [date]="event.to_date"[showWeekDay]="entityDetails?.recurring_event=== 'weekly'"></con-date-shower>
                        </ng-container>
                    </div>
                    <div class="table-cell w-115px justify-content-center">{{event.date_confirmed}}</div>
                    <div class="table-cell flex-1" *ngIf="hasCommentField">{{event.comment || '-'}}</div>
                    <div class="table-cell w-120px justify-content-center" *ngIf="mode !== modalModes.DELETE">
                        <button type="button" class="btn btn-primary btn-sm" [disabled]="(first && mode === modalModes.CREATE) || event.id === currentEntityValues?.id" (click)="toggleEventEdit(index)">
                            <con-icon-by-name iconName="pencil"></con-icon-by-name>
                        </button>
                    </div>
                </div>
                <div class="error-section w-100 pb-2" *ngIf="formErrors?.fields?.recurrences[index]?.length">
                    <con-form-errors [extra]="formErrors?.fields?.recurrences[index]"></con-form-errors>
                </div>
                <ng-container *ngIf="currentEditingEvents.hasOwnProperty(index)">
                    <div class="w-100 edit-section pb-2" [ngClass]="{'is-editing': mode === modalModes.EDIT}">
                        <div class="col-12">
                            <div class="form-group">
                                <label> <small>From Date </small></label>
                                <con-date-input [entityForm]="currentEditingEvents[index]" [control]="currentEditingEvents[index].controls['from_date']" [field]="{ key : 'from_date'}"></con-date-input>
                                <con-form-errors [control]="currentEditingEvents[index].controls['from_date']" [extra]="currentEditingEvents[index]?.errors?.parent_date_error ? ['The from date must be greater than the from date of first event'] : []">
                                </con-form-errors>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label> <small>To Date </small></label>
                                <con-date-input [entityForm]="currentEditingEvents[index]" [control]="currentEditingEvents[index].controls['to_date']" [field]="{ key : 'to_date'}"></con-date-input>
                                <con-form-errors [control]="currentEditingEvents[index].controls['to_date']" [extra]="currentEditingEvents[index]?.errors?.date_range_error ? ['The to date must be greater than from date'] : []"></con-form-errors>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="table-cell">
                                <div class="form-group">
                                    <label> <small>Date confirmed </small></label>
                                    <con-checkbox-input [control]="currentEditingEvents[index].controls['date_confirmed']" [field]="{ label : 'Date confirmed'}"></con-checkbox-input>
                                    <con-form-errors [control]="currentEditingEvents[index].controls['date_confirmed']"></con-form-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="currentEditingEvents[index].controls['comment']">
                            <div class="table-cell">
                                <div class="form-group">
                                    <label> <small>Comment </small></label>
                                    <textarea class="form-control" [formControl]="currentEditingEvents[index].controls['comment']" placeholder="Comment"></textarea>
                                    <con-form-errors [control]="currentEditingEvents[index].controls['comment']"></con-form-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="toggleEventEdit(index)">Cancel</button>
                            <button type="button" class="btn btn-sm btn-success" (click)="saveRecurringEvent(index)">Save</button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
<div class="modal-footer d-block">
    <div class="general-errors" *ngIf="formErrors?.general.length">
        <div class="alert alert-danger" *ngFor="let message of formErrors?.general">
            {{ message }}
        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary mr-2" (click)="exitModal(false)">Close</button>
        <button type="button" class="btn" [ngClass]="this.mode === modalModes.DELETE ? 'btn-danger' : 'btn-primary'" (click)="onSubmitRecurringEvents()" [disabled]="isSaving || !hasAtleastOneEventSelected || isLoading">
            <ng-container *ngIf="isSaving">
                <div class="spinner-border spinner-border-sm mr-1"></div>
            </ng-container>
            <span> {{mode !== modalModes.DELETE ? 'Save' : 'Delete'}} {{isAllEventsSelected ? 'all' : 'selected'}}</span>
        </button>
    </div>
</div>
<ng-template #nullValueHiphen>-</ng-template>