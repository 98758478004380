import RangeOffsetModel from './range-offset.model';
import {DocProcessHandlerUtilsService} from '../services/utils.service';
import {UtilService} from '../services/util.service';
import {NewsInstancesService} from '../services/news-instances.service';

export class PersistentRange {
  public rangeOffset: RangeOffsetModel
  public innerString: string;

  public static fromRange(range: Range) : PersistentRange {
    return new this(this.calculateOffsetAccordingToParentHtmlElement(range), range.toString())
  }

  constructor(
    rangeOffset: RangeOffsetModel,
    innerString: string
  ) {
    this.rangeOffset = rangeOffset
    this.innerString = innerString
  }

  private static calculateOffsetAccordingToParentHtmlElement(range: Range): RangeOffsetModel {
    const rangeStartContainerNode = range.startContainer;
    const parent = rangeStartContainerNode.parentElement as HTMLElement;

    let rangeFromParentElement = new RangeOffsetModel(range.startOffset, range.endOffset, NewsInstancesService.getXpathRelativeToDocumentRoot(DocProcessHandlerUtilsService.getXpath(range.startContainer.parentNode)));

    for (let childNode of [...(parent.childNodes)]) {
      if (childNode === rangeStartContainerNode) {
        return rangeFromParentElement;
      } else {
        rangeFromParentElement = rangeFromParentElement.shift(UtilService.getTextLengthOfDomObject(childNode));
      }
    }

    return rangeFromParentElement;
  }

  public static compare(a: PersistentRange, b: PersistentRange): number {
    if (RangeOffsetModel.compare(a.rangeOffset, b.rangeOffset) === 0 && a.innerString === b.innerString)
      return 0;
    else
      return 1
  }
}
