
import {combineLatest as observableCombineLatest,  Subject ,  Observable ,  SubscriptionLike as ISubscription } from 'rxjs';

import {tap, map, startWith, take} from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import * as moment from 'moment-timezone';
import { CalendarEventSettings } from 'app/settings.class';
import { ProfileService } from 'app/auth/services/profile.service';

@Component({
    selector: 'con-date-shower',
    templateUrl: './date-shower.component.html'
})

export class DateShowerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() date: any;
    @Input() showCustomTimezone = false;
    @Input() customTimezone = '';
    @Input() field: any;
    @Input() showWeekDay = false;
    public formattedDate: string;
    private dateSubscription: ISubscription;
    private dateSubject: Subject<string> = new Subject<string>();
    public timezoneControl: UntypedFormControl = new UntypedFormControl();
    public availableTimezones: any = moment.tz.names();
    public isEditingTimezone = false;
    private currentTimezone: string;

    constructor( private profileService: ProfileService) {}

    ngOnInit() {
        this.initializeTimezone();
        this.dateSubscription = observableCombineLatest(
                                                this.dateSubject.pipe(
                                                    startWith(this.date)),
                                                this.timezoneControl
                                                    .valueChanges.pipe(
                                                    tap(() => {
                                                        this.hideTimezoneEditor();
                                                    }))
                                            ).pipe(
                                            map((date) => {
                                                if (date[0] === null || date[0] === undefined) {
                                                    return null;
                                                }
                                                return moment(date[0]).tz(date[1]);
                                            }))
                                            .subscribe(
                                              res => { 
                                                  if (res === null) {
                                                      this.formattedDate = 'Unspecified';
                                                    } else {
                                                        this.formattedDate = res.format(this.showWeekDay ? 'yyyy-MM-DD HH:mm:ss, dddd' : 'yyyy-MM-DD HH:mm:ss');
                                                    }
                                              }
                                            );

        if(this.showCustomTimezone && this.customTimezone) {
          this.timezoneControl.patchValue(this.customTimezone);
        } else {
          this.timezoneControl.patchValue(this.currentTimezone);
        }
    }

    ngOnChanges(changes:SimpleChanges) {
        this.dateSubject.next(this.date);
         if(changes.customTimezone && this.showCustomTimezone && this.customTimezone) {
             this.timezoneControl.patchValue(this.customTimezone);
         }
    }

    ngOnDestroy() {
        this.dateSubscription.unsubscribe();
    }

    hideTimezoneEditor() {
        this.isEditingTimezone = false;
    }

    showTimezoneEditor() {
        this.isEditingTimezone = true;
    }

    toggleTimezoneEditor() {
      if(!this.showCustomTimezone) {
        this.isEditingTimezone = !this.isEditingTimezone;
      }
    }

    private initializeTimezone() {
        this.profileService.getUserTimezone().pipe(take(1)).subscribe( timezone => {
            this.currentTimezone = timezone;
        });
    }
}
