<div class="row pl-3">
    <div>
        <div class="form-inline mb-3">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Agents</span></label>
            <select [disabled]="!dep" class="custom-select" type="text" ng-model="dropdownOptions" (change)="requestAction($event.target.value)">
                <option *ngFor="let option of dropdownOptions" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>
    </div>
    <div>
        <div class="form-inline mb-3 ml-2">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Task type</span></label>
            <select [disabled]="!dep" class="custom-select" type="text" ng-model="depStatus" (change)="requestAction($event.target.value)">
                <option *ngFor="let option of depStatus" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>
    </div>
    <div>
        <div class="form-inline mb-3 ml-2">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Task status</span></label>
            <select [disabled]="!dep"  #taskStatus class="custom-select" type="text" ng-model="depStatus" (change)="setTaskStatus($event.target.value)">
                <option *ngFor="let option of taskStatusArr" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>
    </div>
    <div>
        <div class="form-inline mb-3 ml-2">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Job status</span></label>
            <select [disabled]="!dep" #jobStatus class="custom-select" type="text" ng-model="depStatus" (change)="setJobStatus($event.target.value)">
                <option *ngFor="let option of currentJobs" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>
    </div>

    <div>
        <div class="form-inline mb-3 ml-2">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Filter</span></label>
            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                <button [disabled]="!dep" class="btn dropdown-button" id="dropdownBasic1" ngbDropdownToggle>Categories</button>
                <div class="p-2" ngbDropdownMenu aria-labelledby="dropdownManual">
                    <label *ngFor="let category of categories" class="container" ngbButtonLabel>
                        <button [class]="selectedCategories.indexOf(category) >= 0 ? 'btn btn-primary select-button' : 'btn select-button' " (click)="selected(category)" ngbButton>{{category}}</button>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header d-flex justify-content-start">
                <div class="col-6 d-flex align-items-center">
                    <div class="mr-1">
                        <con-icon-by-name iconName="tasks"></con-icon-by-name>
                        Agents
                    </div>
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end pr-0">
                    <div class="toggle-text mr-2">
                        Auto refresh: {{shouldAutoRefresh ? 'On' : 'Off'}}
                    </div>
                    <label class="col-1 toggle-switch">
                        <input type="checkbox" (change)="setAutoRefresh()" [(ngModel)]="shouldAutoRefresh">
                        <div class="slider"></div>
                    </label>
                </div>
            </div>
            <div class="card-body" *ngIf="agentsExpanded">
                <con-loader-task-manager [isLoading]="isLoading"></con-loader-task-manager>
                <div *ngFor="let depData of expandableDep" class="card col-12 ">
                    <div class="card-header row p-2">
                        <div class="col-3">
                            <con-icon-by-name iconName="wrench" class="mr-2"></con-icon-by-name>
                            <a class="text-white" [routerLink]="['/task_manager', 'tasks', depData.depData.task_id]">{{depData.depData.name}}</a>
                        </div>
                        <div class="col-2">
                            {{depData.depData.category}}
                        </div>
                        <div [class]="
                                depData.depData.task_active ? 
                                depData.depData.agent_status === 'Operational' ? 
                                'col-2 text-success' : 
                                (depData.depData.agent_status === 'FailedProcessing' 
                                || depData.depData.agent_status === 'FailedScrape')  ? 
                                'col-2 text-danger' : 
                                'col-2 text-white' : 
                                'col-2 text-secondary'"
                            >
                            {{depData.depData.task_active ? depData.depData.agent_status : 'Deactivated'}}
                        </div>
                        <div [class]="depData.depData.delivery_status === 'Running: ManualEntry' ? 'text-warning col-2' : 'text-white col-2' ">{{depData.depData.delivery_status}}</div>
                        <div class="col-2">{{depData.depData.last_collection_time}}</div>
                        <div class="card-header-toggle text-right col-1 cursor-pointer" (click)="depData.depData.expanded = !depData.depData.expanded">
                            <con-icon-by-name iconName="angle-right" [mutations]="depData.depData.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" ></con-icon-by-name>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="depData.depData.expanded">
                        <div *ngFor="let delivery of depData.depData.deliveries" class="card col-12 p-0">
                            <div style="background-color: #4a4663;" class="card-header row p-2" (click)="delivery.expanded = !delivery.expanded">
                                <div class="col-3">
                                    <con-icon-by-name iconName="truck" class="mr-2"></con-icon-by-name>
                                    <a class="text-white" [routerLink]="['/task_manager', 'deliveries', delivery.delivery_id]">{{delivery.delivery_id}}</a>
                                </div>
                                <div class="col-2">
                                    {{delivery.scheduled_time}}
                                </div>
                                <div 
                                    [class]="
                                        delivery.delivery_state === 'Completed' ? 
                                        'text-success col-2' : 
                                        delivery.job_step === 'ManualEntry' ? 
                                        'text-warning col-2' : 
                                        delivery.delivery_state === 'Pending' ? 
                                        'text-white col-2' : 
                                        delivery.delivery_state === 'Canceled' ? 
                                        'text-secondary col-2' : 
                                        'text-warning col-2'"
                                >
                                    {{ delivery.job_step ? delivery.delivery_state  + ': ' + delivery.job_step : delivery.delivery_state}}
                                </div>
                                <div [class]="delivery.job_status?.indexOf('Manual') >= 0? 'text-warning col-2 d-flex justify-content-start' : 'text-white col-2 d-flex justify-content-start' ">
                                    {{delivery.job_status}}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>