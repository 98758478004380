import {Injectable} from '@angular/core';
import {InstanceDefinition} from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {InstanceData, InstanceDataUnioned, TaxonomyFieldTypes} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import {DataExtractorService} from './data-extractor.service';
import {NewsInstancesService} from '../../doc-process-common/services/news-instances.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedFieldService {

  handleSelectedField(selectedField: string | TaxonomyFieldTypes, instanceDefinitions: InstanceDefinition[], instanceId: number, instanceData: InstanceData) {
    const {InstanceDocumentAnnotation} = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    if (InstanceDocumentAnnotation) {
      const {annotations} = InstanceDocumentAnnotation;
      const targetTaxonomy = (instanceData as InstanceDataUnioned).taxonomy_data[selectedField].values.find(value => value.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod);
      if (targetTaxonomy !== this.newsInstanceService.selectedTaxonomy) {
        const lastTargetTaxonomyValue = targetTaxonomy.values[targetTaxonomy.values.length - 1];
        const annotationToSelect = annotations.find(annot => {

          if (annot) {
            if (annot.fields[0].context.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod &&
              annot.annot_id === (lastTargetTaxonomyValue && lastTargetTaxonomyValue.annot_id)) {
              return annot;
            }
          }

        });
        if (annotationToSelect) {
          this.newsInstanceService.setSelectedNewsAnnotation(annotationToSelect);
        }
      }

    }
  }

  constructor(private newsInstanceService: NewsInstancesService, private dataExtractorService: DataExtractorService) { }
}
