<div *ngIf='!loading'>

  <!-- income statements -->
  <div>
    <div class='card mb-2'>
      <div class='card-header' (click)='expandIncomeStatementListing()'>
        <div class='d-flex justify-content-start align-items-center'>
          <con-icon-by-name iconName='database' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
          Income Statements
          <div class='card-header-toggle-individual ml-auto'>
            <con-icon-by-name iconName='angle-right'
                              [mutations]='getMutations(expandIncomeStatements)'></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class='col-12' *ngIf='expandIncomeStatements'>
        <div *ngFor='let incomeStatement of allStatementsData?.income_statements; let index = index' class='mt-15 mb-15'>

          <div class="card-header grad-tile" (click)="toggleType(incomeStatement)">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              {{getDisplayNameISCS(incomeStatement)}}
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right"
                                  [mutations]="getMutations(incomeStatement.hide)"></con-icon-by-name>
              </div>
            </div>
          </div>


          <div class="p-4 col-12 border-nested" *ngIf="!incomeStatement?.hide">
            <con-entity-form-content [entity]="incomeStatement" entityName="IncomeStatement"
                                     [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                                     [canDelete]="false" (afterSave)="incomeStatementUpdated($event, index)"
                                     toastMessage="Income Statement updated successfully!"
                                     [canEdit]="!incomeStatement?.locked  && !entityService.entityLockedByMl(entity)">
            </con-entity-form-content>
          </div>
        </div>
      </div>

    </div>
    <con-no-data [count]='allStatementsData?.income_statements?.length'
                 message='No income statements available for this report'></con-no-data>
  </div>


  <!-- balance sheets -->
  <div>
    <div class='card mb-2'>
      <div class='card-header' (click)='expandBalanceSheetListing()'>
        <div class='d-flex justify-content-start align-items-center'>
          <con-icon-by-name iconName='database' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
          Balance Sheets
          <div class='card-header-toggle-individual ml-auto'>
            <con-icon-by-name iconName='angle-right'
                              [mutations]='getMutations(expandBalanceSheets)'></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class='col-12' *ngIf='expandBalanceSheets'>
        <div *ngFor='let bs of allStatementsData?.balance_sheets; let index = index' class='mt-15 mb-15'>

          <div class="card-header grad-tile" (click)="toggleType(bs)">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              {{getDisplayNameBS(bs)}}
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right"
                                  [mutations]="getMutations(bs.hide)"></con-icon-by-name>
              </div>
            </div>
          </div>


          <div class="p-4 col-12 border-nested" *ngIf="!bs?.hide">
            <con-entity-form-content [entity]="bs" entityName="BalanceSheet"
                                     [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                                     [canDelete]="false" (afterSave)="balanceSheetUpdated($event, index)"
                                     toastMessage="Balance sheet updated successfully!"
                                     [canEdit]="!bs?.locked  && !entityService.entityLockedByMl(entity)">
            </con-entity-form-content>
          </div>
        </div>
      </div>

    </div>
    <con-no-data [count]='allStatementsData?.balance_sheets?.length'
                 message='No balance sheets available for this repor'></con-no-data>
  </div>

  <!-- cash flow statements -->
  <div>
    <div class='card mb-2'>
      <div class='card-header' (click)='expandCashFlowListing()'>
        <div class='d-flex justify-content-start align-items-center'>
          <con-icon-by-name iconName='database' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
          Cash Flows
          <div class='card-header-toggle-individual ml-auto'>
            <con-icon-by-name iconName='angle-right'
                              [mutations]='getMutations(expandCashFlows)'></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class='col-12' *ngIf='expandCashFlows'>
        <div *ngFor='let cs of allStatementsData?.cash_flows; let index = index' class='mt-15 mb-15'>

          <div class="card-header grad-tile" (click)="toggleType(cs)">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              {{getDisplayNameISCS(cs)}}
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right"
                                  [mutations]="getMutations(cs.hide)"></con-icon-by-name>
              </div>
            </div>
          </div>


          <div class="p-4 col-12 border-nested" *ngIf="!cs?.hide">
            <con-entity-form-content [entity]="cs" entityName="CashFlow"
                                     [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                                     [canDelete]="false" (afterSave)="cashFlowUpdated($event, index)"
                                     toastMessage="Cashflow updated successfully!"
                                     [canEdit]="!cs?.locked  && !entityService.entityLockedByMl(entity)">
            </con-entity-form-content>
          </div>
        </div>
      </div>

    </div>
    <con-no-data [count]='allStatementsData?.cash_flows?.length'
                 message='No cash flows available for this report'></con-no-data>
  </div>

  <!-- period kpis -->
  <div>
    <div class='card mb-2'>
      <div class='card-header' (click)='expandPeriodKpiListing()'>
        <div class='d-flex justify-content-start align-items-center'>
          <con-icon-by-name iconName='database' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
          Period Kpis
          <div class='card-header-toggle-individual ml-auto'>
            <con-icon-by-name iconName='angle-right'
                              [mutations]='getMutations(expandPeriodKpis)'></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class='col-12' *ngIf='expandPeriodKpis'>
        <div *ngFor='let pk of allStatementsData?.period_kpis; let index = index' class='mt-15 mb-15'>

          <div class="card-header grad-tile" (click)="toggleType(pk)">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              {{getDisplayNamePeriodKpi(pk)}}
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right"
                                  [mutations]="getMutations(pk.hide)"></con-icon-by-name>
              </div>
            </div>
          </div>


          <div class="p-4 col-12 border-nested" *ngIf="!pk?.hide">
            <con-entity-form-content [entity]="pk" entityName="PeriodKpi"
                                     [showWhenChanged]="false"
                                     [fixedValues]="{ company_report_id: entity?.id }"
                                     [canDelete]="false" (afterSave)="periodKpiUpdated($event, index)"
                                     toastMessage="Period kpi updated successfully!"
                                     [canEdit]="!pk?.locked  && !entityService.entityLockedByMl(entity)">
            </con-entity-form-content>
          </div>
        </div>
      </div>

    </div>
    <con-no-data [count]='allStatementsData?.period_kpis?.length'
                 message='No period kpis available for this report'></con-no-data>
  </div>

  <!-- snapshot kpis -->
  <div>
    <div class='card mb-2'>
      <div class='card-header' (click)='expandSnapshotKpiListing()'>
        <div class='d-flex justify-content-start align-items-center'>
          <con-icon-by-name iconName='database' [mutations]="['fw']" class='mr-1'></con-icon-by-name>
          Snapshot Kpis
          <div class='card-header-toggle-individual ml-auto'>
            <con-icon-by-name iconName='angle-right'
                              [mutations]='getMutations(expandSnapshotKpis)'></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class='col-12' *ngIf='expandSnapshotKpis'>
        <div *ngFor='let sk of allStatementsData?.snapshot_kpis; let index = index' class='mt-15 mb-15'>

          <div class="card-header grad-tile" (click)="toggleType(sk)">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              {{getDisplayNameSnapshotKpi(sk)}}
              <div class="card-header-toggle-individual ml-auto">
                <con-icon-by-name iconName="angle-right"
                                  [mutations]="getMutations(sk.hide)"></con-icon-by-name>
              </div>
            </div>
          </div>


          <div class="p-4 col-12 border-nested" *ngIf="!sk?.hide">
            <con-entity-form-content [entity]="sk" entityName="SnapshotKpi"
                                     [showWhenChanged]="false"
                                     [fixedValues]="{ company_report_id: entity?.id }"
                                     [canDelete]="false" (afterSave)="snapshotKpiUpdated($event, index)"
                                     toastMessage="Snapshot kpi updated successfully!"
                                     [canEdit]="!sk?.locked  && !entityService.entityLockedByMl(entity)">
            </con-entity-form-content>
          </div>
        </div>
      </div>

    </div>
    <con-no-data [count]='allStatementsData?.snapshot_kpis?.length'
                 message='No snapshot kpis available for this report'></con-no-data>
  </div>
</div>
<div>
  <con-loader *ngIf='loading'></con-loader>
</div>
