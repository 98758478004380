import { Component, Input } from '@angular/core';

@Component({
    selector: 'con-entity-content-by-field',
    templateUrl: './entity-content-by-field.component.html'
})

export class EntityContentByFieldComponent {
    @Input() entity: any;
    @Input() entityDescription: any;
    @Input() field: any;

    private MASKED_FIELDS = ['shares', 'share_count'];
    public mask: any = {
        mask: Number,
        thousandsSeparator: ' ',
        radix: '.',
        mapToRadix: [','],
    }
    public isSystemTimestamp() {
        return this.field ? ['created_at', 'updated_at', 'deleted_at'].indexOf(this.field.key) > -1 : false;
    }
    public isText() {
        return this.field ? this.field.type === 'text' : false;
    }
    public isDate() {
        return this.field ? this.field.type === 'date' : false;
    }
    public nothingSpecial() {
        return !this.isSystemTimestamp() &&
                !this.isText() &&
                !this.isDate();
    }

    public replaceAtToBy(type) {
        return type.replace('_at', '_by');
    }
    public shouldBeMasked() {
        return this.MASKED_FIELDS.indexOf(this.field.key) > -1;
    }
}
