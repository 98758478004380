<div class="d-flex justify-content-between align-items-center mb-2">
    <h3 class="mb-0"><button class="btn btn-link" (click)="decrementOffset()"><con-icon-by-name iconName="chevron-left"></con-icon-by-name></button> {{ startDay | amDateFormat:'MMMM yyyy' }} <button class="btn btn-link" (click)="incrementOffset()"><con-icon-by-name iconName="chevron-right"></con-icon-by-name></button></h3>
    <div class="form-group mb-0">
        <div class="form-inline justify-content-end type-search-field">
            <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Calendar event type</span></label>
            <ng-container *ngIf="!selectedTypeDetails; else selectedTypeInfo">
                <con-entity-search-field entityName="CalendarEventType" (onSelect)="selectType($event)"></con-entity-search-field>
            </ng-container>
            <ng-template #selectedTypeInfo>
                <div class="custom-alert alert alert-info alert-dismissible mb-0">
                    <button type="button" class="close" aria-label="Close" (click)="clearType()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <a class="alert-link-icon mr-1" [routerLink]="['/entity/', 'calendar_event_type', selectedTypeDetails?.id]" placement="top" container="body" [ngbTooltip]="'Go to calendar event type'">
                        <con-icon-by-name [iconName]="'calendar-minus-o'" [mutations]="['fw']"></con-icon-by-name>
                    </a>
                    <strong>{{ selectedTypeDetails?.name }}</strong>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<con-month-shower [date]="startDay" *ngIf="_canIndex" [searchParams]="{calendar_event_type_id: selectedTypeDetails?.id || ''}"></con-month-shower>
<p class="lead" *ngIf="!_canIndex">You are not authorized to see this</p>
