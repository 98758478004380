import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthSettings } from '../settings.class';

import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { FileTokenService } from './services/file-token.service';

import { AuthGuard } from './guards/auth-guard.service';
import { GuestGuard } from './guards/guest-guard.service';
import { SuperUserGuard } from './guards/super-user-guard.service';

import { LoginComponent } from './components/login.component';
import { ProfileHandlerComponent } from './components/profile-handler.component';
import { NotAuthorizedModalComponent } from './components/not-authorized-modal.component';
import { NotAllowedAlertComponent } from './components/not-allowed-alert.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        RouterModule,
        NgbModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        GuestGuard,
        SuperUserGuard,
        ProfileService,
        FileTokenService
    ],
    declarations: [
        LoginComponent,
        ProfileHandlerComponent,
        NotAuthorizedModalComponent,
        NotAllowedAlertComponent
    ],
    exports: [
        ProfileHandlerComponent,
        NotAuthorizedModalComponent,
        NotAllowedAlertComponent
    ]
})
export class AuthModule {}
