
import {switchMap, tap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe';


import { Entity, EntitiesService, EntityDescription } from '../services/entities.service';
import { ProfileService } from '../../auth/services/profile.service';

import { camelCase, upperCaseFirst } from 'change-case';
import {MLSendStatus} from '../../company-reports/models/MLSendStatus';

@Component({
    selector: 'con-entity-details',
    templateUrl: './entity-details.component.html',
    providers: [CapitalizePipe]
})

export class EntityDetailsComponent implements OnInit {
    public entity: any;
    public entityDescription: EntityDescription;
    public entityName: string;
    private loading: boolean;
    private showComponentDict: any = {};
    private id: number;
    public MLSendStatus = MLSendStatus;
    constructor(private service: EntitiesService,
                public profile: ProfileService,
                private capitalizePipe: CapitalizePipe,
                private route: ActivatedRoute,
                private router: Router) {}
    public isLoading() {
        return this.loading || this.profile.isLoading() || this.service.isLoading();
    }
    ngOnInit() {
        this.route.params.pipe(
                    switchMap((params: Params) => {
                        this.entityName = upperCaseFirst(camelCase(params.name));
                        return this.service.getEntityDescriptionByEntityName(this.entityName);
                    }),
                    tap(entityDescription => {
                        this.entityDescription = entityDescription;
                    }), )
                    .subscribe(
                        entityDescription => this.entityDescription = entityDescription,
                        err => this.router.navigate(['entity', 'error'], { skipLocationChange: true })
                    );
        this.route.params.pipe(
                    tap((params: Params) => {
                        this.loading = true;
                        this.id = params.id;
                    }),
                    switchMap((params: Params) => this.service.getEntityById(this.capitalizePipe.transform(params.name), params.id)), )
                    .subscribe(
                        entity => {
                            this.entity = entity
                            this.processEntity();
                            this.loading = false;
                        },
                        error => {
                            if (error.isPermissionError()) {
                                this.entity = {
                                    id: this.id
                                };
                                this.loading = false;
                            } else if (error.isNotFoundError()) {
                                this.router.navigate(['entity', 'error'], { skipLocationChange: true });
                            }

                    });
    }
    processEntity() {
        if (this.entityName === 'CalendarEventComment') {
            const calendar_event_type =  {
                                        name : ''
                                    };
            if (this.entity.calendar_event && this.entity.calendar_event.calendar_event_type_id) {
                calendar_event_type.name = this.entity.calendar_event.owner_type ?
                            this.entity.calendar_event.calendar_event_type_id + ', ' + this.entity.calendar_event.owner_type :
                            this.entity.calendar_event.calendar_event_type_id;
            }
            this.entity.calendar_event.calendar_event_type = calendar_event_type;
        }
        if (this.entityName === 'Owner') {
            this.entity.capital = (this.entity.capital !== null && this.entity.capital !== '') ? (this.entity.capital * 100).toFixed(2) : null;
            this.entity.votes = (this.entity.votes !== null && this.entity.votes !== '') ? (this.entity.votes * 100).toFixed(2) : null;
        }
        if (this.entityName === 'CompanyPosition') {
            if (this.entity.company_role && this.entity.company_role && this.entity.company_role.name && this.entity.company_role.type_name) {
                this.entity.company_role.name = `${this.entity.company_role.name} , ${this.entity.company_role.type_name}` ;
            }
        }
    }
    entityUpdated(entity) {
        if (entity === null) {
            this.router.navigate(['404'], { skipLocationChange: true });
        }
        this.entity = entity;
    }
    showComponent(relationName: string) {
        return this.showComponentDict[relationName];
    }
    toggleComponent(relationName: string) {
        this.showComponentDict[relationName] = !this.showComponentDict[relationName];
    }
    shouldUseToManyEditor(relation: any) {
        return ['hasMany', 'belongsToMany', 'morphMany'].indexOf(relation.type) > -1;
    }
    shouldUseBelongsToEditor(relation: any) {
        return ['belongsTo', 'morphTo'].indexOf(relation.type) > -1;
    }
    shouldUseHasOneEditor(relation: any) {
        return ['hasOne', 'morphOne'].indexOf(relation.type) > -1;
    }

    reportFileUpdated(updatedData: any) {
      if (updatedData.data === null) {
        this.router.navigate(['404'], { skipLocationChange: true });
      }
      this.entity = updatedData.data;
    }
  public entityLockedByMl(entityObject: any) : boolean{
    if(
      entityObject?.company_report?.ml_info &&
      entityObject.company_report.ml_info.ml_request_status !== null
      && entityObject.company_report.ml_info.ml_request_status !== MLSendStatus.CLOSED
      && entityObject.company_report.ml_info.ml_request_status !== MLSendStatus.FAILED) {
      return true;
    }
    if(
      entityObject?.source?.ml_info &&
      entityObject.source.ml_info.ml_request_status !== null
      && entityObject.source.ml_info.ml_request_status !== MLSendStatus.FAILED
      && entityObject.source.ml_info.ml_request_status !== MLSendStatus.CLOSED
    ) {
      return true;
    }
    // case for shareholder entity detail page
    if(
      entityObject?.ml_info &&
      entityObject.ml_info.ml_request_status !== null
      && entityObject.ml_info.ml_request_status !== MLSendStatus.FAILED
      && entityObject.ml_info.ml_request_status !== MLSendStatus.CLOSED
    ) {
      return true;
    }
    return false;
  }
  public entityLockedByMlStatus(entityObject: any) : string{
    if(entityObject?.company_report?.ml_info ){
      return entityObject.company_report.ml_info.ml_request_status;
    }
    if(entityObject?.source?.ml_info ){
      if(entityObject.source.ml_info.ml_request_status !== null && entityObject.source.ml_info.ml_request_status !== MLSendStatus.FAILED && entityObject.source.ml_info.ml_request_status !== MLSendStatus.CLOSED) {
        return entityObject.source.ml_info.ml_request_status;
      }
    }
    // case for shareholder entity detail page
    if(entityObject?.ml_info ){
      if(entityObject.ml_info.ml_request_status !== null && entityObject.ml_info.ml_request_status !== MLSendStatus.FAILED && entityObject.ml_info.ml_request_status !== MLSendStatus.CLOSED) {
        return entityObject.ml_info.ml_request_status;
      }
    }
    return '';
  }
}
