<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link"
                        [routerLink]="[]"
                        [queryParams]="{ view: 'missing' }"
                        routerLinkActive="active">Missing</a>
                    </li>
                    <li class="nav-item">
                            <a class="nav-link"
                            [routerLink]="[]"
                            [queryParams]="{ view: 'outdated' }"
                            routerLinkActive="active">Outdated</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                        [routerLink]="[]"
                        [queryParams]="{ view: 'review' }"
                        routerLinkActive="active">Review</a>
                </li>
                </ul>
            </div>
            <con-loader *ngIf="loading"></con-loader>
        </div>
    </div>
    <div class="row">
        <div class="col-12 inner-scroll" *ngIf="!loading">
            <con-shareholders-editor
            [company]="company"
            [mode]="view"
            [fromSearch]="true"
            (afterFixed)="updateCompany($event)"
            (afterUpdate)="updateCompany($event)"></con-shareholders-editor>
        </div>
    </div>
</div>