import { Injectable } from '@angular/core';
import { AutomatedContentSettings } from 'app/settings.class';
import {Md5} from 'ts-md5';

@Injectable({
  providedIn: 'root'
})

export class EnvSettingsService {

  public static MOZENDA_URL = () => {
    const server = localStorage.getItem('task_manager_server')
    if (!server) { return AutomatedContentSettings.MOZENDA_URL_PRODUCTION_EUR + "userToken=" + EnvSettingsService.generateUserToken() + "&" }
    switch (server) {
      case 'local': return AutomatedContentSettings.MOZENDA_URL_LOCAL + "userToken=" + EnvSettingsService.generateUserToken() + "&"
      case 'stage': return AutomatedContentSettings.MOZENDA_URL_STAGE  + "userToken=" + EnvSettingsService.generateUserToken() + "&"
      case 'production_europe': return AutomatedContentSettings.MOZENDA_URL_PRODUCTION_EUR  + "userToken=" + EnvSettingsService.generateUserToken() + "&"
      case 'production_usa': return AutomatedContentSettings.MOZENDA_URL_PRODUCTION_USA + "userToken=" + EnvSettingsService.generateUserToken() + "&"
    }
  }
  constructor() {

  }

  private static generateUserToken(): string {
    const user = EnvSettingsService.getUser()
    let userEmail = user.email;
    let created_at = user.created_at;

    const md5 = new Md5();
    let md5HashString = md5.appendStr(userEmail+created_at).end().toString();

    return md5HashString;
  }

  public static getUser() {
    return JSON.parse(localStorage.getItem('USER-DETAILS'))
  }
}
