<con-loader *ngIf="loading"></con-loader>
<div class="card" *ngIf="!loading">
    <div class="card-body">
        <h4 class="card-title mb-0">
            <con-icon-by-name [iconName]="entityDescription.getIcon()"></con-icon-by-name>
            {{ entityDescription.getPrimaryString(entity) }}
        </h4>
    </div>
    <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngIf="entityDescription.name === 'CompanyReportFile'">
          <strong>File : </strong>
          <a target="_blank" type="link" [conResolveLinkToFile]="entity?.source">Open</a>
        </li>
        <li class="list-group-item" *ngFor="let field of entityDescription.getFieldsForTable()">
            <strong>{{ field.label }}:</strong>
            <con-entity-content-by-field [field]="field" [entityDescription]="entityDescription" [entity]="entity">
            </con-entity-content-by-field>
        </li>

    </ul>
    <div class="card-body">
        <button *ngFor="let action of actions" (click)="doAction(action.name)" class="btn btn-info">
            <con-icon-by-name [iconName]="action.icon"></con-icon-by-name> {{ action.label }}
        </button>
        <button *ngIf="showGotoButton() && !redirectButton.show" (click)="redirectTo(entityDescription.name, entity.id)" class="btn btn-primary">
            <con-icon-by-name iconName="sign-out"></con-icon-by-name> Go to
        </button>
      <a *ngIf="redirectButton.show" [routerLink]="redirectButton.link" class="btn btn-dark" target="_blank">{{redirectButton.label | titlecase}}</a>
    </div>
</div>
