import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {
  InstanceDataKiid,
  LANGUAGES,
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { CurrentFields } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {DataFieldValidityService} from '../../../doc-process-common/services/data-field-validity.service';

@Component({
  selector: 'con-kiid-priip-instances-handler',
  templateUrl: './kiid-priip-instances-handler.component.html',
})
export class KiidPriipInstancesHandlerComponent implements OnInit, OnDestroy {

  @Input() instanceData: InstanceDataKiid
  @Input() currentFields: CurrentFields[]

  public readonly RecHoldingPeriod = Array.from(new Array(15), (val, index) => index + 1).map(String);

  get LANGUAGES(): Array<string> { return LANGUAGES }
  get srriArray() { return Array.from({length:7},(v,k)=>k+1).map(String) }

  constructor(
    public dataFieldValidityService: DataFieldValidityService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
