<div [ngClass]="displayMLPreviewUrl ? 'd-block': 'd-none'" class="mt-15 mb-15">
  <div class="card">
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-light">
        <th>Preview File
          <button type="button" class="close pull-right" aria-label="Close" (click)='close()'>
            <span aria-hidden="true">&times;</span>
          </button>
        </th>
        </thead>
        <tbody>
        <tr>
          <td>
            <con-loader [showCircularLoder]="true" *ngIf="loading"></con-loader>
            <div  [ngClass]="displayMLPreviewUrl && !loading ? 'd-block': 'd-none'">
              <iframe #previewIframe [src]="displayMLPreviewUrl |safe: 'resourceUrl'" height="1000" width="100%" title="preview ml directory file"  (load)="contentLoaded()"></iframe>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
