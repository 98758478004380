<div class="content">
  <div class="container-fluid mb-3">
    <div class="row">
      <div class="col-12">
        <con-loader *ngIf="loading"></con-loader>
        <ng-container *ngIf="!loading">
            <con-customers-editor
                        *ngFor="let cust of customers?.data"
                        mode="revision"
                        [customer]="cust"
                        (afterFixed)="whenFixed($event)"
                        (afterUpdate)="removeFromList($event)">
                    </con-customers-editor>
            <con-no-data [count]="customers?.total" [message]="noDataMessage"></con-no-data>        
        </ng-container>
        <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!customers?.total}">
          <ngb-pagination
            [collectionSize]="customers?.total"
            [page]="customers?.current_page"
            (pageChange)="loadPage($event)"
            [pageSize]="customers?.per_page"
            maxSize="10"
            [rotate]="true"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
