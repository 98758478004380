
import {of as observableOf,  Observable ,  Subject ,  SubscriptionLike as ISubscription } from 'rxjs';

import {switchMap, distinctUntilChanged} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { AuthSettings, ApiSettings } from '../../settings.class';
import { JwtHelperService } from '@auth0/angular-jwt';




@Injectable()
export class AuthService implements OnDestroy {
    private payload: any;
    private initialLoad = true;
    private isLoggedIn$: Subject<boolean> = new Subject<boolean>();
    private isLoggedInSubscription: ISubscription;
    private loggedIn: boolean;
    private redirectUrl: string;

    constructor(private http: HttpClient,
                private jwtHelper: JwtHelperService,
                private router: Router) {
                    this.isLoggedInSubscription = this.isLoggedIn$
                                                        .subscribe(status => {
                                                            if (this.loggedIn !== status) {
                                                                if (status) {
                                                                    this.storePayload(AuthSettings.getToken());
                                                                } else {
                                                                    this.emptyPayload();
                                                                }
                                                                this.loggedIn = status;
                                                            }
                                                        });
                }
    observeStatus() {
        return this.isLoggedIn$
                    .asObservable().pipe(
                    distinctUntilChanged());
    }
    isLoggedIn() {
        if (this.jwtHelper.tokenGetter() !== null) {
            this.isLoggedIn$.next(true);
            return observableOf(true);
        }
        this.isLoggedIn$.next(false);
        return observableOf(false);
    }

    emptyPayload() {
        this.payload = null;
    }
    storePayload(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        this.payload = JSON.parse(window.atob(base64));
    }
    storeToken(token: any) {
        AuthSettings.setToken(token.token);
    }

    public set RedirectUrl (url: string) {
        this.redirectUrl = url;
    }

    public get RedirectUrl(): string {
        return this.redirectUrl;
    }

    login(email: string, password: string) {
        return this.http.post<any>(ApiSettings.BASE_URL + '/' + ApiSettings.AUTH_ENDPOINT, { email, password }).pipe(
                        switchMap(token => {
                            this.storeToken(token);
                            this.isLoggedIn$.next(true);
                            const url = JSON.parse(sessionStorage.getItem(AuthSettings.REDIRECT_KEY)) || '';
                            if (!url || url === '/') {
                                this.RedirectUrl = '';
                            } else {
                                this.RedirectUrl = url;
                            }
                            sessionStorage.removeItem(AuthSettings.REDIRECT_KEY);
                            this.router.navigateByUrl('');
                            return observableOf(true);
                        }));
    }

    logout() {
        AuthSettings.emptyToken();
        this.RedirectUrl = '';
        this.isLoggedIn$.next(false);
        this.router.navigate(['/login']);
    }

    ngOnDestroy() {
        this.isLoggedInSubscription.unsubscribe();
    }
}
