import {NewsInstancesService} from '../services/news-instances.service';

export default class RangeOffsetModel {
  get start(): number{
    return this.startOffset
  }

  get end(): number{
    return this.endOffset
  }

  get length(): number{
    return this.end - this.start;
  }

  public constructor(private startOffset: number, private endOffset: number, public relativeXpath: string) {
  }

  public shift(numbrOfCharactersToShift: number): RangeOffsetModel {
    this.startOffset += numbrOfCharactersToShift;
    this.endOffset += numbrOfCharactersToShift;
    return this;
  }

  public static compare(a: RangeOffsetModel, b: RangeOffsetModel): number {
    if (a.relativeXpath === b.relativeXpath && a.start === b.start && a.end === b.end)
      return 0
    else
      return 1
  }
}

