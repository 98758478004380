<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Details</h3>
            </div>
        </div>
    </div>
    <con-loader *ngIf="isLoading()"></con-loader>

  <div class="alert ml-status-created" *ngIf="entityLockedByMl(entity) && (entityLockedByMlStatus(entity) === MLSendStatus.CREATED)">
    <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.CREATED_MESSAGE}}
  </div>
  <div class="alert ml-status-in-progress" *ngIf="entityLockedByMl(entity) && (entityLockedByMlStatus(entity) === MLSendStatus.IN_PROGRESS)">
    <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.IN_PROGRESS_MESSAGE}}
  </div>
  <div class="alert ml-status-submitted" *ngIf="entityLockedByMl(entity) && (entityLockedByMlStatus(entity) === MLSendStatus.SUBMITTED)">
    <con-icon-by-name iconName="gear"></con-icon-by-name> {{MLSendStatus.SUBMITTED_MESSAGE}}
  </div>

    <div class="row" *ngIf="!isLoading()">
        <div class="col-md-12" *ngIf="!profile.canOr([profile.getPermissionName(entityName, 'show'), profile.getPermissionName(entityName, 'update')])">
            <p class="lead">Not authorized to change or see this object</p>
        </div>

        <div class="col-lg-12 col-xl-6" *ngIf="profile.can(profile.getPermissionName(entityName, 'show'))">
          <con-report-file-editor *ngIf="entityName === 'CompanyReportFile'" context="EDIT_REPORT_FILE" [companyReportFile]="entity"   (afterSave)="reportFileUpdated($event)"></con-report-file-editor>
          <con-entity-form
              [fromDetailPage]="true"
              (afterSave)="entityUpdated($event)"
              [canEdit]="!entityLockedByMl(entity)"
              [entity]="entity"
              [entityName]="entityName"
              *ngIf="entityName !== 'CompanyReportFile'"></con-entity-form>
        </div>
        <div class="col-lg-12 col-xl-6" *ngIf="profile.can(profile.getPermissionName(entityName, 'show'))">
            <div class="card mb-2">
                <div class="card-header cursor-pointer" role="tab" (click)="toggleComponent('::inspect')">
                    <div class="d-flex justify-content-start">
                        <con-icon-by-name iconName="code" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        Inspect
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="showComponent('::inspect') ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <con-object-inspector [object]="entity" *ngIf="showComponent('::inspect')"></con-object-inspector>
            </div>
            <div class="card mb-2">
                <div class="card-header cursor-pointer" role="tab" (click)="toggleComponent('::history')">
                    <div class="d-flex justify-content-start">
                        <con-icon-by-name iconName="history" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        History
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="showComponent('::history') ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="showComponent('::history')">
                    <con-entity-history-list [entityName]="entityName" [entity]="entity"></con-entity-history-list>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isLoading()">
        <h3 *ngIf="entityDescription?.getRelationsOutsideForm().length">Relations</h3>
        <ng-container *ngFor="let relation of entityDescription?.getRelationsOutsideForm()">
            <div class="row" *ngIf="profile.canOr([profile.getPermissionName(entityName, 'show', relation.name),
                                                   profile.getPermissionName(entityName, 'update', relation.name)])">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header cursor-pointer" role="tab" (click)="toggleComponent(relation.name)">
                            <div class="d-flex justify-content-start">
                                {{ relation.label }}
                                <div class="card-header-toggle ml-auto">
                                    <con-icon-by-name iconName="angle-right" [mutations]="showComponent(relation.name) ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showComponent(relation.name)">
                            <con-to-many-editor
                                    *ngIf="shouldUseToManyEditor(relation)"
                                    [canShow]="profile.can(profile.getPermissionName(entityName, 'show', relation.name))"
                                    [canEdit]="profile.canAnd([profile.getPermissionName(entityName, 'update', relation.name),
                                                               profile.getPermissionName(relation.model, 'index')])"
                                    [relation]="relation"
                                    [entity]="entity"
                                   >
                            </con-to-many-editor>
                            <con-belongs-to-editor
                                    *ngIf="shouldUseBelongsToEditor(relation)"
                                    [canShow]="profile.can(profile.getPermissionName(entityName, 'show', relation.name))"
                                    [canEdit]="profile.can(profile.getPermissionName(entityName, 'update', relation.name))
                                            && profile.canOr(profile.getPermissionNames(relation.model ? [relation.model] : relation.models, 'index'))"
                                    [relation]="relation"
                                    [entity]="entity"
                                    >
                            </con-belongs-to-editor>
                            <con-has-one-editor *ngIf="shouldUseHasOneEditor(relation)"
                                            [canShow]="profile.can(profile.getPermissionName(entityName, 'show', relation.name))"
                                            [canEdit]="profile.canAnd([profile.getPermissionName(entityName, 'update', relation.name),
                                                                       profile.getPermissionName(relation.model, 'index')])"
                                            [canEditRequired]="profile.canAnd([profile.getPermissionName(entityName, 'update', relation.name),
                                                                                profile.getPermissionName(relation.model, 'store'),
                                                                                profile.getPermissionName(relation.model, 'update'),
                                                                                profile.getPermissionName(relation.model, 'delete')])"
                                            [relation]="relation"
                                            [entity]="entity"
                                            >
                            </con-has-one-editor>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
