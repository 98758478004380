import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilService {
    private intervalTimer: any;
    constructor() {}

    isNumeric(str: string) {
        if (typeof str === 'string') {
            return str.split('').some(char => Number(char) >= 0 || Number(char) <= 0);
        } else { return true; }
    }

    isDecimal(n: any) {
        const regex = /^[+-]?(\d*\.)?\d+$/g
        const passedCheck = regex.test(n);
        return passedCheck

    }

    isValidDate(str: string) {
        if (parseInt(str.split('-')[2], 10) > 31) {
            return false;
        }
        const regex = /^(\d{4})-(\d{2})-(\d{2}) ?((\d{2}):(\d{2}):(\d{2}))?$/g
        const passedCheck = regex.test(str);
        return passedCheck;
    }
    getConfirmation(message: string) {
        const hasConfirmed = confirm(message);
        if (hasConfirmed === true) {
            return true;
        } else {
            return false;
        }
    }
    isJsonString(json: string) {
        const testValue = json.toString();
        try {
            JSON.parse(testValue);
        } catch (e) {
            return false;
        }
        return true;
    }
    getMilliSeconds(num: number) {
        return num * 1000;
    }
    refreshPage(seconds: number, callback: () => void) {
        const milis = this.getMilliSeconds(seconds)
        this.intervalTimer = setInterval(() => callback(), milis);
    }
    stopRefreshingPage() {
        clearInterval(this.intervalTimer);
    }
    onlyUnique(value, index, self) {
        if (!value) {
            return;
        }
        return self.indexOf(value) === index;
    }
    flatMap<T, U>(array: T[], mapFunc: (x: T) => U[]): U[] {
        return array.reduce((cumulus: U[], next: T) => [...mapFunc(next), ...cumulus], <U[]> []);
    }
}
