<div class="sidebar">
    <con-loader *ngIf="loading"></con-loader>
    <nav class="nav flex-column" *ngIf="!loading">
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['company']" routerLinkActive="active" (click)="selectedType('Company')">
                Company
                <span class="badge badge-pill badge-danger">{{ counts['companies'] }}</span>
            </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('Company')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body"  placement="right" 
                (click)="this.loadPage('Company', $event)">
                    <con-icon-by-name iconName="refresh"></con-icon-by-name>
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['institution']" routerLinkActive="active"
            (click)="selectedType('Institution')">
                Institution
                <span class="badge badge-pill badge-danger">{{ counts['institutions'] }}</span>
            </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('Institution')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events"
                    container="body" placement="right" (click)="this.loadPage('Institution', $event)">
                    <con-icon-by-name iconName="refresh"></con-icon-by-name>
                </button>
            </div>
        </div>
    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
