import { Component, OnInit, OnDestroy } from '@angular/core';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { Subject } from 'rxjs';

import { ProfileService } from 'app/auth/services/profile.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';

@Component({
  selector: 'con-create-task',
  templateUrl: './create-task-view.component.html',
})
export class CreateTaskViewComponent implements OnInit, OnDestroy {


    public jsonDataSub = new Subject();
    public jsonData: any;
    public createDataResponse: any = '';
    public isLoading = true;
    public errMessage: string;
    public closeResult: string;
    public taskID: number;
    public modalContentLoading = true;
    public createFailed = false;
    public dropdownOptions: string[] = ['Create task', 'Create symbol', 'Create index', 'Create IHS symbol'];
    public createMode = 'task';
    private changedCreateModeSub = new Subject();

    constructor(
      private router: Router,
      private taskManagerService: TaskManagerService,
      public profile: ProfileService,
      private modalService: NgbModal,
      private subscriptionService: SubscriptionService
      ) {}

    ngOnInit() {
        this.taskManagerService.getPlaceholderJSONTaskData();

        this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: any) => {
          switch (response.type) {
            case 'createTask': {
              if (response.data.State === 'Error') {
                this.handleErrorFromApi(response.data.Exception);
                return;
              }
              this.createFailed = false;
              this.taskID = response.data.Return.task_id;
              this.modalContentLoading = false;
            } break;
            case 'createSymbol': {
              if (response.data.State === 'Error') {
                this.handleErrorFromApi(response.data.Exception);
                return;
              }
              this.createFailed = false;
              this.modalContentLoading = false;
            } break;
            case 'createIndex': {
              if (response.data.State === 'Error') {
                this.handleErrorFromApi(response.data.Exception);
                return;
              }
              this.createFailed = false;
              this.modalContentLoading = false;
            } break;
            case 'createVWDSymbol': {
              if (response.data.State === 'Error') {
                this.handleErrorFromApi(response.data.Exception);
                return;
              }
              this.createFailed = false;
              this.modalContentLoading = false;
            } break;
            case 'createIhsSymbol': {
              if (response.data.State === 'Error') {
                this.handleErrorFromApi(response.data.Exception);
                return;
              }
              this.createFailed = false;
              this.modalContentLoading = false;
            } break;
            case 'getPlaceholderJSONIhsData':
            case 'getPlaceholderJSONTaskData':
            case 'getPlaceholderJSONSymbolsData':
            case 'getPlaceholderJSONIndexData': {
              this.jsonData = response.data;
              this.jsonDataSub.next(response.data);
              this.isLoading = false;
              this.createDataResponse = '';
            } break;
          }

        });
        this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe(err => {
          this.isLoading = false;
          this.errMessage = err['data'];
        });
    }
    ngOnDestroy(): void {
      this.subscriptionService.apiResponseSub.unsubscribe();
      this.subscriptionService.apiErrorSub.unsubscribe();
    }

    requestAction(action: string) {
      switch (action) {
        case 'task': {
          this.taskManagerService.getPlaceholderJSONTaskData();
        } break;
        case 'symbol': {
          this.taskManagerService.getPlaceholderJSONSymbolsData();
        } break;
        case 'index': {
          this.taskManagerService.getPlaceholderJSONIndexData();
        } break;
        case 'ihs': {
          this.taskManagerService.getPlaceholderJsonIhsData();
        } break;
      }
    }
    redirectToTask(id: number) {
      this.router.navigate(['task_manager', 'tasks', id]);
    }

    handleErrorFromApi(exception: string) {
      this.createFailed = true;
      this.errMessage = exception;
      this.modalContentLoading = false;
    }

    open(content: any) {
      this.resetFeedbackValues();
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    createTask(jsonData: string) {
      this.taskManagerService.createTask(jsonData);
    }
    createSymbol(jsonData: string) {
      this.taskManagerService.createSymbol(jsonData);
    }
    createIndex(jsonData: string) {
      this.taskManagerService.createIndex(jsonData);
    }
    createIHS(jsonData: string) {
      this.taskManagerService.createIHSSymbol(jsonData);
    }
    createAnotherTask() {
      this.taskManagerService.getPlaceholderJSONTaskData();
    }
    createAnotherSymbol() {
      this.taskManagerService.getPlaceholderJSONSymbolsData();
    }
    createAnotherIndex() {
      this.taskManagerService.getPlaceholderJSONIndexData();
    }
    createAnotherIHSSymbol() {
      this.taskManagerService.getPlaceholderJsonIhsData();
    }

    resetFeedbackValues() {
      this.errMessage = '';
      this.createFailed = false;
      this.createDataResponse = '';
    }
    create(body: string) {
      if (this.createMode === 'IHS') {
        this.createMode = 'IHS symbol';
      }
      switch (this.createMode) {
        case 'task': return this.createTask(body);
        case 'symbol': return this.createSymbol(body);
        case 'index': return this.createIndex(body);
        case 'IHS symbol': return this.createIHS(body);
      }
    }

    proceedToTask() {
      this.redirectToTask(this.taskID);
    }

    private getDismissReason(reason: any): string {
      switch (reason) {
        case ModalDismissReasons.ESC: return 'by pressing ESC';
        case ModalDismissReasons.BACKDROP_CLICK: return 'by clicking on a backdrop';
        default: return `with: ${reason}`;

      }
    }
    changeMode(mode: string) {
      this.createMode = mode.split(' ')[1];
      switch (mode) {
        case 'Create task': return this.requestAction('task');
        case 'Create symbol': return this.requestAction('symbol');
        case 'Create index': return this.requestAction('index');
        case 'Create IHS symbol': return this.requestAction('ihs');
      }
    }
}
