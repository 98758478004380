<con-loader [showCircularLoder]="false" *ngIf="isLoading() || progressLoader"></con-loader>
<div *ngIf="!loading && !hidden" class="table-responsive">
    <span *ngIf="!_canShow && !_canEdit">
        You are not allowed to see/edit this entity
    </span>
    <span *ngIf="!_canStore">
        You are not allowed to create new entities of this type
    </span>
    <form *ngIf="(_canEdit || _canStore) && (_canShow)" [formGroup]="entityForm" novalidate>
    <div class="table">
        <div class="table-row">
            <div class="table-header">
                <div  class="table-row-column table-row-index"*ngFor="let field of fields">
                    {{field.label}} <span *ngIf="field.label==='Votes'||field.label==='Capital'"> (%)</span>
                </div>
                <div class="table-row-column table-row-index" *ngFor="let relation of getBelongsToRelations()">
                    {{relation.label}}
                </div>
            </div>
                <div class="row-header-button"></div>
        </div>

        <div formArrayName="items" >
            <div class="row-container" *ngFor="let row of items.controls; let index  = index; let last = last; let first = first" [formGroupName]="index">
                <div class="table-row">
                <div class="row-data-fields">
                <div  class="table-row-column table-row-center" *ngFor="let field of fields">
                    <span *ngIf="entityAlreadyExists(index) && !editMode(index)"
                        class="form-control alert-info wrap-text"
                        ngbTooltip="{{row.get(field.key).value}}">
                        <span *ngIf="field.key === 'shares'">
                            {{row.get(field.key).value | stringify | imask: mask}}
                        </span>
                        <span *ngIf="field.key != 'shares'">
                            {{row.get(field.key).value}}
                        </span>
                    </span>
                    <con-flexible-input *ngIf="!entityAlreadyExists(index) || (entityAlreadyExists(index) && editMode(index))" [control]="row.get(field.key)" [field]="field"></con-flexible-input>
                    <con-form-errors [control]="items.get(field.key)" [extra]="fieldErrors[index][field.key]"></con-form-errors>
                </div>
                <div  class="table-row-column table-row-center" *ngFor="let relation of getBelongsToRelations()">
                    <con-belongs-to-form
                            [entity]="row"
                            [form]="entityForm"
                            [relation]="relation"
                            (onSelect)="resetErrorsForRelation(relation, $event)">
                    </con-belongs-to-form>
                </div>
                </div>
                <div class="row-button-fields" *ngIf="!isLocked">
                <div *ngIf="entityAlreadyExists(index) && !editMode(index)"  class="table-row-column table-row-center table-button ">
                    <button type="button" (click)="editEvent(index, true)" class="btn btn-success">Edit</button>
                </div>
                <div  *ngIf="!entityAlreadyExists(index) || (entityAlreadyExists(index) && editMode(index))" class="table-row-column table-row-center table-button">
                    <button type="submit" (click)="onSubmit(index)" class="btn btn-success">Save</button>
                </div>
                <div *ngIf="(entityAlreadyExists(index) && editMode(index))" class="table-row-column table-row-center table-button ">
                    <button type="button" (click)="editEvent(index, false)" class="btn btn-default cancel-btn">Cancel</button>
                </div>
                <!-- <div *ngIf="!entityAlreadyExists(index) || (entityAlreadyExists(index) && editMode(index))">
                    <button type="reset" (click)="revert(index)" [disabled]="isFormArrayValid(index)" class="btn btn-warning">Reset</button>
                </div> -->
                <div  *ngIf="_canDelete && entityAlreadyExists(index) && editMode(index)" class="table-row-column table-row-center table-button ">
                    <button type="button" (click)="delete(index)"
                        class="btn btn-danger">Delete</button>
                </div>
                <div *ngIf="!entityAlreadyExists(index) && entityList.length && !first" class="table-row-column table-row-center table-button add-remove-btn ">
                    <button type="button"  (click)="removeRow(index )"
                        class="btn btn-danger"><con-icon-by-name iconName="minus-circle"></con-icon-by-name> </button>
                </div>
                <div *ngIf="last" class="table-row-column table-row-center table-button add-remove-btn">
                    <button type="button" [disabled]="!entityAlreadyExists(index)" (click)="addRow()"
                        class="btn btn-primary"><con-icon-by-name iconName="plus-circle"></con-icon-by-name> </button>
                </div>
                </div>
                <div class="loader circular-loader-container row-loader" *ngIf="selectedIndex === index && progressLoader">
                    <div class="circular-loader">
                       <svg class="circular" viewBox="25 25 50 50">
                          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger mt-2 mb-2" *ngFor="let message of generalErrors[index]">
                {{ message }}
            </div>
        </div>
            <div class="alert alert-danger mt-2 mb-2" *ngFor="let message of generalSaveAllErrors">
              {{ message }}
            </div>
            <div *ngIf="!isLocked">
              <button (click)="saveAll()" class="btn btn-success save-all-pos" [disabled]="progressLoader">Save All</button>
              <button (click)="revertAll()" class="btn btn-warning save-all-pos" [disabled]="progressLoader">Revert</button>
            </div>
        </div>
    </div>
    </form>
</div>
