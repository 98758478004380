import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class TableService {

      changedTableData: BehaviorSubject<boolean> = new BehaviorSubject(false) // TODO refactor me into a subject. I'm just a stateless global event emitter.
      constructor() {}
  }
