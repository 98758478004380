<ng-template #rt let-r="result" let-t="term">
 {{r.label}}
</ng-template>

<div class="form-group half-width" [class.has-danger]="searchFailed">
  <div class="input-group">
    <div class="input-group-prepend">
    </div>
    <input  type="search" class="form-control" [(ngModel)]="model" (ngModelChange)="entitySelected($event)"
           [ngbTypeahead]="search" [resultTemplate]="rt" placeholder="Search report files to preview" #searchInput/>
    <div class="input-group-append" *ngIf="searching">
      <span class="input-group-text"><con-loader></con-loader></span>
    </div>
    <div *ngIf="showDismiss" (click)="dismiss()">
      <button class="btn btn-light">
        <con-icon-by-name iconName="remove"></con-icon-by-name>
      </button>
    </div>
  </div>
  <div class="form-control-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</div>
</div>
