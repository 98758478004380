<div class="my-3" *ngIf="!isLoading">
    <div class="card">
        <div class="card-header cursor-pointer" (click)="toggleExpansion()">
            <div class="d-flex justify-content-start">
                <con-icon-by-name iconName="calendar-minus-o" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                <span>Missing company events filter</span>
                <div class="card-header-toggle ml-auto">
                    <con-icon-by-name iconName="angle-right" [mutations]="isFilterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                </div>
            </div>
        </div>
        <div class="card-body" [hidden]="!isFilterExpanded">
            <form [formGroup]="filterForm" (ngSubmit)="filter()">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label> <small>Company Tier </small></label>
                            <ng-container *ngIf="!dropdownFieldValues.tier; else selectedTierInfo">
                                <con-entity-search-field entityName="CompanyTier" (onSelect)="onDropdownValueSelected($event, 'tier')"></con-entity-search-field>
                            </ng-container>
                            <ng-template #selectedTierInfo>
                                <div class="alert alert-info alert-dismissible mb-0">
                                    <button type="button" class="close" aria-label="Close" (click)="clearSelectedDropdownValues('tier')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <a class="alert-link-icon mr-1" [routerLink]="['/entity/', 'company_tier', dropdownFieldValues.tier?.id]" placement="top" container="body"[ngbTooltip]="'Go to ' + 'company tier'">
                                        <con-icon-by-name [iconName]="'graduation-cap'" [mutations]="['fw']"></con-icon-by-name>
                                    </a>
                                    <strong>{{ dropdownFieldValues.tier?.name }}</strong>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label> <small>Universe </small></label>
                            <ng-container *ngIf="!dropdownFieldValues.universe; else selectedUniverseInfo">
                                <con-entity-search-field entityName="CompanyUniverse" (onSelect)="onDropdownValueSelected($event, 'universe')"></con-entity-search-field>
                            </ng-container>
                            <ng-template #selectedUniverseInfo>
                                <div class="alert alert-info alert-dismissible mb-0">
                                    <button type="button" class="close" aria-label="Close" (click)="clearSelectedDropdownValues('universe')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <a class="alert-link-icon mr-1" [routerLink]="['/entity/', 'company_universe', dropdownFieldValues.universe?.id]" placement="top" container="body"[ngbTooltip]="'Go to ' + 'company universe'">
                                        <con-icon-by-name [iconName]="'globe'" [mutations]="['fw']"></con-icon-by-name>
                                    </a>
                                    <strong>{{ dropdownFieldValues.universe?.name }}</strong>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label> <small>Event Types </small></label>
                            <div>
                                <ng-container *ngFor="let type of eventTypes; let index = index">
                                    <button type="button" class="btn btn-sm mr-1 my-1" [ngClass]="{ 'btn-outline-info': !type.isSelected, 'btn-info': type.isSelected }" placement="top" [ngbTooltip]="type.isSelected ? 'Remove from filter' : 'Add to Filter'" (click)="toggleEventTypeFilter(type)">
                                        {{ type.name }}
                                    </button>
                                </ng-container>
                                <small *ngIf="!eventTypes.length">No event types found</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label> <small>From Date </small></label>
                            <con-date-input [control]="filterForm.controls['from_date']" [field]="{ key : 'from_date'}"></con-date-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label> <small>To Date </small></label>
                            <con-date-input [control]="filterForm.controls['to_date']" [field]="{ key : 'from_date'}"></con-date-input>
                            <div class="mt-2"></div>
                            <con-form-errors [extra]="filterForm?.errors?.date_range_error ? ['The to date must be a date after or eaqual to from date'] : []"></con-form-errors>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <button class="btn btn-success" type="submit" [disabled]="!filterForm.dirty">Filter</button>
                        <button class="btn btn-warning ml-1" (click)="reset()" type="button">Clear</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>