<div class="modal-header">
    <h5 class="modal-title">Estimates manual onboarding</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">
            <con-icon-by-name iconName="close"></con-icon-by-name>
        </span>
    </button>
</div>
<form [formGroup]="importForm" #formSelector="ngForm" (ngSubmit)="importExcel()">
    <div class="modal-body">
        <div class="form-group px-2">
            <label for="file">
                <small>File</small>
            </label>
            <ng-container *ngIf="!selectedFile; else fileDetails">
                <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="onFileSelection($event)" accept=".xlsx" [multiple]="false">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <span>Drop File or </span>
                        <button type="button" (click)="openFileSelector()" class='btn btn-primary ml-15'>Browse</button>
                    </ng-template>
                </ngx-file-drop>
                <ng-container *ngIf="(importForm.controls['workbook']?.invalid && (formSelector.submitted || importForm.controls['workbook'].touched)) && !isInvalidFile">
                    <span class="form-control-feedback">This field is required</span>
                </ng-container>
                <ng-container *ngIf="isInvalidFile">
                    <span class="form-control-feedback">Please select a valid xlsx file.</span>
                </ng-container>
            </ng-container>
            <ng-template #fileDetails>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="w-100">
                        <strong>{{ selectedFile?.name }}</strong>
                        <ng-container *ngIf="isUploading">
                            <div> {{ selectedFile.size/1024/1024 | number:'.2-2' }} MB</div>
                            <div class="progress mt-2">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploadProgress + '%' }"></div>
                            </div>
                        </ng-container>
                    </div>
                    <button class="btn btn-sm btn-danger" (click)='emptyFile()' [ngbTooltip]="'Remove selected file'" *ngIf="importForm?.value?.workbook">
                        <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                    </button>
                </div>
            </ng-template>
            <ul *ngIf="fileDataErrors.length" class="list-unstyled">
                <li *ngFor="let error of fileDataErrors" class="form-control-feedback"> {{ error }} </li>
            </ul>
        </div>
        <div class="form-group px-2">
            <label for="consensusType">
                <small>Consensus type</small>
            </label>
            <select class="form-control" id="consensusType" name="consensusType" formControlName="consensus_type">
                <option [value]="''">Select type</option>
                <option *ngFor="let type of consensusTypeOptions" [value]="type.id">{{ type.name }}</option>
            </select>
            <ng-container *ngIf="importForm.controls['consensus_type']?.invalid && (formSelector.submitted ||importForm.controls['consensus_type'].touched)">
                <span class="form-control-feedback">This field is required</span>
            </ng-container>
        </div>
        <div class="px-2">
            <p class="mb-0"><small>* Figures will have a quantity of {{quantity?.name || 'corresponding consensus'}}, except for KPI figures which have their own specified quantities.</small></p>   
        </div>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="isImporting; else buttonSection">
            <con-loader [isSmallLoader]="true"></con-loader>
        </ng-container>
        <ng-template #buttonSection>
            <button class="btn btn-secondary" type="button" (click)="activeModal.dismiss()">Cancel</button>
            <button class="btn btn-primary" type="submit" [disabled]="!importForm.dirty">Import</button>
        </ng-template>
    </div>
</form>