import { Component, Input, OnChanges } from '@angular/core';
import { ProfileService } from '../../../auth/services/profile.service';

import { EntitiesService } from '../../../entities/services/entities.service';
import { ApiSettings, ListsSettings } from '../../../settings.class';

@Component({
  selector: 'con-tier-ignored-institutions',
  templateUrl: './tier-ignored-institutions.component.html'
})

export class TierIgnoredInstitutionsComponent implements OnChanges {
    @Input() tierId: number;
    public canView = false;
    public result: any;
    public loading = false;
    private institutionName = '';

    constructor(private entities: EntitiesService,
                private profile: ProfileService) {}

    ngOnChanges() {
        if (this.profile.can(this.profile.getPermissionName('InstitutionTier', 'show', 'Institutions'))) {
            this.canView = true;
            this.loadPage(1);
        }
    }

    updateInstitution(institution: any) {
        const index = this.result.data.findIndex(c => c.id === institution.id);
        if (index > -1) {
            this.result.data[index] = institution;
        }
    }

    removeInstitution(institution: any) {
        const index = this.result.data.findIndex(c => c.id === institution.id);
        if (index > -1) {
            this.result.data.splice(index, 1);
        }
    }

    searchChanged(name: string) {
        this.institutionName = name;

        this.loadPage(this.result.current_page);
    }

    loadPage(pageNumber: number) {
        this.loading = true;
        this.entities.searchRelation(
                'InstitutionTier',
                this.tierId,
                'ignoredInstitutions',
                {
                    name: this.institutionName
                }
            )
            .subscribe(
                res => {
                    this.loading = false;
                    this.result = res;
                },
                err => console.log(err)
            )
    }
}
