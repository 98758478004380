<div class="login-container">
    <div class="row">
        <div class="col-12">
            <div class="card p-5">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="logo mb-1">
                        <con-icon-by-name iconName="plug"></con-icon-by-name> connector
                    </div>
                </div>
                <p class="text-muted text-center">Sign in to your account</p>
                <form novalidate>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><con-icon-by-name iconName="user" [mutations]="['fw']"></con-icon-by-name></span>
                        </div>
                        <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="email" (ngModelChange)="onChange()">
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><con-icon-by-name iconName="lock" [mutations]="['fw']"></con-icon-by-name></span>
                        </div>
                        <input type="password" class="form-control" placeholder="Password" name="password" [(ngModel)]="password" (ngModelChange)="onChange()">
                    </div>
                    <button [hidden]="loading" type="submit" class="btn btn-block btn-primary" (click)="login()">Login</button>

                    <con-loader *ngIf="loading"></con-loader>
                    <div class="alert alert-danger text-center mt-2 mb-0" *ngIf="hasErrors">
                        <con-icon-by-name iconName="exclamation-triangle" class="mr-1"></con-icon-by-name><strong>Wrong credentials!</strong>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
