import { Component, OnInit } from '@angular/core';
import { CompanyReportsSettings } from '../../settings.class';
import { Router } from '@angular/router';
import { ProfileService } from '../../auth/services/profile.service';
import { CompanyReportsService } from '../../company-reports/services/company-reports.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'con-estimates-start',
  templateUrl: './estimates-start.component.html'
})
export class EstimatesStartComponent implements OnInit {

  // todo instead of company report tier context check estimate context is needed
  public tierId: number = CompanyReportsSettings.TIER_ID;
  public recentList: any[];
  public lsName = 'recentCompanies';
  constructor(
    private router: Router,
    public profile: ProfileService,
    private reportService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.recentList = this.reportService.getRecent('recentCompanies');
    this.reportService.recentSearchUpdated.subscribe(data => {
      this.recentList = this.reportService.getRecent('recentCompanies');
    })
  }

  public navigateToCompany(company: any) {
    const recentData = {
      id: company.id,
      name: company.name
    };
    this.reportService.setRecent(this.lsName, recentData);
    this.router.navigate(['estimates', 'company', company.id]);

  }
  public removeFromList(company) {
    this.reportService.removeFromRecent('recentCompanies', company, true)
  }
}

