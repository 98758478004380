<div class="card">
    <div class="card-header">
        Passed unconfirmed events
        <button class="btn" ngbTooltip="Refresh" container="body" (click)="refresh()">
            <con-icon-by-name iconName="refresh" [mutations]="['fw']">
            </con-icon-by-name>
        </button>
    </div>
    <div class="card-body">
        <con-loader *ngIf="loading"></con-loader>
        <div *ngIf="!loading">
            <div class="card" *ngFor="let e of events?.data">
                <div class="card-header">
                    {{ e.owner.name }} - {{ e.calendar_event_type.name }} - {{ e.from_date | amLocal | amDateFormat:'yyyy-MM-DD HH:ss' }}
                </div>
                <div class="card-body">
                    <span>
                        {{ e.comment }}
                    </span>
                    <p class="lead" *ngIf="!profile.can(profile.getPermissionName('CalendarEvent', 'update'))">
                        You are not authorized to update company events
                    </p>
                    <div *ngIf="profile.can(profile.getPermissionName('CalendarEvent', 'update'))">
                        <button class="btn btn-primary" (click)="toggleEvent(e.id)">Edit</button>
                        <button class="btn btn-success" (click)="confirmEvent(e)" *ngIf="!confirming[e.id]">Confirm</button>
                        <con-loader *ngIf="confirming[e.id]"></con-loader>
                    </div>
                    <con-custom-entity-form-content entityName="CalendarEvent" [hidden]="!expansionDict[e.id]" [entity]="e" [except]="['direkt_id']" [fixedValues]="{ owner_id: e.company_id, owner_type: ownerType, revised_at: now }"
                    [hasFixedCalendarValues]="true" [handleFullDayEvent]="true" [enableRecurringEvent]="true" (afterSave)="removeFromList($event.id)" (afterDelete)="removeFromList($event.id)"></con-custom-entity-form-content>
                </div>
            </div>
            <con-no-data [count]="events?.data.length" [message]="noDataMessage"></con-no-data>
        </div>
        <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!events?.data.length}">
            <ngb-pagination [collectionSize]="events?.total" [(page)]="page" (pageChange)="changePage($event)" [pageSize]="events?.per_page"
                maxSize="10" [rotate]="true"></ngb-pagination>
        </div>
    </div>
</div>
