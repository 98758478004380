import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { EntitiesModule } from '../entities/entities.module';

import { ShareholdersComponent } from './layout/shareholders.component';
import { ShareholdersOutdatedComponent } from './layout/shareholders-outdated.component';
import { ShareholdersMissingComponent } from './layout/shareholders-missing.component';
import { ShareholdersStartComponent } from './layout/shareholders-start.component';
import { ShareholdersCompanyComponent } from './layout/shareholders-company.component';

import { ShareholdersEditorComponent } from './components/shareholders-editor.component';

import { CountService } from './services/count.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        NgbModule,
        AuthModule,
        EntitiesModule
    ],
    providers: [
        CountService
    ],
    declarations: [
        ShareholdersComponent,
        ShareholdersOutdatedComponent,
        ShareholdersMissingComponent,
        ShareholdersStartComponent,
        ShareholdersCompanyComponent,
        ShareholdersEditorComponent
    ],
    exports: []
})
export class ShareholdersModule {}
