import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { MozendaDeliveriesSettings } from '../../settings.class';

import { Observable ,  ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class MozendaService {
    private countsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
    private countRequest: Observable<any>;
    public reloadSubject = new Subject();
    private loading = false;
    private counts: any;
    public loadingCompleted = false;

    constructor(private http: HttpClient) {
        this.getCounts().subscribe(counts => {
            this.counts = counts;
        });
    }

    public decrementCount(type: string) {
        if (this.counts && Number.isInteger(this.counts[type])) {
            this.counts[type]--;
            this.countsSubject.next(this.counts);
        }
    }

    public setCount(type: string, count: number) {
        this.loadingCompleted = true;
        if (this.counts && this.counts[type] !== count) {
            this.counts[type] = count;
            this.countsSubject.next(this.counts);
        }
    }

    getCounts(refresh: boolean = false): Observable<any> {
        if (!this.countRequest || refresh) {
            this.loading = true;
            this.countRequest = this.http.get(MozendaDeliveriesSettings.BASE_URL + '/' + MozendaDeliveriesSettings.COUNTS_ENDPOINT);

            this.countRequest.subscribe(res => {
                                            this.countsSubject.next(res);
                                            this.loading = false;
                                        },
                                        err => {
                                            this.countsSubject.error(err);
                                            this.loading = false;
                                        })
        }
        return this.countsSubject.asObservable();
    }

    public deleteMozendaData(id: number): Observable<any> {
        return this.http
                    .put(
                        MozendaDeliveriesSettings.BASE_URL + '/' + MozendaDeliveriesSettings.DELIVERIES_ENDPOINT + '/' + id + '/destroy', {}
                    );
    }
    public deleteSelectedMozendaData(ids: number[]): Observable<any> {
        return this.http
                    .put(
                        MozendaDeliveriesSettings.BASE_URL + '/' + MozendaDeliveriesSettings.DELIVERIES_ENDPOINT  + '/destroy', {ids: ids}
                    );
    }

    isLoading() {
        return this.loading;
    }
}
