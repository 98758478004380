import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { snakeCase } from 'change-case';

@Pipe({name: 'snakeCase'})

export class SnakeCasePipe implements PipeTransform {

    transform(value: string) {
        if (value) {
            return snakeCase(value);
        }
        return value;
    }

}
