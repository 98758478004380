import { Component } from '@angular/core';

@Component({
    selector: 'con-start',
    templateUrl: './start.component.html'
})

export class StartComponent {

}
