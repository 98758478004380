import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../../auth/services/profile.service';
import {CompanyReportsSettings, MissingCompanyEventsSettings} from '../../../settings.class';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'con-company-report-events',
  templateUrl: './company-report-events.component.html',
  styleUrls: ['./company-report-events.component.scss']
})
export class CompanyReportEventsComponent implements OnInit{
  reportEventData: any;
  public noDataMessage = 'No data to display.';
  public companyTierId: number = CompanyReportsSettings.TIER_ID;
  constructor(private http: HttpClient,  public profile: ProfileService, private router: Router,) { }

  ngOnInit(): void {
    this.loadPage(1);
  }

  loadPage(page: number) {
    this.http.get<any>(CompanyReportsSettings.MISSING_EVENTS_ENDPOINT,
      {
        params: {
          page: String(page)
        }
      }).pipe(take(1))
      .subscribe(response => {
        // map data
        this.reportEventData = this.mapEventResponseData(response);
      }, err => {
        console.error(err);
      });
  }

  mapEventResponseData(eventResponseData) {
    if(eventResponseData){
      eventResponseData.data.forEach((company) => {
        //  add update event flag
        company.showAddEvent = false;
        company.expanded = true;
        // check annual key in missing event types
        if((company.missing_event_types as Array<any>).includes('annual')) {
          const annualMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'annual');
          if(annualMissingReportEvent) {
            const previousAnnualDate = annualMissingReportEvent.report_date;
            const prefilledAnnualData = {
              company_id : company.id,
              report_date: this.getAddedAnnualDate(previousAnnualDate),
              report_type: 'annual'
            }
            const previousAnnualDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'annual');
            company.missing_report_events.splice(previousAnnualDataIndex,1);
            company.missing_report_events.push(prefilledAnnualData);
          } else {
            const prefilledAnnualData = {
              company_id : company.id,
              report_date: this.getCurrentDate(),
              report_type: 'annual'
            }
            company.missing_report_events.push(prefilledAnnualData)
          }
        }
        // check interim key in missing event types
        if((company.missing_event_types as Array<any>).includes('interim')) {
          const interimMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'interim');
          if(interimMissingReportEvent) {
            const previousInterimDate = interimMissingReportEvent.report_date;
            const prefilledInterimData = {
              company_id : company.id,
              report_date: this.getAddedInterimDate(previousInterimDate),
              report_type: 'interim'
            }
            const previousInterimDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'interim');
            company.missing_report_events.splice(previousInterimDataIndex,1);
            company.missing_report_events.push(prefilledInterimData);
          } else {
            const prefilledInterimData = {
              company_id : company.id,
              report_date: this.getCurrentDate(),
              report_type: 'interim'
            }
            company.missing_report_events.push(prefilledInterimData)
          }
        }
        // set expanded flag
        company.missing_report_events.forEach((event) => {
          event.expanded = true;
        });
      })
    }
    return eventResponseData;
  }

  getCurrentDate(): string {
    return moment().format('yyyy-MM-DD').toString();
  }
  getAddedAnnualDate(date: string): string {
    return moment(date).add(1,'year').format('yyyy-MM-DD').toString();
  }
  getAddedInterimDate(date: string): string {
    return moment(date).add(3,'months').format('yyyy-MM-DD').toString();
  }

  navigateToCompany(company: any) {
    this.router.navigate(['company_reports','report-events', 'company', company.id]);
  }

  updateCompanyMissingData(companyId: number) {
    this.loadData(companyId);
  }

  loadData(companyId: number) {
    this.http.get( CompanyReportsSettings.MISSING_EVENTS_ENDPOINT + '/' + companyId + '/').subscribe((result) => {
      if(result){
        const resultObj = this.mapEventResponseSingleCompanyData(result);
        const changedItemIndex = this.reportEventData.data.findIndex(item => item.id === resultObj.id);
        if(changedItemIndex !== -1){
          this.reportEventData.data[changedItemIndex] = resultObj;
        }
      }
    },(error) => {
      console.error(error);
    });
  }

  mapEventResponseSingleCompanyData(company) {
    company.id = company.company.id;
    company.name = company.company.name;
    //  add update event flag
    company.showAddEvent = false;
    company.expanded = true;
    // check annual key in missing event types
    if((company.missing_event_types as Array<any>).includes('annual')) {
      const annualMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'annual');
      if(annualMissingReportEvent) {
        const previousAnnualDate = annualMissingReportEvent.report_date;
        const prefilledAnnualData = {
          company_id : company.id,
          report_date: this.getAddedAnnualDate(previousAnnualDate),
          report_type: 'annual'
        }
        const previousAnnualDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'annual');
        company.missing_report_events.splice(previousAnnualDataIndex,1);
        company.missing_report_events.push(prefilledAnnualData);
      } else {
        const prefilledAnnualData = {
          company_id : company.id,
          report_date: this.getCurrentDate(),
          report_type: 'annual'
        }
        company.missing_report_events.push(prefilledAnnualData)
      }
    }
    // check interim key in missing event types
    if((company.missing_event_types as Array<any>).includes('interim')) {
      const interimMissingReportEvent = (company.missing_report_events as Array<any>).find(item => item.report_type === 'interim');
      if(interimMissingReportEvent) {
        const previousInterimDate = interimMissingReportEvent.report_date;
        const prefilledInterimData = {
          company_id : company.id,
          report_date: this.getAddedInterimDate(previousInterimDate),
          report_type: 'interim'
        }
        const previousInterimDataIndex = (company.missing_report_events as Array<any>).findIndex(item => item.report_type === 'interim');
        company.missing_report_events.splice(previousInterimDataIndex,1);
        company.missing_report_events.push(prefilledInterimData);
      } else {
        const prefilledInterimData = {
          company_id : company.id,
          report_date: this.getCurrentDate(),
          report_type: 'interim'
        }
        company.missing_report_events.push(prefilledInterimData)
      }
    }
    // set expanded flag
    company.missing_report_events.forEach((event) => {
      event.expanded = true;
    });
    return company;
  }


}
