<div class="mat-elevation-z0">
  <table mat-table matSort [dataSource]="this.dataSource" multiTemplateDataRows>
        <ng-container *ngFor="let column of columnsToDisplay; let i = index" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>
                <div class="d-flex">
                    <mat-checkbox
                        *ngIf="isSelectable && isSelectableColumn(i)"
                        (change)="$event ? masterToggle(i) : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                    <div mat-sort-header [disabled]="!isSortable">
                        {{column !== 'meta_flag_list' ? this.utilService.prettify(column) : 'Comments'}}
                    </div>
                </div>
            </th>
            <td mat-cell
              *matCellDef="let element"
              [ngClass]="{'selectable': isClickable && isClickableColumn(i), 'text-primary': isClickable && isClickableColumn(i) && makeLinksPrimaryColor}"
              [class]="fieldClasses[dataSource.filteredData.indexOf(element)]"
              (click)="isClickable && isClickableColumn(i) && !isEditable ? onRowClick(element): null"
            >
                <ng-container *ngIf="isSelectable && isSelectableColumn(i)">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? toggleSelection(element[column]) : null; selectedOne(element)"
                        [checked]="selection.isSelected(element[column])">
                    </mat-checkbox>
                </ng-container>

                <ng-container *ngIf="isEditable">
                    <mat-form-field [ngClass]="{'border': isEditable, 'selectable': isClickable && isClickableColumn(i) && !isEditable}" appearance="outline">
                        <input
                            [ngClass]="{'selectable': isClickable && isClickableColumn(i) && !isEditable}"
                            matInput
                            [readonly]="!isEditable"
                            [value]="element[column]"
                        >
                        <button
                            mat-button
                            *ngIf="element[column] && isEditable"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="element[column]=null; onClearInput(element)"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="column !== 'meta_flag_list'">
                  <ng-container *ngIf="element['meta_connector_language_abbreviation']">
                    <con-flag-icon [connectorLanguageAbbreviation]="element['meta_connector_language_abbreviation']"></con-flag-icon>
                  </ng-container>
                  <span
                    (click)="element['meta_link'] ? window.open(element['meta_link'], '_blank') : null"
                    style="cursor: pointer;"
                    [ngStyle]="{'cursor': element['meta_link'] ? 'pointer' : null}"
                  >
                    {{element[column]}}
                  </span>
                </ng-container>

              <ng-container *ngIf="column === 'meta_flag_list'">
                <ng-container *ngFor="let flagAbbr of element['meta_flag_list']">
                  <con-flag-icon [connectorLanguageAbbreviation]="flagAbbr"></con-flag-icon>
                </ng-container>
              </ng-container>

            </td>
        </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!!element.expand">
            <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="(isExpandable ? columnsToDisplayWithExpand : columnsToDisplay).length">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <con-material-table
              [data]="element.expandData"
              [hideTableHeader]="true"
              [columnsToHide]="['meta_link', 'meta_connector_language_abbreviation']"
              [metaLink]="true"
              style="width: 100%"
            >
            </con-material-table>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="isExpandable ? columnsToDisplayWithExpand : columnsToDisplay" [hidden]="hideTableHeader"></tr>
      <tr mat-row *matRowDef="let element; columns: isExpandable ? columnsToDisplayWithExpand : columnsToDisplay"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      ></tr>
      <ng-container *ngIf="isExpandable">
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </ng-container>
    </table>
    <ng-container *ngIf="isPaginated">
        <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
    </ng-container>
</div>
