<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Report</h3>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12'>
      <div class='d-flex justify-content-start py-2 sticky-header non-sticky'>
        <h3 class='mb-0'><span class='badge badge-dark'>{{company?.name}}</span></h3>
        <span  class='website-link-positioning'>
           <a class='link-fontsize' target='_blank'[href]='company?.website' >{{company?.website}}</a></span>
        <div class="ml-auto">

          <div class='chip chip-inverse'>
            <con-avatar [userId]="" [size]="32"></con-avatar>
            <span >Viktor Karlson</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Report view  -->
  <!-- 1 -->
  <div class="card mb-2">
    <div class="card-header">
      <div class="d-flex justify-content-start align-items-center">
        <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
       Create Report
        <div class="card-header-toggle-individual ml-auto">
          <con-icon-by-name iconName="angle-right"
                            [mutations]="['fw', 'lg']"></con-icon-by-name>
        </div>
      </div>
    </div>
    <div class="p-4 col-6">
      <con-entity-form-content entityName="CompanyReport">
      </con-entity-form-content>
    </div>
  </div>

  <!-- list items start -->

  <div>
    <!-- 1 -->
    <div class="card mb-2">
      <div class="card-header">
        <div class="d-flex justify-content-start align-items-center">
          <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
          2021Q4
          <div class="card-header-toggle-individual ml-auto">
            <con-icon-by-name iconName="angle-right"
                              [mutations]="['fw', 'lg']"></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class="p-4 col-12">

        <!-- tabular data for module estimates -->
        <div class='card'>
          <div class='table-responsive'>
            <table class='table'>
              <thead class='thead-light'>
              <tr>
                <th>Module Estimates</th>
                <th>&nbsp;</th>
                <th>Report 21Q4</th>
              </tr>
              </thead>
              <tbody>

                <tr class='grad-estimates'>
                  <td>Basic earnings per share</td>
                  <td>Unity(Monetary)</td>
                  <td>200</td>
                </tr>
                <tr class='grad-estimates'>
                  <td>Diluted earnings per share</td>
                  <td>Unity(Monetary)</td>
                  <td>200</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class='card-body d-flex justify-content-center'>
          </div>
        </div>


      </div>
    </div>
    <!-- 2 -->
    <div class="card mb-2">
      <div class="card-header">
        <div class="d-flex justify-content-start align-items-center">
          <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
         2021A
          <div class="card-header-toggle-individual ml-auto">
            <con-icon-by-name iconName="angle-right"
                              [mutations]="['fw', 'lg']"></con-icon-by-name>
          </div>
        </div>
      </div>
    </div>
    <!-- list items end -->
</div>
</div>
