import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Dep, Delivery } from 'app/task-manager/typings/Typings';

interface ExpandedDep {
  depData: Dep
}

@Component({
  selector: 'con-tasks',
  templateUrl: './tasks-view.component.html',
  styleUrls: ['./tasks-view.component.scss']
})
export class TasksViewComponent implements OnInit, OnDestroy {

  public dep: Dep;
  public depArr: Dep[];
  public expanded = false;
  public errMessage: string;
  public expandableDep: {depData: Dep }[];
  public agentsExpanded = true;
  public isLoading = true;
  public firstDeliveryJobStep: string;
  public processingJobs: any[] = [];
  public dropdownOptions: string[] = ['All', 'Show only running'];
  public depStatus: string[] = ['Dep', 'Indicies'];
  public categories: string[] = [];
  public selectedCategories: string [] = [];
  public shouldAutoRefresh = true;
  public expandableDepOriginal: {depData: Dep }[];
  public taskStatusArr = [];
  public taskStatus = 'All';
  public currentJobs = [];
  public currentJob = 'All';

  @ViewChild('taskStatus') taskStatusElm: ElementRef;
  @ViewChild('jobStatus') jobStatusElm: ElementRef;

  constructor(
    private subscriptionService: SubscriptionService,
    private taskManagerService: TaskManagerService,
    private utilService: UtilService,
    config: NgbDropdownConfig,
  ) {
    config.autoClose = true;
    const shouldRefresh = localStorage.getItem('refresh')
    if (shouldRefresh === 'true') {
      this.shouldAutoRefresh = true;
      this.utilService.refreshPage(180, () => this.ngOnInit());
      localStorage.setItem('refresh', 'true');
    } else {
      this.shouldAutoRefresh = false;
    }
  }

  ngOnInit() {
    localStorage.removeItem('task-type');
    localStorage.setItem('task-type', 'dep')
    this.taskManagerService.getDepStatus();
    this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: any) => {
      this.expandableDep = [];
      this.expandableDepOriginal = [];
      switch (response.type) {
        case 'getDepStatus': {
          this.depArr = response.data;
          this.createCategories(response.data);
          this.taskStatusArr = this.createTaskStatus(response.data);
          this.taskStatusArr.unshift('All');
          this.currentJobs = this.createCurrentJobs(response.data);
          this.currentJobs.unshift('All');
          response.data.forEach((dep: Dep) => {
            this.createData(dep);
          });
        } break;
        case 'getDepStatusRunning': {
          this.createCategories(response.data);
          response.data.forEach((dep: Dep) => {
            this.createData(dep);
          });
        } break;
        case 'getTaskOverviewWithIndicies': {
          this.createCategories(response.data);
          response.data.forEach((dep: Dep) => {
            this.createData(dep);
          });
        } break;
      }
      this.isLoading = false;
    });
    this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: any) => {
      this.errMessage = err;
    });
  }
  ngOnDestroy() {
    this.subscriptionService.apiResponseSub.unsubscribe();
    this.subscriptionService.apiErrorSub.unsubscribe();
    this.utilService.stopRefreshingPage();
  }
  toggleAgentsExpanded() {
    this.agentsExpanded = !this.agentsExpanded;
  }


  createData(dep: Dep) {
      this.dep = dep;
      const depDataCopy = dep;
      const deliveryArr = []
      Object.keys(dep.deliveries).forEach((deliveryKey) => {
        deliveryArr.push(depDataCopy.deliveries[deliveryKey]);
      });
      const inverted = deliveryArr.reverse()
      depDataCopy.deliveries = inverted;
      this.expandableDep = [];
      this.expandableDep.push({depData: depDataCopy});
      this.expandableDepOriginal.push({depData: depDataCopy})
      this.checkIfLocalStorageContainsCategory();
  }
  setTaskStatus(status: string, alreadyFiltered?: boolean) {
    this.taskStatus = status;
    this.expandableDep = this.expandableDepOriginal;
    this.checkForJobStatus(this.currentJob);
    if (status === 'All') {
      this.taskStatus = 'All';
      return;
    }


    const depDataCopy = this.expandableDep.filter((depData: ExpandedDep) => {
      if (status === 'Deactivated') {
        return !depData.depData.task_active;
      }
      return depData.depData.agent_status === status && depData.depData.task_active;
    });
    this.expandableDep = depDataCopy;
  }

  setJobStatus(job: string, alreadyFiltered?: boolean) {
    this.currentJob = job;
    this.expandableDep = this.expandableDepOriginal;
    this.checkForTaskStatus(this.taskStatus);

    if (job === 'All') {
      this.currentJob = job;
      return;
    }

    const depDataCopy = this.expandableDep.filter((dep: ExpandedDep) => {
      const filterDeliveries = (dep.depData.deliveries as Delivery[]).filter((delivery: Delivery) => {
        return delivery.job_status && delivery.job_status.split(':')[1].trim().replace(',', '').trim() === job;
      })
      if (filterDeliveries.length > 0) {
        return filterDeliveries;
      }
    }).filter(this.utilService.onlyUnique);
    this.expandableDep = depDataCopy;
  }

  checkForTaskStatus(status: string) {
    this.checkForCategory();
    if (status === 'All') {
      return;
    }
    const depDataCopy = this.expandableDepOriginal.filter((depData: ExpandedDep) => {
      if (status === 'Deactivated') {
        return !depData.depData.task_active;
      }
      return depData.depData.agent_status === status && depData.depData.task_active;
    });
    this.expandableDep = depDataCopy;
  }

  checkForJobStatus(job: string) {
    this.checkForCategory();
    if (job === 'All') {
      return;
    }
    const depDataCopy = this.expandableDepOriginal.filter((dep: ExpandedDep) => {
      const filterDeliveries = (dep.depData.deliveries as Delivery[]).filter((delivery: Delivery) => {
        return delivery.job_status && delivery.job_status.split(':')[1].trim().replace(',', '').trim() === job;
      })
      if (filterDeliveries.length > 0) {
        return filterDeliveries;
      }
    }).filter(this.utilService.onlyUnique);
    this.expandableDep = depDataCopy;
  }

  createTaskStatus(data: Dep[]) {
    return data.map((dep: Dep) => {
      if (!dep.task_active) {
        return 'Deactivated'
      }
      return dep.agent_status;
    }).filter(this.utilService.onlyUnique)
  }

  createCurrentJobs(data: Dep[]) {
    return this.utilService.flatMap(data, (dep: Dep) => {
      return Object.keys(dep.deliveries).map((key: string) => {
        const delivery: Delivery = dep.deliveries[key];
        if (delivery.job_status !== undefined || delivery.job_status !== null) {
          if (delivery.job_status) {
            const jobStatus = delivery.job_status.split(':')[1].trim().replace(',', '').trim();
            return jobStatus
          }
        }
      });
    }).filter(this.utilService.onlyUnique);
  }

  checkIfLocalStorageContainsCategory() {
    this.setJobStatus(this.currentJob);
    this.setTaskStatus(this.taskStatus);
    this.checkForCategory();
  }

  checkForCategory() {
    const taskType = localStorage.getItem('task-type');
    const selectedCategoriesFromLocalStorage = localStorage.getItem(taskType === 'dep' ? 'dep' : 'index');
    if (selectedCategoriesFromLocalStorage) {
      this.selectedCategories = JSON.parse(localStorage.getItem(taskType === 'dep' ? 'dep' : 'index'));
      this.expandableDep = this.expandableDep.filter(data => {
        return this.selectedCategories.find(predicate => {
          return predicate === data.depData.category;
        });
      })
    }
  }

  selected(category?: string) {
    const taskType = localStorage.getItem('task-type');
    const selectedCategoriesFromLocalStorage =  JSON.parse(localStorage.getItem('category'));
    if (selectedCategoriesFromLocalStorage) {
      this.selectedCategories = selectedCategoriesFromLocalStorage
    }

    if (this.selectedCategories.indexOf(category) < 0) {
      this.selectedCategories.push(category);
      this.expandableDep = this.expandableDep.filter(data => {
        return this.selectedCategories.find(predicate => {
          return predicate === data.depData.category;
        });
      });
      localStorage.setItem(taskType === 'dep' ? 'dep' : 'index', JSON.stringify(this.selectedCategories));
      this.setJobStatus(this.currentJob);
      this.setTaskStatus(this.taskStatus);
    } else {
      const existingCategory = this.selectedCategories.indexOf(category);
      this.selectedCategories.splice(existingCategory, 1);
      if (this.selectedCategories.length <= 0 ) {
        localStorage.removeItem(taskType === 'dep' ? 'dep' : 'index');
        this.setJobStatus(this.currentJob);
        this.setTaskStatus(this.taskStatus);
      } else {
        this.expandableDep = this.expandableDep.filter(data => {
          return this.selectedCategories.find(predicate => {
            return predicate === data.depData.category;
          });
        })
        localStorage.setItem(taskType === 'dep' ? 'dep' : 'index', JSON.stringify(this.selectedCategories));
        this.setJobStatus(this.currentJob);
        this.setTaskStatus(this.taskStatus);
      }
    }
  }

  createCategories(dep: Dep[]) {
    this.categories = dep.map((dep: Dep) => dep.category).filter(this.utilService.onlyUnique);
  }

  setAutoRefresh() {
    if (this.shouldAutoRefresh) {
      localStorage.setItem('refresh', 'true');
      this.utilService.refreshPage(180, () => this.ngOnInit());
    } else {
      localStorage.setItem('refresh', 'false');
      this.utilService.stopRefreshingPage();
    }
  }
  requestAction(action: string) {
    switch (action) {
      case 'All' : return this.taskManagerService.getDepStatus();
      case 'Show only running': return this.taskManagerService.getDepStatusRunning();
      case 'Indicies': {
        localStorage.setItem('task-type', 'index');
        return this.taskManagerService.getTaskOverviewWithIndicies();
      }
      case 'Dep' : {
        localStorage.setItem('task-type', 'dep')
        return this.taskManagerService.getDepStatus();
      }
    }
  }
}
