
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { EntitiesService } from '../../entities/services/entities.service';

@Component({
    selector: 'con-tier-detail',
    templateUrl: './tier-detail.component.html'
})

export class TierDetailComponent implements OnInit {
    public companyTier: any;
    public companyTierId: number;
    public loading: boolean;

    public toggles: any = {};

    constructor(private entities: EntitiesService,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.route.params.pipe(
              switchMap((params: Params) => {
                  this.loading = true;
                  this.companyTierId = params.id;
                  return this.entities.getEntityById('CompanyTier', params.id);
              }))
              .subscribe(companyTier => {
                  this.companyTier = companyTier;
                  this.loading = false;
              },
              err => {
                  if (err.status === 404) {
                      this.router.navigate(['tier', 'error'], { skipLocationChange: true });
                  }
                  this.loading = false;
              });
    }

    public toggleView(view: string) {
        this.toggles[view] = !this.toggles[view];
    }

    public ignoreCompany(company: any) {
        this.entities.addRelation(
            'CompanyTier',
            this.companyTierId,
            'ignoredCompanies',
            [ company.id ]
        )
        .subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    }
    public unignoreCompany(company: any) {
        this.entities.removeRelation(
            'CompanyTier',
            this.companyTierId,
            'ignoredCompanies',
            [ company.id ]
        )
        .subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    }
}
