<div>
  <div class="row page-title create-validation" *ngIf="!fetchingData">
    <div class="col-12">
        <div class="mt-3 pull-left">
          <div class="d-flex ">
            <h3 class="mb-0 mr-2 mt-1">{{headerName | prettyCase}}</h3>
            <con-server-chooser></con-server-chooser>
          </div>
        </div>
        <div class="mb-3 mr-3 mt-3 pull-right">
            <button class="btn btn-primary" (click)="createValidation()">Create Validation Task</button>
        </div>
    </div>
  </div>
  <div class="project-data mt-3">
    <con-loader *ngIf="fetchingData"></con-loader>
    <div class="row" *ngIf="!fetchingData">
      <div class="col-6 col-md-12 mb-3">
        <div class="card">
          <div class="card-header">Validation Tasks</div>
          <div class="card-body p-0">
            <con-loader *ngIf="fetchingTableData"></con-loader>
            <con-material-table
              #materialTable
              *ngIf="!fetchingTableData"
              [isPaginated]="true"
              [isClickable]="true"
              [isSortable]="true"
              [data]="projectDetailTableData"
              (onClick)="onTaskIdClick($event)"
            >
            </con-material-table>
            <div class="mt-3">
              <ngb-alert [dismissible]="false" type="success" *ngIf="responseMessage">{{responseMessage}}</ngb-alert>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">Instances</div>
          <div class="card-body p-0">
          <con-entity-list
            [entityName]="instanceEntityName"
            [isSelectable]=true
            [external]=true
            [timestamps]=false
            [enableHyperLink]=false
            [refreshExternalData]="refreshExternalData"
            [externalMetaData]="projectDetails.table_meta"
            [externalData]="tableData$"
            [fixedSearchParams]="{ 'instance_status': 'Ready' }"
            (loadExternalData)="loadData($event)">
<!--            [preserveSelection]="preserveSelection" // TODO refactor-->
          </con-entity-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #projectHomeModal let-modal>
  <div class="modal-header">
      <h5 class="modal-title">Do you want to proceed?</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="mb-0">
          {{modalMessage}}<br>
          <em><b>{{excludeIds}}</b></em>
      </p>
  </div>
  <div class="modal-footer">
      <button class="btn btn-primary" (click)="modal.close('Save click')">Proceed</button>
      <button class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Abort</button>
  </div>
  </ng-template>

</div>

