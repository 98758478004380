<div class="sidebar">
    <nav class="nav flex-column" >
        <a class="nav-link"
        [routerLink]="['revisions']"
        routerLinkActive="active" (click)="resetCount()">
          Revisions
          <span class="badge badge-pill badge-danger" *ngIf="!countService.isLoading() && countService.context === 'revisions'">{{ counts['revisions'] }}</span>
          <span class="badge badge-pill badge-danger" *ngIf="countService.context === 'missing' || countService.context===''">{{ counts['revisions'] }}</span>
          <span class="circular-loader-container custom-small-loader" *ngIf="countService.isLoading() && (countService.context==='revisions' || countService.context === '')">
            <span class="circular-loader circular-loader-small custom-small-loader-width">
              <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"/>
              </svg>
            </span>
          </span>

        </a>
        <a class="nav-link"
        [routerLink]="['missing']"
        routerLinkActive="active" (click)="resetCount()">
          Missing
          <span class="badge badge-pill badge-danger" *ngIf="!countService.isLoading() && countService.context==='missing'">{{ counts['missing'] }}</span>
          <span class="badge badge-pill badge-danger" *ngIf="countService.context === 'revisions' || countService.context===''">{{ counts['missing'] }}</span>
          <span class="circular-loader-container custom-small-loader" *ngIf="countService.isLoading() && (countService.context==='missing' || countService.context === '')">
            <span class="circular-loader circular-loader-small custom-small-loader-width">
              <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"/>
              </svg>
            </span>
          </span>
        </a>
    </nav>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
