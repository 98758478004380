import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { EntitiesService, EntityDescription } from '../../services/entities.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

import { snakeCase } from 'change-case';

@Component({
    selector: 'con-entity-primary-alert',
    templateUrl: './entity-primary-alert.component.html'
})

export class EntityPrimaryAlertComponent implements OnChanges, OnDestroy {
    @Input() entityType: string;
    @Input() entity: any;

    public snakeCase = snakeCase;
    private loading: boolean;
    public entityDescription: EntityDescription;
    private entityDescriptionSubscribtion: ISubscription;

    constructor(private entitiesService: EntitiesService) {}

    ngOnChanges() {
        this.loading = true;
        this.entityDescriptionSubscribtion = this.entitiesService
                                            .getEntityDescriptionByEntityName(this.entityType)
                                            .subscribe(entityDescription => {
                                                this.entityDescription = entityDescription;
                                                this.loading = false;
                                            });
    }

    ngOnDestroy() {
        this.entityDescriptionSubscribtion.unsubscribe();
    }
}
