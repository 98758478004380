<div class="modal-header">
  <h5 class="modal-title">{{modalData.title}} </h5>
  <button type="button" class="close no-highlight" (click)="activeModal.dismiss('abort')" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<p class="mb-0 ml-dialog-msg text-warning" *ngIf="modalData?.mlInfo?.ml_request_status === mode.FAILED"> Processing failed. </p>

<code *ngIf="modalData?.mlInfo?.error_msg">
  <p class="ml-status-msg mb-10" >ML status message :</p>
  <p class="ml-status-msg" >{{modalData?.mlInfo?.error_msg}}</p>
</code>

<p class="mb-0 ml-dialog-msg"  *ngIf="initialized" [innerHTML]="modalData.message"></p>
<div class="modal-body ml-dialog-body-custom" [ngClass]="showTable ? 'dynamic-height': ''" *ngIf="showTable">

    <con-loader *ngIf="loading"></con-loader>
    <div class="table-responsive" *ngIf="!loading && showTable">
      <table class="table table-hover">
        <thead class="thead-light">
          <tr>
            <th>Select</th>
            <th>Label</th>
            <th>Language</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of response?.data">
          <td><input type="radio" name="report_file" [value]="data?.id" (click)="updateCurrentReportFileSelection(data)"></td>
          <td><a href="/entity/company_report_file/{{data?.id}}" target="_blank">{{data?.label}}</a></td>
          <td>{{data?.language?.name}}</td>
          <td><a type="link" conResolveLinkToFile="{{data?.source}}" target="_blank"><con-icon-by-name iconName="download" ></con-icon-by-name></a></td>
        </tr>
        </tbody>
      </table>
      <con-no-data *ngIf="!response?.total" [count]="response?.total" [message]="noDataMessage"></con-no-data>
    </div>
</div>
<div class="d-flex justify-content-center mt-16" [ngClass]="{'hide-pagination': response?.total < 2}" *ngIf="showTable">
  <ngb-pagination [collectionSize]="response?.total"
                  [(page)]="currentPage"
                  (pageChange)="changePage($event)"
                  [pageSize]="response?.per_page"
                  maxSize="10"
                  [rotate]="true"></ngb-pagination>
</div>
<div class="custom-modal-footer" *ngIf="!modalData.alert && canShowFooterActions()">

  <div class="custom-modal-footer-left padding-btm-10">
    <div [innerHTML]="sendToMlMessage"></div>
    <div  *ngIf="changeEnabledStatuses() && modalData?.numberOfAvailableReportFiles > 1 && !showTable"> <a type="link" (click)="loadData(true)" class="mat-primary cursor-pointer">Click</a> to change file.</div>
  </div>
  <div class="custom-modal-footer-right">
    <button class="btn btn-secondary pull-right ml-10" (click)="activeModal.dismiss('abort')">No</button>
    <button class="btn btn-primary pull-right" (click)="activeModal.close('proceed')">Yes</button>
  </div>
</div>
