import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MozendaService } from '../services/mozenda.service';
@Component({
    selector: 'con-mozenda',
    templateUrl: './mozenda.component.html'
})

export class MozendaComponent implements OnInit, OnDestroy {
    public loading = false;
    public counts: any = {};
    public selected: string;
    private countSubscription = new Subscription;

    constructor(private mozendaService: MozendaService,
                private router: Router,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.loading = true;
        this.countSubscription = this.mozendaService.getCounts().subscribe(counts => {
            this.counts = counts;
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.countSubscription.unsubscribe();
    }

    public selectedType (type: string) {
        this.selected = type;
    }
}
