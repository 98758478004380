import {DynamicDataEntryComponentInterface} from '../models/dynamic-data-entry-component-interface';
import {FundamentalsAnnotation} from '../../kpi-and-kiid/models/fields';

export class FetchTaskDataEntryState {
  static readonly type = '[DataEntry] Fetch';
  constructor() { }
}

export class SetTaskId {
  static readonly type = '[DataEntry] Set Task Id';
  constructor(public taskId: number) { }
}

export class SetSelectedInstanceId {
  static readonly type = '[DataEntry] Set Instance Id';
  constructor(public selectedInstanceId: number) { }
}

export class FetchTaskInstances {
  static readonly type = '[DataEntry] FetchTaskInstances';
  constructor() { }
}

export class UpdateDocuments {
  static readonly type = '[DataEntry] UpdateDocuments';
  constructor(public documents: any) { }
}

export class SaveTask {
  static readonly type = '[DataEntry] SaveTask';
  constructor(public instanceIdsToSubmit: any) { }
}

export class SetComponentData {
  static readonly type = '[DataEntry] SetComponentData';
  constructor(public instanceId: number, public component: DynamicDataEntryComponentInterface, public value: any, public addressNodes?: any[]) { }
}

export class SetStatementAnnotations {
  static readonly type = '[DataEntry] SetStatementAnnotations';
  constructor(public instanceId: number, public component: DynamicDataEntryComponentInterface, public statementAnnotations: FundamentalsAnnotation[]) { }
}
