import {Component, Input, OnInit} from '@angular/core';
import {InstanceDataValueFactsheet, ShareClassFactsheet} from '../../models/Typings';
import {FactsheetService} from '../../services/factsheet.service';
import {HotkeyHandlerService} from '../../services/hotkey-handler.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'con-factsheet-input-table',
  template: `
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="w-100">Name</th>
          <th>Value</th>
          <th>Mapping</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of tableData.value; let i = index;">
          <tr class="">
            <td class="p-0 pb-1 pr-1 "><input class="align-middle w-100" (ngModelChange)="onModification()" [(ngModel)]="row.name" type="text" placeholder=""></td>
            <td class="p-0 pb-1 pr-1 "><input class="align-middle w-100" (ngModelChange)="onModification()" [(ngModel)]="row.value" type="text" placeholder=""></td>
            <td class="p-0 pb-1 pr-1 "><input class="align-middle w-100" (ngModelChange)="onModification()" [(ngModel)]="row.mapping" type="text" placeholder=""></td>
            <td class="py-0 px-1 align-middle p-0">
              <button (click)="addNewRow(i)" class="btn btn-primary smaller-button px-1 py-0 m-0">
                <con-icon-by-name iconName="plus"></con-icon-by-name>
              </button>
            </td>
            <td class="py-0 px-1 align-middle p-0">
              <button (click)="removeRow(i)" class="btn btn-danger smaller-button px-1 py-0 m-0" [disabled]="tableData.value.length <= 1 || (isLastRow(i) && FactsheetService.isRowEmpty(row))">
                <con-icon-by-name iconName="minus"></con-icon-by-name>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td>Total:</td>
          <td>{{sumOfValueColumn}}</td>
        </tr>
      </tfoot>

    </table>
  `,
  styles: [`
    .share-class-area{
      overflow-y: scroll;
    }

    .share-class-container {
      display: inline-flex;
      min-height: 170px;
    }

    .share-class-field {
      min-width: 160px;
    }
    .lang-button{
      font-size: 0.9em;
      min-width: 28px;
      padding: 8px;
    }

    .smaller-text-form{
      font-size: 0.70rem;
    }

    .smaller-input{
      padding: 0 10px;
      height: 30px;
    }

    .smaller-button {
      padding: 0.1rem 0.4rem;
    }

    .share-class-input {
      max-width: 120px;
    }
    .full-width{
      width: 100%;
    }
    .checked{
      color: green;
      margin-right: 4px;
    }
    .warning {
      color: orange;
      margin-right: 4px;
    }
  `]
})
export class FactsheetInputTableComponent implements OnInit {
  @Input() tableData: InstanceDataValueFactsheet;
  private subscribeUntil: Subject<boolean> = new Subject();

  get lastItemIndex(): number {
    return this.tableData.value.length -1
  }

  get FactsheetService() {
    return FactsheetService
  }

  get sumOfValueColumn(): string {
    const allValues: Array<number> = this.tableData.value.map((a: ShareClassFactsheet) => Number(a.value) ?? 0)
    const sumOfAllValues: number = allValues.reduce((prevRowValue: number, curRowValue: number) => { return prevRowValue + curRowValue }, 0)
    return sumOfAllValues.toFixed(2) as string
  }

  constructor(
    private hotkeyHandlerService: HotkeyHandlerService
  ) {
    hotkeyHandlerService.ctrlCevent$.pipe(takeUntil(this.subscribeUntil)).subscribe((textCopied: string) => {
      this.putValueIntoNextEmptyCell(textCopied)
    })
  }

  ngOnInit(): void {
    this.addEmptyRowIfLastRowIsNotEmpty()
    this.hotkeyHandlerService.clickEvent$.pipe(takeUntil(this.subscribeUntil)).subscribe( (ev: MouseEvent) => {
      if (ev.altKey && !ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
        let stringContents = (ev.target as HTMLElement).innerText
        this.putValueIntoNextEmptyCell(stringContents)
      }
    })
  }

  ngOnDestroy(): void {
    this.subscribeUntil.next(true);
  }

  onModification(): void {
    this.tableData.altered = true;
    this.tableData.confirmed = 'Altered'
    this.addEmptyRowIfLastRowIsNotEmpty()
  }

  addNewRow(i: number) {
    this.tableData.value
      .push({
          name: null,
          value: null,
          mapping: null
        } as ShareClassFactsheet
      )
  }

  removeRow(i: number) {
    this.tableData.value.splice(i, 1)
    this.onModification()
  }

  private isLastRowEmpty(): boolean {
    return FactsheetService.isRowEmpty(this.tableData.value[this.lastItemIndex])
  }

  isLastRow(rowIndex: number): boolean {
    if (this.lastItemIndex === rowIndex)
      return true
    return false
  }

  private addEmptyRowIfLastRowIsNotEmpty() {
    if (!this.isLastRowEmpty())
      this.addNewRow(this.lastItemIndex)
  }

  private putValueIntoNextEmptyCell(textCopied: string) {
    function insertValueToRow(row: ShareClassFactsheet, textCopied: string) {
      if (!!row && !!row?.name && !row?.value)
        row.value = FactsheetInputTableComponent.stripToNumber(textCopied) ?? "0"
      else if (!!row && !row?.name && !row?.value)
        row.name = textCopied
      else
        throw new ReferenceError()
    }

    try {
      let secondRowFromLast: ShareClassFactsheet = this.tableData.value?.[this.tableData.value.length - 2]
      insertValueToRow(secondRowFromLast, textCopied)
    }
    catch (e) {
      if (e instanceof ReferenceError){
        let lastRow: ShareClassFactsheet = this.tableData.value?.[this.tableData.value.length - 1]
        insertValueToRow(lastRow, textCopied)
      } else {
        throw e
      }
    }
    finally {
      this.onModification()
    }
  }

  private static stripToNumber(textCopied: string): string | null {
    let numString = textCopied.replace(/[^\.,-\d]/g,''); // remove if not digit, comma or dot
    if (/\d/.test(numString)) // test if there are any digits left in selection
      return numString
    return null
  }
}
