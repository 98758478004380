import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {switchMap, take, tap} from 'rxjs/operators';
import { EntitiesService, EntityDescription } from '../../../entities/services/entities.service';
import { CompanyReportsSettings } from 'app/settings.class';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import * as moment from 'moment';
import { camelCase, upperCaseFirst } from 'change-case';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import {SendToMlDialogComponent} from '../send-to-ml-dialog/send-to-ml-dialog.component';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'con-new-report',
  templateUrl: './new-report.component.html',
  styles: [`.link-fontsize { font-size: 14px;}`, `.btn-padding {padding: 1px 10px;}`]
})
export class NewReportComponent implements OnInit, OnDestroy {

  public entityDescription: EntityDescription;
  public company: any;
  public preFillValues: any;
  public prefill: any = {};
  public entityCompany: any;
  public loading = true;
  public noDataMessage = 'No reports to display';
  public entityName: string;
  public lsName = 'recentCompanies';
  public companyWebsite = '';

  private showComponentDict: any = {};
  public MLSendStatus = MLSendStatus;
  private dialogRef = null;
  showMlLoader = false;

  constructor(
    private service: EntitiesService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toaster: ToastrService,
    private modalService: NgbModal,
    public reportService: CompanyReportsService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      tap((params: Params) => {
        this.reportService.getCompanyData(params.id).subscribe((companyDetails) => {
          this.companyWebsite = companyDetails.website;
        })
      }),
      switchMap((params: Params) => {
        this.loading = true;
        return this.reportService.getReports(true, params.id);
      }))
      .subscribe((company: any) => {
        this.company = company;
        if (this.company.company_reports.length) {
          this.company.company_reports = this.sortReports();
          this.company.company_reports.forEach((report, index) => {
            if (index !== 0) {
              report.hide = true;
            }
            if (index === 0) {
              this.prefill = report;
            }
          });
        }
        this.loading = false;
        this.preFillValues = {
          company_id: this.company.id,
          currency_id: this.prefill.currency_id ? this.prefill.currency_id : null,
          offset_month: this.prefill && this.prefill.offset_month ? this.prefill.offset_month : null,
          accounting_standard: this.prefill && this.prefill.accounting_standard ? this.prefill.accounting_standard : null
        };
        this.entityCompany = {
          'company_id': this.company.id,
          company: { id: this.company.id, name: this.company.name },
          currency_id: this.prefill && this.prefill.currency_id ? this.prefill.currency_id : null,
          currency: this.prefill && this.prefill.currency ? this.prefill.currency : null,
          offset_month: this.prefill && this.prefill.offset_month ? this.prefill.offset_month : null,
          accounting_standard: this.prefill && this.prefill.accounting_standard ? this.prefill.accounting_standard : null
        };
        this.setReportTabs(company.company_reports);

        const recentData = {
          id: company.id,
          name: company.name
        };
        this.reportService.setRecent(this.lsName, recentData);
      },
        err => {
          if (err.status === 404) {
            this.router.navigate(['company_reports', 'error'], { skipLocationChange: true });
          } else {
            this.toaster.error('Sorry, some error occurred!');
          }
          this.loading = false;
        });
        this.entityName = upperCaseFirst(camelCase('company_report'));
        this.service.getEntityDescriptionByEntityName(this.entityName)
          .subscribe(
              entityDescription => this.entityDescription = entityDescription,
              err => this.router.navigate(['entity', 'error'], { skipLocationChange: true })
          );
  }
  setReportTabs(reports: any[]) {
    this.reportService.setReports(reports);
  }
  sortReports() {
    return this.company.company_reports.sort((a, b) => {
      return <any>new Date(b.report_date) - <any>new Date(a.report_date);
    });
  }
  newReportSaved(report) {
    this.router.navigate(['company_reports', this.company.id, 'report', report.id, 'report-overview']);
  }

  getMutations(event) {
    return !event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg'];
  }

  goToReportPage(event, report) {
    event.stopPropagation();
    this.router.navigate([report.company_id, 'report', report.id, 'report-overview'], { relativeTo: this.route.parent.parent });
  }

  public getTaxonomyData(income: any) {
    if (income && income.incomeData) {
      return;
    }
    income.loading = true;
    this.http
      .get(
        CompanyReportsSettings.BASE_URL +
          '/' +
          'income_statement' +
          '/' +
          income.id
      )
      .subscribe(
        (statement) => {
          income.incomeData = statement;
          income.loading = false;
        },
        (err) => {
          if (err.status === 404) {
            this.router.navigate(['income-statement', 'error'], {
              skipLocationChange: true,
            });
          }
          income.loading = false;
        }
      );
  }
  public toggleType(event?) {
    event.hide = !event.hide;
  }
  getDisplayName(report?: any) {
    let displayName = this.company.name;
    if (report) {
      displayName +=
        ' / ' +
        report.report_type +
        ', ' +
        moment(report.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
  reportUpdated(report, index) {
    this.company.company_reports[index] = report;
  }
  reportFileCreated() {

  }

  afterRelationChange(event) {

  }
  showComponent(relationName: string) {
    return this.showComponentDict[relationName];
  }
  toggleComponent(relationName: string) {
    this.showComponentDict[relationName] = !this.showComponentDict[relationName];
  }

  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }
  openSendToMLPopup(event, report): void {
    event.stopPropagation();
    this.clearToastMessages();
    // validate report files status
    this.reportService.getReportFileStatuses(report.id).subscribe((response: any) => {
      // check if report can be send to ml based on status
      if(response.ml_info.ml_request_status !== null && response.ml_info.ml_request_status !== MLSendStatus.FAILED) {
        this.toaster.warning('Invalid Request. A request already sent to ML');
        return;
      }
      // validate each items for ml processing
      if(!response.balanceSheets || !response.cashFlows || !response.companyReportFiles || !response.incomeStatements || !response.periodKpis || !response.snapshotKpis || response.locked) {
        let errorStr = `<ul class='ml-message-list'>`;
        // check report is locked
        if(response.locked) {
          errorStr += `<li> Report is locked </li>`
        }
        // check at least one report file is added
        if(!response.companyReportFiles) {
          errorStr += `<li> Minimum one file of type Report is required </li>`
        }
        if(!response.balanceSheets) {
          errorStr += `<li> No balance sheet attached </li>`
        }
        if(!response.cashFlows) {
          errorStr += `<li> No cash flow attached </li>`
        }
        if(!response.incomeStatements) {
          errorStr += `<li> No income statement attached </li>`
        }
        if(!response.periodKpis) {
          errorStr += `<li> No period kpi attached </li>`
        }
        if(!response.snapshotKpis) {
          errorStr += `<li> No snapshot kpi attached </li>`
        }
        errorStr += `</ul>`;
        this.toaster.warning(errorStr, 'Unable to send to ML', {enableHtml: true});

        return;
      }

      // get data of custom data of report files

      this.reportService.getCompanyReportFilesData(report.id, 1).subscribe((reportFilesListData: any) => {
        if(this.dialogRef === null) {
          this.dialogRef = this.modalService.open(SendToMlDialogComponent,
            {
              size: 'lg',
            });
        } else {
          return;
        }
        const data = {
          title: 'Send To ML',
          // make below code more generic to support future handling of cases other than created & null
          message: !!response.ml_info.ml_request_status ? 'Are you sure you want to send this report to ML again?' : 'Are you sure you want to send this report to ML?',
          alert: false,
          reportId: report.id,
          mlInfo: response.ml_info,
          numberOfAvailableReportFiles: reportFilesListData.total,
          reportFilesData: reportFilesListData
        }

        this.dialogRef.componentInstance.modalData = data;
        this.reportService.setSelectedReportFile(null);
        this.dialogRef.result.then(() => {
            this.dialogRef = null;
            this.showMlLoader = true;
            this.renderer.addClass(this.document.body, 'cursor-wait');
            this.reportService.sendToML(report.id).subscribe((sendToMLResponse) => {
              this.showMlLoader = false;
              this.renderer.removeClass(this.document.body, 'cursor-wait');
              if (sendToMLResponse.hasOwnProperty('request_status')) {
                report.ml_info = {company_report_file: this.reportService.getSelectedReportFile(), ml_request_status: sendToMLResponse.request_status};
                this.reportService.setMLCurrentStatus(report.ml_info);
                this.toaster.success(sendToMLResponse.message);
              }
            }, (err) => {
              this.showMlLoader = false;
              if (Array.isArray(err.data)) {
                let errorStr = `<ul class='ml-message-list'>`;
                err.data.forEach((errorMessage) => {
                  errorStr += `<li> ${errorMessage} </li>`
                })
                errorStr += `</ul>`;
                this.toaster.warning(errorStr, 'Unable to send to ML', {enableHtml: true});
              } else {
                this.toaster.error(
                  'Sorry, some error occurred',
                  'Send To ML'
                );
              }
            })
          },
          (reason) => {
            console.error(reason);
            this.dialogRef = null;
          })
      });

    },(error) => {
      console.error(error);
    });

  }
  clearToastMessages() {
    this.toaster.toasts.forEach(toast => {this.toaster.remove(toast.toastId)})
    this.toaster.clear();
  }
  ngOnDestroy(): void {
    this.clearToastMessages();
    this.renderer.removeClass(this.document.body, 'cursor-wait');
  }
}
