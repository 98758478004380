import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../auth/services/profile.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'con-universe-start',
    templateUrl: './universe-start.component.html'
})

export class UniverseStartComponent implements OnInit, OnDestroy {
    private userId: number;
    public loadingUser: boolean;
    entityName: string;

    private userSubscription: ISubscription;

    public actions: any = [
        {
            buttonClass: 'success',
            name: 'navigate',
            label: 'Overview',
            icon: 'arrow-right'
        }
    ];


    constructor(private router: Router,
                public profile: ProfileService) {}

    public navigateToUniverse(universe: any) {
        this.router.navigate(['company_institution_lists',
                this.entityName === 'CompanyUniverse' ? 'company_universe' : 'institution_universe',
                universe.id]);
    }

    public handleEventInList(event: any) {
        if (event.action === 'navigate') {
            this.navigateToUniverse(event.entity);
        }
    }

    ngOnInit() {
        if (this.router.url.indexOf('company_universe') > -1) {
            this.entityName = 'CompanyUniverse';
        } else {
            this.entityName = 'InstitutionUniverse';
        }
        this.loadingUser = true;
        this.userSubscription = this.profile
                                    .getUser()
                                    .subscribe(user => {
                                        if (user !== null) {
                                            this.userId = user.id;
                                            this.loadingUser = false;
                                        }
                                    });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }
}
