<div class="my-3">
    <div class="card mb-2">
        <div class="card-header">
            Go to company
        </div>
        <div class="card-body">
            <ng-container *ngIf="companyDetails; else searchForm">
                <div class="alert alert-info alert-dismissible mb-0">
                    <button type="button" class="close" aria-label="Close" (click)="clearChoosenCompany()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <a class="alert-link-icon mr-1" [routerLink]="['/entity/', 'company', companyDetails?.id]" placement="top" container="body" [ngbTooltip]="'Go to company'">
                        <con-icon-by-name [iconName]="'building'" [mutations]="['fw']"></con-icon-by-name>
                    </a>
                    <strong>{{ companyDetails?.name }}</strong>
                </div>
            </ng-container>
            <ng-template #searchForm>
                <con-entity-search-in-relation 
                    parentEntityName="CompanyTier" 
                    [parentId]="companyTierId"
                    relationName="companies"
                    [disabled]="!profileService.can(profileService.getPermissionName('CompanyTier', 'show', 'companies'))"
                    (onSelect)="chooseCompany($event)">
                </con-entity-search-in-relation>
            </ng-template>
        </div>
    </div>
    <div class="mt-3">
        <router-outlet></router-outlet>
    </div>
</div>
