import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MozendaDeliveriesSettings } from '../../settings.class';
import { MozendaService } from '../services/mozenda.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'con-mozenda-production',
    templateUrl: './mozenda-production.component.html'
})

export class MozendaProductionComponent implements OnInit, OnDestroy {
    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    public production: any;
    public selectedTypeFilter = {label: 'All', value: ''};
    public selectedStatusFilter = {label: 'All', value: ''};
    public selectedSort = {label: 'Date', value: 'created_at'};
    public filterTypes: any[];
    public filterStatuses: any[];
    public sortTypes: any[];
    public sortOrder = 'desc';
    public searchText = '';
    public filterModels =  {
        selectedTypeFilter: '',
        selectedStatusFilter: '',
        selectedSort: 'created_at',
    }

    private searchSubject = new Subject<string>();
    private reloadSubscription = new Subscription;
    private productionSubscription = new Subscription;

    constructor(private http: HttpClient,
                private router: Router,
                private mozendaService: MozendaService) {}

    ngOnInit() {
        this.filterTypes = [
            {
                label: 'All',
                value: ''
            },
            {
                label: 'Shareholders',
                value: 'ShareHolders'
            },
            {
                label: 'Company reports',
                value: 'CompanyReports'
            }
        ];
        this.filterStatuses = [
            {
                label: 'All',
                value: ''
            },
            {
                label: 'Failed',
                value: 'false'
            },
            {
                label: 'Success',
                value: 'true'
            }
        ];
        this.sortTypes = [
            {
                label: 'Date',
                value: 'created_at'
            },
            {
                label: 'Project',
                value: 'project'
            },
            {
                label: 'Agent',
                value: 'agent_id'
            }
        ];
        this.loadPage(1);
        this.reloadSubscription = this.mozendaService.reloadSubject.subscribe(type => {
            if (type === 'Missing') {
                this.loadPage(1);
            }
        });
        this.searchSubject
            .pipe(debounceTime(1000))
            .pipe(distinctUntilChanged())
            .subscribe(model => {
              this.loadPage(1);
        });
    }

    ngOnDestroy() {
        this.reloadSubscription.unsubscribe();
        this.productionSubscription.unsubscribe();
    }

    processParams(pageNumber: number) {
        const params: any = {};
        params.page = String(pageNumber);
        if (this.selectedTypeFilter.value) {
            params.project = this.selectedTypeFilter.value;
        }
        if (this.selectedStatusFilter.value) {
            params.processed = this.selectedStatusFilter.value;
        }
        if (this.selectedSort.value) {
            params.order_by = this.selectedSort.value;
        }
        if (this.sortOrder === 'asc') {
            params.asc = true;
        }
        if (this.searchText) {
            params.agent_id = this.searchText;
        }
        return params;
    }

    loadPage(pageNumber: number) {
        this.mozendaService.getCounts(true);
        this.loading = true;

        this.productionSubscription = this.http.get<any>(MozendaDeliveriesSettings.BASE_URL + '/' + MozendaDeliveriesSettings.PRODUCTION_ENDPOINT,
                {
                    params: this.processParams(pageNumber)
                })
                 .subscribe(res => {
                     this.mozendaService.setCount('missing', res.total);
                     this.formatProductionData(res);
                     this.loading = false;
                 }, err => {
                     this.loading = false;
                     if (err.isPermissionError()) {
                         this.router.navigate(['shareholders', 'auth'], { skipLocationChange: true });
                     }
                 });
    }
    formatProductionData(res: any) {
        res.data.forEach((prod, index)=> {
            if (index !== 0) {
               prod.hide =  true;
            }
            prod.data = JSON.parse(prod.data);
        });
        this.production = res;
    }

    removeFromList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.ngOnInit();
        }
    }
    public toggleType(event?) {
        event.hide = ! event.hide;
    }
    getMutations(event) {
        return (!event.hide ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
    }

    selectTypeFilter(filter) {
        if (filter.value === this.selectedTypeFilter.value) {
            return;
        }
        this.selectedTypeFilter = filter;
        this.loadPage(1);
    }

    selectStatusFilter(filter) {
        if (filter.value === this.selectedStatusFilter.value) {
            return;
        }
        this.selectedStatusFilter = filter;
        this.loadPage(1);
    }

    selectSort(sort) {
        if (sort.value === this.selectedSort.value) {
            return;
        }
        this.selectedSort = sort;
        this.loadPage(1);
    }
    setSortOrder(order: string) {
        this.sortOrder = order;
        this.loadPage(1);
    }
    search() {
        this.searchSubject.next(this.searchText);
    }
    resetFilters() {
        this.searchText = '';
        this.selectedTypeFilter = {label: 'All', value: ''};
        this.selectedStatusFilter = {label: 'All', value: ''};
        this.selectedSort = {label: 'Date', value: 'created_at'};
        this.sortOrder = 'desc';
        this.filterModels =  {
            selectedTypeFilter: '',
            selectedStatusFilter: '',
            selectedSort: 'created_at',
        }
        this.loadPage(1);
    }
}
