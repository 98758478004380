import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { LayoutService } from './layout.service';

import { ProfileService } from './auth/services/profile.service';

import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
    selector: 'con-toolbar',
    templateUrl: './toolbar.component.html'
})

export class ToolbarComponent implements OnInit, OnDestroy {
    public views: any;
    public loading: boolean;

    private userSubscription: ISubscription;
    private viewsSubscription: ISubscription;

    constructor(private layout: LayoutService,
                private router: Router,
                public profile: ProfileService) {}

    ngOnInit() {
        this.loading = true;
        this.userSubscription = this.profile
                                    .userData$
                                    .subscribe(res => {
                                        this.loading = false;
                                    });

        this.viewsSubscription = this.layout
                                     .getViewsInToolbar()
                                     .subscribe(views => {
                                         this.views = views;
                                     });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.viewsSubscription.unsubscribe();

    }
}
