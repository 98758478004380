import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import { EntitiesModule } from '../entities/entities.module';

import { MozendaComponent } from './layout/mozenda.component';
import { MozendaDeliveriesComponent } from './layout/mozenda-deliveries.component';
import { MozendaProductionComponent } from './layout/mozenda-production.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MozendaService } from './services/mozenda.service';
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
        NgbModule,
        AuthModule,
        EntitiesModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule
    ],
    providers: [
       MozendaService
    ],
    declarations: [
        MozendaComponent,
        MozendaDeliveriesComponent,
        MozendaProductionComponent
    ],
    exports: []
})
export class MozendaModule {}
