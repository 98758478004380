import { RouterModule, Routes } from '@angular/router';

import { MozendaComponent } from './layout/mozenda.component';

import { PageNotFoundComponent } from '../page-not-found.component';
import { NotAuthorizedComponent } from '../not-authorized.component';
import { MozendaDeliveriesComponent } from './layout/mozenda-deliveries.component';
import { MozendaProductionComponent } from './layout/mozenda-production.component';

export const mozendaRoutes: Routes = [
    {
        path: '',
        component: MozendaComponent,
        canActivate: [],
        children: [
            {
                path: '',
                redirectTo: 'deliveries',
                pathMatch: 'full'
            }, {
                path: 'deliveries',
                component: MozendaDeliveriesComponent
            }, {
                path: 'production',
                component: MozendaProductionComponent
            }, {
                path: 'error',
                component: PageNotFoundComponent
            }, {
                path: 'auth',
                component: NotAuthorizedComponent
            }
        ]
    }
];

export const shareholdersRouting = RouterModule.forChild(mozendaRoutes);
