import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../auth/services/profile.service';
import { MissingCompanyEventsSettings, MissingInstitutionEventsSettings } from '../../settings.class';

@Component({
    selector: 'con-missing-events-start',
    templateUrl: './missing-events-start.component.html'
})

export class MissingEventsStartComponent {
    public companyTierId: number = MissingCompanyEventsSettings.TIER_ID;
    public institutionTierId: number = MissingInstitutionEventsSettings.TIER_ID;

    constructor(private router: Router,
                public profile: ProfileService) {}

    public navigateToCompany(company: any) {
        this.router.navigate(['missing-events/company', company.id]);
    }
    public navigateToInstitution(institution: any) {
        this.router.navigate(['missing-events/institution', institution.id]);
    }
}
