import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Instance} from '../../kpi-and-kiid/models/Typings';
import {FieldClass} from '../../kpi-and-kiid/models/field-color';
import {BehaviorSubject} from 'rxjs';
import {MaterialTableComponent} from '../../doc-process-common/components/material-table/material-table.component';
import {Task} from '../models/Task';
import {InstanceService} from "../services/instance.service";
import {SetSelectedInstanceId} from '../state-management/actions';
import {Store} from '@ngxs/store';

type instanceTableData = Pick<Instance, 'instance_id' | 'issuer_name'>;

@Component({
  selector: 'doc-process-instance-list',
  template: `
    <div class="card" id="instanceCard" style="height: 90vh; overflow-y: auto;">
      <div class="card-header">Instances</div>
      <div class="card-body p-0">
        <con-material-table
          #instanceNavigationTable
          [isClickable]="true"
          [isSelectable]="true"
          [selectableColumns]="[0]"
          [clickableColumns]="[0, 1]"
          [data]="DocProcessInstanceListComponent.getInstanceTableInfo(instanceTableData)"
          [fieldClasses]="getFieldClasses()"
          [columnsToHide]="['']"
          (onClick)="onInstanceSelect($event.instance_id)"
        ></con-material-table>
      </div>
    </div>
  `,
})
export class DocProcessInstanceListComponent implements AfterViewInit {
  @Input() instanceTableData: Task;
  @Input() focusedInstanceIndex: BehaviorSubject<number>;
  @ViewChild('instanceNavigationTable') instanceNavigationTable: MaterialTableComponent<any, any>;
  get DocProcessInstanceListComponent(){ return DocProcessInstanceListComponent }

  public static getInstanceTableInfo(instanceTableData: Task): Array<instanceTableData> {
    let instanceTableInfo = instanceTableData?.instances.map(eachInstance => {
      let fieldExtractor = ({instance_id, issuer_name}) => ({instance_id, issuer_name});
      let trimmedInstance: instanceTableData = fieldExtractor(eachInstance) as instanceTableData;
      return trimmedInstance
    }) as Array<instanceTableData>;

    return instanceTableInfo;
  }

  constructor(
    private instanceService: InstanceService,
  private store: Store
  ) {}

  ngAfterViewInit(): void {
    setTimeout( () => {
      this.enableInstanceCheckbox()
    }, 0)
  }

   onInstanceSelect(instanceId: number) {
    this.focusedInstanceIndex.next(this.instanceTableData.instances.findIndex(instance => instance.instance_id === instanceId))
    this.enableInstanceCheckbox()
    this.instanceService.setInstanceId(instanceId);
    this.store.dispatch(new SetSelectedInstanceId(instanceId));
     this.instanceService.loadInstanceDocumentsBatch(instanceId);
  }

  private enableInstanceCheckbox() {
    const newInstanceId = this.instanceTableData.instances[this.focusedInstanceIndex.getValue()].instance_id
    this.instanceNavigationTable.selection.select(newInstanceId)
  }

  getFieldClasses() {
    let val = DocProcessInstanceListComponent.getInstanceTableInfo(this.instanceTableData).map((i, index) => index !== this.focusedInstanceIndex.getValue() ? FieldClass.Previous : FieldClass.Current);
    return val
  }

  getSelectedInstanceIds() {
    return this.instanceNavigationTable.selection.selected
  }
}
