import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { prettyCase } from '../utilities.classes';

@Pipe({name: 'prettyCase'})

export class PrettyCasePipe implements PipeTransform {

    transform(value: string) {
        if (value) {
            return prettyCase(value);
        }
        return value;
    }

}
