import { Component } from '@angular/core';

@Component({
    selector: 'con-inconsistent-layout',
    templateUrl: './inconsistent-layout.component.html'
})

export class InconsistentLayoutComponent {

}
