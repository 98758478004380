import {Injectable} from '@angular/core';
import {
  InstanceData,
  InstanceDataUnioned,
  TaxonomyDataHotkeyBinding,
  TaxonomyFieldTypes,
  TaxonomyQuantity,
  TaxonomySign
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import {BehaviorSubject, Subject} from 'rxjs';
import {NewsInstancesService} from '../../doc-process-common/services/news-instances.service';
import {UtilService} from '../../doc-process-common/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class HotkeyHandlerService { // TODO merge with DocProcessLegacyCommonsService

  private hotkeyBindings = []
  private tabIndex = 0
  private shiftIndex = 0
  private altIndex = 0
  public ctrlCevent$ : Subject<string> = new Subject()
  public clickEvent$: Subject<MouseEvent> = new Subject<MouseEvent>()

  currentSelectedMainField = new BehaviorSubject(null)
  currentSelectedMarginField = new BehaviorSubject(null)
  currentSelectedAdjustedField = new BehaviorSubject(null)

  currentSelectedMainField$ = this.currentSelectedMainField.asObservable()
  currentSelectedMarginField$ = this.currentSelectedMarginField.asObservable()
  currentSelectedAdjustedField$ = this.currentSelectedAdjustedField.asObservable()

  handleNewsLabelingHotkeyBindings(ev: KeyboardEvent, data: InstanceData, selectedField: string, selectedPeriod: string) {
    if(!this.hotkeyBindings.length) this.createHotkeyBindings((data as InstanceDataUnioned), selectedField)
    switch(ev.key.toLowerCase()) {
      case 'tab': {
        ev.preventDefault()
        ev.stopPropagation()
        this.tabIndex = this.tabIndex < this.hotkeyBindings.length -1 ? this.tabIndex + 1 : this.tabIndex = 0
        this.shiftIndex = 0
        this.altIndex = 0
        const nextValue = this.hotkeyBindings[this.tabIndex].key
        if(nextValue) this.currentSelectedMainField.next(nextValue)
        break;
      }
      case 'shift': {
        ev.preventDefault()
        ev.stopPropagation()
        const nextValue = this.hotkeyBindings.find(b => b.key === this.currentSelectedMainField.getValue()).values.shift[this.shiftIndex]
        if(nextValue)this.currentSelectedMarginField.next(nextValue)
        this.shiftIndex = this.shiftIndex + 1
        this.altIndex = 0
        break;
      }
      case 'alt': {
        ev.preventDefault()
        ev.stopPropagation()
        const nextValue = this.hotkeyBindings.find(b => b.key === this.currentSelectedMainField.getValue()).values.alt[this.altIndex]
        if (nextValue) {
          this.currentSelectedAdjustedField.next(nextValue)
        }
        this.altIndex = this.altIndex + 1
        this.shiftIndex = 0
        break;
      }
      case 'o' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.orders])
      }
      case 'r' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.revenue])
      }/* Revenues */
      case 's' : {
        return this.setMainFieldValue('sales')
      }/* Sales */
      case 'g' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.gross_profit])
      }/* Gross profit */
      case 'd' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.ebitda])
      }/* EBITDA */
      case 'a' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.ebita])
      }/* EBITA */
      case 'e' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.ebit])
      }/* EBIT */
      case 't' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.pretax_profit])
      }/* Pretax Profit */
      case 'n' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.net_profit])
      }/* Net profit */
      case 'm' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.net_profit_after_min])
      }/* Net profit after Minority */
      case 'c' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.eps])
      }/* EPS */
      case 'v' : {
        return this.setMainFieldValue(TaxonomyFieldTypes[TaxonomyFieldTypes.dividend])
      }/* Dividend */
      case 'k' : {
        const valueToChange = (data as InstanceDataUnioned).taxonomy_data[selectedField].values.find(value => value.period === selectedPeriod)
        if (valueToChange) {
          valueToChange.quantity = TaxonomyQuantity.Thousand
        }
        break;
      }/* Select Thousands (K) as quantifier for selected field */
      case 'q' : {
        const valueToChange = (data as InstanceDataUnioned).taxonomy_data[selectedField].values.find(value => value.period === selectedPeriod)

        if (!valueToChange) {
          break;
        }
        if (valueToChange.quantity === TaxonomyQuantity.Percent) {
          break;
        }

        let quantifierIndex = this.utilService.ToStringArray(TaxonomyQuantity).findIndex(val => val === valueToChange.quantity)
        const latestAnnotation = valueToChange.values[valueToChange.values.length - 1]

        // let quantifierIndex = this.utilService.ToStringArray(TaxonomyQuantity).findIndex(val => {
        //   return val === this.newsInstanceService.selectedNewsAnnotation.fields[0].context.quantity
        // })

          quantifierIndex < Object.keys(TaxonomyQuantity).length - 2
            ? quantifierIndex++
            : quantifierIndex = 0
          valueToChange.quantity = TaxonomyQuantity[Object.keys(TaxonomyQuantity)[quantifierIndex]]

        latestAnnotation.quantity = valueToChange.quantity

        break;
      }/* Cycle quantifiers for selected field */
      case 'b' : {
        const valueToChange = (data as InstanceDataUnioned).taxonomy_data[selectedField].values.find(value => value.period === selectedPeriod)
        if(valueToChange) {
          valueToChange.quantity = TaxonomyQuantity.Billion
        }
        break;
      }/* Select Billion (B) as quantifier for selected field */
      case '-' : {
        const valueToChange = (data as InstanceDataUnioned).taxonomy_data[selectedField].values.find(value => value.period === selectedPeriod)
        if(valueToChange) {
          valueToChange.sign =  valueToChange.sign === TaxonomySign.Plus ? TaxonomySign.Minus : TaxonomySign.Plus
        }
        break;
      }/* Toggle between (+/-) for selected field */
    }
  }

  setMainFieldValue(key: string) {
    const nextValue = this.hotkeyBindings.find(b => b.key === key)
    if(nextValue) this.currentSelectedMainField.next(nextValue.key)
  }
  createHotkeyBindings(data: InstanceData & InstanceDataUnioned, selectedField: string) {
    let currentTabKey = ''
    if(data.taxonomy_data) {
      Object.keys(data.taxonomy_data).forEach((key, index) =>  {
        if(TaxonomyDataHotkeyBinding[key] === 'tab') {
          currentTabKey = key
          this.hotkeyBindings.push({key: key, values: {alt: [], shift: []}})
        } else if(TaxonomyDataHotkeyBinding[key] === 'alt') {
          this.hotkeyBindings.find(val => val.key === currentTabKey).values['alt'].push(key)
        } else if(TaxonomyDataHotkeyBinding[key] === 'shift') {
          this.hotkeyBindings.find(val => val.key === currentTabKey).values['shift'].push(key)
        }
      })
    }

    this.setSelectedFieldIndex(selectedField);
  }

  constructor(private utilService: UtilService,
              private newsInstanceService: NewsInstancesService
  ) {
  }

  setSelectedFieldIndex(selectedField: string, setValue = true) {

    const mainIndex = this.hotkeyBindings.findIndex(binding => binding.key === selectedField);
    const altIndex = this.hotkeyBindings.map(binding => binding.values.alt.findIndex(val => val === selectedField)).filter(index => index > -1)[0];
    const altValue = this.hotkeyBindings.find(binding => binding.values.alt.find(field => field === selectedField));
    const shiftIndex = this.hotkeyBindings.map(binding => binding.values.shift.findIndex(val => val === selectedField)).filter(index => index > -1)[0];
    const shiftValue = this.hotkeyBindings.find(binding => binding.values.shift.find(field => field === selectedField));

    if (mainIndex && mainIndex > -1) {
      this.tabIndex = mainIndex;
      if(setValue) {
        this.currentSelectedMainField.next(this.hotkeyBindings[mainIndex].key);

      }
    } else if (altIndex > -1) {
      this.altIndex = altIndex;
      this.tabIndex = this.hotkeyBindings.findIndex(binding => binding.key === altValue.key);
      if (altValue && setValue) {
        this.currentSelectedAdjustedField.next(altValue.key);
      }
    } else if (shiftIndex > -1) {
      this.shiftIndex = shiftIndex;
      this.tabIndex = this.hotkeyBindings.findIndex(binding => binding.key === shiftValue.key);
      if (shiftValue && setValue) {
        this.currentSelectedAdjustedField.next(shiftValue.key);
      }
    } else {
      if(setValue) {
        this.hotkeyBindings.length && this.currentSelectedMainField.next(this.hotkeyBindings[0].key);
      }
    }
  }
}
