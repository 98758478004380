import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { ValidatorConversionService } from '../../services/validator-conversion.service';

import { Relation, RelationService } from '../../services/relation.service';

@Component({
    selector: 'con-pivot-form',
    templateUrl: './pivot-form.component.html'
})

export class PivotFormComponent implements OnChanges {
    @Input() relation: any;
    @Input() object: any;
    @Input() disabled = false;
    @Input() fixedSearchParams: any = {};
    @Input() errors: any;
    @Input() parentEntity: any;
    @Input() parentCompany: any;

    @Output() onAbort: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    public group: UntypedFormGroup;
    public entity: any;
    public entityDescriptions: any;
    private relations: any;

    constructor(private validatorConverter: ValidatorConversionService,
                private relationService: RelationService,
                private formBuilder: UntypedFormBuilder) {}

    alreadyCreated() {
        return (this.object !== undefined
                && this.object.pivot !== undefined
                && this.object.pivot.id !== undefined);
    }

    objectSelected($event) {
        this.entity = $event;

        if (!this.hasPivotFields()) {
            this.returnData();
        }
    }

    returnData() {
        if (Relation.isHasManyToManyRelationType(this.relation.type)) {
            if (this.entity) {
                if (!this.alreadyCreated()) {
                    this.afterSave.emit({
                        entity: this.entity,
                        pivot: this.group.value
                    });
                } else {
                    const values = this.group.value;
                    Object.keys(values).forEach(key => {
                        this.entity.pivot[key] = values[key];
                    });

                    this.afterUpdate.emit({
                        entity: this.entity,
                        pivot: this.entity.pivot
                    });
                }
            }
        } else {
            this.afterSave.emit({
                entity: this.entity
            });
        }
    }

    hasPivotFields() {
        return this.relations.hasPivotFields();
    }

    abort() {
        if (this.alreadyCreated()) {
            this.onAbort.emit(this.object);
        }
    }

    empty() {
        if (!this.alreadyCreated()) {
            this.entity = undefined;
        }
    }

    ngOnChanges() {
        const fields = {};

        this.entity = this.object ? this.object : undefined;

        this.relations = this.relationService.getRelation(this.relation);
        this.relations.init()
                      .subscribe(() => {
                          this.entityDescriptions = this.relations.getAllRelatedEntityDescriptions();
                      });

        if (this.hasPivotFields()) {
            this.relation.pivot_fields.forEach(field => {
                const structure = [];

                if (this.alreadyCreated()
                    || (
                    this.object !== undefined
                    && this.object.pivot !== undefined
                    && this.object.pivot[field.key] !== undefined
                    && this.object.pivot[field.key] !== null
                    && this.object.pivot[field.key] !== '')) {
                    structure.push({
                        value: this.object.pivot[field.key],
                        disabled: field.disabled
                    });
                } else {
                    structure.push({
                        value: '',
                        disabled: false
                    })
                }

                // Default value
                const validators = [];
                field.rules.forEach(rule => {
                    const validator = this.validatorConverter.getValidatorByString(rule);
                    if (validator) {
                        validators.push(validator);
                    }
                });

                structure.push(validators);

                fields[field.key] = structure;
            });
        }

        this.group = this.formBuilder.group(fields);
    }
}
