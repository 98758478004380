
<div class="p-2">
  <!--  Language-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'Language'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="LANGUAGES"
      [fieldName]="'Language'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

  <!--  SRRI-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'SRRI'"
  >
    <con-multiple-choice-data-entry
      [instanceData]="instanceData"
      [multipleChoiceOptionsList]="SRRI"
      [fieldName]="'SRRI'"
    ></con-multiple-choice-data-entry>
  </con-instance-data-field-wrapper>

  <!--  InvestmentMandate-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'InvestmentMandate'"
  >
    <con-wide-textbox-data-entry
      [instanceData]="instanceData"
      [fieldName]="'InvestmentMandate'"
      class="d-block w-100"
    ></con-wide-textbox-data-entry>
  </con-instance-data-field-wrapper>

  <!--  PublicationDate-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'PublicationDate'"
  >
    <con-date-picker-component
      [dateObject]="instanceData.PublicationDate"
      (onValidation)="onDatePickerValidationChange($event)"
    ></con-date-picker-component>
  </con-instance-data-field-wrapper>

  <!--  OngoingChargesDate-->
  <con-instance-data-field-wrapper
    [instanceData]="instanceData"
    [currentFields]="currentFields"
    [fieldName]="'OngoingChargesDate'"
  >
    <con-date-picker-component
      [dateObject]="instanceData.OngoingChargesDate"
      (onValidation)="onDatePickerValidationChange($event)"
    ></con-date-picker-component>
  </con-instance-data-field-wrapper>

  <!--  ShareClasses-->
  <con-share-classes
    [instanceData]="instanceData"
    [fieldName]="'ShareClasses'"
    [currentFields]="currentFields"
  ></con-share-classes>
</div>
