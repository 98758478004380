import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
    selector: 'con-flexible-input',
    templateUrl: './flexible-input.component.html'
})

export class FlexibleInputComponent {

    @Input() field: any;
    @Input() control: any;
    @Input() entityForm: UntypedFormGroup;
    @Input() resetKey: string;
    @Input() reverseDates: boolean = false;
    @Input() isSaving: boolean = false;
    @Input() isSmallInput: boolean = false;

    private STRING_INPUTS = ['string'];
    private EDITOR_INPUTS = ['rich-text-editor'];
    private MULTIFILE_INPUTS = ['multiple-files'];
    private URL_INPUTS = ['url'];
    private EMAIL_INPUTS = ['email'];
    private TEXTAREA_INPUTS = ['text'];
    private FILE_INPUTS = ['file', 'image'];
    private SELECT_INPUTS = ['enum'];
    private DATE_INPUTS = ['date'];
    private DAY_INPUTS = ['day'];
    private CHECKBOX_INPUTS = ['boolean'];
    private INTEGER_INPUTS = ['int'];
    private PASSWORD_INPUTS = ['password'];
    private NUMBER_INPUTS = ['decimal'];
    private TELEPHONE_INPUTS = ['tel'];
    private MASKED_FIELDS = ['shares', 'share_count'];
    public mask: any = {
        signed:true,
        mask: Number,
        thousandsSeparator: ' ',
        scale: 0,
        nullable: true,
    };
    public radixMask: any = {
        signed:true,
        mask: Number,
        scale: 15,
        radix: '.',
        mapToRadix: [','],
        nullable: true,
    };


  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      customClasses: [
        {
          name: 'bold',
          class: 'boldText',
        },
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['fontName']
      ]
    };



    shouldUseIntegerInput() {
        return this.INTEGER_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUsePasswordInput() {
        return this.PASSWORD_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseCheckbox() {
        return this.CHECKBOX_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseDatePicker() {
        return this.DATE_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseDayPicker() {
        return this.DAY_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseTextInput() {
        return this.STRING_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseUrlInput() {
        return this.URL_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseEmailInput() {
        return this.EMAIL_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseTextAreaInput() {
        return this.TEXTAREA_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseFileInput() {
        return this.FILE_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseSelectInput() {
        return this.SELECT_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseNumberInput() {
        return this.NUMBER_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseTelephoneInput() {
        return this.TELEPHONE_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldBeMasked() {
        return this.MASKED_FIELDS.indexOf(this.field.key) > -1;
    }
    shouldUseEditorInput() {
      return this.EDITOR_INPUTS.indexOf(this.field.type) > -1;
    }
    shouldUseMultiFileUpload() {
      return this.MULTIFILE_INPUTS.indexOf(this.field.type) > -1;
    }
}
