<ul *ngIf="treeData">

    <li *ngFor="let node of treeData">

        <div class="tree-node" [ngClass]="{ 'grad':enable_row_highlight(node?.is_highlighted),  'parent-node' : node.level , 'active-tree-node': node.focus}" >
            <con-icon-by-name (click)="toggleChild(node)" class="toggle" *ngIf="node.children" iconName="angle-right"
                [mutations]="node.showChildren ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>

            <span class="padding-name">{{ node.name }} </span>
          <con-icon-by-name [ngbTooltip]="node?.description" iconName="question-circle-o" *ngIf="node?.description"></con-icon-by-name>
          <div *ngIf="showPrevious" class="pull-right">
              <span class="previous-value">
                <con-icon-by-name class="pull-right ml-15 cursor-pointer" *ngIf="node?.prev_display_value && node?.prev_value_source" [ngbTooltip]="formatTooltip(node?.prev_value_source)" iconName="info"></con-icon-by-name>
                {{node.prev_display_value | stringify | imask: mask}}
              </span>
          </div>
          <con-icon-by-name *ngIf="node?.value_source && node?.display_value" class="pull-right ml-15 cursor-pointer" [ngbTooltip]="formatTooltip(node?.value_source)"  iconName="info"></con-icon-by-name>
          <span  class="editor-input pull-right" >
                      <input class="editor-input bg-transparent" [ngClass]="{'value-error': (node.has_limit_error || node.has_error), 'locked': locked}"
                            [(ngModel)]="node.display_value" placeholder="Enter a value here" (change)="valueChanged($event,node)"
                            [imask]="mask" [unmask]="true" (focus)="node.focus=true" (focusout)="node.focus=false" [disabled]="locked"  />
          </span>

        </div>

        <con-income-statement-editor *ngIf="node.showChildren" [treeData]="node.children" [parent]="node"
            [quantity]="quantity" (onValueChanged)="detectChange($event)" [autoCalc]="autoCalc"
            (onMaxValueReached)="onReachingMaxValue($event)" [locked]="locked" [entity]="entity"
            [showPrevious]="showPrevious">
        </con-income-statement-editor>

    </li>

</ul>
