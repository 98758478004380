import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';

@Component ({
    selector: 'con-webhook-editor',
    templateUrl: './webhook-editor.component.html'
})
export class WebhookEditorComponent implements OnChanges {
    loading = false;

    @Input() customer: any;

    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

    private showsForm = false;
    public fixedValues: any;

    constructor(public profile: ProfileService,
                private dateConverter: DateConverterService) {}

    ngOnChanges() {
        this.fixedValues = {
            revised_at: this.dateConverter.toEntityString(moment())
        }
    }

    customerUpdated(customer: any) {
        if (customer.wehook_active) {
            this.showsForm = false;
        }
        this.afterUpdate.emit(customer);
    }

    toggleForm() {
        this.showsForm = !this.showsForm;
    }

    hasNoWebsite() {
        return this.customer.webhook_url === null;
    }

    showForm() {
        return this.customer.webhook_url === null || this.showsForm;
    }
}
