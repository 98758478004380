<div class="card mb-2" *ngIf="showForm()">
    <div class="card-header">
        <con-icon-by-name iconName="phone" [mutations]="['fw']"></con-icon-by-name>Phone
    </div>
    <div class="card-body">
        <con-entity-form-content
            entityName="Company"
            [entity]="company"
            [only]="['phone', 'revised_at']"
            [fixedValues]="fixedValues"
            [showWhenChanged]="false"
            [canDelete]="false"
            (afterSave)="companyUpdated($event)"
            toastMessage="Phone updated successfully!"
        >
        </con-entity-form-content>
    </div>
    <div class="card-body pt-0" *ngIf="company.phone !== null">
        <button class="btn btn-secondary" (click)="toggleForm()">
            <con-icon-by-name iconName="remove"></con-icon-by-name> Cancel
        </button>
    </div>
</div>
<div class="card mb-2" *ngIf="!showForm()">
    <div class="card-header">
        <con-icon-by-name iconName="phone"></con-icon-by-name>Phone
    </div>
    <div class="card-body">
        <div class="alert alert-info">
            <con-icon-by-name iconName="phone" [mutations]="['fw']" class="mr-1"></con-icon-by-name><strong><a [href]="'tel:' + company.phone">{{ company.phone }}</a></strong>
        </div>
        <button class="btn btn-info" (click)="toggleForm()" *ngIf="profile.can(profile.getPermissionName('Company', 'update'))">
            <con-icon-by-name iconName="pencil"></con-icon-by-name> Edit
        </button>
    </div>
</div>
