import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { ShareholderSettings, ApiSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'con-shareholders-outdated',
    templateUrl: './shareholders-outdated.component.html'
})

export class ShareholdersOutdatedComponent implements OnInit, OnDestroy {
    public revisions: any;
    public loading = true;
    private reloadSubscription = new Subscription;
    private outdatedSubscription = new Subscription;
    public noDataMessage = 'No data to display.';
    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService) {}

    ngOnInit() {
        this.loadPage(1);
        this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
            if (type === 'Outdated') {
                this.loadPage(1);
            }
        });
    }

    ngOnDestroy() {
        this.reloadSubscription.unsubscribe();
        this.outdatedSubscription.unsubscribe();
    }

    loadPage(pageNumber: number) {
        this.countService.getCounts(true);
        this.loading = true;
        this.outdatedSubscription = this.http.get<any>(ShareholderSettings.BASE_URL + '/' + ShareholderSettings.REVISIONS_ENDPOINT,
                {
                    params: {
                        page: String(pageNumber)
                    }
                })
                 .subscribe(res => {
                     this.countService.setCount('outdated', res.total);
                     this.formatRevisionData(res);
                     this.loading = false;
                 }, err => {
                     this.loading = false;
                     if (err.status === ApiSettings.RESPONSE_CODES.PERMISSION_ERROR) {
                         this.router.navigate(['shareholders', 'auth'], { skipLocationChange: true });
                     }
                 });
    }

    formatRevisionData(response: any) {
        response.data.forEach(company => {
            company.outdated = true;
            company.shareholders.forEach(shareholder => {
                if (shareholder.source_type === 'CompanyReport' && shareholder.source && shareholder.source.company) {
                    shareholder.source.company.name = shareholder.source.company.name + ' - ' + moment(shareholder.source.report_date). format('yyyy-MM-DD');
                }
            });
        });
        this.revisions = response;
    }

    removeFromList(company: any) {
        const indx = this.revisions.data.findIndex(c => c.id === company.id);
        this.revisions.data.splice(indx, 1);
        if (!this.revisions.data.length) {
            this.ngOnInit();
        }
    }

    whenFixed(company: any) {
        this.removeFromList(company);
        this.countService.decrementCount('revisions');
    }

    updateInList(company: any) {
        const indx = this.revisions.data.findIndex(c => c.id === company.id);
        company.positions = this.revisions.data[indx].positions;
        company.required_languages = this.revisions.data[indx].required_languages;
        company.descriptions = this.revisions.data[indx].descriptions;
        this.revisions.data[indx] = company;
    }
}
