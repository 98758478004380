import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {shareReplay} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {CompanyReportsSettings} from '../../../settings.class';

@Component({
  selector: 'con-filter-static',
  templateUrl: './filter-static.component.html',
  styleUrls: ['./filter-static.component.scss']
})
export class FilterStaticComponent implements OnInit {

  public exchanges: any[] = [];
  public presetExchanges : any = {};

  exchange_ids = '';
  @Output() filterUpdate = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get(CompanyReportsSettings.BASE_URL +'/get_exchange_presets/').subscribe((exchangePresets: any) => {

      this.presetExchanges = exchangePresets;

      for (let key in this.presetExchanges) {
        if(key === 'Nordic') {
          const initialPreset = {
            key,
            value: this.presetExchanges[key]
          }
          this.addExchange(initialPreset);
        }
      }
    })
  }

  updateFilter() {
    const filterObject = {
      exchange_ids: this.exchange_ids
    }
    this.filterUpdate.emit(filterObject);
  }
  refresh(){
    if (this.exchanges.length) {
      this.exchange_ids = this.exchanges.map(e => e.id).join();
    } else {
      this.exchange_ids = '';
    }
    this.updateFilter()
  }
  addExchange(data: any) {
    // From group
    if (data.hasOwnProperty('value') && Array.isArray(data.value)) {
      const uniqueExchanges = [];

      if (this.addedToExchangeFilter(data)) {
        // toggle remove
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList !== -1) {
            this.exchanges.splice(givenExchangeInExistingList, 1);
          }
        })
        this.refresh();
      } else {
        // toggle add
        (data.value as Array<any>).forEach((preset) => {
          const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(preset.id));
          if (givenExchangeInExistingList === -1) {
            uniqueExchanges.push(preset);
          }
        })
        if (uniqueExchanges.length > 0) {
          this.exchanges.push(...uniqueExchanges);
          this.refresh();
        }
      }
    } else {
      // From search box
      const givenExchangeInExistingList = this.exchanges.findIndex(e => Number(e.id) === Number(data.id));
      if (givenExchangeInExistingList === -1) {
        this.exchanges.push(data);
        this.refresh();
      }
    }
  }
  addedToExchangeFilter(preset): boolean {
    if (preset.hasOwnProperty('value') && Array.isArray(preset.value)) {
      const mappedPresetIds = preset.value.map((item) => item.id);
      const mappedExchangesIds = this.exchanges.map((item) => item.id);
      return mappedPresetIds.every(v => mappedExchangesIds.includes(v));
    }
    return false;
  }
  removeExchange(exchange: any) {
    this.exchanges = this.exchanges.filter(e => Number(e.id) !== Number(exchange.id));
    this.refresh();
  }
  removeAllExchanges() {
    this.exchanges = [];
    this.refresh();
  }

}
