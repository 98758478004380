import {Component, HostListener, Input, OnDestroy, OnChanges} from '@angular/core';
import {
  InstanceDataTopicClassification, TopicClassificationField
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import {DocProcessLegacyCommonsService} from '../../services/doc-process-legacy-commons.service';
import {UtilService} from '../../../doc-process-common/services/util.service';
import {DocProcessProjectName, SelectedProjectService} from '../../services/selected-project.service';

@Component({
  selector: 'con-topic-classification-instances-handler',
  templateUrl: './topic-classification-instances-handler.component.html',
  styleUrls: ['./topic-classification-instances-handler.component.scss']
})
export class TopicClassificationInstancesHandlerComponent implements OnChanges {
  @Input() public instanceData: InstanceDataTopicClassification = null;
  public companyId: number;
  public companyWebsite: any;
  public highlightedFieldIndex: number;
  public activeSidebarMenuTab: string;

  constructor(
    private docProcessCommonsService: DocProcessLegacyCommonsService,
    private selectedProjectService: SelectedProjectService,
  ) {

  }

  public ngOnChanges() {
    this.highlightedFieldIndex = this.instanceData.taxonomy_data.classes.length;
    this.companyId = this.instanceData?.doc_meta?.company?.company_id
    this.companyWebsite = this.instanceData?.doc_meta?.company?.company_website
    if (this.selectedProjectService.project === DocProcessProjectName.Calendar)
      this.activeSidebarMenuTab = 'Calendar'
  }

  public restoreData(restore: boolean) {
    this.docProcessCommonsService.restoreData.next(restore)
  }

  openCalendarPage() {
    window.open("/calendar/company/" + this.companyId, "_blank");
  }

  openCompanyWebsite() {
    window.open(this.companyWebsite, "_blank");
  }

  openCompanyPage() {
    window.open("/entity/company/" + this.companyId, "_blank");
  }

  handleSelectionChange($event: Array<any>) {
    this.instanceData.calendar_event_ids = $event
  }
}
