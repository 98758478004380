import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

import { map } from 'rxjs/operators';


import { EntitiesService, EntityDescription } from '../../entities/services/entities.service';

import { HttpClient } from '@angular/common/http';
import { MatchSettings } from '../../settings.class';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'con-matcher',
    templateUrl: './matcher.component.html'
})

export class MatcherComponent implements OnInit {
    @Input() match: any;
    @Output() afterMatch: EventEmitter<any> = new EventEmitter<any>();

    public loading: boolean;
    public expanded = true;

    private entityDescription: EntityDescription;
    public inactiveField: any;
    public inactiveFormControl: UntypedFormControl;

    constructor(private http: HttpClient,
                private entities: EntitiesService,
                private toastr: ToastrService ) {}

    ngOnInit() {
        this.loading = true;
        this.inactiveFormControl = new UntypedFormControl();

        this.entities
            .getEntityDescriptionByEntityName('Instrument')
            .pipe(map(entityDescription => {
                return entityDescription
                        .getData()
                        .fields
                        .find(f => f.key === 'inactive');
            }))
            .subscribe(field => {
                this.inactiveField = field;
                this.loading = false;
            });
    }

    public isExpanded() {
        return this.expanded;
    }

    public toggleExpanded() {
        this.expanded = !this.expanded;
    }

    public markAsInactive() {
        this.loading = true;

        this.http.post(
                    MatchSettings.BASE_URL + '/' + MatchSettings.MARK_AS_INACTIVE_ENDPOINT,
                    { isin: this.match.isin, inactive: this.inactiveFormControl.value }
                )
                 .subscribe(res => {
                     this.afterMatch.emit(this.match);
                     this.loading = false;
                     this.toastr.success('Marked as inactive!', 'Instrument');
                 }, err => {
                     this.loading = false;
                 });
    }

    public matchWith(company: any) {
        this.loading = true;

        this.http.post(
                    MatchSettings.BASE_URL + '/' + MatchSettings.MATCH_ENDPOINT,
                    { isin: this.match.isin, company_id: company.id }
                )
                 .subscribe(res => {
                     this.toastr.success('Matched with ' + company.name, 'Instrument');
                     this.afterMatch.emit(this.match);
                     this.loading = false;
                 }, err => {
                     this.loading = false;
                 })
    }
}
