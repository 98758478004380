import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { upperCaseFirst } from 'change-case';

@Pipe({name: 'capitalize'})

export class CapitalizePipe implements PipeTransform {

    transform(value: string) {
        if (value) {
            return upperCaseFirst(value);
        }
        return value;
    }

}
