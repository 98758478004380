import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { DeliveryState } from 'app/task-manager/services/methods.service';
import { Delivery } from 'app/task-manager/typings/Typings';

@Component({
  selector: 'con-delivery',
  templateUrl: './deliveries-view.component.html',
  styleUrls: ['./deliveries-view.component.scss']
})
export class DeliveriesViewComponent implements OnInit, OnDestroy {
  public deliveries: Delivery[];
  public untouchedDeliveries: Delivery[];
  public errMessage: string;
  public isLoading = true;
  public dropdownOptions = ['Running', 'Pending', 'Completed'];
  public deliveryLimits = ['50', '100', '150'];
  public fromDate: string = new Date().toISOString().substring(0, 10);
  public toDate: string = new Date().toISOString().substring(0, 10);
  public fromDateValid = true;
  public toDateValid = true;
  public deliveryState: string;
  public deliveryOffset = 0;
  public deliveryLimit: number;
  public invalidDateFormat = false;
  public order: string;
  public categories: string[] = [];
  public selectedCategories: string [] = [];
  public hasDateSet = false;

  constructor(
    private router: Router,
    private taskManagerService: TaskManagerService,
    private subscriptionService: SubscriptionService,
    private utilService: UtilService
  ) {
    this.utilService.refreshPage(60, () => this.requestAction(this.deliveryLimit ? this.deliveryLimit.toString() : this.deliveryLimits[0]));
  }

  ngOnInit() {
    if (!this.deliveryState) {
      this.deliveryState = DeliveryState.Running
    }
    this.requestAction(this.deliveryLimits[0]);
    this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: Delivery[] | any) => {
      this.errMessage = '';
      if (response.type === 'getDeliveriesFromGivenTimeRange') {
        if (this.deliveryState === DeliveryState.Running || this.deliveryState === DeliveryState.Pending) {
          const deliveries = response.data;
          deliveries.reverse();
          this.deliveries = deliveries;
          this.untouchedDeliveries = deliveries;
        } else {
          this.deliveries = response.data;
          this.untouchedDeliveries = response.data;
        }
        if (this.deliveries.length <= 0) {
          this.errMessage = 'No available deliveries for selected date(s)'
        }
        this.isLoading = false;
        return;
      }

      if (this.deliveryState === DeliveryState.Running || this.deliveryState === DeliveryState.Pending) {
        const deliveries = response;
        deliveries.reverse();
        this.deliveries = deliveries;
        this.untouchedDeliveries = deliveries;
      } else {
        this.deliveries = response;
        this.untouchedDeliveries = response;
      }
      this.createCategories(response);
      this.checkIfLocalStorageContainsCategory();
      this.isLoading = false;
    });
    this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: string) => {
      this.errMessage = err;
    });
  }
  ngOnDestroy() {
    this.subscriptionService.apiResponseSub.unsubscribe();
    this.utilService.stopRefreshingPage();
  }

  redirectToTask(id: number) {
    this.router.navigate(['task_manager', 'deliveries', id]);
  }
  checkIfFromDateIsValid() {
    this.fromDateValid = this.fromDate ? this.utilService.isValidDate(this.fromDate) : true;
  }
  checkIfToDateIsValid() {
    this.toDateValid = this.toDate ? this.utilService.isValidDate(this.toDate) : true;
  }

  search(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'enter') {
      if (this.fromDateValid && this.toDateValid) {
        this.getDeliveriesFromGivenTimeRange();
      }
    }
    if (event.key.toLowerCase() === 'tab') {
      const date = new Date();
      if (this.fromDate.length <= 4) {
        switch (this.fromDate) {
          case 'today': {
            this.fromDate = date.toISOString().substring(0, 10);
            this.toDate = new Date().toISOString().substring(0, 10);
            this.toDateValid = true;
            this.fromDateValid = true;
          } break;
          case 'yesterday': {
            date.setDate(date.getDate() - 1);
            this.fromDate = date.toISOString().substring(0, 10);
            this.toDate = new Date().toISOString().substring(0, 10);
            this.toDateValid = true;
            this.fromDateValid = true;
          } break;
          default: {
            if (this.utilService.isNumeric(this.fromDate)) {
              try {
                date.setDate(date.getDate() - Number(this.fromDate));
                this.fromDate = date.toISOString().substring(0, 10);
                this.toDate = '';
                this.toDateValid = true;
                this.fromDateValid = true;
              } catch (err) {
              }
            }
          }
        }
      }
    }
  }

  sortingOrder(a) {
    return a;
  }
  checkIfLocalStorageContainsCategory() {
    const selectedCategoriesFromLocalStorage = localStorage.getItem('category-deliveries');
    if (selectedCategoriesFromLocalStorage) {
      this.selectedCategories = JSON.parse(localStorage.getItem('category-deliveries'));
      this.deliveries = this.untouchedDeliveries.filter((data: Delivery) => {
        return this.selectedCategories.find(predicate => predicate === data.category);
      })
    }
  }

  getDeliveriesFromGivenTimeRange() {
    if (this.deliveryLimit) {
      this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState, this.fromDate, this.toDate, this.deliveryLimit, this.deliveryOffset);
      return;
    }
    this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState, this.fromDate, this.toDate)
  }


  getDeliveriesWithoutTimeRange() {
    if (this.deliveryLimit && !this.deliveryState) {
      this.requestDeliveryWithLimitAndState('Running', this.deliveryLimit);
      return;
    }
    if (this.deliveryState && !this.deliveryLimit) {
      this.requestDeliveryWithLimitAndState(this.deliveryState, 50);
      return;
    }
    if (this.deliveryState && this.deliveryLimit) {
      this.requestDeliveryWithLimitAndState(this.deliveryState, this.deliveryLimit);
      return;
    }
  }

  selected(category?: string) {
    const selectedCategoriesFromLocalStorage = JSON.parse(localStorage.getItem('category-deliveries'));
    if (selectedCategoriesFromLocalStorage) {
      this.selectedCategories = selectedCategoriesFromLocalStorage;
    }
    if (this.selectedCategories.indexOf(category) < 0) {
      this.selectedCategories.push(category);
      this.deliveries = this.untouchedDeliveries.filter((data: Delivery) => {
        return this.selectedCategories.find(predicate => predicate === data.category);
      })
      localStorage.setItem('category-deliveries', JSON.stringify(this.selectedCategories));
    } else {
      const existingCategory = this.selectedCategories.indexOf(category);
      this.selectedCategories.splice(existingCategory, 1);
      if (this.selectedCategories.length <= 0) {
        this.deliveries = this.untouchedDeliveries;
        localStorage.removeItem('category-deliveries')
      } else {
        this.deliveries = this.untouchedDeliveries.filter((data: Delivery) => {
          return this.selectedCategories.find(predicate => predicate === data.category);
        })
        localStorage.setItem('category-deliveries', JSON.stringify(this.selectedCategories));
      }
    }
  }


  requestDeliveryWithLimitAndState(deliveryState: string, limit: number) {
    this.deliveryLimit = limit;
    switch (deliveryState) {
      case DeliveryState.Running: {
        return this.taskManagerService.getRunningDeliveries(limit, this.deliveryOffset);
      }
      case DeliveryState.Pending: {
        return this.taskManagerService.getPendingDeliveries(limit, this.deliveryOffset);
      }
      case DeliveryState.Completed: {
        return this.taskManagerService.getCompletedDelieveries(limit, this.deliveryOffset);
      }
    }
  }

  loadMoreDeliveris() {
    this.deliveryLimit = this.deliveryLimit + this.deliveryLimit;
    if (this.hasDateSet) {
      return this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState || 'Running', this.fromDate, this.toDate, this.deliveryLimit, this.deliveryOffset || 0);
    }
    this.requestDeliveryWithLimitAndState(this.deliveryState, this.deliveryLimit);
  }

  createCategories(deliveries: Delivery[]) {
    this.categories = deliveries.map((delivery: Delivery) => delivery.category).filter(this.utilService.onlyUnique);
  }

  requestAction(action: string) {
    this.errMessage = '';
    switch (action) {
      case 'Running': {
        this.order = 'asc';
        this.deliveryState = DeliveryState.Running;
        return this.fromDate || this.toDate ? this.getDeliveriesFromGivenTimeRange() : this.requestDeliveryWithLimitAndState(this.deliveryState, this.deliveryLimit);
      };
      case 'Pending': {
        this.order = 'asc';
        this.deliveryState = DeliveryState.Pending;
        return this.fromDate || this.toDate ? this.getDeliveriesFromGivenTimeRange() : this.requestDeliveryWithLimitAndState(this.deliveryState, this.deliveryLimit);
      }
      case 'Completed': {
        this.order = 'desc';
        this.deliveryState = DeliveryState.Completed;
        return this.fromDate || this.toDate ? this.getDeliveriesFromGivenTimeRange() : this.requestDeliveryWithLimitAndState(this.deliveryState, this.deliveryLimit);
      }
      case '50': {
        this.deliveryLimit = 50;
        this.deliveryOffset = 0;
        if (this.hasDateSet) {
          return this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState || 'Running', this.fromDate, this.toDate, this.deliveryLimit, this.deliveryOffset || 0);
        }
        return this.requestDeliveryWithLimitAndState(this.deliveryState, 50)
      }
      case '100': {
        this.deliveryLimit = 100;
        this.deliveryOffset = 0;
        if (this.hasDateSet) {
          return this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState || 'Running', this.fromDate, this.toDate, this.deliveryLimit, this.deliveryOffset || 0);
        }
        return this.requestDeliveryWithLimitAndState(this.deliveryState, 100)
      }
      case '150': {
        this.deliveryLimit = 150;
        this.deliveryOffset = 0;
        if (this.hasDateSet) {
          return this.taskManagerService.getDeliveriesFromGivenTimeRange(this.deliveryState || 'Running', this.fromDate, this.toDate, this.deliveryLimit, this.deliveryOffset || 0);
        }
        return this.requestDeliveryWithLimitAndState(this.deliveryState, 150)
      }
    }
  }
}
