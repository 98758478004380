import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  public zoomMagnification: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  stylingEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  constructor() {
    this.zoomMagnification.subscribe( (val) => {
      // console.log("zoom:"+val/100)
      // console.log(val/100)
    })
  }
}
