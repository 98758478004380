import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, map, throttleTime} from 'rxjs/operators';
import {TaxonomyModule} from '../../doc-process-common/models/taxonomy-module';
import {UtilService} from '../../doc-process-common/services/util.service';

@Injectable({
  providedIn: null
})
export class TaxonomyFieldListService {
  public focusedTaxonomyModulePosition: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _onModuleClick: Subject<TaxonomyModule> = new Subject<TaxonomyModule>();
  public topLevelValues: BehaviorSubject<{ [id: number] : [boolean, number] }> = new BehaviorSubject<{[id: number]: [boolean, number]}>({}) // TODO might that be a Record<number, [boolean, number]> ?

  setOnModuleClick(val: TaxonomyModule): void {
    this._onModuleClick.next(val)
  }
  getOnModuleClick(): Observable<TaxonomyModule> {
    return this._onModuleClick.pipe(throttleTime(200))
    // this event used to trigger all parent modules, that's why here's a throttleTime.
    // then we solved it using $event.stopPropagation().
  }

  constructor() {
    this.getOnModuleClick().subscribe( (clickedModule: TaxonomyModule) => {
      UtilService.logIfAdmin("subscribed onModuleClick:")
      UtilService.logIfAdmin(clickedModule)
      this.focusedTaxonomyModulePosition.next(clickedModule.position)
    })
  }

  public isFieldFocused(position: number | undefined): Observable<boolean> { // TODO this seems unused. shall we remove?
    return this.focusedTaxonomyModulePosition.pipe(
      map( (focusedModulePosition: number) => {
        return focusedModulePosition === position
      })
    );
  }
}
