import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from './../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EntitiesModule } from '../entities/entities.module';
import { AuthModule } from '../auth/auth.module';

import { MomentModule } from 'ngx-moment';
import { TaskManagerLayoutComponent } from './layout/task-manager-layout.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { EditorComponent } from './components/editor/editor.component'
import { TasksViewComponent } from './views/tasks-view/tasks-view.component';
import { CreateTaskViewComponent } from './views/create-task-view/create-task-view.component';
import { TaskDetailViewComponent } from './views/task-detail-view/task-detail-view.component';
import { TableComponent } from './components/table/table.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DeliveriesViewComponent } from './views/deliveries-view/deliveries-view.component';
import { DeliveryDetailViewComponent } from './views/delivery-detail-view/delivery-detail-view.component';
import { FormsModule } from '@angular/forms';
import { AgendEditViewComponent } from './views/task-detail-view/agent-edit-view/agent-edit-view.component';
import { EmailMappingViewComponent } from './views/task-detail-view/email-mapping-view/email-mapping-view.component';
import { SymbolsViewComponent } from './views/symbols-view/symbols-view.component';
import { IndiciesViewComponent } from './views/task-detail-view/indicies-view/indicies-view.component';
import { SwitchComponent } from './components/switch/switch.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        EntitiesModule,
        AuthModule,
        RouterModule,
        NgbModule,
        MomentModule,
        NgJsonEditorModule,
        FormsModule
    ],

    providers: [

    ],

    declarations: [
        TaskManagerLayoutComponent,
        TasksViewComponent,
        CreateTaskViewComponent,
        DeliveriesViewComponent,
        TaskDetailViewComponent,
        AgendEditViewComponent,
        SymbolsViewComponent,
        EmailMappingViewComponent,
        IndiciesViewComponent,
        EditorComponent,
        TableComponent,
        LoaderComponent,
        DeliveryDetailViewComponent,
        SwitchComponent
    ],

    exports: []
})

export class TaskManagerModule {}
