import { Component, Input, Output, OnInit, OnChanges, OnDestroy, EventEmitter } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EntitiesService } from '../../../entities/services/entities.service';
import { EntityConfirmModalComponent } from 'app/entities/components/views/entity-confirm-modal.component';

@Component({
    selector: 'con-tier-company-editor',
    templateUrl: './tier-company-editor.component.html'
})

export class TierCompanyEditorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() result: any;
    @Input() tierId: number;

    @Input() canRemoveManger = true;

    @Input() fetching = false;
    @Input() showIgnore = false;
    @Input() showUnignore = false;
    @Input() showInclude = false;
    @Input() showExclude = false;

    @Output() companyUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() companyIgnored: EventEmitter<any> = new EventEmitter<any>();
    @Output() companyUnignored: EventEmitter<any> = new EventEmitter<any>();
    @Output() companyIncluded: EventEmitter<any> = new EventEmitter<any>();
    @Output() companyExcluded: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() termsChanged: EventEmitter<string> = new EventEmitter<string>();

    private editing: any = {};
    private loading: any = {};
    private errors: any = {};
    public noDataMessage = 'No data to display.';
    public companyName: UntypedFormControl = new UntypedFormControl();
    public companyNameSubscription: Subscription;
    public generalErrors: any;
    public includingAll = false;

    constructor(private entitiesService: EntitiesService,
                private toastr: ToastrService,
                private modalService: NgbModal) {}

    refresh() {
        this.pageChanged.emit(this.result.current_page);
    }

    ngOnInit() {
        this.companyNameSubscription = this.companyName.valueChanges
                                                       .pipe(debounceTime(300))
                                                       .subscribe(
                                                           companyName => {
                                                               this.termsChanged.emit(companyName);
                                                           }
                                                       );
    }

    ngOnDestroy() {
        this.companyNameSubscription.unsubscribe();
    }

    ngOnChanges() {
        this.editing = {};
        this.loading = {};
        this.errors = {};
    }

    changePage(pageNumber: number) {
        this.pageChanged.emit(pageNumber);
    }

    doAction(companyId: Number) {
        this.loading[companyId + '-action'] = true;
    }

    actionFinished(companyId: Number) {
        this.loading[companyId + '-action'] = false;
    }

    isDoingAction(companyId: Number) {
        return this.loading[companyId + '-action'];
    }

    managerHasErrors(companyId: Number) {
        return this.errors[companyId + '-manager'];
    }

    emptyManagerErrors(companyId: Number) {
        delete this.errors[companyId + '-manager'];
    }

    getManagerErrors(companyId: Number) {
        return this.errors[companyId + '-manager'];
    }

    companyHasManager(company: any) {
        return company.manager_id !== null;
    }

    isEditingManager(companyId: Number) {
        return this.editing[companyId + '-manager'];
    }

    isLoadingManger(companyId: Number) {
        return this.loading[companyId + '-manager'];
    }

    toggleEditingManager(companyId: Number) {
        if (!(companyId + '-manager' in this.editing)) {
            this.editing[companyId + '-manager'] = false;
        }
        this.emptyManagerErrors(companyId);
        this.editing[companyId + '-manager'] = !this.editing[companyId + '-manager'];
    }

    hideEditingManager(companyId: Number) {
        this.editing[companyId + '-manager'] = false;
    }

    includeCompany(company: any) {
        this.doAction(company.id);

        this.entitiesService.addRelation(
            'CompanyTier',
            this.tierId,
            'companies',
            [ company.id ]
        )
        .subscribe(res => {
            this.companyIncluded.emit(company);
            this.actionFinished(company.id);
        }, err => {
            if (err.isValueError()) {
                this.generalErrors = err.getData().general;
                this.toastr.error(this.generalErrors, 'Include');
            }
            console.log(err);
            this.actionFinished(company.id);
        });
    }

    confirmIncludeAll() {
        this.includingAll = true;
        this.entitiesService.includeAll(
            'CompanyTier',
            this.tierId
        )
        .subscribe(res => {
            this.includingAll = false;
            this.toastr.success('Success!', 'Include');
            this.pageChanged.emit(1);
        }, err => {
            if (err.isValueError()) {
                this.generalErrors = err.getData().general;
                this.toastr.error(this.generalErrors, 'Include');
                this.pageChanged.emit(1);
            }
            this.includingAll = false;

        });
    }
    includeAllCompany() {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
              title: 'Include All',
              message: 'Are you sure you want to include all missing companies ?',
              alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.confirmIncludeAll();
          }, (reason) => {
          });
    }

    excludeCompany(company: any) {
        this.doAction(company.id);

        this.entitiesService.removeRelation(
            'CompanyTier',
            this.tierId,
            'companies',
            [ company.id ]
        )
        .subscribe(res => {
            this.companyExcluded.emit(company);
            this.actionFinished(company.id);
        }, err => {
            console.log(err);
            this.actionFinished(company.id);
        });
    }

    ignoreCompany(company: any) {
        this.doAction(company.id);

        this.entitiesService.addRelation(
            'CompanyTier',
            this.tierId,
            'ignoredCompanies',
            [ company.id ]
        )
        .subscribe(res => {
            this.companyIgnored.emit(company);
            this.actionFinished(company.id);
        }, err => {
            console.log(err);
            this.actionFinished(company.id);
        });
    }

    unignoreCompany(company: any) {
        this.doAction(company.id);

        this.entitiesService.removeRelation(
            'CompanyTier',
            this.tierId,
            'ignoredCompanies',
            [ company.id ]
        )
        .subscribe(res => {
            this.companyUnignored.emit(company);
            this.actionFinished(company.id);
        }, err => {
            this.actionFinished(company.id);
        });
    }

    removeManger(company: any) {
        this.loading[company.id + '-manager'] = true;

        this.entitiesService.saveEntity(
            'Company',
            {
                id: company.id,
                updated_at: company.updated_at,
                manager_id: null
            }
        )
        .subscribe(res => {
            this.loading[company.id + '-manager'] = false;
            this.companyUpdated.emit(res);
        }, err => {
            if (err.isValueError()) {
                this.errors[company.id + '-manager'] = err.getData().general;
            }
            this.loading[company.id + '-manager'] = false;
        });
    }

    updateManager(company: any, manager: any) {
        this.loading[company.id + '-manager'] = true;
        this.entitiesService.saveEntity(
            'Company',
            {
                id: company.id,
                updated_at: company.updated_at,
                manager_id: manager.id
            }
        )
        .subscribe(res => {
            this.loading[company.id + '-manager'] = false;
            this.hideEditingManager(company.id);
            this.companyUpdated.emit(res);
        }, err => {
            this.loading[company.id + '-manager'] = false;
        });
    }
}
