import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'con-checkbox-input',
    templateUrl: './checkbox-input.component.html'
})

export class CheckboxInputComponent implements OnChanges, OnInit {
    @Input() control: UntypedFormControl;
    @Input() field: any;
    public checkBoxId: any;

    ngOnInit() {
        this.checkBoxId = Math.random();
    }

    ngOnChanges() {
        if (this.control.value === '') {
            if (this.field.default !== undefined) {
                this.control.setValue(this.field.default);
            } else {
                this.control.setValue(false);
            }
        }
    }
}
