import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'con-ref-id-input',
  templateUrl: './ref-id-input.component.html',
  styleUrls: ['./ref-id-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RefIdInputComponent),
      multi: true
    }
  ]
})
export class RefIdInputComponent implements ControlValueAccessor {

  constructor() { }

  firstNumber: string;
  secondNumber: string;
  public readonly tooltipMessageList = {
    idle : 'Copy ref_file_id to clipboard',
    copied: 'Copied'
    } as const;
  tooltipMessage: string = this.tooltipMessageList['idle'];

  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(value: string): void {
    const [first, second] = (value || '').split('-')
    this.firstNumber = first || '';
    this.secondNumber = second || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInput() {
    const value = (this.firstNumber.length || this.secondNumber.length) ? `${this.firstNumber || ''}-${this.secondNumber || ''}` : null;
    this.onChange(value);
    this.onTouch();
  }

  onPaste(event: ClipboardEvent, field: string) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const splitValues = pastedData.split('-');
    if (splitValues.length > 1) {
      event.preventDefault();
      [this.firstNumber, this.secondNumber] = splitValues;
    }
    this.onInput();
  }
}
