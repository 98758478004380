import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { EntitiesModule } from '../entities/entities.module';

import { DashboardLayoutComponent } from './layout/dashboard-layout.component';
import { DashboardComponent } from './layout/dashboard.component';
import { DashboardCalendarComponent } from './layout/dashboard-calendar.component';

import { MonthExplorerComponent } from './components/month-explorer.component';
import { MonthShowerComponent } from './components/month-shower.component';

import { AssignmentListComponent } from './components/assignment-list.component';
import { CreateTaskComponent } from './components/create-task.component';
import { TaskAlertComponent } from './components/task-alert.component';

import { TaskLinkingService } from './services/task-linking.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbModule,
        EntitiesModule,
        RouterModule,
        MomentModule,
        DragulaModule
    ],
    providers: [
        TaskLinkingService
    ],
    declarations: [
        DashboardLayoutComponent,
        DashboardComponent,
        DashboardCalendarComponent,
        AssignmentListComponent,
        CreateTaskComponent,
        TaskAlertComponent,
        MonthExplorerComponent,
        MonthShowerComponent
    ],
    exports: [
        CreateTaskComponent
    ]
})
export class DashboardModule {}
