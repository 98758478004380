<con-loader *ngIf="loading"></con-loader>
<ng-container *ngFor="let inverseRelation of inverseRelations">
    <div *ngIf="!loading && !inverseRelation.required">
        <con-pretty-entity-shower
            *ngIf="!loading && existing && canShow"
            [entity]="relatedObject"
            [entityName]="relatedType"
            [actions]="getActions()"
            (onAction)="handleAction($event)">
        </con-pretty-entity-shower>
        <div *ngIf="!loading && !existing">
            <h5><con-icon-by-name iconName="plug"></con-icon-by-name> Attach</h5>
            <con-entity-search-field
                (onSelect)="addRelation(inverseRelation, $event)"
                [entityName]="relatedEntityDescriptions[inverseRelation.owner].name"
                [fixedSearchParams]="fixedSearchParams[relations.getUniqueNameForRelation(inverseRelation)]"
                [disabled]="!canEdit || relation.disabled">
            </con-entity-search-field>
        </div>
    </div>
    <div *ngIf="!loading && inverseRelation.required && !relation.disabled">
        <con-entity-form *ngIf="canShow"
                     [entityName]="inverseRelation.owner"
                     [entity]="relatedObject"
                     [fixedValues]="fixedValuesForChilds[relations.getUniqueNameForRelation(inverseRelation)]"
                     [canEdit]="canEditRequired"
                     [canDelete]="canEditRequired"
                     (afterSave)="updateRelationFromForm($event)"></con-entity-form>
    </div>
    <div class="alert alert-info" *ngIf="!loading && inverseRelation.required && relation.disabled">
        You can't edit this relation.
    </div>
</ng-container>
