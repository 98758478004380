import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

import { DateConverterService } from '../services/date-converter.service';

import * as moment from 'moment';

@Pipe({name: 'field'})

export class FormatAsFieldPipe implements PipeTransform {
    constructor(private dateConverter: DateConverterService) {}

    isNotNull(value: any) {
        return value !== null && value !== undefined;
    }
    transform(value: any, field: any) {
        if (this.isNotNull(value) && field && field['type']) {
            switch (field['type']) {
                case 'string':
                    return value;
                case 'boolean':
                    return (value === false ? 'False' : 'True');
                case 'date':
                    return this.dateConverter.fromEntityString(value);
                case 'enum':
                    const label = field.values.find(option => option.value === value);
                    if (this.isNotNull(label)) {
                        return label.label;
                    }
                    return 'Unknown value';
            }
        }
        return value;
    }

}
