import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CompanyReportsService} from '../../services/company-reports.service';
import {take} from 'rxjs/operators';
import {MissingReportData, PaginatedMissingReport} from '../../models/PaginatedMissingReport';
import {CustomMissingReport} from '../../models/CustomMissingReport';
import {ProfileService} from '../../../auth/services/profile.service';

@Component({
  selector: 'con-missing-company-reports',
  templateUrl: './missing-company-reports.component.html',
  styleUrls: ['./missing-company-reports.component.scss']
})
export class MissingCompanyReportsComponent {

  missingReports: CustomMissingReport[] = [];
  paginatedMissingReports: PaginatedMissingReport;
  loading = true;
  entityName = 'CompanyReport';
  filterConfiguration = {
    report_type: 'annual',
    report_date_from: null,
    report_date_to: null,
    order_by: 'updated_at',
    page: 1,
    exchange_ids:''
  }
  filterExpanded = true;
  constructor(public reportService: CompanyReportsService, public profile: ProfileService,) {
  }

  loadMissingReports(pageNumber: number) {
    this.loading = true;
    this.filterConfiguration.page = pageNumber;
    const filterKeysWithValue = Object.fromEntries(Object.entries(this.filterConfiguration).filter(([_, v]) => !!v === true));
    this.reportService.getMissingReports(filterKeysWithValue).pipe(take(1)).subscribe((missingReports: PaginatedMissingReport) => {

      this.paginatedMissingReports = missingReports;

      // Process data, here we need to map the data to suite the dynamic form.
      // Expanded and id are also added to control accordion and splicing data respectively.

      this.missingReports = missingReports.data.map((data: MissingReportData) => {
        return {
          hasLatestReport:!!data.latest_report,
          entity: {
            company_id: data.company_id,
            report_type: data.report_type,
            report_date: data.report_date,
            accounting_standard: data.latest_report ?  data.latest_report.accounting_standard : null,
            offset_month: data.latest_report ?  data.latest_report.offset_month : null,
            currency:  data.latest_report ?  data.latest_report.currency : null,
            currency_id:  data.latest_report ?  data.latest_report.currency_id : null
          },
          preFillValues: {
            company_id: data.company_id,
            company: data.company,
            report_type: data.report_type,
            report_date: data.report_date,
            accounting_standard: data.latest_report ?  data.latest_report.accounting_standard : null,
            offset_month: data.latest_report ?  data.latest_report.offset_month : null,
            currency:  data.latest_report ?  data.latest_report.currency : null,
            currency_id:  data.latest_report ?  data.latest_report.currency_id : null
          },
          fixedValues: {company_id: data.company_id},
          expanded: true,
          id: data.id
        };
      });

      this.loading = false;

    }, (error) => {
      this.loading = false;
      console.error(error);
    })
  }

  toggleExpansion(report: any) {
    report.expanded = !report.expanded;
  }

  missingReportSaved(missingReport: CustomMissingReport) {
    const index = this.missingReports.findIndex((report: CustomMissingReport) => report.id === missingReport.id);

    if (index !== -1) {
      this.missingReports.splice(index, 1);
    }

    // reload data if the array data is empty after all the splicing

    if (this.missingReports.length === 0) {
      this.loadMissingReports(1);
    }
  }

  updateFilterConfiguration(filter: any) {
    this.filterConfiguration.report_date_from = filter.fromDate;
    this.filterConfiguration.report_date_to = filter.toDate;
    this.filterConfiguration.report_type = filter.reportType;
    this.filterConfiguration.exchange_ids = filter.exchange_ids;
    this.loadMissingReports(1);
  }
}
