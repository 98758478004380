<div class="card">
    <div class="table-responsive">
        <table class="table">
            <thead  class="thead-light">
                <tr>
                    <th>Job name</th>
                    <th>Status</th>
                    <th>Extra</th>
                    <th>Time</th>
                    <th class="text-right">
                        <con-icon-by-name (click)="refresh()" iconName="refresh"></con-icon-by-name>
                    </th>
                </tr>
            </thead>
            <con-loader *ngIf="loading"></con-loader>
            <tbody *ngIf="!loading">
                <ng-container *ngFor="let log of data?.data">
                    <tr>
                        <td>{{ log.job_name }}</td>
                        <td>{{ log.message }}</td>
                        <td><span class="badge badge-info cursor-pointer" *ngIf="log.extra != '' && log.extra != null" (click)="toggleExtra(log)">View Log <con-icon-by-name iconName="code"></con-icon-by-name></span></td>
                        <td colspan="2">{{ log.created_at }}</td>
                    </tr>
                    <con-object-inspector *ngIf="showExtra(log)" [object]="log.extra"></con-object-inspector>
                </ng-container>
            </tbody>
        </table>
    </div>
    <con-no-data *ngIf="!loading" [count]="data?.total" [message]="noDataMessage"></con-no-data>
    <div class="card-body d-flex justify-content-center" [ngClass]="{'hide-pagination':!data?.total}">
        <ngb-pagination [collectionSize]="data?.total"
                        [(page)]="currentPage"
                        (pageChange)="changePage($event)"
                        [pageSize]="data?.per_page"
                        maxSize="10"
                        [rotate]="true"></ngb-pagination>
    </div>
</div>
