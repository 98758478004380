import { Component, Input } from '@angular/core';

@Component({
    selector: 'con-task-alert',
    templateUrl: './task-alert.component.html'
})

export class TaskAlertComponent {
    @Input() task: any;

    getAssignedToName() {
        if (this.task.assigned_to_type === 'User') {
            return this.task.assigned_to.first_name + ' ' + this.task.assigned_to.last_name;
        }
        return this.task.assigned_to.name;
    }
}
