import { HttpEventType } from '@angular/common/http';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EstimatesService } from 'app/estimates/services/estimates.service';
import { ApiSettings, EstimatesSettings } from 'app/settings.class';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-excel-import-modal',
  templateUrl: './excel-import-modal.component.html',
  styleUrls: ['./excel-import-modal.component.scss']
})
export class ExcelImportModalComponent implements OnDestroy {

  @Input() surveyId: number;
  @Input() quantity: any;

  private componentDestroyed$ = new Subject();

  selectedFile!: File | null;
  isInvalidFile = false;
  isImporting = false;
  isUploading = false;
  uploadProgress: number | null;
  fileDataErrors: any[] = [];
  consensusTypeOptions = EstimatesSettings.CONSENSUS_TYPE_OPTIONS
  importForm = new FormGroup({
    workbook: new FormControl('', [Validators.required]),
    consensus_type: new FormControl('', [Validators.required]),
  });

  constructor(
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private estimateService: EstimatesService,
    private zone: NgZone
  ) { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onFileSelection(files: NgxFileDropEntry[]) {
    if (files.length !== 1 || !files[0].fileEntry) {
      this.isInvalidFile = true;
      return;
    }
    const fileName = files[0].fileEntry.name;
    if (!fileName.endsWith('.xlsx')) {
      this.isInvalidFile = true;
      return;
    }
    this.isInvalidFile = false;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      this.selectedFile = file;
      this.uploadFile(file);
    });
  }

  uploadFile(file: File) {
    this.isUploading = true;
    const payload = new FormData();
    payload.append('file', file);
    this.estimateService.uploadFile(payload).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      this.zone.run(() => { //using ngZone to update teh UI since the change is not detected
        if (response.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * response.loaded / response.total);
        } else if (response.type === HttpEventType.Response) {
          this.importForm.patchValue({ workbook: response.body.file_name });
          this.postUploadActions()
        }
      });
    },
      () => {
        this.zone.run(() => {
          this.toaster.error('Error while uploading file', 'File upload');
          this.selectedFile = null;
          this.postUploadActions()
        });
      }
    );
  }

  postUploadActions() {
    this.isUploading = false;
    this.uploadProgress = null;
    this.importForm.markAsDirty();
    this.importForm.controls['workbook'].markAsTouched();
  }

  public importExcel() {
    if (this.importForm.valid) {
      this.isImporting = true;
      this.estimateService.importExcel(this.surveyId, this.importForm.value).subscribe((res: any) => {
        this.toaster.success('Data imported successfully', 'Estimates manual onboarding');
        this.activeModal.close();
      },
        (error) => {
          if (error?.data?.type === ApiSettings.RESPONSES.VALUE_ERROR) {
            const fields = error.data.fields;
            this.fileDataErrors = Object.values(fields).flatMap(errors => errors);
          } else {
            this.toaster.error('Error while importing data', 'Estimates manual onboarding');
          }
          this.isImporting = false;
        });
    }
  }

  emptyFile() {
    this.selectedFile = null;
    this.importForm.patchValue({ workbook: '' });
    this.fileDataErrors = [];
  }

}
