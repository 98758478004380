import { Component } from '@angular/core';

@Component({
    selector: 'con-dashboard-layout',
    templateUrl: './dashboard-layout.component.html'
})

export class DashboardLayoutComponent {

}
