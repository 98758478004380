<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Deliveries</h3>
            </div>
        </div>
    </div>
    <div class="row mb-3 sticky-header mozenda-menu">
        <div class="col-12">
            <div class="row">
                <div class="col-2 filter-options">
                    <mat-form-field >
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="filterModels.selectedTypeFilter">
                        <mat-option *ngFor="let type of filterTypes" 
                                [value]="type.value" 
                                (click)="selectTypeFilter(type)">
                                    {{type.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
                <div class="col-3 filter-options pull-left">
                    <mat-form-field class="sort">
                    <mat-label>Sort by</mat-label>
                    <mat-select [(ngModel)]="filterModels.selectedSort">
                        <mat-option *ngFor="let sort of sortTypes" 
                            [value]="sort.value" 
                            (click)="selectSort(sort)">
                                {{sort.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="filter-icon pull-right">
                    <span  class="mozenda-sort">
                        <i class="fa fa-sort-asc fa-lg" 
                            [ngClass]="{'active': sortOrder === 'asc'}"
                            (click)="setSortOrder('asc')"></i>
                        <i class="fa fa-sort-desc fa-lg" 
                            [ngClass]="{'active': sortOrder === 'desc'}"
                            (click)="setSortOrder('desc')"></i>
                    </span>
                </div>
                </div>
                <div class="mozenda-search">
                    <mat-form-field class="example-form-field">
                    <mat-label>Search by agent</mat-label>
                    <input matInput type="text" [(ngModel)]="searchText" (ngModelChange)="search()">
                    <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''; search()">
                      <mat-icon>close</mat-icon>
                    </button>
                    </mat-form-field>
                    <div class="filter-icon pull-right" ngbTooltip="Reset filters">
                    <span (click)="resetFilters()">
                        <i class="fa fa-undo reset"></i>
                    </span>
                </div>
                </div>
                
                <div class="ml-auto mr-3 select-and-confirm" *ngIf="deliveries?.data?.length">
                    <button *ngIf="selectedItems.length>0" class="btn btn-success" (click)="confirmSelected()">Confirm & Delete Selected</button>
                    <button *ngIf="allSelected() === false;" class="btn btn-primary" (click)="selectAll()">Select All</button>
                    <button  *ngIf="allSelected() === true;"class="btn btn-primary" (click)="deselectAll()">Deselect All</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading" [showCircularLoder]=showCircularLoader></con-loader>
            <div class="inner-scroll" *ngIf="deliveries?.data && deliveries?.data.length && !showCircularLoader">
                <div >
                    <div>
                        <div *ngFor="let delivery of deliveries.data; let index = index">
                            <div>
                                <div class="card mb-2">
                                    <div class="card-header" (click)="toggleType(delivery)"> 
                                        <div class="d-flex justify-content-start align-items-center">
                                            <input type="checkbox" class="" (click)="selectItem(delivery.id, $event)" id="filledInCheckbox" [checked]="isSelected(delivery.id)">
                                            <div class="mozenda-icon">
                                                <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name> 
                                            </div>
                                            {{ delivery.project}} - Agent Id # {{delivery.agent_id}} 
                                            <div class="card-header-toggle-individual ml-auto">
                                                {{delivery.created_at}}
                                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(delivery)"></con-icon-by-name> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-4 col-6" *ngIf="!delivery.hide">
                                        <div class="card-header"> 
                                            <div class="d-flex justify-content-start align-items-center">
                                                <con-icon-by-name iconName="medium" [mutations]="['fw']"></con-icon-by-name>Inspect
                                                <div class="card-header-toggle-individual ml-auto">
                                                </div>
                                            </div>
                                        </div>
                                        <con-object-inspector [object]="delivery.data">
                                            
                                        </con-object-inspector>

                                        <div class="mt-3 row-button-fields">
                                            <div class="table-row-column table-row-center table-button">
                                                <button type="submit" (click)="onSubmit(delivery, index)" class="btn btn-success">Confirm & Delete                         </button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!deliveries?.total}">
                <ngb-pagination [collectionSize]="deliveries?.total"
                                [page]="deliveries?.current_page"
                                (pageChange)="loadPage($event)"
                                [pageSize]="deliveries?.per_page"
                                maxSize="10"
                                [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
        <con-no-data *ngIf="!loading" [count]="deliveries?.total" [message]="noDataMessage"></con-no-data>
    </div>
</div>