import { Component } from '@angular/core';

@Component({
    selector: 'con-dashboard-calendar',
    templateUrl: './dashboard-calendar.component.html'
})

export class DashboardCalendarComponent {

}
