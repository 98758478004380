import { Query } from '../services/methods.service';

export interface Headers {
  params?: any,
  body?: any,
  options?: any
}
export enum HTTPMethod {
  Get,
  Post,
  Delete
}
export interface HTTPResponse {
  data: any,
  type: Query
}
export type ResponseType = 'arraybuffer' | 'blob' | 'text' | 'json'
