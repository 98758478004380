<div class="row mt-1 mb-3" *ngIf="showSaveButton">
    <div class="col-6">
        {{failedEqualityCheckMessage || createdMessage}}
    </div>
    <div class="col-6 text-right">
        <button class="btn btn-primary" (click)="sendData()">Create</button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <json-editor [options]="editorOptions" [data]="data" (change)="setData($event)"></json-editor>
    </div>
</div>