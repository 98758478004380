
<div class="con-date-picker mb-1" [formGroup]="formDate" *ngIf="editing">
    <div class="con-date-picker-icon">
        <con-icon-by-name iconName="calendar" [mutations]="['fw']"></con-icon-by-name>
    </div>
    <div class="con-date-picker-input">
        <input class="form-control" type="number" formControlName="year" maxlength="4" placeholder="YYYY" min="0" (keyup)="markControlAsDirty()">
    </div>
    <div class="con-date-picker-spacer">
        -
    </div>
    <div class="con-date-picker-input">
        <select class="form-control custom-select" formControlName="month" (click)="markControlAsDirty()">
            <option *ngFor="let month of availableMonths"
                    [value]="month">{{ month }}
            </option>
        </select>
    </div>
    <div class="con-date-picker-spacer">
        -
    </div>
    <div class="con-date-picker-input">
        <input class="form-control" type="number" formControlName="date" maxlength="2" placeholder="DD" min="1" (keyup)="markControlAsDirty()">
    </div>

</div>
<div class="con-date-time-editor mb-2" *ngIf="editing && isToDate() && !reverseDates" >
    <ng-container>
      <div class="btn btn-sm btn-primary" (click)="setDate(3, 'from_date')" placement="top" ngbTooltip="Add 3 months">
        <i class="fa fa-plus"></i> 3M
      </div>

      <div class="btn btn-sm btn-primary" (click)="setDate(6, 'from_date')" placement="top" ngbTooltip="Add 6 months">
          <i class="fa fa-plus"></i> 6M
      </div>
      <div class="btn btn-sm btn-primary" (click)="setDate(9, 'from_date')" placement="top" ngbTooltip="Add 9 months">
          <i class="fa fa-plus"></i> 9M
      </div>
      <div class="btn btn-sm btn-primary" (click)="setDate(12, 'from_date')" placement="top" ngbTooltip="Add 12 months">
          <i class="fa fa-plus"></i> 12M
      </div>
    </ng-container>
</div>
<div class="con-date-time-editor mb-2" *ngIf="editing && isFromDate() && reverseDates" >
  <ng-container>
    <div class="btn btn-sm btn-primary" (click)="setDate(-3, 'to_date')" placement="top" ngbTooltip="Subtract 3 months">
      <i class="fa fa-minus"></i> 3M
    </div>

    <div class="btn btn-sm btn-primary" (click)="setDate(-6, 'to_date')" placement="top" ngbTooltip="Subtract 6 months">
      <i class="fa fa-minus"></i> 6M
    </div>
    <div class="btn btn-sm btn-primary" (click)="setDate(-9, 'to_date')" placement="top" ngbTooltip="Subtract 9 months">
      <i class="fa fa-minus"></i> 9M
    </div>
    <div class="btn btn-sm btn-primary" (click)="setDate(-12, 'to_date')" placement="top" ngbTooltip="Subtract 12 months">
      <i class="fa fa-minus"></i> 12M
    </div>
  </ng-container>
</div>
<div class="con-date-time-editor">
    <div class="btn btn-sm btn-info" (click)="toggleEdit()" placement="top" ngbTooltip="Edit Day">
        <con-icon-by-name iconName="pencil"></con-icon-by-name>
    </div>
    <div class="btn btn-sm btn-warning" (click)="reset()" placement="top" ngbTooltip="Remove Day">
        <con-icon-by-name iconName="remove"></con-icon-by-name>
    </div>
    <span class="badge badge-light badge-time" *ngIf="dateSelected()">{{ control.value }}</span>
    <span class="badge badge-light badge-time" *ngIf="!dateSelected()">No day selected</span>
</div>
