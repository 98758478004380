<div class="table-responsive">
    <table class="table table-hover">
        <thead class="thead-light">
            <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Manager</th>
                <th>Actions</th>
            </tr>
            <tr>
                <td>
                    <button class="btn btn-dark" (click)="refresh()">
                        <con-icon-by-name iconName="refresh" [mutations]="['lg']"></con-icon-by-name>
                    </button>
                </td>
                <td>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <con-icon-by-name iconName="search" [mutations]="['fw']"></con-icon-by-name>
                            </div>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search by Name"
                            [formControl]="institutionName">
                    </div>
                </td>
                <td colspan="2"></td>
            </tr>
        </thead>
        <con-loader *ngIf="fetching"></con-loader>
        <tbody *ngIf="!fetching">
            <tr *ngFor="let institution of result.data">
                <td class="align-middle"><a [routerLink]="['/entity', 'institution', institution.id]">{{ institution.id }}</a></td>
                <td class="align-middle">{{ institution.name }}</td>
                <td class="align-middle">
                    <con-loader *ngIf="isLoadingManger(institution.id)"></con-loader>
                    <ng-container *ngIf="!isLoadingManger(institution.id)">
                        <div class="chip"
                            *ngIf="institutionHasManager(institution) && !isEditingManager(institution.id)">
                            <con-avatar [userId]="institution.manager_id" [size]="32"></con-avatar> {{ institution.manager.first_name }} {{ institution.manager.last_name }}
                            <button class="btn btn-sm btn-link px-0 py-0 text-danger" (click)="removeManger(institution)">
                                <con-icon-by-name iconName="remove" *ngIf="canRemoveManger"></con-icon-by-name>
                            </button>
                            <button class="btn btn-sm btn-link px-0 py-0 text-info" (click)="toggleEditingManager(institution.id)">
                                <con-icon-by-name iconName="pencil"></con-icon-by-name>
                            </button>
                        </div>
                        <con-entity-search-field
                            *ngIf="isEditingManager(institution.id) || !institutionHasManager(institution)"
                            entityName="User"
                            (onSelect)="updateManager(institution, $event)"
                            [showDismiss]="institutionHasManager(institution)"
                            (onDismiss)="toggleEditingManager(institution.id)">
                        </con-entity-search-field>
                    </ng-container>
                    <p class="text-danger" *ngIf="managerHasErrors(institution.id)">
                        <span *ngFor="let message of getManagerErrors(institution.id)">
                            {{ message }}
                        </span>
                        <con-icon-by-name
                            iconName="remove"
                            class="cursor-pointer"
                            (click)="emptyManagerErrors(institution.id)">
                        </con-icon-by-name>
                    </p>
                </td>
                <td class="align-middle">
                    <con-loader *ngIf="isDoingAction(institution.id)"></con-loader>
                    <ng-container *ngIf="!isDoingAction(institution.id)">
                        <button
                            class="btn btn-sm btn-warning"
                            *ngIf="showIgnore"
                            (click)="ignoreInstitution(institution)">
                            <con-icon-by-name iconName="ban"></con-icon-by-name>Ignore
                        </button>
                        <button
                            class="btn btn-sm btn-info"
                            *ngIf="showUnignore"
                            (click)="unignoreInstitution(institution)">
                            <con-icon-by-name iconName="undo"></con-icon-by-name>Unignore
                        </button>
                        <button
                            class="btn btn-sm btn-success"
                            *ngIf="showInclude && institutionHasManager(institution)"
                            (click)="includeInstitution(institution)">
                            <con-icon-by-name iconName="plus"></con-icon-by-name>Include
                        </button>
                        <button
                            class="btn btn-sm btn-danger"
                            *ngIf="showExclude"
                            (click)="excludeInstitution(institution)">
                            <con-icon-by-name iconName="minus"></con-icon-by-name>Exclude
                        </button>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<con-no-data *ngIf="!fetching" [count]="result?.total" [message]="noDataMessage"></con-no-data>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!result?.total}">
    <ngb-pagination [collectionSize]="result?.total"
                    [page]="result?.current_page"
                    (pageChange)="changePage($event)"
                    [pageSize]="result?.per_page"
                    maxSize="10"
                    [rotate]="true">
    </ngb-pagination>
</div>
