
import {switchMap} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs'
import { ShareholderSettings } from '../../settings.class';

import { EntitiesService } from '../../entities/services/entities.service';
import * as moment from 'moment';

@Component({
    selector: 'con-shareholders-company',
    templateUrl: './shareholders-company.component.html'
})

export class ShareholdersCompanyComponent implements OnInit, OnDestroy {
    public company: any;
    public loading: boolean;
    public view: string;
    private routeSubscription = new Subscription;
    private querySubscription = new Subscription;

    constructor(private service: EntitiesService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.querySubscription.unsubscribe();
    }

    ngOnInit() {
        this.querySubscription = this.route.queryParams
                  .subscribe((params: Params) => {
                      if (!params.view && ['missing', 'outdated', 'review'].indexOf(params.view) < 0) {
                          this.router.navigate([], { queryParams:  { view: 'missing' }});
                      } else {
                          this.view = params.view;
                      }
                      this.collapseAll();
                  });
        this.routeSubscription = this.route.params.pipe(
                  switchMap((params: Params) => {
                      this.loading = true;

                      return this.http.get(ShareholderSettings.BASE_URL + '/' + ShareholderSettings.COMPANY_ENDPOINT + '/' + params.id);
                  }))
                  .subscribe(company => {
                      this.company = company;
                      this.formatRevisionData(company);
                  },
                  err => {
                      if (err.status === 404) {
                          this.router.navigate(['shareholders', 'error'], { skipLocationChange: true });
                      }
                      this.loading = false;
                  });
    }

    formatRevisionData(company: any) {

        if (!company.shareholders || !company.shareholders.length) {
            company.missing = true;
            company.shareholders = [{
                owners: [],
                missing: true,
                source: {
                    id: company.id,
                    name: company.name
                },
                source_type: 'Company',
                source_id: company.id
            }]
        }
        if (company.shareholders.length) {
            company.shareholders.forEach(share => {
                if (!share.owners.length) {
                    share.missing = true;
                    company.missing = true;
                }
                if (share.source_type === 'CompanyReport' && share.source && share.source.company) {
                    share.source.company.name = share.source.company.name + ' - ' + moment(share.source.report_date). format('yyyy-MM-DD');
                }
            });
        }

        if (company.outdated) {
            company.shareholders[0].outdated = true;
        }
        this.company = company;
        this.collapseAll();
        if (this.company.outdated) {
            this.router.navigate([], { queryParams: { view: 'outdated' } });
        } else if (this.company.missing) {
            this.router.navigate([], { queryParams: { view: 'missing' } });
        } else {
            this.router.navigate([], { queryParams: { view: 'review' } });
        }
        this.loading = false;
    }
    collapseAll() {
        if (this.company && this.company.shareholders) {
            this.company.shareholders.forEach(shareholder => {
                if (this.view === 'review') {
                    shareholder.hide = true;
                } else {
                    shareholder.hide = false;
                }
            });
            this.company.shareholders[0].hide = false;
        }
    }

    updateCompany(company: any) {
        this.company = company;
    }
}
