<nav class="nav flex-column">
  <a class="nav-link"
     [routerLink]="['report']"
     routerLinkActive="active">
      Report
  </a>
  <a class="nav-link"
     [routerLink]="['check']"
     routerLinkActive="active">
    Check
  </a>
  <a class="nav-link"
     [routerLink]="['report-events']"
     routerLinkActive="active">
    Report Events
  </a>
  <a class="nav-link"
     [routerLink]="['status']"
     routerLinkActive="active">
      Status
  </a>
</nav>
