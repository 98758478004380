
import {switchMap} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Observable } from 'rxjs';

import { EntitiesService, EntityDescription } from '../services/entities.service';
import { ProfileService } from '../../auth/services/profile.service';
import { CapitalizePipe } from '../../shared/pipes/capitalize.pipe';

import { camelCase, snakeCase, upperCaseFirst } from 'change-case';



@Component({
    selector: 'con-entity-start',
    templateUrl: './entity-start.component.html',
    providers: [CapitalizePipe]
})

export class EntityStartComponent implements OnInit {
    public entityDescription: EntityDescription;
    public entityName: string;
    public loading: boolean;
    constructor(
        private service: EntitiesService,
        public profile: ProfileService,
        private route: ActivatedRoute,
        private router: Router,
        private capitalizePipe: CapitalizePipe
    ) {

    }

    ngOnInit() {
        this.loading = true;
        this.route.params.pipe(
            switchMap((params: Params) => {
                this.entityName = upperCaseFirst(camelCase(params.name));
                return this.service.getEntityDescriptionByEntityName(upperCaseFirst(camelCase(params.name)))
            }))
            .subscribe(
                entityDescription => {
                    this.entityDescription = entityDescription;
                    this.loading = false;
                },
                err => this.router.navigate(['404'], { skipLocationChange: true })
            );
    }

    redirectToEntity(entity) {
       this.router.navigate(['entity', snakeCase(this.entityName), entity.id]);
    }
    redirectToReportFile(savedData){
      if(savedData.context === 'ADD_REPORT_FILE'){
        this.router.navigate(['entity', snakeCase(this.entityName), savedData.data.id]);
      }
    }
}
