<div class="card">
    <div class="card-header">
        Confirm events
        <div class="btn-group btn-group-sm" role="group">
            <button class="btn" ngbTooltip="Refresh" container="body" (click)="refresh()">
                <con-icon-by-name iconName="refresh" [mutations]="['fw']">
                </con-icon-by-name>
            </button>
        </div>
    </div>
    <div class="card-body">
        <con-loader *ngIf="loading"></con-loader>
        <div *ngIf="!loading && error">
            <p class="lead">
                Oops, something went wrong!
            </p>
        </div>
        <div *ngIf="!loading && !error">
            <div class="card" *ngFor="let e of confirmEvents">
                <div class="card-header">
                    {{ e.calendar_event_type.name }}
                </div>
                <div class="card-body">
                    <br>
                    <strong>From:</strong> <con-date-shower [date]="e.from_date"></con-date-shower><br>
                    <strong>To:</strong> <con-date-shower [date]="e.to_date"></con-date-shower><br>
                    <strong>Confirmed:</strong> {{ e.date_confirmed }}<br>
                    <strong>Revised:</strong> {{ e.revised_at }}<br>
                    <p>{{ e.comment }}</p>
                    <br>
                    <button class="btn btn-primary" (click)="toggleEdit(e.id)">Edit</button>
                    <ng-container *ngIf="profile.can(profile.getPermissionName('CalendarEvent', 'update'))">
                        <button class="btn btn-success" *ngIf="!loaders[e.id]" (click)="confirmEvent(e.id)">Confirm</button>
                        <con-loader *ngIf="loaders[e.id]"></con-loader>
                    </ng-container>
                    <br>
                    <con-custom-entity-form-content *ngIf="editing[e.id]" entityName="CalendarEvent" [entity]="e" [fixedValues]="{ calendar_event_type_id: e.calendar_event_type_id, owner_id: company.id, owner_type: ownerType, revised_at: now }"
                    [hasFixedCalendarValues]="true" [handleFullDayEvent]="true" [enableRecurringEvent]="true" (afterSave)="removeFromList($event.id)" (afterDelete)="removeFromList($event.id)"></con-custom-entity-form-content>
                </div>
            </div>
            <con-no-data [count]="confirmEvents.length" [message]="'No events to confirm!'"></con-no-data>
        </div>
    </div>
</div>