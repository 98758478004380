import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../auth/services/profile.service';
import { StaticSettings } from '../../settings.class';
import {CountService} from '../services/count.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'con-static-start',
    templateUrl: './static-start.component.html'
})

export class StaticStartComponent{
    public tierId: number = StaticSettings.TIER_ID;

    constructor(private router: Router,
                public profile: ProfileService) {}

    public navigateToCompany(company: any) {
        this.router.navigate(['static', company.id]);
    }
}
