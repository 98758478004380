<div class="container-fluid mb-3">
    <div class="row kpi">
        <div class="col-12">
            <div class="card mb-2">
                <div class="card-header" (click)="toggleType(kpi); getKpiData(kpi)">
                    <div class="d-flex justify-content-start align-items-center">
                        <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1 cursor-pointer" (click)="reportservice.goToEntity($event, kpiType === 'PeriodKpi'? 'period_kpi':'snapshot_kpi', kpi?.id)"></con-icon-by-name>
                        {{getDisplayName(kpi)}}
                        <div class="card-header-toggle-individual ml-auto">
                            <con-icon-by-name iconName="angle-right"
                                [mutations]="getMutations(kpi)"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!kpi.hide">
                  <div class="row m-0">
                      <div *ngIf="kpiType === 'PeriodKpi' " class="p-4 col-6">
                          <con-entity-form-content
                              [entity]="kpi"
                              entityName="PeriodKpi"
                              [fixedValues]="{ company_report_id: entity?.id }"
                              [showWhenChanged]="false"
                              [canDelete]="false"
                              (afterSave)="kpiUpdated($event)"
                              toastMessage="Period Kpi updated successfully!"
                              [canEdit]="false">
                          </con-entity-form-content>
                      </div>
                      <div *ngIf="kpiType === 'SnapshotKpi' " class="p-4 col-6">
                          <con-entity-form-content [entity]="kpi" entityName="SnapshotKpi"
                              [fixedValues]="{ company_report_id: entity?.id }" [showWhenChanged]="false"
                              [canDelete]="false" (afterSave)="kpiUpdated($event)"
                              toastMessage="Snapshot Kpi updated successfully!"
                              [canEdit]="false">
                              <!-- [canEdit]="!entity?.locked"> -->
                          </con-entity-form-content>
                      </div>
                      <div class="col-6" *ngIf="!kpi?.hide && !entity?.locked && !entitiesService.entityLockedByMl(entity)">
                        <div class="mt-15">
                          <con-common-comment (commentUpdated)="kpiCommentUpdated($event)" [commentInfo]="kpi?.comment" [id]="kpi?.id" [updateUrl]="kpiType === 'PeriodKpi' ? 'period_kpi': 'snapshot_kpi'"></con-common-comment>
                        </div>
                      </div>
                    </div>
                    <con-loader *ngIf="kpi.loading"></con-loader>
                    <div class="card-body mb-2" *ngIf="kpi && kpi.kpiData && !kpi.loading">
                        <div class="card-header">
                                            {{ getKpiType() }}
                        </div>
                    <div class="card-body" >
                      <ul class="prev-val-ui">
                        <li>
                          <div  class="pull-right editor-year" *ngIf="kpi?.kpiData?.showPrevious">
                            <select [formControl]="dateRangePicker" class="form-control custom-select editor-select" (ngModelChange)="_check()">
                              <option *ngFor="let item of kpi.kpiData?.past_statements" [value]="item.id" >
                                <!-- check if condition for kpi type -->
                                <ng-container  *ngIf="kpiType==='PeriodKpi'">{{item?.from_date  | date:'yyyy-MM-dd'}} - {{item?.to_date  | date:'yyyy-MM-dd'}}</ng-container>
                                <ng-container  *ngIf="kpiType==='SnapshotKpi'">{{item?.snapshot_date  | date:'yyyy-MM-dd'}}</ng-container>
                              </option>
                            </select>
                            <div class="pull-right w-60" *ngIf="loadingData"><con-loader [showCircularLoder]="true"></con-loader></div>
                          </div>
                          <div  class="pull-right editor-year" *ngIf="kpi?.kpiData?.showPrevious">
                            <!-- check if condition for kpi type -->
                            <span class="previous-value-header"  *ngIf="kpiType==='PeriodKpi'" >{{kpi.kpiData?.from_date  | date:'yyyy-MM-dd'}} - {{kpi.kpiData?.to_date  | date:'yyyy-MM-dd'}}</span>
                            <span class="previous-value-header"  *ngIf="kpiType==='SnapshotKpi'" >{{kpi.kpiData?.snapshot_date  | date:'yyyy-MM-dd'}} </span>
                          </div>
                        </li>
                      </ul>
                      <div>
                        <con-custom-search-select (onSelect)="appendPreviewUrl($event, kpi.kpiData)" [id]="kpi?.company_report_id" ></con-custom-search-select>
                        <con-ml-directory-preview [displayMLPreviewUrl]=" kpi.kpiData?.previewUrl" (closePreviewFile)="setPreviewMLReportUrlEmpty(kpi.kpiData, $event)"></con-ml-directory-preview>
                      </div>
                    <form #kpiFigureForm="ngForm" *ngIf="kpi && kpi.kpiData" conLoadDiv (onDivLoad)="setInitialData()">

                      <div *ngFor="let module of kpi.kpiData?.modules; let arrayIndex=index">
                        <div class="tree-node" [ngClass]="{'grad': enable_row_highlight(module?.is_highlighted), 'active-tree-node': module.focus}">
                          <span class="padding-name">{{ module.tree_name ? module.tree_name : module.name}}</span>
                          <span  class="inline-block custom-width-dividend" *ngIf="module.is_dividend_currency_available ">
                                  <span *ngIf="!module.showCurrency"><strong *ngIf="module?.currency?.code">{{module?.currency?.code}} ({{module?.currency?.symbol}})</strong></span>
                                   <a class="btn-link cursor-pointer"  (click)="module.showCurrency = !module.showCurrency" *ngIf="!module.showCurrency && !kpi.locked && !isMLLocked()" >Update Currency</a>
                                   <span *ngIf="!module.showCurrency && !kpi.locked && !isMLLocked() && module?.currency?.code">|</span>
                                   <a class="btn-link cursor-pointer"   *ngIf="!module.showCurrency && !kpi.locked && !isMLLocked() && module?.currency?.code" (click)="updateCurrency(null,module, true)"> Reset</a>
                                   <span class="currency-class"  *ngIf="module.showCurrency">
                                    <con-entity-search-field entityName="Currency" (onSelect)="updateCurrency($event, module, false)"></con-entity-search-field>
                                   </span>
                                   <span class="inline-block">
                                    <button *ngIf="module.showCurrency" (click)="module.showCurrency = !module.showCurrency" class="btn btn-primary inline-block" >
                                      <con-icon-by-name iconName="close"></con-icon-by-name>
                                    </button>
                                   </span>
                                   <span class="inline-block custom-loader" *ngIf="module.showLoader">
                                     <con-loader [showCircularLoder]="true" class="loader-block" ></con-loader>
                                   </span>
                                </span>
                          <div *ngIf="kpi.kpiData.showPrevious" class="pull-right fixed-width-225">
                                   <span class="previous-value">
                                     <span class="kpi-prev-value-block">
                                       &nbsp;
                                       <con-icon-by-name class="cursor-pointer" *ngIf="module?.prev_display_value && module?.prev_value_source" [ngbTooltip]="formatTooltip(module?.prev_value_source)" iconName="info"></con-icon-by-name>
                                     </span>
                                     {{module?.prev_display_value | stringify | imask: mask}}
                                  </span>
                          </div>
                          <span  class="kpi-value-block">
                                    &nbsp;
                                    <con-icon-by-name *ngIf="module?.value_source && module.display_value" class="cursor-pointer" [ngbTooltip]="formatTooltip(module?.value_source)"  iconName="info"></con-icon-by-name>
                                  </span>
                          <div class="float-right"
                               ngbTooltip="{{ module.calculated && !kpi.locked ? 'Editing not allowed for a calculated value': ''}}">

                            <input name={{arrayIndex}}
                                   class="editor-input bg-transparent"
                                   [ngClass]="{'value-error': (valueCtrl.errors?.has_limit_error ), 'locked': kpi.locked || module.calculated || isMLLocked()}"
                                   [placeholder]="module.calculated ? '':'Enter a value here'"
                                   [(ngModel)]="module.display_value" (change)="valueChanged(module,valueCtrl)"
                                   [imask]="mask" [unmask]="true" (focus)="module.focus=true"
                                   (focusout)="module.focus=false"
                                   [disabled]="(module.calculated || isMLLocked())? true: null" #valueCtrl="ngModel" />
                          </div>
                          <span class="float-right">{{module.quantity + ' (' + module.unit + ')'}}</span>
                        </div>
                      </div>
                        <div class="btnRow" *ngIf="!processingTree">
                            <button type="submit" (click)="saveKpiFigure(kpiFigureForm)" [disabled]="disableButtons"
                                class="btn btn-success">Save</button>
                            <button (click)="onReset()" [disabled]="disableButtons"
                                class="btn btn-warning">Revert</button>
                          <button type="reset" class="btn btn-warning" (click)="clearFormData()">Clear</button>
                        </div>
                        <con-loader *ngIf="processingTree"></con-loader>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
