import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UtilService } from '../../doc-process-common/services/util.service';

@Component({
  selector: 'con-dp-company-events-table',
  template: `
    <div class="card my-2">
      <div class="card-header">Recent and future events</div>

      <ng-container *ngIf="calendarEventsTableData">
        <con-material-table
          [data]="calendarEventsTableData"
          [columnsToHide]="['comments']"
          [isClickable]="true"
          [isExpandable]="true"
          [isSelectable]="true"
          [makeLinksPrimaryColor]="true"
          [initiallySelected]="calendarEventIds"
          (onClick)="onEventIdClick($event)"
          (onSelectionChange)="handleSelectionChange($event)"
        >
        </con-material-table>
      </ng-container>
    </div>
  `,
  styleUrls: ['styles/mat-table-clear.scss'],
})
export class CompanyEventsTableComponent {
  @Input() companyId: number;
  @Input() companyEvents: any;
  @Input() calendarEventIds: Array<number>;

  @Output() onSelectionChange: EventEmitter<Array<any>> = new EventEmitter();

  get calendarEventsTableData() {
    if (!this.companyEvents.calendar_events) return [];

    return this.companyEvents.calendar_events.map((event) => {
      const newEvent = UtilService.copyObject(event);
      newEvent['meta_flag_list'] = event.comments.reduce((a, b) => {
        a.push(b.language);
        return a;
      }, []);

      newEvent['expandData'] = [];
      newEvent?.comments?.forEach((commentObj) => {
        newEvent['expandData'].push({
          comment: commentObj.comment,
          meta_link: '/entity/calendar_event_comment/' + commentObj.id.toString(),
          meta_connector_language_abbreviation: commentObj.language,
        });
      });

      const temp = newEvent['updated_at'];
      delete newEvent['updated_at'];
      newEvent['updated_at'] = temp;

      delete newEvent['comments'];
      return newEvent;
    });
  }


  onEventIdClick($event) {
    window.open('/entity/calendar_event/' + $event.event_id.toString(), '_blank');
  }

  handleSelectionChange($event: Array<any>) {
    this.onSelectionChange.emit($event);
  }
}
