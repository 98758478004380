<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title pb-3 pt-3">
                <h3 class="mb-0">Update share count</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <ng-container *ngIf="!loading">
                <con-share-count-editor *ngFor="let comp of updates?.data" [company]="{name : comp.name, manager : comp.manager}" [preFillValues]="{valid_from: comp.valid_from}" [fixedValues]="{company_id : comp.id }" header='Update share count' displayEntityList="true"
                    (afterSave)="updateInList($event)">
                </con-share-count-editor>
                <con-no-data [count]="updates?.total" [message]="noDataMessage"></con-no-data>
            </ng-container>
            <div class="d-flex justify-content-center" *ngIf="!loading && updates?.data?.length" [ngClass]="{'hide-pagination':!updates?.total}">
                <ngb-pagination [collectionSize]="updates?.total" [page]="updates?.current_page" (pageChange)="loadPage($event)" [pageSize]="updates?.per_page" maxSize="10" [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>