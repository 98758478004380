import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Task, IndexComponent, TaskDetail } from 'app/task-manager/typings/Typings';


interface SelectedSearchResult {
    feednu: number
    feedcode: string
    feedname: string
    ticker: string
    name: string
    isin: string
    search_rank: number
}


@Component({
  selector: 'indicies-view',
  templateUrl: './indicies-view.component.html',
  styleUrls: ['./indicies-view.component.scss'],

})

export class IndiciesViewComponent implements OnInit, OnDestroy {

    public task: Task;
    public loading = true;
    public confCopy: any;
    public errorMessage: string;
    public actionSucceeded = false;
    public feedbackFromApiResponse: string;
    public showFeedbackFromApiAlert = false;
    public componentTableHeader: string[] = [];
    public componendTableBody: IndexComponent[] = [];
    public failedDataTypeMsg = '';
    public futureComponendTableHeader: IndexComponent[] = [];
    public futureComponendTableBody: IndexComponent[] = [];
    public symbolTableHeader: string[] = [];
    public symbolTableBody: IndexComponent[] = [];
    public componentTableIsEditable = false;
    public closeResult: string;
    public newIndexComponent: IndexComponent;
    public futureComponentTableIsEditable = false;
    public showAlert = false;
    public emptySearchMessage: string;
    public isValidDateTo = true;
    public isValidDateFrom = true;

    public searchCondition = {
        feednu: null,
        ticker: null,
        name: null,
        isin: null
    }

    @Input() taskDetail: TaskDetail;
    @Input() checkPassed: boolean;
    @Input() checkLoading: boolean;
    @Input() deliveryView = false;

    @Output() actionHandler = new EventEmitter();
    @Output() loadingAnimationHandler = new EventEmitter();

    @ViewChild('responseElm') responseElm: ElementRef;

    constructor( public taskManagerService: TaskManagerService, private subscriptionService: SubscriptionService, private utilService: UtilService, private modalService: NgbModal) {}

    ngOnInit(): void {
        this.initNewIndexComponent();
        if (this.taskDetail) {
            this.taskManagerService.getIndiciesTaskDetails(this.taskDetail.task_id);
        }

        this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: any) => {
            this.handleResponseStateMessage(response.data)
            switch (response.type) {
                case 'getIndiciesTaskDetails': {
                    this.task = response.data;
                    this.task.index_comp.current.map(currentIndexComponent => {
                        return [Object.keys(currentIndexComponent), Object.keys(currentIndexComponent).map(function(key) {return currentIndexComponent[key]})]
                    }).forEach(componentTableData => {
                        this.componentTableHeader = componentTableData[0];
                    });
                    this.componendTableBody = this.task.index_comp.current;
                    this.futureComponendTableBody = this.task.index_comp.future;
                } break;
                case 'symbolSearch': {
                    this.emptySearchMessage = undefined;
                    if (response.data.result.length > 0) {
                        response.data.result.map(currentIndexComponent => {
                            return [Object.keys(currentIndexComponent), Object.keys(currentIndexComponent).map(function(key) {return currentIndexComponent[key]})]
                        }).forEach(componentTableData => {
                            this.symbolTableHeader = componentTableData[0];
                        });
                        this.symbolTableBody = response.data.result;
                    } else {
                        this.emptySearchMessage = 'No search results'
                    }

                } break;
                case 'deleteIndexComponent': {
                    this.task = response.data;
                    this.refreshTask();
                    this.modalService.dismissAll();
                    this.initNewIndexComponent();
                } break;
                case 'updateIndexComponent': {
                    this.task = response.data;
                    this.refreshTask();
                    this.modalService.dismissAll();
                    this.initNewIndexComponent();
                } break;
            }
        });
        this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: any) => {
            this.actionHandler.emit('failed');
            this.errorMessage = err.data;
            this.feedbackFromApiResponse = err.data.Traceback
        })
    }

    ngOnDestroy(): void {
        this.subscriptionService.apiErrorSub.unsubscribe();
        this.subscriptionService.apiResponseSub.unsubscribe();
    }

    requestAction(action: string, futureComponent?: boolean) {
        this.startAnimation();
        this.actionSucceeded = false;
        this.feedbackFromApiResponse = '';
        this.showFeedbackFromApiAlert = false;
        switch (action) {
            case 'symbolSearch': {
                this.symbolTableBody = [];
                this.symbolTableHeader = [];
                const searchConditionJson = {
                    query: this.searchCondition
                }
                this.taskManagerService.symbolSearch(searchConditionJson);
            } break;
            case 'deleteIndexComponent': return this.taskManagerService.deleteIndexComponent(this.task.task_id, this.newIndexComponent);
            case 'updateIndexComponent': return this.taskManagerService.updateIndexComponent(this.task.task_id, this.newIndexComponent);
        }
    }
    editComponent(indexComponent: IndexComponent) {
        if (indexComponent) {
            const editedIndexComponent = indexComponent;
            delete editedIndexComponent['icon'];
            this.newIndexComponent = editedIndexComponent;
        }
    }
    deleteComponent(indexComponent: IndexComponent) {
        if (indexComponent) {
            const indexComponentToDelete = indexComponent;
            delete indexComponentToDelete['icon'];
            this.newIndexComponent = indexComponentToDelete;
        }
    }
    enableCurrentComponentEdit() {
        this.futureComponentTableIsEditable = false;
        this.componentTableIsEditable = !this.componentTableIsEditable;
    }
    enableFutureComponentEdit() {
        this.componentTableIsEditable = false;
        this.futureComponentTableIsEditable = !this.futureComponentTableIsEditable;
    }
    private handleResponseStateMessage(response: any) {
        const STATE = this.utilService.isJsonString(response) ? JSON.parse(response).State : response.State
        if (STATE) {
            this.showAlert = false;
            this.showFeedbackFromApiAlert = true;
        } else { return }
        switch (STATE) {
            case 'Error': {
                this.stopAnimation('failed');
                const EXCEPTION_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Exception : response.Exception;
                this.actionSucceeded = false;
                this.feedbackFromApiResponse = EXCEPTION_MESSAGE;
                this.showAlert = true;
                return false
            };
            case 'Success': {
                this.stopAnimation('approved');
                const RESULT_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Result : response.Result;
                this.actionSucceeded = true;
                this.feedbackFromApiResponse = RESULT_MESSAGE;
                return true;
            };
        }
    }
    open(content: any) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            this.initNewIndexComponent();
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            this.initNewIndexComponent();
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    initNewIndexComponent() {
        this.newIndexComponent = {
            comp_id: null,
            feednu: null,
            fullname: '',
            ticker: '',
            comp_weight: '',
            valid_from: '',
            valid_to: ''
        };
        this.searchCondition = {
            feednu: null,
            ticker: null,
            name: null,
            isin: null
        }
        this.symbolTableBody = null;
        this.symbolTableHeader = null;
        this.emptySearchMessage = undefined;
    }

    refreshTask() {
        this.taskManagerService.getIndiciesTaskDetails(this.taskDetail.task_id);
    }

    searchSymbol() {
        this.requestAction('symbolSearch');
    }

    addConfirmation() {
        const confirmAdd = this.utilService.getConfirmation(`Are you sure that you want to submit?`);
        if (confirmAdd) {
            const editedIndexComponent = this.newIndexComponent;
            if (editedIndexComponent['icon']) {
                delete editedIndexComponent['icon'];
            }
            this.newIndexComponent = editedIndexComponent;
            this.requestAction('updateIndexComponent')
        }
    }

    deleteConfirmation() {
        const confirmDelete = this.utilService.getConfirmation('Are you sure that you want to delete this component?');
        if (confirmDelete) {
            const editedIndexComponent = this.newIndexComponent;
            if (editedIndexComponent['icon']) {
                delete editedIndexComponent['icon'];
            }
            this.newIndexComponent = editedIndexComponent;
            this.requestAction('deleteIndexComponent')
        }
    }

    parseSelectedObject(selectedObject: SelectedSearchResult) {
        const today = new Date().toISOString().substring(0, 10);
        this.newIndexComponent.feednu = selectedObject.feednu;
        this.newIndexComponent.fullname = selectedObject.name;
        this.newIndexComponent.ticker = selectedObject.ticker;
        this.newIndexComponent.valid_from = today;
    }
    validateDateTo(ev) {
        if (!ev) {
            this.isValidDateTo = true;
            return;
        }
        this.isValidDateTo =  this.utilService.isValidDate(ev);
    }
    validateDateFrom(ev) {
        if (!ev) {
            this.isValidDateFrom = true;
            return;
        }
        this.isValidDateFrom =  this.utilService.isValidDate(ev);
    }

    private startAnimation() {
        this.loadingAnimationHandler.emit('start');
    }

    private stopAnimation(state: string) {
        this.actionHandler.emit(state);
        this.loadingAnimationHandler.emit('stop');
    }

}
