<con-loader *ngIf="fetchingData"></con-loader>
<div class="page-title d-flex justify-content-between" *ngIf="!fetchingData">
  <div class="mt-3 pull-left">
    <div class="d-flex ">
      <h3 class="mb-0 mt-1">Task validation</h3>
      <con-server-chooser></con-server-chooser>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <div>{{closeResult}}</div>
  </div>
  <div class="mb-3 mr-3 mt-3 pull-right">
    <button *ngIf="isKiidSubtype()" class="btn btn-primary mr-3" (click)="openModal(autoIgnore);"
            ngbPopover="Set values for each document to be ignored if there are confirmed values for same field, instrument and date in other documents."
            container="body"
            [openDelay]="300"
            [closeDelay]="500"
            triggers="mouseenter:mouseleave"
    >Auto ignore</button>
    <button *ngIf="isKiidSubtype()" class="btn btn-primary mr-3" (click)="openModal(clearConfirmationTasks);"
            ngbPopover="Clears confirmation for selected documents and fields."
            container="body"
            [openDelay]="300"
            [closeDelay]="500"
            triggers="mouseenter:mouseleave"
    >Clear confirmation tasks</button>
    <button *ngIf="isKiidSubtype()" class="btn btn-primary mr-3" (click)="openModal(restoreInstanceData);"
            ngbPopover="Converts the data for selected fields and documents to the original state."
            container="body"
            [openDelay]="300"
            [closeDelay]="500"
            triggers="mouseenter:mouseleave"
    >Restore instance data</button>
    <button class="btn btn-danger mr-3" (click)="validationInstances.current_instances.length ? openModal(removeInstanceFromTask) : toastr.warning('You should select the instance(s) you want to remove from the task.', 'No Instance Selected')">Move out selected instances</button>
    <button class="btn btn-danger mr-5" (click)="validationInstances.current_instances.length ? openModal(rejectInstances) : toastr.warning('You should select the instance(s) you want to reject.', 'No Instance Selected')">Reject selected instances</button>
    <button class="btn btn-primary mr-3" (click)="startValidation()">Start validation</button>
    <button class="btn btn-warning" (click)="submitData()">Submit data</button>
  </div>
</div>
<div class="row project-data">
  <div class="col-3" *ngIf="isDataFieldsWidgetVisible()">
    <div class="card">
      <div *ngIf="instanceDataTableData" class="card-header">Instance data fields</div>
        <div class="col-12 p-0">
          <div *ngIf="instanceDataTableData.length" class="col-12 p-0 pull-left">
            <con-material-table
              #instanceDataTable
              [data]="instanceDataTableData"
              [columnsToHide]="['type', 'selected']"
              [isSelectable]="true"
              (onSelectedAll)="onSelectedAllFields($event, fieldType.InstanceData)"
              (onSelected)="onSelectedFields($event)">
            </con-material-table>
        </div>
        <div *ngIf="shareClassesTableData" class="col-12 p-0 mt-1 pull-right">
          <h6 class="pl-3 mb-0 mt-1">
            Share Classes
          </h6>
          <div *ngIf="shareClassesTableData">
            <con-material-table
              #shareClassesTable
              [data]="shareClassesTableData"
              [columnsToHide]="['type', 'selected']"
              [isSelectable]="true"
              (onSelectedAll)="onSelectedAllFields($event, fieldType.ShareClasses)"
              (onSelected)="onSelectedFields($event)"
            ></con-material-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3" *ngIf="isDefaultPeriodWidgetVisible()">
    <div class="card">
      <div class="card-header">Default taxonomy period</div>
      <div class="py-2 px-1">
        <div *ngFor="let period of TaxonomyContextPeriodKeysArray" class="p-1">
          <input
            type="radio"
            name = "defaultPeriod"
            [value]="period"
            [(ngModel)]="defaultRadioButtonChoice">
          <span class="radioClass">{{period}}</span>
        </div>
      </div>
    </div>

    <!-- document style toggle -->
    <div class="card mt-3">
      <div class="card-header">Display Document With Styling</div>
      <div class="d-flex card flex-row align-items-center py-2 px-1">
        <con-icon-by-name class="" iconName="adjust"></con-icon-by-name>
        <label class="switch my-0">
          <input
            type="checkbox"
            [ngModel]="zoomService.stylingEnabled | async"
            (ngModelChange)="zoomService.stylingEnabled.next($event)"/>
          <span class="slider round" style="transform: scale(0.75);"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-3" *ngIf="isDefaultFundamentalsTaxonomyTypeWidgetVisible()">
    <div class="card">
      <div class="card-header">Default taxonomy type</div>
      <div class="py-2 px-1">
        <div *ngFor="let taxonomyType of this.utilService.ToArray(TaxonomyTypeEnum); let i = index" class="p-1">
          <input
            type="radio"
            name = "defaultPeriod"
            [value]="i"
            [(ngModel)]="defaultTaxonomyTypeChoice">
          <span class="radioClass">{{taxonomyType}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="{{isFullSizeInstanceTable() ? 'col-12' : 'col-9'}} mb-3" *ngIf="!fetchingData">
    <div class="card">
      <div class="card-header">Instances</div>
        <con-entity-list
          entityName="projectInstanceTable"
          [isSelectable]=true
          [external]=true
          [timestamps]=false
          [enableHyperLink]=false
          [externalData]="tableData$"
          [externalMetaData]="externalMetaData"
          [preserveSelection]="preserveSelection"
          [refreshExternalData]="refreshExternalData"
          (onChecked)="setSelectedIds($event); selectedInstance($event)"
          (onCheckedAll)="setAllInstanceIds($event)"
          (loadExternalData)="loadData($event)">
        </con-entity-list>
    </div>
  </div>
</div>

<ng-template #autoIgnore let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>You want to auto ignore
      {{filteredFields.fields.length ? 'following fields: ' : 'all fields'}}
    </h4>
    <ul *ngFor="let field of filteredFields.fields">
      <li>
        {{field}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-3" (click)="modal.dismiss('autoIgnore')">Proceed</button>
  </div>
</ng-template>
<ng-template #clearConfirmationTasks let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>You to clear confirmation on
      {{filteredFields.fields.length ? 'following fields: ' : 'all fields'}}
    </h4>
    <ul *ngFor="let field of filteredFields.fields">
      <li>
        {{field}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-3" (click)="modal.dismiss('clearConfirmationTasks')">Proceed</button>
  </div>
</ng-template>

<ng-template #restoreInstanceData let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">Warning </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4> You want to restore instance data on
      {{filteredFields.fields.length ? 'following fields: ' : 'all fields'}}
    </h4>
    <ul *ngFor="let field of filteredFields.fields">
      <li>
        {{field}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-3" (click)="modal.dismiss('restoreInstanceData')">Proceed</button>
  </div>
</ng-template>

<ng-template #removeInstanceFromTask let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">Warning </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4> You want to remove selected instances from task and move it back to the global instances list?
    </h4>
    <ul *ngFor="let instance of validationInstances.current_instances">
      <li>
        {{instance}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-3" (click)="modal.dismiss('removeInstanceFromTask')">Proceed</button>
  </div>
</ng-template>

<ng-template #rejectInstances let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">Warning </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4> Do you want to reject selected instances?
    </h4>
    <ul *ngFor="let instance of validationInstances.current_instances">
      <li>
        {{instance}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-3" (click)="modal.dismiss('rejectInstances')">Proceed</button>
  </div>
</ng-template>
