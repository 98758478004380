<div class="container-fluid mb-3">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="calendar"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Report Events</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header">
          Go to company report events
        </div>
        <div class="card-body">
          <con-entity-search-in-relation
            parentEntityName="CompanyTier"
            [parentId]="companyTierId"
            relationName="companies"
            [disabled]="!profile.can(profile.getPermissionName('CompanyTier', 'show', 'companies'))"
            (onSelect)="navigateToCompany($event)"
          ></con-entity-search-in-relation>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <con-report-events-editor
        *ngFor="let comp of reportEventData?.data"
        ownerType="Company"
        [company]="comp"
        (savedCompany)="updateCompanyMissingData($event)"
        [isListing]="true"
      ></con-report-events-editor>
      <con-no-data [count]="reportEventData?.total" [message]="noDataMessage"></con-no-data>
      <div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!reportEventData?.total}">
        <ngb-pagination [collectionSize]="reportEventData?.total"
                        [page]="reportEventData?.current_page"
                        (pageChange)="loadPage($event)"
                        [pageSize]="reportEventData?.per_page"
                        maxSize="10"
                        [rotate]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
