import { Component } from '@angular/core';

@Component({
    selector: 'con-not-authorized',
    templateUrl: './not-authorized.component.html'
})

export class NotAuthorizedComponent {

}
