export const enum Query {
  GetProjects = 'getProjects',
  GetProjectInstances = 'getProjectInstances',
  GetProjectDetails = 'getProjectDetails',
  CreateValidationTask = 'createValidationTask',
  RemoveInstancesFromTask = 'removeInstancesFromTask',
  RejectInstances = 'rejectInstances',

  ActivateTask = 'activateTask',
  GetTaskInstances = 'getTaskInstances',
  GetTaskOverview = 'getTaskFieldsOverview',
  GetTaskInstancesOverview = 'getTaskInstancesOverview',
  SubmitValidationTask = 'submitValidationTask',
  ClearConfirmations = 'clearConfirmations',
  RestoreInstanceData = 'restoreInstanceData',
  AutoConfirm = 'autoConfirm',

  GetTaskInstancesOld = 'getTaskInstancesOld',
  GetHtmlFile = 'getHtmlFile',
  GetInstanceData = 'getInstanceData',
  GetCalendarEvents = 'getCalendarEvents',

  UpdateInstanceData = 'updateInstanceData',
  UpdateJsonAnnotations = 'updateJsonAnnotations',
  AutoCompleteAnnotations = 'autoCompleteAnnotations',
  UpdateInstanceDetails = 'updateInstanceDetails',

  GetDocument = 'getDocuments',

  GetUpdatedInstanceData = 'getInstanceDataOld',
  GetJsonAnnotations = 'getJsonAnnotations',

  GetInstanceDataNewsTemp = 'getInstanceDataNewsTemp',
  GetDocumentsNewsTemp = 'getDocumentsNewsTemp',
  GetJsonAnnotationsNewsTemp = 'getJsonAnnotationsNewsTemp',
}

export const enum SubMethod {
  TaskID = '&task_id=',
  ProjectID = '&project_id=',
  InstanceID = '&instance_id=',
  DocID = '&doc_id=',
  CompanyID = '&company_id=',
}

export const getSubQuery = (method: Query) => {
  switch (method) {
    case Query.ActivateTask:
    case Query.GetTaskInstances:
    case Query.GetTaskOverview:
    case Query.GetTaskInstancesOverview:
    case Query.SubmitValidationTask:
    case Query.ClearConfirmations:
    case Query.RestoreInstanceData:
    case Query.AutoConfirm:
    case Query.UpdateJsonAnnotations:
    case Query.UpdateInstanceData:
    case Query.UpdateInstanceDetails:
    case Query.AutoCompleteAnnotations:
      return SubMethod.TaskID;

    case Query.GetProjectInstances:
    case Query.GetProjectDetails:
    case Query.CreateValidationTask:
      return SubMethod.ProjectID;

    case Query.GetUpdatedInstanceData:
    case Query.GetJsonAnnotations:
    case Query.GetInstanceDataNewsTemp:
    case Query.GetJsonAnnotationsNewsTemp:
    case Query.GetInstanceData:
    case Query.RemoveInstancesFromTask:
    case Query.RejectInstances:
      return SubMethod.InstanceID;

    case Query.GetDocument:
    case Query.GetDocumentsNewsTemp:
      return SubMethod.DocID;

    case Query.GetCalendarEvents:
      return SubMethod.CompanyID;

    default:
      return '';
  }
};
