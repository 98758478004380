
<div class="row">
    <div class="col-12 col-xl-6">
        <div class="card mb-3">
            <div class="card-header">Details</div>
            <div class="card-body px-0 py-0 symbols">
                <con-table
                    (deleteObject)="open(content); setDeliveryIdToCancel($event);"
                    [linkPath] = "'tasks'"
                    [clickableCells] = "[0, 5]"
                    [cellsContainingUrl] = "[5]"
                    [isEditable]="false" 
                    [withHeader]="true"
                    editingObject = "delivery"
                    [canCancelData]="true"
                    [tableHeadData] = "deliverDetailTableHeader" 
                    [tableBodyData]="deliveryDetailTableBody"
                >
                </con-table>
            </div>
        </div>
        <div *ngIf="!isPending" class="card mb-3">
            <div class="card-header">Jobs</div>
            <div class="card-body px-0 py-0 symbols" style="max-height: 150px; overflow-y: scroll;">
                <con-table 
                    [isEditable]="false" 
                    [withHeader]="true"
                    [canCancelData]="true"
                    specialConditionForCancellingData="Running Mozenda Job"
                    [tableHeadData] = "jobTableHeader"
                    [tableBodyData]="jobsTableBody" >
                </con-table>
            </div>
        </div>
        <div *ngIf="!isPending && !isIndicesTaskType" class="card mb-3">
            <div class="card-header d-flex justify-content-between">
                <div class="col-11 d-flex justify-content-between p-0">                
                    <div>
                        Data
                    </div>
                    <con-switch 
                        conditionFalseText="Old data"  
                        conditionTrueText="New data" 
                        [model]="newDataModel"
                        (onChange)="changeModel()"
                    >
                    </con-switch>
                </div>
                <button [class]="isEditable ? 'btn btn-sm btn-danger py-0' : 'btn btn-sm btn-success py-0'" *ngIf="newDataModel" (click)="isEditable = !isEditable; failedDataTypeMsg = ''; refreshDelivery()">
                    <con-icon-by-name [iconName]="isEditable ? 'times':'pencil'"></con-icon-by-name>
                </button>
            </div>
            <div *ngIf="newDataModel" class="card-body px-0 py-0 symbols d-flex" style="min-height: 500px;">
                <con-table  
                    class="col-12  p-0"
                    (passedDataTypeCheck)="dataTypeCheck($event)" 
                    (onChange)="onChange($event)" 
                    [isEditable]="isEditable" 
                    [dataTypes]="dataTypes"
                    [withHeader]="true" 
                    [tableHeadData]="deliveryHeader" 
                    [tableBodyData]="deliveryBody" 
                    [oldTableHeadData]="previousDataHeader" 
                    [oldTableBodyData]="previousDataBody" 
                    [notEditableColumns]="[0, 1]"
                >
                </con-table>

            </div>
            <div *ngIf="!newDataModel" class="card-body px-0 py-0 symbols d-flex" style="min-height: 500px;">
                <con-table  
                    class="col-12 p-0"
                    [withHeader]="true" 
                    [tableHeadData]="previousDataHeader" 
                    [tableBodyData]="previousDataBody" 
                    [notEditableColumns]="[0, 1]"
                >
                </con-table>
            </div>

        </div>
        <div *ngIf="!isPending && isIndicesTaskType" class="card mb-3">
            <div class="card-header d-flex justify-content-between">
                <div>
                    Edit
                </div>
            </div>
            <div class="card-body px-0 py-0 symbols text-center" [ngStyle]="{'min-height': isIndicesTaskType ? '900px' : '500px'}">
                <indicies-view
                    [taskDetail]="taskDetail"
                    [deliveryView]="true"
                >
                </indicies-view>
                <button (click)="requestAction('submitDataDelivery')" class="col-2 mt-3 btn btn-primary">Submit</button>
            </div>
            
        </div>

        <div *ngIf="!isPending && !isIndicesTaskType" class="col-12 col-6-xl d-flex justify-content-between my-3">
            <button [disabled]="!isEditable || failedDataTypeCheck" (click)="requestAction('updateDataDelivery'); isEditable = !isEditable" class="col-2 btn btn-primary">Save</button>
            <button (click)="requestAction('submitDataDelivery')" class="col-2 btn btn-primary">Submit</button>
        </div>
        <ngb-alert class="col-6" type="danger" *ngIf="errMessage"  (close)="showAlert = !showAlert" [dismissible]="true">
            {{errMessage}}
        </ngb-alert>
        <div *ngIf="failedDataTypeCheck">{{failedDataTypeMsg}}</div>
        <ngb-alert class="col-6" [type]="submitDataDelivery ? 'success' : 'warning'" *ngIf="showAlert"  (close)="showAlert = !showAlert" [dismissible]="true">
            {{responseMessage}}
        </ngb-alert>
    </div>


    <div class="col-12 col-xl-6">
        <div *ngIf="!isPending && isIndicesTaskType" class="col-12 pl-0 col-xl-6">
            <button [disabled]="isHTMLView"(click)="requestAction('getDeliveryDetail'); isHTMLView = !isHTMLView" class="col-2 btn btn-primary">Html</button>
            <button [disabled]="!isHTMLView" (click)="requestAction('getIndicesMozendaData'); isHTMLView = !isHTMLView" class="col-2 btn btn-primary">Data</button>
        </div>
        <div *ngIf="!isPending" class="card is-html">
            <div class="card-header d-flex justify-content-between">
                <div>
                    {{isHTMLView ? 'HTML' : 'DATA'}}
                </div>
                <con-icon-by-name class="cursor-pointer" iconName="refresh" (click)="refreshScrapedHtml()"></con-icon-by-name>
            </div>
            <div *ngIf="!isHTMLView" #cardHeader class="card-body"></div>
            <ngb-alert *ngIf="isHTMLView && !hasScrapedHTML" [dismissible]="false" type="warning">No html scrape available for this delivery</ngb-alert>
            <iframe *ngIf="isHTMLView" #scrapedHtml [attr.srcDoc]="srcDoc" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" class="card-body">
            </iframe>
        </div>
    </div>    
</div>

<ng-template #content let-modal>
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Cancel delivery</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>Why are you cancelling the delivery?</div>
            <select class="custom-select col-5" type="text" (change)="setReasonForCancellationOfDelivery($event.target.value);" ng-model="dropdownOptions">
                <option *ngFor="let option of dropdownOptions" value={{option}}>
                    {{option}}
                </option>
            </select>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button type="button" class="btn btn-primary" (click)="requestAction('cancelDelivery'); modal.close('Cancel delivery')" >Cancel delivery</button>
            <button type="button" class="btn btn-primary" (click)="modal.close('Go back')" >Go back</button>
        </div>
    </div>
</ng-template>