import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ApiSettings, AuthSettings, PressReleaseAttachmentSettings } from '../../settings.class';

import { FileUploader } from 'ng2-file-upload';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'con-file-uploader',
    templateUrl: './file-uploader.component.html'
})

export class FileUploaderComponent implements OnInit, OnChanges {
    @Input() type: string;
    @Input() control: UntypedFormControl;
    @Input() rules: string[] = [];

    public hasSubmitted = false;
    public hadFile: boolean;
    public uploader: FileUploader;
    public maxFileSize: number;
    public validExtensions: string[];
    public acceptParameterValue: string;
    public fileErrors: string[] = [];
    public sizeErrorKey = PressReleaseAttachmentSettings.INVALID_SIZE_ERROR_KEY;
    public extensionErrorKey = PressReleaseAttachmentSettings.INVALID_EXTENSION_ERROR_KEY;
    public selectedFileName: string

    private UPLOAD_CONFIG: any = {
        url: ApiSettings.BASE_URL + '/' + ApiSettings.TEMPORARY_STORAGE,
        autoUpload: false,
        authTokenHeader: AuthSettings.HEADER_NAME,
        authToken: AuthSettings.HEADER_PREFIX + ' ' + AuthSettings.getToken()
    }

    constructor(
        private validationConversionService: ValidatorConversionService,
        private toaster: ToastrService
    ) { }

    hasFile() {
        return this.control.value !== '';
    }
    setFile(file_name: string) {
        this.control.patchValue(file_name);
        this.control.markAsDirty();
        this.control.markAsTouched();
    }
    emptyFile() {
        this.setFile('');
        this.uploader.clearQueue();
    }
    ngOnChanges() {
        this.hasSubmitted = false;
        this.hadFile = this.hasFile();
        this.uploader = new FileUploader(this.UPLOAD_CONFIG);
        this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
            this.setFile(JSON.parse(response).file_name);
            this.hasSubmitted = true;
        }
        this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
            this.uploader.clearQueue();
            this.toaster.error(`Failed to upload ${item?.file?.name}`, 'Something went wrong');
        }
    }
    ngOnInit() {
        this.maxFileSize = this.validationConversionService.getFileMaxSizeFromRules(this.rules);
        this.validExtensions = this.validationConversionService.getFileValidExtensionsFromRules(this.rules);
        this.acceptParameterValue = this.validExtensions.map((ext) => `.${ext}`).join(',');
    }
    isImage() {
        return this.type === 'image';
    }

    onFileSelected(selectedFiles: File) {
        if(!this.uploader.queue.length) {
            return;
        }
        this.fileErrors = this.validationConversionService.validateFile(selectedFiles[0], this.maxFileSize, this.validExtensions);
        if(this.fileErrors.length) {
            this.selectedFileName = selectedFiles[0].name;
            this.uploader.clearQueue();
        } else {
            this.selectedFileName = null;
            this.uploader.uploadAll();
        }
    }
}
