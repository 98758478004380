import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';
import { TranslationService } from 'app/utility/services/translation.service';

@Component({
    selector: 'con-description-editor',
    templateUrl: './description-editor.component.html',
    styles: [
      `.position-btn { padding: 1px 10px !important; float: right !important; }`
    ]
})

export class DescriptionEditorComponent implements OnChanges {
    @Input() description: any;
    @Input() language: any;
    @Input() companyId: number;
    @Input() requiredLanguages: any;
    @Input() companyDescriptions: any;
    @Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();

    private formExpanded = false;
    public fixedValues: any;
    public required: boolean;
    public englishDescription: any;
    constructor(public profile: ProfileService,
                private dateConverter: DateConverterService,
                private translationService: TranslationService) {}

    ngOnChanges() {
        this.fixedValues = {
            language_id: this.language.id,
            company_id: this.companyId,
            revised_at: this.dateConverter.toEntityString(moment())
        }

        this.required = this.requiredLanguages.findIndex(l => this.language.id === l.id) > -1;
    }

    descriptionUpdated(description: any) {
        this.formExpanded = false;
        this.afterUpdate.emit(description);
        if(description && description.language){
            this.openTranslation(description)
        }
    }

    descriptionDeleted(description: any) {
        this.formExpanded = false;
        this.afterDelete.emit(description);
    }

    public showForm() {
        return !this.description || this.formExpanded;
    }

    public toggleForm() {
        this.formExpanded = !this.formExpanded;
    }

    public canTranslate() {
        return this.profile.can(this.profile.getPermissionName('Description', 'update')) &&
        this.language.name === 'English'
    }

    public openTranslation(description) {
        this.translationService.onDescriptionSaved(description)
    }
    public openTranslationWithGivenMissingDescription(description, currentMissingLanguage){
          this.translationService.onDescriptionSaved(description, currentMissingLanguage)
    }

    showTranslateButton() {
      const hasPermissionToUpdateDescription = this.profile.can(this.profile.getPermissionName('Description', 'update'));
      // check company description exists and have to update the description
      if (this.companyDescriptions  && hasPermissionToUpdateDescription) {
        // if exist check it contains english language
          const index = this.companyDescriptions.findIndex((companyDescription) => companyDescription.language.name === 'English');
        // if english description is found, we return true
          if (index !== -1) {
            this.englishDescription = this.companyDescriptions[index];
            return true;
          }
      }
    return false;
    }
}
