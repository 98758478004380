<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="list"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Coverage List</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row mt-15 mb-15">
        <div class="col-12">
          <div class="card">
            <div class="card-header">Estimates Tier Isin</div>

            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <con-entity-form entityName="EstimatesTierIsin" (afterSave)="updateListing($event)" [emptyAfterSave]="true" [canEdit]="false"></con-entity-form>
                </div>
                <div class="col-8">
                  &nbsp;
                </div>
              </div>
              <div class="col-12 mt-15">
                <div class="row">
                  <con-entity-list entityName="EstimatesTierIsin" [runUpdate]="reloadSubject"></con-entity-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
