<div #newsInstanceView class="p-2" *ngIf="instanceDataNews">
  <div class="d-flex justify-content-between">
    <div class="form-inline mb-3">
      <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Document type</span></label>
      <div class="input-group p-1">
        <div class="d-flex align-items-center justify-content-end p-0">
          <button
            *ngFor="let documentType of documentTypes"
            style="border:none;"
            [class]="instanceDataNews.doc_meta.document_type.toString() === docMetaDocumentType[documentType] ? 'm-1 btn-primary btn-lg cursor-pointer lang-button' : 'm-1 btn-disabled lang-button btn-lg cursor-pointer'"
            (click)="instanceDataNews.doc_meta.document_type = $event.target.value; $event.target.blur() "
            [value]="docMetaDocumentType[documentType]"
          >
            {{ docMetaDocumentType[documentType] }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <button class="m-1 btn-primary btn-lg cursor-pointer lang-button d-flex align-items-center"
              (click)="restoreData(true);">
        Reload data
        <con-icon-by-name class="ml-2 d-flex align-items-center" iconName="refresh"></con-icon-by-name>
      </button>
      <button class="m-1 btn-danger btn-lg cursor-pointer lang-button d-flex align-items-center" (click)="clearData();">
        Clear data
        <con-icon-by-name class="ml-2 d-flex align-items-center" iconName="eraser"></con-icon-by-name>
      </button>
    </div>
  </div>
  <div class="d-flex">
    <div>
      <div class="form-inline mb-3">

        <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Report end</span></label>
        <div class="input-group p-1">
          <div class="d-flex align-items-center justify-content-end col-12">
            <input
              *ngIf="instanceDataNews.doc_meta.report_date_end"
              [(ngModel)]="instanceDataNews.doc_meta.report_date_end"
              type="text"
              class="form-control smaller-input"
              placeholder="Report end"
              aria-label="Report end"
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-inline mb-3">

        <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Fiscal period</span></label>
        <div class="input-group p-1">
          <div class="d-flex align-items-center justify-content-end col-12">
            <input
              *ngIf="instanceDataNews.doc_meta.fiscal_period != null"
              [(ngModel)]="instanceDataNews.doc_meta.fiscal_period"
              type="text"
              class="form-control smaller-input"
              placeholder="Fiscal period"
              aria-label="Fiscal period"
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-inline mb-3">
    <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Currency</span></label>
    <div class="input-group p-1">

      <div class="d-flex align-items-center justify-content-end p-0">

        <button
          *ngFor="let currency of currencies"
          style="border:none;"
          [class]="instanceDataNews.doc_meta.currency.toString() === docMetaCurrency[currency] ? 'm-1 btn-primary btn-lg cursor-pointer lang-button' : 'm-1 btn-disabled lang-button btn-lg cursor-pointer'"
          (click)="instanceDataNews.doc_meta.currency = $event.target.value; $event.target.blur() "
          [value]="docMetaCurrency[currency]"
        >
          {{ docMetaCurrency[currency] }}
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-2 share-class-area">
    <div class="form d-flex align-items-center justify-content-between">
      <h4 class="my-1 mr-2 d-none d-sm-block row"><span class="badge badge-light">Data</span></h4>
      <div class="form-inline">
        <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">periods</span></label>
        <div class="input-group p-1">
          <div class="d-flex align-items-center justify-content-end p-0">
            <button
              *ngFor="let period of periods"
              style="border:none;"
              [class]="currentPeriod === taxonomyDataContextPeriod[period] ? 'm-1 btn-primary btn-lg cursor-pointer lang-button' : 'm-1 btn-disabled lang-button btn-lg cursor-pointer'"
              (click)="onPeriodChangeButtonClick($event)"
              [value]="taxonomyDataContextPeriod[period]"
            >
              {{ taxonomyDataContextPeriod[period] }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="column p-0">
      <div class="col-12 p-0 share-class-container">
        <div [ngClass]="getFieldClasses(field)" (click)="onTaxonomyFieldClick(field)"
             *ngFor="let field of taxonomyDataFields; let i = index">
          <ng-container *ngIf="instanceDataNews.taxonomy_data[field]">
            <div class="d-flex align-items-center justify-content-end col-12 p-0"
                 *ngFor="let item of instanceDataNews.taxonomy_data[field].values; let g = index">
              <ng-container *ngIf="currentPeriod === item.period">
                <div class="container-fluid p-0 m-0">
                  <div class="row col-12 p-0 m-0">
                    <div class="col-1 color-field" [ngStyle]="{backgroundColor : taxonomyDataFieldColors[field]}"></div>
                    <div class='col-5' style="cursor: pointer">
                      <div [ngClass]="taxonomyDataFieldClasses[field]">
                        {{field}}
                      </div>
                    </div>
                    <div class="col-6 row">
                      <!--Sanity check-->
                      <div class="col-2 p-0">
                        <button *ngIf="!doSanityCheck(item)"
                                class="btn p-0 quantity-button d-flex align-items-center"
                                type="button"
                                ngbPopover="{{sanityErrorMessage}}"
                                container="body"
                                [openDelay]="300"
                                [closeDelay]="500"
                                triggers="mouseenter:mouseleave">
                          <con-icon-by-name class="d-flex align-items-center"
                                            [iconName]="'exclamation'"
                                            style="color: red;"></con-icon-by-name>
                        </button>
                      </div>
                      <!--Sign-->
                      <div class="col-2 p-0">
                        <button [disabled]="!newsInstanceService.selectedNewsAnnotation"
                                class="btn p-0 quantity-button d-flex align-items-center"
                                (click)="item.sign === 'p' ? (item.sign = 'n') : (item.sign = 'p'); updateSign(item.sign, instanceDataNewsTaxonomyDataKey[i], g);  item.altered=true; item.confirmed='Altered'">
                          (
                          <con-icon-by-name class="d-flex align-items-center"
                                            [iconName]="item.sign === 'n' ? 'minus' : 'plus'"></con-icon-by-name>
                          )
                        </button>
                      </div>
                      <!--Annotation selector dropdown-->
                      <div class="col-6 p-0 d-flex justify-content-between">
                        <!-- {{item.value}} -->
                        <div class="col-3 p-0">
                          <div class="form-inline select-container">
                            <select style='max-width:180px' id="custom-select" class="custom-select-field-value"
                                    type="text"
                                    (change)="updateSelectedAnnotation(field, $event, instanceDataNewsTaxonomyDataKey[i], g, item.values, this);"
                                    [ngModel]="getValueModel(item, field)">
                              <option [ngStyle]="{backgroundColor: !value.annot_id ? 'red' : 'white'}" class="maxwidth"
                                      *ngFor="let value of item.values" [attr.data-annot_id]="value.annot_id"
                                      [attr.data-text]="value.value" [ngValue]="value && value.value">
                                {{value && value.value}}
                                <con-icon-by-name *ngIf="item.value && item.value.length" (click)="item.value = null;"
                                                  class="d-flex align-items-center mr-3"
                                                  iconName="times-circle"></con-icon-by-name>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!--Quantity selector dropdown-->
                      <div class="col-2 p-0">
                        <div class="form-inline">
                          <select
                            [disabled]="item.quantity == '%' && (field !== 'core_tier_1_ratio' && field !== 'cost_income_ratio' && field !== 'return_on_equity')"
                            class="custom-select"
                            type="text"
                            [ngModel]="getQuantityModel(item, field)"
                            (change)="onTaxonomyQuantityChange($event, item, field)"
                            name="{{i}}"
                          >
                            <ng-container *ngFor="let quantity of taxonomyDataQuantities">
                              <option
                                *ngIf="quantity != 'Percent' || item.quantity == '%' || !item.quantity"
                                [ngValue]="taxonomyDataQuantity[quantity]">
                                {{taxonomyDataQuantity[quantity]}}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
