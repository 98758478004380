<div class="sidebar entity-scroll">

  <nav class="nav flex-column">
    <div *ngIf="showMainSideMenu">
      <a class="nav-link cursor-pointer" routerLinkActive="active" routerLink="/estimates" [routerLinkActiveOptions]="{exact: true}">Estimates</a>
      <a class="nav-link cursor-pointer" routerLinkActive="active" routerLink="/estimates/coverage-list" [routerLinkActiveOptions]="{exact: true}">Coverage List</a>
    </div>

    <div *ngIf="showSurveyDetailsSideMenu">
      <a class="nav-link back-to-reports" (click)="backToSurveyList()">
        <con-icon-by-name iconName="chevron-circle-left"></con-icon-by-name>Back to surveys
      </a>
      <a class="nav-link cursor-pointer" [ngClass]="{'active': selectedMenu === 'survey-main'}"  (click)="setCurrentMenu('survey-main')">
        Survey Main
      </a>

      <a class="nav-link cursor-pointer" [ngClass]="{'active': selectedMenu === 'active-consensus'}"  (click)="setCurrentMenu('active-consensus')">
        Consensus Snapshot
      </a>
      <a class="nav-link back-to-reports d-flex justify-content-between align-items-center my-1" [ngClass]="{ 'disabled-switch': !enableSurveyApproveToggle }">
        <div *ngIf="!['IP_APPROVE', 'IP_UNAPPROVE'].includes(currentSurveyState)" [ngbTooltip]="tooltipText" [placement]="'right'" [container]="'body'">
          <label class="switch">
            <input type="checkbox" [checked]="currentSurveyState === 'APPROVED'"  (click)="toggleSurveyApprove()" [disabled]="!enableSurveyApproveToggle">
            <span class="slider round"></span>
          </label>
          <span class="switch-text">
            {{ currentSurveyState === 'APPROVED' ? 'Unapprove' : 'Approve' }}
          </span>
        </div>
        <div *ngIf="['IP_APPROVE', 'IP_UNAPPROVE'].includes(currentSurveyState)">
          <div class="spinner-border spinner-border-sm"></div>
          <span>{{ currentSurveyState === 'IP_APPROVE'  ? 'Approving...' : 'Unapproving...'}}</span>
        </div>
        <div *ngIf="!['IP_APPROVE', 'IP_UNAPPROVE'].includes(currentSurveyState) && selectedSurveyDetails?.locked" [routerLink]="['/entity/user', selectedSurveyDetails?.updated_by]" class="user-profile-link-custom">
          <con-avatar [userId]="selectedSurveyDetails?.updated_by" [size]="20" [placement]="'right'" [tooltipPrefix]="'Approved by'"></con-avatar>
        </div>
      </a>
    </div>
    <div *ngIf="showSurveySideMenu && surveyDataLoaded">
      <a class="nav-link cursor-pointer" *ngFor="let survey of companySurveys; let i = index" (click)="goToEstimate(survey)">
        <div class="d-flex justify-content-between">
          {{estimatesService.getCompanySurveyName(survey)}}
          <con-icon-by-name [ngClass]="survey?.locked ? 'lock-set': ''" [iconName]="survey?.locked ? 'lock': 'unlock'" ></con-icon-by-name>
        </div>
      </a>
    </div>
  </nav>
</div>
<div class="content">
  <router-outlet  (activate)="onActivate($event)"></router-outlet>
</div>
