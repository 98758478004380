import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';

import { ProfileService } from '../../auth/services/profile.service';
import { DateConverterService } from '../../shared/services/date-converter.service';

import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { StaticService } from '../services/static.service';
import { ToastrService } from 'ngx-toastr';

interface Sector {
  id: number;
  name: string;
  infront_sector_l2?: Sector[];
  infront_sector_l1_id?: number;
}


@Component({
  selector: 'con-sector-editor',
  templateUrl: './sector-editor.component.html'
})
export class SectorEditorComponent implements OnChanges {
@Input() company: any;
@Input() sectors: any;


@Output() afterUpdate: EventEmitter<any> = new EventEmitter<any>();

form: FormGroup;
// sectors: Sector[] = [];
subsectors: Sector[] = [];

private showsForm = false;
public fixedValues: any;

  constructor(public profile: ProfileService,
              private fb: FormBuilder,
              private staticService: StaticService,
              private http: HttpClient,
              private toaster: ToastrService,
    private dateConverter: DateConverterService) {
    this.form = this.fb.group({
      infront_sector_l1: null,
      infront_sector_l2: null,
    });
  }
  ngOnInit() {
    this.form.get('infront_sector_l1')!.valueChanges.subscribe((sectorId) => {
      this.updateSubsectors(sectorId);
    });
  }

  updateSubsectors(sectorId: any) {
    const sector = this.sectors.find((s) => s.id === parseInt(sectorId));
    if(sector) {
      this.subsectors = sector.infront_sector_l2 || [];
    } else {
      this.subsectors = [];
      this.form.patchValue({ infront_sector_l2: null });
    }
  }
  ngOnChanges() {
    this.fixedValues = {
      revised_at: this.dateConverter.toEntityString(moment())
    }
  }

  companyUpdated(company: any) {
    this.showsForm = false;
    this.afterUpdate.emit(company);
  }

  toggleForm() {
    this.showsForm = !this.showsForm;
    this.form.patchValue({
      infront_sector_l1: this.company.infront_sector_l1_id,
      infront_sector_l2: this.company.infront_sector_l2_id
    });
  }

  hasNoSector() {
    return this.company.infront_sector_l1_id === null
  }

  showForm() {
    return this.company.infront_sector_l1_id === null || this.showsForm;
  }

  saveSectors() {
    if (this.form.value.infront_sector_l1 == this.company.infront_sector_l1_id && this.form.value.infront_sector_l2 == this.company.infront_sector_l2_id) {
      this.toggleForm()
      return;
    }
    const sector = {
      infront_sector_l1_id: this.form.value.infront_sector_l1 ? parseInt(this.form.value.infront_sector_l1) : null,
      infront_sector_l2_id: this.form.value.infront_sector_l2 ? parseInt(this.form.value.infront_sector_l2) : null,
      companyId: this.company.id,
      updated_at: this.company.updated_at,
      revised_at: this.fixedValues.revised_at
    }
    this.staticService.updateSectorsOfCompany(sector).subscribe((company) => {
      this.toaster.success("Sector updated successfully", "Company");
      this.showsForm = false;
      this.afterUpdate.emit(company);
      this.company = company;
      this.getCompanySector();
    })

  }

  getCompanySector() {
    const sectorL1 = this.sectors?.find(sector => sector.id === parseInt(this.company.infront_sector_l1_id));
    let sectorL2: Sector | undefined;
    if (sectorL1 && sectorL1.infront_sector_l2) {
      sectorL2 = sectorL1.infront_sector_l2.find(sector => sector.id === parseInt(this.company.infront_sector_l2_id));
    }
    return {
      l1_name: sectorL1?.name || '',
      l2_name: sectorL2?.name || ''
    }
  }
}
