import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeAll, shareReplay, take } from 'rxjs/operators';
import { Query } from '../../doc-process-common/services/methods.service';
import { ApiService } from '../../doc-process-common/services/api.service';
import { HTTPMethod } from '../../doc-process-common/models/api';
import { DocProcessService } from '../../doc-process-common/services/doc-process.service';
import { FundamentalsAnnotation } from '../../kpi-and-kiid/models/fields';
import { AbstractApiService } from './abstract-api.service';
import { AutoCompleteAnnotationsResponseContract } from '../models/auto-complete-annotations-response-contract';
import { TaskDataEntryService } from '../state-management/services/task-data-entry.service';
import { TaskDataEntryState } from '../state-management/states';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FundamentalsApiService extends AbstractApiService {
  public updatingInstanceData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private docProcessService: DocProcessService, apiService: ApiService, private taskDataEntryService: TaskDataEntryService, private store: Store, private toastr: ToastrService) {
    super(apiService);
  }

  public getInstanceJsonAnnotationsBulk(taskInstancesString$: Observable<string>): Observable<{ [key: number]: Array<FundamentalsAnnotation> | null }> {
    const instanceJsonAnnotationsResponse = taskInstancesString$.pipe(
      map((taskInstancesString: string) => {
        const res = this.apiService.httpGet({ params: taskInstancesString }, Query.GetJsonAnnotations);
        return res;
      }),
      mergeAll(),
      shareReplay()
    );

    const jsonAnnotations = instanceJsonAnnotationsResponse.pipe(
      map((res: any) => {
        const newRes = res.reduce((a, b) => {
          a[b.instance_id] = b?.annotations ?? [];
          return a;
        }, {});
        return newRes;
      })
    );

    return jsonAnnotations;
  }

  public submitTaskJsonAnnotations(jsonAnnotations) {
    this.docProcessService.getData({ body: jsonAnnotations, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateJsonAnnotations, HTTPMethod.Post).finally(() => {
      this.updatingInstanceData.next(false);
    });
  }

  //TODO: Create model
  public submitAutoCompleteRequest(currentInstanceId, previousInstanceId, statementId) {
    const sourceAnnotations = this.store.selectSnapshot(TaskDataEntryState.selectInstanceJsonAnnotationById(previousInstanceId));
    const targetAnnotations = this.store.selectSnapshot(TaskDataEntryState.selectInstanceJsonAnnotationById(currentInstanceId));
    const sourceInstance = { annotations: sourceAnnotations, instance_id: previousInstanceId };
    const targetInstance = { annotations: targetAnnotations, instance_id: currentInstanceId, statement_id: statementId };

    const body = {
      source_instance: sourceInstance,
      target_instance: targetInstance,
      task_id: this.docProcessService.taskId.getValue(),
    };

    return this.apiService.httpPost<AutoCompleteAnnotationsResponseContract>({ body: body, params: this.docProcessService.taskId.getValue().toString() }, Query.AutoCompleteAnnotations);
  }
  //TODO: Remove
  public submitInstanceDataUpdate(instanceData) {
    this.docProcessService
      .getData({ body: instanceData, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateInstanceData, HTTPMethod.Post)
      .then((data) => {
        this.docProcessService.displaySaveCompletedToastr(data);
        this.docProcessService.unsavedData.next(false);
      })
      .finally(() => {
        this.updatingInstanceData.next(false);
      });
  }

  public submitInstanceDetails(instanceDetails) {
    this.apiService.httpPost({ body: instanceDetails, params: this.docProcessService.taskId.getValue().toString() }, Query.UpdateInstanceDetails).subscribe(
      (res) => {
        this.docProcessService.unsavedData.next(false);
        this.docProcessService.displaySaveCompletedToastr(res);
      },
      (e) => {
        const responseMessage = e.data?.exception;
        this.docProcessService.displayResponseError(responseMessage);
        this.updatingInstanceData.next(false);
      },
      () => {
        this.updatingInstanceData.next(false);
      }
    );
  }
}
