import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'con-no-data',
  templateUrl: './no-data.component.html'
})
export class NoDataComponent implements OnInit {
  @Input() count: number;
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
