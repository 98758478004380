import { RouterModule, Routes } from '@angular/router';
import { PressReleaseComponent } from './layout/press-release.component';
import { PressReleaseStartComponent } from './layout/press-release-start.component';
import { CompanyPressReleaseComponent } from './component/company-press-release/company-press-release.component';
import { InstitutionPressReleaseComponent } from './component/institution-press-release/institution-press-release.component';


export const pressReleaseRoutes: Routes = [

  {
    path: '',
    component: PressReleaseComponent,
    canActivate: [],
    children: [
      {
        path: '',
        component: PressReleaseStartComponent
      },
      {
        path: 'company/:id',
        component: CompanyPressReleaseComponent
      },
      {
        path: 'institution/:id',
        component: InstitutionPressReleaseComponent
      }
      ]
  }
];
export const pressReleaseRouting = RouterModule.forChild(pressReleaseRoutes);
