<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <h3 class="mb-0">Details</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <con-loader *ngIf="loading"></con-loader>
        <div class="col-6 mb-2" *ngIf="!loading">
            <con-pretty-entity-shower
                [entity]="companyUniverse"
                entityName="CompanyUniverse"
                [actions]="[{
                    name: 'sync',
                    label: 'Sync',
                    icon: 'refresh'
                }]"
                (onAction)="syncCompanies($event)"
                ></con-pretty-entity-shower>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('exchanges')">
                    <div class="d-flex justify-content-start">
                        Exchanges
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['exchanges'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['exchanges']">
                    <con-entity-list
                        entityName="Exchange"
                        originEntityName="CompanyUniverse"
                        relation="exchanges"
                        [entityId]="companyUniverseId"
                    ></con-entity-list>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('indexes')">
                    <div class="d-flex justify-content-start">
                        Indexes
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['indexes'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['indexes']">
                    <con-entity-list
                        entityName="Index"
                        originEntityName="CompanyUniverse"
                        relation="indexes"
                        [entityId]="companyUniverseId"
                    ></con-entity-list>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('companies')">
                    <div class="d-flex justify-content-start">
                        Companies
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['companies'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <div class="card-content" *ngIf="toggles['companies']">
                    <con-entity-list
                        entityName="Company"
                        originEntityName="CompanyUniverse"
                        relation="companies"
                        [entityId]="companyUniverseId"
                    ></con-entity-list>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header cursor-pointer" (click)="toggleView('companies_without_manager')">
                    <div class="d-flex justify-content-start">
                        Companies without manager
                        <div class="card-header-toggle ml-auto">
                            <con-icon-by-name iconName="angle-right" [mutations]="toggles['companies_without_manager'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="toggles['companies_without_manager']">
                    <con-entity-list
                        entityName="Company"
                        originEntityName="CompanyUniverse"
                        relation="companies"
                        [fixedSearchParams]="{ manager_id: 'f:null' }"
                        [entityId]="companyUniverseId"
                    ></con-entity-list>
                    <div class="card-body" *ngIf="isSuperUser">
                        <div class="alert alert-success" *ngIf="assignSuccessful"><con-icon-by-name iconName="check"></con-icon-by-name>Assign successful!</div>
                        <ng-container *ngIf="!assignSuccessful">
                            <h5>Assign all to:</h5>
                            <con-entity-search-field
                                entityName="User"
                                (onSelect)="assignManagerToCompaniesWithoutManager($event)"
                            ></con-entity-search-field>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header cursor-pointer" (click)="toggleView('companies_without_instruments')">
          <div class="d-flex justify-content-start">
            Companies without instruments
            <div class="card-header-toggle ml-auto">
              <con-icon-by-name iconName="angle-right" [mutations]="toggles['companies_without_instruments'] ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
            </div>
          </div>
        </div>
        <ng-container *ngIf="toggles['companies_without_instruments']">
          <con-entity-list
            entityName="Company"
            originEntityName="CompanyUniverse"
            relation="companies_without_instruments"
            [entityId]="companyUniverseId"
          ></con-entity-list>
        </ng-container>
      </div>
    </div>
  </div>

</div>
