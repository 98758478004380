import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { FundamentalsAnnotation, InstanceDocument } from '../../kpi-and-kiid/models/fields';
import { DataEntryTaxonomyDetails } from '../models/data-entry-taxonomy-details';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';

@Component({
  selector: 'con-dynamic-data-entry-component-container',
  styles: [
    `
      .nav-link {
        height: 100%;
      }
    `,
  ],
  template: `
    <!--if tab list-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.TabComponents">
      <ul ngbNav #nav="ngbNav" class="nav-tabs flex d-flex w-100 justify-content-start flex-nowrap mb-2" [(activeId)]="activeMenuTab">
        <ng-container *ngFor="let eachComponent of component.components">
          <li [ngbNavItem]="eachComponent.title" class="flex-grow-1">
            <a ngbNavLink class="btn btn-sm btn-primary p-1 py-2">
              {{ eachComponent.title }}
            </a>
            <ng-template ngbNavContent>
              <dp-dynamic-data-entry-component-wrapper [component]="eachComponent" [activeMenuTabId]="activeMenuTab"></dp-dynamic-data-entry-component-wrapper>
            </ng-template>
          </li>
        </ng-container>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </ng-container>

    <!--if list of components-->
    <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.ListOfComponents">
      <div class="d-flex flex-wrap flex-column">
        <div *ngFor="let eachComponent of component.components">
          <label *ngIf="component.options?.display_title !== false && eachComponent.title">
            <small>{{ eachComponent.title }}</small>
          </label>
          <dp-dynamic-data-entry-component-wrapper [component]="eachComponent"></dp-dynamic-data-entry-component-wrapper>
        </div>
      </div>
    </ng-container>
  `,
})
export class DynamicDataEntryComponentContainerComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;

  public activeMenuTab: any;

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum;
  }

  constructor() {}

  ngOnInit(): void {}
}
