import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UtilService } from 'app/doc-process/sub-modules/doc-process-common/services/util.service';
import { InstanceDocumentAnnotations } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {
  DocMetaCurrency,
  DocMetaDocumentType,
  InstanceDataNews,
  NewsTaxonomyDataFieldColors,
  TaxonomyContextPeriod,
  TaxonomyDataFieldIndentClasses,
  TaxonomyDataHotkeyBinding,
  TaxonomyDataValue,
  TaxonomyFieldTypes,
  TaxonomyQuantity,
  TaxonomySign,
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { merge, Observable, Subscription } from 'rxjs';
import { DocProcessLegacyCommonsService } from '../../services/doc-process-legacy-commons.service';
import { HotkeyHandlerService } from '../../services/hotkey-handler.service';
import { NewsInstancesService } from '../../../doc-process-common/services/news-instances.service';
import NewsSanityCheckService from '../../../doc-process-common/services/news-sanity-check.service';
import {DocProcessService} from '../../../doc-process-common/services/doc-process.service';

@Component({
  selector: 'con-news-instances-handler',
  templateUrl: './news-instances-handler.component.html',
  styleUrls: ['./news-instances-handler.component.scss'],
})
export class NewsInstancesHandlerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() instanceDataNews: InstanceDataNews = null;
  @Input() instanceDataAnnotations: InstanceDocumentAnnotations = null;
  @ViewChild('newsInstanceView') newsInstanceView: ElementRef;
  @Output() onClearAllAnnotations: EventEmitter<any> = new EventEmitter();

  currentPeriod: TaxonomyContextPeriod = this.docProcessService.newsLabelingDefaultPeriod.getValue();
  public readonly documentTypes: Array<DocMetaDocumentType> = NewsInstancesHandlerComponent.getDocumentTypesAsArray();
  public readonly currencies: Array<DocMetaCurrency> = NewsInstancesHandlerComponent.getCurrencyTypesAsArray();
  periods: Array<TaxonomyContextPeriod> = [];
  taxonomyDataFields: Array<TaxonomyFieldTypes | string> = [];
  taxonomyDataQuantities: Array<TaxonomyQuantity> = [];
  selectedFieldValue: string = null;
  public sanityErrorMessage: string = 'There is an inconsistency between the amounts (tagged values and quantities) of different annotations for this field.';

  currentSelectedMainFieldSub = new Subscription();
  currentSelectedMarginFieldSub = new Subscription();
  currentSelectedAdjustedFieldSub = new Subscription();

  public selectedField$ = new Observable();

  ngOnInit() {
    // this.newsInstanceService.selectedTaxonomyValue = this.instanceDataNews.taxonomy_data[TaxonomyFieldTypes[TaxonomyFieldTypes.ebit]].values
    //   .find( (periodCollection: TaxonomyDataValue) => periodCollection.period === this.docProcessService.newsLabelingDefaultPeriod.getValue());

    for (let dataKey in TaxonomyFieldTypes) {
      if (!isNaN(Number(dataKey))) {
        this.newsInstanceService.fieldValueIndexes[dataKey] = 0;
        this.taxonomyDataFields.push(TaxonomyFieldTypes[dataKey]);
      }
    }
    for (let period in TaxonomyContextPeriod) {
      this.periods.push(period as unknown as TaxonomyContextPeriod);
    }
    for (let quantity in TaxonomyQuantity) {
      this.taxonomyDataQuantities.push(quantity as unknown as TaxonomyQuantity);
    }
  }

  ngAfterViewInit(): void {
    this.selectedField$ = merge(this.hotkeyHandlerService.currentSelectedMainField$, this.hotkeyHandlerService.currentSelectedMarginField$, this.hotkeyHandlerService.currentSelectedAdjustedField$);
    this.selectedField$.subscribe((field: string) => {
      if (field) {
        this.newsInstanceService.setSelectedNewsInstanceField(field);
      }
    });
  }

  get docMetaDocumentType(): typeof DocMetaDocumentType {
    return DocMetaDocumentType;
  }

  get docMetaCurrency(): typeof DocMetaCurrency {
    return DocMetaCurrency;
  }

  get instanceDataNewsTaxonomyDataKey(): typeof TaxonomyFieldTypes {
    return TaxonomyFieldTypes;
  }

  get taxonomyDataFieldColors(): typeof NewsTaxonomyDataFieldColors {
    return NewsTaxonomyDataFieldColors;
  }

  get taxonomyDataFieldClasses(): typeof TaxonomyDataFieldIndentClasses {
    return TaxonomyDataFieldIndentClasses;
  }

  get taxonomyDataContextPeriod(): typeof TaxonomyContextPeriod {
    return TaxonomyContextPeriod;
  }

  get taxonomyField(): typeof TaxonomyFieldTypes {
    return TaxonomyFieldTypes;
  }

  get taxonomyDataQuantity(): typeof TaxonomyQuantity {
    return TaxonomyQuantity;
  }

  get selectedTaxonomyDataField(): TaxonomyFieldTypes | string {
    return this.newsInstanceService.selectedTaxonomyDataField;
  }

  private static getCurrencyTypesAsArray(): Array<DocMetaCurrency> {
    const currencyTypes = [];
    for (const currency in DocMetaCurrency) {
      if (!isNaN(Number(currency))) {
        currencyTypes.push(currency as unknown as DocMetaCurrency);
      }
    }
    return currencyTypes;
  }

  private static getDocumentTypesAsArray(): Array<DocMetaDocumentType> {
    const documentTypes = [];
    for (const documentType in DocMetaDocumentType) {
      if (!isNaN(Number(documentType))) {
        documentTypes.push(documentType as unknown as DocMetaDocumentType);
      }
    }
    return documentTypes;
  }

  getQuantityModel(value: any, field: TaxonomyFieldTypes | string) {
    if (this.newsInstanceService.selectedNewsAnnotation) {
      const selectedAnnotationIndex = value.values.findIndex((value) => value.annot_id === this.newsInstanceService.selectedNewsAnnotation.annot_id);
      if (selectedAnnotationIndex > -1 && field === this.newsInstanceService.selectedTaxonomyDataField) {
        this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[this.newsInstanceService.selectedTaxonomyDataField]] = selectedAnnotationIndex;
      }
    }
    if (value.values && value.values.length && value.values[this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[field]]]) {
      return value.values[this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[field]]].quantity;
    } else {
      return value.quantity;
    }
  }

  getValueModel(value: any, field: TaxonomyFieldTypes | string) {
    if (this.newsInstanceService.selectedNewsAnnotation) {
      const selectedAnnotationIndex = value.values.findIndex((value) => value.annot_id === this.newsInstanceService.selectedNewsAnnotation.annot_id);
      if (selectedAnnotationIndex > -1 && field === this.newsInstanceService.selectedTaxonomyDataField) {
        this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[this.newsInstanceService.selectedTaxonomyDataField]] = selectedAnnotationIndex;
      }
    }

    if (value.values && value.values.length) {
      if (!value.values[this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[field]]]) {
        return value.values[value.values.length - 1].value;
      } else {
        return value.values[this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[field]]] && value.values[this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[field]]].value;
      }
    } else {
      return value.value;
    }
  }

  updateSign(sign: TaxonomySign, taxonomyKey: string, valuesIndex: number) {
    this.instanceDataNews.taxonomy_data[taxonomyKey].values[valuesIndex].altered = true;
    this.instanceDataNews.taxonomy_data[taxonomyKey].values[valuesIndex].confirmed = 'Altered';
    this.newsInstanceService.setSelectedTaxonomySign(sign);
  }

  onTaxonomyFieldClick(taxonomyDataField: TaxonomyFieldTypes | string) {
    this.newsInstanceService.setSelectedNewsInstanceField(taxonomyDataField);
    this.hotkeyHandlerService.setSelectedFieldIndex(taxonomyDataField.toString(), false);
    const index = this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[taxonomyDataField]];
    const value = this.instanceDataNews.taxonomy_data[taxonomyDataField].values.find((value) => value.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod).values[index];
    const selectedAnnotation = this.instanceDataAnnotations.annotations.find((annot) => {
      if (annot) {
        if (annot.fields[0].context.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod && annot.annot_id === (value && value.annot_id)) {
          return annot;
        }
      }
    });
    const elements = [].slice.call(document.getElementsByClassName('highlight'));
    elements.forEach((elm: HTMLElement) => elm.classList.remove('outline'));
    const elm = elements.find((elm) => elm.getAttribute('data-annotation') === JSON.stringify(selectedAnnotation));
    if (elm) {
      elm.classList.add('outline');
    }
    this.newsInstanceService.setSelectedNewsAnnotation(selectedAnnotation ? selectedAnnotation : null);
    switch (TaxonomyDataHotkeyBinding[taxonomyDataField]) {
      case 'shift':
        this.hotkeyHandlerService.currentSelectedMarginField.next(taxonomyDataField);
        break;
      case 'alt':
        this.hotkeyHandlerService.currentSelectedAdjustedField.next(taxonomyDataField);
        break;
      case 'tab':
        this.hotkeyHandlerService.currentSelectedMainField.next(taxonomyDataField);
        break;
    }
  }

  getFieldClasses(taxonomyDataField: TaxonomyFieldTypes | string) {
    if (taxonomyDataField === this.selectedTaxonomyDataField) {
      return 'selected';
    } else {
      return '';
    }
  }

  onPeriodChangeButtonClick($event: any) {
    this.newsInstanceService.setTaxonomyDataContextPeriod($event.target.value);
    this.currentPeriod = $event.target.value;
    $event.target.blur();
  }

  restoreData(restore: boolean) {
    this.docProcessCommonsService.restoreData.next(restore);
  }

  getValue(value: string) {
    const newValue = value;
    return newValue.length >= 50 ? newValue.substr(0, 50) + '\u2026' : newValue;
  }

  updateSelectedAnnotation(field: string, value: any, taxonomyKey: string, valuesIndex: number, values: TaxonomyDataValue[], self: any) {
    const optionId = value.target.selectedIndex;
    const selectedPeriod = this.newsInstanceService.selectedTaxonomyDataContextPeriod;

    this.newsInstanceService.selectedTaxonomy = this.instanceDataNews.taxonomy_data[taxonomyKey].values[valuesIndex];
    this.newsInstanceService.fieldValueIndexes[TaxonomyFieldTypes[this.newsInstanceService.selectedTaxonomyDataField]] = optionId;
    const id = values[optionId].annot_id;
    const selectedAnnotation = this.instanceDataAnnotations.annotations.find((annot) => {
      if (annot) {
        if (annot.fields[0].context.period === selectedPeriod && annot.annot_id === id) {
          return annot;
        }
      }
    });
    this.newsInstanceService.setSelectedNewsAnnotation(selectedAnnotation);
    const elements = [].slice.call(document.getElementsByClassName('highlight'));

    const elm = elements.find((elem) => elem.getAttribute('data-annotation') === JSON.stringify(selectedAnnotation));
    if (elm) {
      setTimeout(() => {
        elements.forEach((elem: HTMLElement) => elem.classList.remove('outline'));
        elm.classList.add('outline');
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.currentSelectedMainFieldSub.unsubscribe();
    this.currentSelectedMarginFieldSub.unsubscribe();
    this.currentSelectedAdjustedFieldSub.unsubscribe();
  }

  constructor(
    private utilService: UtilService,
    public newsInstanceService: NewsInstancesService,
    private docProcessCommonsService: DocProcessLegacyCommonsService,
    private hotkeyHandlerService: HotkeyHandlerService,
    private newsSanityCheckService: NewsSanityCheckService,
    private docProcessService: DocProcessService,
  ) {}

  public resetSelectedPeriodToDefault() {
    const period = this.getDefaultPeriod()
    this.newsInstanceService.setTaxonomyDataContextPeriod(period);
    this.currentPeriod = period;
  }

  public onTaxonomyQuantityChange($event, item, field) {
    item.values[this.newsInstanceService.fieldValueIndexes[this.taxonomyField[field]]].quantity = $event.target.value.split(':')[1].trim();
    item.altered = true;
    item.confirmed = 'Altered';
    this.newsInstanceService.setSelectedTaxonomyQuantity(item.values[this.newsInstanceService.fieldValueIndexes[this.taxonomyField[field]]].quantity);
  }

  doSanityCheck(annotations: TaxonomyDataValue) {
    // TODO: don't call this function with *ngIf.
    // Because this function is called on every Angular change detection run,
    // binding to a function might cause performance issues.
    // Alternative solutions: Use pipes. Angular knows response will be the same if the input is same for pure pipes,
    // so the service won't be called unless input data changes);
    // Or, call inside ngOnChanges(changes: SimpleChanges) manually with an if check; (ugly)
    // Or inside annotations subscription. (uglier)
    const isSane: boolean = this.newsSanityCheckService.checkSanity(annotations.values);
    return isSane;
  }

  clearData() {
    this.onClearAllAnnotations.emit();
  }

  private getDefaultPeriod(): TaxonomyContextPeriod {
    return this.docProcessService.newsLabelingDefaultPeriod.getValue()
  }
}
