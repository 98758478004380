import {Component, Input} from '@angular/core';

@Component({
  selector: 'con-data-entry',
  template: './data-entry.component.html',
})
export abstract class DataEntryComponent {
  @Input() instanceData;
  @Input() fieldName: string;

  protected constructor() { }

  public onValueChange(target: EventTarget, choice) {
    this.instanceData[this.fieldName].value = choice
    this.instanceData[this.fieldName].altered = true;
    this.instanceData[this.fieldName].confirmed = 'Altered';
    try {
      (target as HTMLElement)?.blur()
    }
    catch (e) { }
  }
}
