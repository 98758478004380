<con-add-new-company-report></con-add-new-company-report>
<div class="row">
  <div class="col-12">
    <div class="card mb-3">
      <div class="card-body">
        <div>
          <h5 class="card-title">Filter Report</h5>
        </div>
        <con-icon-by-name iconName="angle-right" (click)="filterExpanded = !filterExpanded" [mutations]="filterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']" class="filter-toggle"></con-icon-by-name>
        <div [ngClass]="filterExpanded ? 'd-block' : 'd-none'">
          <con-filter-report [loading]="loading" (filterUpdate)="updateFilterConfiguration($event)"></con-filter-report>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- preloader -->

  <con-loader *ngIf="loading" [showCircularLoder]=loading></con-loader>

  <con-no-data [count]="missingReports.length" message="No company reports found" *ngIf="!loading"></con-no-data>


  <!-- show missing report section after data has been loaded -->

<div class="inner-scroll" *ngIf="missingReports && !loading">

  <!--     loop missing reports -->

  <div *ngFor="let report of missingReports">
    <div class="card mb-2">
      <div class="card-header cursor-pointer" (click)="toggleExpansion(report)">
        <div class="d-flex justify-content-start">
          <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
          <span>{{report?.preFillValues?.company?.name}} / {{report?.preFillValues?.report_type | titlecase}}
            , {{report?.preFillValues?.report_date | date}}</span>
          <span *ngIf="report?.preFillValues?.company?.website" ngbTooltip="Website Link">
            <a [href]=report?.preFillValues?.company?.website class="text-white pl-10" target="_blank"> <con-icon-by-name iconName="external-link"></con-icon-by-name></a>
          </span>
          <div class="card-header-toggle ml-auto">
            <con-icon-by-name iconName="angle-right"
                              [mutations]="report?.expanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
          </div>
        </div>
      </div>
      <div class="card-body" [hidden]="!report?.expanded">
        <con-entity-form-content [entityName]="entityName" [showWhenChanged]="false"
                                 [entity]="report?.entity" [preFillValues]="report?.preFillValues"
                                 [fixedValues]="report?.fixedValues" [canDelete]="false" [initialSaveEnabled]="report?.hasLatestReport"
                                 (afterSave)="missingReportSaved(report)"
                                 toastMessage="Missing report saved successfully!">
        </con-entity-form-content>
      </div>
    </div>
  </div>

  <!-- pagination -->


  <div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!paginatedMissingReports?.total}">
    <ngb-pagination [collectionSize]="paginatedMissingReports?.total"
                    [page]="paginatedMissingReports?.current_page"
                    (pageChange)="loadMissingReports($event)"
                    [pageSize]="paginatedMissingReports?.per_page"
                    maxSize="10"
                    [rotate]="true">
    </ngb-pagination>
  </div>


</div>

