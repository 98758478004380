import { Injectable, OnDestroy } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../../settings.class';

import { ReplaySubject ,  Observable } from 'rxjs';

@Injectable()
export class FileTokenService {
    private tokenSubject: ReplaySubject<any> = new ReplaySubject<any>();
    private tokenRequest: Observable<any>;

    private loading = false;

    constructor(private http: HttpClient) {
    }

    public getToken(refresh: boolean = false) {
        if (refresh || !this.tokenRequest) {
            this.loading = true;
            this.tokenRequest = this.http.get(ApiSettings.BASE_URL + '/' + ApiSettings.FILE_TOKEN_ENDPOINT);

            this.tokenRequest.subscribe(
                    res => {
                        this.tokenSubject.next(res);
                        this.loading = false;
                    },
                    err => {
                        this.tokenSubject.error(err);
                        this.loading = false;
                    }
            );
        }
        return this.tokenSubject.asObservable();
    }
}
