import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { camelCase, snakeCase, upperCaseFirst } from 'change-case';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Task, TaskDetail, Delivery } from 'app/task-manager/typings/Typings';


@Component({
  selector: 'con-task-detail',
  templateUrl: './task-detail-view.component.html',
  styleUrls: ['./task-detail-view.component.scss']
})
export class TaskDetailViewComponent implements OnInit, OnDestroy {
  public task: Task;
  public hasTask: boolean;
  public symbols: Symbol[];
  public symbolsHeader: string[];
  public symbolsBody: any;
  public checkPassed: boolean;
  public errorMessage: string;
  public taskIsActive: boolean;
  public checkLoading: boolean;
  public taskDetail: TaskDetail;
  public config = new Subject();
  public deliveries: Delivery[];
  public loading = true;
  public actionSucceeded: boolean;
  public createdDeliveryFeedback: string;
  public createdDelivery = false;
  public editMode = 'agent-view';
  public succesfullyCreatedDelivery: boolean;
  public symbolAttributesHeaderNames: string[] = [];
  public taskSpec: any;
  public closeResult: string;
  public updateTaskSpecLoading = false;
  public updateTaskSpecResponseMessage: any;
  public symbolAttributesBody: any;
  public dropdownOptions: string[] = ['Edit agent'];
  @ViewChild('responseElm') responseElm: ElementRef;
  public reasonForCancellationOfDelivery: string;
  private taskSpecEdited: any;

  constructor(
    public taskManagerService: TaskManagerService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private utilService: UtilService,
    private modalService: NgbModal
    ) {}

  ngOnInit() {

    localStorage.getItem('task-type') === 'index' && this.dropdownOptions.push('Edit index');
    this.route.params.subscribe(param => {
      localStorage.getItem('task-type') === 'index' ? this.taskManagerService.getIndiciesTaskDetails(param.id) : this.taskManagerService.getTask(param.id);
    });

    this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: Task | any) => {
      if (response.type === 'getTask' || response.type === 'getIndiciesTaskDetails') {
        this.mapReponseDataToCorrectObjects(response.data);
        this.stopLoadingAnimation();
      }
      if (response.type === 'deactivateTask') {
        this.taskManagerService.getTask(this.task.task_id);
      }
      if (response.type === 'createMozendaDelivery') {
        if (response.data.State === 'Success') {
          this.showDeliveryAlert();
          this.taskManagerService.getTask(this.task.task_id);
          this.createdDeliveryFeedback = response.data.Result;
          this.actionApproved();
          this.stopLoadingAnimation();
        }

      }
      if (response.type === 'createManualDelivery') {
        if (response.data.State === 'Success') {
          this.showDeliveryAlert();
          this.taskManagerService.getTask(this.task.task_id);
          this.createdDeliveryFeedback = response.data.Result;
          this.actionApproved();
          this.stopLoadingAnimation();
        }
      }
      if (response.type === 'getTaskSpec') {
        this.taskSpec = response.data;
      }
      if (response.type === 'updateTaskSpec') {
        if (response.data.State === 'Success') {
          this.updateTaskSpecLoading = false;
          this.stopLoadingAnimation();
          this.updateTaskSpecResponseMessage = response.data.Result;
          this.taskManagerService.getTask(this.taskDetail.task_id);
        }
        if (response.data.State === 'Error') {
          this.updateTaskSpecLoading = false;
          this.stopLoadingAnimation();
          this.actionFailed();
          this.updateTaskSpecResponseMessage = 'Edit task failed. Exception: ' + response.data.Exception;
        }
      }
    });
    this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: any) => {
      this.actionFailed();
      this.errorMessage = err.data;
    });
  }

  ngOnDestroy() {
    this.subscriptionService.apiResponseSub.unsubscribe();
    this.subscriptionService.apiErrorSub.unsubscribe();
  }

  resetEditTask() {
    if (this.updateTaskSpecResponseMessage) {
      this.taskManagerService.getTaskSpec(this.task.task_id);
      this.updateTaskSpecResponseMessage = undefined;
    }
  }
  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  setReasonForCancellationOfDelivery(reason: string) {
    this.reasonForCancellationOfDelivery = reason;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  requestAction(action: string) {
    this.startLoadingAnimation();
    switch (action) {
        case 'activateTask': return this.taskManagerService.activateTask(this.taskDetail.task_id);
        case 'deactivateTask': {
          if (this.utilService.getConfirmation('Press OK to deactivate task. All running/pending deliveries will be canceled.')) {
            this.taskManagerService.deactivateTask(this.taskDetail.task_id);
          } else {
            this.stopLoadingAnimation();
          }
        }; break;
        case 'deleteTask': {
          if (this.utilService.getConfirmation('Do you want to delete this task')) {
              this.hasTask = false;
              this.taskManagerService.deleteTask(this.taskDetail.task_id);
          } else {
              this.stopLoadingAnimation();
          }
        }; break;
        case 'createMozendaDelivery': {
          if (this.utilService.getConfirmation('This action creates a new delivery for the task. Proceed?')) {
            this.hideDeliveryAlert();
            this.taskManagerService.createMozendaDelivery(this.taskDetail.task_id);
          } else {
            this.stopLoadingAnimation();
          }
        } break;
        case 'createManualDelivery': {
          if (this.utilService.getConfirmation('This action creates a new delivery for the task. Proceed?')) {
            this.hideDeliveryAlert();
            this.taskManagerService.createManualDelivery(this.taskDetail.task_id);
        } else {
            this.stopLoadingAnimation();
        }} break;
        case 'updateTaskSpec': {
          this.updateTaskSpecLoading = true;
          this.taskManagerService.updateTaskSpec(this.task.task_id, this.taskSpecEdited);
        } break;
    }
  }

  toggleEditMode(editMode: string) {
    switch (editMode) {
      case 'Email mappings': {
        return this.editMode = 'email-mapping';
      }
      case 'Edit agent': {
        return this.editMode = 'agent-view';
      }
      case 'Edit index': {
        return this.editMode = 'indicies-view';
      }
    }
  }

  animationHandler(handler: string) {
    switch (handler) {
      case 'stop': return this.stopLoadingAnimation();
      case 'start': return this.startLoadingAnimation();
    }
  }
  actionStateHandler(handler: string) {
    switch (handler) {
      case 'failed': return this.actionFailed();
      case 'approved': return this.actionApproved();
    }
  }
  onConfigChange(newTaskSpec: any) {
    this.taskSpecEdited = newTaskSpec;
  }

  private mapReponseDataToCorrectObjects(task: Task) {
    this.setTask(task);
    this.setTaskDetail(task);
    this.setSymbolsHeader(task);
    this.setSymbolsBody(task);
    this.setDeliveries(task.deliveries);
    this.createSymbolAttributtesTableHeader(task);
    this.setSymbolAttributesBody(task);
    this.taskManagerService.getTaskSpec(task.task_id);
    this.taskIsActive = task.task_active;
    this.hasTask = true;
    this.loading = false;
  }

  private setTask(task: Task) {
    this.task = task;
  }
  private setSymbolsHeader(task: Task) {
    if (localStorage.getItem('task-type') === 'index') {
      this.symbolsHeader = Object.keys(this.task.index_details);
    } else {
      if (task.symbols) {
        task.symbols.forEach(symbol => {
          this.symbolsHeader = Object.keys(symbol);
        });
      }
    }
  }
  private setSymbolsBody(task: Task) {
    if (localStorage.getItem('task-type') === 'index') {
      this.symbolsBody = [this.task.index_details];
    } else {
      this.symbolsBody = this.task.symbols
    }
  }
  private setDeliveries(deliveries: Delivery[]) {
    this.deliveries = deliveries;
  }
  private setTaskDetail(task: Task) {
    const taskDetail: TaskDetail = {
      name: task.name,
      task_schedule: task.task_schedule,
      dep_mask: task.dep_mask,
      task_active: task.task_active,
      task_id: task.task_id,
      last_collection_time: task.last_collection_time,
    };
    this.taskDetail = taskDetail;
  }
  private createSymbolAttributtesTableHeader(task: Task) {
    if (task.symbol_attributes) {
      task.symbol_attributes.forEach(attr => this.symbolAttributesHeaderNames = Object.keys(attr));
    }
    if (task.index_details) {
      this.symbolAttributesHeaderNames = Object.keys(task.index_details)
    }
  }
  private setSymbolAttributesBody(task: Task) {
    this.symbolAttributesBody = localStorage.getItem('task-type') === 'index' ?  task.index_details :  task.symbol_attributes;
  }

  private startLoadingAnimation() {
    this.errorMessage = '';
    this.checkPassed = undefined;
    this.checkLoading = true;
  }

  private stopLoadingAnimation() {
    this.checkPassed = undefined;
    this.checkLoading = false;
  }

  private actionFailed() {
    this.checkLoading = false;
    this.checkPassed = false;
  }

  private actionApproved() {
    this.checkLoading = false;
    this.checkPassed = true;
  }
  private hideDeliveryAlert() {
    this.createdDelivery = false;
    this.succesfullyCreatedDelivery = false;
    this.createdDeliveryFeedback = '';
  }
  private showDeliveryAlert() {
    this.succesfullyCreatedDelivery = true;
    this.createdDelivery = true;
  }
}
