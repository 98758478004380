import {Component, OnInit} from '@angular/core';
import {ProfileService} from '../../../auth/services/profile.service';

@Component({
  selector: 'con-company-report',
  templateUrl: './company-report.component.html',
  styleUrls: ['./company-report.component.scss']
})
export class CompanyReportComponent implements OnInit {

  constructor(public profile: ProfileService) {
  }

  ngOnInit() {
  }

}
