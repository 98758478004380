import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { EntitiesModule } from '../../../entities/entities.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataEntryPage } from './pages/data-entry-page.component';
import { AnnotateViewHeaderComponent } from './components/annotate-view-header.component';
import { DocProcessInstanceListComponent } from './components/doc-process-instance-list.component';
import { DocProcessAnnotateDocViewComponent } from './components/doc-process-annotate-doc-view.component';
import { DocProcessAnnotateViewTaxonomySidebarComponent } from './components/doc-process-annotate-view-taxonomy-sidebar.component';
import { DocProcessStatementViewComponent } from './components/doc-process-statement-view.component';
import { DocProcessReportDataViewComponent } from './components/doc-process-report-data-view.component';
import { DocProcessTaxonomyFieldComponent } from './components/doc-process-taxonomy-field.component';
import { FieldAnnotationsListComponent } from './components/field-annotations-list.component';
import { FieldAnnotationComponent } from './components/field-annotation.component';
import { TaxonomyFieldsListComponent } from './components/taxonomy-fields-list.component';
import { DataEntryInstanceComponent } from './components/data-entry-instance.component';
import { DocProcessCommonModule } from '../doc-process-common/doc-process-common.module';
import { TaxonomyTypeContainerComponent } from './components/taxonomy-type-container.component';
import { DocProcessAnnotateViewReportDataSidebarComponent } from './components/doc-process-annotate-view-report-data-sidebar.component';
import { FundamentalsSanityDeltaComponent } from './components/fundamentals-sanity-delta.component';
import { FundamentalsFieldPrimaryValueComponent } from './components/fundamentals-field-primary-value.component';
import { StatementNavigatorComponent } from './components/statement-navigator.component';
import { SafePipe } from './directives/safe.pipe';
import { RouterOutletCanDeactivatePluginDirective } from './directives/router-outlet-can-deactivate-plugin.directive';
import { DynamicDataEntryComponentWrapper } from './components/dynamic-data-entry-component-wrapper.component';
import { DynamicDataEntryComponentContainerComponent } from './components/dynamic-data-entry-component-container.component';
import { DynamicDataEntryDateInputComponent } from './components/dynamic-data-entry-date-input.component';
import { DynamicDataEntryIntegerInputComponent } from './components/dynamic-data-entry-integer-input.component';
import { DynamicDataEntryCurrencyInputComponent } from './components/dynamic-data-entry-currency-input.component';
import { DynamicDataEntryCheckboxInputComponent } from './components/dynamic-data-entry-checkbox-input.component';
import { DynamicDataEntryLabelingInputComponent } from './components/dynamic-data-entry-labeling-input.component';
import { LabelingTaxonomyComponent } from './components/labeling-taxonomy.component';
import { DynamicDataEntryLanguageSelectComponent } from './components/dynamic-data-entry-language-select.component';
import { DynamicSingleHorizontalSelectorComponent } from './components/dynamic-single-horizontal-selector.component';
import { DynamicMultipleHorizontalSelectorComponent } from './components/dynamic-multiple-horizontal-selector.component';
import { TaskDataEntryService } from './state-management/services/task-data-entry.service';
import { LabelingHelperService } from './services/labeling-helper.service';
import { LabelingTaxonomyToggleComponent } from './components/labeling-taxonomy-toggle.component';
import { LabelingTaxonomyButtonComponent } from './components/labeling-taxonomy-button.component';
import { DynamicClearDataModalComponent } from './components/dynamic-clear-data-modal.component';
import { DocumentsLazyLoadingService } from './services/documents-lazy-loading.service';
import { FundamentalsApiService } from './services/fundamentals-api.service';
import { DynamicDataEntryMulticlassTaxonomySelectorComponent } from './components/dynamic-data-entry-multiclass-taxonomy-selector.component';
import { MulticlassTaxonomyComponent } from './components/multiclass-taxonomy.component';
import { NewEventFormComponent } from './components/new-event-form.component';
import { CompanyEventsTableComponent } from './components/company-events-table.component';
import { CalenderEventsWidgetComponent } from './components/calender-events-widget.component';
import { MulticlassTaxonomyTagsComponent } from './components/multiclass-taxonomy-tags.component';
import { ConnectorRedirectButtonComponent } from './components/connector-redirect-button.component';
import { ConnectorIframeComponent } from './components/connector-iframe-component';
import { CounterComponent } from './components/counter.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, SharedModule, EntitiesModule, NgbModule, FormsModule, DocProcessCommonModule],
    providers: [TaskDataEntryService, LabelingHelperService, DocumentsLazyLoadingService, FundamentalsApiService],
    declarations: [
        AnnotateViewHeaderComponent,
        DocProcessAnnotateDocViewComponent,
        DocProcessInstanceListComponent,
        DocProcessAnnotateViewTaxonomySidebarComponent,
        DocProcessAnnotateViewReportDataSidebarComponent,
        DocProcessStatementViewComponent,
        DocProcessReportDataViewComponent,
        DocProcessTaxonomyFieldComponent,
        FieldAnnotationsListComponent,
        FieldAnnotationComponent,
        TaxonomyFieldsListComponent,
        DataEntryInstanceComponent,
        DataEntryPage,
        TaxonomyTypeContainerComponent,
        FundamentalsSanityDeltaComponent,
        FundamentalsFieldPrimaryValueComponent,
        StatementNavigatorComponent,
        SafePipe,
        RouterOutletCanDeactivatePluginDirective,
        DynamicDataEntryComponentWrapper,
        DynamicDataEntryComponentContainerComponent,
        DynamicDataEntryDateInputComponent,
        DynamicDataEntryIntegerInputComponent,
        DynamicDataEntryCurrencyInputComponent,
        DynamicDataEntryCheckboxInputComponent,
        DynamicDataEntryLabelingInputComponent,
        DynamicDataEntryLanguageSelectComponent,
        DynamicSingleHorizontalSelectorComponent,
        DynamicMultipleHorizontalSelectorComponent,
        LabelingTaxonomyComponent,
        LabelingTaxonomyToggleComponent,
        LabelingTaxonomyButtonComponent,
        DynamicClearDataModalComponent,
        DynamicDataEntryMulticlassTaxonomySelectorComponent,
        MulticlassTaxonomyComponent,
        CalenderEventsWidgetComponent,
        NewEventFormComponent,
        CompanyEventsTableComponent,
        MulticlassTaxonomyTagsComponent,
        ConnectorRedirectButtonComponent,
        ConnectorIframeComponent,
        CounterComponent
    ],
  exports: [DataEntryPage, AnnotateViewHeaderComponent, SafePipe, RouterOutletCanDeactivatePluginDirective]
})
export class UniversalDataEntryModule {}
