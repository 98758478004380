<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title mb-2 pb-3 pt-3">
                <div class="page-title-icon">
                    <con-icon-by-name iconName="globe"></con-icon-by-name>
                </div>
                <h3 class="mb-0">Universes</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mb-2">
                <div class="card-header">
                    Go to universe
                </div>
                <div class="card-body">
                    <con-entity-search-field
                        [entityName]="entityName"
                        (onSelect)="navigateToUniverse($event)"
                    ></con-entity-search-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card mb-2">
                <div class="card-header">
                    All universes
                </div>
                <con-loader *ngIf="loadingUser"></con-loader>
                <con-entity-list
                    [entityName]="entityName"
                    [timestamps]="false"
                    [actions]="actions"
                    (onAction)="handleEventInList($event)"
                    *ngIf="!loadingUser"
                    ></con-entity-list>
            </div>
        </div>
    </div>
</div>
