import {Component, OnInit} from '@angular/core';
import {FieldColor} from '../../kpi-and-kiid/models/field-color';
import {CurrentFields} from '../../kpi-and-kiid/models/fields';
import {AbstractFieldMetaDependencies} from "../models/FieldStatusInterface";

@Component({
  selector: 'con-instance-data-field-meta-functions',
  template: ''
})
export abstract class AbstractInstanceDataFieldMeta extends AbstractFieldMetaDependencies implements OnInit {

  constructor() { super() }

  ngOnInit() { }

  public getLowMlConfOrConfrimedFieldClass() {
    if (this.isFieldConfirmed()) { return 'checked' }
    if (this.isMlConfigLow()) { return 'warning' }
    return ''
  }
  public getLowMlConfOrConfrimedFieldIconName() {
    if (this.isFieldConfirmed()) { return 'check-circle-o' }
    if (this.isMlConfigLow()) { return 'exclamation-triangle' }
    return ''
  }
  public getFieldBackgroundColor() {
    if (this.isCurrentClass()) { return FieldColor.Current }
    return FieldColor.Previous
  }
  private isFieldConfirmed() {
      return this.instanceData[this.fieldName].confirmed
  }
  private isMlConfigLow() {
      return this.instanceData[this.fieldName].ml_conf === 'Low'
  }
  private isCurrentClass() {
    return this.currentFields.find(field => CurrentFields[this.fieldName] === field) > -1
  }
}
