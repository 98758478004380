import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileService } from 'app/auth/services/profile.service';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { EntityDescription, EntitiesService } from 'app/entities/services/entities.service';
import { CalendarEventSettings } from 'app/settings.class';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarService } from '../calendar.service';

@Component({
  selector: 'con-report-dates-form',
  templateUrl: './report-dates-form.component.html'
})
export class ReportDatesFormComponent implements OnInit {

  @Input() companyDetails: any = {};
  @Input() eventTypeId: number;
  @Output() eventCreated: EventEmitter<any> = new EventEmitter();

  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  public showCreateForm = true;
  public isLoading = false;
  public isSaving = false;
  public fixedValues: any = {}
  public entityDescription: EntityDescription;
  public reportDatesPageError: string;
  public calendarEventForm: FormGroup;
  public formErrors: string[] = [];
  public hasPermission: boolean = false;
  public permissionErrorMessage = CalendarEventSettings.CALENDAR_EVENT_CREATE_PERMISSION_ERROR;
  public defaultTimezone;

  constructor(
    public companyService: CompanyReportsService,
    private calendarService: CalendarService,
    private entityService: EntitiesService,
    private dateDateConverter: DateConverterService,
    private toaster: ToastrService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.calendarService.hasPermission('write', this.companyDetails?.company_tiers).subscribe((hasPermission) => {
      this.hasPermission = hasPermission;
    });
    this.initReportDatesPage();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private initReportDatesPage() {
    this.isLoading = true;
    combineLatest([
      this.entityService.getEntityDescriptionByEntityName(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY),
      this.profileService.getUserTimezone()
    ]).pipe(takeUntil(this.componentDestroyed$)).subscribe(
      ([entityDescription, defaultTimeZone]: [EntityDescription, string]) => {
        this.defaultTimezone = defaultTimeZone;
        this.entityDescription = entityDescription;
        this.fixedValues = this.calendarService.getPrefillValuesForReportDatesForm(entityDescription);
        this.fixedValues.owner_id = this.companyDetails.id;
        this.fixedValues.calendar_event_type_id = this.eventTypeId;
        this.initForm();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false
      })
  }

  private createCalendarEvent(payload: any) {
    this.isSaving = true;
    this.entityService.saveEntity(CalendarEventSettings.CALENDAR_EVENT_ENTITY_KEY, payload).pipe(takeUntil(this.componentDestroyed$)).subscribe(
      (response) => {
        this.isSaving = false;
        this.initForm();
        this.toaster.success('Saved successfully!', 'Calendar event');
        this.eventCreated.emit(response);
      },
      (error) => {
        if (error.isValueError()) {
          this.formErrors = this.calendarService.getFlattendErrorArray(error.data);
        } else {
          this.toaster.error('Something went wrong. Please contact the administrator.', 'Save calendar event');
        }
        this.isSaving = false;
      }
    )
  }

  public initForm() {
    const tomorrow = moment().add(1, 'day').startOf('day');
    this.calendarEventForm = new FormGroup({
      from_date: new FormControl(tomorrow.format('YYYY-MM-DD'), [Validators.required]),
    });
  }

  public expandCreateForm() {
    this.showCreateForm = !this.showCreateForm;
  }

  public getMutations(event) {
    return (!event ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']);
  }

  public submitCalendarEvent() {
    this.formErrors = [];
    if (this.calendarEventForm.invalid) return;
    const dateInCustomTimezone = moment.tz(this.calendarEventForm.value.from_date, this.defaultTimezone)
    const payload = {
      ...this.fixedValues,
      from_date: this.dateDateConverter.toEntityString(dateInCustomTimezone)
    }
    this.createCalendarEvent(payload);
  }

}

