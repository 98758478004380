import { Component, OnInit, ViewChild, Input, Output, OnChanges, AfterViewInit } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'con-editor',
  templateUrl: './editor.component.html',
})
export class EditorComponent implements OnInit, AfterViewInit, OnChanges {

  public editorOptions: JsonEditorOptions;
  @Input() data: any;
  @Input() createdMessage: any;
  @Input() showSaveButton: boolean;
  @Input() dataSub: Subject<any>
  @Output() onDataSaved = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  private editedJsonData: any;
  public failedEqualityCheckMessage: string;

  constructor() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'code';
    this.editorOptions.enableSort = false;
  }

  ngOnInit() {
    if (this.dataSub) {
      this.dataSub.subscribe(data => {
        this.data = data;
      });
    }
  }
  ngAfterViewInit() {
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
  }
  setData(data) {
    if (!data.target) {
      this.editedJsonData = data;
      this.onChange.emit(this.editedJsonData);
    }

  }
  sendData() {
    this.onDataSaved.emit(this.editedJsonData);
  }
  checkForEquality() {
    if (!this.editedJsonData) {
      this.editedJsonData = this.data;
    }
    return JSON.stringify(Object.keys(this.data.taskDetails)) === JSON.stringify(Object.keys(this.editedJsonData.taskDetails));
  }
}
