import { Component, OnInit, Input } from '@angular/core';
import { InstanceDataKiid, LANGUAGES } from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { CurrentFields } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {DocProcessService} from "../../../doc-process-common/services/doc-process.service";
import {DataFieldValidityService} from '../../../doc-process-common/services/data-field-validity.service';

@Component({
  selector: 'con-kiid-metadata-instances-handler',
  templateUrl: './kiid-metadata-instances-handler.component.html',
  styleUrls: ['./kiid-metadata-instances-handler.component.scss']
})
export class KiidMetadataInstancesHandlerComponent implements OnInit {
  @Input() instanceData: InstanceDataKiid
  @Input() currentFields: CurrentFields[]

  public DocumentTypes = [
    'key-information-document',
    'priip-key-information-document',
    'factsheet',
    'prospectus',
  ]

  constructor(
    public docProcessService: DocProcessService,
    public dataFieldValidityService: DataFieldValidityService,
  ) { }

  // get documentTypesArray(): Array<string> {
  //   return Object.keys(this.DocumentTypes).filter(key => isNaN(Number(key)))
  // }

  get documentTypesDisplayValuesArray(): Array<string> {
    return this.DocumentTypes.map(docType => this.userFriendlyName(docType))
  }

  get LANGUAGES(): Array<string> { return LANGUAGES }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  userFriendlyName(documentTypeDefinition: string): string {
    let nameMap = {
      'key-information-document' : 'KIID',
      'priip-key-information-document' : 'PRIIP_KIID',
      'factsheet' : 'Factsheet',
      'prospectus' : 'Prospectus',
    }
    return nameMap[documentTypeDefinition] ?? documentTypeDefinition
  }
}
