<div class="my-3">
    <div class="card">
        <div class="card-header cursor-pointer" (click)="isFilterExpanded = !isFilterExpanded">
            <div class="d-flex justify-content-start">
                <con-icon-by-name iconName="filter" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                <span>Filter Direkt sync events</span>
                <div class="card-header-toggle ml-auto">
                    <con-icon-by-name iconName="angle-right" [mutations]="isFilterExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
                </div>
            </div>
        </div>
        <div class="card-body" [hidden]="!isFilterExpanded">
            <ng-container *ngIf="isLoading; else searchForm">
                <con-loader></con-loader>
            </ng-container>
            <ng-template #searchForm>
                <form [formGroup]="filterForm" (ngSubmit)="submitFilter()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>Status </small></label>
                                        <select class="form-control custom-select" formControlName="handled">
                                            <option [value]="''">All</option>
                                            <option [value]="true">Handled</option>
                                            <option [value]="false">Unhandled</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>Calendar event type </small></label>
                                        <ng-container *ngIf="!selectedTypeDetails; else selectedTypeInfo">
                                            <con-entity-search-field entityName="CalendarEventType" (onSelect)="selectType($event)"></con-entity-search-field>
                                        </ng-container>
                                        <ng-template #selectedTypeInfo>
                                            <div class="alert alert-info alert-dismissible mb-0">
                                                <button type="button" class="close" aria-label="Close" (click)="clearType()">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <a class="alert-link-icon mr-1" [routerLink]="['/entity/', 'calendar_event_type', selectedTypeDetails?.id]" placement="top" container="body" [ngbTooltip]="'Go to calendar event type'">
                                                    <con-icon-by-name [iconName]="'calendar-minus-o'" [mutations]="['fw']"></con-icon-by-name>
                                                </a>
                                                <strong>{{ selectedTypeDetails?.name }}</strong>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>Event owner </small></label>
                                        <ng-container *ngIf="!selectedOwnerDetails; else selectedOwnerInfo">
                                            <con-entity-search-field entityName="Company" (onSelect)="selectOwner($event, 'company')"></con-entity-search-field>
                                            <con-entity-search-field entityName="Institution" (onSelect)="selectOwner($event, 'institution')"></con-entity-search-field>
                                        </ng-container>
                                        <ng-template #selectedOwnerInfo>
                                            <div class="alert alert-info alert-dismissible mb-0">
                                                <button type="button" class="close" aria-label="Close" (click)="clearOwner()">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <a class="alert-link-icon mr-1" [routerLink]="['/entity/', filterForm.value.owner_type, selectedOwnerDetails?.id]" placement="top" container="body" [ngbTooltip]="'Go to ' + filterForm.value.owner_type">
                                                    <con-icon-by-name [iconName]="filterForm.value.owner_type === 'company' ? 'building' : 'university'" [mutations]="['fw']"></con-icon-by-name>
                                                </a>
                                                <strong>{{ selectedOwnerDetails?.name }}</strong>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>Event </small></label>
                                        <select class="form-control custom-select" formControlName="event">
                                            <option [value]="''">All</option>
                                            <option [value]="'created'">Created</option>
                                            <option [value]="'updated'">Updated</option>
                                            <option [value]="'deleted'">Deleted</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>Direkt response code</small></label>
                                        <input type="number" class="form-control" formControlName="direkt_response_code" placeholder="Direkt response code">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label> <small>From date </small></label>
                                        <con-date-input [control]="filterForm.controls['date']" [field]="{ key : 'from_date'}" [prefillDate]="today"></con-date-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <button class="btn btn-success" type="submit" [disabled]="!filterForm.dirty || isLoadingData">Filter</button>
                            <button class="btn btn-warning ml-1" type="button"  [disabled]="!filterForm.dirty || isLoadingData" (click)="reset()">Clear</button>
                        </div>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</div>