import { Injectable } from '@angular/core';
import {ShareClassFactsheet} from '../models/Typings';

@Injectable({
  providedIn: 'root'
})
export class FactsheetService {

  constructor() { }

  public static isRowEmpty(row: ShareClassFactsheet) {
    if (!!row && !row?.value && !row?.mapping && !row?.name)
      return true
    return false
  }
}
