<div style="display: block">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeSidebarMenuTab">

    <li [ngbNavItem]="'Taxonomy'">
      <a ngbNavLink [class]="'border border-primary p-1'" >
        <small>
          Taxonomy
        </small>
      </a>
      <ng-template ngbNavContent>
        <div class="p-2">
          <div class="d-flex justify-content-between">
            <div class="form-inline mt-1 mr-auto">
              <div class="input-group">
                <div class="d-flex align-items-end justify-content-end p-0">
                  <h4>
                    <span class="badge badge-light">Data</span>
                  </h4>
                </div>
              </div>
            </div>
            <button
              *ngIf="!!companyWebsite"
              (click)="openCompanyWebsite()"
              type="button"
              class="btn btn-primary btn-sm d-flex align-items-center flex-nowrap my-2 mx-2 py-0">
              Company Website
              <con-icon-by-name class="ml-2" iconName="external-link"></con-icon-by-name>
            </button>
            <button
              *ngIf="companyId !== undefined && companyId !== null"
              (click)="openCompanyPage()"
              type="button"
              class="btn btn-primary btn-sm d-flex align-items-center flex-nowrap my-2 mx-2 py-0">
              Company
              <con-icon-by-name class="ml-2" iconName="linode"></con-icon-by-name>
            </button>
            <button
              *ngIf="companyId !== undefined && companyId !== null"
              (click)="openCalendarPage()"
              type="button"
              class="btn btn-primary btn-sm d-flex align-items-center flex-nowrap my-2 mx-2 py-0">
              Calendar
              <con-icon-by-name class="ml-2" iconName="calendar"></con-icon-by-name>
            </button>
            <button (click)="restoreData(true);"
                    class="m-1 btn-warning btn-lg cursor-pointer lang-button d-flex align-items-center flex-nowrap">
              Reload data
              <con-icon-by-name class="ml-2" iconName="refresh"></con-icon-by-name>
            </button>
          </div>
          <div class="form-group">
            <label class="ml-1 d-flex align-items-center">
              <small class="align-self-center">Is Duplicate</small>
              <input class="ml-2 align-self-center" type="checkbox" [ngModel]="instanceData.is_duplicate" (ngModelChange)="instanceData.is_duplicate = $event" name="is_duplicate" />
            </label>
          </div>

          <!--taxonomy-->
          <div class="mt-0">
            <con-topic-classification-taxonomy
              [instanceData]="instanceData"
              [highlightedFieldIndex]="highlightedFieldIndex"
              [writeAccess]="true"
            ></con-topic-classification-taxonomy>
          </div>
        </div>
      </ng-template>
    </li>

    <ng-container *ngIf="companyId !== undefined && companyId !== null">
      <li [ngbNavItem]="'Company'">
        <a ngbNavLink [class]="'border border-primary p-1'" >
          <small>
            Company
          </small>
        </a>
        <ng-template ngbNavContent>
          <iframe [src]="('/entity/company/' + companyId) | safe" class="m-0 p-0 iframe"
                  style="width: 100%; height: 75vh;"
          ></iframe>
        </ng-template>
      </li>

      <li [ngbNavItem]="'Calendar'">
        <a ngbNavLink [class]="'border border-primary p-1'" >
          <small>
            Calendar
          </small>
        </a>
        <ng-template ngbNavContent>
          <div class="my-2">
            <calendar-events-widget
            [companyId]="companyId"
            [calendarEventIds]="instanceData.calendar_event_ids"
            (onSelectionChange)="handleSelectionChange($event)"
            >
              <con-topic-classification-taxonomy
                [instanceData]="instanceData"
                [highlightedFieldIndex]="highlightedFieldIndex"
                [writeAccess]="false"
              ></con-topic-classification-taxonomy>
            </calendar-events-widget>
          </div>
        </ng-template>
      </li>
    </ng-container>
  </ul>

</div>

<div [ngbNavOutlet]="nav"></div>
