import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: null
})
export class DataFieldValidityService {
  public allowInstanceNavigation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  constructor(
    private toastr: ToastrService,
  ) { }

  public displayInvalidReportDateError(): void {
    this.toastr.error('You must enter a valid report date before saving or leaving instance.', 'Report Date Is Invalid');
  }
}
