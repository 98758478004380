import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Environment} from './env-settings.service';
import {UtilService} from './util.service';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ServerChooserService {

  public hasChangedServer: Subject<boolean> = new Subject()
  // public currentRoute: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public getBackendEnvName(): Environment {
    const fullPostfix: string = this.router.routerState.snapshot.url
    const backendEnvName: Environment = this.findBackendEnvNameFromUrl(fullPostfix)
    return backendEnvName
  }

  public getServerOptions(): Array<string> {
    let options = [
      'Production',
      'Labeling',
      'Preview',
    ]

    if (UtilService.isAdmin()) {
      options = [
        ...options,
        'Test',
        'Dev',
        'Local',
      ]
    }

    return options;
  }

  private findBackendEnvNameFromUrl(url: string): Environment { // TODO refactor me
    let backendEnvName: any = url.split('/')
    .reduce(
      (isBackendEnv: boolean | string, currentString: string) => {
        if (typeof isBackendEnv === 'string')
          return isBackendEnv;

        if (isBackendEnv === true) {
          return currentString
        }
        else if (currentString === 'doc_process')
          return true
        else
          return false
      }, false)

    if (typeof backendEnvName === 'string')
      return backendEnvName as Environment
    else
      return null
  }
}
