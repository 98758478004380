import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileService } from 'app/auth/services/profile.service';
import { featureFlagSettings } from 'app/settings.class';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private featureFlags$ = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient, private profileService: ProfileService) {
    this.initializeFeatureFlags();
  }

  private initializeFeatureFlags(): void {
    this.fetchFeatureFlags().pipe(take(1)).subscribe();
  }

  private fetchFeatureFlags(): Observable<any> {
    const params = new HttpParams()
      .set('page', '1')
      .set('per_page', '10000');
    return this.http.get(`${featureFlagSettings.BASE_URL}`, { params }).pipe(
      tap((flags: any) => {
        const featureFlags = flags?.data?.filter((flag: any) => flag.enabled || flag.enabled_for_admins);
        this.featureFlags$.next(featureFlags);
      }),
      catchError(error => {
        this.featureFlags$.next([]);
        return of({});
      })
    );
  }

  isFeaturesEnabled(features: string[]): Observable<{ [key: string]: boolean }> {
    return combineLatest([this.featureFlags$, this.profileService.isSuperUser()]).pipe(
      map(([flags, isSuperAdmin]: [any, boolean]) => {
        const featureStatus: { [key: string]: boolean } = {};
        features.forEach(feature => {
          const featureDetails = flags.find(flag => flag.key === feature);
          featureStatus[feature] = featureDetails
            ? (isSuperAdmin ? (featureDetails.enabled || featureDetails.enabled_for_admins) : featureDetails.enabled)
            : false;
        });
        return featureStatus;
      })
    );
  }
}
