import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';

import { EntitiesService } from '../../entities/services/entities.service';
import { DateConverterService } from '../../shared/services/date-converter.service';
import { UsersService } from '../../shared/services/users.service';
import { ProfileService } from '../../auth/services/profile.service';

import { ReplaySubject ,  Subscription } from 'rxjs';

import { tap, switchMap, take } from 'rxjs/operators';


import * as moment from 'moment-timezone';

import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'con-month-shower',
  templateUrl: './month-shower.component.html',
  styleUrls: ['./month-shower.component.scss']
})
export class MonthShowerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() date: any;

  private intervalSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  private subs: Subscription = new Subscription();

  public timezone: string;

  public users: any;
  public loadingUsers = false;
  public teams: any;
  public loadingTeams = false;

  private startDay: any;
  private endDay: any;
  public weeks: any;

  public loading: boolean;

  public owner = 'any';
  public assigned_to: any = 'any';
  public assigned_to_type = 'User';

  public currentUserId: any;

  constructor(private entitiesService: EntitiesService,
              private dateConverter: DateConverterService,
              private usersService: UsersService,
              private profileService: ProfileService,
              private dragulaService: DragulaService) {

                // Delete previous group
                this.dragulaService.destroy('TASKS');

                this.dragulaService.createGroup('TASKS', {
                  moves: (el, source, handle, sibling) => !el.classList.contains('no-drag')
                });

                this.subs.add(
                  this.dragulaService
                  .dropModel('TASKS')
                  .subscribe((event) => {
                    const date = moment(event.target.attributes.getNamedItem('date').value);
                    const task = event.item;
                    if (!date.isSame(task.deadline)) {
                      const newDate = task.deadline.clone();
                      newDate.date(date.date());
                      newDate.month(date.month());
                      newDate.year(date.year());
                      const data = {
                        'id': task.id,
                        'updated_at': task.updated_at,
                        'deadline': this.dateConverter.toEntityString(newDate)
                      };
                      this.entitiesService.saveEntity('Task', data)
                                          .subscribe(task_data => {
                                            this.updateTask(task_data);
                                          }, err => {
                                            console.log(err);
                                          })
                    }

                  })
                );
              }

  ngOnInit() {
    this.initializeTimezone();
    this.subs.add(
      this.usersService.getUsers()
                       .pipe(tap(() => this.loadingUsers = true))
                       .subscribe(
                         users => {
                           this.users = users;
                           this.loadingUsers = false;
                         }
                       )
    );

    this.subs.add(
      this.usersService.getTeams()
                       .pipe(tap(() => this.loadingTeams = true))
                       .subscribe(
                         teams => {
                           this.teams = teams;
                           this.loadingTeams = false;
                         }
                       )
    );

    this.subs.add(
      this.profileService.getUser()
                         .subscribe(user => {
                            this.currentUserId = user.id;
                            this.setAssignedTo(user.id, 'User');
                         })
    );

    this.subs.add(
      this.intervalSubject
          .pipe(tap(() => (this.loading = true)))
          .pipe(switchMap(dates => this.loadTasks(dates)))
          .subscribe(
            result => {
              this.generateWeeks(result.data);
              this.loading = false;
            },
            err => console.log(err)
          )
        );
  }

  setAssignedTo(id: any, type: string) {
    this.assigned_to = id;
    this.assigned_to_type = type;

    this.refresh();
  }

  setOwner(id: any) {
    this.owner = id;

    this.refresh();
  }

  getOwner() {
    return this.owner;
  }

  getAssignedToType() {
    return this.assigned_to_type;
  }

  getAssignedToId() {
    return this.assigned_to;
  }

  ngOnChanges() {
    // Set date to start of the month
    this.calculateStartDay();

    this.calculateEndDay();
  }

  refresh() {
    this.intervalSubject.next({
      startDay: this.startDay,
      endDay: this.endDay
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  loadTasks(dates: any) {
    this.loading = true;
    const from = dates.startDay.clone();
    from.subtract(1, 'days');
    const to = dates.endDay.clone();
    to.add(1, 'days');

    const params = {
      deadline: [
        'f:geq:' + from.format('yyyy-MM-DD'),
        'f:leq:' + to.format('yyyy-MM-DD')
      ]
    };

    if (this.assigned_to !== 'any') {
      params['assigned_to_id'] = this.assigned_to;
    }
    if (this.assigned_to_type !== 'any') {
      params['assigned_to_type'] = this.assigned_to_type;
    }
    if (this.owner !== 'any') {
      params['owner_id'] = this.owner;
    }
    return this.entitiesService.searchEntities(
      'Task',
      params,
      {
        page: 1,
        order_by: 'deadline',
        order_asc: true,
        per_page: 10000
      }
    );
  }

  calculateStartDay() {
    this.startDay = this.date.clone();
    this.startDay.date(1);
  }

  calculateEndDay() {
    this.endDay = this.startDay.clone();
    this.endDay.endOf('month');
  }

  updateTask(task: any) {
    task.deadline = moment(task).tz(this.timezone);

    let found = false;
    let w = 0;
    this.weeks.forEach(week => {
      if (!found) {
        let d = 0;
        week.forEach(date => {
          let t = 0;
          date.tasks.forEach(_t => {
            if (_t.id === task.id) {
              this.weeks[w][d].tasks[t] = task;
              found = true;
            }
            t++;
          })
          d++;
        });
      }
      w++;
    })
  }

  generateWeeks(tasks: any) {
    this.weeks = [];
    this.startDay.tz(this.timezone);
    this.endDay.tz(this.timezone);

    let date = this.startDay.clone();
    date.day('Monday');
    let previousDate = date;
    let week = [];
    while (
      date.isBefore(this.endDay, 'date') ||
      date.isSame(previousDate, 'isoWeek')
    ) {
        if (!date.isSame(previousDate, 'isoWeek')) {
            this.weeks.push(week);
            week = [];
        }
        week.push({
            date,
            tasks: []
        });
        previousDate = date;

      date = date.clone();
      date.add(1, 'day');
    }
    this.weeks.push(week);

    tasks.forEach(task => {
      task.deadline = moment(task.deadline).tz(this.timezone);
    });

    let currentWeek = 0;
    let currentDay = 0;
    tasks.forEach(task => {
      while (
        currentWeek < this.weeks.length &&
        task.deadline.isAfter(
          this.weeks[currentWeek][currentDay].date,
          'date'
        )
      ) {
        currentDay++;
        if (currentDay > this.weeks[currentWeek].length - 1) {
          currentDay = 0;
          currentWeek++;
        }
      }
      if (currentWeek < this.weeks.length) {
        this.weeks[currentWeek][currentDay].tasks.push(task);
      }
    });
  }

  isHighlightedMonth(date: any) {
    return date.isSame(this.date, 'month');
  }

  isToday(date: any) {
    return date.isSame(moment(), 'date');
  }

  private initializeTimezone() {
    this.profileService.getUserTimezone().pipe(take(1)).subscribe((timezone) => {
      this.timezone = timezone;
    })
  }
}
