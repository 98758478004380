import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialTableComponent} from './components/material-table/material-table.component';
import {LoaderComponentSmall} from './components/loader/loader-small.component';
import {SharedModule} from '../../../shared/shared.module';
import {EntitiesModule} from '../../../entities/entities.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {DocProcessProjectView} from './pages/doc-process-project/doc-process-project-view.component';
import {ServerChooserComponent} from './components/server-chooser/server-chooser.component';
import {ClearDataModalComponent} from './components/clear-data-modal/clear-data-modal.component';
import { DatePickerComponentComponent } from './components/date-picker-component.component';
import {FormsModule} from '@angular/forms';
import { InstanceDataFieldWrapperComponent } from './components/instance-data-field-wrapper/instance-data-field-wrapper.component';
import { WideTextboxDataEntryComponent } from './components/wide-textbox-data-entry/wide-textbox-data-entry.component';
import { ShareClassesComponent } from './components/share-classes/share-classes.component';
import { FlagIconComponent } from './components/flag-icon.component';
import { DatePickerComponent } from "./components/date-picker.component";

@NgModule({
  declarations: [
    MaterialTableComponent,
    LoaderComponentSmall,
    DocProcessProjectView,
    ServerChooserComponent,
    ClearDataModalComponent,
    DatePickerComponent,
    DatePickerComponentComponent,
    InstanceDataFieldWrapperComponent,
    WideTextboxDataEntryComponent,
    ShareClassesComponent,
    FlagIconComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntitiesModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
  ],
  providers: [
  ],
  exports: [
    MaterialTableComponent,
    LoaderComponentSmall,
    ServerChooserComponent,
    ClearDataModalComponent,
    DatePickerComponentComponent,
    InstanceDataFieldWrapperComponent,
    WideTextboxDataEntryComponent,
    ShareClassesComponent,
    DatePickerComponent,
  ]
})
export class DocProcessCommonModule { }
