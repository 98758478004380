import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EntitiesService } from 'app/entities/services/entities.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarService } from '../calendar.service';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { ProfileService } from 'app/auth/services/profile.service';

@Component({
  selector: 'con-direkt-sync-status',
  templateUrl: './direkt-sync-status.component.html',
  styleUrls: ['./direkt-sync-status.component.scss']
})
export class DirektSyncStatusComponent implements OnInit, OnDestroy {

  constructor(
    private toaster: ToastrService,
    private entityService: EntitiesService,
    private calendarService: CalendarService,
    private profileService: ProfileService
  ) { }

  private componentDestroyed = new Subject<void>()

  public isLoading = false;
  public syncEventList: any;
  public currentPage = 1;
  private filterValues = {}
  public eventExpandList: number[] = []
  public snapshotExpandList: number[] = []
  public currentTimezone;

  ngOnInit(): void {
    this.initializeTimezone()
    this.getSyncList()
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next()
    this.componentDestroyed.complete()
  }

  getSyncList(): void {
    this.isLoading = true
    let params = new HttpParams();
    params = this.entityService.setSearchParams(params, this.filterValues);
    params = params.append('page', this.currentPage.toString());
    this.calendarService.getDirektSyncList(params).pipe(takeUntil(this.componentDestroyed)).subscribe((response) => {
      this.syncEventList = response
      this.isLoading = false
      this.eventExpandList = []
    },
    (error) => {
      this.isLoading = false
    }
  )}

  toggleAccordion(index: number, event: any): void {
    if(!event?.calendar_event && !event.request_body && !event.direkt_response_body)
        return
    if (this.eventExpandList.includes(index)) {
      this.eventExpandList = this.eventExpandList.filter((item) => item !== index)
      this.snapshotExpandList = this.snapshotExpandList.filter((item) => item !== index)
    } else {
      this.eventExpandList.push(index)
    }
  }

  toggleSnapshot(index: number): void {
    if (this.snapshotExpandList.includes(index)) {
      this.snapshotExpandList = this.snapshotExpandList.filter((item) => item !== index)
    } else {
      this.snapshotExpandList.push(index)
    }
  }

  pageChanged(event: any): void {
    this.currentPage = event
    this.eventExpandList = []
    this.getSyncList()
  }

  copyToClipboard(data: any, key: string, event?: Event): void {
    if (event) 
      event.stopPropagation();
    const dataToCopy = key === 'snapshot' ? JSON.stringify(data?.calendar_event) : data[key];
    navigator.clipboard.writeText(dataToCopy);
    this.toaster.success(`Information successfully copied to clipboard.`);
  }

  filterEvents(event: any): void {
    this.currentPage = 1
    this.filterValues = event
    this.getSyncList()
  }

  openCalendarEvent(eventId: number, event: Event): void {
    event.stopPropagation()
    window.open('/entity/calendar_event/' + eventId, '_blank')
  }

  initializeTimezone(): void {
    this.profileService.getUserTimezone().pipe(takeUntil(this.componentDestroyed)).subscribe((response) => {
      this.currentTimezone = response
    })
  }

}