export enum DynamicDataEntryComponentTypeEnum {
  TabComponents = 'tab-multi-components',
  ListOfComponents = 'page-multi-components',
  RowOfComponents = 'horizontal-multi-components',
  LabelingComponent = 'fundamentals-taxonomy',
  DateSelectorComponent = 'date-selector',
  IntegerSelectorComponent = 'integer-selector',
  ConnectorCurrencySelectorComponent = 'connector-currency-selector',
  CheckboxComponent = 'checkbox',
  SingleHorizontalSelectorComponent = 'enum-single-horizontal-selector',
  MultiHorizontalSelectorComponent = 'enum-multi-horizontal-selector',
  LanguageSelectorComponent = 'connector-language-selector',
  MulticlassTaxonomySelector = 'multiclass-taxonomy-selector',
  CompanyCalendarWidget= 'company-calendar-widget',
  ConnectorRedirectButton = 'connector-redirect-button',
  ConnectorIframe= 'connector-iframe'
}
