import { Injectable } from '@angular/core';

import { map, take } from 'rxjs/operators';
import { SaveTask, SetTaskId } from '../state-management/actions';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: null,
})
export class DynamicDataEntryPageService {
  constructor(private store: Store, private route: ActivatedRoute) {
    this.route.params
      .pipe(
        map((params) => parseInt(params.task_id)),
        take(1)
      )
      .subscribe((taskId) => {
        this.store.dispatch(new SetTaskId(taskId));
      }); // TODO add a destructor to this service and clear everything in the state within that destructor.
    // we also need a new action in the state to do that. or you can provide the store in this page component,
    // so that the store instance gets removed when user moves out of the page.
    // but that breaks the single source of truth principle of redux.
  }

  public saveData(instanceIdsToSubmit) {
    this.store.dispatch(new SaveTask(instanceIdsToSubmit));
  }
}
