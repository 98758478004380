import {Component, HostListener, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {DocProcessService} from '../../doc-process-common/services/doc-process.service';
import {FundamentalsApiService} from '../services/fundamentals-api.service';
import {DataEntryTaxonomyDetails} from '../models/data-entry-taxonomy-details';
import {DataEntryInstanceComponent} from '../components/data-entry-instance.component';
import {FundamentalsAnnotation, InstanceDocument} from '../../kpi-and-kiid/models/fields';
import {DocProcessInstanceListComponent} from '../components/doc-process-instance-list.component';
import {Task, taskInstancesToInstanceIds$} from '../models/Task';
import {DynamicDataEntryPageService} from '../services/dynamic-data-entry-page.service';
import {TaskDataEntryState} from '../state-management/states';
import {Select, Store} from '@ngxs/store';
import {FetchTaskDataEntryState} from '../state-management/actions';

@Component({
  selector: 'dp-data-entry-page',
  providers: [DynamicDataEntryPageService],
  template: `
    <doc-process-annotate-view-header
      class="col-12"
      (onSaveData)="saveData()"
      [(updatingInstanceData)]="updatingInstanceData"
    ></doc-process-annotate-view-header>

    <div class="row" *ngIf="taskInstances$ | async as taskInstances">
      <doc-process-instance-list
        #instanceListComponent
        [instanceTableData]="taskInstances"
        [focusedInstanceIndex]="focusedInstanceIndex"
        class="col-2 px-1"></doc-process-instance-list>

      <ng-container *ngFor="let instance of (taskInstances)?.instances; let i = index">
        <con-data-entry-instance
          #instanceComponent
          [hidden]="(focusedInstanceIndex | async) !== i"
          [hide]="(focusedInstanceIndex | async) !== i"
          [instanceIndex]="i"
          [instanceId]="instance.instance_id"
          style="display: contents;"
        >
        </con-data-entry-instance>
      </ng-container>
    </div>

    <ng-template #loader>
      <div class="card col-10">
        <div class="card-body">
          <con-loader></con-loader>
        </div>
      </div>
    </ng-template>
  `,
})
export class DataEntryPage<T extends DataEntryTaxonomyDetails>  {
  public focusedInstanceIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  @ViewChildren('instanceComponent') instanceComponents: Array<DataEntryInstanceComponent>;
  @ViewChild('instanceListComponent') instanceListComponent: DocProcessInstanceListComponent;
  @Select(TaskDataEntryState) taskDataEntryState$: Observable<any>;
  @Select(TaskDataEntryState.selectTaskInstances) taskInstances$: Observable<Task>;


  get updatingInstanceData(): boolean {
    return this.fundamentalsApiService.updatingInstanceData.getValue()
  };
  set updatingInstanceData(val) {
    this.fundamentalsApiService.updatingInstanceData.next(val)
  };

  constructor(
    router: Router,
    route: ActivatedRoute,
    private docProcessService: DocProcessService,
    public fundamentalsApiService: FundamentalsApiService,
    public dynamicDataEntryPageService: DynamicDataEntryPageService,
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) { // return false to interrupt refresh via F5, true to normal behavior.
    if (this.isUnsavedData())
      return false // interrupt leaving
    else
      return true // allow leaving
  }

  // consolidateInstances(): [any, {instances: any}] {
  //   let consolidatedObject = {  }
  //   let allInstancesData = {}
  //   for (let instanceComponent of this.instanceComponents) {
  //     let [annotations, instanceData] = instanceComponent.consolidateInstance()
  //     allInstancesData[instanceComponent.instanceService.instanceId.getValue()] = instanceData
  //
  //     consolidatedObject[instanceComponent.instanceService.instanceId.getValue()] = {
  //       instance_id: instanceComponent.instanceService.instanceId.getValue(),
  //       annotations: annotations
  //     }
  //   }
  //
  //   const updateInstanceDataParams: {instances: any} = {instances: allInstancesData}
  //
  //   return [consolidatedObject, updateInstanceDataParams]
  // }

  saveData() {
    // const [submittableData, updateInstanceDataEndpointParams] = this.consolidateInstances()
    let instanceIdsToSubmit = this.instanceListComponent.getSelectedInstanceIds().reduce((prev, curr) => {
      prev[curr] = true
      return prev
    }, {})

    this.dynamicDataEntryPageService.saveData(instanceIdsToSubmit)

    // DocProcessService.filterInstancesBySubmissionCheckbox(submittableData, instanceIdsToSubmit)
    // DocProcessService.filterInstancesBySubmissionCheckbox(updateInstanceDataEndpointParams?.instances, instanceIdsToSubmit)
    // this.fundamentalsApiService.submitTaskJsonAnnotations(submittableData)
    // this.fundamentalsApiService.submitInstanceDataUpdate(updateInstanceDataEndpointParams)
  }

  protected isUnsavedData(): boolean {
    return this.docProcessService.unsavedData.getValue()
  }
}
