import { Component, OnInit, OnDestroy } from '@angular/core';

import { ProfileService } from './../services/profile.service';
import { ApiSettings } from '../../settings.class';

import { SubscriptionLike as ISubscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'con-profile-handler',
    templateUrl: './profile-handler.component.html'
})

export class ProfileHandlerComponent implements OnInit, OnDestroy {
    public user: any;
    public hasUser: boolean;
    public loading: boolean;
    private profileSubscription: ISubscription;

    constructor(private profileService: ProfileService,
                private authService: AuthService,
                private router: Router) {}

    ngOnInit() {
        this.loading = true;
        this.profileSubscription = this.profileService
                                        .userData$
                                        .subscribe(res => {
                                            this.loading = false;
                                            this.user = res;
                                            this.hasUser = true;

                        if (this.authService.RedirectUrl) {
                            const url = this.authService.RedirectUrl;
                            this.authService.RedirectUrl = '';
                            this.router.navigateByUrl(url);
                        }
                                        },
                                        err => {
                                            this.hasUser = false;
                                        });
    }

    logout() {
        this.profileService.logout();
    }

    ngOnDestroy() {
        this.profileSubscription.unsubscribe();
    }
}
