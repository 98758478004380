<div class="p-2">
  <div class="form-inline mb-3">
    <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">Language</span></label>
    <div class="input-group p-1">

      <div [ngStyle]="{'background-color': getFieldBackgroundColor('Language') }"
           class="d-flex align-items-center justify-content-end p-0">
        <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass('Language')"
                          [iconName]="getLowMlConfOrConfrimedFieldIconName('Language')"></con-icon-by-name>
        <button
          *ngFor="let language of languages"
          style="border:none;"
          [class]="instanceData.Language.value === languageDefinitions[language] ? 'm-1 btn-primary btn-lg cursor-pointer lang-button' : 'm-1 btn-disabled lang-button btn-lg cursor-pointer'"
          (click)="instanceData.Language.value = handleSRRI(instanceData.Language.value,  $event.target.value) ? $event.target.value : null; instanceData.Language.altered = true; instanceData.Language.confirmed = 'Altered';  $event.target.blur() "
          [value]="languageDefinitions[language]"
        >
          {{ languageDefinitions[language] }}
        </button>
      </div>

    </div>
  </div>
  <div class="form-inline mb-3">
    <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">PublicationDate</span></label>
    <div [ngStyle]="{'background-color' : getFieldBackgroundColor('PublicationDate') }" class="input-group p-1">
      <div class="d-flex align-items-center justify-content-end col-12">
        <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass('PublicationDate')"
                          [iconName]="getLowMlConfOrConfrimedFieldIconName('PublicationDate')"></con-icon-by-name>
        <con-date-picker-component
          [dateObject]="instanceData.PublicationDate"
        ></con-date-picker-component>
      </div>
    </div>
  </div>
  <div class="form-inline mb-3">
    <label class="my-1 mr-2 d-none d-sm-block"><span class="badge badge-light">ISIN</span></label>
    <div [ngStyle]="{'background-color' : getFieldBackgroundColor('ISIN')}" class="input-group p-1">
      <div class="d-flex align-items-center justify-content-end col-12 p-0">
        <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass('ISIN')"
                          [iconName]="getLowMlConfOrConfrimedFieldIconName('ISIN')"></con-icon-by-name>
        <input
          *ngIf="instanceData.ISIN"
          [(ngModel)]="instanceData.ISIN.value"
          type="text"
          class="form-control smaller-input"
          placeholder="Ongoing charges date"
          aria-label="Ongoing charges date"
          (ngModelChange)="instanceData.ISIN.altered = true; instanceData.ISIN.confirmed = 'Altered'" >
      </div>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (activeIdChange)="activeIdChange($event)">
    <li *ngFor="let tableName of FactsheetTables"
        [ngbNavItem]="tableName" >
      <a ngbNavLink
         [ngStyle]="{'background-color' : getFieldBackgroundColor(tableName)}"
         [class]="'border border-primary p-1 ' + (tableName === activeNavId ? 'bg-primary text-white' : '')" >
        <small>
          <con-icon-by-name [class]="getLowMlConfOrConfrimedFieldClass(tableName)"
                            [iconName]="getLowMlConfOrConfrimedFieldIconName(tableName)"
                            [hidden]="!getLowMlConfOrConfrimedFieldIconName(tableName)"
          ></con-icon-by-name>
          {{tableName}}
        </small>
      </a>
      <ng-template ngbNavContent>
        <div [ngStyle]="{'background-color' : getFieldBackgroundColor(tableName)}" class="p-1">
          <div class="p-0">
            <con-factsheet-input-table [tableData]="instanceData[tableName]"></con-factsheet-input-table>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
