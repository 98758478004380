
import {distinct, map} from 'rxjs/operators';
import {Component, Input, OnChanges, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { DateConverterService } from '../services/date-converter.service';
import { EntitiesService } from 'app/entities/services/entities.service';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';



@Component({
    selector: 'con-day-input',
    templateUrl: './day-input.component.html',
    styleUrls: ['./day-input.component.scss']
})

export class DayInputComponent implements OnChanges, OnInit, OnDestroy {
    @Input() control: UntypedFormControl;
    @Input() resetKey: string;
    @Input() entityForm: UntypedFormGroup;
    @Input() field: any;
    @Input() reverseDates: boolean = false;
    @Output() updated = new EventEmitter<boolean>();

    private defaultDate: any;
    public formDate: UntypedFormGroup;
    public resetSub: Subscription;
    public editing = false;

    public availableMonths: any = moment.months();

    constructor(
        private dateConverter: DateConverterService,
        private service: EntitiesService) {}

    ngOnInit() {
        this.resetSub = this.service.resetDayInput.subscribe( data => {
            if (data === this.resetKey) {
                this.editing = false;
            }
        })
    }
    ngOnDestroy() {
        this.resetSub.unsubscribe();
    }
    ngOnChanges() {
        if (this.control.value !== '') {
            this.defaultDate = moment(this.control.value, 'yyyy-MM-DD');
        } else {
            this.defaultDate = moment();
        }

        this.formDate = new UntypedFormGroup({
            year: new UntypedFormControl(this.defaultDate.year()),
            month: new UntypedFormControl(this.defaultDate.format('MMMM')),
            date: new UntypedFormControl(this.defaultDate.date())
        }, {
            updateOn: 'blur'
        });

        this.dateUpdated()
            .subscribe(
                res => {
                    if (!res.error) {
                        this.control.patchValue(res.date);
                        this.control.markAsDirty();
                        this.control.markAsTouched();
                    } else {
                        this.control.setErrors({
                            'dateError': true
                        });
                    }
                }
            );
    }

    dateSelected() {
        return this.control.value !== null && this.control.value !== '';
    }

    toggleEdit() {
        this.editing = !this.editing;

        this.formDate.controls['year'].patchValue(this.formDate.controls['year'].value);

        if(!this.editing){
          this.updated.emit(true);
        }
    }

    reset() {
        this.editing = false;
        this.control.patchValue(null);
        this.control.markAsDirty();
        this.control.markAsTouched();
        this.updated.emit(true);
    }

    dateUpdated() {
        return  this.formDate
                .valueChanges.pipe(
                map(date => {
                    try {
                        const d = moment();
                        const done = false;

                        ['year',
                        'month',
                        'date'].forEach(key => {
                            if (date[key] === null) {
                                throw new TypeError(key + ' is not valid');
                            }
                            d.set(key, date[key]);
                        });

                        return {
                            'error': false,
                            'date': d.format('yyyy-MM-DD')
                        }

                    } catch (e) {
                        return {
                            'error': true,
                            'date': null
                        }
                    }
                }),
                distinct(), );
    }
    setDate(months: number, key: string) {
        const start_date_val =  this.entityForm.get(key).value;
        let start_date;
        if (start_date_val) {
          start_date = moment(start_date_val);
        } else {
          start_date = moment();
        }
        const end_date = key ==='from_date' ? moment(start_date).add(months, 'M').subtract(1, 'd') : moment(start_date).add(1, 'd').add(months, 'M') ;
        this.control.patchValue(end_date.format('yyyy-MM-DD'));
        this.formDate.patchValue({
            'year': end_date.year(),
            'month': end_date.format('MMMM'),
            'date': end_date.date(),
            'hour': end_date.hour(),
            'minute': end_date.minute(),
            'second': end_date.second()
        })
    }
    isToDate() {
        if (this.field.key === 'to_date') {
            return true;
        }
        return false;
    }
    isFromDate() {
      if (this.field.key === 'from_date') {
        return true;
      }
      return false;
    }
    markControlAsDirty() {
      this.control.markAsDirty();
    }
}
