import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DynamicDataEntryComponentOptions} from '../models/dynamic-data-entry-component-options';
import {DynamicDataEntryComponentInterface} from '../models/dynamic-data-entry-component-interface';

@Injectable({
  providedIn: null
})
export class ComponentOptionsService {
  public componentOptions: BehaviorSubject<DynamicDataEntryComponentOptions> = new BehaviorSubject<DynamicDataEntryComponentOptions>(null);
  private component: BehaviorSubject<DynamicDataEntryComponentInterface> = new BehaviorSubject<DynamicDataEntryComponentInterface>(null);

  constructor() { }

  setComponent(component: DynamicDataEntryComponentInterface) {
    this.component.next(component)
  }

  getComponent(): DynamicDataEntryComponentInterface {
    return this.component.getValue()
  }

}
