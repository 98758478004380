import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'con-layout',
    templateUrl: './layout.component.html'
})

export class LayoutComponent implements OnInit {
    private counts: any;

    constructor() {}

    ngOnInit() {

    }
}
