import {Component, OnInit} from '@angular/core';
import {CompanyReportsService} from '../../services/company-reports.service';
import {CompanyReportData, PaginatedCompanyReport} from '../../models/PaginatedCompanyReport';

import {take} from 'rxjs/operators';
import {ProfileService} from '../../../auth/services/profile.service';
import {MLSendStatus} from '../../models/MLSendStatus';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'con-company-check',
  templateUrl: './company-check.component.html',
  styleUrls: ['./company-check.component.scss']
})
export class CompanyCheckComponent {
  checkCompanyReports: CompanyReportData[] = [];
  paginatedCheckCompanyReports: PaginatedCompanyReport;
  loading = true;
  entityName = 'CompanyReport';
  MLSendStatus = MLSendStatus;
  filterConfiguration = {
    report_type: 'annual',
    report_date_from: null,
    report_date_to: null,
    order_by: 'updated_at',
    page: 1,
    exchange_ids:''
  }
  filterExpanded = true;
  constructor(public reportService: CompanyReportsService, public profile: ProfileService) {
  }

  loadCheckCompanyReports(pageNumber: number){
    this.loading = true;
    this.filterConfiguration.page = pageNumber;
    const filterKeysWithValue = Object.fromEntries(Object.entries(this.filterConfiguration).filter(([_, v]) => !!v === true));

    this.reportService.getCheckReports(filterKeysWithValue).pipe(take(1)).subscribe((checkedCompanyReports: PaginatedCompanyReport) => {

      this.paginatedCheckCompanyReports = checkedCompanyReports;

      // Process data, Expanded key is added to control accordion

      this.checkCompanyReports = this.paginatedCheckCompanyReports.data.map((data: CompanyReportData) => {
        return {...data, expanded: true}
      });

      this.loading = false;
    }, (error) => {
      console.error(error);
      this.loading = false;
    })
  }

  toggleExpansion(report: CompanyReportData) {
    report.expanded = !report.expanded;
  }

  //
  companyReportSaved(companyReport: CompanyReportData) {

    // if after saving report data is not locked state, return
    if (!companyReport.locked) {
      return;
    }

    const index = this.checkCompanyReports.findIndex((report: CompanyReportData) => report.id === companyReport.id);

    if (index !== -1) {
      this.checkCompanyReports.splice(index, 1);
    }

    // reload data if the array data is empty after all the splicing

    if (this.checkCompanyReports.length === 0) {
      this.loadCheckCompanyReports(1);
    }

  }

  updateFilterConfiguration(filter: any) {
    this.filterConfiguration.report_date_from = filter.fromDate;
    this.filterConfiguration.report_date_to = filter.toDate;
    this.filterConfiguration.report_type = filter.reportType;
    this.filterConfiguration.exchange_ids = filter.exchange_ids;
    this.loadCheckCompanyReports(1);
  }
}
