import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Pipe, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'
import { UtilService } from 'app/task-manager/services/util.service';
import { DeliveryDetail, Job, Task, TaskDetail } from 'app/task-manager/typings/Typings';




@Component({
  selector: 'con-delivery-detail',
  templateUrl: './delivery-detail-view.component.html',
  styleUrls: ['./delivery-detail-view.component.scss'],
})
export class DeliveryDetailViewComponent implements OnInit, OnDestroy {
  public deliveryDetail: DeliveryDetail;
  private newObj: any;

  public dataTypes = [];
  public fieldEditedSub = new Subject();
  public errMessage: string;
  public failedDataTypeMsg: string;
  public failedDataTypeCheck = false;
  public jobTableHeader = [];
  public jobsTableBody: Job[] = [];
  public deliveryDetailTableBody: string[] = [];
  public deliverDetailTableHeader: string[] = [];
  public deliveryHeader: string[] = [];
  public deliveryBody: string[] = [];
  public srcDoc: any;
  public responseMessage: string;
  public isPending: boolean;
  public showAlert: boolean;
  public submitDataDelivery: boolean;
  public isEditable = false;
  public closeResult: string;
  public dropdownOptions = [];
  public task: Task;
  public isHTMLView = true;
  public taskDetail: TaskDetail;
  public isIndicesTaskType = false;
  public previousDataBody = []
  public previousDataHeader = []
  public newDataModel = true;
  public hasScrapedHTML: boolean;
  private deliveryIdToCancel: number;
  private reasonForCancellationOfDelivery = 'Holiday';
  @ViewChild('scrapedHtml') scrapedHtml: ElementRef
  @ViewChild('cardHeader') cardHeader: ElementRef;

  constructor(
    private router: Router,
    private taskManagerService: TaskManagerService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async param => {
      this.taskManagerService.getDelivery(param.id);
    });

    this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: DeliveryDetail | any) => {
      this.errMessage = '';
      if (response.data) {
        if (this.utilService.isJsonString(response.data)) {
          const responseJson = JSON.parse(response.data);
          if (responseJson.State === 'Error') {
            this.errMessage = responseJson.Exception;
            return;
          }
        }
      }

      switch (response.type) {
        case 'DeliveryDetail' : {
          this.deliveryBody = [];
          this.setDeliveryDetail(response.data);
          this.getTask(response.data);
          this.setDetailState(response.data);
          this.createDetailsTableBody(response.data);
          this.createDetailsTableHeader();
          this.createJobsTableBody(response.data.jobs);
          this.createJobsTableHeader();
          if (!this.deliveryDetail.agent_html_view) {
            this.hasScrapedHTML = false;
          }
          if (this.deliveryDetail.agent_html_view) {
            this.hasScrapedHTML = true;
            this.taskManagerService.getScrapedMozendaHTML(this.deliveryDetail.delivery_id);
          }
          if (response.data.cancellation_options) {
            this.dropdownOptions = response.data.cancellation_options;
          }
          if (!response.data.index_constituents) {
            this.createDataTypes();
            this.createDeliveryHeaderData();
            this.createDeliveryBodyData();
            // this.deliveryDetail.data = {data: this.deliveryDetail.data};
            this.isIndicesTaskType = false;
          } else {
            this.isIndicesTaskType = true;
          }

        } break;
        case 'html': {
          return !response.data ? this.hasScrapedHTML = false : (this.srcDoc = this.domSanitizer.bypassSecurityTrustHtml(response.data));
        }
        case 'updateDataDelivery': {
          this.errMessage = '';
          this.failedDataTypeMsg = '';
          this.showAlert = true;
          this.submitDataDelivery = false;
          this.taskManagerService.getDelivery(this.deliveryDetail.delivery_id);
          return this.responseMessage = 'Saved data';
        }
        case 'submitDataDelivery': {
          this.errMessage = '';
          this.failedDataTypeMsg = '';
          this.showAlert = true;
          this.submitDataDelivery = true;
          return this.responseMessage = 'Submitted data';
        }
        case 'cancelDelivery': {
          this.taskManagerService.getDelivery(response.data.Return.delivery_id);
        } break;
        case 'getIndiciesTaskDetails': {
          this.task = response.data;
          const taskDetail: TaskDetail = {
            name: this.task.name,
            task_schedule: this.task.task_schedule,
            dep_mask: this.task.dep_mask,
            task_active: this.task.task_active,
            task_id: this.task.task_id,
            last_collection_time: this.task.last_collection_time,
          };
          this.taskDetail = taskDetail;
        } break;
        case 'getIndicesMozendaData': {
          const replaceDeletion = this.replaceAll(response.data, `<tr status="deletion">`, `<tr status='deletion' style='background: #ffcccc'>`);
          const replaceAddition = this.replaceAll(replaceDeletion, `<tr status="addition">`, `<tr status='addition' style='background: #d7fcb1'>`);
          const html = replaceAddition;
          // this.srcDoc = this.domSanitizer.bypassSecurityTrustHtml(html);
          this.cardHeader.nativeElement.innerHTML = html;
        }break;
      }
    });
    this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: string) => {
      this.errMessage = err['data'];
    });
  }

  ngOnDestroy() {
    this.subscriptionService.apiResponseSub.unsubscribe();
  }

  refreshDelivery() {
    if (this.deliveryDetail) {
      this.taskManagerService.getDelivery(this.deliveryDetail.delivery_id);
    }
  }
  refreshScrapedHtml() {
    if (this.deliveryDetail.agent_html_view) {
      this.taskManagerService.getScrapedMozendaHTML(this.deliveryDetail.delivery_id);
    }
  }

  replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  requestAction(action: string) {
    this.showAlert = false;
    if (this.failedDataTypeCheck) {
      return;
    }
    switch (action) {

      case 'getDeliveryDetail': return this.taskManagerService.getDelivery(this.deliveryDetail.delivery_id);
      case 'updateDataDelivery': {
        this.attachDataObjectToDelivery();
        this.taskManagerService.updateDataDelivery(this.deliveryDetail.delivery_id, this.deliveryDetail.data);
      }break;
      case 'submitDataDelivery': {
        this.attachDataObjectToDelivery();
        this.taskManagerService.submitDataDelivery(this.deliveryDetail.delivery_id, this.deliveryDetail.data);
      }break;
      case 'cancelDelivery': return this.taskManagerService.cancelDelivery(this.deliveryIdToCancel, this.reasonForCancellationOfDelivery);
      case 'getIndicesMozendaData': return this.taskManagerService.getIndicesMozendaData(this.deliveryDetail.delivery_id);
    }
  }
  attachDataObjectToDelivery() {
    this.deliveryDetail.data = {data: this.deliveryDetail.data}
  }
  private createDeliveryHeaderData() {
    if (this.deliveryDetail.symbol_attributes) {
      const headData = Object.keys(this.deliveryDetail.symbol_attributes);
      headData.unshift('Title');
      headData.unshift('');
      this.deliveryHeader = headData;
    }
  }
  private createDeliveryBodyData() {
    if (this.deliveryDetail.data) {
      const bodyArr = [];
      const bodyData = Object.keys(this.deliveryDetail.data);
      bodyData.forEach(key => {
        let obj: any = {}
        Object.keys(this.deliveryDetail.data[key]).forEach(k => {
          const keyCopy = {key: key, titles: this.deliveryDetail['symbol_titles'][key]}
          obj = {...keyCopy, ...this.deliveryDetail.data[key]}
        })
        bodyArr.push(obj);
      });
      this.deliveryBody = bodyArr
    }
  }

  getTask(deliveryDetail: DeliveryDetail) {
    this.taskManagerService.getIndiciesTaskDetails(deliveryDetail.task_id)
  }

  createDataTypes() {
    if (this.deliveryDetail.symbol_attributes) {
      this.dataTypes = Object.keys(this.deliveryDetail.symbol_attributes).map(key => this.deliveryDetail.symbol_attributes[key]);
      this.dataTypes.unshift('');
      this.dataTypes.unshift('');
    }
  }

  onChange(data: any) {
    this.newObj = {};
    this.showAlert = false;
    data.forEach((item, idx) => {
      const obj = {}
      Object.keys(item).forEach((key, index) => {
        if (index > 0) {
          obj[key] = item[key]
        }
      })
      this.newObj[item['key']] = obj;
    })
    this.deliveryDetail.data = this.newObj;
  }

  dataTypeCheck(check: any) {
    this.errMessage = '';
    this.responseMessage = '';
    if (check === true) {
      this.failedDataTypeCheck = false;
    } else {
      this.failedDataTypeCheck = true;
      this.failedDataTypeMsg = check;
    }
  }

  setDeliveryIdToCancel(object: any) {
    this.deliveryIdToCancel = object.delivery_id;
  }

  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  setReasonForCancellationOfDelivery(reason: string) {
    this.reasonForCancellationOfDelivery = reason;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  private setDeliveryDetail(deliveryDetail: DeliveryDetail) {
    this.deliveryDetail = deliveryDetail;
    if (deliveryDetail['previous_data']) {
      const headData = Object.keys(this.deliveryDetail.symbol_attributes);
      headData.unshift('Title');
      headData.unshift('');
      this.previousDataHeader = headData;


      const bodyArr = [];
      const bodyData = Object.keys(deliveryDetail['previous_data']);
      bodyData.forEach(key => {
        let obj: any = {}
        Object.keys(deliveryDetail['previous_data'][key]).forEach(k => {
          const keyCopy = {key: key, titles: this.deliveryDetail['symbol_titles'][key]}
          obj = {...keyCopy, ...deliveryDetail['previous_data'][key]}
        })
        bodyArr.push(obj);
      });
      this.previousDataBody = bodyArr
    }
  }
  private setDetailState(deliveryDetail: DeliveryDetail) {
    deliveryDetail.delivery_state === 'Pending' ? this.isPending = true : this.isPending = false;
  }
  private createDetailsTableBody(deliveryDetail: DeliveryDetail) {
    this.deliveryDetailTableBody = [];
    const deliveryDetailObj: any = {
      task_id: deliveryDetail.task_id,
      delivery_id: deliveryDetail.delivery_id,
      name: deliveryDetail.name,
      dep_mask: deliveryDetail.dep_mask,
      scheduled_time: deliveryDetail.scheduled_time,
      scraping_url: deliveryDetail.scraping_url,
      feed: deliveryDetail.feed,
      delivery_state: deliveryDetail.delivery_state,
    }
    this.deliveryDetailTableBody.push(deliveryDetailObj)
  }

  private createDetailsTableHeader() {
    this.deliverDetailTableHeader = [
      'Task ID',
      'Delivery ID',
      'Name',
      'Dep mask',
      'Scheduled time',
      'Scraping URL',
      'Feed',
      'Delivery state'
    ];
  }

  changeModel() {
    this.newDataModel = !this.newDataModel;
  }

  private createJobsTableBody(jobs: Job[]) {
    this.jobsTableBody = jobs.map(job => {
      return ({
          job_id: job.job_id,
          job_state: job.job_state,
          job_step: job.job_step,
          m_job_id: job.m_job_id,
          job_start_time: moment(job.job_start_time).format('MM-DD HH:mm:ss'),
          job_completed_time: job.job_completed_time ?  moment(job.job_completed_time).format('MM-DD HH:mm:ss') : null,
          error_msg: job.error_msg
        }) as Job;
    })
  }
  private createJobsTableHeader() {
    this.jobTableHeader = [
      'JobID',
      'Job state',
      'Job Step',
      'Mozenda JobID',
      'Start',
      'Completed',
      'Error msg'
    ]
  }

}
