<div class="row mt-15">
    <div class="col-12">
      <div class="card">
        <div class="card-header" (click)="addCalendarEventExpanded = !addCalendarEventExpanded">
          New Event
          <con-icon-by-name class="pull-right" iconName="angle-right" [mutations]="addCalendarEventExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
        </div>
        <div class="card-body"  *ngIf="addCalendarEventExpanded">
          <con-custom-entity-form-content
            entityName="CalendarEvent"
            [fixedValues]="{ revised_at: now }"
            [except]="['direkt_id']"
            [emptyAfterSave]="true"
            [hasFixedCalendarValues]="true"
            [preFillValues]="{ from_date: tomorrow }"
            (afterSave)="calendarEventCreated($event)"
            [handleFullDayEvent]="true"
            toastMessage="Added successfully!"
            [enableRecurringEvent]="true"></con-custom-entity-form-content>
        </div>
      </div>

    </div>
  </div>
  <div class="row mt-15">
      <div class="col-12">
          <div class="card mb-2">
              <div class="card-header">
                  Go to company
              </div>
              <div class="card-body">
                  <con-entity-search-in-relation parentEntityName="CompanyTier" [parentId]="tierId" relationName="companies" [disabled]="!profile.can(profile.getPermissionName('CompanyTier', 'show', 'companies'))"
                      (onSelect)="navigateToCompany($event)"></con-entity-search-in-relation>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-12">
          <div class="card mb-2">
              <div class="card-header">
                  Go to institution
              </div>
              <div class="card-body">
                  <con-entity-search-in-relation parentEntityName="InstitutionTier" [parentId]="institutionTierId" relationName="institutions" [disabled]="!profile.can(profile.getPermissionName('InstitutionTier', 'show', 'institutions'))"
                      (onSelect)="navigateToInstitution($event)"></con-entity-search-in-relation>
              </div>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-12">
          <div class="card">
              <div class="card-header" (click)="monthExplorerExpanded = !monthExplorerExpanded" >
                  Month explorer
                <con-icon-by-name class="pull-right" iconName="angle-right" [mutations]="monthExplorerExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
              </div>
              <div class="card-body" *ngIf="monthExplorerExpanded">
                  <con-month-explorer></con-month-explorer>
              </div>
          </div>
      </div>
  </div>