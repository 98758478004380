import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import { UtilService } from 'app/task-manager/services/util.service';
import { Task, TaskDetail } from 'app/task-manager/typings/Typings';

@Component({
  selector: 'con-agent-edit-view',
  templateUrl: './agent-edit-view.component.html',
})
export class AgendEditViewComponent implements OnInit, OnDestroy {

    public task: Task;
    public step = 1;
    public steps: number[] = [];
    public loading = true;
    public confCopy: any;
    public errorMessage: string;
    public actionSucceeded = false;
    public configPlaceholder: any;
    public feedbackFromApiResponse: string;
    public showFeedbackFromApiAlert = false;

    @Input() taskDetail: TaskDetail;
    @Input() checkPassed: boolean;
    @Input() checkLoading: boolean;

    @Output() actionHandler = new EventEmitter();
    @Output() loadingAnimationHandler = new EventEmitter();

    @ViewChild('responseElm') responseElm: ElementRef;

    constructor( public taskManagerService: TaskManagerService, private subscriptionService: SubscriptionService, private utilService: UtilService) {}

    ngOnInit(): void {
        this.taskManagerService.getConfig(this.taskDetail.task_id);
        this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: Task | any) => {
            switch (response.type) {
                case 'getMozendaData':
                case 'getStepData':
                case 'getMapColumns':
                case 'testConfig': {
                    if (this.utilService.isJsonString(response.data)) {
                        this.handleResponseStateMessage(response.data);
                    } else {
                        this.showHTMLResponseFromAPI(response.data);
                        this.stopAnimation('approved');
                    }
                } break;
                case 'dimCheck':
                case 'addAgent':
                case 'deleteAgent': {
                    this.handleResponseStateMessage(response.data);
                } break;
                case 'jsonPlaceholder': {
                    this.configPlaceholder = response.data;
                    this.confCopy = response.data;
                    if (response.data.transformations) {
                        this.setTransformationSteps(response.data.transformations.length);
                    }
                    this.stopAnimation('approved');
                } break;
                case 'getConfig': {
                    this.configPlaceholder = response.data.config;
                    this.confCopy = response.data.config;
                    if (response.data) {
                        if (response.data.config) {
                            if (response.data.config.transformations) {
                                this.setTransformationSteps(response.data.config.transformations.length);
                            }
                        }
                    }
                    this.stopAnimation('approved');
                } break;
            }
        });

        this.subscriptionService.apiErrorSub = this.taskManagerService.apiError.subscribe((err: any) => {
            this.actionHandler.emit('failed');
            this.errorMessage = err.data;
            this.feedbackFromApiResponse = err.data.Traceback
        })
    }

    ngOnDestroy(): void {
        this.subscriptionService.apiErrorSub.unsubscribe();
        this.subscriptionService.apiResponseSub.unsubscribe();
    }

    requestAction(action: string) {
        this.responseElm.nativeElement.innerHTML = '';
        this.startAnimation();
        this.actionSucceeded = false;
        this.feedbackFromApiResponse = '';
        this.showFeedbackFromApiAlert = false;
        switch (action) {
            case 'getMozendaData': return this.taskManagerService.getMozendaData(this.confCopy);
            case 'getStepData': return this.taskManagerService.getStepData(this.confCopy, this.step);
            case 'dimCheck': return this.taskManagerService.dimCheck(this.confCopy);
            case 'getMapColumns': return this.taskManagerService.getMapColumns(this.confCopy);
            case 'testConfig': return this.taskManagerService.testConfig(this.confCopy, this.taskDetail.task_id)
            case 'addAgent': return this.taskManagerService.addAgent(this.taskDetail.task_id, this.confCopy)
            case 'deleteAgent': return this.taskManagerService.deleteAgent(this.taskDetail.task_id, this.confCopy);
            case 'getPlaceholderConfig': return this.taskManagerService.getPlaceholderConfig(this.taskDetail.task_id);
        }
    }

    setTransformationStep(step: number) {
        this.step = 1;
        this.step = step;
    }

    onConfigChange(config) {
        this.confCopy = config
        this.setTransformationSteps(this.confCopy.transformations.length)
    }

    private handleResponseStateMessage(response: any) {
        this.showFeedbackFromApiAlert = true;
        const STATE = this.utilService.isJsonString(response) ? JSON.parse(response).State : response.State
        switch (STATE) {
            case 'Error': {
                this.stopAnimation('failed');
                const EXCEPTION_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Exception : response.Exception;
                this.actionSucceeded = false;
                this.feedbackFromApiResponse = EXCEPTION_MESSAGE;
            }; break;
            case 'Success': {
                this.stopAnimation('approved');
                const RESULT_MESSAGE = this.utilService.isJsonString(response) ? JSON.parse(response).Result : response.Result;
                this.actionSucceeded = true;
                this.feedbackFromApiResponse = RESULT_MESSAGE;
            } break;
            default: {
                this.showHTMLResponseFromAPI(response);
            }
        }
    }

    private startAnimation() {
        this.loadingAnimationHandler.emit('start');
    }

    private stopAnimation(state: string) {
        this.actionHandler.emit(state);
        this.loadingAnimationHandler.emit('stop');
    }

    private showHTMLResponseFromAPI(responseContainer: string) {
        this.responseElm.nativeElement.innerHTML = responseContainer;
    }

    private setTransformationSteps(size: number) {
        this.steps = [];
        for (let i = 0; i < size; i++) {
          this.steps.push(i + 1);
        }
    }
}
