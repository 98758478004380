import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { camelCase, snakeCase, upperCaseFirst } from 'change-case';
import { prettyCase } from 'app/shared/utilities.classes';
import { TaskManagerService } from '../services/task-manager.service';
import { ProfileService } from 'app/auth/services/profile.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'con-task-manager-layout',
  templateUrl: './task-manager-layout.component.html',
  styleUrls: ['./task-manager-layout.component.scss']
})
export class TaskManagerLayoutComponent implements OnInit {
  public headerName: string;
  public isAuthorized: boolean;
  public localEnv = !environment.stage && !environment.production;
  private tempIndex: number;
  public index: number;
  public selectedValue: any;

  public serverPool = [
    {value: 'local'},
    {value: 'stage'},
    {value: 'production_europe'},
    {value: 'production_usa'},
  ]

  public taskManagerDescriptions = [
    {taskName: 'Create', taskIcon: 'pencil-square'},
    {taskName: 'Tasks', taskIcon: 'gears'},
    {taskName: 'Symbols', taskIcon: 'tags'},
    {taskName: 'Deliveries', taskIcon: 'truck'}
  ]

  constructor(private taskManagerService: TaskManagerService, public profile: ProfileService) {
    this.taskManagerService.headerNameSub.subscribe(name => {
      this.headerName = name;
    })
    const index =  this.serverPool.findIndex(server => server.value === localStorage.getItem('task_manager_server'))

    // checks if we have server name stored in local storage
    if (index >= 0) { this.tempIndex = index }

    // if not, return production_europe from server pool
    else { this.tempIndex = 2 }
  }

  isLoading() {
    return this.profile.isLoading();
  }

  ngOnInit() {
    this.index = this.tempIndex;
    this.selectedValue = this.serverPool[this.index].value
  }

  change(changeMode: boolean) {
    if (localStorage.getItem('dep')) {
      localStorage.removeItem('dep');
    }
    if (localStorage.getItem('category-deliveries')) {
      localStorage.removeItem('category-deliveries');
    }
    if (localStorage.getItem('index')) {
      localStorage.removeItem('index');
    }
    localStorage.setItem('localMode', changeMode.toString());
    location.reload();
  }

  setServer(server) {
    localStorage.setItem('task_manager_server', server)
    location.reload()
  }

}
