import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../../../settings.class';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Component({
  selector: 'con-common-comment',
  templateUrl: './common-comment.component.html',
  styleUrls: ['./common-comment.component.scss']
})
export class CommonCommentComponent implements OnInit {

  @Input() commentInfo: null | string;
  @Input() updateUrl: string;
  @Input() id: number;

  @Output() commentUpdated = new EventEmitter();
  commentControl: FormControl;

  initialCommentValue: string;
  saveEnabled: boolean = false;

  constructor(private http: HttpClient, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.initialCommentValue = this.commentInfo || '';
    this.commentControl = new FormControl(this.commentInfo);
    this.commentControl.valueChanges.subscribe(() => {
      this.saveEnabled = this.commentControl.dirty;
    });
  }

  saveComment(): void {
    const comment = this.commentControl.value;
    const updateEndpoint = ApiSettings.BASE_URL + '/' + this.updateUrl + '/' + this.id;
    this.http.patch(updateEndpoint, { comment }).pipe(take(1)).subscribe({
      next: response => {
        this.toaster.success("Updated comment successfully!");
        this.commentUpdated.next(response);
        this.initialCommentValue = this.commentControl.value;
        this.saveEnabled = false;
        this.commentControl.markAsPristine();
      },
      error: err => {
        this.toaster.error('Error saving comment');
        console.error(err);
      }
    });
  }
  revertComment(): void {
    this.commentControl.setValue(this.initialCommentValue);
    this.commentControl.markAsPristine();
    this.saveEnabled = false;
  }
}
