import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {EntitiesService} from '../../../../entities/services/entities.service';
import {Observable} from 'rxjs';
import { ApiService } from '../../doc-process-common/services/api.service';
import { Query } from '../../doc-process-common/services/methods.service';

@Component({
  selector: 'calendar-events-widget',
  template: `
    <ng-content></ng-content>
    <ng-container *ngIf="companyEvents$ | async as companyEvents">
      <ng-container *ngIf="company$ | async as company">
        <con-dp-company-events-table
          [companyId]="companyId"
          [companyEvents]="companyEvents"
          [calendarEventIds]="calendarEventIds"
          (onSelectionChange)="handleSelectionChange($event)"
        ></con-dp-company-events-table>
        <con-dp-new-event-form
          [company]="company"
          [ownerType]="'Company'"
        ></con-dp-new-event-form>
      </ng-container>
    </ng-container>
  `,
})
export class CalenderEventsWidgetComponent implements OnChanges {
  @Input() companyId: number;
  @Input() calendarEventIds: Array<number>;
  @Output() onSelectionChange: EventEmitter<Array<any>> = new EventEmitter()

  public company$: Observable<any>;
  public companyEvents$: any;

  constructor(
    private entitiesService: EntitiesService,
    private apiService: ApiService,
  ) { }

  ngOnChanges(changes) {
    if (changes?.companyId) {
      this.company$ = this.entitiesService.getEntityById('Company', this.companyId);
      this.companyEvents$ = this.apiService.httpGet({params: this.companyId}, Query.GetCalendarEvents);
    }
  }

  handleSelectionChange($event: Array<any>) {
    this.onSelectionChange.emit($event)
  }
}
