
import {of as observableOf,  BehaviorSubject ,  Observable ,  SubscriptionLike as ISubscription } from 'rxjs';

import {tap, switchMap} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { EntitiesService } from '../../entities/services/entities.service';
import { ProfileService } from '../../auth/services/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'con-conversation',
    templateUrl: './conversation.component.html'
})

export class ConversationComponent implements OnChanges, OnDestroy {
    @Input() subjectId: number;
    @Input() subjectType: string;

    @Output() afterAdd: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();

    private page = 1;
    private user: any;
    private page$: BehaviorSubject<number> = new BehaviorSubject<number>(this.page);
    public loading = true;
    public saving = false;
    public comments: any;
    public messageForm: UntypedFormGroup;
    public formErrors: any;
    public generalErrors: any;

    public commentsSubscription: ISubscription;

    constructor(private service: EntitiesService,
                public profile: ProfileService,
                private formBuilder: UntypedFormBuilder,
                private toastr: ToastrService) {}

    ngOnChanges() {
        this.loading = true;
        this.createMessageForm();
        this.commentsSubscription = this.profile
                                        .getUser().pipe(
                                        switchMap(user => {
                                            if (user !== null) {
                                                this.user = user;
                                                return this.getCommentsObserver()
                                            }
                                            return observableOf(false);
                                        }))
                                        .subscribe(data => {
                                            if (data) {
                                                this.comments = data;
                                                this.loading = false;
                                            }
                                        });
    }

    private createMessageForm() {
        this.messageForm = this.formBuilder.group({
            message: ['', Validators.required]
        });
    }

    addComment() {
        const comment = {
            message: this.messageForm.value.message,
            user_id: this.user.id,
            subject_id: this.subjectId,
            subject_type: this.subjectType
        }

        this.saving = true;
        this.service.saveEntity('Comment', comment)
                    .subscribe(res => {
                        this.comments.data.unshift(res);
                        this.afterAdd.emit(res);
                        this.saving = false;
                        this.messageForm.reset();
                        this.toastr.success('Comment added!', 'Task');
                    }, errors => {
                        this.saving = false;
                        this.formErrors = errors.fields;
                        this.generalErrors = errors.general;
                        if (errors.data && errors.data.message) {
                            this.toastr.error(errors.data.message, 'Task');
                        } else {
                            this.toastr.error('Sorry, some error occurred!', 'Task')
                        }
                    });
    }

    getCommentsObserver() {
        return this.page$.pipe(
                    tap(() => {
                        this.loading = true;
                    }),
                    switchMap(page => {
                        return this.service
                                    .searchRelation(this.subjectType,
                                                    this.subjectId,
                                                    'comments',
                                                    {},
                                                    page,
                                                    'created_at',
                                                    false);
                    }), );
    }

    refresh() {
        this.page$.next(1);
    }

    ngOnDestroy() {
        this.commentsSubscription.unsubscribe();
    }
}
