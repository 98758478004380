import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { InstanceDataValue } from '../../kpi-and-kiid/models/Typings';
import { IdentifierModel } from '../../doc-process-common/models/identifier-model';
import { InstanceService } from '../services/instance.service';
import { SetComponentData } from '../state-management/actions';
import { Store } from '@ngxs/store';
import { TaskDataEntryState  } from '../state-management/states';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'con-dynamic-data-entry-date-input-component',
  template: `
      <ng-container *ngIf="component.component_type === DynamicDataEntryComponentTypeEnum.DateSelectorComponent">
          <div class="form-group">
              <con-date-picker
                      [dateObject]="dateObject$ | async"
                      (dateEmitter)="onDateValidationAssessment($event)"
              ></con-date-picker>
          </div>
      </ng-container>
  `,
})
export class DynamicDataEntryDateInputComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;

  public dateObject$: Observable<InstanceDataValue>;
  public isDateValid: boolean;

  constructor(
    public instanceService: InstanceService,
    private store: Store,
  ) {
  }

  get DynamicDataEntryComponentTypeEnum() {
    return DynamicDataEntryComponentTypeEnum
  }

  ngOnInit(): void {
    this.dateObject$ = this.store.select<InstanceDataValue>(TaskDataEntryState.selectComponentData<InstanceDataValue>(this.instanceService.getInstanceId(), this.component))
      .pipe(
        map((dateValue: any) => {
            return {
              value: dateValue
            } as InstanceDataValue
          }
        )
      )
  }

  public onDateValidationAssessment($event: [boolean, IdentifierModel, InstanceDataValue]): void {
    const instanceDate: string = $event[2].value;
    this.isDateValid = $event[0];

    if (this.isDateValid === false) {
      return
    }

    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, instanceDate))
  }
}
