import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { MatchSettings } from '../../settings.class';

@Component({
    selector: 'con-inconsistent-resolver',
    templateUrl: './inconsistent-resolver.component.html'
})

export class InconsistentResolverComponent implements OnChanges {
    @Input() inconsistent: any;
    @Output() afterFix: EventEmitter<any> = new EventEmitter<any>();
    public companies: any;
    public loading: boolean;
    constructor(private http: HttpClient) {}

    ngOnChanges() {
        this.companies = [];
        this.inconsistent.groups.forEach(g => {
            this.companies.push(g.company);
        });
    }

    matchWith(company: any) {
        this.loading = true;
        this.http.post(
                    MatchSettings.BASE_URL + '/' + MatchSettings.MATCH_ENDPOINT,
                    { isin: this.inconsistent.isin, company_id: company.id }
                )
                 .subscribe(res => {
                     this.afterFix.emit(this.inconsistent);
                     this.loading = false;
                 }, err => {
                     this.loading = false;
                 })
    }
}
