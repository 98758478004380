import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EstimatesService } from '../../services/estimates.service';
import { EstimatesSettings } from 'app/settings.class';
import { EntitiesService } from 'app/entities/services/entities.service';
import { CalendarService } from 'app/calendar/calendar.service';
import { ToastrService } from 'ngx-toastr';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'con-consenus-snapshot',
  templateUrl: './consenus-snapshot.component.html',
  styleUrls: ['./consenus-snapshot.component.scss']
})
export class ConsenusSnapshotComponent implements OnInit {

  @Input() modules: any[];
  @Input() periodId: number;
  @Input() consensusId: number;
  @Input() isSurveyLocked: boolean;
  @Input() currency: any;
  @Output() figureSaved: EventEmitter<any> = new EventEmitter();

  public modulesArray: any[] = [];
  public maskOptions: any = EstimatesSettings.VALUE_MASK;
  private collapsedNodes = [];
  private fieldValidators: any;
  private fieldsTobeConverted = ['mean', 'median', 'high', 'low'];

  constructor(
    private estimateService: EstimatesService, 
    private entityService: EntitiesService, 
    private calendarService: CalendarService,
    private toaster: ToastrService,
    private validationService: ValidatorConversionService
  ) {}

  ngOnInit() {
    this.entityService.getFieldsObjectByEntityName('ConsensusFigure').pipe(take(1)).subscribe((response) => {
      this.fieldValidators = this.validationService.getValidatorsForFields(response);
    })
    this.obtainFlattenedModules(this.modules, 0).forEach(module => {
      this.modulesArray.push(this.createModuleObject(module));
    });
  }

  private createModuleObject(module) {
    return {
      id: module.consensus_figure_id || null,
      line_id: module.line_id,
      name: module.name,
      mean: this.estimateService.applyMultiplier(module.mean, module?.quantityMultiplier, true).toString(),
      median: this.estimateService.applyMultiplier(module.median, module?.quantityMultiplier, true).toString(),
      high: this.estimateService.applyMultiplier(module.high, module?.quantityMultiplier, true).toString(),
      low: this.estimateService.applyMultiplier(module.low, module?.quantityMultiplier, true).toString(),
      contributors_count: module.contributors_count,
      level: module.level || 0,
      parentId: module.parentId || null,
      isExpanded: true,
      hasChildren: module.hasChildren,
      alias: module.alias || null,
      quantityMultiplier: module.quantityMultiplier || 1,
      currency: module?.currency || null,
      currency_id: module?.currency?.id || null,
      newCurrency: module?.currency || null,
      formControls: {},
      loaders: {},
      formErrors: {},
    };
  }

  private obtainFlattenedModules(modules, level = 0, parentId = null) {
    let flatModules = [];
    for (let module of modules) {
      module.level = level;
      module.parentId = parentId;
      flatModules.push(module);
      if (module.children) {
        module.hasChildren = true;
        flatModules.push(...this.obtainFlattenedModules(module.children, level + 1, module.line_id));
      }
    }
    return flatModules;
  }

  public toggleExpand(module: any) {
    module.isExpanded = !module.isExpanded;
    if (!module.isExpanded) {
      return;
    }
    const rowsToBeCollapsed = [];
    this.collapsedNodes.push(module.line_id);
    this.modulesArray.forEach(control => {
      let parentId = control.parentId;
      while (parentId) {
        if (parentId === module.line_id && this.collapsedNodes.includes(control.line_id)) {
          control.isExpanded = false;
          rowsToBeCollapsed.push(control);
          break;
        }
        parentId = this.modulesArray.find(c => c.line_id === parentId)?.parentId;
      }
    });
  }

  public shouldShowRow(module: any) {
    let parentId = module.parentId;
    while (parentId != null) {
      const parentControl = this.modulesArray.find(control => control.line_id === parentId);
      if (parentControl?.isExpanded === false) {
        return false;
      }
      parentId = parentControl?.parentId || null;
    }
    return true;
  }

  public hasChildren(hasChildren: any) {
    return !!hasChildren;
  }

  public levelClass(module: any) {
    return `level-${module.level}`;
  }

  public toggleEditMode(module: any, status: boolean, field: string) {
    if(status) {
      delete module.formErrors[field];
      module.formControls[field] = new FormControl(module[field], this.fieldValidators[field]);
    } else {
      delete module.formControls[field];
      if(field === 'currency_id') {
        module.newCurrency = module.currency;
      }
    }
  }

  public saveField(module: any, field: string) {
    if(module.formControls[field].invalid) return;
    let payload = {
      id: module.id || undefined,
      [field]: this.fieldsTobeConverted.includes(field) ? this.estimateService.applyMultiplier(module.formControls[field].value, module?.quantityMultiplier, false) : module.formControls[field].value,
    };
    if(!module.id){
      payload['taxonomy_line_id'] = module.line_id;
      payload['consensus_id'] = this.consensusId;
      payload['survey_period_id'] = this.periodId;
    }
    module.loaders[field] = true;
    this.entityService.saveEntity('consensus_figure', payload).subscribe((response) => {
      module[field] = this.fieldsTobeConverted.includes(field) ? this.estimateService.applyMultiplier(response[field], module?.quantityMultiplier, true).toString() : response[field];
      module.id = response.id;
      module.loaders[field] = false;
      this.toggleEditMode(module, false, field);
      if(field === 'currency_id') {
        module.currency = response?.currency;
        module.newCurrency = response?.currency;
        this.figureSaved.emit({module: module, field: 'currency', value: module.newCurrency});
      } else {
        this.figureSaved.emit({module: module, field: field, value: response[field]});
      }
    },
    (error) => {
      if(error.isValueError()) {
        module.formErrors[field] = this.calendarService.getFlattendErrorArray(error.data);
      } else {
        this.toaster.error('Something went wrong. Please contact the administrator.', 'Save cconsensus figure');
      }
      module.loaders[field] = false;
    });
  }

  public clearCurrency(module: any) {
    module.newCurrency = null;
    module.formControls['currency_id'].setValue(null);
    module.formControls['currency_id'].markAsDirty();
  }

  public selectCurrency(currency: any, module: any) {
    module.newCurrency = currency;
    module.formControls['currency_id'].setValue(currency.id);
    module.formControls['currency_id'].markAsDirty();
  }
}
