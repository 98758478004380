<!-- prettier-ignore -->
<div class="card ml-3 mt-5 mb-4 mr-4">
  <div class="card-body">
    <markdown ngPreserveWhitespaces>
      # Doc Processing Help Page
      ## Fundamentals
      ### Project Page

      You can create tasks on this page by selecting instances at the \`Instances\` table and clicking \`Create
      Validation Task\` on the right top corner.

      Or you can navigate to an existing task by clicking on the id of the task you want to enter into.

      ### Task Page

      Using the \`Default taxonomy type\` widget on the left, you can select the tab that you will view by default.
      This should be handy when you are only doing, for example, Period KPIs.

      By selecting instance(s) at the \`Instances\` table, you can move them out of the task by clicking on the \`Move
      out selected instances\` button or reject them for good by clicking on the \`Reject selected instances\` button.

      \`Submit data\` button submits the entire task to the ML services, regardless which instances are selected. You
      should click this button when all the work is final on the task.

      By clicking \`Start validation\`, you will be directed to the validation page.

      ### Validation Page

      There are multiple buttons on the top of the statement page. Those are:

      #### Compelete

      Indicates whether the statement is fully annotated or not. If it is on the \`Report\` tab, indicates whether the
      report is fully annotated or not.

      #### Quantity

      Indicates the quantity of the statement table. Default: designated by the classic connector.

      #### Decimal

      Used to switch between comma (\`,\`), dot (\`.\`) and Auto mode. Auto mode does not always work correctly.
      When it doesn't, user is required to manually select the decimal seperator.

      #### Delta

      Enables and disables the delta calculations and sanity warnings. Useful when user is annotating the entire table.

      #### Clear

      Clears the annotations from selected statement.

      #### Auto-Fill

      Uses previous instance's or statement's annotations to predict the annotations of the current statement.
      User is required to annotate at least one value in the current statement for this functionality to work.

      #### Reload

      Reloads the annotations of the statement from the backend.
      If no changes has been done on the statement, this would be its initial state.
      If there are previously saved work on the statement, this button will load the state after the previous work is
      saved.

      #### Shortcuts
      1. \`Caps Lock\`

      When deactivated, user highlights text by double clicking on the text or holding left mouse button and dragging
      curson. When activated, user can highlight cells by a single click on a cell. Default: deactivated

      2. \`Delete\` / \`Backspace\`

      Removes selected annotation.

      3. \`Tab\`

      Navigates to the next available module.

      4. \`Shift + Tab\`

      Navigates to the previous available module.

      5. \`s\` / \`-\`

      Switches the sign of selected annotation.
    </markdown>
  </div>
</div>
