<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Post Consensus Snapshot</h3>
      </div>
    </div>
  </div>
  <div class='row'>
    <div class='col-12'>
      <div class='d-flex justify-content-start py-2 sticky-header non-sticky'>
        <h3 class='mb-0'><span class='badge badge-dark'>{{company?.name}}</span></h3>
        <span  class='website-link-positioning'>
          <a class='link-fontsize' target='_blank'[href]='company?.website' >{{company?.website}}</a></span>
        <div class="ml-auto">

          <div class='chip chip-inverse'>
            <con-avatar [userId]="" [size]="32"></con-avatar>
            <span >Viktor Karlson</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>

    <div>
      <div class="card mb-2">
        <div class="card-header" >
          <div class="d-flex justify-content-start align-items-center">
            <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
            Post Consensus snapshot
            <div class="card-header-toggle-individual ml-auto">
              <con-icon-by-name iconName="angle-right" ></con-icon-by-name>
            </div>
          </div>
        </div>
        <div class="p-4 col-12 ">
          <form [formGroup]="recommendationsForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-6">
                <div class="full-width-custom">
                  <strong>Target Price (SEK)</strong>
                </div>
                <div class="consensus-status-bg">
                  <table class="consensus-table table bg-white">
                    <thead  class="thead-light">
                    <tr>
                      <th>Mean</th>
                      <th>Median</th>
                      <th>High</th>
                      <th>Low</th>
                      <th>No</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr formGroupName="targetPrice">
                      <td>
                        <input formControlName="id" hidden>
                        <input formControlName="mean"><br/>
                        <small *ngIf="recommendationsForm.get('targetPrice').get('mean').errors?.pattern && recommendationsForm.get('targetPrice').get('mean').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="median"><br/>
                        <small *ngIf="recommendationsForm.get('targetPrice').get('median').errors?.pattern && recommendationsForm.get('targetPrice').get('median').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="high"> <br/>
                        <small *ngIf="recommendationsForm.get('targetPrice').get('high').errors?.pattern && recommendationsForm.get('targetPrice').get('high').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="low"><br/>
                        <small *ngIf="recommendationsForm.get('targetPrice').get('low').errors?.pattern && recommendationsForm.get('targetPrice').get('low').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="amount"> <br/>
                        <small *ngIf="recommendationsForm.get('targetPrice').get('amount').errors?.pattern && recommendationsForm.get('targetPrice').get('amount').value !== ''">Invalid format</small>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6">
                <div class="full-width-custom">
                  <strong>Recommendations / Outlook</strong>
                </div>
                <div class="consensus-status-bg">
                  <table class="consensus-table table bg-white">
                    <thead  class="thead-light">
                    <tr>
                      <th>Buy</th>
                      <th>Overweight</th>
                      <th>Hold</th>
                      <th>Underweight</th>
                      <th>Sell</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr formGroupName="outlook">
                      <td>
                        <input formControlName="id" hidden>
                        <input formControlName="buy"><br/>
                        <small *ngIf="recommendationsForm.get('outlook').get('buy').errors?.pattern && recommendationsForm.get('outlook').get('buy').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="overweight"> <br/>
                        <small *ngIf="recommendationsForm.get('outlook').get('overweight').errors?.pattern && recommendationsForm.get('outlook').get('overweight').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="hold"><br/>
                        <small *ngIf="recommendationsForm.get('outlook').get('hold').errors?.pattern && recommendationsForm.get('outlook').get('hold').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="underweight"><br/>
                        <small *ngIf="recommendationsForm.get('outlook').get('underweight').errors?.pattern && recommendationsForm.get('outlook').get('underweight').value !== ''">Invalid format</small>
                      </td>
                      <td>
                        <input formControlName="sell"><br/>
                        <small *ngIf="recommendationsForm.get('outlook').get('sell').errors?.pattern && recommendationsForm.get('outlook').get('sell').value !== ''">Invalid format</small>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-success mt-15">Save</button>
          </form>
        </div>
        <div class="p-4 col-12" >

          <!-- loop start -->
          <div>
            <div class="card mb-2">
              <div class="card-header" (click)="showData = !showData">
                <div class="d-flex justify-content-start align-items-center">
                  <con-icon-by-name iconName="database" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                  R21Q4
                  <div class="card-header-toggle-individual ml-auto">
                    <con-icon-by-name iconName="angle-right" ></con-icon-by-name>
                  </div>
                </div>
              </div>
              <div class="p-4 col-12" *ngIf="showData" >
                <!-- show consensus view -->
                <con-consenus-snapshot></con-consenus-snapshot>
              </div>
            </div>
          </div>
          <!-- loop end -->


        </div>
      </div>
    </div>


  </div>

</div>
