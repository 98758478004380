import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'con-dynamic-clear-data-modal',
  template: ` <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Clear instance annotations?</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="mb-0">
        <span>Your data will be deleted and irrecoverably lost.</span>
        <br /><br />
        <span><b>Are you sure?</b></span>
      </p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger" (click)="modal.close(true)">Clear All</button>
      <button class="btn btn-secondary" (click)="modal.dismiss(false)">Cancel</button>
    </div>
  </ng-template>`,
})
export class DynamicClearDataModalComponent {
  @ViewChild('content') content;
  public closeResult: boolean;

  constructor(private modalService: NgbModal) {}

  public show(): Promise<any> {
    return this.modalService.open(this.content, {}).result;
  }
}
