
import {switchMap, startWith, map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ApiSettings } from './../settings.class';

import { HttpClient } from '@angular/common/http';

import { Observable ,  Subject } from 'rxjs';



@Component({
    selector: 'con-logs-table',
    templateUrl: './logs-table.component.html'
})

export class LogsTableComponent implements OnInit {
    public loading: boolean;
    private page$ = new Subject<number>();
    public currentPage = 1;
    public data: any;
    private extraShow: any = {};
    public noDataMessage = 'No logs to display.';
    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.page$.pipe(
            startWith(this.currentPage),
            switchMap(page => {
                this.loading = true;
                this.extraShow = {};
                return this.http.get<any>(ApiSettings.BASE_URL + '/' + ApiSettings.BATCH_LOG_ENDPOINT, {
                    params: {
                        page: String(page)
                    }
                })
            }), )
            .pipe(map(res => {
                res.data = res.data.map(log => {
                    if (log.extra != null && log.extra !== '') {
                        log.extra = JSON.parse(log.extra);
                    }
                    return log;
                });
                return res;
            }))
            .subscribe(res => {
                this.data = res;
                this.loading = false;
            });
    }

    toggleExtra(log: any) {
        this.extraShow[log.id] = !this.extraShow[log.id];
    }

    showExtra(log: any) {
        return this.extraShow[log.id];
    }

    changePage(page: number) {
        this.page$.next(page);
    }

    refresh() {
        this.page$.next(1);
    }
}
