<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" [accept]="acceptParameterValue"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <span>Drop Files or </span>
    <!--      Optional custom content that replaces the the entire default content.-->
    <button type="button" (click)="openFileSelector()" class='btn btn-primary ml-15'>Browse Files</button>
  </ng-template>
</ngx-file-drop>
<small>Only valid file formats
  (<ng-container *ngFor="let extension of validExtensions; let last = last">
    {{ extension }}<ng-container *ngIf="!last">,</ng-container>
  </ng-container>) with a maximum size of {{maxFileSize/1024}} MB (Per file) are accepted.
</small>
<div class="form-control-feedback custom-error" *ngIf="invalidFileNames.size.length || invalidFileNames.extension.length">
  <p>
    Invalid file(s) selected. <br>
    <ng-container *ngIf="invalidFileNames.size.length">
      File size exceeds for <ng-container *ngFor="let name of invalidFileNames.size; let last = last"> {{ name }}<ng-container *ngIf="!last">,</ng-container> </ng-container> file(s). <br>
    </ng-container>
    <ng-container *ngIf="invalidFileNames.extension.length">
      Invalid file extension for <ng-container *ngFor="let name of invalidFileNames.extension; let last = last"> {{ name }}<ng-container *ngIf="!last">,</ng-container> </ng-container> file(s).
    </ng-container>
  </p>
</div>

<!-- table format of listing -->

<div class="card mt-15" [formGroup]="entityForm"  *ngIf="attachmentsGroup().controls.length || uploadProgresses.length">
  <div class="table-responsive" formArrayName="attachments">
    <table class="table mb-0">
      <thead  class="thead-light">
      <tr>
        <th>File</th>
        <th>ref_file_id</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of attachmentsGroup().controls; let i = index;">
        <ng-container [formGroupName]='i'>
          <tr>
              <td class="v-middle w-250">
                <ng-container *ngIf='item?.value.id === null'>
                  {{item?.value?.name}} <br/> <a target="_blank"  type="link" [conResolveLinkToFile]="item?.value?.file_name">Open</a>
                </ng-container>
                <ng-container *ngIf='item?.value.id !== null'>
                  {{item?.value.source}} <br/> <a target="_blank"  type="link" [conResolveLinkToFile]="item?.value.source">Open</a>
                </ng-container>
              </td>
              <td class="w-210 align-middle">
                <con-ref-id-input formControlName="ref_file_id" (ngModelChange)="updatedChangesInEditMode(item)"></con-ref-id-input>
              </td>
              <td class="align-middle">
                <ng-container *ngIf='item?.value.id === null'>
                  <button class="btn btn-sm mr-15 btn-danger" (click)=removeFile(null,i)>
                    <con-icon-by-name  iconName="minus-circle"></con-icon-by-name>
                  </button>
                </ng-container>
                <ng-container *ngIf='item?.value.id !== null'>
                  <button class="btn btn-sm mr-15 btn-danger" (click)='removeFile(item.value.id, i)'>
                    <con-icon-by-name  iconName="minus-circle"></con-icon-by-name>
                  </button>
                </ng-container>
              </td>
          </tr>
          <tr *ngIf="item.get('ref_file_id')?.errors?.ref_file_error">
            <td colspan="4">
              <div class="form-control-feedback custom-error">
                <p>Invalid format. Expecting format number-number. Ex: 123-123</p>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let file of uploadProgresses">
        <tr>
          <td colspan="3">
            {{ file.file.name }} <br/>
            {{ file.file.size/1024/1024 | number:'.2-2' }} MB <br/>
            <div class="progress mt-2">
                 <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': file.progress + '%' }"></div>
             </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
