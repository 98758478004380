import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public activateTaskSub: Subscription;
  public deactivateTaskSub: Subscription;
  public deleteTaskSub: Subscription;
  public getMozendaDataSub: Subscription;
  public getStepDataSub: Subscription;
  public getMapColumnsSub: Subscription;
  public submitAgentSub: Subscription;
  public dimCheckSub: Subscription;
  public testConfigSub: Subscription;
  public apiResponseSub: Subscription;
  public apiErrorSub: Subscription;
  constructor() { }

  public taskDetailSubscriptions(): Subscription[] {
    return new Array(
      this.activateTaskSub,
      this.deactivateTaskSub,
      this.deleteTaskSub,
      this.getMozendaDataSub,
      this.getStepDataSub,
      this.getMapColumnsSub,
      this.submitAgentSub,
      this.dimCheckSub,
      this.testConfigSub,
    )
  }
}
