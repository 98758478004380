
import {switchMap, startWith, tap} from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { EntitiesService, EntityDescription } from '../../services/entities.service';

import { Subject } from 'rxjs';


@Component({
    selector: 'con-entity-history-list',
    templateUrl: './entity-history-list.component.html'
})

export class EntityHistoryListComponent implements OnInit, OnChanges {
    @Input() entityName: string;
    @Input() entity: any;

    private page$: Subject<number> = new Subject<number>();
    private entityName$: Subject<string> = new Subject<string>();
    private inspectShowingDict: any = {};
    public entityDescription: EntityDescription;
    public history: any;
    public loading = true;
    public response: any;
    public currentPage = 1;
    constructor(private service: EntitiesService) {}

    showInspect(id) {
        return this.inspectShowingDict[id];
    }
    toggleInspect(id) {
        this.inspectShowingDict[id] = !this.inspectShowingDict[id];
    }


    ngOnInit() {
        this.entityName$.pipe(
            startWith(this.entityName),
            switchMap(entityName => {
                return this.service.getEntityDescriptionByEntityName(this.entityName);
            }), )
            .subscribe(entityDescription => {
                this.entityDescription = entityDescription;
            });

        this.page$.pipe(
            startWith(1))
            .pipe(tap(page => this.loading = true))
            .pipe(switchMap(entityDescription => {
                return this.service.getEntityHistory(this.entityName, this.entity.id)
            }))
            .subscribe(res => {
                this.history = res.data;
                this.response = res;
                this.loading = false;
            });
    }
    ngOnChanges(event) {
        if (event.entity) {
            this.page$.next(1);
        }
        if (event.entityName) {
            this.entityName$.next(this.entityName);
        }
    }
    changePage(event) {
        this.page$.next(event);
    }
}
