import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSelected'
})
export class FilterSelectedPipe implements PipeTransform {

  transform(items: any[], selectedNames: string[], currentValue: any): any[] {
    if (!items) return [];
    if (!selectedNames) return items;

    return items.filter(item => {
      // If the item is currently selected, don't filter it out
      if (currentValue && item.name === currentValue.name) {
        return true;
      }

      // Filter out item if its name is in the selectedNames
      return !selectedNames.includes(item.name);
    });
  }

}
