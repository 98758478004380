import {Injectable} from '@angular/core';
import {FieldClass} from 'app/doc-process/sub-modules/kpi-and-kiid/models/field-color';
import {CurrentFields, InstanceDefinition} from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {Instance} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import {BehaviorSubject} from 'rxjs';
import {DataExtractorService} from './data-extractor.service';
import {Task} from '../../universal-data-entry/models/Task';

@Injectable({
  providedIn: 'root'
})
export class DocProcessLegacyCommonsService { // TODO move to hotkeys

  restoreData = new BehaviorSubject(false)
  doHighlight = new BehaviorSubject<Range>(null)


  handleLeftArrowPressed(pageIndex: number, instanceDefinitions: Array<InstanceDefinition>, coloredClasses) {
    pageIndex > 0 && pageIndex--;
    const instanceId = this.dataExtractorService.getInstanceId(instanceDefinitions, pageIndex);
    const { Instance } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    coloredClasses[pageIndex] = (Instance && Instance.validated) ? FieldClass.ValidatedPrevious : FieldClass.Current;
    if (pageIndex <= instanceDefinitions.length) {
      const instanceId = this.dataExtractorService.getInstanceId(instanceDefinitions, pageIndex + 1);
      const { Instance } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
      if (Instance && Instance.validated) {
        coloredClasses[pageIndex + 1] = FieldClass.Validated;
      } else {
        coloredClasses[pageIndex + 1] = FieldClass.Previous;
      }
    }
    return {pageIndex, coloredClasses}
  }

  handleRightArrowPressed(pageIndex: number, instanceDefinitions: Array<InstanceDefinition>, coloredClasses) {
    pageIndex < instanceDefinitions.length - 1 && pageIndex++;
    const instanceId = this.dataExtractorService.getInstanceId(instanceDefinitions, pageIndex);
    const { Instance } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    coloredClasses[pageIndex] = (Instance && Instance.validated) ? FieldClass.ValidatedPrevious : FieldClass.Current;
    if (pageIndex > 0) {
      const instanceId = this.dataExtractorService.getInstanceId(instanceDefinitions, pageIndex - 1);
      const { Instance } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
      if (Instance && Instance.validated) {
        coloredClasses[pageIndex - 1] = FieldClass.Validated;
      } else {
        coloredClasses[pageIndex - 1] = FieldClass.Previous;
      }
    }
    return {pageIndex, coloredClasses}
  }


  createInstanceDefinitions(taskInstance: Task, instances: Instance[]) {
    let { current_fields } = taskInstance;

    const newCurrentFields: CurrentFields[] = (current_fields || []).map(field => {
      if (field.split(':')[1])
        return CurrentFields[field.split(':')[1]];
      return CurrentFields[field];
    });
    const instanceDefinitions = instances.map(instance => {
      const { instance_id, issuer_name, doc_id, show_page } = instance;

      const instanceDefinition: InstanceDefinition = {
        InstanceId: instance_id,
        InstanceInfo: {instance_id, issuer_name},
        InstanceDocument: {instance_id, doc_id, doc_content: '', doc_type: null, show_page, src: null, documentString: null, documentSafeResourceUrl: null, documentSafeResourceUrl$: null},
        InstanceDataKiid: null,
        InstanceDataNews: null,
        Instance: instance,
        InstanceDocumentAnnotation: null
      } as InstanceDefinition;
      return instanceDefinition;
    });
    return [newCurrentFields, instanceDefinitions]
  }

  constructor(private dataExtractorService: DataExtractorService) { }
}
