import { Component, OnInit } from '@angular/core';

import { CountService } from '../../services/count.service';

@Component({
    selector: 'con-layout',
    templateUrl: './layout.component.html'
})

export class LayoutComponent implements OnInit {
    public counts: any;

    constructor(private count: CountService) {}

    ngOnInit() {
        this.count.getCounts().subscribe(counts => {
            this.counts = counts;
        });
    }
}
