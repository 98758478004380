import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { InstanceService } from '../services/instance.service';
import { TaskDataEntryState } from '../state-management/states';
import { map } from 'rxjs/operators';
import { SetComponentData } from '../state-management/actions';

@Component({
  selector: 'con-dynamic-data-entry-checkbox-input-component',
  template: `
    <ng-container *ngIf="{ value: value$ | async } as data">
      <input class="ml-2 align-self-center" type="checkbox" [ngModel]="data.value" (ngModelChange)="updateValue($event)" name="checkbox" [disabled]="isImmutable" />
    </ng-container>
  `,
  styles: [':host { vertical-align: middle}'],
})
export class DynamicDataEntryCheckboxInputComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<number>;

  get isImmutable(): boolean {
    return !!this.component.options?.immutable;
  }

  constructor(private store: Store, public instanceService: InstanceService) {}

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component)).pipe(
      map((value: any) => {
        return value as number;
      })
    );
  }

  updateValue($event: any) {
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, $event));
  }
}
