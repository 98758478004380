
import { Injectable } from '@angular/core';
import { FieldType, TableData } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';

@Injectable({
    providedIn: 'root'
})
export class CreateFieldsService<T> {
    constructor() {}
    createInstanceDataFieldsBody(fieldDetails: T, type: FieldType): TableData[] {
      if(fieldDetails) {
        return Object.keys(fieldDetails).map(key => {
          if(key === FieldType[FieldType.ShareClasses] && type !== FieldType.ShareClasses) return
          if(fieldDetails[key]) {
            return new Object({
              name: key || '',
              value: ((fieldDetails[key].value_count !== null && fieldDetails[key].value_count !== undefined) ? fieldDetails[key].value_count : fieldDetails[key].value ) + '/' + fieldDetails[key].total,
              confirmed: ((fieldDetails[key].confirmed_count !== null && fieldDetails[key].confirmed_count !== undefined ) ? fieldDetails[key].confirmed_count : fieldDetails[key].confirmed ) + '/' + fieldDetails[key].total,
              type: type,
              selected: false
            })
          }
        }).filter(e => e) as TableData[]
      }
    }


}
