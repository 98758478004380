<div [class]="deliveryView ? 'row m-0' : 'row'">
    <div [class]="deliveryView ? 'col-sm-12 p-0 text-center' : 'col-lg-6 col-sm-12 p-0 text-center'">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div class="d-flex justify-content-start">
                    <div class="mr-3">
                        Components
                    </div>
                    <div>
                        Count: {{componendTableBody.length}}
                    </div>
                </div>
                <con-icon-by-name 
                    [iconName]="componentTableIsEditable ? 'times':'pencil'" 
                    (click)="enableCurrentComponentEdit();
                    failedDataTypeMsg = '';"
                    style="cursor: pointer;">
                </con-icon-by-name>
            </div>
            <div class="card-body p-2">
                <con-table
                    *ngIf="componendTableBody.length > 0"
                    (editObject)="editComponent($event); open(content)"
                    (deleteObject) ="deleteComponent($event); deleteConfirmation();"
                    [tableBodyData] = "componendTableBody"
                    [tableHeadData] = "componentTableHeader"
                    [withHeader]="true"
                    [canDeleteData] ="!componentTableIsEditable"
                    editingObject = "component"
                    [editDataWithoutInput]= "componentTableIsEditable"
                >
                </con-table>
                <button class="btn btn-success my-4" (click)="open(content)">
                    Add
                </button>
            </div>
        </div>
    </div>
    <div [class]="deliveryView ? 'col-sm-12 p-0 text-center' : 'col-lg-6 col-sm-12 p-0 text-center'">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div>
                    Future changes
                </div>
                <con-icon-by-name 
                    [iconName]="futureComponentTableIsEditable ? 'times':'pencil'" 
                    (click)="enableFutureComponentEdit() ; 
                    failedDataTypeMsg = '';"
                    style="cursor: pointer;">
                </con-icon-by-name>
            </div>
            <div class="card-body p-2">
                <con-table
                    *ngIf="futureComponendTableBody.length > 0"
                    (editObject)="editComponent($event); open(content)"
                    (deleteObject) ="deleteComponent($event); deleteConfirmation();"
                    [tableBodyData] = "futureComponendTableBody"
                    [tableHeadData] = "componentTableHeader"
                    [withHeader]="true"
                    [canDeleteData] ="!futureComponentTableIsEditable"
                    editingObject = "component"
                    [editDataWithoutInput]= "futureComponentTableIsEditable"
                >
                </con-table>
                <button class="btn btn-success my-4" (click)="open(content)">
                    Add
                </button>
            </div>
        </div>
    </div>
    <ngb-alert type="danger" *ngIf="showAlert"  (close)="showAlert = !showAlert" [dismissible]="true">
        {{feedbackFromApiResponse || errorMessage}}
    </ngb-alert>
</div>


<ng-template #content let-modal>
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit component</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); initNewIndexComponent();">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="card">
                <div class="card-header">
                    Search
                </div>
                <div class="card-body">

                    <div class="row" *ngIf="searchCondition">
                        <div class="col-3">
                            <label for="feednu">Feednu</label>
                            <input id="feednu"[(ngModel)]="searchCondition.feednu"/>
                        </div>
                        <div class="col-3">
                            <label for="ticker">Ticker</label>
                            <input id="ticker" [(ngModel)]="searchCondition.ticker"/>
                        </div>
                        <div class="col-3">
                            <label for="name">Name</label>
                            <input id="name" [(ngModel)]="searchCondition.name">
                        </div>
                        <div class="col-3">
                            <label for="isin">ISIN</label>
                            <input id="isin" [(ngModel)]="searchCondition.isin">
                        </div>
                    </div>
                    <div class="row">
                        <div class="my-4 col-12 d-flex justify-content-end">
                            <button class="btn btn-primary" (click)="searchSymbol()">
                                Search
                            </button>
                        </div>
                        <div class="col-12">
                            <ngb-alert *ngIf="emptySearchMessage" type="danger" [dismissible]="true" (close)="emptySearchMessage=undefined">{{emptySearchMessage}}</ngb-alert>
                        </div>
                    </div>
                    <div class="row">
                        <con-table
                            id="search-table"
                            class="col-12"
                            [withHeader] = "true"
                            [tableHeadData] = "symbolTableHeader"
                            [tableBodyData] = "symbolTableBody"
                            [clickableCells] = "[0]"
                            [cellsContainingUrl] = "[0]"
                            [selectColumn] = "true"
                            editingObject = "component"
                            (selectedObject)= "parseSelectedObject($event)"
                        >
                        </con-table>
                    </div>
                    <div class="row mt-4">
                        <div class="col-2">
                            <label for="feednu">{{componentTableHeader[1]}}</label>
                            <input class="index-component-input" id="feednu" [(ngModel)]="newIndexComponent.feednu"/>
                        </div>
                        <div class="col-2">
                            <label for="ticker">{{componentTableHeader[2]}}</label>
                            <input class="index-component-input" id="ticker" [(ngModel)]="newIndexComponent.ticker"/>
                        </div>
                        <div class="col-2">
                            <label for="name">{{componentTableHeader[3]}}</label>
                            <input class="index-component-input" id="name" [(ngModel)]="newIndexComponent.fullname">
                        </div>
                        <div class="col-2">
                            <label for="isin">{{componentTableHeader[4]}}</label>
                            <input class="index-component-input" id="isin" [(ngModel)]="newIndexComponent.valid_from" (ngModelChange)="validateDateFrom($event)">
                        </div>
                        <div class="col-2">
                            <label for="isin">{{componentTableHeader[5]}}</label>
                            <input class="index-component-input" id="isin" [(ngModel)]="newIndexComponent.valid_to" (ngModelChange)="validateDateTo($event)">
                        </div>
                        <div class="col-2">
                            <label for="isin">{{componentTableHeader[6]}}</label>
                            <input class="index-component-input" id="isin" [(ngModel)]="newIndexComponent.comp_weight">
                        </div>
                    </div>
                    <div class="row">
                        <div class="my-4 col-12 d-flex justify-content-end">
                            <button [disabled]="!isValidDateTo || !isValidDateFrom" class="btn btn-success" (click)="addConfirmation()">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
        </div>
    </div>
</ng-template>