<input (keyup)="search()" *ngIf="hasSearch" [(ngModel)]="searchTerm" class="mb-3" placeholder="Search">
<table border="1" *ngIf="hasData">
    <thead *ngIf="withHeader">
        <td *ngFor="let item of tableHeadData" [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}">
            {{item}}
        </td>
    </thead>
    <tbody>
        <tr 
            [ngStyle]="{
                'max-width': canCancelData && row === bodyArr.length - 1 ? '20px' : 'inherit',
                'width': canCancelData && row === bodyArr.length - 1 ? '20px' : 'inherit'
            }" 
            *ngFor="let body of bodyArr; let row = index" 
        >
            <td class="table-column" [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}" *ngFor="let item of body; let column = index">
                <div [id]="wideCells ? 'wide-cells' : ''" *ngIf="!isEditable && clickableCells.indexOf(column) < 0 && item !== 'times' && item !=='pencil'">
                    {{item}}
                </div>

                <a 
                    [routerLink]="['/task_manager', linkPath ? linkPath : '', item ? item : '']" 
                    [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}"
                    *ngIf="!isEditable && linkPath && clickableCells.indexOf(column) >= 0 && cellsContainingUrl.indexOf(column) < 0 && item !== 'times' && item !== 'pencil'">
                    {{item}}
                </a>
                <a 
                    [href]="item" target="_" 
                    [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}"
                    *ngIf="!isEditable && linkPath && clickableCells.indexOf(column) >= 0 && cellsContainingUrl.indexOf(column) >= 0 && item !== 'times' && item !== 'pencil'">
                    {{item}}
                </a>
                <div 
                    tabindex="0" 
                    style="cursor: pointer;" 
                    (click)="sendSelectedObject(body)" 
                    [id]="wideCells ? 'wide-cells' : ''" 
                    *ngIf="!isEditable && selectColumn && clickableCells.indexOf(column) >= 0"
                    [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}"
                >
                    {{item}}
                </div>
                <div class="d-flex just-content-start align-items-center">
                    <input 
                        type="text"
                        [ngStyle]="{   
                            'border-color': dataTypeCheckFailedRow === row 
                                && dataTypeCheckFailedColumn === column ? 'red' :
                                row === editingRow && column === editingColumn && moreThanTenPercentDiscrepancy ? 'orange' : '',
                            'border': notEditableColumns.includes(column) ? 'none' : '', 
                            'background-color': notEditableColumns.includes(column) ? 'inherit' : '' ,
                            'max-width': cellWidth && cellWidth + 'px', 
                            'width': cellWidth && cellWidth + 'px'
                        }"
                        [readonly]="notEditableColumns.includes(column)"
                        [tabIndex]="notEditableColumns.includes(column) ? -1 : ''"
                        value="{{item}}"
                        (ngModelChange)="onChangeEvent(row,column, $event)"
                        [ngModel]="item"
                        *ngIf="isEditable" 
                        placeholder={{item}} 
                        name={{item}} 
                    />
                    <div class="ml-1 text-nowrap" *ngIf="differenceMessage && row === editingRow && column === editingColumn">
                        {{differenceMessage}}
                    </div>
                </div>

                <div 
                    [id]="wideCells ? 'wide-cells' : ''" 
                    *ngIf="item === 'times' || item === 'pencil'" 
                    tyle="width:100%" 
                    class="d-flex justify-content-center" 
                    [ngStyle]="{'max-width': cellWidth && cellWidth + 'px', 'width': cellWidth && cellWidth + 'px'}"
                >
                    <button 
                        [class]="item === 'times' ? 'btn btn-sm btn-block btn-outline-danger py-0' : 'btn btn-sm btn-block btn-outline-success py-0'"
                        (click)="item === 'times' ? deleteData(body) : editData(body)">
                        <con-icon-by-name [iconName]="item"></con-icon-by-name>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>
<ngb-pagination
    *ngIf="isPaginated" 
    [collectionSize]="amountOfPages" 
    [pageSize]="amountOfElementsPerPage" 
    (pageChange)="pageChange($event)" 
    [(page)]="page" 
    [boundaryLinks]="true">
</ngb-pagination>