import { Component, Input, OnInit } from '@angular/core';
import { DynamicDataEntryComponentInterface } from '../models/dynamic-data-entry-component-interface';
import { DynamicDataEntryComponentTypeEnum } from '../models/dynamic-data-entry-component-type-enum';
import { InstanceService } from '../services/instance.service';
import { SetComponentData } from '../state-management/actions';
import { Store } from '@ngxs/store';
import { TaskDataEntryState  } from '../state-management/states';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'con-dynamic-data-entry-language-selector-component',
  template: `
    <ng-container *ngIf="{ language: value$ | async } as data">      
        <con-entity-search-field 
        [hidden]="isLanguageSelectorHidden"
        [entityName]="'Language'"
        [showDismiss]="false"
        (onSelect)="updateValue($event)"
        >     
        </con-entity-search-field>
        <div class="alert alert-info alert-dismissible mb-0" [hidden]="!isLanguageSelectorHidden">
            <button type="button" aria-label="Close" class="close" (click)="updateValue(null)"  [hidden]="data.language === null"  >
                <span aria-hidden="true">
                  &times;
                </span>
            </button>
            <con-icon-by-name iconName="language" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                        <strong>{{ data.language?.name }}, {{ data.language?.iso639_1 }} </strong>
        </div>       
    </ng-container>
  `,
})
export class DynamicDataEntryLanguageSelectComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
  public value$: Observable<any>;
  public isLanguageSelectorHidden: boolean = false;


  constructor(
    public instanceService: InstanceService,
    private store: Store,
  ) { }

  get DynamicDataEntryComponentTypeEnum() { return DynamicDataEntryComponentTypeEnum }

  ngOnInit(): void {
    this.value$ = this.store.select(TaskDataEntryState.selectComponentData(this.instanceService.getInstanceId(), this.component))
      .pipe(
        map((value: any) => {
            this.isLanguageSelectorHidden = value ? true : false;
            return value
          }
        )
      )
  }

  updateValue($event: any) {
    this.isLanguageSelectorHidden = !this.isLanguageSelectorHidden;
    this.store.dispatch(new SetComponentData(this.instanceService.getInstanceId(), this.component, $event))
  }
}
