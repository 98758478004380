import { RouterModule, Routes } from '@angular/router';

import { EntitiesLayoutComponent } from './layout/entities-layout.component';
import { EntityStartComponent } from './layout/entity-start.component';
import { EntityDetailsComponent } from './layout/entity-details.component';

import { PageNotFoundComponent } from '../page-not-found.component';

export const entitiesRoutes: Routes = [
    {
        path: '',
        component: EntitiesLayoutComponent,
        canActivate: [],
        children: [
            {
                path: 'error',
                component: PageNotFoundComponent
            },
            {
                path: ':name',
                component: EntityStartComponent
            }, {
                path: ':name/:id',
                component: EntityDetailsComponent
            }
        ]
    }
];

export const entitiesRouting = RouterModule.forChild(entitiesRoutes);
