<div>
    <div>
      <small>From Report Date</small>
      <con-day-input [control]="fromDateControl"  [field]="fromDateField" (updated)="updateFilter($event, 'FROM_DATE')"></con-day-input>
    </div>
    <div>
      <small>To Report Date</small>
      <con-day-input [control]="toDateControl"  [field]="toDateField"   (updated)="updateFilter($event, 'TO_DATE')"></con-day-input>
    </div>
    <div *ngIf="showDateMismatchError">
      <small class="mat-error"> <sup>*</sup>From report date is greater than To report date</small>
    </div>
    <div>
      <small>Report Type</small>
      <div class="custom-filter-width">
        <select class="form-control custom-select" [formControl]="reportTypeControl" (ngModelChange)="updateFilter(true, 'REPORT_TYPE')">
          <option value="all" >All</option>
          <option value="annual">Annual</option>
          <option value="interim" >Interim</option>
          <option value="update" >Update</option>
        </select>
      </div>
    </div>
  <div>
    <small>Filter by exchange</small>
    <con-entity-search-field
      entityName="Exchange"
      (onSelect)="addExchange($event)"
    ></con-entity-search-field>
    <div>
      <button type="button" class="btn btn-sm btn-outline-info mr-1 adjust-margin" *ngFor="let exchange of exchanges" (click)="removeExchange(exchange)" placement="top" ngbTooltip="Remove from Filter">
        {{ exchange.name }}
      </button>
      <div *ngIf="exchanges.length"><a  class="btn-link cursor-pointer" (click)="removeAllExchanges()"><small>Clear All Exchanges</small></a></div>
    </div>
    <div>
      <small>Exchange Presets</small>
    </div>
    <button type="button" class="btn btn-sm btn-outline-info mr-1"  placement="top" ngbTooltip="Toggle Filter"
            *ngFor="let preset of presetExchanges | keyvalue" (click)="addExchange(preset)" [ngClass]="{'highlight-preset': addedToExchangeFilter(preset)}">
      {{preset.key}}
    </button>
  </div>

</div>
