<div class="sidebar entity-scroll">
  <nav class="nav flex-column">
    <a class="nav-link" [routerLink]="['/press_release']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      Press Releases
    </a>
  </nav>

</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
