import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { TaskManagerService } from 'app/task-manager/services/task-manager.service';
import { SubscriptionService } from 'app/task-manager/services/subscription.service';
import * as moment from 'moment'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Dep, Symbol } from 'app/task-manager/typings/Typings';

@Component({
  selector: 'con-tasks',
  templateUrl: './symbols-view.component.html',
  // styleUrls: ['./tasks-view.component.scss']
})
export class SymbolsViewComponent implements OnInit, OnDestroy {

  public dep: Dep;
  public symbolsTableBodyData: Symbol[];
  public symbolsTableHeaderData: string[];
  public expanded = false;
  public errMessage: string;
  public depDataWithExpandedBool: {depData: Dep }[];
  public agentsExpanded = true;
  public isLoading = true;
  public firstDeliveryJobStep: string;
  public processingJobs: any[] = [];
  public dropdownOptions: string[] = ['All symbols', 'Renditen'];
  public closeResult: string;
  public symbolSpec: any;
  public editedSymbolSpec: any;
  public updateSymbolSpecLoading = false;
  public updateSymbolSpecResponseMessage: string;
  private symbol: Symbol;
  private deliveryIdToEdit: number;
  private reasonForCancellationOfDelivery: string;

  constructor(private subscriptionService: SubscriptionService, private taskManagerService: TaskManagerService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.taskManagerService.getAllSymbols();
    this.subscriptionService.apiResponseSub = this.taskManagerService.apiResponse.subscribe((response: any | Symbol) => {
      switch (response.type) {
        case 'getAllSymbols': {
          this.symbolsTableBodyData = response.data;
          this.symbolsTableHeaderData = Object.keys(response.data[0]);
          this.isLoading = false;
        } break;
        case 'getRendigenSymbols' : {
          this.symbolsTableBodyData = response.data;
          this.symbolsTableHeaderData = Object.keys(response.data[0]);
          this.isLoading = false;
        } break;
        case 'getSymbolSpec' : {
          this.updateSymbolSpecLoading = false;
          this.symbolSpec = response.data;
        } break;
        case 'updateSymbolSpec': {
          this.updateSymbolSpecLoading = false;
          this.updateSymbolSpecResponseMessage = response.data.Result;
          this.taskManagerService.getAllSymbols();
        }break;
      }
    })
  }
  ngOnDestroy() {
    this.subscriptionService.apiResponseSub.unsubscribe();
  }
  toggleAgentsExpanded() {
    this.agentsExpanded = !this.agentsExpanded;
  }

  requestAction(action: string) {
    this.updateSymbolSpecLoading = true;
    switch (action) {
      case 'All symbols' : return this.taskManagerService.getAllSymbols();
      case 'Renditen': return this.taskManagerService.getRendintenSymbols();
      case 'getSymbolSpec': return this.taskManagerService.getSymbolSpec(this.symbol.symbol_id);
      case 'updateSymbolSpec': return this.taskManagerService.updateSymbolSpec(this.symbol.symbol_id, this.editedSymbolSpec);
    }
  }
  onConfigChange(symbolSpec: any) {
    this.editedSymbolSpec = symbolSpec;
  }
  setSymbol(symbol: Symbol) {
    this.symbol = symbol;
  }
  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      if (result === 'Cancel') {
        this.symbolSpec = undefined;
        this.updateSymbolSpecResponseMessage = '';
        this.updateSymbolSpecLoading = false;
      }
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  setReasonForCancellationOfDelivery(reason: string) {
    this.reasonForCancellationOfDelivery = reason;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
