import { RouterModule, Routes } from '@angular/router';

import { MissingEventsComponent } from './layout/missing-events.component';
import { MissingEventsInstitutionComponent } from './layout/missing-events-institution.component';
import { MissingEventsCompanyComponent } from './layout/missing-events-company.component';
import { MissingEventsStartComponent } from './layout/missing-events-start.component';

import { PageNotFoundComponent } from '../page-not-found.component';
import { NotAuthorizedComponent } from '../not-authorized.component';
import { MissingEventsSearchComponent } from './layout/missing-events-search.component';

export const missingEventsRoutes: Routes = [
    {
        path: '',
        component: MissingEventsComponent,
        canActivate: [],
        children: [
            {
                path: '',
                component: MissingEventsStartComponent
            }, {
                path: 'error',
                component: PageNotFoundComponent
            }, {
                path: 'auth',
                component: NotAuthorizedComponent
            }, {
                path: 'company',
                component: MissingEventsCompanyComponent
            }, {
                path: 'institution',
                component: MissingEventsInstitutionComponent
            }, {
                path: 'company/:id',
                component: MissingEventsSearchComponent
            }, {
                path: 'institution/:id',
                component: MissingEventsSearchComponent
            }
        ]
    }
];

export const missingEventsRouting = RouterModule.forChild(missingEventsRoutes);
