import { Injectable } from '@angular/core';
import { InstanceDefinition } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { TaxonomySign, InstanceData, InstanceDataUnioned } from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { DataExtractorService } from './data-extractor.service';
import { NewsInstancesService } from '../../doc-process-common/services/news-instances.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedSignService {

  handleSelectedSign(selectedSign: TaxonomySign, instanceDefinitions: InstanceDefinition[], instanceId: number, instanceData: InstanceData) {
    const { InstanceDocumentAnnotation } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    if (InstanceDocumentAnnotation) {
      const { annotations } = InstanceDocumentAnnotation;
      const selectedAnnotationIndex = annotations.findIndex(annotation => JSON.stringify(annotation && annotation.spans) === JSON.stringify(this.newsInstanceService.selectedNewsAnnotation && this.newsInstanceService.selectedNewsAnnotation.spans));
      const targetTaxonomy = (instanceData as InstanceDataUnioned).taxonomy_data[this.newsInstanceService.selectedTaxonomyDataField].values.find(value => value.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod);
      targetTaxonomy.sign = selectedSign;
      if (selectedAnnotationIndex > -1) {
        annotations[selectedAnnotationIndex].fields.forEach(field => {
          if (field.field === this.newsInstanceService.selectedTaxonomyDataField.toString()) {
            field.context.sign = this.newsInstanceService.selectedTaxonomySign;
          }
        });
      }
    }
  }

  constructor(private newsInstanceService: NewsInstancesService, private dataExtractorService: DataExtractorService) { }
}
