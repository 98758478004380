<div *ngIf="company">
    <div class="d-flex justify-content-start sticky-header py-2">
       <a routerLink="../../entity/company/{{company?.id}}" target="_blank"> <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3></a>
        <div class="chip chip-inverse ml-auto">
            <con-avatar *ngIf="company.manager?.id" [userId]="company.manager?.id" [size]="32"></con-avatar>
            {{managerName}}
        </div>
    </div>

    <div class="card mb-2" *ngIf="showEditor && profile.can(profile.getPermissionName('Company', 'show', 'instruments'))">
        <div class="card-header">
            <con-icon-by-name iconName="hashtag" [mutations]="['fw']"></con-icon-by-name> Missing primary instrument
        </div>

        <div class="table-responsive">
          <table class="table table-hover">
            <thead class="thead-light">
            <tr>
              <th style="width:15%;">Ticker</th>
              <th style="width:15%;">Name</th>
              <th style="width:15%;">ISIN</th>
              <th style="width:15%;">Exchange Name</th>
              <th style="width:10%;">Exchange Mic</th>
              <th style="width:10%;">Currency Code</th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let instrument of company?.instruments">
              <td>{{instrument?.ticker}}</td>
              <td>{{instrument?.name}}</td>
              <td>{{instrument?.isin}}</td>
              <td>{{instrument?.exchange?.name}}</td>
              <td>{{instrument?.exchange?.mic}}</td>
              <td>{{instrument?.currency?.code}}</td>
              <td class="row-action">
                <ng-container>
                        <span style="display: block; margin: 0px auto;">
                          <button class="btn btn-sm action-btn btn-success" (click)="updateMissingPrimaryInstrument(instrument)">
                            <con-icon-by-name iconName="plus-circle"></con-icon-by-name>
                            Make Primary
                          </button>
                        </span>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="company?.instruments.length === 0">
              <td colspan="7">
                <div class="alert no-data">
                  Instrument data not available.
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

    </div>
    <div class="row" *ngIf="!showEditor">
        <div class="col-12">
            <div class="alert no-data">Nothing to display!</div>
        </div>

    </div>
</div>
