<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title pb-3 pt-3">
                <h3 class="mb-0">Duplicate primary instrument</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <con-loader *ngIf="loading"></con-loader>
            <ng-container *ngIf="!loading">
                <con-primary-instrument-editor *ngFor="let comp of duplicates?.data" [company]="comp" (afterSave)="updateInList($event)">
                </con-primary-instrument-editor>
                <con-no-data [count]="duplicates?.total" [message]="noDataMessage"></con-no-data>
            </ng-container>
            <div class="d-flex justify-content-center" *ngIf="!loading && duplicates?.data?.length>0" [ngClass]="{'hide-pagination':!duplicates?.total}">
                <ngb-pagination [collectionSize]="duplicates?.total" [page]="duplicates?.current_page" (pageChange)="loadPage($event)" [pageSize]="duplicates?.per_page" maxSize="10" [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>