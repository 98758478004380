import { Component, OnInit, Input } from '@angular/core';
import {
  InstanceDataKiid,
  LANGUAGES
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { CurrentFields } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import {IdentifierModel} from '../../../doc-process-common/models/identifier-model';
import {DataFieldValidityService} from '../../../doc-process-common/services/data-field-validity.service';

@Component({
  selector: 'con-kiid-instances-handler',
  templateUrl: './kiid-instances-handler.component.html',
})
export class KiidInstancesHandlerComponent implements OnInit {

  @Input() instanceData: InstanceDataKiid
  @Input() currentFields: CurrentFields[]

  private datePickerValidationMap: {[key: string]: boolean} = {}

  public SRRI = Array.from(new Array(7), (val, index) => index + 1);
  get LANGUAGES(): Array<string> { return LANGUAGES }

  constructor(
    public dataFieldValidityService: DataFieldValidityService,
  ) { }

  ngOnInit() {
  }

  public onDatePickerValidationChange($event: [boolean, IdentifierModel]) {
    const datePickerGuid: IdentifierModel = $event[1];
    this.datePickerValidationMap[datePickerGuid.toString()] = $event[0];

    const isAllDatesValid: boolean = KiidInstancesHandlerComponent.checkValidityOfAllDates(this.datePickerValidationMap);
    this.dataFieldValidityService.allowInstanceNavigation.next(isAllDatesValid);
  }

  private static checkValidityOfAllDates(datePickerValidationMap: { [p: string]: boolean }) {
    return Object.keys(datePickerValidationMap).every((key: string) => datePickerValidationMap[key] === true)
  }
}
