<div class="sidebar">
    <con-loader *ngIf="loading"></con-loader>
    <nav class="nav flex-column" *ngIf="!loading">
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['outdated']" routerLinkActive="active" (click)="selectedType('Outdated')">
                Outdated
                <span class="badge badge-pill badge-danger">{{ counts['outdated'] }}</span>
            </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('Outdated')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events" container="body"  placement="right" 
                (click)="this.loadPage('Outdated', $event)">
                    <con-icon-by-name iconName="refresh"></con-icon-by-name>
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-start align-items-center">
            <a class="nav-link flex-grow-1" [routerLink]="['missing']" routerLinkActive="active"
            (click)="selectedType('Missing')">
                Missing
                <span class="badge badge-pill badge-danger">{{ counts['missing'] }}</span>
            </a>
            <div class="ml-auto align-self-stretch" *ngIf="showRefreshButton('Missing')">
                <button class="btn btn-sm btn-dark" ngbTooltip="Refresh Events"
                    container="body" placement="right" (click)="this.loadPage('Missing', $event)">
                    <con-icon-by-name iconName="refresh"></con-icon-by-name>
                </button>
            </div>
        </div>
    </nav>
</div>
<div class="content no-scroll">
    <router-outlet></router-outlet>
</div>
