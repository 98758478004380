<div class="modal-header">
    <h5 class="modal-title" ngbAutofocus>Translate Description</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('abort')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Do you like to translate the description? If yes, please select the languages:</p>
        <form>
            <div class="custom-control custom-checkbox" *ngFor="let lang of language">
                <input type="checkbox" class="custom-control-input" [id]="lang.iso639_1" [formControl]="listForm.controls[lang.iso639_1]">
                <label class="custom-control-label" [for]="lang.iso639_1">{{ lang.name  }}</label>
                <con-icon-by-name iconName="eye" class="float-right" *ngIf="isDescriptionExists(lang.iso639_1)" ngbPopover="{{ getDescriptionByCode(lang.iso639_1) }}" triggers="mouseenter:mouseleave" popoverTitle="{{ lang.name }}"></con-icon-by-name> 
            </div>
        </form>
        
</div>
<div class="modal-footer">
    <con-loader *ngIf="loading"></con-loader>
    <button class="btn btn-primary" [disabled]="canDisableYesButton()" (click)="getTranslation()">Preview Translation</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('abort')">Cancel</button>
</div>