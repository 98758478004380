import { Component, OnInit, ViewChild, Input, Output, AfterContentChecked, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'con-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {

    @Input() conditionTrueText = '';
    @Input() conditionFalseText = '';
    @Input() labelName = '';
    @Input() model: boolean;
    @Output() onChange = new EventEmitter();

    constructor(private utilService: UtilService) {

    }

    ngOnInit(): void {
    }

    emitChange() {
        this.onChange.emit()
    }

}
