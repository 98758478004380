import {Component, Input, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';

@Component({
  selector: 'con-flag-icon',
  template: `
    <span class="fi fi-{{countryAbbreviation}} fis"></span>
  `,
})
export class FlagIconComponent implements OnInit {
  @Input() connectorLanguageAbbreviation;
  public countryAbbreviation: string; // ISO 3166-1-alpha-2 code

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.countryAbbreviation = UtilService.languageToFlag(this.connectorLanguageAbbreviation)
  }
}
