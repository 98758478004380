<div class="modal-header">
    <h5 class="modal-title">{{modalData.title}}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('abort')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0">
        {{modalData.message}}
    </p>
</div>
<div class="modal-footer" *ngIf="!modalData.alert">
    <button class="btn btn-primary" (click)="activeModal.close('proceed')">Yes</button>
    <button class="btn btn-secondary" (click)="activeModal.dismiss('abort')">Cancel</button>
</div>
<div class="modal-footer" *ngIf="modalData.alert">
    <button class="btn btn-secondary" (click)="activeModal.dismiss('abort')">OK</button>
</div>
