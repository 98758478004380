import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {
  ShareClassFactsheet, FactsheetTables, InstanceDataKiid
} from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { CurrentFields } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { FieldColor } from 'app/doc-process/sub-modules/kpi-and-kiid/models/field-color';
import {KiidService} from '../../services/kiid.service';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap/nav/nav';


export enum Languages {EN, DE, FR, NL, IT, ES, NB, SV, DA, FI, IS}


@Component({
  selector: 'con-kiid-factsheet-instances-handler',
  templateUrl: './kiid-factsheet-instances-handler.component.html',
  styleUrls: ['./kiid-factsheet-instances-handler.component.scss']
})
export class KiidFactsheetInstancesHandlerComponent implements OnInit {

  @Input() instanceData: InstanceDataKiid
  @Input() currentFields: CurrentFields[]
  @ViewChild('nav') nav: NgbNav;

  public languages: Languages[] = []
  public activeNavId: string;

  constructor(
    public kiidService: KiidService
  ) { }

  get FactsheetTables(): string[] {
    return FactsheetTables.filter((tableName: string) => {
      if (this.instanceData?.[tableName] !== null && this.instanceData?.[tableName] !== undefined )
        return true
      return false
    })
  }

  get fieldColor(): typeof FieldColor {
    return FieldColor
  }
  get languageDefinitions(): typeof Languages {
    return Languages
  }

  ngOnInit() {
    for (const language in Languages) {
      if (!isNaN(Number(language))) {
        // @ts-ignore
        this.languages.push(language)
      }
    }
  }

  ngAfterViewInit(): void {
    this.selectFirstActiveTableTab();
  }

  isMlConfigLow(field: string, isShareClass?: boolean, shareClassIndex?: number) {
    if (isShareClass) {
      return this.instanceData[field][shareClassIndex][field]['ml_conf'] === 'Low'
    } else {
      return this.instanceData[field].ml_conf === 'Low'
    }
  }
  isFieldConfirmed(field: string, isShareClass?: boolean, shareClassIndex?: number) {
    if (isShareClass) {
      return this.instanceData[field][shareClassIndex][field]['confirmed']
    } else {
      return this.instanceData[field].confirmed
    }

  }
  getLowMlConfOrConfrimedFieldClass(field: string, isShareClass?: boolean, shareClassIndex?: number) {
    if (this.isFieldConfirmed(field, isShareClass, shareClassIndex)) { return 'checked' }
    if (this.isMlConfigLow(field, isShareClass, shareClassIndex)) { return 'warning' }
    return ''
  }
  getLowMlConfOrConfrimedFieldIconName(field: string, isShareClass?: boolean, shareClassIndex?: number) {
    if (this.isFieldConfirmed(field, isShareClass, shareClassIndex)) { return 'check-circle-o' }
    if (this.isMlConfigLow(field, isShareClass, shareClassIndex)) { return 'exclamation-triangle' }
    return ''
  }
  isCurrentClass(currentClass: string) {
    return this.currentFields.find(field => CurrentFields[currentClass] === field) > -1
  }
  getFieldBackgroundColor(field: string) {
    if (this.isCurrentClass(field)) { return FieldColor.Current }
    return FieldColor.Previous
  }
  getBackgroundColorClass(field: string) {
    if (this.isCurrentClass(field)) { return "bg-primary" }
    return "bg-white"
  }
  parseToInteger(stringValue: string) {
    return parseInt(stringValue)
  }

  getShareClassProperties(shareClass: ShareClassFactsheet) {
    return Object.keys(shareClass)
  }
  removeMultipleSpacesAndNewLine(clipboardEvent: ClipboardEvent, valueToReplace: any) {
    setTimeout(() => {
      const filteredText = (clipboardEvent.target as HTMLTextAreaElement).value.split(/\s{2,}(\r\n|\n|\r)/).join('').replace(/\s+/g, ' ')
      valueToReplace.value = filteredText
    }, 0)
  }
  handleSRRI(oldValue, newValue) {
    return oldValue === newValue ? false : true;
  }
  activeIdChange($event) {
    this.activeNavId = $event
  }

  private selectFirstActiveTableTab() {
    if (!!this.instanceData && !!this.currentFields) {
      let initialTab = this.FactsheetTables.find((tableTitle: string) => {
        if (this.getFieldBackgroundColor(tableTitle) === FieldColor.Current) {
          return true
        }
        return false
      })

      setTimeout(() => {
        this.nav.select(initialTab)
      }, 1)
    }
  }
}
