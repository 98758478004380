import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'con-survey-detail-main',
  templateUrl: './survey-detail-main.component.html',
  styleUrls: ['./survey-detail-main.component.scss']
})
export class SurveyDetailMainComponent implements OnInit {
  public relation: string;
  constructor( private route: ActivatedRoute) {
    this.route.firstChild.params.subscribe(
      (params: any) => {
        this.relation = params.relation;
      });
  }

  ngOnInit(): void {
  }

}
