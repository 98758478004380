// import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import {Breadcrumb} from '@sentry/angular';
import {UtilService} from './app/doc-process/sub-modules/doc-process-common/services/util.service';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/lib/codemirror';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/lint/lint';
if (environment.production || environment.oldstage) {
  enableProdMode();
}

let tracingOrigins = [
  "localhost",
  "connector.test",
  "infront",
  "inquiry",
  "connector",
  "infront-doc-processing-dev",
  "infront-doc-processing-test",
  "infront-doc-processing-labeling",
  "infront-doc-processing-prod3",
]

Sentry.init({
  dsn: "https://076bc31137bb4e739a17b15de24ce76c@o1167486.ingest.sentry.io/6258596",
  tunnel: environment.api_host + "/api/sentry/request-event",
  autoSessionTracking: true,
  tracesSampleRate: 0.05, // environment.production === true' ? 0.2 : 1,
  integrations: [
    new BrowserTracing({
      tracingOrigins: tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation,
      beforeNavigate: (context) => {
        const storedUser = JSON.parse(localStorage.getItem('USER-DETAILS'))
        const strippedUser = {
          email: storedUser?.email ?? "",
          roles: storedUser?.roles ?? "",
          created_at: storedUser?.created_at ?? "",
        }
        const localStorageValue = Object.assign({}, localStorage)
        delete localStorageValue.token

        Sentry.setUser(strippedUser);
        Sentry.setExtras(localStorageValue)
        Sentry.setTag('dp-backend-env', localStorageValue?.['doc_process_server'])
        Sentry.setTag('user-email', localStorageValue?.['email'])
        Sentry.setTag('hostname', window.location.hostname)

        return context
      }
    }),
  ],
  beforeSend(event) {
    event.breadcrumbs.map((bc: Breadcrumb) => {
      if (bc.category !== "xhr")
        return bc

      bc.data.url = UtilService.removeURLParameter(bc.data.url, "code")
      bc.data.url = UtilService.removeURLParameter(bc.data.url, "userToken")
      return bc
    })
    return event
  }
});
Sentry.configureScope((scope) => {
  scope.setLevel(Sentry.Severity.Warning);
});

platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true });
