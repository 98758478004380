import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpParams } from '@angular/common/http';

import { ApiSettings, MissingCompanyEventsSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { Subscription } from 'rxjs';
import { EntitiesService } from '../../entities/services/entities.service';

@Component({
    selector: 'con-missing-events-company',
    templateUrl: './missing-events-company.component.html'
})

export class MissingEventsCompanyComponent implements OnInit, OnDestroy {
    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    private filterFormValues: any;
    private reloadSubscription = new Subscription;
    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService,
                private entityService: EntitiesService) {}

    ngOnInit() {
        this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
            if (type === 'Company') {
                this.loadPage(1);
            }
        });
    }

    ngOnDestroy() {
       this.reloadSubscription.unsubscribe();
       this.countService.resetToDefaultCounts();
    }

    loadPage(pageNumber: number) {
        this.loading = true;
        let searchParams = new HttpParams().set('page', String(pageNumber));
        if (this.filterFormValues) {
            if (this.filterFormValues.universe)
                searchParams = searchParams.set('universe', String(this.filterFormValues.universe.id));
            if (this.filterFormValues.event_types.length)
                searchParams = searchParams.set('event_types', this.filterFormValues.event_types.join(','));
            if (this.filterFormValues.from_date)
                searchParams = searchParams.set('from_date', this.filterFormValues.from_date);
            if (this.filterFormValues.to_date)
                searchParams = searchParams.set('to_date', this.filterFormValues.to_date);
            if (this.filterFormValues.tier)
                searchParams = searchParams.set('tier', String(this.filterFormValues.tier.id));
        }

        this.http.get<any>(MissingCompanyEventsSettings.BASE_URL + '/' + MissingCompanyEventsSettings.MISSING_ENDPOINT, { params: searchParams })
            .subscribe(res => {
                this.countService.setCount('companies', res.total);
                this.missing = res;
                this.loading = false;
            }, err => {
                if (err.isPermissionError()) {
                    this.router.navigate(['static', 'auth'], { skipLocationChange: true });
                }
            });
    }

    removeFromList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.ngOnInit();
        }
    }

    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
    }

    whenFixed(company: any) {
        this.removeFromList(company);
        this.countService.decrementCount('missing');
    }

    filterEvents(payload: any): void {
        this.filterFormValues = payload;
        this.loadPage(1);
    }
}
