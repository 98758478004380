import { Component, Input } from '@angular/core';

import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'con-entity-search-by-field',
    templateUrl: './entity-search-by-field.component.html'
})

export class EntitySearchByFieldComponent {
    @Input() field: any;
    @Input() control: UntypedFormControl;

    public boolValues: any = [
        {
            label: 'True',
            value: 'f:true'
        },
        {
            label: 'False',
            value: 'f:false'
        },
        {
            label: 'All',
            value: ''
        }
    ];

    public isBoolean() {
        return this.field.type === 'boolean';
    }

    public isEnum() {
        return this.field.type === 'enum';
    }

    public nothingSpecial() {
        return !this.isBoolean() && !this.isEnum();
    }
}
