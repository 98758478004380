import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { EntitiesModule } from '../entities/entities.module';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';

import { ListsComponent } from './layout/lists.component';
import { TierStartComponent } from './layout/tier-start.component';
import { TierDetailComponent } from './layout/tier-detail.component';
import { InstitutionTierDetailComponent } from './layout/institution-tier-detail.component';
import { TierMissingCompaniesComponent } from './components/company/tier-missing-companies.component';
import { TierAdditionalCompaniesComponent } from './components/company/tier-additional-companies.component';
import { TierIgnoredCompaniesComponent } from './components/company/tier-ignored-companies.component';
import { TierCompaniesComponent } from './components/company/tier-companies.component';
import { TierCompanyEditorComponent } from './components/company/tier-company-editor.component';
import { TierMissingInstitutionsComponent } from './components/institution/tier-missing-institutions.component';
import { TierAdditionalInstitutionsComponent } from './components/institution/tier-additional-institutions.component';
import { TierIgnoredInstitutionsComponent } from './components/institution/tier-ignored-institutions.component';
import { TierInstitutionsComponent } from './components/institution/tier-institutions.component';
import { TierInstitutionEditorComponent } from './components/institution/tier-institution-editor.component';

import { UniverseStartComponent } from './layout/universe-start.component';
import { UniverseDetailComponent } from './layout/universe-detail.component';
import { InstitutionUniverseDetailComponent } from './layout/institution-universe-detail.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        EntitiesModule,
        SharedModule,
        AuthModule,
        NgbModule
    ],
    providers: [
    ],
    declarations: [
        ListsComponent,
        TierStartComponent,
        TierDetailComponent,
        TierMissingCompaniesComponent,
        TierAdditionalCompaniesComponent,
        TierIgnoredCompaniesComponent,
        TierCompaniesComponent,
        TierMissingInstitutionsComponent,
        TierAdditionalInstitutionsComponent,
        TierIgnoredInstitutionsComponent,
        TierInstitutionsComponent,
        TierInstitutionEditorComponent,
        TierCompanyEditorComponent,
        TierInstitutionEditorComponent,
        UniverseStartComponent,
        UniverseDetailComponent,
        InstitutionTierDetailComponent,
        InstitutionUniverseDetailComponent
    ],
    exports: []
})
export class ListsModule {}
