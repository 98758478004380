import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { AuthModule } from './../auth/auth.module';
import { RouterModule } from '@angular/router';

import { PowersLayoutComponent } from './powers-layout.component';
import { PowersComponent } from './powers.component';
import { LogsTableComponent } from './logs-table.component';
import { CustomerComponent } from './customer.component';
import { CustomersWebhookComponent } from './customers-webhook.component';
import { CustomersEditorComponent } from './webhook/customers-editor.component';
import { WebhookEditorComponent } from './webhook/webhook-editor.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntitiesModule } from './../entities/entities.module';
import { DeltaDeliveriesComponent } from './delta-deliveries.component';
import { DeltaDeliveriesLogComponent } from './delta-deliveries-log.component';
import { DeltaDeliveriesPrimaryMissingComponent } from './delta-deliveries-primary-missing.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AuthModule,
        NgbModule,
        RouterModule,
        EntitiesModule
    ],
    providers: [
    ],
    declarations: [
        PowersLayoutComponent,
        PowersComponent,
        LogsTableComponent,
        CustomersWebhookComponent,
        CustomersEditorComponent,
        WebhookEditorComponent,
        CustomerComponent,
        DeltaDeliveriesComponent,
        DeltaDeliveriesLogComponent,
        DeltaDeliveriesPrimaryMissingComponent
    ],
    exports: []
})
export class PowersModule {}
