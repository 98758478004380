import { Injectable } from '@angular/core';
import { UtilService } from 'app/doc-process/sub-modules/doc-process-common/services/util.service';
import { NewsLabelingAnnotation, NewsAnnotation, InstanceDefinition } from 'app/doc-process/sub-modules/kpi-and-kiid/models/fields';
import { InstanceData, InstanceDataUnioned, TaxonomyData } from 'app/doc-process/sub-modules/kpi-and-kiid/models/Typings';
import { DataExtractorService } from './data-extractor.service';
import { NewsInstancesService } from '../../doc-process-common/services/news-instances.service';
import { DocProcessHandlerUtilsService } from '../../doc-process-common/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class DeleteAnnotationService {

  handleDeletedAnnotation(instanceDefinitions:InstanceDefinition[], instanceId: number, instanceData: InstanceData) {
    const { InstanceDocumentAnnotation, InstanceDataNews } = this.dataExtractorService.getInstanceDefinition(instanceDefinitions, instanceId);
    const annotationToDeleteIndex = InstanceDocumentAnnotation.annotations.findIndex(annot => annot && JSON.stringify(annot) === JSON.stringify(this.newsInstanceService.selectedNewsAnnotation));

    if (annotationToDeleteIndex > -1) {
      const annotationToDelete = InstanceDocumentAnnotation.annotations[annotationToDeleteIndex];
      this.removeAnnotationFromTaxonomy((annotationToDelete as any) || [], instanceData as InstanceDataUnioned);

      InstanceDataNews.taxonomy_data = (instanceData as InstanceDataUnioned).taxonomy_data;

      //In case we have "empty" indexes from before
      InstanceDocumentAnnotation.annotations = this.utilService.removeEmptyEntries(InstanceDocumentAnnotation.annotations);

      this.removeAnnotationFromDocument(annotationToDelete);
      this.rearrangeAnnotationsInDocument(InstanceDocumentAnnotation);
      this.deleteAndReorderAnnotations(InstanceDocumentAnnotation, annotationToDeleteIndex);

      this.notifyOnDeletedAnnotaion();
    }
  }

  private notifyOnDeletedAnnotaion() {
    this.newsInstanceService._deletedAnnotation.next(true);
  }

  private deleteAndReorderAnnotations(InstanceDocumentAnnotation: NewsLabelingAnnotation, index: number) {
    delete InstanceDocumentAnnotation.annotations[index];
    InstanceDocumentAnnotation.annotations = InstanceDocumentAnnotation.annotations.filter(e => e).sort((a, b) => a['order'] - b['order']);
  }

  private rearrangeAnnotationsInDocument(InstanceDocumentAnnotation: NewsLabelingAnnotation) {
    const serializedHighlights = JSON.parse((this.newsInstanceService.textHighlighter.serializeHighlights()));

    this.newsInstanceService.textHighlighter.getHighlights().forEach((annot, index) => {
      const xpathFromHighlight = DocProcessHandlerUtilsService.getXpath(annot);
      const annotationToChange = annot.getAttribute('data-annotation');
      const xpathAfterSectionElement = xpathFromHighlight.split('section')[1].split('/').splice(1, xpathFromHighlight.split('section')[1].length).join('/');
      const newsAnnotationOffsetXpath = '/html/body/' + xpathAfterSectionElement;
      const highlight = serializedHighlights.find(high => {
        const elm = document.createElement('template');
        elm.innerHTML += high[0];
        const annot = elm.content.children[0].getAttribute('data-annotation');
        return annot === annotationToChange;
      });
      const annotationToEdit = InstanceDocumentAnnotation.annotations.find(annot => JSON.stringify(annot) === annotationToChange);
      annotationToEdit.spans[0].xref = newsAnnotationOffsetXpath;
      annotationToEdit.spans[0].offsets = [highlight[3], highlight[3] + highlight[4]];

      annotationToEdit['order'] = index;
    });
  }

  private removeAnnotationFromDocument(annotationToDelete: NewsAnnotation) {
    const highlightToRemove = this.newsInstanceService.textHighlighter.getHighlights().find(highlight => {
      if ((highlight as HTMLElement).getAttribute('data-annotation') === JSON.stringify(annotationToDelete))
        return highlight;
    });
    if (highlightToRemove) {
      this.newsInstanceService.textHighlighter.removeHighlights(highlightToRemove);
    }
  }

  private removeAnnotationFromTaxonomy = (annot: NewsAnnotation, instanceData: InstanceDataUnioned) => {
    annot.fields.forEach(field => {
      const taxonomyData: TaxonomyData = instanceData.taxonomy_data[field.field]
      if(!!taxonomyData) {
        const taxonomyDataValuesToDelete = taxonomyData.values.find(value => value.period === this.newsInstanceService.selectedTaxonomyDataContextPeriod)
        const indexToDelete = taxonomyDataValuesToDelete.values.findIndex(value => value.annot_id === annot.annot_id)
        taxonomyDataValuesToDelete.values.splice(indexToDelete, 1);

      }
    })
  }

  constructor(
    private newsInstanceService: NewsInstancesService,
    private docProcessHandlerUtilsService: DocProcessHandlerUtilsService,
    private dataExtractorService: DataExtractorService,
    private utilService: UtilService
  ) { }
}
