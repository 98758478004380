import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { CompanyReportsSettings } from 'app/settings.class';
import { ToastrService } from 'ngx-toastr';
import { MLSendStatus } from 'app/company-reports/models/MLSendStatus';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'con-key-financials',
  templateUrl: './key-financials.component.html',
  styleUrls: ['./key-financials.component.scss']
})
export class KeyFinancialsComponent implements OnInit {
  reportCtrls: UntypedFormGroup;
  @Input() entity: any = { };

  mask = CompanyReportsSettings.MASK
  loading = false;
  tableFormatData = {
    tableHeadData : [],
    incomeStatements : [],
    cashFlows : [],
    periodKpis : [],
    balanceSheets : [],
    snapshotKpis : [],
  };

  public MLSendStatus = MLSendStatus;
  constructor(private toastr: ToastrService,
    private http: HttpClient,
    private datePipe: DatePipe,
    public companyService: CompanyReportsService) {
    this.reportCtrls = new UntypedFormGroup({ });
  }

  ngOnInit() {
    if(!this.entity.key_financials_approved && this.entity.locked) {
      this.entity.key_financials_approved = true
    }
    this.reportCtrls.addControl('checkbox', new UntypedFormControl(this.entity.key_financials_approved));
    this.getReport(this.entity.id)
    this.companyService.onReportLocked.subscribe((locked: boolean)=>{
      if(locked) {
        this.reportCtrls.controls['checkbox'].setValue(true)
      }
    })
  }

  getReport(reportId: string) {
    this.loading = true;
    this.http
      .get(
        `${CompanyReportsSettings.KEY_FINANCIAL_ENDPOINT}/${reportId}`
      )
      .subscribe(
        (result) => {
          // this.reportData = result;
          this.generateTabularData(result);
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.toastr.error(
            "Sorry, some error occurred",
            "Key Financial Approval"
          );
        }
      );
  }

  approveKeyFinancials() {
    this.http
      .put(
        `${CompanyReportsSettings.BASE_URL}/${this.entity.id}`,
        {
          id: this.entity.id,
          key_financials_approved: this.reportCtrls.value.checkbox
        }
      )
      .subscribe(
        (result) => {
          this.toastr.success(
            "Key financial approval status is updated successfully",
            "Key Financial Approval"
          );
        },
        (err) => {
          this.toastr.error(
            "Sorry, some error occurred",
            "Key Financial Approval"
          );
        }
      );
  }

  getQuantity(reportName: string, row, quantity, unit) {
    if (reportName === 'period_kpi' || reportName === 'snapshot_kpi') {
      return `${row.quantity} (${row.unit})`
    } else {
      return `${quantity.name} (${unit.name})`
    }
  }

  getDisplayValue(reportName: string, row: any, quantity) {
    let value, multiplier = null
    if (reportName === 'period_kpi' || reportName === 'snapshot_kpi') {
      value = this.getConvertedKPIValue(row);
    } else {
      value = row.value_rep;
      multiplier = quantity.multiplier;
      if (multiplier !== undefined && value !== null) {
        value = value / multiplier;
      }
    }
    return value
  }
  getConvertedKPIValue(row: any): any {
    if (row.value_rep !== null && row.value_rep !== undefined && row.value_rep !== '') {
      if (row.multiplier === 0.01 || row.multiplier === '0.01') {
        row.display_value = (row.value_rep / row.multiplier).toFixed(4);
      } else {
        row.display_value = (row.value_rep / row.multiplier).toString();
      }
    }
    return row.display_value
  }

  get settingLock() {
    return this.companyService.locking;
  }

  generateTabularData(reportData) {
    // this.tableFormatData = [];
    const fromDates = reportData.fromDates;
    const toDates = reportData.toDates;
    const headerTextArray: string[] = [];
    // check fromDates is empty, exit if yes
    if(!fromDates.length){
      return;
    }
    // generate multiple date support items like income statements, cash flow & period kpis
    this.generateMultipleDateSupportingItems(fromDates, toDates, reportData)
    // generate single date support items like snapshot kpis & balance sheet
    this.generateSingleDateSupportingItems(toDates, reportData)
    // generate header text
    fromDates.forEach((item, index) => {
      item =  this.datePipe.transform(item,'dd/MM/yyyy').toString();
      item += ' - ';
      item += this.datePipe.transform(toDates[index],'dd/MM/yyyy').toString();
      headerTextArray.push(item);
    });
    this.tableFormatData.tableHeadData = headerTextArray;
  }
  generateSingleDateSupportingItems(toDates: string[],reportData): void {
    toDates.forEach((date) => {
      this.tableFormatData.balanceSheets.push(reportData.balance_sheet[date]);
      reportData.snapshot_kpi[date].quantity = null;
      reportData.snapshot_kpi[date].unit = null;
      this.tableFormatData.snapshotKpis.push(reportData.snapshot_kpi[date]);
    })
  }
  generateMultipleDateSupportingItems(fromDates: string[], toDates: string[], reportData): void{
    fromDates.forEach((item, index) => {
      item += '/';
      item += toDates[index];
      for (let key in reportData.income_statement) {
        if(key === item) {
          this.tableFormatData.incomeStatements.push(reportData.income_statement[key])
        }
      }
      for (let key in reportData.cash_flow) {
        if(key === item) {
          this.tableFormatData.cashFlows.push(reportData.cash_flow[key])
        }
      }
      for (let key in reportData.period_kpi) {
        if(key === item) {
          reportData.period_kpi[key].quantity = null;
          reportData.period_kpi[key].unit = null;
          this.tableFormatData.periodKpis.push(reportData.period_kpi[key])
        }
      }
    });
  }
  getDisplayTitle() {
    let displayName = this.entity.company.name;
    if (this.entity) {
      displayName +=
        ' / ' +
        this.entity.report_type +
        ', ' +
        moment(this.entity.report_date).format('yyyy-MM-DD');
    }
    return displayName;
  }
}
