import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MissingInstitutionEventsSettings, ApiSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'con-missing-events-institution',
    templateUrl: './missing-events-institution.component.html'
})

export class MissingEventsInstitutionComponent implements OnInit, OnDestroy {
    public missing: any;
    public loading = true;
    public noDataMessage = 'No data to display.';
    private _reloadSubscription = new Subscription;
    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService) {}

    ngOnInit() {
        this.loadPage(1);
        this.countService.reloadSubject.subscribe(type => {
            if (type === 'Institution') {
                this.loadPage(1);
            }
        })
    }
    ngOnDestroy() {
        this._reloadSubscription.unsubscribe();
    }

    loadPage(pageNumber: number) {
        this.loading = true;
        this.http.get<any>(MissingInstitutionEventsSettings.BASE_URL + '/' + MissingInstitutionEventsSettings.MISSING_ENDPOINT,
                {
                    params: {
                        page: String(pageNumber)
                    }
                })
                 .subscribe(res => {
                     this.countService.setCount('institutions', res.total);
                     this.missing = res;
                     this.loading = false;
                 }, err => {
                     if (err.status === ApiSettings.RESPONSE_CODES.PERMISSION_ERROR) {
                         this.router.navigate(['static', 'auth'], { skipLocationChange: true });
                     }
                 });
    }

    removeFromList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.ngOnInit();
        }
    }

    whenFixed(company: any) {
        this.removeFromList(company);
        this.countService.decrementCount('missing');
    }

    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
    }
}
