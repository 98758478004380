import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { EntitiesService, EntityDescription } from '../../services/entities.service';
import { RelationService, Relation } from '../../services/relation.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'con-belongs-to-editor',
    templateUrl: './belongs-to-editor.component.html'
})

export class BelongsToEditorComponent implements OnChanges {
    private _canEdit: boolean = true;
    private _relation: any;
    @Input()
    set canEdit(value: boolean) {
      this._canEdit = value === undefined ? true : value;
      this.getActions();
    }

    get canEdit(): boolean {
      return this._canEdit;
    }

    @Input()
    set relation(value: any) {
      this._relation = value;
      this.getActions();
    }

    get relation(): any {
      return this._relation;
    }
    @Input() entity: any;
    @Input() canShow = true;
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    public inverseRelations: any;
    private relatedEntityDescriptions: any;
    private exists = false;

    public relatedObject: any;
    public relatedType: string;

    public loading: boolean;
    public existing: boolean;
    public isHasOne: any = {};

    public relations: Relation;
    public fixedSearchParams: any = {};
    public actions: any[] = [];

    attachError = false;
    attachErrorText = '';

    constructor(private service: EntitiesService,
                private relationService: RelationService) {}

    ngOnChanges() {
        this.loading = true;
        if (this.entity && (this.service.isEntityLocked(this.entity) || this.service.entityLockedByMl(this.entity))) {
            this.canEdit = false;
        }
        this.relations = this.relationService.getRelation(this.relation);
        this.service.getRelation(this.relation.owner, this.entity.id, this.relation.name)
                    .pipe(switchMap(res => {
                                const obs = this.relations.init();

                                if (this.relations.isMorphRelation()) {
                                    if (res.object) {
                                        this.relatedObject = res.object;
                                        this.relatedType = res.type;
                                    } else {
                                        this.relatedObject = {};
                                        this.relatedType = null;
                                    }

                                } else {
                                    this.relatedObject = res;
                                    this.relatedType = this.relation.model;
                                }

                                if (this.relatedObject.id) {
                                    this.existing = true;
                                }

                                return obs;
                            })
                        )
                    .subscribe(() => {
                        this.relatedEntityDescriptions = this.relations.getAllRelatedEntityDescriptions();
                        this.inverseRelations = this.relations.getAllInverseRelations();
                        this.fixedSearchParams = this.relations.getAllFixedSearchParams(this.entity);

                        this.loading = false;
                    });
    }
    reset() {
        this.inverseRelations = [];
        this.relatedEntityDescriptions = {};
        this.fixedSearchParams = {};
    }


    addRelation(relation, $event) {
        this.loading = true;
        this.attachError = false;
        this.attachErrorText = '';
        this.getSaveObserver(relation, $event)
                    .subscribe(res => {
                        this.relatedObject = res ? res : $event;
                        this.relatedType = relation.owner;
                        this.loading = false;
                        this.existing = true;
                    },
                    err => {
                        if(err?.data?.general.length && err?.type === 'VALUE_ERROR') {
                          this.attachError = true;
                          this.attachErrorText = err.data.general[0];
                        }
                        this.loading = false;
                        this.existing = false;
                    })
    }
    getSaveObserver(relation, $event) {
        let payload;
        if (this.relations.isMorphRelation()) {
            payload = {};
            payload[relation.owner] = [$event.id];
        } else {
            payload = [$event.id];
        }
        return this.service.addRelation(this.relation.owner, this.entity.id, this.relation.name, payload);
    }
    getDetachObserver() {
        let payload;
        if (this.relations.isMorphRelation()) {
            payload = {};
            payload[this.relatedType] = [ this.relatedObject.id ];
        } else {
            payload = [ this.relatedObject.id ];
        }
        return this.service.removeRelation(this.relation.owner, this.entity.id, this.relation.name, payload);
    }
    handleAction($event) {
        if ($event.action === 'detach') {
            this.loading = true;
            this.getDetachObserver()
                        .subscribe(res => {
                            this.relatedObject = {};
                            this.relatedType = null;
                            this.loading = false;
                            this.existing = false;
                        },
                        err => {
                            this.loading = false;
                            this.existing = true;
                        });
        }
    }
    getActions() {
      this.actions = (this.canEdit && this.relation && !this.relation.disabled) ? [{
        name: 'detach',
        label: 'Detach',
        icon: 'eject'
      }] : [];
    }
}
