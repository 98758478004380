import { Component, OnInit } from '@angular/core';
import { CompanyReportsSettings } from 'app/settings.class';
import { Router } from '@angular/router';
import { ProfileService } from 'app/auth/services/profile.service';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';

@Component({
  selector: 'con-start',
  templateUrl: './start.component.html'
})

export class StartComponent implements OnInit {
  public tierId: number = CompanyReportsSettings.TIER_ID;
  public recentList: any[];
  public lsName = 'recentCompanies';

  constructor(
    private router: Router,
    public profile: ProfileService,
    private reportService: CompanyReportsService
  ) { }

  ngOnInit() {
    this.recentList = this.reportService.getRecent('recentCompanies');
    this.reportService.recentSearchUpdated.subscribe(data => {
      this.recentList = this.reportService.getRecent('recentCompanies');
    })
  }

  public navigateToCompany(company: any) {
    const recentData = {
      id: company.id,
      name: company.name
    };
    this.reportService.setRecent(this.lsName, recentData);
    this.router.navigate(['company_reports', 'company', company.id]);

  }
  public removeFromList(company) {
    this.reportService.removeFromRecent('recentCompanies', company, true)
  }
}
