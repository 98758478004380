
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketcapSettings } from 'app/settings.class';
import { HttpClient } from '@angular/common/http';
import { MarketcapCountService } from 'app/market-cap/service/marketcap-count.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'con-duplicate-primary-instrument',
  templateUrl: './duplicate-primary-instrument.component.html'
})

export class DuplicatePrimaryInstrumentComponent implements OnInit, OnDestroy {

  public loading = true;
  public duplicates: any;
  private primarySubscription = new Subscription;
  private reloadSubscription = new Subscription;
  public noDataMessage = 'No data to display.';

  constructor(
    private http: HttpClient,
    private countService: MarketcapCountService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.loadPage(1);
    this.reloadSubscription = this.countService.reloadSubject.subscribe(type => {
      if (type === 'duplicate_primaries') {
        this.loadPage(1);
      }
    });
  }
  loadPage(pageNumber: number) {
    this.loading = true;
    if (this.primarySubscription) {
      this.primarySubscription.unsubscribe();
    }
    this.primarySubscription = this.http.get<any>(MarketcapSettings.BASE_URL + '/' + MarketcapSettings.DUPLICATE_PRIMARIES_ENDPOINT,
      {
        params: {
          page: String(pageNumber)
        }
      })
      .subscribe(res => {
        this.countService.setCount('duplicate_primaries', res.total);
        this.duplicates = res;
        this.loading = false;
      },
      error => {
        this.duplicates = {total: 0};
        this.countService.setCount('duplicate_primaries', 0);
        this.loading = false;
        this.toastr.error('Sorry, some error occurred!');
      }
      );
  }

  updateInList(company: any) {
    const index = this.duplicates.data.findIndex(c => c.id === company.id);
    this.duplicates.data.splice(index, 1);
    this.duplicates.total--;
    this.countService.decrementCount('duplicate_primaries');
  }

  ngOnDestroy() {
    if (this.primarySubscription) {
      this.primarySubscription.unsubscribe();
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }
}
