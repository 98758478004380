<div class="modal-header">
    <h5 class="modal-title">Not authorized</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('abort')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0">
        You are not authorized to do this action.
    </p>
</div>
