<div class="row">
    <div class="col-12">
        <div class="page-title mb-2 pb-3 pt-3">
            <h3 class="mb-0">Unmatched</h3>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card mb-3">
            <div class="card-body">
                <h5 class="card-title">Filter by exchange</h5>
                <con-entity-search-field
                    entityName="Exchange"
                    (onSelect)="addExchange($event)"
                ></con-entity-search-field>
                <div>
                  <button type="button" class="btn btn-sm btn-outline-info mr-1 adjust-margin" *ngFor="let exchange of exchanges" (click)="removeExchange(exchange)" placement="top" ngbTooltip="Remove from Filter">
                     {{ exchange.name }}
                  </button>
                  <div *ngIf="exchanges.length"><a  class="btn-link cursor-pointer" (click)="removeAllExchanges()"><small>Clear All Exchanges</small></a></div>
                </div>
                <div>
                  <small>Exchange Presets</small>
                </div>
                <button type="button" class="btn btn-sm btn-outline-info mr-1"  placement="top" ngbTooltip="Toggle Filter"
                        *ngFor="let preset of presetExchanges | keyvalue" (click)="addExchange(preset)" [ngClass]="{'highlight-preset': addedToExchangeFilter(preset)}" >
                        {{preset.key}}
                </button>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!data?.total}">
    <ngb-pagination [collectionSize]="data?.total"
                    [(page)]="currentPage"
                    (pageChange)="changePage($event)"
                    [pageSize]="data?.per_page"
                    maxSize="10"
                    [rotate]="true"></ngb-pagination>
</div>
<con-loader *ngIf="loading"></con-loader>
<con-matcher *ngFor="let match of data?.data" [match]="match" (afterMatch)="afterMatched($event)"></con-matcher>
<con-no-data  *ngIf="!loading" [count]="data?.total" [message]="noDataMessage"></con-no-data>
<div class="d-flex justify-content-center" [ngClass]="{'hide-pagination':!data?.total}">
    <ngb-pagination [collectionSize]="data?.total"
                    [(page)]="currentPage"
                    (pageChange)="changePage($event)"
                    [pageSize]="data?.per_page"
                    maxSize="10"
                    [rotate]="true"></ngb-pagination>
</div>
