<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title d-flex justify-content-between py-3">
                <div class="title-section d-flex align-items-center">
                    <div class="page-title-icon">
                        <con-icon-by-name iconName="calendar"></con-icon-by-name>
                    </div>
                    <h3 class="mb-0">Calendar</h3>
                </div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/calendar']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Overview</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/calendar/direkt_sync_status']" routerLinkActive="active">Direkt sync status</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/calendar/earnings']" routerLinkActive="active">Earnings</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
