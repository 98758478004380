<div>
  <div class="alert ml-status-failed mb-0" *ngIf="taxonomyListingData === null && surveyEntity?.locked; else elseTaxonomySection">
    <con-icon-by-name iconName="warning"></con-icon-by-name> No taxonomy attached!
  </div>
  <ng-template #elseTaxonomySection>
    <div class="card mb-15">
      <div class="card-header">
        Taxonomy <ng-container *ngIf="surveryDate">for {{surveryDate}} survey</ng-container>
      </div>
      <div class="card-body">
        <div class="taxonomy-pos" *ngIf="taxonomyListingData === null && !surveyEntity?.locked">
  
          <div class="tax-create">
            <div>No Taxonomy Created <br/> <button class="btn btn-primary"  (click)=showCreateForm() >Create Taxonomy</button></div>
          </div>
          <div class="select-tax">
            <div *ngIf="showPreLoader"><con-loader [showCircularLoder]="true"></con-loader></div>
            <div *ngIf="!showPreLoader">Choose existing taxonomy <con-entity-search-field (onSelect)="updateSurveyTaxonomy($event)" entityName="Taxonomy" [fixedSearchParams]="{taxonomy_type:'Estimates'}"></con-entity-search-field></div>
          </div>
        </div>
  
        <div *ngIf="taxonomyListingData">
          <div class="table-responsive">
            <table class="table">
              <thead  class="thead-light">
              <tr>
                <th>Name</th>
                <th>Locked</th>
                <th>Created at</th>
                <th>Updated at</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{taxonomyListingData?.name}}</td>
                <td>{{taxonomyListingData?.locked}}</td>
                <td>{{taxonomyListingData?.created_at}}</td>
                <td>{{taxonomyListingData?.updated_at}}</td>
                <td>
                  <button (click)="showEditForm()" class="btn btn-primary">
                    <ng-container *ngIf="!surveyEntity?.locked; else elseButtonLabel">Edit</ng-container>
                    <ng-template #elseButtonLabel>View</ng-template>
                     Taxonomy
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-container  *ngIf="isLoadingTaxonomy">
  <con-loader></con-loader>
</ng-container>
<div class="card" *ngIf="!isLoadingTaxonomy && (showCreateMode || showEditMode)" [ngClass]="{ 'is-locked': surveyEntity?.locked}">
  <div class="card-header">
    <span *ngIf='showCreateMode'>Create</span> 
    <span *ngIf='showEditMode && !surveyEntity?.locked'>Edit</span>
    <span *ngIf='showEditMode && surveyEntity?.locked'>View</span> Taxonomy
  </div>
  <ng-container  *ngIf="!loading && (showCreateMode || showEditMode) ">
    <div class="padding-adj">
      <div class="form-group inp-width" *ngIf='showCreateMode'>
        <label>
          <small>Default Taxonomies</small>
        </label>
        <div>
          <select [(ngModel)]="selectedValue" (change)="onDropDownChange($event.target.value)" name="dropdown" class="tree-input-dd-width form-control custom-select">
            <option value="std">Standard</option>
            <option value="std-bank">Standard Bank</option>
            <option value="std-real-est">Standard Real Estate</option>
          </select>
        </div>
      </div>
      <button class="btn btn-primary pull-right mt-20" (click)="addParentModule()" *ngIf="!surveyEntity?.locked">
        Add Parent Module
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="mt-15 pr-20 pb-20">
      <div class="taxonomy-header">
        <div class="left-header">
          <strong>Modules</strong>
        </div>
        <div class="right-header" [ngClass]="{ 'locked-header': surveyEntity?.locked }">
          <strong>Alias</strong>
        </div>
      </div>
      <div class="clearfix"></div>
      <form [formGroup]="formArray" (ngSubmit)="showUpdateConfirmModal()">
        <div [dragula]="surveyEntity?.locked ? null : 'parents'">
          <ng-container *ngFor="let form of formArray.controls; let i = index;">
            <div formGroupName="{{i}}" class="tree-row-data" [id]="i">
              <div class="rcm parentHandle" [ngClass]="form.errors? 'rcm-error-height-adj' : ''">
                <div class="btn-w pull-left">
                  <!-- Add toggle button to expand/collapse children -->
                  <button type="button" class="btn-w btn" *ngIf="form.controls.children.length" (click)="toggleChildrenDisplay(form)">
                    <con-icon-by-name [iconName]="form.controls.expandChildren.value ? 'angle-down' : 'angle-right'"></con-icon-by-name>
                  </button>
                </div>
                <div class="w-auto select-pos">
                  <ng-select class='"w-250'  formControlName="item" #selectDropdown
                             placeholder="Select or add an item..."
                             notFoundText="Type to add value..."
                             typeToSearchText="Type to add value..."
                             (change)="updateAlias(form, $event)"
                             [compareWith]="compareFn"
                             [clearable]="false" [items]="nameOptions | filterSelected:selectedNames:form.get('item').value" bindLabel="name" bindValue="value"   [addTag]="form.get('allowTags')?.value ? addTag : null">
                  </ng-select>
                  <small *ngIf="form.errors"  class="mat-error">Error: {{form.errors[0]}}</small>
                </div>

                <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                  <button type="button" class="btn btn-danger pull-right"  (click)="removeParent(i)">
                    <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                  </button>
                  <button type="button" class="btn btn-primary pull-right mr-15" (click)="addChild(form)" >
                    <con-icon-by-name iconName="plus-circle"></con-icon-by-name>
                  </button>
                </div>
                <div class="tree-input-alias-width pull-right">
                  <input formControlName="alias" [placeholder]="surveyEntity?.locked ? '-' : 'Alias'" class="bg-custom form-control ">
                </div>
              </div>
              <div [dragula]="surveyEntity?.locked ? null : 'children'" class="drop-area">
                <ng-container *ngIf="form.controls.expandChildren.value">
                  <ng-container formArrayName="children"  *ngIf="form.controls.children">
                    <div class="tree-row-data" *ngFor="let childForm of form.controls.children.controls; let j = index;"
                         [formGroupName]="j" [id]="j">
                      <div class="rcm childHandle" [ngClass]="childForm.errors? 'rcm-error-height-adj' : ''">
                        <div class="btn-w pull-left">&nbsp;</div>
                        <div class="w-auto select-pos">
                          <ng-select class='"w-250'  formControlName="item"
                                     placeholder="Select or add an item..."
                                     notFoundText="Type to add value..."
                                     typeToSearchText="Type to add value..."
                                   (change)="updateAlias(childForm, $event)"
                                     [compareWith]="compareFn"
                                     [clearable]="false" [items]="nameOptions | filterSelected:selectedNames:childForm.get('item').value" bindLabel="name" bindValue="value"   [addTag]="childForm.get('allowTags')?.value ? addTag : null">
                          </ng-select>
                          <small *ngIf="childForm.errors"  class="mat-error">Error: {{childForm.errors[0]}}</small>
                        </div>
                        <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                          <button type="button" class="btn btn-danger pull-right"  (click)="removeChild(form, j)">
                            <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                          </button>
                          <button type="button" class="btn btn-primary pull-right mr-15" (click)="addGrandchild(childForm)" >
                            <con-icon-by-name iconName="plus-circle"></con-icon-by-name>
                          </button>
                        </div>
                        <div class="tree-input-alias-width pull-right child-mr-adj">
                          <input formControlName="alias" [placeholder]="surveyEntity?.locked ? '-' : 'Alias'" class="bg-custom form-control">
                        </div>
                      </div>
                      <div [dragula]="surveyEntity?.locked ? null : 'grandChildren'" class="drop-area">
                        <ng-container formArrayName="children" *ngIf="childForm.controls.children">
                          <div class="tree-row-data" *ngFor="let grandchildForm of childForm.controls.children.controls; let k = index;"
                          [formGroupName]="k" >
                            <div class="rcm" [ngClass]="grandchildForm.errors? 'rcm-error-height-adj' : ''">
                              <div class="btn-w pull-left">&nbsp;</div>
                              <div class="w-500 select-pos">
                                <ng-select class='"w-250'  formControlName="item"
                                           placeholder="Select or add an item..."
                                           notFoundText="Type to add value..."
                                           typeToSearchText="Type to add value..."
                                       (change)="updateAlias(grandchildForm, $event)"
                                           [compareWith]="compareFn"
                                           [clearable]="false" [items]="nameOptions | filterSelected:selectedNames:grandchildForm.get('item').value" bindLabel="name" bindValue="value" [addTag]="grandchildForm.get('allowTags')?.value ? addTag : null">
                                </ng-select>
                                <small *ngIf="grandchildForm.errors"  class="mat-error">Error: {{grandchildForm.errors[0]}}</small>
                              </div>
                              <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                                <button type="button" class="btn btn-danger pull-right" (click)="removeGrandchild(childForm, k)">
                                  <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                                </button>
                              </div>
                              <div class="tree-input-alias-width pull-right child-mr-adj">
                                <input formControlName="alias" [placeholder]="surveyEntity?.locked ? '-' : 'Alias'" class="bg-custom form-control">
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="save-position">
          <!-- Save button and input field for taxonomy name -->
          <div class="form-group">
            <label>
              <small>
                Taxonomy Name
              </small>
            </label>
            <input type="text" [formControl]="taxonomyName"  placeholder="Custom Taxonomy Name" class="form-control tree-input-alias-width child-mr-adj">
            <small *ngIf='taxonomyName.errors'  class="mat-error">Error: {{taxonomyName.errors.serverError}}</small>
          </div>
          <div *ngIf="!surveyEntity?.locked">
            <button type="submit"  class="btn btn-success mt-15">
             <span *ngIf="showCreateMode"> Create </span>
             <span *ngIf="showEditMode"> Update </span>
              Taxonomy
            </button>
          </div>
          <div *ngIf="errorResponse">
            <small class="mat-error">Error : {{ errorResponse?.message }}</small>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

</div>


<div>

</div>

