import {Component, Input, OnInit} from '@angular/core';
import {DynamicDataEntryComponentInterface} from '../models/dynamic-data-entry-component-interface';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {InstanceService} from '../services/instance.service';
import {TaskDataEntryState} from '../state-management/states';
import {InstanceDataTopicClassification} from '../../kpi-and-kiid/models/Typings';

@Component({
  selector: 'con-dynamic-data-entry-multiclass-taxonomy-selector',
  template: `   
   <ng-container *ngIf="{ value: value$ | async } as data">
       <con-multiclass-taxonomy [instanceData]="value$ | async"></con-multiclass-taxonomy>
    </ng-container>
  `,

})
export class DynamicDataEntryMulticlassTaxonomySelectorComponent implements OnInit {
  @Input() component: DynamicDataEntryComponentInterface;
 public value$: Observable<InstanceDataTopicClassification>;

  get isImmutable(): boolean {
    return !! this.component.options?.immutable
  }

  constructor(
    private store: Store,
    public instanceService: InstanceService,
  ) { }

  ngOnInit(): void {

   this.value$ = this.store.select(TaskDataEntryState.selectInstanceDetails(this.instanceService.getInstanceId())) as Observable<InstanceDataTopicClassification>;

  }


}
