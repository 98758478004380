<div class="row">
    <div class="col-lg-6 col-sm-12 p-0 text-center">
        <div class="col-lg-12 col-sm-12 d-flex">
            <button (click)="requestAction('testEmailMapping')" class="btn btn-primary mr-2 my-3">Test email mapping</button>
            <button (click)="requestAction('setEmailMapping')" class="btn btn-primary mr-2 my-3">Set email mapping</button>
            <button (click)="requestAction('removeEmailMapping')" class="btn btn-primary mr-2 my-3">Remove email mapping</button>
            <div class="check-container d-flex align-items-center mr-2 my-3 ml-auto">
                <con-loader *ngIf="checkLoading" class="loader"></con-loader>
                <con-icon-by-name class="loader" *ngIf="checkPassed !== undefined" [iconName]="checkPassed ? 'check' : 'times'"></con-icon-by-name>
            </div>
        </div>
        
        <button (click)="requestAction('getEmailMappingPlaceholder')" class="btn btn-success" *ngIf="!emailMapping">Get config</button>
        <con-editor *ngIf="emailMapping" (onChange)="onConfigChange($event)" [data]="emailMapping"></con-editor>
    </div>
    <div class="col-lg-6 col-md-12">
        <div class="card">
            <div class="card-header">Response</div>
            <div #responseElm class="card-body">
            </div>
        </div>
        <ngb-alert [type]="actionSucceeded ? 'success' : 'danger'" *ngIf="showFeedbackFromApiAlert" [dismissible]="false">
            {{feedbackFromApiResponse}}
        </ngb-alert>
        <div>{{errorMessage}}</div>
    </div>
</div>