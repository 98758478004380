import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'con-labeling-taxonomy-toggle',
  styles: [
    `
      label {
        margin-left: auto;
        margin-right: auto;
        white-space: nowrap;
      }

      label.switch {
        transform: scale(0.7);
      }
      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `,
  ],
  template: `
    <div class="row" [ngClass]="{ disabled: isDisabled }">
      <label
        ><small>{{ name }}</small></label
      >
    </div>
    <div class="row" [ngClass]="{ disabled: isDisabled }">
      <label class="switch" (click)="$event.stopPropagation()">
        <input type="checkbox" id="toggle" [checked]="isChecked" [ngModel]="model" (ngModelChange)="change($event)" />
        <span class="slider round"></span>
      </label>
    </div>
  `,
})
export class LabelingTaxonomyToggleComponent implements OnInit {
  @Input() name: string;
  @Input() model: any;
  @Input() isDisabled?: boolean;
  @Output() toggle = new EventEmitter();
  public isChecked: boolean;

  ngOnInit() {
    this.isChecked = this.model;
  }

  change($event): void {
    if ($event == false) {
      this.model = false;
      this.isChecked = false;
    }
    this.toggle.emit($event);
    this.isChecked = true;
  }
}
