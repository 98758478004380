<div class="container-fluid mb-3">
    <div class="row">
        <div class="col-12">
            <div class="page-title pb-3 pt-3">
                <div class="page-title-icon">
                    <con-icon-by-name iconName="calendar-minus-o"></con-icon-by-name>
                </div>
                <h3 class="mb-0">Missing Events</h3>
            </div>
            <con-loader *ngIf="isLoading()"></con-loader>
        </div>
    </div>
    
    <div class="row">
        <div class="col-12" *ngIf="!isLoading()">
            <con-missing-events-editor
            [ownerType]="ownerType"
            [company]="company"
            (afterFixed)="updateCompany($event)"
            (afterUpdate)="updateCompany($event)"></con-missing-events-editor>
        </div>
    </div>
</div>