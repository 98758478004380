<h4>Delta delivery logs</h4>
<div class='card'>
  <div class='table-responsive'>
    <table class='table'>
      <thead class='thead-light'>
      <tr>
        <th>Id</th>
        <th>File</th>
        <th>Created At</th>
        <th>Message</th>
        <th class="text-right">
          <con-icon-by-name (click)="refresh()" iconName="refresh"></con-icon-by-name>
        </th>
      </tr>
      </thead>
      <con-loader *ngIf='loading'></con-loader>
      <tbody *ngIf='!loading'>
      <ng-container *ngFor='let dl of data?.data'>
        <tr>
          <td>{{ dl?.id }}</td>
          <td>
            <a *ngIf="dl?.file_url" type="link" conResolveLinkToFile="{{dl?.file_url}}" target="_blank" class='nav-link pl-0' >{{dl?.file_name}}</a>
          </td>
          <td>{{ dl?.created_at }}</td>
          <td colspan="2">{{ dl?.error_message }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <con-no-data *ngIf='!loading' [count]='data?.total' [message]='noDataMessage'></con-no-data>
  <div class='card-body d-flex justify-content-center' [ngClass]="{'hide-pagination':!data?.total}">
    <ngb-pagination [collectionSize]='data?.total'
                    [(page)]='currentPage'
                    (pageChange)='changePage($event)'
                    [pageSize]='data?.per_page'
                    maxSize='10'
                    [rotate]='true'></ngb-pagination>
  </div>
</div>
