import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslationSettings, ApiSettings } from "app/settings.class";
import { DateConverterService } from "app/shared/services/date-converter.service";
import { ListInModalComponent } from "app/utility/components/list-in-modal.component";
import { TranslatedDescriptionsComponent } from "app/utility/components/translated-descriptions/translated-descriptions.component";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  public languages = [];
  public sourceLanguages = [];
  public onTranslationSaved = new Subject<string | number>();

  constructor(private modalService: NgbModal, private http: HttpClient,
    private toastr : ToastrService,
    private dateConverter : DateConverterService) {
    this.getTranslationLanguages();
  }

  public onDescriptionSaved(description, targetLanguageDescription?) {
    this.getTranslationLanguages().then(() => {
      if (description && this.isLanguageInSourceList(description.language_id, description)) {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(ListInModalComponent, {
          size: "md",
        });
        modalRef.componentInstance.description = description;
        modalRef.componentInstance.language = this.languages;
        if (targetLanguageDescription) {
           modalRef.componentInstance.selectGivenLanguage = true;
           modalRef.componentInstance.selectedLanguage = targetLanguageDescription;
        }
        modalRef.result.then(
          (result) => {
            const errorLanguage = []
            const descriptions = []
            this.languages.forEach((lang) => {
              if (result[lang.iso639_1])
                if (result[lang.iso639_1].error) {
                  errorLanguage.push(lang.name)
                } else {
                  descriptions.push({
                    company_id: description.company_id,
                    language_id: lang.id,
                    revised_at: this.dateConverter.toEntityString(moment()),
                    description: result[lang.iso639_1],
                    langauge_name: lang.name,
                  });
                }
            });
            if (errorLanguage.length) {
              this.toastr.error("Error while fetching translations for " + errorLanguage.join(', '), "Translation", {timeOut: 5000})
            } else {
              this.toastr.success(
                "Translated successfully!",
                "Company Description"
              );
            }
            if(descriptions.length > 0) {

            // Open next modal to review translations
            const reviewModalRef = this.modalService.open(
              TranslatedDescriptionsComponent,
              {
                size: "lg",
              }
            );
            reviewModalRef.componentInstance.companyId = description.company_id;
            reviewModalRef.componentInstance.translations = result;
            reviewModalRef.componentInstance.targetLanguages = this.languages;
            reviewModalRef.componentInstance.descriptions = descriptions
            reviewModalRef.componentInstance.sourceDescription = description
            reviewModalRef.result.then((reload) => {
              if(reload) {
                this.onTranslationSaved.next(description.company_id);
              }
            });
            }
          },
          (reason) => {}
        );
      }
    });
  }

  isLanguageInSourceList(languageId, description) {
    const result = this.sourceLanguages.find((lang) => {
      return lang.id === languageId;
    });
    if(result && !description.language) {
      description.language = result
    }
    return result ? true : false;
  }

  public getTranslationLanguages() {
    return new Promise((resolve, reject) => {
      if (this.languages.length > 0) {
        resolve(this.languages);
      } else {
        this.http
          .get(
            `${ApiSettings.BASE_URL}/${TranslationSettings.LANGUAGE_LIST_ENDPOINT}`
          )
          .subscribe(
            (languages: {
              target_languages: any[];
              source_languages: any[];
            }) => {
              this.languages = languages.target_languages;
              this.sourceLanguages = languages.source_languages;
              resolve(languages);
            },
            (err) => {
              console.log(err);
              reject();
            }
          );
      }
    });
  }
}
