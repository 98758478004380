<!--disable cache-->
<meta http-equiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0" />
<meta http-equiv="expires" content="0" />
<meta http-equiv="pragma" content="no-cache" />

<div class="sidebar">
  <ng-container *ngIf="(selectedProjectService.projectsData$ | async) && !loading && serverChooserService.getBackendEnvName(); else loader">
    <nav class="nav flex-column h-100">
      <a class="nav-link pt-1 pb-1" placement="right" [ngbTooltip]="serverChooserService.getBackendEnvName()" [style]="'background:' + projectColor">
        <span class="project-icon">{{ projectNameHumanFriendly?.substring(0, 5) }}</span>
      </a>
      <a
        *ngFor="let list of selectedProjectService.projectsData$ | async"
        class="nav-link"
        [routerLink]="['/doc_process/', getBackendEnvName(), 'project', list.project_id]"
        routerLinkActive="active"
        placement="right"
        [ngbTooltip]="list.project_name"
      >
        <span class="project-icon">{{ list.project_name.substring(0, 4) }}</span>
      </a>
      <a
        (click)="onHelpIconClick()"
        [routerLink]="['/doc_process/', getBackendEnvName(), 'help']"
        routerLinkActive="active"
        placement="right"
        [ngbTooltip]="'Help Page'"
        class="nav-link mt-auto text-center"
        style="color: #ffffff; font-size: 200%"
      >
        <con-icon-by-name iconName="rebel"></con-icon-by-name>
      </a>
    </nav>
  </ng-container>
</div>
<div class="content" *ngIf="!profile.isLoading()">
  <div *ngIf="profile.canOr([profile.getPermissionName('doc_process', 'show'), profile.getPermissionName('doc_process', 'update')]); else unauthorized" class="container-fluid mb-0">
    <router-outlet></router-outlet>
  </div>
  <ng-template #unauthorized>
    <div class="container-fluid mb-3">
      <div class="jumbotron">
        <h1>Oops!</h1>
        <p class="lead">You are not authorized to see this! <con-icon-by-name iconName="frown-o"></con-icon-by-name></p>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #loader>
  <con-loader *ngIf="!(selectedProjectService.projectsData$ | async) || loading"></con-loader>
</ng-template>
