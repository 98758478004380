<con-loader *ngIf="loading"></con-loader>
<div class="table-responsive">
    <table class="table" *ngIf="!loading">
        <thead>
            <tr>
                <th *ngFor="let field of entityDescription?.getFieldsForHistory()">
                    {{ field.label }}
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let line of history">
                <tr>
                    <td *ngFor="let field of entityDescription?.getFieldsForHistory()">
                        {{ entityDescription.getAttributeByString(line, field.key) }}
                        <span *ngIf="field.key === 'updated_at'">
                            <con-avatar [userId]="line.updated_by" [size]="17"></con-avatar>
                        </span>
                        <span *ngIf="field.key === 'created_at'">
                            <con-avatar [userId]="line.created_by" [size]="17"></con-avatar>
                        </span>
                    </td>
                    <td (click)="toggleInspect(line.id)">
                        <con-icon-by-name iconName="code"></con-icon-by-name>
                    </td>
                </tr>
                <con-object-inspector *ngIf="showInspect(line.id)" [object]="line"></con-object-inspector>
            </ng-container>
        </tbody>
    </table>
</div>
<div class="d-flex justify-content-center mt-2" [ngClass]="{'hide-pagination':!response?.total}">
    <ngb-pagination [collectionSize]="response?.total"
                    [(page)]="currentPage"
                    (pageChange)="changePage($event)"
                    [pageSize]="response?.per_page"
                    maxSize="10"
                    [rotate]="true"></ngb-pagination>
</div>
